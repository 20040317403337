<template>
  <section class="o-inner-page">
    <!-- wait for filter restore to get initial values -->
    <template v-if="filtersRestored">
      <div v-if="!hideToolbar" ref="otWrapper" :class="otWrapperClasses" :style="styles">
        <!--        t: {{ toolbarInitialDataLoaded }} cf: {{ customFieldFiltersInitialDataLoaded }}

        <div style="display: grid; width: 100%; grid-template-columns: 1fr 1fr 1fr">
          <pre>
          {{ listState.customFieldsFiltersValues }}
        </pre
          >
          <pre>
          {{ listState.filtersValues }}
        </pre
          >

          <pre>
          {{ currentWorkspaceCustomFieldsIds }}
        </pre
          >
        </div>-->
        <OkrToolbar
          v-show="showToolbar"
          ref="toolbar"
          v-model:intervals="intervals"
          v-model:my-group="myGroup"
          :custom-fields-filters-values="listState.customFieldsFiltersValues"
          :excel-export-proceed="excelExportProceed"
          :filter-preset="filterPreset"
          :filters-values="listState.filtersValues"
          :full-screen="fullScreen"
          :tab-for-tracker="tabForTracker"
          :view="view"
          :workspace-id="workspaceId"
          @update-filter-value="onFilterParameterUpdate"
          @update-custom-field-filter-value="onCustomFieldFilterParameterUpdate"
          @reset-filter-value="onFilterValueReset"
          @reset-custom-field-filters="onResetCustomFiledFilters"
          @initial-data-loaded="onToolbarInitialDataLoaded"
          @custom-fields-filters-initial-data-loaded="onCustomFieldFiltersInitialDataLoaded"
          @change-view="onViewChange"
          @export-excel="onExportToExcel"
          @toolbar-scrolled-above="onToolbarScrolledAbove"
          @reset-custom-filter="onResetFilter"
        />
      </div>

      <slot />
    </template>

    <template v-if="!hidePortals">
      <portal v-if="userCanCreateObjectives" to="menu-objectives-link">
        <OkrElementCreator
          ref="appMenuCreateObjective"
          :objective-levels="objectiveLevels"
          :select-options-list-test-id="MENU_OKR_ELEMENT_CREATOR_TEST_ID"
          append-to="parent"
          dropdown-position="right-start"
          dropdown-width="188px"
          @on-create-okr-element="onCreateObjectiveClick"
        >
          <template #trigger="{ triggerSelector, showDropdown }">
            <div :class="triggerSelector">
              <AppButton
                v-tippy="{
                  content: $t('action.create_objective'),
                  placement: 'top'
                }"
                :class="{
                  'olp-MenuCreateObjective-active': showDropdown
                }"
                class="olp-MenuCreateObjective"
                height="24"
                icon="plus-small"
                size="sm"
                type="ghost-next"
                width="24"
              />
            </div>
          </template>
        </OkrElementCreator>
      </portal>
      <portal v-if="userCanCreateObjectives" to="toolbar-actions">
        <OkrElementCreator
          ref="createSelect"
          :objective-levels="objectiveLevels"
          :select-options-list-test-id="TOOLBAR_OKR_ELEMENT_CREATOR_TEST_ID"
          dropdown-position="bottom-end"
          @on-create-okr-element="onCreateObjectiveClick"
        >
          <template #trigger="{ triggerSelector }">
            <AppButton
              id="v-step-0"
              :class="triggerSelector"
              height="24"
              icon="plus-next"
              type="primary-next"
              width="24"
            >
              {{ $t('action.create_objective') }}
            </AppButton>
          </template>
        </OkrElementCreator>
      </portal>

      <portal to="objective-navigation-right">
        <AppSearch
          v-model="searchString"
          :placeholder="$t('filter.search_okr_placeholder')"
          width="160"
        />
      </portal>

      <portal to="modal-windows">
        <ObjectiveModal
          v-if="showObjectiveModal"
          ref="objectiveModal"
          source="button"
          @close="onObjectiveModalClose"
          @closed="showObjectiveModal = false"
          @element-created="onElementCreated"
        />
      </portal>
    </template>
  </section>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs'
import FileSaver from 'file-saver'
import { cloneDeep, isEmpty, has, isBoolean, isString, isEqual, isUndefined, isArray } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import ObjectivesApiHandler from '@/api/okr-elements'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import {
  CUSTOM_FIELDS_FILTERS_QUERY_KEY,
  DEFAULT_CUSTOM_FIELD_FILTERS_VALUE,
  getCustomFieldFiltersQuery
} from '@/utils/custom-fields/helpers'
import { UNSELECTED_DATE } from '@/utils/date'
import { handleError } from '@/utils/error-handling'
import { getSuitableInterval } from '@/utils/interval'
import { NOTIFICATION_DURATIONS, NOTIFICATION_TYPES, showNotify } from '@/utils/notify'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import {
  clearExplorerUniqueIds,
  currentUserCanReadObjective,
  getExpandedItemList,
  OBJECTIVE_SORT_OPTIONS,
  OKR_FORM_VIEWS,
  OKR_TYPE_TO_FORM_VIEW,
  OKR_VIEW_PAGES,
  OKR_VIEW_PAGES_IDS,
  okrElementIsExpandable
} from '@/utils/objectives'
import {
  createCustomFieldsFiltersPayload,
  CUSTOM_FIELD_FILTER_DEFAULT_VALUES,
  DEFAULT_VALUE_FOR_FILTER,
  FILTER_DATA_TYPES,
  FILTER_DEFAULT_VALUES,
  FILTER_LS_KEYS,
  FILTER_PRESETS,
  FILTERS_KEYS,
  isTimeRangeSelectedInsteadOfInterval,
  RESET_FILTER_TYPES,
  RESETTABLE_FILTERS_KEYS,
  restoreDates,
  restoreFilterValue,
  saveFilterValues,
  VALUES_DATA_TYPES,
  restoreRelativeValues,
  LAST_COMMENT_UPDATE_DATE_FROM,
  LAST_COMMENT_UPDATE_DATE_TO,
  SHOULD_BE_COMMENTED,
  NEVER_COMMENTED
} from '@/utils/okr-elements/filters'
import {
  checkIsShowNotification,
  createdObjectiveModalNotificationActions,
  getCreatedElementNotificationTitle
} from '@/utils/okr-elements/notifications'
import { getEditValueFromQuery } from '@/utils/okr-elements-forms-helpers'
import { updateStorageByKey } from '@/utils/persist'
import { checkResponseStatus, RESPONSE_STATUSES } from '@/utils/response-statuses'
import { replaceQueryParameters } from '@/utils/router'
import { getSelectWithSelectAllApiParameter, selectAllIsSelected } from '@/utils/select'

import ObjectiveModal from '@/components/objectives/ObjectiveModal'
import OkrElementCreator from '@/components/objectives/OkrElementCreator'
import OkrToolbar from '@/components/objectives/toolbar/OkrToolbar'
import AppButton from '@/components/ui/AppButton/AppButton'
import {
  MENU_OKR_ELEMENT_CREATOR_TEST_ID,
  TOOLBAR_OKR_ELEMENT_CREATOR_TEST_ID
} from '@/components/ui/AppSelect/jest-helpers'
import AppSearch from '@/components/ui/Search/Search'

const PAGES = { ...OKR_VIEW_PAGES }

const LIST_STATE = {
  READY_TO_LOAD: 'READY_TO_LOAD',
  COMPLETED: 'COMPLETED'
}

const SEARCH_TYPES = {
  [PAGES.OKREXPLORER]: 1,
  [PAGES.ALIGNMENT]: 2,
  [PAGES.MINDMAP]: 2,
  [PAGES.ROADMAP]: 2
}

const restoreIntervalsAndDates = ({ restoredFiltersValues, intervals }) => {
  const suitableInterval = getSuitableInterval(
    intervals,
    DEFAULT_VALUE_FOR_FILTER[FILTERS_KEYS.INTERVAL_IDS],
    true
  )

  // const isSuitableIntervalFounded = !isUndefined(suitableInterval)
  // const defaultIntervalsValue = isSuitableIntervalFounded
  //   ? [suitableInterval]
  //   : cloneDeep(DEFAULT_VALUE_FOR_FILTER[FILTERS_KEYS.INTERVAL_IDS])

  if (
    !has(restoredFiltersValues, FILTERS_KEYS.INTERVAL_IDS) ||
    !has(restoredFiltersValues, FILTERS_KEYS.START_DATES) ||
    !has(restoredFiltersValues, FILTERS_KEYS.DUE_DATES) ||
    !has(restoredFiltersValues, FILTERS_KEYS.LAST_GRADE_UPDATE_DATES)
  ) {
    return {
      [FILTERS_KEYS.INTERVAL_IDS]: [suitableInterval],
      [FILTERS_KEYS.START_DATES]: FILTER_DEFAULT_VALUES[FILTERS_KEYS.START_DATES],
      [FILTERS_KEYS.DUE_DATES]: FILTER_DEFAULT_VALUES[FILTERS_KEYS.DUE_DATES],
      [FILTERS_KEYS.LAST_GRADE_UPDATE_DATES]:
        FILTER_DEFAULT_VALUES[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES]
    }
  }

  const restoredStartDates = restoredFiltersValues[FILTERS_KEYS.START_DATES]
  const restoredDueDates = restoredFiltersValues[FILTERS_KEYS.DUE_DATES]
  const restoredIntervals = restoredFiltersValues[FILTERS_KEYS.INTERVAL_IDS]
  const restoredLastGradeUpdateDates = restoredFiltersValues[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES]

  const {
    isTimeRangeSelected,
    isBetweenRangeSelected,
    isBeforeRangeSelected,
    isSinceRangeSelected
  } = isTimeRangeSelectedInsteadOfInterval({
    startDates: restoredStartDates,
    dueDates: restoredDueDates,
    intervals: restoredIntervals
  })

  const result = {}

  if (isTimeRangeSelected) {
    result[FILTERS_KEYS.INTERVAL_IDS] = cloneDeep(FILTER_DEFAULT_VALUES[FILTERS_KEYS.INTERVAL_IDS])

    const [startDateFrom] = restoredStartDates
    const [, dueDateTo] = restoredDueDates

    // to avoid code duplication
    if (isSinceRangeSelected || isBetweenRangeSelected) {
      result[FILTERS_KEYS.START_DATES] = restoreDates({
        dates: [startDateFrom, UNSELECTED_DATE],
        isTimeRangeSelectedInsteadOfInterval: true,
        defaultValue: FILTER_DEFAULT_VALUES[FILTERS_KEYS.START_DATES],
        dateProp: FILTERS_KEYS.START_DATES
      })
    }

    // to avoid code duplication
    if (isBeforeRangeSelected || isBetweenRangeSelected) {
      result[FILTERS_KEYS.DUE_DATES] = restoreDates({
        dates: [UNSELECTED_DATE, dueDateTo],
        isTimeRangeSelectedInsteadOfInterval: true,
        defaultValue: FILTER_DEFAULT_VALUES[FILTERS_KEYS.DUE_DATES],
        dateProp: FILTERS_KEYS.DUE_DATES
      })
    }

    if (isSinceRangeSelected) {
      result[FILTERS_KEYS.DUE_DATES] = [UNSELECTED_DATE, UNSELECTED_DATE]
    }

    if (isBeforeRangeSelected) {
      result[FILTERS_KEYS.START_DATES] = [UNSELECTED_DATE, UNSELECTED_DATE]
    }
  } else {
    result[FILTERS_KEYS.INTERVAL_IDS] = isEmpty(restoredIntervals)
      ? [suitableInterval]
      : restoredIntervals

    result[FILTERS_KEYS.START_DATES] = restoreDates({
      dates: restoredStartDates,
      defaultValue: FILTER_DEFAULT_VALUES[FILTERS_KEYS.START_DATES],
      dateProp: FILTERS_KEYS.START_DATES
    })

    result[FILTERS_KEYS.DUE_DATES] = restoreDates({
      dates: restoredDueDates,
      defaultValue: FILTER_DEFAULT_VALUES[FILTERS_KEYS.DUE_DATES],
      dateProp: FILTERS_KEYS.DUE_DATES
    })
  }

  result[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES] = restoreDates({
    dates: restoredLastGradeUpdateDates,
    defaultValue: FILTER_DEFAULT_VALUES[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES],
    dateProp: FILTERS_KEYS.LAST_GRADE_UPDATE_DATES
  })

  return result
}

/*
 * Data loading:
 * there are a few preconditions such as loading of filters data etc, that should be hold to
 * load the data. That's why instead of direct call of `updateObjectives` method
 * `tryToUpdateOkrElements` should be used.
 */
const DAY_UNIT = 'day'
const DAYS_UNIT = 'days'

export default defineComponent({
  name: 'ObjectiveListPage',

  components: {
    AppSearch,
    OkrElementCreator,
    ObjectiveModal,
    OkrToolbar,
    AppButton
  },

  provide() {
    return {
      listState: this.listState
    }
  },

  inject: ['isJiraApp'],

  props: {
    offsetLeft: {
      type: [String, Number],
      default: ''
    },

    offsetRight: {
      type: [String, Number],
      default: ''
    },

    fetchExpandAll: {
      type: Boolean
    },

    workspaceId: {
      type: [String, Number],
      required: true
    },

    objectiveLevels: {
      type: Array,
      default: () => []
    },

    hideToolbar: {
      type: Boolean
    },

    hidePortals: {
      type: Boolean
    },

    view: {
      type: String,
      required: true
    },

    userCanCreateObjectives: {
      type: Boolean
    },

    filterValues: {
      type: Object,
      default: () => ({})
    },

    stopScrollLoaderAfterFirstLoad: {
      type: Boolean
    },

    filterPreset: {
      type: String,
      default: FILTER_PRESETS.NONE
    }
  },

  emits: { 'show-error': null, 'data-request': null, 'initial-data-loaded': null },

  data() {
    return {
      listState: {
        filtersValues: cloneDeep(FILTER_DEFAULT_VALUES),
        customFieldsFiltersValues: {},
        requestParameters: {},
        isInitialDataLoaded: false,
        page: 1,
        view: PAGES.ALIGNMENT,
        haveAllFiltersDefaultValues: true,
        noResults: false,
        // { id: { ...element } }
        okrElements: {},
        // { id: [id1, id2] }
        okrElementChildren: {},
        blinkObjectiveIds: [], // [{ id: number, onFirstLevel: boolean }],
        // root elements(lists of ids)
        objectives: [],
        krs: [],
        nestedTasks: [],
        expandedTasks: {},
        tasksChildren: {},
        dataLoaded: false,
        showFilteredChildrenObjectives: {}
      },

      blinkObjectiveIds: [],
      intervals: [],
      isLoading: false,
      searchString: '',
      showObjectiveModal: false,
      additionalParameters: {},
      getObjectivesAxiosSource: null,

      objectivesWithEmptySearchExist: false,
      toolbarInitialDataLoaded: false,
      customFieldFiltersInitialDataLoaded: false,
      initialObjectivesAreLoaded: false,
      filtersRestored: false,
      state: LIST_STATE.READY_TO_LOAD,
      excelExportProceed: false,
      myGroup: FILTER_DEFAULT_VALUES[FILTERS_KEYS.GROUP_IDS]
    }
  },

  computed: {
    ...mapState('system', {
      userData: state => state.userData
    }),

    ...mapGetters('pluginOptions', {
      isPluginServer: 'isPluginServer'
    }),

    ...mapGetters('customFields', {
      fieldById: 'fieldById',
      columnsByWorkspaceId: 'columnsByWorkspaceId',
      columnsIdsByWorkspaceId: 'columnsIdsByWorkspaceId'
    }),

    MENU_OKR_ELEMENT_CREATOR_TEST_ID: () => MENU_OKR_ELEMENT_CREATOR_TEST_ID,
    TOOLBAR_OKR_ELEMENT_CREATOR_TEST_ID: () => TOOLBAR_OKR_ELEMENT_CREATOR_TEST_ID,

    currentWorkspaceCustomFields() {
      // check $store existing cause in jira gadget we haven't store and that might crash app
      if (!this.$store) {
        return []
      }

      return this.columnsByWorkspaceId(this.workspaceId)
    },

    currentWorkspaceCustomFieldsIds() {
      // check $store existing cause in jira gadget we haven't store and that might crash app
      if (!this.$store) {
        return []
      }

      return this.columnsIdsByWorkspaceId(this.workspaceId)
    },

    // filterPresetTriggers() {
    //   return [this.workspaceId, this.filterPreset]
    // },

    otWrapperClasses() {
      const view = this.view.toLowerCase()
      return {
        'ot-wrapper': true,
        [`ot-Wrapper-${view}`]: view
      }
    },

    onboarding() {
      // required this syntax for gadget and other jira instances
      return this.$store?.state.pluginOptions.onboarding || false
    },

    menuPinned() {
      return this.$store?.state.system.appMenu.pinned || false
    },

    menuShowed() {
      return this.$store?.state.system.appMenu.showed || false
    },

    fullScreen() {
      return this.$store?.state.system.fullscreen || false
    },

    styles() {
      const { offsetRight, offsetLeft, fullScreen } = this
      const paddingBottom = [PAGES.ROADMAP, PAGES.MINDMAP].includes(this.view) ? '16px' : '24px'
      return fullScreen ? {} : { padding: `16px ${offsetRight} ${paddingBottom} ${offsetLeft}` }
    },

    tableParameters() {
      return {
        searchType: SEARCH_TYPES[this.view],
        searchString: this.searchString
      }
    },

    showToolbar() {
      // DO NOT SIMPLIFY FOR BETTER READABILITY

      if (this.hideToolbar) {
        return false
      }

      if (!this.listState.isInitialDataLoaded) {
        return false
      }

      const { haveAllFiltersDefaultValues, objectivesWithEmptySearchExist, isLoading } = this

      return (
        (!isLoading && haveAllFiltersDefaultValues && !objectivesWithEmptySearchExist) === false
      )
    },

    // showToolbar() {
    //   if (this.hideToolbar) {
    //     return false
    //   }
    //   // DO NOT SIMPLIFY FOR BETTER READABILITY
    //   if (this.listState.isInitialDataLoaded) {
    //     if (this.isPageOKRExplorer) {
    //       return this.listState.dataLoaded
    //     }
    //     return (
    //       (this.isLoading === false &&
    //         this.haveAllFiltersDefaultValues &&
    //         this.objectivesWithEmptySearchExist === false) === false
    //     )
    //   } else {
    //     return false
    //   }
    // },

    // temporary helper(until we need haveFiltersDefaultValue or in other
    // words filters are not local)
    haveAllFiltersDefaultValues() {
      const filtersValues = this.listState.filtersValues
      let result =
        selectAllIsSelected(filtersValues[FILTERS_KEYS.ASSIGNEE_IDS]) &&
        selectAllIsSelected(filtersValues[FILTERS_KEYS.LABEL_IDS]) &&
        selectAllIsSelected(filtersValues[FILTERS_KEYS.STAKEHOLDER_IDS]) &&
        selectAllIsSelected(filtersValues[FILTERS_KEYS.GROUP_IDS]) &&
        selectAllIsSelected(filtersValues[FILTERS_KEYS.GRADE_TYPES]) &&
        selectAllIsSelected(filtersValues[FILTERS_KEYS.OKR_TYPE_IDS]) &&
        filtersValues[FILTERS_KEYS.DUE_DATES] === FILTER_DEFAULT_VALUES[FILTERS_KEYS.DUE_DATES] &&
        filtersValues[FILTERS_KEYS.START_DATES] ===
          FILTER_DEFAULT_VALUES[FILTERS_KEYS.START_DATES] &&
        filtersValues[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES] ===
          FILTER_DEFAULT_VALUES[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES] &&
        isEqual(
          filtersValues[FILTERS_KEYS.LAST_COMMENT],
          FILTER_DEFAULT_VALUES[FILTERS_KEYS.LAST_COMMENT]
        )

      if (this.searchString) {
        result = result && this.searchString === ''
      }

      return result
    },

    filterCollapsedSensibleData() {
      const filtersValues = this.listState.filtersValues
      return {
        workspaceId: this.workspaceId,
        dueDates: filtersValues[FILTERS_KEYS.DUE_DATES],
        startDates: filtersValues[FILTERS_KEYS.START_DATES],
        lastGradeUpdateDates: filtersValues[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES],
        lastCommentUpdateDates: filtersValues[FILTERS_KEYS.LAST_COMMENT],
        okrTypes: filtersValues[FILTERS_KEYS.OKR_TYPE_IDS],
        ownerIds: filtersValues[FILTERS_KEYS.ASSIGNEE_IDS],
        labelIds: filtersValues[FILTERS_KEYS.LABEL_IDS],
        stakeholderIds: filtersValues[FILTERS_KEYS.STAKEHOLDER_IDS],
        groupIds: filtersValues[FILTERS_KEYS.GROUP_IDS],
        gradeTypes: filtersValues[FILTERS_KEYS.GRADE_TYPES],
        intervalIds: filtersValues[FILTERS_KEYS.INTERVAL_IDS],
        sortOrder: filtersValues[FILTERS_KEYS.SORT_ORDER],
        sortChildren: filtersValues[FILTERS_KEYS.SORT_CHILDREN],
        searchType: this.tableParameters.searchType,
        searchString: this.tableParameters.searchString
      }
    },

    listMayBeLoaded() {
      if (isEmpty(this.filterValues)) {
        return (
          this.toolbarInitialDataLoaded &&
          this.customFieldFiltersInitialDataLoaded &&
          !this.initialObjectivesAreLoaded
        )
      } else {
        return !this.initialObjectivesAreLoaded
      }
    },

    tabForTracker() {
      const TAB_NAMES = {
        [ROUTE_NAMES.OKR_ELEMENTS_HIERARCHY_TABLE]: OKR_VIEW_PAGES_IDS.ALIGNMENT,
        [ROUTE_NAMES.OKR_ELEMENTS_ROADMAP]: OKR_VIEW_PAGES_IDS.ROADMAP,
        [ROUTE_NAMES.OKR_ELEMENTS_EXPLORER]: OKR_VIEW_PAGES_IDS.OKREXPLORER,
        [ROUTE_NAMES.OKR_ELEMENTS_MIND_MAP]: OKR_VIEW_PAGES_IDS.MINDMAP
      }

      return TAB_NAMES[this.$route?.name] || OKR_VIEW_PAGES_IDS.ALIGNMENT
    }
  },

  watch: {
    'listState.view'() {
      this.$nextTick(() => {
        this.updateObjectives({ resetItemsImmediately: true })

        this.calculateOtWrapperHeight()
      })
    },

    searchString() {
      this.updateObjectives({ resetItemsImmediately: true })
    },

    'listState.filtersValues.intervalIds': {
      handler() {
        if (this.listState.isInitialDataLoaded) {
          // filters changed, value will be stored after data update, no additional saving is needed
          this.listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS] = {}
        }
        this.resetItems()
        this.initialObjectivesAreLoaded = false
      },

      deep: true
    },

    haveAllFiltersDefaultValues(newValue) {
      this.listState.haveAllFiltersDefaultValues = newValue
    },

    view: {
      handler(newValue) {
        this.listState.view = newValue
        if (this.initialObjectivesAreLoaded) {
          this.$nextTick(() => {
            this.updateObjectives({ resetItemsImmediately: true })
          })
        }
      },

      immediate: true
    },

    filterCollapsedSensibleData(newValue, oldValue) {
      if (
        JSON.stringify(newValue) !== JSON.stringify(oldValue) &&
        this.listState.isInitialDataLoaded
      ) {
        // filters changed, value will be stored after data update, no additional saving is needed
        this.listState.filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS] = {}
      }
    },

    workspaceId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.listState.isInitialDataLoaded = false
        this.toolbarInitialDataLoaded = false
        this.customFieldFiltersInitialDataLoaded = false

        // filters changed, value will be stored after data update, no additional saving is needed
        this.listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS] = {}
      }
    },

    listMayBeLoaded(newValue) {
      if (newValue) {
        this.updateObjectivesRequestPayload()
        this.updateOkrElements({
          parameters: { expandAll: this.onboarding }
        })
        this.initialObjectivesAreLoaded = true
      }
    },

    showToolbar: {
      handler() {
        if (!this.hideToolbar) {
          this.calculateOtWrapperHeight()
        }
      },

      immediate: true
    },

    menuPinned(newValue) {
      if (!newValue && this.userCanCreateObjectives) {
        this.$refs.appMenuCreateObjective.hideDropdown()
      }
    },

    menuShowed(newValue) {
      if (!newValue && this.userCanCreateObjectives) {
        this.$refs.appMenuCreateObjective.hideDropdown()
      }
    },

    filterPreset(newValue) {
      if (Object.values(FILTER_PRESETS).includes(newValue)) {
        this.filterPresetUpdated()
      }
    }

    /// filterPresetTriggers(newValue, oldValue) {
    ///   const [newWorkspaceId, newFilterPreset] = newValue
    ///   const [oldWorkspaceId] = oldValue
    ///
    ///   if (Object.values(FILTER_PRESETS).includes(newFilterPreset)) {
    ///     this.filterPresetUpdated(newWorkspaceId !== oldWorkspaceId)
    ///   }
    /// }
  },

  mounted() {
    if (isEmpty(this.filterValues)) {
      this.restoreFilterValues()

      if (this.$route) {
        const { query } = this.$route

        const editValue = getEditValueFromQuery({ query })

        if (editValue) {
          this.onEditElement(editValue)
        }
      }
    } else {
      this.restoreExternalFilterValues()
    }
  },

  methods: {
    ...mapActions('system', {
      setOtWrapperHeight: 'setOtWrapperHeight'
    }),

    onToolbarScrolledAbove() {
      const { createSelect } = this.$refs
      if (createSelect) {
        createSelect.hideDropdown()
      }
    },

    /** @public */
    createOkrElement(data) {
      this.onCreateObjectiveClick(data.levelId)
    },

    updateObjectives(parameters = { keepObjectivesSize: true }) {
      this.tryToUpdateOkrElements({ ...parameters })
    },

    onViewChange() {
      this.$nextTick(() => {
        this.initialObjectivesAreLoaded = false
      })
    },

    async onCreateObjectiveClick(levelId) {
      this.showObjectiveModal = true
      await this.$nextTick()

      const { filtersValues } = this.listState

      const dueDates = filtersValues[FILTERS_KEYS.DUE_DATES]
      const startDates = filtersValues[FILTERS_KEYS.START_DATES]

      const dueDateFrom = dueDates ? dueDates[0] : UNSELECTED_DATE
      const dueDateTo = dueDates ? dueDates[1] : UNSELECTED_DATE

      const startDateFrom = startDates ? startDates[0] : UNSELECTED_DATE
      const startDateTo = startDates ? startDates[1] : UNSELECTED_DATE

      const { isTimeRangeSelected } = isTimeRangeSelectedInsteadOfInterval({
        startDates: [startDateFrom, startDateTo],
        dueDates: [dueDateFrom, dueDateTo],
        intervals: this.listState.filtersValues[FILTERS_KEYS.INTERVAL_IDS]
      })

      this.$refs.objectiveModal.openForm(
        OKR_FORM_VIEWS.OBJECTIVE,
        {
          levelId,
          intervalId: getSuitableInterval(
            this.intervals,
            this.listState.filtersValues[FILTERS_KEYS.INTERVAL_IDS],
            isTimeRangeSelected
          )
        },
        'grid'
      )
    },

    onElementCreated(elementType) {
      const title = getCreatedElementNotificationTitle(elementType)

      setTimeout(() => {
        // wait for modal to close
        this.$nextTick(() => {
          if (this.showObjectiveModal) {
            // show notify only if objective modal is open
            // that means that user created (KR, TASK, OBJECTIVE) from objective modal
            showNotify({
              title: this.$t(title)
            })
          }
        })
      })
    },

    onObjectiveModalClose(eventData) {
      const isNeedShowNotification = checkIsShowNotification(eventData).OBJECTIVE
      if (isNeedShowNotification) {
        const { createdObjective: createdElement } = eventData
        showNotify({
          duration: NOTIFICATION_DURATIONS.SHORT,
          expanded: true,
          title: this.$t('create.objective.success_title'),
          content: this.$t('create.objective.success_description'),
          actions: [
            {
              ...createdObjectiveModalNotificationActions.OPEN,
              handler: () => {
                this.onEditElement(createdElement)
              }
            },
            {
              ...createdObjectiveModalNotificationActions.COPY_LINK,
              handler: () => {
                createdObjectiveModalNotificationActions.COPY_LINK.handler({
                  isPluginServer: this.isPluginServer,
                  workspaceId: this.workspaceId,
                  createdElement,
                  isJiraApp: this.isJiraApp
                })
                showNotify({ title: this.$t('notifications.link_copied') })
              }
            }
          ]
        })
      }

      const parameters = {
        keepObjectivesSize: true
      }

      const createdObject = eventData.createdObjective || eventData.createdKR

      const handleOkrElement = element => {
        parameters.blinkObjectiveIds = [
          {
            uniqueId: element.uniqueId,
            // onFirstLevel: true
            onFirstLevel: false
          }
        ]
      }

      if (createdObject) {
        handleOkrElement(createdObject)
      } else if (eventData.createdTasks) {
        eventData.createdTasks.forEach(issue => handleOkrElement(issue))
      }

      this.updateObjectives(parameters)
    },

    restoreExternalFilterValues() {
      const {
        ownerIds,
        stakeholderIds,
        groupIds,
        gradeTypes,
        levelIds,
        dueDatesFrom,
        dueDatesTo,
        startDatesFrom,
        startDatesTo,
        intervalIds
      } = this.filterValues
      this.listState.filtersValues[FILTERS_KEYS.INTERVAL_IDS] = isArray(intervalIds)
        ? intervalIds
        : [intervalIds]

      this.listState.filtersValues[FILTERS_KEYS.ASSIGNEE_IDS] = isEmpty(ownerIds)
        ? cloneDeep(FILTER_DEFAULT_VALUES[FILTERS_KEYS.ASSIGNEE_IDS])
        : ownerIds
      this.listState.filtersValues[FILTERS_KEYS.STAKEHOLDER_IDS] = isEmpty(stakeholderIds)
        ? cloneDeep(FILTER_DEFAULT_VALUES[FILTERS_KEYS.STAKEHOLDER_IDS])
        : stakeholderIds
      this.listState.filtersValues[FILTERS_KEYS.GROUP_IDS] = isEmpty(groupIds)
        ? cloneDeep(FILTER_DEFAULT_VALUES[FILTERS_KEYS.GROUP_IDS])
        : groupIds
      this.listState.filtersValues[FILTERS_KEYS.GRADE_TYPES] = isEmpty(gradeTypes)
        ? cloneDeep(FILTER_DEFAULT_VALUES[FILTERS_KEYS.GRADE_TYPES])
        : gradeTypes
      this.listState.filtersValues[FILTERS_KEYS.OKR_TYPE_IDS] = isEmpty(levelIds)
        ? cloneDeep(FILTER_DEFAULT_VALUES[FILTERS_KEYS.OKR_TYPE_IDS])
        : levelIds
      if (dueDatesFrom && dueDatesTo) {
        this.listState.filtersValues[FILTERS_KEYS.DUE_DATES] = [
          dayjs(dueDatesFrom).toDate(),
          dayjs(dueDatesTo).toDate()
        ]
      } else {
        this.listState.filtersValues[FILTERS_KEYS.DUE_DATES] =
          FILTER_DEFAULT_VALUES[FILTERS_KEYS.DUE_DATES]
      }

      if (startDatesFrom && startDatesTo) {
        this.listState.filtersValues[FILTERS_KEYS.START_DATES] = [
          dayjs(startDatesFrom).toDate(),
          dayjs(startDatesTo).toDate()
        ]
      } else {
        this.listState.filtersValues[FILTERS_KEYS.START_DATES] =
          FILTER_DEFAULT_VALUES[FILTERS_KEYS.START_DATES]
      }

      this.updateObjectivesRequestPayload()
      this.filtersRestored = true
    },

    /** @public */
    restoreSavedFilters(query) {
      const restoredFiltersValues = {}

      Object.entries(query).forEach(([key, value]) => {
        if (Object.values(FILTERS_KEYS).includes(key)) {
          restoredFiltersValues[key] = JSON.parse(value)

          // const restoredValue = JSON.parse(value)

          // const conditionForDates =
          //   (key === FILTERS_KEYS.DUE_DATES ||
          //     key === FILTERS_KEYS.START_DATES ||
          //     key === FILTERS_KEYS.LAST_GRADE_UPDATE_DATES) &&
          //   Array.isArray(restoredValue) &&
          //   restoredValue.length === 2

          // this.listState.filtersValues[key] = conditionForDates
          //   ? [dayjs(restoredValue[0]).toDate(), dayjs(restoredValue[1]).toDate()]
          //   : restoredValue
        }
      })

      const restoredIntervalsAndDates = restoreIntervalsAndDates({
        intervals: this.intervals,
        restoredFiltersValues
      })

      restoredFiltersValues[FILTERS_KEYS.INTERVAL_IDS] = cloneDeep(
        restoredIntervalsAndDates[FILTERS_KEYS.INTERVAL_IDS]
      )
      restoredFiltersValues[FILTERS_KEYS.START_DATES] = cloneDeep(
        restoredIntervalsAndDates[FILTERS_KEYS.START_DATES]
      )
      restoredFiltersValues[FILTERS_KEYS.DUE_DATES] = cloneDeep(
        restoredIntervalsAndDates[FILTERS_KEYS.DUE_DATES]
      )

      restoredFiltersValues[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES] = cloneDeep(
        restoredIntervalsAndDates[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES]
      )

      restoredFiltersValues[FILTERS_KEYS.LAST_COMMENT] = cloneDeep(
        restoreRelativeValues({
          restoredValue: restoredFiltersValues[FILTERS_KEYS.LAST_COMMENT],
          defaultValue: FILTER_DEFAULT_VALUES[FILTERS_KEYS.LAST_COMMENT],
          keyProp: FILTERS_KEYS.LAST_COMMENT
        })
      )

      Object.entries(restoredFiltersValues).forEach(([key, value]) => {
        this.listState.filtersValues[key] = value
      })

      const customFieldFiltersValuesFromQuery = query[CUSTOM_FIELDS_FILTERS_QUERY_KEY]

      if (customFieldFiltersValuesFromQuery) {
        const restoredCustomFieldsFiltersValues = JSON.parse(customFieldFiltersValuesFromQuery)
        if (
          Array.isArray(restoredCustomFieldsFiltersValues) &&
          !isEmpty(restoredCustomFieldsFiltersValues)
        ) {
          const customFieldsFiltersValues = this.restoreCustomFieldFiltersValuesFromQuery({
            restoredCustomFieldsFiltersValues
          })

          this.listState.customFieldsFiltersValues = {
            ...customFieldsFiltersValues
          }
        } else {
          this.listState.customFieldsFiltersValues = {}
        }
      } else {
        this.listState.customFieldsFiltersValues = {}
      }

      this.updateObjectivesRequestPayload()
      // restored filters values should be saved in route as well,
      // but it doesn't need to be done explicitly here, because they
      // are saved on loading initial data automatically.
      this.filtersRestored = true
    },

    restoreFilterValues() {
      const restoredFiltersValues = {}
      Object.values(FILTERS_KEYS).forEach(filterName => {
        // restore
        let restoredValue = restoreFilterValue(
          this.$route,
          filterName,
          cloneDeep(FILTER_DEFAULT_VALUES[filterName])
        )

        // check type
        if (FILTER_DATA_TYPES[filterName] === VALUES_DATA_TYPES.ARRAY) {
          if (!Array.isArray(restoredValue)) {
            restoredValue = cloneDeep(FILTER_DEFAULT_VALUES[filterName])
          }
        } else if (FILTER_DATA_TYPES[filterName] === VALUES_DATA_TYPES.BOOLEAN) {
          if (!isBoolean(restoredValue)) {
            restoredValue = FILTER_DEFAULT_VALUES[filterName]
          }
        } else if (FILTER_DATA_TYPES[filterName] === VALUES_DATA_TYPES.DATES) {
          // if (Array.isArray(restoredValue) && restoredValue.length === 2) {
          //   restoredValue = [dayjs(restoredValue[0]).toDate(), dayjs(restoredValue[1]).toDate()]
          // } else {
          //   restoredValue = FILTER_DEFAULT_VALUES[filterName]
          // }
          if (!Array.isArray(restoredValue) || restoredValue.length !== 2) {
            restoredValue = FILTER_DEFAULT_VALUES[filterName]
          }
        }

        if (filterName === FILTERS_KEYS.INTERVAL_IDS && !restoredValue.length) {
          // const suitableInterval = getSuitableInterval(this.intervals, [], true)
          // restoredValue = [suitableInterval]
          restoredValue = FILTER_DEFAULT_VALUES[FILTERS_KEYS.INTERVAL_IDS]
        } else if (
          FILTER_DATA_TYPES[filterName] === VALUES_DATA_TYPES.ARRAY &&
          isEmpty(restoredValue)
        ) {
          const filterValueCanBeEmptyArray = FILTER_DEFAULT_VALUES[filterName].length === 0
          if (!filterValueCanBeEmptyArray) {
            restoredValue = cloneDeep(FILTER_DEFAULT_VALUES[filterName])
          }
        } else if (FILTER_DATA_TYPES[filterName] === VALUES_DATA_TYPES.ARRAY) {
          const isRadioWithInputValue = restoredValue !== null && restoredValue !== undefined
          if (!isRadioWithInputValue) {
            restoredValue = cloneDeep(FILTER_DEFAULT_VALUES[filterName])
          }
        }

        restoredFiltersValues[filterName] = restoredValue
      })

      const restoredIntervalsAndDates = restoreIntervalsAndDates({
        intervals: this.intervals,
        restoredFiltersValues
      })

      restoredFiltersValues[FILTERS_KEYS.INTERVAL_IDS] = cloneDeep(
        restoredIntervalsAndDates[FILTERS_KEYS.INTERVAL_IDS]
      )
      restoredFiltersValues[FILTERS_KEYS.START_DATES] = cloneDeep(
        restoredIntervalsAndDates[FILTERS_KEYS.START_DATES]
      )
      restoredFiltersValues[FILTERS_KEYS.DUE_DATES] = cloneDeep(
        restoredIntervalsAndDates[FILTERS_KEYS.DUE_DATES]
      )

      restoredFiltersValues[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES] = cloneDeep(
        restoredIntervalsAndDates[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES]
      )

      restoredFiltersValues[FILTERS_KEYS.LAST_COMMENT] = cloneDeep(
        restoreRelativeValues({
          restoredValue: restoredFiltersValues[FILTERS_KEYS.LAST_COMMENT],
          defaultValue: FILTER_DEFAULT_VALUES[FILTERS_KEYS.LAST_COMMENT],
          keyProp: FILTERS_KEYS.LAST_COMMENT
        })
      )

      Object.entries(restoredFiltersValues).forEach(([key, value]) => {
        if (key === FILTERS_KEYS.EXPANDED_ITEMS || key === FILTERS_KEYS.FILTER_COLLAPSED_ITEMS) {
          this.listState.filtersValues[key] = value.reduce((acc, val) => {
            acc[val] = true
            return acc
          }, {})
        } else {
          this.listState.filtersValues[key] = value
        }
      })

      const restoredCustomFieldsFiltersValues = restoreFilterValue(
        this.$route,
        CUSTOM_FIELDS_FILTERS_QUERY_KEY,
        DEFAULT_CUSTOM_FIELD_FILTERS_VALUE
      )

      const customFieldsFiltersValues = this.restoreCustomFieldFiltersValuesFromQuery({
        restoredCustomFieldsFiltersValues
      })

      this.listState.customFieldsFiltersValues = {
        ...customFieldsFiltersValues
      }

      this.updateObjectivesRequestPayload()
      // restored filters values should be saved in route as well,
      // but it doesn't need to be done explicitly here, because they
      // are saved on loading initial data automatically.
      this.filtersRestored = true
    },

    restoreCustomFieldFiltersValuesFromQuery({
      restoredCustomFieldsFiltersValues = DEFAULT_CUSTOM_FIELD_FILTERS_VALUE
    } = {}) {
      return restoredCustomFieldsFiltersValues.reduce((acc, val) => {
        const [id, restoredValue] = val
        const fieldId = Number(id)
        if (this.currentWorkspaceCustomFieldsIds.includes(fieldId)) {
          const { typeId } = this.fieldById({ fieldId })

          const defaultValue = cloneDeep(CUSTOM_FIELD_FILTER_DEFAULT_VALUES[typeId])

          if (typeId === ALL_CUSTOM_FIELDS.getTypeIds().DATE) {
            return {
              ...acc,
              [fieldId]: restoreDates({
                dates: restoredValue,
                defaultValue,
                dateProp: typeId
              })
            }
          }
          // else if (
          //   typeId === ALL_CUSTOM_FIELDS.getTypeIds().MONEY ||
          //   typeId === ALL_CUSTOM_FIELDS.getTypeIds().NUMBER
          // ) {
          //   return {
          //     ...acc,
          //     [fieldId]: restoreNumberFilter({
          //       restoredValue,
          //       defaultValue
          //     })
          //   }
          // }
          else {
            const resolvedValue = !Array.isArray(restoredValue) ? defaultValue : restoredValue
            return {
              ...acc,
              [fieldId]: resolvedValue
            }
          }
        } else {
          return acc
        }
      }, {})
    },

    async calculateOtWrapperHeight() {
      await this.$nextTick()
      if (!this.hideToolbar) {
        const { otWrapper } = this.$refs
        await this.setOtWrapperHeight(otWrapper.offsetHeight)
      }
    },

    onFilterParameterUpdate({ key, value }) {
      // start loading before value changing to avoid toolbar blinking and other
      // unwanted effects
      this.isLoading = true
      this.listState.filtersValues[key] = value
      this.tryToUpdateOkrElements({ resetItemsImmediately: true })
    },

    onCustomFieldFilterParameterUpdate({ fieldId, value }) {
      // start loading before value changing to avoid toolbar blinking and other
      // unwanted effects
      this.isLoading = true
      this.listState.customFieldsFiltersValues[fieldId] = value
      this.tryToUpdateOkrElements({ resetItemsImmediately: true })
    },

    onFilterValueReset(key, updateElements = true) {
      this.listState.filtersValues[key] = cloneDeep(FILTER_DEFAULT_VALUES[key])
      if (updateElements) {
        this.tryToUpdateOkrElements({ resetItemsImmediately: true })
      }
    },

    getSavebleFilters() {
      const { filtersValues, customFieldsFiltersValues } = this.listState

      const intervalsFilter = {
        [FILTERS_KEYS.INTERVAL_IDS]: filtersValues[FILTERS_KEYS.INTERVAL_IDS]
      }

      const expandedCollapsedItemsFilters = {
        [FILTERS_KEYS.EXPANDED_ITEMS]: getExpandedItemList(
          filtersValues[FILTERS_KEYS.EXPANDED_ITEMS]
        ),

        [FILTERS_KEYS.FILTER_COLLAPSED_ITEMS]: getExpandedItemList(
          filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS]
        )
      }

      const sortingFilters = {
        [FILTERS_KEYS.SORT_ORDER]: filtersValues[FILTERS_KEYS.SORT_ORDER],
        [FILTERS_KEYS.SORT_CHILDREN]: filtersValues[FILTERS_KEYS.SORT_CHILDREN]
      }

      if (
        this.filterPreset === FILTER_PRESETS.MY_OKR ||
        this.filterPreset === FILTER_PRESETS.MY_GROUP
      ) {
        return {
          ...intervalsFilter,
          ...sortingFilters,
          ...expandedCollapsedItemsFilters
        }
      } else {
        return {
          ...intervalsFilter,
          [FILTERS_KEYS.ASSIGNEE_IDS]: filtersValues[FILTERS_KEYS.ASSIGNEE_IDS],
          [FILTERS_KEYS.GROUP_IDS]: filtersValues[FILTERS_KEYS.GROUP_IDS],
          [FILTERS_KEYS.GRADE_TYPES]: filtersValues[FILTERS_KEYS.GRADE_TYPES],
          [FILTERS_KEYS.OKR_TYPE_IDS]: filtersValues[FILTERS_KEYS.OKR_TYPE_IDS],
          [FILTERS_KEYS.DUE_DATES]: filtersValues[FILTERS_KEYS.DUE_DATES],
          [FILTERS_KEYS.START_DATES]: filtersValues[FILTERS_KEYS.START_DATES],
          [FILTERS_KEYS.LAST_GRADE_UPDATE_DATES]:
            filtersValues[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES],

          [FILTERS_KEYS.LAST_COMMENT]: filtersValues[FILTERS_KEYS.LAST_COMMENT],

          [FILTERS_KEYS.LABEL_IDS]: filtersValues[FILTERS_KEYS.LABEL_IDS],
          [FILTERS_KEYS.STAKEHOLDER_IDS]: filtersValues[FILTERS_KEYS.STAKEHOLDER_IDS],
          ...sortingFilters,
          ...expandedCollapsedItemsFilters,
          [CUSTOM_FIELDS_FILTERS_QUERY_KEY]: getCustomFieldFiltersQuery({
            customFieldsFiltersValues
          })
        }
      }
    },

    /**
     * @public
     */
    onExpandAll() {
      this.listState.filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS] = {}
      this.updateObjectives({
        keepObjectivesSize: true,
        parameters: {
          itemsToOpen: null,
          expandAll: true
        }
      })
    },

    /**
     * @public
     */
    onCollapseAll() {
      if (this.view === PAGES.OKREXPLORER) {
        clearExplorerUniqueIds()
      }
      const filtersValues = this.listState.filtersValues
      filtersValues[FILTERS_KEYS.EXPANDED_ITEMS] = {}
      if (!this.listState.haveAllFiltersDefaultValues) {
        const expandableObjectivesOnFirstLevel = this.listState.objectives
          .map(elId => this.listState.okrElements[elId])
          .filter(objective =>
            okrElementIsExpandable({
              element: objective
            })
          )
        const expandableKrsOnFirstLevel = this.listState.krs
          .map(elId => this.listState.okrElements[elId])
          .filter(kr =>
            okrElementIsExpandable({
              element: kr
            })
          )
        const collapsedElementIds = expandableObjectivesOnFirstLevel
          .concat(expandableKrsOnFirstLevel)
          .reduce((acc, val) => {
            acc[`${val.id}-0`] = true
            return acc
          }, {})
        filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS] = collapsedElementIds
      }

      // no data update and autosave, save new value
      saveFilterValues(
        this.$router,
        this.$route,
        [FILTERS_KEYS.EXPANDED_ITEMS, FILTERS_KEYS.FILTER_COLLAPSED_ITEMS],
        [
          getExpandedItemList(filtersValues[FILTERS_KEYS.EXPANDED_ITEMS]),
          getExpandedItemList(filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS])
        ]
      )
    },

    async onExportToExcel() {
      this.excelExportProceed = true
      tracker.logEvent('Data exported', {
        category: EVENT_CATEGORIES.EXPORT
      })

      const objectivesApi = new ObjectivesApiHandler()
      let response = null

      const customFieldsFiltersPayload = createCustomFieldsFiltersPayload({
        customFields: this.currentWorkspaceCustomFields,
        filtersValues: this.listState.customFieldsFiltersValues
      })

      try {
        response = await objectivesApi.getExcelExport({
          ...this.listState.requestParameters,
          offset: 0,
          limit: 1000, // temporary
          workspaceId: this.workspaceId,
          customFields: customFieldsFiltersPayload
        })

        this.excelExportProceed = false

        // checking response.data.size because if size is 0 that means report will send to email
        if (response.data.size > 0) {
          showNotify({
            title: this.$t('notifications.excel_export_file')
          })
          FileSaver.saveAs(response.data, response.filename)
        } else {
          showNotify({
            title: this.$t('notifications.excel_export_email')
          })
        }
      } catch (error) {
        this.excelExportProceed = false
        // this is a way how to get error message from response-type Blob
        const content = await error.response.data.text()
        showNotify({
          title: this.$t('notifications.error'),
          content,
          type: NOTIFICATION_TYPES.ERROR
        })
      }
    },

    tryToUpdateOkrElements(parameters) {
      if (
        this.toolbarInitialDataLoaded &&
        this.customFieldFiltersInitialDataLoaded &&
        this.initialObjectivesAreLoaded
      ) {
        this.updateObjectivesRequestPayload()
        this.updateOkrElements({
          parameters: { expandAll: this.onboarding },
          ...parameters
        })
        this.initialObjectivesAreLoaded = true
      }
    },

    /** @public */
    resetSearchCriteria() {
      this.$refs.toolbar?.onResetFilters()
      this.searchString = ''
    },

    getLastCommentPayload() {
      const [selectedValue, inputValue] = this.listState.filtersValues[FILTERS_KEYS.LAST_COMMENT]

      let lastCommentPayload = {
        [LAST_COMMENT_UPDATE_DATE_FROM]: null,
        [LAST_COMMENT_UPDATE_DATE_TO]: null,
        [SHOULD_BE_COMMENTED]: null
      }

      const lastCommentUpdateDate = dayjs()
        .utc()
        .subtract(inputValue || 1, DAYS_UNIT)

      if (selectedValue === LAST_COMMENT_UPDATE_DATE_FROM) {
        lastCommentPayload[LAST_COMMENT_UPDATE_DATE_FROM] = lastCommentUpdateDate
          .startOf(DAY_UNIT)
          .toDate()
      } else if (selectedValue === LAST_COMMENT_UPDATE_DATE_TO) {
        lastCommentPayload[LAST_COMMENT_UPDATE_DATE_TO] = lastCommentUpdateDate
          .endOf(DAY_UNIT)
          .toDate()
      } else if (selectedValue === SHOULD_BE_COMMENTED) {
        lastCommentPayload[SHOULD_BE_COMMENTED] = true
      } else if (selectedValue === NEVER_COMMENTED) {
        lastCommentPayload[SHOULD_BE_COMMENTED] = false
      }

      return lastCommentPayload
    },

    updateObjectivesRequestPayload() {
      let order = [OBJECTIVE_SORT_OPTIONS.ORDER_ASC]
      let childOrder = [OBJECTIVE_SORT_OPTIONS.ORDER_ASC]
      // overwrite default values for explorer
      if (this.view === PAGES.OKREXPLORER) {
        order = [OBJECTIVE_SORT_OPTIONS.LEVEL_ASC, OBJECTIVE_SORT_OPTIONS.START_DATE_ASC]
      }

      if (this.listState.filtersValues[FILTERS_KEYS.SORT_ORDER].length > 0) {
        order = this.listState.filtersValues[FILTERS_KEYS.SORT_ORDER]
        if (this.listState.filtersValues[FILTERS_KEYS.SORT_CHILDREN]) {
          childOrder = [...order]
        }
      }

      const filtersValues = this.listState.filtersValues
      const dueDates = filtersValues[FILTERS_KEYS.DUE_DATES]
      const startDates = filtersValues[FILTERS_KEYS.START_DATES]
      const lastGradeUpdateDates = filtersValues[FILTERS_KEYS.LAST_GRADE_UPDATE_DATES]

      const lastCommentPayload = this.getLastCommentPayload()

      const customFieldsFiltersPayload = createCustomFieldsFiltersPayload({
        customFields: this.currentWorkspaceCustomFields,
        filtersValues: this.listState.customFieldsFiltersValues
      })

      const defaultPayload = {
        workspaceId: this.workspaceId,
        dueDateFrom: dueDates ? dueDates[0] : UNSELECTED_DATE,
        dueDateTo: dueDates ? dueDates[1] : UNSELECTED_DATE,
        startDateFrom: startDates ? startDates[0] : UNSELECTED_DATE,
        startDateTo: startDates ? startDates[1] : UNSELECTED_DATE,
        lastGradeUpdateDateFrom: lastGradeUpdateDates ? lastGradeUpdateDates[0] : UNSELECTED_DATE,
        lastGradeUpdateDateTo: lastGradeUpdateDates ? lastGradeUpdateDates[1] : UNSELECTED_DATE,
        levelIds: getSelectWithSelectAllApiParameter(filtersValues[FILTERS_KEYS.OKR_TYPE_IDS]),
        ownerIds: getSelectWithSelectAllApiParameter(filtersValues[FILTERS_KEYS.ASSIGNEE_IDS]),
        labelIds: getSelectWithSelectAllApiParameter(filtersValues[FILTERS_KEYS.LABEL_IDS]),
        stakeholderIds: getSelectWithSelectAllApiParameter(
          filtersValues[FILTERS_KEYS.STAKEHOLDER_IDS]
        ),

        groupIds: getSelectWithSelectAllApiParameter(filtersValues[FILTERS_KEYS.GROUP_IDS]),
        gradeTypes: getSelectWithSelectAllApiParameter(filtersValues[FILTERS_KEYS.GRADE_TYPES]),
        typeIds: null,
        intervalIds: filtersValues[FILTERS_KEYS.INTERVAL_IDS],
        order,
        // to keep order consistent in depth. For first level it is overwritten below
        childOrder,
        searchType: this.tableParameters.searchType,
        searchString: this.tableParameters.searchString,
        offset: (this.listState.page - 1) * 50,
        limit: 50,
        expandedItems: filtersValues[FILTERS_KEYS.EXPANDED_ITEMS],
        ...lastCommentPayload,
        customFields: customFieldsFiltersPayload
      }

      const { isTimeRangeSelected } = isTimeRangeSelectedInsteadOfInterval({
        startDates: [defaultPayload.startDateFrom, defaultPayload.startDateTo],
        dueDates: [defaultPayload.dueDateFrom, defaultPayload.dueDateTo],
        intervals: defaultPayload.intervalIds
      })

      if (isTimeRangeSelected) {
        defaultPayload.intervalIds = null
      }

      const sharedPayload = isEmpty(this.filterValues)
        ? defaultPayload
        : { ...defaultPayload, ...this.filterValues }
      this.listState.requestParameters = {
        ...sharedPayload
      }

      return sharedPayload
    },

    async onEditElement(elementData) {
      const { typeId, id, children } = elementData
      this.showObjectiveModal = true
      await this.$nextTick()
      this.$refs.objectiveModal.openForm(OKR_TYPE_TO_FORM_VIEW[typeId], {
        typeId,
        id,
        workspaceId: this.workspaceId
      })
      if (children) {
        const { typeId, id } = children
        this.$refs.objectiveModal.openChildForm(OKR_TYPE_TO_FORM_VIEW[typeId], {
          typeId,
          id,
          workspaceId: this.workspaceId
        })
      }
    },

    infiniteHandler($state) {
      if (this.additionalParameters?.resetItemsImmediately) {
        this.resetItems()
        this.additionalParameters.resetItemsImmediately = false
      }

      this.listState.dataLoaded = false
      let expandAll = false
      let resetItems = false
      let parameters = {}
      if (this.additionalParameters) {
        parameters = { ...this.additionalParameters.parameters }
        if ('parameters' in this.additionalParameters) {
          expandAll = this.additionalParameters.parameters.expandAll || false
        }

        if ('resetItems' in this.additionalParameters) {
          resetItems = this.additionalParameters.resetItems
        }
      }

      const { CancelToken } = axios
      this.getObjectivesAxiosSource = CancelToken.source()
      const payload = {
        ...this.listState.requestParameters,
        offset: (this.listState.page - 1) * 50,
        // parameters can contain overwritten offset, it should be after default value
        ...parameters
      }

      // fetch with expandAll parameter, but don't expand all items automatically
      // it's needed to avoid loading children on expanding, instead having them
      // preloaded
      if (this.fetchExpandAll) {
        payload.expandAll = true
      }

      const expandedItems = []
      getExpandedItemList(this.listState.requestParameters.expandedItems).forEach(item => {
        try {
          expandedItems.push(item.split('-')[0])
        } catch {
          console.error(`invalid expand item: ${item}`)
        }
      })
      payload.itemsToOpen = expandedItems
      delete payload.expandedItems

      const objectivesApi = new ObjectivesApiHandler()
      this.getObjectivesPromise = objectivesApi
        .getObjectives(payload, this.getObjectivesAxiosSource.token)
        .then(response => {
          if (resetItems) {
            this.resetItems()
          }

          if (
            expandAll ||
            (!this.listState.haveAllFiltersDefaultValues &&
              [PAGES.ROADMAP, PAGES.ALIGNMENT].includes(this.listState.view))
          ) {
            // new value is saved with other filters after finishing data loading
            this.listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS] = this.expandItemsRecursively(
              response,
              0,
              !expandAll,
              expandAll
            )
          }

          let newData = false
          if (this.listState.objectives.length === 0 && this.listState.krs.length === 0) {
            // load of new list, not additional data
            newData = true
          }

          const { objectives, krs } = response.reduce(
            (acc, currentItem) => {
              if ([OBJECTIVE_TYPES.KR, OBJECTIVE_TYPES.TASK].includes(currentItem.typeId)) {
                acc.krs.push(currentItem.uniqueId)
              } else {
                acc.objectives.push(currentItem.uniqueId)
              }
              return acc
            },
            { objectives: [], krs: [] }
          )

          if (newData) {
            let showKrs = true
            const defaultTypesAreSelected = this.listState.requestParameters.levelIds === null
            if (
              this.hideableKrs &&
              defaultTypesAreSelected &&
              (this.listState.objectives.length > 0 || objectives.length > 0)
            ) {
              showKrs = false
            }
            this.showKrs = showKrs
          }

          this.saveOkrElementsAndChildren(response)
          this.listState.objectives = this.listState.objectives.concat(objectives)
          this.listState.krs = this.listState.krs.concat(krs)

          if (response.length === 0) {
            $state.complete()
            this.state = LIST_STATE.COMPLETED
          } else {
            $state.loaded()
            if (response.length < 50 || this.stopScrollLoaderAfterFirstLoad) {
              $state.complete()
              this.state = LIST_STATE.COMPLETED
            } else {
              this.listState.page += 1
            }
          }

          if (this.searchString === '') {
            this.objectivesWithEmptySearchExist = response.length > 0
          }

          this.listState.noResults = payload.offset === 0 && response.length === 0
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            return error
          }
          if (checkResponseStatus({ error, status: RESPONSE_STATUSES.FORBIDDEN })) {
            this.$emit('show-error')
          }
          handleError({ error })
        })
        .then(response => {
          if (!axios.isCancel(response)) {
            this.listState.isInitialDataLoaded = true
            if (!this.initialObjectivesAreLoaded) {
              this.initialObjectivesAreLoaded = true
            }
            this.additionalParameters = {}
            this.getObjectivesAxiosSource = null
            this.listState.dataLoaded = true

            if (this.blinkObjectiveIds.length > 0) {
              this.listState.blinkObjectiveIds = this.blinkObjectiveIds
            }

            this.saveFilters()

            this.$emit('initial-data-loaded')
          }
        })
    },

    saveFilters() {
      if (!this.hideToolbar) {
        const filters = this.getSavebleFilters()
        let queryParameters = { ...this.$route.query }
        Object.entries(filters).forEach(([key, value]) => {
          const newValue = JSON.stringify(value)
          if (
            [FILTERS_KEYS.EXPANDED_ITEMS, FILTERS_KEYS.FILTER_COLLAPSED_ITEMS].includes(key) &&
            getExpandedItemList(value) === JSON.stringify(FILTER_DEFAULT_VALUES[key])
          ) {
            delete queryParameters[key]
          } else {
            queryParameters[key] = newValue
          }
        })

        Object.entries(filters).forEach(([key, value]) => {
          const resolvedKey = FILTER_LS_KEYS[key] || key
          updateStorageByKey(resolvedKey, value)
        })

        replaceQueryParameters(this.$router, this.$route, queryParameters)
      }
    },

    resetItems() {
      this.listState.krs = []
      this.listState.objectives = []
      this.listState.okrElements = {}
      this.listState.okrElementChildren = {}
    },

    saveOkrElementsAndChildren(elements) {
      elements.forEach(element => {
        const oldElement = this.listState.okrElements[element.uniqueId]
        // when okrElement exists on few levels, weight and weightMultiplier can
        // appear not always, but only in some of them. Save independent from level
        this.listState.okrElements[element.uniqueId] = {
          ...element,
          weight: element.weight === 0 ? 0 : oldElement?.weight || element.weight,
          weightMultiplier:
            element.weightMultiplier === 0
              ? 0
              : oldElement?.weightMultiplier || element.weightMultiplier
        }
        this.listState.okrElementChildren[element.uniqueId] = element.childElements.map(
          childElement => childElement.uniqueId
        )
        if (element.childElements.length > 0) {
          this.saveOkrElementsAndChildren(element.childElements)
        }
      })
    },

    expandItemsRecursively(items, depth = 0, excludeFilterCollapsed = false, expandAll = false) {
      return items.reduce((acc, val) => {
        const uniqueId = `${val.id}-${depth}`
        if (
          excludeFilterCollapsed &&
          this.listState.filtersValues[FILTERS_KEYS.FILTER_COLLAPSED_ITEMS][uniqueId]
        ) {
          return acc
        }
        if (currentUserCanReadObjective(val) && val.childElements.length > 0) {
          // if we have filtered child items and already click on "eye" button, we should expand this item
          const isShowFilteredItems = this.listState.showFilteredChildrenObjectives[val.id]
          const resolvedIsShowFilteredItems = isUndefined(isShowFilteredItems)
            ? true
            : isShowFilteredItems
          const isOkrExplorer = this.view === PAGES.OKREXPLORER

          // in OKR Explorer we always have 0 visible count
          // so we should check only is item already expanded or eye button clicked
          const mainCondition = isOkrExplorer || val.visibleCount > 0
          acc = {
            ...acc,
            // if we click an expand all button
            // we should expand all tree
            // but if all children of element are filtered we should not expand it
            // so we check on item visible count and is item already expanded or eye button clicked
            [uniqueId]: expandAll
              ? mainCondition || resolvedIsShowFilteredItems
              : expandAll || val.expanded || resolvedIsShowFilteredItems, // true @FIXME: check if it is correct; I hope it is fix but not sure, regression testing will show it
            ...this.expandItemsRecursively(
              val.childElements,
              depth + 1,
              excludeFilterCollapsed,
              expandAll
            )
          }
        }
        return acc
      }, {})
    },

    updateOkrElements({
      keepObjectivesSize = false,
      resetItemsImmediately = false,
      blinkObjectiveIds = [],
      parameters = {}
    } = {}) {
      this.blinkObjectiveIds = blinkObjectiveIds

      // cancel previous request if it is not finished
      if (this.getObjectivesAxiosSource) {
        this.getObjectivesAxiosSource.cancel()
        this.getObjectivesAxiosSource = null
      }

      const additionalParameters = {
        keepObjectivesSize,
        parameters,
        resetItems: true,
        resetItemsImmediately: false
      }

      if (resetItemsImmediately) {
        additionalParameters.resetItemsImmediately = true
        additionalParameters.resetItems = false
      }

      const itemsToOpen = new Set()
      getExpandedItemList(this.listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS]).forEach(i => {
        // this data is gotten from query params, validate it
        try {
          if (isString(i)) {
            const splittedItem = i.split('-')[0]
            const number = parseInt(splittedItem, 10)
            if (!Number.isNaN(number) && number > 0) {
              itemsToOpen.add(number)
            }
          }
        } catch {
          console.error(`Invalid data "${i}" in expandedItems`)
        }
      })
      if (additionalParameters.keepObjectivesSize) {
        if (this.state === LIST_STATE.READY_TO_LOAD) {
          // loading of next page was scheduled, back to current to relad the same items
          this.listState.page -= 1
        }
        additionalParameters.parameters.offset = 0
        additionalParameters.parameters.limit = this.listState.page * 50

        if (!('itemsToOpen' in additionalParameters.parameters)) {
          additionalParameters.parameters.itemsToOpen = [...itemsToOpen]
        }
      } else {
        this.state = LIST_STATE.READY_TO_LOAD
        this.listState.page = 1
        additionalParameters.keepObjectivesSize = false
      }

      // add itemsToOpen but only on first page
      if (this.listState.page === 1 && !('itemsToOpen' in additionalParameters.parameters)) {
        additionalParameters.parameters.itemsToOpen = [...itemsToOpen]
      }

      this.additionalParameters = additionalParameters
      this.$emit('data-request', parameters.expandAll)
    },

    /** @public */
    loadMore(data) {
      this.infiniteHandler(data)
    },

    resetFilters() {
      RESETTABLE_FILTERS_KEYS.forEach(key => {
        this.onFilterValueReset(key, false)
      })
      this.onResetCustomFiledFilters({ updateElements: false })
    },

    async filterPresetUpdated() {
      this.resetFilters()
      if (this.filterPreset === FILTER_PRESETS.NONE) {
        this.restoreFilterValues()
      }

      if (this.filterPreset === FILTER_PRESETS.MY_OKR) {
        this.setAssigneeMe()
      } else if (this.filterPreset === FILTER_PRESETS.MY_GROUP) {
        await this.$refs.toolbar?.fetchGroups(true)
        this.setGroupMy()
      }

      this.tryToUpdateOkrElements({ resetItemsImmediately: true })
    },

    setAssigneeMe() {
      const currentUserId = this.userData?.userAccountId
      if (currentUserId) {
        this.listState.filtersValues[FILTERS_KEYS.ASSIGNEE_IDS] = [currentUserId]
      }
    },

    setGroupMy() {
      if (
        !isEmpty(this.myGroup) &&
        !isEqual(this.myGroup, FILTER_DEFAULT_VALUES[FILTERS_KEYS.GROUP_IDS])
      ) {
        this.listState.filtersValues[FILTERS_KEYS.GROUP_IDS] = this.myGroup
      }
    },

    onResetFilter({ type, query = {} }) {
      this.resetFilters()

      const [action] = [
        type === RESET_FILTER_TYPES.MY_OKR && this.setAssigneeMe,
        type === RESET_FILTER_TYPES.MY_GROUP && this.setGroupMy,
        type === RESET_FILTER_TYPES.CUSTOM && this.restoreSavedFilters
      ].filter(Boolean)

      action(query)

      this.tryToUpdateOkrElements({ resetItemsImmediately: true })
    },

    onToolbarInitialDataLoaded() {
      this.toolbarInitialDataLoaded = true
    },

    onCustomFieldFiltersInitialDataLoaded() {
      this.customFieldFiltersInitialDataLoaded = true
    },

    onResetCustomFiledFilters({ updateElements = false } = {}) {
      this.listState.customFieldsFiltersValues = {}

      if (updateElements) {
        this.tryToUpdateOkrElements({ resetItemsImmediately: true })
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.olp-MenuCreateObjective {
  color: inherit;

  &:not(&-active) {
    @media (any-hover: hover) {
      &:hover {
        background-color: rgba($white, 0.15);
      }
    }
  }

  &-active {
    background-color: $white;
    color: $primary-color-next;
  }
}

.ot-Wrapper {
  &-roadmap {
    position: relative;
    // box-shadow: inset 0 -1px 0 $grey-medium;
    &:after {
      content: '';
      position: absolute;
      left: $page-left-padding;
      bottom: 0;
      width: calc(100% - #{$page-left-padding});
      height: 1px;
      background: $grey-2-next;
    }
  }
}
</style>
