<template>
  <AppModalWithConfirmation
    :confirm-close="areDataChanged"
    :hide-footer="currentStep === STEPS.RATE"
    :show="show"
    :size="500"
    :title="modalTitle"
    hide-hero
    @on-close="onClose"
    @on-confirm="onConfirm"
  >
    <FormFieldNext v-if="currentStep === STEPS.MESSAGE" :label="t('feedback.modal.message_label')">
      <AppTextarea v-model="message" :max-length="MAX_CHARACTERS" style-type="primary-next" />
      <div class="fm-Counter">{{ MAX_CHARACTERS - message.length }}</div>
    </FormFieldNext>

    <div v-if="currentStep === STEPS.RATE" class="fm-Rate">
      <AppTitle :level="3" class="fm-StepTitle" disable-margin>
        {{ rateStepTitle }}
      </AppTitle>

      <div v-if="isShowReviewWindow" class="fm-StepDescription">
        {{ t('feedback.modal.review_description') }}
      </div>

      <StarRating v-else v-model="rating" />

      <AppButton class="fm-ConfirmButton" @click="onConfirm">
        {{ rateStepButtonText }}
      </AppButton>
    </div>
  </AppModalWithConfirmation>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { openLink } from '@/utils/external-link-helper'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppTextarea from '@/components/ui/AppTextarea/AppTextarea'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import StarRating from '@/components/ui/StarRating/StarRating'

const MAX_CHARACTERS = 1024

defineOptions({
  name: 'FeedbackModal'
})

defineProps({
  show: {
    type: Boolean
  }
})

const emit = defineEmits({
  close: null
})

const { t } = useI18n()

const onClose = () => {
  emit('close')
}

const STEPS = {
  MESSAGE: 'MESSAGE',
  RATE: 'RATE'
}

const currentStep = ref(STEPS.MESSAGE)

const modalTitle = computed(() => {
  if (currentStep.value === STEPS.RATE) {
    return null
  }

  return t('feedback.modal.title')
})

const message = ref('')

const areDataChanged = computed(() => {
  if (currentStep.value === STEPS.MESSAGE) {
    return !!message.value.trim()
  }

  if (currentStep.value === STEPS.RATE && !isShowReviewWindow.value) {
    return !!rating.value
  }

  return false
})

const isShowReviewWindow = ref(false)

const rateStepTitle = computed(() => {
  return isShowReviewWindow.value
    ? t('feedback.modal.review_title')
    : t('feedback.modal.rate_title')
})

const rateStepButtonText = computed(() => {
  return isShowReviewWindow.value ? t('action.leave_review') : t('action.confirm')
})

const onConfirm = () => {
  if (currentStep.value === STEPS.MESSAGE) {
    if (areDataChanged.value) {
      tracker.logEvent('feedback sent', {
        category: EVENT_CATEGORIES.FEEDBACK,
        message: message.value
      })
    }

    currentStep.value = STEPS.RATE

    return
  }

  if (currentStep.value === STEPS.RATE) {
    if (isShowReviewWindow.value) {
      openLink('https://gtnr.io/5vqXHIU4G')
      onClose()
      return
    }

    if (rating.value >= 4) {
      isShowReviewWindow.value = true
    } else {
      onClose()
    }
  }
}

const rating = ref(0)
</script>

<style lang="scss" scoped>
.fm-Counter {
  margin-left: auto;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  color: $grey-1-next;
}

.fm-StepTitle {
  text-align: center;
  white-space: normal;
  line-height: 28px;
}

.fm-StepDescription {
  text-align: center;
  font-size: $fs-14;
  line-height: 20px;
}

.fm-Rate {
  display: grid;
  gap: 20px;
  justify-content: center;
  padding-bottom: 40px;
}

.fm-ConfirmButton {
  margin: 20px auto 0;
}
</style>
