<template>
  <AppTable
    :columns="COLUMNS"
    :data="tableData"
    :hover-row="tableHoverRow"
    no-border
    offset-left="var(--page-left-padding)"
    offset-right="var(--page-right-padding)"
    sticky-header
    type="primary-next"
  >
    <template #header-cell="{ column }">
      <template v-if="column.title">
        <slot :name="`header-cell-${column.key}-title`">
          {{ column.title }}
        </slot>
      </template>
    </template>
    <template #cell="{ columnKey, item, index }">
      <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
        <ObjectiveStatus
          :status="item"
          type="default-next"
          @click="onMenuActionsClick(ACTIONS.RENAME, item)"
        />
      </template>

      <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
        <div class="sst-Actions">
          <DropdownMenu
            :items="getMenuActions(item)"
            :offset="[0, 0]"
            preferred-position="bottom-end"
            type="default-next"
            @close="tableHoverRow = -1"
            @open="tableHoverRow = index"
            @item-click="onMenuActionsClick($event, item)"
          >
            <template #activator>
              <AppButton icon="more-next" size="sm" type="tertiary-next" />
            </template>
          </DropdownMenu>
        </div>
      </template>
    </template>

    <template #footer>
      <slot name="footer" />
    </template>
  </AppTable>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ALL_STATUS_NAMES } from '@/utils/status-names-factory'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import ObjectiveStatus from '@/components/objectives/ObjectiveStatus'
import AppButton from '@/components/ui/AppButton/AppButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import AppTable from '@/components/ui/Table/AppTable'

defineOptions({
  name: 'StatusSettingsTable'
})

const { t } = useI18n()

const ACTIONS = {
  RENAME: 'rename',
  RESTORE_DEFAULT: 'restore-default'
}

const DROPDOWN_MENU_ACTIONS = [
  {
    name: ACTIONS.RENAME,
    title: 'action.rename',
    icon: 'rename'
  },
  {
    name: ACTIONS.RESTORE_DEFAULT,
    title: 'dropdown.restore_default',
    icon: 'restore',
    allowedOnlyForRenamed: true
  }
]

const COLUMNS = [
  {
    title: t('statuses.progress_statuses'),
    key: TABLE_COLUMNS_KEYS.NAME,
    width: 'auto'
  },
  {
    key: TABLE_COLUMNS_KEYS.ACTION,
    width: 24
  }
]

defineProps({
  tableData: {
    type: Array,
    required: true
  }
})

const tableHoverRow = ref(-1)

const emit = defineEmits({
  'menu-action-click': ({ action, statusId }) => ({ action, statusId })
})
const getMenuActions = statusId => {
  const isStatusRenamed = ALL_STATUS_NAMES.renamedState[statusId]
  return DROPDOWN_MENU_ACTIONS.filter(action => {
    if (action.allowedOnlyForRenamed) {
      return isStatusRenamed
    }
    return true
  })
}

const onMenuActionsClick = (action, statusId) => {
  emit('menu-action-click', { action, statusId })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.sst-Actions {
  @extend %app-table-hidden-items;
}
</style>
