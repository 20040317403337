<template>
  <div
    :class="{ 'pc-PaginationBlock-without-nav': totalPage <= MIN_PAGE_NUMBER }"
    class="pc-PaginationBlock"
  >
    <div class="pc-DisplayOptions">
      <div v-if="!hideEntriesSelect">
        <AppSelect
          :model-value="itemsOnPage"
          :offset="[0, 0]"
          :options="PAGINATION_OPTIONS"
          theme="no-shadow-next light"
          type="secondary-next"
          @update:model-value="$emit('update:itemsOnPage', $event)"
        >
          <template #button-content="{ active, option }">
            <SimpleSelectTrigger :opened="active">
              <span class="pc-Entries">
                {{ option.value }}
                {{ $t('pagination.entries') }}
              </span>
            </SimpleSelectTrigger>
          </template>
        </AppSelect>
      </div>
    </div>
    <span class="pc-PaginationBlock_Description">
      {{ paginationCurrent }}
      {{ $t('pagination.of') }}
      {{ itemsCount }}
    </span>
    <div v-if="totalPage > MIN_PAGE_NUMBER" class="pc-PaginationWrapper">
      <AppButton
        :class="{ 'pc-Page-disable': currentPage === MIN_PAGE_NUMBER }"
        :icon="icons.prev"
        class="pc-Page"
        remove-padding
        size="md"
        type="none"
        @click="goToPrevious"
      />
      <ul class="pc-Pagination">
        <li
          v-for="pageNumber in filteredTotalPage"
          :key="pageNumber"
          :class="getPageNumberClasses(pageNumber)"
          @click="onPaginationButtonClick(pageNumber)"
        >
          <span class="pc-PageNumber">
            {{ pageNumber }}
          </span>
        </li>
      </ul>
      <AppButton
        :class="{ 'pc-Page-disable': currentPage === totalPage }"
        :icon="icons.next"
        class="pc-Page"
        remove-padding
        size="md"
        type="none"
        @click="goToNext"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { DEFAULT_PAGINATION_OPTIONS } from '@/utils/pagination'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import SimpleSelectTrigger from '@/components/ui/AppSelect/TriggerButtons/SimpleSelectTrigger'

const PAGINATION_OPTIONS = [...DEFAULT_PAGINATION_OPTIONS].map(option => {
  return {
    value: option,
    label: String(option)
  }
})

const DEFAULT_PAGE_COUNT = 5
const MIN_PAGE_NUMBER = 1
const SECOND_PAGE = 2
const THIRD_PAGE = 3

export default defineComponent({
  name: 'AppPagination',

  components: {
    SimpleSelectTrigger,
    AppButton,
    AppSelect
  },

  props: {
    itemsOnPage: {
      type: Number,
      default: 10
    },

    totalPage: {
      type: Number,
      default: 1
    },

    currentPage: {
      type: Number,
      default: 1
    },

    itemsCount: {
      type: Number,
      default: 0
    },

    itemsShown: {
      type: Number,
      default: 0
    },

    hideEntriesSelect: {
      type: Boolean
    },

    icons: {
      type: Object,
      default: () => ({
        prev: 'arrow-left-black-next',
        next: 'arrow-right-black-next'
      })
    }
  },

  emits: { 'update:itemsOnPage': null, 'update:currentPage': null },

  computed: {
    PAGINATION_OPTIONS: () => PAGINATION_OPTIONS,
    MIN_PAGE_NUMBER: () => MIN_PAGE_NUMBER,
    filteredTotalPage() {
      let result = []
      const pages = [...Array(this.totalPage).keys()].map(i => (i += 1))
      result = pages.filter(
        item =>
          Math.abs(item - this.currentPage) < SECOND_PAGE ||
          item === this.totalPage ||
          item === MIN_PAGE_NUMBER ||
          (this.currentPage === MIN_PAGE_NUMBER && item === THIRD_PAGE) ||
          (this.currentPage >= this.totalPage - SECOND_PAGE && item >= this.totalPage - THIRD_PAGE)
      )
      return result
    },

    getFirstElementPageNumber() {
      return (this.currentPage - 1) * this.itemsOnPage + 1
    },

    paginationCurrent() {
      const lastValue = this.currentPage * this.itemsShown
      const firstValue = this.getFirstElementPageNumber

      if (this.totalPage === MIN_PAGE_NUMBER) {
        return `${MIN_PAGE_NUMBER} - ${this.itemsCount}`
      }
      if (firstValue === lastValue) {
        return firstValue
      }
      if (this.itemsShown < this.itemsOnPage) {
        return `${firstValue}`
      } else {
        return `${firstValue} - ${lastValue}`
      }
    }
  },

  methods: {
    onPaginationButtonClick(page) {
      this.$emit('update:currentPage', page)
    },

    getPageNumberClasses(pageNumber) {
      const { currentPage, totalPage } = this
      return {
        'pc-Page': true,
        'pc-Page-current': currentPage === pageNumber,
        'pc-Page-ellipsis-after':
          pageNumber === MIN_PAGE_NUMBER &&
          Math.abs(pageNumber - currentPage) > SECOND_PAGE &&
          totalPage > DEFAULT_PAGE_COUNT,

        'pc-Page-ellipsis-before':
          pageNumber === totalPage && Math.abs(pageNumber - currentPage) >= THIRD_PAGE
      }
    },

    goToPrevious() {
      if (this.currentPage > 1) {
        this.$emit('update:currentPage', this.currentPage - 1)
      }
    },

    goToNext() {
      if (this.currentPage < this.totalPage) {
        this.$emit('update:currentPage', this.currentPage + 1)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.pc-PaginationBlock {
  margin: 20px 0 32px 0;
  grid-template-columns: 1fr auto 1fr;
  gap: 8px;
  align-items: center;
  font-family: $system-ui;

  &:not(&-without-nav) {
    display: grid;
  }

  &-without-nav {
    display: flex;
    justify-content: space-between;
  }
}

.pc-PaginationWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.pc-Page {
  min-width: 32px;
  height: 32px;
  cursor: pointer;
  background: $grey-3-next;
  border-radius: $border-radius-sm-next;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark-2;

  &:hover {
    background-color: $grey-2-next;
  }

  .pc-PageNumber {
    font-size: $fs-12;
    line-height: 16px;
    font-weight: fw('bold');
    color: $dark-grey;
    text-decoration: none;
  }

  &-current,
  &-current:hover {
    background-color: $blue-2;

    .pc-PageNumber {
      color: $main-bg-grey;
    }
  }

  &-ellipsis-after,
  &-ellipsis-before {
    position: relative;
  }

  &-ellipsis-after::after,
  &-ellipsis-before::before {
    content: '⋯';
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: default;
    width: 32px;
    // pointer-events: none;
  }

  &-ellipsis-after {
    margin-right: 40px;

    &::after {
      left: 40px;
    }
  }

  &-ellipsis-before {
    margin-left: 40px;

    &::before {
      right: 40px;
    }
  }
  &-disable {
    color: $grey-1-next;
    cursor: not-allowed;

    &:active {
      pointer-events: none;
    }
    &:hover {
      background-color: $grey-3-next;
    }
  }
}
.pc-Pagination {
  margin: 0;
  list-style-type: none;
  display: flex;
  gap: 8px;
}

.pc-DisplayOptions {
  display: flex;
  align-items: center;
  color: $grey-semi-medium;
  gap: 16px;
}

.pc-Entries {
  display: flex;
  align-items: center;
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
}

.pc-ItemOnPage {
  display: flex;
  align-items: center;
  // align in Safari
  justify-content: flex-end;
  outline: none;
}
.pc-PaginationBlock_Description {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}
</style>

<style lang="scss">
.pc-PaginationBlock {
  .o-droplist {
    min-height: 0;
  }
  .as-AppSelect {
    .as-AppDroplistButton {
      padding: 4px;
    }
    .as-AppDroplistButton_Content {
      padding: 0;
      margin: 0;
    }
  }
}
</style>
