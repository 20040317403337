import { cloneDeep, isBoolean, isEmpty, isEqual, isNumber, isUndefined } from 'lodash'

import BillingApiHandler from '@/api/billing'
import CustomFieldsApiHandler from '@/api/custom-fields'
import PluginApiHandler from '@/api/plugin'
import {
  DELETE_USER_DATA_PARAMETER,
  SET_APP_LICENSE_BANNER_HEIGHT,
  SET_BILLING_PLAN_DETAILS,
  SET_BILLING_PLAN_DETAILS_LOADER,
  SET_EXTERNAL_ENVIRONMENT_ELEMENTS_HEIGHT,
  SET_FULL_APP_LOADER_STATUS,
  SET_FULLSCREEN,
  SET_PAGE_HEADER_HEIGHT,
  SET_RESTRICTIONS,
  SET_ROADMAP_STICKY_STATUS,
  SET_SETTINGS,
  SET_SETTINGS_ARE_LOADED,
  SET_SETTINGS_PARAMETER,
  SET_TOOLBAR_HEIGHT,
  SET_USER_DATA,
  SET_USER_DATA_BY_KEY,
  TOGGLE_APP_MENU_PINNED_STATUS,
  TOGGLE_APP_MENU_SHOWED_STATUS,
  UPDATE_USER_SETTINGS
} from '@/store/mutation-types'
import { ESSENTIAL, PROFESSIONAL } from '@/utils/billing-settings'
import { COMPANY_SETTINGS_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { getUserGroupsByWorkspace } from '@/utils/global-groups'
import { LICENSE_TYPES } from '@/utils/license-types'
import { applyStatusNames } from '@/utils/status-names'
import {
  APP_LOCALE,
  APP_OPENED_COUNT,
  CUSTOM_DASHBOARD_FILTERS,
  DEFAULT_USERS_SETTINGS,
  FEEDBACK_MODAL_ID,
  getOkrElementsTableSettingsKeys,
  HOMEPAGE_SEARCH_TYPE_ID,
  HOMEPAGE_SEARCH_TYPE_ID_TOOLTIP,
  OKR_EXPLORER_SHOW_KRS,
  ONBOARDING_HUB,
  parseUserSettings,
  PERFORMANCE_REPORT_FILTERS,
  RELEASE_UPDATES_MODAL_ID,
  ROADMAP_ACTIVE_VIEW,
  ROADMAP_TABLE_COLLAPSED_STATE,
  ROADMAP_TABLE_SIDE_WIDTH,
  ROADMAP_VIEW_SCALE,
  SETTINGS_REFERER,
  STORYLANE,
  stringifyUserSettings,
  USER_SETTINGS_MAPPER,
  DASHBOARD_GENERAL_FILTERS
} from '@/utils/user-settings'
import { restoreLastSelectedWorkspace } from '@/utils/workspaces'
import { REQUIRED_APP_OPENED_COUNT_TO_SHOW_MODAL } from '@jira/utils/feedback-modal'

const DEFAULT_APP_LOADING_OPTIONS = {
  alpha: 0.5,
  backgroundColor: '#091e42',
  circleColor: 'var(--white-color)'
}

const state = {
  settings: null,
  settingsAreLoaded: false,
  fullscreen: false,
  roadmapStickyStatus: false,
  userData: null,
  restrictions: {},
  otWrapperHeight: 0,
  appLicenseBannerHeight: 0,
  pageHeaderHeight: 0,
  externalEnvironmentElementsHeight: {
    navbar: 0,
    footer: 0
  },
  fullAppLoading: {
    flag: false,
    ...DEFAULT_APP_LOADING_OPTIONS
  },
  appMenu: {
    showed: false,
    pinned: true
  },
  billingPlanDetails: {
    loading: false,
    data: {
      upcomingInvoice: {}
    }
  }
}

const getters = {
  userIsAdmin: (state, getters, rootState, rootGetters) => {
    const selectedWorkspace = rootGetters['workspaces/selectedWorkspace']
    const isUserPluginAdmin = rootState.pluginOptions.isPluginAdmin

    return (
      isUserPluginAdmin ||
      (selectedWorkspace && selectedWorkspace.workspaceAdmin) ||
      state.userData?.hasAccessToWorkspaceSettingsPage ||
      state.userData?.hasAccessToGlobalGroupsPage
    )
  },

  userIsWorkspaceAdmin: state => {
    return state.userData?.hasAccessToWorkspaceSettingsPage
  },

  userRoleForTracking: (state, getters, rootState) => {
    const isUserPluginAdmin = rootState.pluginOptions.isPluginAdmin
    const isWorkspaceAdmin = state.userData?.hasAccessToWorkspaceSettingsPage

    const [role] = [
      isUserPluginAdmin && 'plugin admin',
      isWorkspaceAdmin && 'ws admin',
      'other'
    ].filter(Boolean)

    return role
  },

  showDashboardItem: (state, getters) => {
    const hideDashboard = state.settings?.[COMPANY_SETTINGS_ENTITY_KEYS.HIDE_DASHBOARD_FROM_USERS]
    if (hideDashboard === undefined) {
      return false
    }
    return !hideDashboard || !(hideDashboard && !getters.userIsAdmin)
  },

  allowLinkOkrElementsToKr: state => {
    const linkObjToKR = state.settings?.[COMPANY_SETTINGS_ENTITY_KEYS.LINK_OBJ_TO_KR]
    if (isUndefined(linkObjToKR)) {
      return false
    }

    return linkObjToKR
  },

  tracingDisabled: state => {
    const tracingDisabled = state.settings?.[COMPANY_SETTINGS_ENTITY_KEYS.TRACING_DISABLED]

    if (isUndefined(tracingDisabled)) {
      return true
    }

    return tracingDisabled
  },

  userGroupsByWorkspace: state => workspaceId => {
    const allGroups = state.userData?.userGroups || []

    return getUserGroupsByWorkspace({
      allGroups,
      workspaceId
    })
  },

  okrElementsTableEnabledColumns: state => tablePlacement => {
    const key = getOkrElementsTableSettingsKeys(tablePlacement).enabledColumns
    return state.userData.userSettings[USER_SETTINGS_MAPPER[key]] || DEFAULT_USERS_SETTINGS[key]
  },

  okrElementsTableColumnsWidth: state => tablePlacement => {
    const key = getOkrElementsTableSettingsKeys(tablePlacement).columnsWidth
    return state.userData.userSettings[USER_SETTINGS_MAPPER[key]] || DEFAULT_USERS_SETTINGS[key]
  },

  okExplorerShowKrs: state => {
    const key = OKR_EXPLORER_SHOW_KRS
    const valueInState = state.userData?.userSettings[USER_SETTINGS_MAPPER[key]]
    return isBoolean(valueInState) ? valueInState : DEFAULT_USERS_SETTINGS[key]
  },

  roadmapTableCollapsedState: state => {
    const key = ROADMAP_TABLE_COLLAPSED_STATE
    const valueInState = state.userData.userSettings[USER_SETTINGS_MAPPER[key]]
    return isBoolean(valueInState) ? valueInState : DEFAULT_USERS_SETTINGS[key]
  },

  roadmapTableSideWidth: state => {
    const key = ROADMAP_TABLE_SIDE_WIDTH
    const valueInState = state.userData.userSettings[USER_SETTINGS_MAPPER[key]]
    return isNumber(valueInState) ? valueInState : DEFAULT_USERS_SETTINGS[key]
  },

  roadmapActiveView: state => {
    const key = ROADMAP_ACTIVE_VIEW
    return state.userData.userSettings[USER_SETTINGS_MAPPER[key]] || DEFAULT_USERS_SETTINGS[key]
  },

  roadmapViewScale: state => {
    const key = ROADMAP_VIEW_SCALE
    return state.userData.userSettings[USER_SETTINGS_MAPPER[key]] || DEFAULT_USERS_SETTINGS[key]
  },

  releaseUpdatesModalId: state => {
    if (!state.userData) {
      return undefined
    }
    return state.userData.userSettings[USER_SETTINGS_MAPPER[RELEASE_UPDATES_MODAL_ID]] || null
  },

  feedBackModalId: state => {
    if (!state.userData) {
      return undefined
    }
    return state.userData.userSettings[USER_SETTINGS_MAPPER[FEEDBACK_MODAL_ID]] || null
  },

  appOpenedCount: state => {
    const key = APP_OPENED_COUNT
    return state.userData.userSettings[USER_SETTINGS_MAPPER[key]] || DEFAULT_USERS_SETTINGS[key]
  },

  searchTypeId: state => {
    const key = HOMEPAGE_SEARCH_TYPE_ID
    const resolvedValue = state.userData?.userSettings[USER_SETTINGS_MAPPER[key]]
    return resolvedValue ? resolvedValue : DEFAULT_USERS_SETTINGS[key]
  },

  searchTypeIdTooltip: state => {
    const key = HOMEPAGE_SEARCH_TYPE_ID_TOOLTIP

    const resolvedValue = state.userData?.userSettings[USER_SETTINGS_MAPPER[key]]
    return isBoolean(resolvedValue) ? resolvedValue : DEFAULT_USERS_SETTINGS[key]
  },

  performanceReportFilters: state => {
    const key = PERFORMANCE_REPORT_FILTERS
    return state.userData.userSettings[USER_SETTINGS_MAPPER[key]] || DEFAULT_USERS_SETTINGS[key]
  },

  customDashboardFilters: state => {
    const key = CUSTOM_DASHBOARD_FILTERS
    return state.userData.userSettings[USER_SETTINGS_MAPPER[key]] || DEFAULT_USERS_SETTINGS[key]
  },

  onboardingHubState: state => {
    const key = ONBOARDING_HUB
    return state.userData.userSettings[USER_SETTINGS_MAPPER[key]] || DEFAULT_USERS_SETTINGS[key]
  },

  appLocale: state => {
    const key = APP_LOCALE
    return state.userData.userSettings[USER_SETTINGS_MAPPER[key]] || DEFAULT_USERS_SETTINGS[key]
  },

  settingsReferer: state => {
    const key = SETTINGS_REFERER
    return state.userData.userSettings[USER_SETTINGS_MAPPER[key]] || DEFAULT_USERS_SETTINGS[key]
  },

  subscriptionStatuses: state => {
    const billingPlanDetails = state.billingPlanDetails.data
    const statusId = billingPlanDetails.statusId?.toString()
    const {
      WEB_APP_TRIAL,
      WEB_APP_ACTIVE,
      WEB_APP_CANCELED,
      WEB_APP_TO_BE_CANCELED,
      WEB_APP_PAST_DUE,
      WEB_APP_OVERLIMIT,
      WEB_APP_BLOCKED
    } = LICENSE_TYPES

    return {
      isTrial: statusId === WEB_APP_TRIAL,
      isActive: statusId === WEB_APP_ACTIVE,
      isCanceled: statusId === WEB_APP_CANCELED,
      isPastDue: statusId === WEB_APP_PAST_DUE,
      toBeCanceled: statusId === WEB_APP_TO_BE_CANCELED,
      isOverLimitedUsers: statusId === WEB_APP_OVERLIMIT,
      isBlocked: statusId === WEB_APP_BLOCKED,
      isEnterprise:
        billingPlanDetails.name !== ESSENTIAL && billingPlanDetails.name !== PROFESSIONAL
    }
  },

  storylaneSettings: state => {
    const key = STORYLANE
    return (
      state.userData?.userSettings[USER_SETTINGS_MAPPER[key]] || {
        ...DEFAULT_USERS_SETTINGS[key],
        buttonId: DEFAULT_USERS_SETTINGS[key].buttonId - 1 // только для первого показа после релиза
      }
    )
  },

  dashboardGeneralFiltersByWorkspaceId:
    state =>
    ({ workspaceId }) => {
      const key = DASHBOARD_GENERAL_FILTERS
      const DEFAULT_VALUE = cloneDeep(DEFAULT_USERS_SETTINGS[key])

      if (!workspaceId) {
        return DEFAULT_VALUE
      }

      const valueFromState = state.userData.userSettings[USER_SETTINGS_MAPPER[key]]

      if (!valueFromState) {
        return DEFAULT_VALUE
      }

      return valueFromState[workspaceId] || DEFAULT_VALUE
    },

  dashboardGeneralFilters: state => {
    const key = DASHBOARD_GENERAL_FILTERS
    return state.userData.userSettings[USER_SETTINGS_MAPPER[key]] || DEFAULT_USERS_SETTINGS[key]
  }
}

const mutations = {
  [SET_USER_DATA](state, userData) {
    state.userData = userData
  },

  [SET_USER_DATA_BY_KEY](state, { key, value }) {
    state.userData[key] = value
  },

  [DELETE_USER_DATA_PARAMETER](state, parameter) {
    delete state.userData[parameter]
  },

  [SET_SETTINGS](state, settings) {
    state.settings = settings
  },

  [SET_SETTINGS_ARE_LOADED](state, value) {
    state.settingsAreLoaded = value
  },

  [SET_FULLSCREEN](state, flag) {
    state.fullscreen = flag
  },

  [SET_ROADMAP_STICKY_STATUS](state, flag) {
    state.roadmapStickyStatus = flag
  },

  [SET_SETTINGS_PARAMETER](state, { parameter, value }) {
    if (!state.settings) {
      state.settings = {}
    }
    state.settings[parameter] = value
  },

  [TOGGLE_APP_MENU_PINNED_STATUS]: (state, status) => {
    state.appMenu.pinned = status
  },

  [TOGGLE_APP_MENU_SHOWED_STATUS]: (state, status) => {
    state.appMenu.showed = status
  },

  [SET_TOOLBAR_HEIGHT]: (state, height) => {
    state.otWrapperHeight = height
  },

  [SET_PAGE_HEADER_HEIGHT]: (state, height) => {
    state.pageHeaderHeight = height
  },

  [SET_FULL_APP_LOADER_STATUS]: (state, payload) => {
    state.fullAppLoading = payload
  },

  [SET_APP_LICENSE_BANNER_HEIGHT]: (state, height) => {
    state.appLicenseBannerHeight = height
  },

  [SET_EXTERNAL_ENVIRONMENT_ELEMENTS_HEIGHT]: (state, values = { navbar: 0, footer: 0 }) => {
    state.externalEnvironmentElementsHeight = values
  },

  [UPDATE_USER_SETTINGS](state, data) {
    state.userData.userSettings = {
      ...state.userData.userSettings,
      ...data
    }
  },

  [SET_RESTRICTIONS](state, restrictions) {
    state.restrictions = restrictions
  },

  [SET_BILLING_PLAN_DETAILS](state, data) {
    state.billingPlanDetails.data = data
  },

  [SET_BILLING_PLAN_DETAILS_LOADER](state, payload) {
    state.billingPlanDetails.loading = payload
  }
}

const actions = {
  async fetchInitialWorkspaces({ rootState, dispatch }) {
    const pluginApi = new PluginApiHandler()
    const isUserPluginAdmin = rootState.pluginOptions.isPluginAdmin

    const initialValues = await pluginApi.getInitValues({
      filterWorkspacesWhereUserDisabled: !isUserPluginAdmin
    })

    const { userWorkspaces } = initialValues

    dispatch('workspaces/setWorkspaces', userWorkspaces, { root: true })
  },

  async fetchUserData({ state, commit, rootState, dispatch }) {
    if (!isEmpty(state.userData)) {
      return
    }
    const pluginApi = new PluginApiHandler()
    const isUserPluginAdmin = rootState.pluginOptions.isPluginAdmin

    const initialValues = await pluginApi.getInitValues({
      filterWorkspacesWhereUserDisabled: !isUserPluginAdmin
    })

    const {
      userAccountId,
      userAvatarUrl,
      userGroups,
      email,
      hasAccessToWorkspaceSettingsPage,
      hasAccessToGlobalGroupsPage,
      userName,
      organizations,
      userSettings
    } = initialValues

    commit(SET_USER_DATA, {
      userName,
      userAccountId,
      userAvatarUrl,
      userGroups,
      email,
      hasAccessToWorkspaceSettingsPage,
      hasAccessToGlobalGroupsPage,
      userSettings
    })

    const isWebApp = rootState.webApp.isWebApp
    if (isWebApp) {
      dispatch('webApp/setOrganizations', organizations, { root: true })
    }
  },

  async fetchInitData({ commit, dispatch, state, rootState }, { initialWorkspaceId }) {
    if (state.settingsAreLoaded && state.settings) {
      return
    }
    const isUserPluginAdmin = rootState.pluginOptions.isPluginAdmin

    const pluginApi = new PluginApiHandler()
    const customFieldsApi = new CustomFieldsApiHandler()

    let data = null

    try {
      const [initialValues, customFields] = await Promise.all([
        pluginApi.getInitValues({
          filterWorkspacesWhereUserDisabled: !isUserPluginAdmin
        }),
        customFieldsApi.getCustomFieldsData()
      ])

      data = initialValues

      const userSettings = parseUserSettings(data.userSettings)

      const { confidenceLevelCustomNames, ...rest } = data.settings

      applyStatusNames({ confidenceLevelCustomNames })

      commit(SET_SETTINGS, rest)
      const {
        userAccountId,
        userAvatarUrl,
        userGroups,
        email,
        hasAccessToWorkspaceSettingsPage,
        hasAccessToGlobalGroupsPage,
        hasAccessToJira,
        userName,
        organizations,
        restrictions
      } = data
      commit(SET_USER_DATA, {
        userName,
        userAccountId,
        userAvatarUrl,
        userGroups,
        email,
        userSettings,
        hasAccessToWorkspaceSettingsPage,
        hasAccessToGlobalGroupsPage,
        hasAccessToJira
      })

      if (restrictions) {
        commit(SET_RESTRICTIONS, restrictions)
      }

      const isWebApp = rootState.webApp.isWebApp
      if (isWebApp) {
        dispatch('webApp/setOrganizations', organizations, { root: true })
      }

      dispatch('customFields/setCustomFields', customFields, { root: true })
    } finally {
      commit(SET_SETTINGS_ARE_LOADED, true)
    }

    dispatch('workspaces/setWorkspaces', data.userWorkspaces, { root: true })

    const workspaceIdAvailable = workspaceId => {
      return data.userWorkspaces.some(ws => ws.id === workspaceId)
    }

    let workspaceId = null
    if (initialWorkspaceId && workspaceIdAvailable(initialWorkspaceId)) {
      workspaceId = initialWorkspaceId
    } else {
      workspaceId = restoreLastSelectedWorkspace(data.userWorkspaces)
    }

    if (workspaceId !== null) {
      dispatch('workspaces/setWorkspaceId', workspaceId, { root: true })
    }

    dispatch('workspaces/setWorkspacesAreLoaded', true, { root: true })
  },

  updateUserName({ commit }, value) {
    commit(SET_USER_DATA_BY_KEY, {
      key: 'userName',
      value
    })
  },

  toggleFullscreen({ commit, state }, flag) {
    const resolvedFlag = flag !== undefined ? flag : !state.fullscreen
    commit(SET_FULLSCREEN, resolvedFlag)
  },

  toggleRoadmapStickyStatus({ commit, state }, flag) {
    const resolvedFlag = flag !== undefined ? flag : !state.roadmapStickyStatus
    commit(SET_ROADMAP_STICKY_STATUS, resolvedFlag)
  },

  toggleFullAppLoader(
    { commit, state },
    { flag = undefined, ...payload } = { flag: undefined, ...DEFAULT_APP_LOADING_OPTIONS }
  ) {
    const resolvedFlag = flag !== undefined ? flag : !state.fullAppLoading.flag
    commit(SET_FULL_APP_LOADER_STATUS, { ...state.fullAppLoading, flag: resolvedFlag, ...payload })
  },

  toggleMenuPinnedStatus({ commit }, status) {
    if (status) {
      commit(TOGGLE_APP_MENU_SHOWED_STATUS, false)
    }
    commit(TOGGLE_APP_MENU_PINNED_STATUS, Boolean(status))
  },

  toggleMenuShowedStatus({ commit }, status) {
    commit(TOGGLE_APP_MENU_SHOWED_STATUS, status)
  },

  setSettingsParameter({ commit }, { parameter, value }) {
    commit(SET_SETTINGS_PARAMETER, { parameter, value })
  },

  deleteUserDataParameter({ commit }, parameter) {
    commit(DELETE_USER_DATA_PARAMETER, parameter)
  },

  setOtWrapperHeight({ state, commit }, height) {
    if (state.otWrapperHeight !== height) {
      commit(SET_TOOLBAR_HEIGHT, height)
    }
  },

  setPageHeaderHeight({ state, commit }, height) {
    if (state.pageHeaderHeight !== height) {
      commit(SET_PAGE_HEADER_HEIGHT, height)
    }
  },

  setAppLicenseBannerHeight({ state, commit }, height) {
    if (state.appLicenseBannerHeight !== height) {
      commit(SET_APP_LICENSE_BANNER_HEIGHT, height)
    }
  },

  setExternalEnvironmentElementsHeight({ state, commit }, values) {
    if (!isEqual(state.externalEnvironmentElementsHeight, values)) {
      commit(SET_EXTERNAL_ENVIRONMENT_ELEMENTS_HEIGHT, values)
    }
  },

  async updateUserSettings({ commit }, updatedUserSettings) {
    try {
      const pluginApi = new PluginApiHandler()
      const payload = stringifyUserSettings(updatedUserSettings)
      const { settings } = await pluginApi.updateUserSettings(payload)
      commit(UPDATE_USER_SETTINGS, parseUserSettings(settings))
    } catch (error) {
      handleError({ error })
    }
  },

  async fetchBillingPlanDetails({ commit }, { withLoading = true } = {}) {
    const BillingApi = new BillingApiHandler()

    if (withLoading) {
      commit(SET_BILLING_PLAN_DETAILS_LOADER, true)
    }
    const data = await BillingApi.getBillingPlanDetails()

    commit(SET_BILLING_PLAN_DETAILS, data)
    commit(SET_BILLING_PLAN_DETAILS_LOADER, false)
  },

  async increaseAppOpenedCount({ dispatch, state }) {
    const openedCount = state.userData.userSettings[USER_SETTINGS_MAPPER[APP_OPENED_COUNT]] || 0
    if (openedCount < REQUIRED_APP_OPENED_COUNT_TO_SHOW_MODAL) {
      await dispatch('updateUserSettings', {
        [USER_SETTINGS_MAPPER[APP_OPENED_COUNT]]: openedCount + 1
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
