<template>
  <span
    :id="$attrs.id"
    :class="{ 'ltl-Wrapper-empty': !isItemsExist, 'ltl-Wrapper-filtered-out': filteredOut }"
    class="ltl-Wrapper"
  >
    <span
      v-if="isItemsExist"
      v-tippy="{
        content: selectedItemsList,
        placement: 'bottom',
        theme: 'translucent-next word-break semi-bold-text',
        allowHTML: true
      }"
      :title="showNativeTooltip ? selectedItemsList : null"
      class="ltl-List"
    >
      <slot v-for="item in limittedItems" :key="item.id" :item="item" name="item">
        <CellSelectItem
          :active="active"
          class="ltl-List_Item"
          truncated
          user-has-read-access
          user-has-update-access
        >
          {{ item[itemValue] }}
        </CellSelectItem>
      </slot>

      <CellSelectItem
        v-if="showPlusLabel"
        :active="active"
        class="ltl-List_Item ltl-List_Item-count"
        truncated
        user-has-read-access
        user-has-update-access
      >
        +{{ count - limit }}
      </CellSelectItem>
    </span>
  </span>
</template>

<script setup>
import { computed } from 'vue'

import CellSelectItem from '@/components/objectives/table/cells/CellSelectItem'

defineOptions({
  name: 'LimitTagList'
})

const props = defineProps({
  items: {
    type: Array,
    required: true
  },

  itemValue: {
    type: String,
    default: 'name'
  },

  tooltipValue: {
    type: String,
    default: null
  },

  showNativeTooltip: {
    type: Boolean
  },

  active: {
    type: Boolean
  },

  limit: {
    type: Number,
    default: 1
  },

  filteredOut: {
    type: Boolean
  }
})

const count = computed(() => props.items.length)

const isItemsExist = computed(() => count.value > 0)

const limittedItems = computed(() => [...props.items].slice(0, props.limit))

const showPlusLabel = computed(() => count.value > props.limit)

const selectedItemsList = computed(() => {
  if (!props.tooltipValue) return null
  return props.items.map(item => item[props.tooltipValue]).join('</br>')
})
</script>

<style lang="scss" scoped>
.ltl-Wrapper {
  max-width: 100%;

  &-empty {
    width: 100%;
    display: flex;
  }

  &-filtered-out {
    opacity: 0.2;
  }
}

.ltl-List {
  display: flex;
  gap: var(--list-gap, 4px);
  max-width: max-content;
  align-items: center;
  margin: 0;
}

.ltl-List_Item {
  &-count {
    flex: 0 1 auto;
    text-align: center;
    min-width: 24px;
    padding: 2px 3px;
    margin: 0 0 0 var(--count-offset, 4px);
  }
}
</style>
