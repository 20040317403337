<template>
  <portal to="modal-windows">
    <AppDialog
      :show="show"
      :size="1076"
      :type="DIALOG_TYPES.INFO"
      class="cpd-Modal"
      hide-footer
      hide-hero
      style="--dialog-content-padding-right: 12px; --dialog-content-padding-left: 12px"
      @on-close="onClose"
      @on-confirm="onConfirm"
    >
      <div class="cpd-Wrapper">
        <SubscriptionTiers
          :status-data="statusData"
          :subscription="subscription"
          is-show-cancel-btn-on-active-subscription
          @on-contact-us="onContactUsTiers"
          @on-subscribe="onSubscribeTiers"
          @update:subscription="onClose"
        />
      </div>
    </AppDialog>
    <ManagePlanDialog
      v-model:chosen-subscription="managePlanPayload"
      v-model:show="isShowManagePlan"
      :current-subscription="subscription"
      with-switch-plan
    />
    <BillingContactUsDialog v-model:show="isShowBillingContactDialog" />
  </portal>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { getPeriodName, useManagePlanDialog } from '@/utils/billing-settings'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'
import BillingContactUsDialog from '@web-app/components/billing-settings/BillingContactUsDialog'
import ManagePlanDialog from '@web-app/components/billing-settings/ManagePlanDialog'
import SubscriptionTiers from '@web-app/components/billing-settings/SubscriptionTiers'

import AppDialog from '@/components/AppDialog'

defineOptions({
  name: 'ChoosePlanDialog'
})
const emit = defineEmits(['update:show'])

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  },

  subscription: {
    type: Object,
    required: true
  },

  statusData: {
    type: Object,
    required: true
  }
})
const onClose = () => {
  emit('update:show', false)
}
const onConfirm = () => {
  emit('update:show', false)
}
const store = useStore()

const licenseStatusId = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.LICENSE_STATUS_ID]
)

watch(
  () => props.show,
  newValue => {
    if (newValue) {
      tracker.logEvent('subscription window shown', {
        category: EVENT_CATEGORIES.BILLING,
        plan: props.subscription.name.toLowerCase(),
        subscription_cycle: getPeriodName(props.subscription.period),
        number_of_users: props.subscription.maxUsersCount || props.subscription.usersCount,
        current_license: licenseStatusId.value
      })
    }
  }
)

const {
  onSubscribe,
  onContactUs,
  isShowBillingContactDialog,
  isShowManagePlan,
  managePlanPayload
} = useManagePlanDialog({
  subscription: props.subscription,
  licenseStatusId: licenseStatusId.value
})

const onSubscribeTiers = payload => {
  onClose()
  onSubscribe(payload)
}

const onContactUsTiers = () => {
  onClose()
  onContactUs()
}
</script>

<style lang="scss" scoped>
.cpd-Modal {
  &:deep(.o-modal-header) {
    padding-top: 16px;
  }
}
.cpd-Wrapper {
  padding: 0 0 40px 0;
}
</style>
