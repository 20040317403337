<template>
  <AppDroplist
    v-model="opened"
    :append-to="appendTo"
    :boundary="boundary"
    :disabled="disabled"
    :duration="duration"
    :offset="[0, 0]"
    :show-on-init="showOnInit"
    :to-selector="toSelector"
    position="bottom-start"
  >
    <template #button>
      <div
        :class="{
          'dc-Date': true,
          [`dc-Date-${type}`]: type,
          'dc-Date-opened': opened
        }"
        :data-auto-testid="`datepicker-trigger-${dateProp}`"
        :data-testid="`datepicker-trigger-${dateProp}`"
      >
        {{ date }}
      </div>
    </template>
    <DatePicker
      :clearable="false"
      :disabled="disabled"
      :disabled-date="disabledDates"
      :format="dateFormat"
      :inline="true"
      :lang="datepickerOptions"
      :model-value="modelValue"
      :placeholder="$t('objectives.due_date.end_of_interval')"
      class="dc-MarginMinuse"
      @update:model-value="onDatePickerInput"
    >
      <template v-if="!hideFooter" #footer="{ emit }">
        <a href="#" @click.prevent="emit(null)"> {{ buttonText }} </a>
      </template>
    </DatePicker>
  </AppDroplist>
</template>

<script>
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import DatePicker from 'vue2-datepicker'

import 'vue2-datepicker/index.css'

import { dateFormat, datepickerLang, getValidDueOrStartDate } from '@/utils/date'
import { OKR_DATES_SELECT_DATE_PROPS } from '@/utils/okr-element-dates'
import { objectOrNullProp } from '@/utils/prop-validators'

import AppDroplist from '@/components/AppDroplist'

export default defineComponent({
  name: 'OkrDateSelect',

  components: {
    AppDroplist,
    DatePicker
  },

  props: {
    modelValue: {
      required: true,
      validator: value => objectOrNullProp(value)
    },

    disabled: {
      type: Boolean
    },

    showEndOfCycle: {
      type: Boolean,
      default: true
    },

    type: {
      type: String,
      default: 'form'
    },

    boundary: {
      type: String,
      default: ''
    },

    toSelector: {
      type: String,
      default: null
    },

    showOnInit: {
      type: Boolean
    },

    dateProp: {
      type: String,
      required: true,
      validator: v => Object.values(OKR_DATES_SELECT_DATE_PROPS).includes(v)
    },

    minMaxDates: {
      type: Object,
      default: () => ({})
    },

    hideFooter: {
      type: Boolean
    },

    appendTo: {
      type: String,
      default: 'parent'
    },

    duration: {
      type: Array,
      default: () => []
    },

    dateFormat: {
      type: String,
      default: dateFormat
    }
  },

  emits: ['update:modelValue'],

  data() {
    return {
      opened: false
    }
  },

  computed: {
    datepickerOptions() {
      return datepickerLang()
    },

    date() {
      if (this.modelValue === null) {
        return this.showEndOfCycle ? this.buttonText : ''
      }
      return dayjs(this.modelValue).format(this.dateFormat)
    },

    buttonText() {
      const textByDateProp = {
        [OKR_DATES_SELECT_DATE_PROPS.DUE_DATE]: this.$t('objectives.due_date.end_of_interval'),
        [OKR_DATES_SELECT_DATE_PROPS.START_DATE]: this.$t('objectives.due_date.start_of_interval')
      }

      return textByDateProp[this.dateProp]
    }
  },

  methods: {
    /** @public */
    open() {
      this.opened = true
    },

    onDatePickerInput(data) {
      this.$emit('update:modelValue', getValidDueOrStartDate(data, this.dateProp))
      this.opened = false
    },

    disabledDates(date) {
      if (!isEmpty(this.minMaxDates)) {
        if (this.dateProp === OKR_DATES_SELECT_DATE_PROPS.START_DATE) {
          return date.getTime() > this.minMaxDates.maxDate.getTime()
        } else {
          return date.getTime() < this.minMaxDates.minDate.getTime()
        }
      } else {
        return false
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.dc-Date {
  color: $primary-color;

  &-form {
    padding: 0 6px;
    border-radius: $border-radius-sm-next;
    line-height: 20px;
    max-width: 130px;
    background-color: rgba($primary-color-next, 0.15);
    cursor: pointer;
  }

  &-form#{&}-opened {
    background-color: $dark-2;
    color: $white;
  }
}

.dc-Date-grid {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: $blue-1;
    text-decoration: underline;
  }
}
</style>
