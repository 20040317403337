import { has, isEmpty } from 'lodash'

import {
  createQueryForExternalLink,
  getDefaultAppRoute,
  OBOARD_CLOUD_PLUGIN_ROUTE
} from '@/utils/external-link-helper'
import { copyToClipboard } from '@/utils/general'
import { OKR_FORM_VIEWS } from '@/utils/objectives'
import { getAtlassianBaseUrl } from '@jira/util'
import { IS_DEVELOPMENT } from '@root/app-modes'

export const createdObjectiveModalNotificationActions = {
  OPEN: {
    id: 0,
    label: 'action.open',
    closeOnHandle: true
  },
  COPY_LINK: {
    id: 1,
    label: 'breadcrumb.copy_link',
    closeOnHandle: true,
    handler: ({ workspaceId, createdElement, isPluginServer = false, isJiraApp = true }) => {
      if (!String(workspaceId)) {
        throw new Error('workspaceId is required')
      }

      const atlassianBaseUrl = getAtlassianBaseUrl()
      const composedQueryParameter = createQueryForExternalLink({
        ...createdElement,
        isJiraApp
      })

      const DEFAULT_APP_ROUTE = getDefaultAppRoute({
        workspaceId: workspaceId,
        query: composedQueryParameter
      })

      let oboardPluginRoute = IS_DEVELOPMENT || isPluginServer ? '' : OBOARD_CLOUD_PLUGIN_ROUTE

      const url = `${atlassianBaseUrl}${oboardPluginRoute}${DEFAULT_APP_ROUTE}`

      copyToClipboard(url)
    }
  }
}

export const getCreatedElementNotificationTitle = elementType => {
  const { KR, OBJECTIVE, LINK_JIRA_ISSUE } = OKR_FORM_VIEWS

  const titles = {
    [KR]: 'create.keyResult.success_message',
    [LINK_JIRA_ISSUE]: 'create.task.success_message',
    [OBJECTIVE]: 'create.objective.success_title'
  }

  return titles[elementType] || 'create.objective.success_title'
}

export const checkIsShowNotification = eventData => {
  return {
    OBJECTIVE:
      has(eventData, 'isChildModal') &&
      !eventData.isChidlModal &&
      !isEmpty(eventData.createdObjective),
    KR: !isEmpty(eventData.createdKR),
    LINK_JIRA_ISSUE: !isEmpty(eventData.createdTasks)
  }
}
