<template>
  <div v-if="!isEmpty(getWorkspaces)" class="wam-Message">
    <AppInfoMessage>
      <slot> {{ $t('global_groups.users_affect') }}: </slot>
    </AppInfoMessage>

    <LimitTagList
      :items="getWorkspaces"
      :limit="limit"
      style="--list-gap: 4px; --count-offset: 0"
      tooltip-value="name"
    >
      <template #item="{ item: workspace }">
        <AppIcon
          v-if="workspace.hidden"
          class="wam-LockedWs"
          height="24"
          icon-name="lock-next"
          width="24"
        />

        <WorkspaceIcon v-else :option="workspace" data-testid="affect-message-workspace-icon" />
      </template>
    </LimitTagList>
  </div>
</template>

<script setup>
import { flatten, isEmpty, isNumber, uniqBy } from 'lodash'
import { computed } from 'vue'

import LimitTagList from '@/components/form/LimitTagList'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import WorkspaceIcon from '@/components/workspaces/WorkspaceIcon'

defineOptions({
  name: 'WorkspacesAffectMessage'
})

const props = defineProps({
  workspaces: {
    type: Array,
    default: () => []
  },

  limit: {
    type: Number,
    default: 12,
    validator: v =>
      isNumber(v) && Number.isFinite(v) && v > 0 && Number.isSafeInteger(v) && !Number.isNaN(v)
  }
})

const getWorkspaces = computed(() => uniqBy(flatten(props.workspaces), 'id'))
</script>

<style lang="scss" scoped>
.wam-LockedWs {
  flex-shrink: 0;
  color: $dark-2;
}

.wam-Message {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
