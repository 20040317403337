<template>
  <slot
    :connection-status="connectionStatus"
    :has-access-to-jira="hasAccessToJira"
    :is-jira-app="isJiraApp"
    :is-jira-connected="isJiraConnected"
    :is-web-app="isWebApp"
    :is-web-app-connected="isWebAppConnected"
  />
</template>

<script setup>
import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

const isJiraApp = inject('isJiraApp')
const isWebApp = inject('isWebApp')

const store = useStore()

const isJiraConnected = computed(() => {
  return store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
})

const isWebAppConnected = computed(() => {
  return store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.WEB_APP_CONNECTED]
})

const connectionStatus = computed(() => {
  return (isWebApp && isJiraConnected.value) || (isJiraApp && isWebAppConnected.value)
})

const hasAccessToJira = computed(() => store.state.system.userData.hasAccessToJira)
</script>
