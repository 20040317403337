<template>
  <div class="st-SubscriptionTier">
    <SubscriptionTierSwitcher v-model:period="period" />
  </div>

  <div class="st-SubscriptionItems">
    <SubscriptionItem
      v-for="tierSubscription in currentTiers"
      :key="tierSubscription.id"
      :current-subscription="subscription"
      :is-current-plan="isCurrentPlan(tierSubscription)"
      :is-same-cycle="isSamePeriod(tierSubscription)"
      :is-show-cancel-btn-on-active-subscription="isShowCancelBtnOnActiveSubscription"
      :period="period"
      :show-label-current-plan="isCurrentPlan(tierSubscription) && isSamePeriod(tierSubscription)"
      :status-data="statusData"
      :subscription="tierSubscription"
      :without-current-plan="withoutCurrentPlan"
      @cancel="onCancelSubscription"
      @renew="onRenew"
      @subscribe="onSubscribe"
      @contact-us="onContactUs"
    />
  </div>

  <FeatureList />
  <SubscriptionUpdatedDialog
    v-model:is-show="isShowSubscriptionUpdatedDialog"
    @update:is-show="onSubscriptionUpdated"
  />
  <portal to="modal-windows">
    <SubscriptionWasntUpdatedDialog v-model:is-show="isShowSubscriptionWasntUpdatedDialog" />
  </portal>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import BillingApiHandler from '@/api/billing'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { MONTH, SUBSCRIPTION_TIERS } from '@/utils/billing-settings'
import { handleError } from '@/utils/error-handling'
import FeatureList from '@web-app/components/billing-settings/FeatureList'
import SubscriptionItem from '@web-app/components/billing-settings/SubscriptionItem'
import SubscriptionTierSwitcher from '@web-app/components/billing-settings/SubscriptionTierSwitcher'
import SubscriptionUpdatedDialog from '@web-app/components/billing-settings/SubscriptionUpdatedDialog'
import SubscriptionWasntUpdatedDialog from '@web-app/components/billing-settings/SubscriptionWasntUpdatedDialog'

const props = defineProps({
  statusData: {
    type: Object,
    required: true
  },

  subscription: {
    type: Object,
    required: true
  },

  withoutCurrentPlan: {
    type: Boolean
  },

  isShowCancelBtnOnActiveSubscription: {
    type: Boolean
  }
})

const emit = defineEmits(['update:subscription', 'onSubscribe', 'onContactUs'])
const store = useStore()

const subscriptionStatuses = computed(() => store.getters['system/subscriptionStatuses'])

const isCurrentPlan = tierSubscription => {
  return (
    props.subscription.name === tierSubscription.plan &&
    !subscriptionStatuses.value.isCanceled &&
    !subscriptionStatuses.value.isTrial
  )
}

const isSamePeriod = tierSubscription => props.subscription.period === tierSubscription.period
const period = ref(MONTH)
const currentTiers = computed(() => {
  return SUBSCRIPTION_TIERS.find(subscription => subscription.period === period.value)?.tiers || []
})

const onContactUs = () => {
  emit('onContactUs')
}

const onSubscribe = payload => {
  emit('onSubscribe', payload)
}

const onRenew = async () => {
  try {
    const BillingApi = new BillingApiHandler()
    await BillingApi.renewSubscription()
    isShowSubscriptionUpdatedDialog.value = true
    await store.dispatch('system/fetchBillingPlanDetails')
    tracker.logEvent('subscription renewed', {
      category: EVENT_CATEGORIES.BILLING
    })
  } catch (error) {
    isShowSubscriptionWasntUpdatedDialog.value = true
    handleError({ error })
  }
}

const isShowSubscriptionUpdatedDialog = ref(false)
const isShowSubscriptionWasntUpdatedDialog = ref(false)

const onCancelSubscription = async () => {
  try {
    const BillingApi = new BillingApiHandler()
    await BillingApi.cancelSubscription()
    isShowSubscriptionUpdatedDialog.value = true
    await store.dispatch('system/fetchBillingPlanDetails')
    tracker.logEvent('subscription cancelled', {
      category: EVENT_CATEGORIES.BILLING
    })
  } catch (error) {
    isShowSubscriptionWasntUpdatedDialog.value = true
    handleError({ error })
  }
}

const onSubscriptionUpdated = value => {
  if (!value) {
    emit('update:subscription')
  }
}

onMounted(() => {
  period.value = props.subscription.period
})
</script>

<style lang="scss" scoped>
.st-SubscriptionTier {
  display: flex;
  justify-content: center;
  margin: 0 0 24px;
}

.st-SubscriptionItems {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  margin: 0 auto 24px auto;
}
</style>
