<template>
  <span :class="{ [`at-Tag-${type}`]: type }" :style="style" class="at-Tag">
    {{ content }}
  </span>
</template>

<script>
import { defineComponent } from 'vue'

import { TAG_TYPES } from '@/utils/components-configurations/app-tag'

export default defineComponent({
  name: 'AppTag',

  props: {
    content: {
      type: String,
      default: ''
    },

    contentColor: {
      type: String,
      default: '#42526e'
    },

    contentBackground: {
      type: String,
      default: '#dfe1e6'
    },

    type: {
      type: String,
      default: TAG_TYPES.DEFAULT,
      validator: v => Object.values(TAG_TYPES).includes(v)
    }
  },

  computed: {
    style() {
      return {
        color: this.contentColor,
        backgroundColor: this.contentBackground
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.at-Tag {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;

  font-weight: fw('bold');

  width: min-content;
  max-width: 100%;

  &-default {
    font-size: $fs-11;
    border-radius: 3px;
    padding: 3px 4px;
  }

  &-default-next {
    font-size: $fs-12;
    line-height: 16px;
    padding: 4px 6px;
    display: block;
    border-radius: $border-radius-sm-next;
  }
}
</style>
