<template>
  <AppButton
    v-if="!showFeatureLists"
    class="fl-ComparePlansButton"
    icon="arrow-down-simple-next"
    type="link-next"
    @click="onComparePlans"
  >
    {{ t('subscription.compare_plans') }}
  </AppButton>

  <transition mode="out-in" name="fade">
    <div v-if="showFeatureLists" class="fl-FeatureLists">
      <div class="fl-FeatureLists_Wrapper">
        <div class="fl-FeatureList">
          <div class="fl-FeatureItem_Title"></div>
          <div v-for="list in FEATURE_LIST" :key="list.name" class="fl-FeatureItem">
            <div class="fl-FeatureItem_Label">{{ list.name }}</div>
          </div>
        </div>
        <div v-for="list in getFeatureListByPlan" :key="list.name" class="fl-FeatureList">
          <div class="fl-FeatureItem_Title">{{ t(`subscription.${list.name}`) }}</div>
          <div
            v-for="(feature, featureIndex) in list.features"
            :key="featureIndex"
            class="fl-FeatureItem"
          >
            <div class="fl-FeatureItem_Label">
              <span v-if="feature.text">{{ feature.text }} </span>
              <AppIcon
                v-else-if="feature.include"
                height="24"
                icon-name="check-active"
                width="24"
              />
              <AppIcon v-else height="24" icon-name="clear" width="24" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { LIMITATIONS, RESTRICTIONS_IDS } from '@/utils/web-app/plans-limitations'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

const showFeatureLists = ref(false)

const { t } = useI18n()

const ESSENTIAL = 'essential'
const PROFESSIONAL = 'professional'
const ENTERPRISE = 'enterprise'

const PLAN_LIST = [ESSENTIAL, PROFESSIONAL, ENTERPRISE]
const FEATURE_LIST = [
  {
    name: 'OKR management',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'OKR levels (types)',
    plans: {
      essential: {
        text: `Up to ${LIMITATIONS.OKR_LEVELS[RESTRICTIONS_IDS.ID_1]}`
      },
      professional: {
        text: `Up to ${LIMITATIONS.OKR_LEVELS[RESTRICTIONS_IDS.ID_2]}`
      },
      enterprise: {
        text: 'Unlimited'
      }
    }
  },
  {
    name: 'Custom dashboard public',
    plans: {
      essential: {
        text: `Up to ${LIMITATIONS.CUSTOM_DASHBOARD_PUBLIC[RESTRICTIONS_IDS.ID_1]}`
      },
      professional: {
        text: `Up to ${LIMITATIONS.CUSTOM_DASHBOARD_PUBLIC[RESTRICTIONS_IDS.ID_2]}`
      },
      enterprise: {
        text: 'Unlimited'
      }
    }
  },
  {
    name: 'Custom field',
    plans: {
      essential: {
        text: `Up to ${LIMITATIONS.CUSTOM_FIELDS[RESTRICTIONS_IDS.ID_1]}`
      },
      professional: {
        text: `Up to ${LIMITATIONS.CUSTOM_FIELDS[RESTRICTIONS_IDS.ID_2]}`
      },
      enterprise: {
        text: `Up to ${LIMITATIONS.CUSTOM_FIELDS[RESTRICTIONS_IDS.ID_3]}`
      }
    }
  },
  {
    name: 'Hierarchical business groups',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Public and private workspaces',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Public API',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Excel / PDF export',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'OKR Map',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Roadmap',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Deeper OKR hierarchy',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Reporting',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Notifications',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Jira integration',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Private OKRs',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Custom thresholds',
    plans: {
      essential: {
        include: true
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },

  {
    name: 'Weighting',
    plans: {
      essential: {
        include: false
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Audit log (user, future)',
    plans: {
      essential: {
        include: false
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Account manager',
    plans: {
      essential: {
        include: false
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Miro integration (future)',
    plans: {
      essential: {
        include: false
      },
      professional: {
        include: true
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Custom rights (future)',
    plans: {
      essential: {
        include: false
      },
      professional: {
        include: false
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Priority support',
    plans: {
      essential: {
        include: false
      },
      professional: {
        include: false
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Consulting discount',
    plans: {
      essential: {
        include: false
      },
      professional: {
        include: false
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'Data residency (future)',
    plans: {
      essential: {
        include: false
      },
      professional: {
        include: false
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'SSO (future)',
    plans: {
      essential: {
        include: false
      },
      professional: {
        include: false
      },
      enterprise: {
        include: true
      }
    }
  },
  {
    name: 'MFA (future)',
    plans: {
      essential: {
        include: false
      },
      professional: {
        include: false
      },
      enterprise: {
        include: true
      }
    }
  }
]

const getFeatureListByPlan = PLAN_LIST.map(plan => {
  return {
    name: plan,
    features: FEATURE_LIST.reduce((acc, feature) => {
      acc = {
        ...acc,
        [feature.name]: {
          ...feature.plans[plan]
        }
      }
      return acc
    }, {})
  }
})

const onComparePlans = () => {
  showFeatureLists.value = !showFeatureLists.value
  tracker.logEvent('plans comparison show', {
    category: EVENT_CATEGORIES.BILLING
  })
}
</script>

<style lang="scss" scoped>
.fl-ComparePlansButton {
  margin: 0 auto;
  font-weight: fw('semi-bold');
}
.fl-FeatureLists {
  &_Wrapper {
    display: grid;
    gap: 32px;
    grid-template-columns: 310px 1fr 1fr 1fr;
    margin: -44px auto auto auto;
  }
  &:before {
    display: block;
    content: '';
    width: 100%;
    height: 40px;
    position: sticky;
    top: 44px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 15%,
      rgba(255, 255, 255, 0.4) 80%,
      rgba(167, 105, 246, 0) 100%
    );
  }
}
.fl-FeatureList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.fl-FeatureItem_Label {
  display: flex;
  color: $dark-2;
  font-size: $fs-14;
  font-style: normal;
  font-weight: fw('semi-bold');
  line-height: 24px;
  border-bottom: 1px solid $grey-2-next;
  padding: 0 32px 8px 32px;
}
.fl-FeatureItem_Title {
  color: $dark-1;
  font-size: $fs-20;
  font-weight: fw('semi-bold');
  line-height: 28px;
  border-bottom: 2px solid $grey-2-next;
  padding: 0 32px 16px 32px;
  height: 44px;
  position: sticky;
  top: 0;
  background-color: $white;
}
</style>
