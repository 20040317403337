<template>
  <div class="wg-Wrapper" data-testid="workspace-groups-tab">
    <template v-if="!isNoGroups">
      <div class="wg-Wrapper_Toolbar" data-testid="groups-toolbar">
        <div class="wg-ToolbarFilter">
          <OkrFilterSelect
            :bottom-fixed-items="
              getFilterBottomItems({
                filtersValues: filtersValues,
                filterKey: GROUP_IDS
              })
            "
            :dropdown-min-width="300"
            :loading="initialDataLoading"
            :model-value="filtersValues[GROUP_IDS]"
            :options="groupsFilterOptions"
            :search-function="searchString => fetchGroupsForFilter({ searchString })"
            has-only-this-button
            n-selected-label="filter.groups"
            prepend-icon="team-next"
            @update:options="groupsFilterOptions = $event"
            @update:model-value="onSelectFilterValue($event, GROUP_IDS)"
          >
            <template #option-label="{ option }">
              <GlobalGroupsSelectOption v-if="option" :group="option" />
            </template>

            <template #bottom-fixed-items="{ bottomFixedItems }">
              <div v-for="item in bottomFixedItems" :key="item.id">
                <BottomFixedSelectItem
                  v-if="isClearSelectionAction(item.action)"
                  @click="clearSelection(GROUP_IDS)"
                >
                  {{ $t(item.text) }}
                </BottomFixedSelectItem>
              </div>
            </template>
          </OkrFilterSelect>
        </div>

        <AppButton
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="showAddGroupsToWorkspaceModal"
        >
          {{ $t('global_groups.add_groups') }}
        </AppButton>
      </div>

      <div class="wg-Wrapper_Table" data-testid="groups-table-wrapper">
        <TableExpandable
          ref="tableReference"
          :child-items="childItems"
          :columns="COLUMNS"
          :data="groups"
          :disable-user-select="false"
          :hover-row="tableHoverRow"
          :loading="isLoading || isGroupsLoading || initialDataLoading"
          :row-is-expandable="rowIsExpandable"
          class="wg-Table"
          hide-expand-button-in-no-expandable-row
          item-value="uniqId"
          offset-left="40px"
          offset-right="40px"
          sticky-header
          @expand="onExpandRow"
        >
          <template #header-cell="{ column }">
            <div v-if="column.title" class="wg-HeadCell">
              <template v-if="column.key === TABLE_COLUMNS_KEYS.LINKED_GROUPS">
                {{ $t(column.title, { platform: $t('app.platform.jira') }) }}

                <AppIcon
                  v-tippy="{
                    content: $t('workspaces.groups_tab.linked_groups.tooltip'),
                    placement: 'top'
                  }"
                  height="24"
                  icon-name="info-next"
                  width="24"
                />
              </template>

              <template v-else>
                {{ $t(column.title) }}
              </template>
            </div>
          </template>

          <template #cell="{ item, columnKey, index, depth }">
            <template v-if="columnKey === TABLE_COLUMNS_KEYS.GROUP">
              <GlobalGroupNameCell
                v-if="item.type === TABLE_ITEM_TYPES.GROUP"
                :actions-list="[GLOBAL_GROUPS_NAME_CELL_ACTIONS.MANAGE_USERS]"
                :data-testid="`name-cell-${item.uniqId}`"
                :group="item"
                :labels-list="[GLOBAL_GROUPS_NAME_CELL_LABELS.USERS_COUNT]"
                hide-warning-message
                style="--cell-padding-right: 0"
                @click="expandManually({ item, index, depth })"
                @manage-group="manageGroupUsers(item)"
              />

              <GroupNameCell
                v-if="item.type === TABLE_ITEM_TYPES.CATEGORY"
                class="wg-CategoryButton"
                hide-icon
                @click="expandManually({ item, index, depth })"
              >
                {{ item.label }}

                <template #count>
                  {{ item.count }}
                </template>
              </GroupNameCell>

              <UserNameCell
                v-if="item.type === TABLE_ITEM_TYPES.USER"
                :item="item"
                item-label="name"
                name-as-link
              />
            </template>

            <template v-if="columnKey === TABLE_COLUMNS_KEYS.SYNC && isShowSyncIconCell(item)">
              <div
                :class="{ 'wg-SyncCell-user': item.type === TABLE_ITEM_TYPES.USER }"
                class="wg-SyncCell"
              >
                <AppIcon height="24" icon-name="replace_jira" width="24" />
              </div>
            </template>

            <div
              v-if="columnKey === TABLE_COLUMNS_KEYS.LINKED_GROUPS && isShowSyncIconCell(item)"
              class="wg-LinkedGroupsCell"
            >
              <CellSelectItemsList
                v-if="item.type === TABLE_ITEM_TYPES.CATEGORY"
                :items="item.linkedGroups"
                item-value="name"
                show-native-tooltip
                user-has-read-access
                user-has-update-access
              />
              <div v-else class="wg-LinkedGroupsCell_Message">
                {{ $t('workspaces.linked_user_message') }}
              </div>
            </div>

            <UserRoleCell
              v-if="columnKey === TABLE_COLUMNS_KEYS.ROLE && item.type === TABLE_ITEM_TYPES.USER"
              :account-id="item.accountId"
              :dropdown-width="`${ROLES_CELL_WIDTH}px`"
              :role-id="item.roleId"
              :roles="roles"
              :selected-groups="item.groups"
              :tracking-source="EVENT_SOURCES.WS_GROUPS_TAB"
              :user-workspaces-data="item.workspaces"
              :workspace-id="props.workspaceData.id"
              append-to=".wg-Table"
              @update-role-id="onUpdateRole(item, $event)"
            />

            <div v-if="columnKey === TABLE_COLUMNS_KEYS.ACTIONS" class="wg-ActionsCell">
              <template v-if="isRowWithDropdownMenu(item)">
                <DropdownMenu
                  v-if="isShowDropdownMenu(item)"
                  :items="getDropdownMenuItems(item)"
                  :offset="[0, 0]"
                  preferred-position="bottom-end"
                  type="default-next"
                  @close="tableHoverRow = null"
                  @open="tableHoverRow = item.uniqId"
                  @item-click="onMenuActionsClick($event, item)"
                >
                  <template #activator>
                    <AppButton icon="more-next" size="sm" type="tertiary-next" />
                  </template>
                </DropdownMenu>

                <AppButton
                  v-else
                  v-tippy="{
                    ...getSyncedUsersActionsTooltipOptions(item)
                  }"
                  disable
                  icon="more-next"
                  size="sm"
                  type="tertiary-next"
                />
              </template>

              <AppButton
                v-if="item.type === TABLE_ITEM_TYPES.USER"
                v-tippy="{
                  theme: 'translucent-next text-center word-break',
                  content: getUserDeleteButtonTooltipContent(item),
                  placement: 'top-start'
                }"
                :disable="!item.canBeRemovedFromGroup || !item.hasUpdateGroupAccess"
                icon="delete-next"
                size="sm"
                type="tertiary-next"
                @click="showUserDeleteModal(item)"
              />
            </div>
          </template>

          <template #row-wrapper-after="{ item, childOffset }">
            <AppTableCreateButton
              v-if="isShowAddButton(item.uniqId)"
              v-tippy="{
                ...getActionsTooltipOptions(item),
                offset: [childOffset + 40, 8]
              }"
              :data-testid="`add-user-button-${item.uniqId}`"
              :disable="!userCanUpdateGroup(item)"
              :style="{ '--offset': `${childOffset}px` }"
              class="wg-AddUserButton"
              full-width
              icon-name="plus-next"
              @click="manageGroupUsers(item)"
            >
              <template #before>
                <ContributeLine class="wg-AddUserButton_ContributeLine" />
              </template>
              {{ $t('global_groups.manage_users') }}
            </AppTableCreateButton>

            <WorkspaceGroupsNestedUsersLoader
              v-if="isShowChildLoader(item)"
              :style="{ '--offset': `${childOffset}px` }"
            />

            <AppLoadMoreButton
              v-if="isShowChildLoadMoreButton(item)"
              :data-testid="`load-more-${item.uniqId}`"
              :style="{ '--offset': `${childOffset}px` }"
              class="wg-LoadMoreButton"
              @click="loadMoreUsers(item)"
            />
          </template>

          <template #loading>
            <WorkspaceGroupsTableLoader />
          </template>

          <template #footer>
            <AppTableCreateButton
              class="wg-FooterCreateButton"
              full-width
              icon-name="plus-next"
              @click="showAddGroupsToWorkspaceModal"
            >
              {{ $t('global_groups.add_groups') }}
            </AppTableCreateButton>
          </template>
        </TableExpandable>
      </div>
    </template>

    <SettingsPagesEmptyState
      v-if="isNoGroups"
      data-testid="groups-empty-state"
      hero-height="128"
      hero-icon="no-groups-hero"
      hero-width="174"
    >
      <template #title> {{ $t('workspaces.no_groups.title') }} </template>
      <template #subtitle> {{ $t('workspaces.no_groups.subtitle') }} </template>

      <template #action>
        <AppButton
          data-testid="empty-state-create-button"
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="showAddGroupsToWorkspaceModal"
        >
          {{ $t('global_groups.add_groups') }}
        </AppButton>
      </template>
    </SettingsPagesEmptyState>

    <AppDialog
      :show="isShowDeleteGroupModal"
      :title="$t('global_groups.remove_from_workspace')"
      :type="DIALOG_TYPES.DELETE"
      data-auto-testid="delete-group-modal"
      data-testid="delete-group-modal"
      @on-confirm="onDeleteGroup"
      @on-close="closeGroupDeleteModal"
    >
      <i18n-t keypath="global_groups.remove_from_workspace.description" scope="global">
        <template #groupName>
          <span class="wg-DangerText">
            {{ groupToDelete?.name }}
          </span>
        </template>
      </i18n-t>

      <template #confirm-btn-text>
        {{ $t('action.remove') }}
      </template>
    </AppDialog>

    <GroupsModalsHub
      ref="modalsHubReference"
      :groups="groups"
      :link-group-tracking-source="EVENT_SOURCES.WS_GROUPS_TAB"
      :unlink-group-tracking-source="EVENT_SOURCES.WS_GROUPS_TAB"
      :workspace-id="workspaceData.id"
      @reload-all-groups="onReloadAllGroups"
      @user-deleted="reloadGroup"
      @reload-group="reloadGroup"
      @child-modal-opened="emit('child-modal-opened')"
      @child-modal-closed="emit('child-modal-closed')"
    />

    <portal to="modal-windows">
      <ManageGlobalGroupModal
        :group-for-manage="groupForManage"
        :show="isShowManageGroupModal"
        :tracking-source="EVENT_SOURCES.WS_GROUP_TAB_GRID"
        hide-modal-actions
        @on-close="onCloseManageGroupModal"
      />
    </portal>
  </div>
</template>

<script setup>
import { cloneDeep, has, isEmpty, uniqBy } from 'lodash'
import { computed, onMounted, ref, watch, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { getFilterBottomItems, isClearSelectionAction } from '@/composables/bottom-fixed-items'
import { useGlobalGroups } from '@/composables/global-groups'
import { useFetchRoles } from '@/composables/plugin-users'
import {
  EVENT_SECTIONS,
  EVENT_SOURCES,
  EVENT_SUBCATEGORIES,
  FILTER_LABELS_FOR_TRACKING,
  MODE_NAMES_FOR_TRACKING,
  TAB_NAMES_FOR_TRACKING,
  trackAddRemoveGroupToWorkspaceEvent,
  trackApplySettingsPagesFilterEvent,
  trackForceSyncJiraGroups,
  trackRemoveUserEvent
} from '@/tracking/amplitude-helpers'
import { useFilterByAccessLevel } from '@/utils/access-level-filter'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { ENTITIES_ACCESS_LEVELS } from '@/utils/entities-access-levels'
import { handleError } from '@/utils/error-handling'
import {
  GLOBAL_GROUPS_NAME_CELL_ACTIONS,
  GLOBAL_GROUPS_NAME_CELL_LABELS,
  GLOBAL_GROUPS_SEARCH_TYPES,
  userCanUpdateGroup
} from '@/utils/global-groups'
import { memoizeGetGlobalGroupActionsTooltipOptions } from '@/utils/memoizations'
import { showNotify } from '@/utils/notify'
import { MENU_ITEMS_GROUPS } from '@/utils/objectives'
import { DEFAULT_VALUE_FOR_FILTER } from '@/utils/okr-elements/filters'
import {
  getNewSelectWithSelectAllValue,
  getSelectWithSelectAllApiParameter,
  selectAllIsSelected
} from '@/utils/select'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import {
  ACCOUNT_ID,
  DEFAULT_GROUPS_LIMIT,
  DEFAULT_USERS_LIMIT,
  ERRORS_TEXTS,
  ID,
  SYNCED,
  TABLE_ITEM_TYPES,
  USERS
} from '@/utils/workspace-configuration-modal-helpers'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppDialog from '@/components/AppDialog'
import GlobalGroupNameCell from '@/components/global-groups/GlobalGroupNameCell'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import ManageGlobalGroupModal from '@/components/global-groups/ManageGlobalGroupModal'
import GroupNameCell from '@/components/GroupNameCell'
import ContributeLine from '@/components/objectives/forms/ContributeLine'
import CellSelectItemsList from '@/components/objectives/table/cells/CellSelectItemsList'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppLoadMoreButton from '@/components/ui/AppLoadMoreButton/AppLoadMoreButton'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import WorkspaceGroupsNestedUsersLoader from '@/components/ui/SkeletonLoaders/WorkspaceGroupsNestedUsersLoader'
import WorkspaceGroupsTableLoader from '@/components/ui/SkeletonLoaders/WorkspaceGroupsTableLoader'
import TableExpandable from '@/components/ui/TableExpandable/TableExpandable'
import GroupsModalsHub from '@/components/workspaces/configuration-modal/GroupsModalsHub'
import UserNameCell from '@/views/workspaces/settings/plugin-users/UserNameCell'
import UserRoleCell from '@/views/workspaces/settings/plugin-users/UserRoleCell'
import { USERS_QUERY_KEYS } from '@/views/workspaces/settings/plugin-users/users-query-params'

defineOptions({
  name: 'WorkspaceGroups'
})

const props = defineProps({
  workspaceData: {
    type: Object,
    required: true
  }
})

const emit = defineEmits({
  'child-modal-opened': null,
  'child-modal-closed': null
})

const store = useStore()
const isWebApp = inject('isWebApp')

const ROLES_CELL_WIDTH = 152
const isJiraConnected = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
)

const INITIAL_COLUMNS = computed(() => {
  return [
    {
      title: 'group.label.name',
      key: TABLE_COLUMNS_KEYS.GROUP,
      width: 'auto',
      accessLevel: ENTITIES_ACCESS_LEVELS.JIRA_APP | ENTITIES_ACCESS_LEVELS.WEB_APP
    },
    {
      key: TABLE_COLUMNS_KEYS.SYNC,
      width: 32,
      accessLevel: ENTITIES_ACCESS_LEVELS.JIRA_APP
    },
    {
      title: 'workspaces.groups_tab.linked_platform_groups',
      key: TABLE_COLUMNS_KEYS.LINKED_GROUPS,
      width: 258,
      accessLevel: ENTITIES_ACCESS_LEVELS.JIRA_APP
    },
    {
      title: 'workspaces.role',
      key: TABLE_COLUMNS_KEYS.ROLE,
      width: ROLES_CELL_WIDTH,
      accessLevel: ENTITIES_ACCESS_LEVELS.JIRA_APP | ENTITIES_ACCESS_LEVELS.WEB_APP
    },
    {
      key: TABLE_COLUMNS_KEYS.ACTIONS,
      width: 24,
      accessLevel: ENTITIES_ACCESS_LEVELS.JIRA_APP | ENTITIES_ACCESS_LEVELS.WEB_APP
    }
  ].map(column => {
    const accessLevel =
      isWebApp && isJiraConnected.value
        ? ENTITIES_ACCESS_LEVELS.JIRA_APP | ENTITIES_ACCESS_LEVELS.WEB_APP
        : column.accessLevel

    return {
      ...column,
      accessLevel
    }
  })
})

const { getEntitiesForPlatform } = useFilterByAccessLevel()

const COLUMNS = getEntitiesForPlatform({ entities: INITIAL_COLUMNS.value })

const GROUP_NESTED_ITEMS = {
  USERS: USERS,
  SYNCED: SYNCED
}

const tableHoverRow = ref(null)

const groupsFilter = ref(DEFAULT_VALUE_FOR_FILTER)

const { GROUP_IDS } = USERS_QUERY_KEYS

const filtersValues = ref({
  [GROUP_IDS]: cloneDeep(DEFAULT_VALUE_FOR_FILTER)
})

const enrichResult = ({ items, key = ID, type = TABLE_ITEM_TYPES.GROUP }) => {
  return items.map(item => {
    return {
      ...item,
      uniqId: `${type}-${item[key]}`,
      type
    }
  })
}

const normalizeUsersResult = ({ users = [], groupId = null, categoryKey = null } = {}) => {
  if (!groupId || !categoryKey) {
    throw new Error(ERRORS_TEXTS.NORMALIZE_USER_REQUIRED_KEYS)
  }
  if (isEmpty(users)) {
    return []
  }

  return enrichResult({
    items: users,
    key: ACCOUNT_ID,
    type: TABLE_ITEM_TYPES.USER
  }).map(user => {
    let canBeRemovedFromGroup = true

    const group = user.groups.find(group => group.id === groupId)
    if (group) {
      canBeRemovedFromGroup = !group.userSyncedInGroup
    }

    let hasUpdateGroupAccess = false

    const currentParentGroup = cloneDeep(findGroupById(groupId))

    if (currentParentGroup) {
      hasUpdateGroupAccess = userCanUpdateGroup(currentParentGroup)
    }

    return {
      ...user,
      currentGroupId: groupId,
      categoryKey: categoryKey,
      canBeRemovedFromGroup,
      hasUpdateGroupAccess,
      roleId: findUserRole(user)
    }
  })
}

const { fetchGroupsForFilter, ALL_GROUPS_OPTION } = useGlobalGroups()

const isLoading = ref(false)
const isGroupsLoading = ref(false)
const groups = ref([])
const groupsFilterOptions = ref([ALL_GROUPS_OPTION])

const fetchGroups = async (searchString = null) => {
  isLoading.value = true

  const api = new GlobalGroupsApiHandler()

  try {
    const result = await api.getGlobalGroups({
      workspaceIds: [props.workspaceData.id],
      startAt: 0,
      limit: DEFAULT_GROUPS_LIMIT,
      searchString,
      searchTypeId: GLOBAL_GROUPS_SEARCH_TYPES.PLAIN
    })

    return enrichResult({
      items: result,
      key: ID,
      type: TABLE_ITEM_TYPES.GROUP
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}
const roles = ref([])
const initialDataLoading = ref(false)
const getInitData = async () => {
  initialDataLoading.value = true
  try {
    const [rolesList, groupsList, groupsForFilter] = await Promise.all([
      useFetchRoles(),
      fetchGroups(),
      fetchGroupsForFilter({ workspaceIds: [props.workspaceData.id] })
    ])

    roles.value = rolesList
    groups.value = [...groups.value, ...cloneDeep(groupsList)]
    groupsFilterOptions.value = [
      {
        ...ALL_GROUPS_OPTION
      },
      ...groupsFilterOptions.value,
      ...cloneDeep(groupsForFilter)
    ]
  } catch (error) {
    handleError({ error })
  } finally {
    initialDataLoading.value = false
  }
}

const tableReference = ref(null)
const reloadAllGroups = async () => {
  const api = new GlobalGroupsApiHandler()

  isGroupsLoading.value = true

  try {
    const groupIds = getSelectWithSelectAllApiParameter(filtersValues.value[GROUP_IDS])

    const result = await api.getGlobalGroups({
      workspaceIds: [props.workspaceData.id],
      startAt: 0,
      limit: DEFAULT_GROUPS_LIMIT,
      groupIds,
      searchTypeId: GLOBAL_GROUPS_SEARCH_TYPES.PLAIN
    })

    const normalizedResult = enrichResult({
      items: result,
      key: ID,
      type: TABLE_ITEM_TYPES.GROUP
    })

    childItems.value = {}
    listState.value = {}
    tableReference.value.collapseAll()

    groups.value = [...normalizedResult]
  } catch (error) {
    handleError({ error })
  } finally {
    isGroupsLoading.value = false
  }
}

onMounted(() => {
  getInitData()
})

const clearSelection = async filterKey => {
  if (filterKey) {
    filtersValues.value[filterKey] = cloneDeep(DEFAULT_VALUE_FOR_FILTER)
  }

  await reloadAllGroups()
}

const onSelectFilterValue = async (newValue, filterKey) => {
  if (filterKey) {
    filtersValues.value[filterKey] = getNewSelectWithSelectAllValue(
      newValue,
      filtersValues.value[filterKey]
    )

    trackApplySettingsPagesFilterEvent({
      section: EVENT_SECTIONS.WORKSPACE,
      tab: TAB_NAMES_FOR_TRACKING.GROUPS,
      label: FILTER_LABELS_FOR_TRACKING.GROUP
    })

    await reloadAllGroups()
  }
}

const isNoGroups = computed(() => {
  return (
    !initialDataLoading.value &&
    !isLoading.value &&
    !isGroupsLoading.value &&
    isEmpty(groups.value) &&
    (selectAllIsSelected(groupsFilter.value) || isEmpty(groupsFilter.value))
  )
})

const createGroupKey = groupId => `${TABLE_ITEM_TYPES.GROUP}-${groupId}`
const createCategoryKey = ({ groupKey, category }) => `${groupKey}-${category}`

const rowIsExpandable = computed(() => {
  return groups.value.reduce((acc, group) => {
    const groupKey = createGroupKey(group.id)
    acc[groupKey] = true
    acc[createCategoryKey({ groupKey, category: USERS })] = true
    acc[createCategoryKey({ groupKey, category: SYNCED })] = true
    return acc
  }, {})
})

const childItems = ref({})

const loadMoreUsers = item => {
  getGroupUsers({ item })
}

const reloadGroupAndUsers = async ({ groupId }) => {
  const globalGroupsApi = new GlobalGroupsApiHandler()

  let isNeedToUpdateUsers = true
  let isNeedToUpdateSyncedUsers = true

  const groupKey = createGroupKey(groupId)

  const usersCategoryKey = createCategoryKey({
    groupKey,
    category: USERS
  })

  const syncedCategoryKey = createCategoryKey({
    groupKey,
    category: SYNCED
  })

  try {
    const result = await globalGroupsApi.getGlobalGroupById({
      startAt: 0,
      limit: 1,
      groupId
    })

    const [normalizedGroup] = enrichResult({
      items: [result],
      key: ID,
      type: TABLE_ITEM_TYPES.GROUP
    })

    if (normalizedGroup.workspaces.map(ws => ws.id).includes(props.workspaceData.id)) {
      // replace updated group in groups list
      groups.value = groups.value.map(group => {
        if (group.id === normalizedGroup.id) {
          return normalizedGroup
        }
        return group
      })

      addCategoriesToGroup({ item: normalizedGroup })

      isNeedToUpdateUsers =
        Boolean(normalizedGroup.notSyncedUserCount) && !isEmpty(childItems.value[usersCategoryKey])
      isNeedToUpdateSyncedUsers =
        Boolean(normalizedGroup.syncedUserCount) && !isEmpty(childItems.value[syncedCategoryKey])
    } else {
      // remove group from groups list
      removeGroupFromInnerData({
        id: groupId
      })

      isNeedToUpdateUsers = false
      isNeedToUpdateSyncedUsers = false
    }
  } catch (error) {
    handleError({ error })
  }

  const usersListStateKey = listState.value[usersCategoryKey]
  const syncedUsersListStateKey = listState.value[syncedCategoryKey]

  if (!usersListStateKey || !syncedUsersListStateKey) {
    throw new Error('listState is incorrect')
  }

  const defaultUsersPayload = {
    groupId,
    startAt: 0
  }

  const usersPayload = {
    ...defaultUsersPayload,
    synced: false,
    limit:
      usersListStateKey.startAt < DEFAULT_USERS_LIMIT
        ? DEFAULT_USERS_LIMIT
        : usersListStateKey.startAt
  }

  const syncedPayload = {
    ...defaultUsersPayload,
    synced: true,
    limit:
      syncedUsersListStateKey.startAt < DEFAULT_USERS_LIMIT
        ? DEFAULT_USERS_LIMIT
        : syncedUsersListStateKey.startAt
  }

  const isUpdateAllUsers = isNeedToUpdateUsers && isNeedToUpdateSyncedUsers
  const isUpdateOnlyUsers = isNeedToUpdateUsers && !isNeedToUpdateSyncedUsers
  const isUpdateOnlySyncedUsers = !isNeedToUpdateUsers && isNeedToUpdateSyncedUsers

  try {
    const requestMethod = payload => globalGroupsApi.getUsersFromGlobalGroup(payload)

    const emptyResponse = {
      users: [],
      total: 0
    }

    const requests = []
    if (isUpdateAllUsers) {
      requests.push(requestMethod(usersPayload))
      requests.push(requestMethod(syncedPayload))
    } else if (isUpdateOnlyUsers) {
      requests.push(requestMethod(usersPayload))
      requests.push({
        ...emptyResponse
      })
    } else if (isUpdateOnlySyncedUsers) {
      requests.push({
        ...emptyResponse
      })
      requests.push(requestMethod(syncedPayload))
    } else {
      requests.push(
        {
          ...emptyResponse
        },
        {
          ...emptyResponse
        }
      )
    }

    const [usersData, syncedUsersData] = await Promise.all(requests)

    const normalizedUsers = normalizeUsersResult({
      users: usersData.users,
      groupId,
      categoryKey: usersCategoryKey
    })

    const normalizedSyncedUsers = normalizeUsersResult({
      users: syncedUsersData.users,
      groupId,
      categoryKey: syncedCategoryKey
    })

    usersListStateKey.startAt = normalizedUsers.length
    syncedUsersListStateKey.startAt = normalizedSyncedUsers.length

    childItems.value = {
      ...childItems.value,
      [usersCategoryKey]: normalizedUsers,
      [syncedCategoryKey]: normalizedSyncedUsers
    }

    if (isEmpty(normalizedUsers)) {
      delete childItems.value[usersCategoryKey]
    }

    if (isEmpty(normalizedSyncedUsers)) {
      delete childItems.value[syncedCategoryKey]
    }
  } catch (error) {
    handleError({ error })
  }
}
const getGroupUsers = async ({ item }) => {
  const globalGroupsApi = new GlobalGroupsApiHandler()
  const synced = item.category === SYNCED

  const listStateItem = listState.value[item.uniqId]

  listStateItem.isLoading = true
  try {
    const { users } = await globalGroupsApi.getUsersFromGlobalGroup({
      groupId: item.groupId,
      synced,
      limit: DEFAULT_USERS_LIMIT,
      startAt: listStateItem.startAt
    })

    const key = item.uniqId

    const normalizedResult = normalizeUsersResult({
      users,
      groupId: item.groupId,
      categoryKey: key
    })

    const combinedResult = uniqBy(
      [...(childItems.value[key] || []), ...normalizedResult],
      ACCOUNT_ID
    )

    listStateItem.startAt = combinedResult.length

    if (!isEmpty(combinedResult)) {
      childItems.value = {
        ...childItems.value,
        [key]: combinedResult
      }
    }
  } catch (error) {
    handleError({ error })
  } finally {
    listStateItem.isLoading = false
  }
}

const { t } = useI18n()

const addCategoriesToGroup = ({ item }) => {
  const { workspaceId, id, uniqId, name } = item

  const DEFAULT_ITEM = {
    calculateHeightOfNestedItemsOnlyForRows: true,
    workspaceId,
    groupId: id,
    groupName: name,
    type: TABLE_ITEM_TYPES.CATEGORY
  }

  const USERS_ITEM = {
    category: USERS,
    ...DEFAULT_ITEM,
    uniqId: `${uniqId}-${USERS}`,
    count: item.notSyncedUserCount,
    label: t('common.users')
  }

  const SYNCED_USERS_ITEM = {
    ...DEFAULT_ITEM,
    category: SYNCED,
    uniqId: `${uniqId}-${SYNCED}`,
    count: item.syncedUserCount,
    label: t('workspaces.users_from_platform', { platform: t('app.platform.jira') }),
    linkedGroups: item.associatedPlatformGroups
  }

  const nestedItems = []

  if (item.notSyncedUserCount) {
    nestedItems.push(USERS_ITEM)
  }

  if (item.syncedUserCount) {
    nestedItems.push(SYNCED_USERS_ITEM)
  }

  childItems.value = {
    ...childItems.value,
    [uniqId]: [...nestedItems]
  }
}

const expandManually = ({ item, index, depth }) => {
  tableReference.value.expand({ item, index, depth })
}

const onExpandRow = row => {
  const { item } = row
  if (Object.values(GROUP_NESTED_ITEMS).includes(item.category)) {
    getGroupUsers({ item })
  } else {
    addCategoriesToGroup({ item })
  }
}

const isShowAddButton = uniqId => groups.value.some(group => group.uniqId === uniqId)

const isShowChildLoadMoreButton = item => {
  if (item.type === TABLE_ITEM_TYPES.CATEGORY) {
    return (
      childItems.value[item.uniqId]?.length < item.count && !listState.value[item.uniqId].isLoading
    )
  }
  return false
}

const isShowChildLoader = item => {
  return item.type === TABLE_ITEM_TYPES.CATEGORY && listState.value[item.uniqId].isLoading
}

const findUserRole = user => {
  return (
    user.workspaces.find(workspace => workspace.workspaceId === props.workspaceData.id)?.roleId || 0
  )
}
const onUpdateRole = (user, newRole) => {
  const { accountId } = user

  //  update user role in all groups
  for (const key in childItems.value) {
    const users = childItems.value[key]
    const userIndex = users.findIndex(user => user.accountId === accountId)

    if (userIndex !== -1) {
      users[userIndex].roleId = newRole
    }
  }
}

const isShowSyncIconCell = item => {
  if (item.type === TABLE_ITEM_TYPES.USER) {
    return !item.canBeRemovedFromGroup
  }
  if (item.type === TABLE_ITEM_TYPES.CATEGORY) {
    return item.category === SYNCED && !isEmpty(item.linkedGroups)
  }
  return false
}

const isRowWithDropdownMenu = item => {
  if (item.type === TABLE_ITEM_TYPES.CATEGORY) {
    return item.category === SYNCED
  }

  return item.type === TABLE_ITEM_TYPES.GROUP
}

const findGroupById = groupId => {
  return groups.value.find(group => group.id === groupId)
}

const isShowDropdownMenu = item => {
  if (item.type === TABLE_ITEM_TYPES.GROUP) {
    return userCanUpdateGroup(item)
  }

  const parentGroup = findGroupById(item.groupId)
  if (!parentGroup) return false

  if (isWebApp) return false

  return userCanUpdateGroup(parentGroup)
}

const { REMOVING, ACTIONS } = MENU_ITEMS_GROUPS

const getDropdownMenuItems = item => {
  if (item.type === TABLE_ITEM_TYPES.GROUP) {
    return [
      {
        name: ACTIONS_KEYS.MANAGE_GROUP,
        icon: 'edit-next',
        title: 'global_groups.manage_group',
        group: ACTIONS
      },
      {
        name: ACTIONS_KEYS.REMOVE,
        title: 'action.remove_from_ws',
        icon: 'delete-next',
        color: 'var(--grade-low-color-next)',
        group: REMOVING
      }
    ]
  }

  if (item.type === TABLE_ITEM_TYPES.CATEGORY) {
    return [
      {
        name: ACTIONS_KEYS.SYNC_NOW,
        title: 'dropdown.sync_now',
        icon: 'sync-next',
        group: ACTIONS
      },
      {
        name: ACTIONS_KEYS.LINK_NEW_PLATFORM_GROUP,
        label: t('dropdown.link_new_platform_groups', { platform: t('app.platform.jira') }),
        icon: 'link-task',
        group: ACTIONS
      },
      {
        name: ACTIONS_KEYS.UNLINK_PLATFORM_GROUP,
        label: t('dropdown.unlink_platform_groups', { platform: t('app.platform.jira') }),
        icon: 'unlink-next',
        color: 'var(--grade-low-color-next)',
        group: REMOVING
      }
    ]
  }
}

const modalsHubReference = ref(null)

const getActionsTooltipOptions = group => {
  return memoizeGetGlobalGroupActionsTooltipOptions({
    group,
    t
  })
}

const getSyncedUsersActionsTooltipOptions = group => {
  const options = getActionsTooltipOptions(group)
  if (isWebApp) {
    return {
      ...options,
      content: t('global_groups.available_only_in_jira')
    }
  }

  return options
}

const groupForManage = ref(null)
const isShowManageGroupModal = ref(false)
const manageGroupUsers = group => {
  if (!userCanUpdateGroup(group)) {
    return
  }
  groupForManage.value = group
  isShowManageGroupModal.value = true
  emit('child-modal-opened')
}

const onManageGroupModalClose = () => {
  emit('child-modal-closed')
  isShowManageGroupModal.value = false
  groupForManage.value = null
}

const onCloseManageGroupModal = async groupId => {
  await reloadGroupAndUsers({ groupId })
  onManageGroupModalClose()
}
const showUserDeleteModal = user => {
  if (!user.canBeRemovedFromGroup || !user.hasUpdateGroupAccess) {
    return
  }

  modalsHubReference.value.showUserDeleteModal({
    user
  })
}

const showAddGroupsToWorkspaceModal = () => {
  modalsHubReference.value.showAddGroupsToWorkspaceModal()
}

const getUserDeleteButtonTooltipContent = user => {
  const { canBeRemovedFromGroup, hasUpdateGroupAccess } = user

  if (!canBeRemovedFromGroup) {
    return t('workspaces.linked_user_message')
  }

  if (!hasUpdateGroupAccess) {
    return t('global_groups.unavailable_group')
  }

  return null
}

const groupToDelete = ref(null)
const isShowDeleteGroupModal = ref(false)
const onMenuActionsClick = (action, item) => {
  if (action === ACTIONS_KEYS.MANAGE_GROUP) {
    manageGroupUsers(item)
  }

  if (action === ACTIONS_KEYS.REMOVE) {
    groupToDelete.value = item
    isShowDeleteGroupModal.value = true
  }

  if (action === ACTIONS_KEYS.SYNC_NOW) {
    syncGroup(item.groupId)
  }

  if (action === ACTIONS_KEYS.LINK_NEW_PLATFORM_GROUP) {
    modalsHubReference.value.showLinkPlatformGroupsModal({
      group: item
    })
  }

  if (action === ACTIONS_KEYS.UNLINK_PLATFORM_GROUP) {
    modalsHubReference.value.showUnlinkPlatformGroupsModal({
      group: item
    })
  }
}

const syncGroup = async groupId => {
  const api = new GlobalGroupsApiHandler()
  try {
    await api.syncGlobalGroup({
      groupId
    })

    trackForceSyncJiraGroups({
      source: EVENT_SOURCES.WS_GROUPS_TAB,
      role: userRoleForTracking.value
    })

    await reloadGroupAndUsers({ groupId })
  } catch (error) {
    handleError({ error })
  }
}

const closeGroupDeleteModal = () => {
  isShowDeleteGroupModal.value = false
  groupToDelete.value = null
}

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

const removeGroupFromInnerData = ({ id }) => {
  const groupKey = createGroupKey(id)
  const usersCategoryKey = createCategoryKey({ groupKey, category: USERS })
  const syncedCategoryKey = createCategoryKey({ groupKey, category: SYNCED })

  groups.value = groups.value.filter(group => group.id !== id)
  groupsFilterOptions.value = groupsFilterOptions.value.filter(group => group.id !== id)
  onSelectFilterValue(
    filtersValues.value[GROUP_IDS].filter(groupId => groupId !== id),
    GROUP_IDS
  )
  delete childItems.value[groupKey]
  delete childItems.value[usersCategoryKey]
  delete childItems.value[syncedCategoryKey]
}

const onDeleteGroup = async () => {
  if (!isEmpty(groupToDelete.value)) {
    const { id } = groupToDelete.value

    const api = new GlobalGroupsApiHandler()

    try {
      await api.removeGlobalGroupFromWorkspace({
        group: groupToDelete.value,
        workspaceIdForRemove: props.workspaceData.id
      })
      trackAddRemoveGroupToWorkspaceEvent({
        eventName: 'Group removed from a workspace',
        subcategory: EVENT_SUBCATEGORIES.SINGLE,
        label: props.workspaceData.id,
        source: EVENT_SOURCES.WS_GROUP_TAB_GRID,
        role: userRoleForTracking.value
      })
      removeGroupFromInnerData({
        id
      })
      showNotify({ title: t('notifications.groups_removed_from_ws') })
      closeGroupDeleteModal()
    } catch (error) {
      handleError({ error })
    }
  }
}

const reloadGroup = async ({ groupId, callBack }) => {
  trackRemoveUserEvent({
    mode: MODE_NAMES_FOR_TRACKING.GROUP,
    source: EVENT_SOURCES.WS_GROUPS_TAB
  })
  await reloadGroupAndUsers({ groupId })
  callBack()
}

const onReloadAllGroups = async ({ callBack }) => {
  await reloadAllGroups()
  callBack()
}

const listState = ref({})

watch(
  groups,
  value => {
    value.forEach(group => {
      const { uniqId } = group
      const usersCategory = createCategoryKey({
        groupKey: uniqId,
        category: USERS
      })

      const syncedCategory = createCategoryKey({
        groupKey: uniqId,
        category: SYNCED
      })

      if (!has(listState.value, usersCategory)) {
        listState.value[usersCategory] = {
          startAt: 0,
          isLoading: false
        }
      }

      if (!has(listState.value, syncedCategory)) {
        listState.value[syncedCategory] = {
          startAt: 0,
          isLoading: false
        }
      }
    })
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/workspace-configuration-modal';
@import '~@/assets/styles/app-table-helpers';
@import '~@/assets/styles/modal-danger-text';

.wg-Wrapper {
}

.wg-AddUserButton_ContributeLine {
  --height: 6px;
  position: absolute;
  left: calc(40px + 12px); // half of the expand button width, 40px - table offset
  top: 16px; // 10 px row padding top + 12px (half height of row content) - 6px height of Line
  --width: calc(
    var(--padding-left, 0) - 12px - 8px - 40px
  ); // 12px - left value, 8px gap between line and content, 40px - table offset
}

.wg-AddUserButton {
  --padding-left: calc(var(--offset, 0) + 40px); // 40px → table offset
}

.wg-LoadMoreButton {
  --margin-left: calc(
    var(--offset, 0) - 24px - 8px + 40px
  ); // 24px - icon width, 8px gap between icon and content. 40px - table offset
  min-height: 44px;
  margin-left: var(--margin-left);
  width: calc(100% - var(--margin-left) - 40px);
  justify-content: flex-start;
  border-bottom: 1px solid $grey-2-next;
  border-radius: 0;
}

.wg-CategoryButton {
  --label-color: #{$dark-3};
  --label-weight: #{fw('regular')};
}

.wg-Wrapper_Toolbar {
  @extend %tab-toolbar-styles;
}

.wg-ToolbarFilter {
  max-width: 220px;
  --select-skeleton-left: 0px;
  --select-skeleton-top: 0px;
}

.wg-Wrapper_Table {
  padding: 0 40px;
}

.wg-Table {
  font-family: $system-ui;
  --head-padding-top: #{$modal-tab-table-padding-top};
  --sticky-top: var(--wcm-tab-table-sticky-top, 0);
  font-style: normal;

  font-size: $fs-14;
  line-height: 20px;
}

.wg-HeadCell {
  min-height: 24px;
  display: flex;
  align-items: center;
}

.wg-LinkedGroupsCell {
  display: flex;
}

.wg-LinkedGroupsCell_Message {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;

  color: $dark-3;
}

.wg-SyncCell {
  display: flex;
  align-items: center;
}

.wg-ActionsCell,
.wg-LinkedGroupsCell_Message,
.wg-SyncCell-user {
  @extend %expandable-table-hidden-items;
}

.wg-FooterCreateButton {
  --padding-left: 40px;
}

.wg-DangerText {
  @extend %modal-danger-text;
}
</style>
