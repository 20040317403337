/*
  valueChanged .. whether value was changed in this function
*/
import { isEqual, cloneDeep } from 'lodash'

import { SELECT_ALL_VALUE, DEFAULT_VALUE_FOR_FILTER } from '@/utils/okr-elements/filters'

export const handleSelectInputWithSelectAll = (newValue, oldValue) => {
  let valueChanged = false
  // remove all values
  // -> select 'Select all'
  if (!oldValue.includes(SELECT_ALL_VALUE) && (newValue === null || newValue.length === 0)) {
    valueChanged = true
    return {
      valueChanged,
      result: cloneDeep(DEFAULT_VALUE_FOR_FILTER)
    }
  }

  // 'Select all' selected
  // -> remove all, keep only 'Select all'
  if (!oldValue.includes(SELECT_ALL_VALUE) && newValue.includes(SELECT_ALL_VALUE)) {
    valueChanged = true
    return {
      valueChanged,
      result: cloneDeep(DEFAULT_VALUE_FOR_FILTER)
    }
  }

  // 'Select all' is only one selected and were deselected
  // -> keep 'Select all' selected
  if (oldValue.includes(SELECT_ALL_VALUE) && newValue.length === 0) {
    valueChanged = true
    return {
      valueChanged,
      result: cloneDeep(DEFAULT_VALUE_FOR_FILTER)
    }
  }

  // 'Select all' was selected, select another option
  // -> deselect 'Select all', keep only another option
  if (oldValue.includes(SELECT_ALL_VALUE) && newValue.length > 1) {
    valueChanged = true
    return {
      valueChanged,
      result: newValue.filter(option => option !== SELECT_ALL_VALUE)
    }
  }

  return {
    valueChanged,
    result: newValue
  }
}

export const getNewSelectWithSelectAllValue = (newValue, oldValue) => {
  let newValueLocal = cloneDeep(newValue)
  if (newValueLocal === null) {
    newValueLocal = DEFAULT_VALUE_FOR_FILTER
  }

  const { valueChanged, result } = handleSelectInputWithSelectAll(newValueLocal, oldValue)
  if (valueChanged) {
    newValueLocal = result
  }

  return newValueLocal
}

export const getSelectWithSelectAllApiParameter = value => {
  // 'All selected' option
  if (selectAllIsSelected(value)) {
    return null
  }
  // return all selected options except 'All selected'
  // it's required because in some lists 'All selected' can be selected with other items
  // at the same moment, but should be never send to the server
  return value.filter(item => item !== SELECT_ALL_VALUE)
}

export const selectAllIsSelected = filterValue => isEqual(filterValue, DEFAULT_VALUE_FOR_FILTER)

export const CREATE_ON_SEARCH_ENTITIES = {
  LABEL: 'label',
  EMAIL: 'email'
}
