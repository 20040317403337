<template>
  <div class="mgg-SettingsTab">
    <FormFieldNext :label="$t('group.label.name')">
      <AppInput
        v-model="localName"
        :is-error="isNameEmpty"
        blur-on-enter
        data-testid="group-name-input"
        max-length="63"
        size="xlg"
        style-type="primary"
        @blur="onNameBlur"
        @focus="isNameEmpty = false"
      />

      <AppFieldError v-if="isNameEmpty" :show="isNameEmpty">
        {{ $t('field.required') }}
      </AppFieldError>
    </FormFieldNext>

    <div v-if="!isEmpty(group)" class="mgg-IconSettings">
      <GroupIconPicker
        v-model:selected-icon="localIcon"
        :display-as-dropdown="false"
        :selected-color="localColor"
        style="--padding: 0; --items-per-row: 11"
      />

      <AppPaletteColorPicker
        v-model:selected-color="localColor"
        :display-as-dropdown="false"
        :palette="GROUPS_CATEGORIZED_COLORS"
        data-testid="palette-color-picker"
        style="--thumb-color: var(--dark-2); --padding: 0"
      />
    </div>

    <GlobalGroupsWorkspaceSelect
      v-model:selected-workspaces="localWorkspaceIds"
      data-testid="workspaces-select"
    />

    <FormFieldNext :label="$t('global_groups.select_parent')">
      <AppSelect
        v-model="localParentId"
        :loading="isParentGroupsLoading"
        :offset="[0, -40]"
        :options="parentGroupsOptions"
        :search-function="$event => getParentGroups({ searchString: $event })"
        class="mgg-ParentGroupSelect"
        data-testid="parent-group-select"
        dropdown-search
        item-label="name"
        item-value="id"
        skeleton-loader
        skeleton-loader-height="100%"
        skeleton-loader-width="100%"
        theme="no-shadow-next light"
        type="default-next"
        @update:options="parentGroups = $event"
      >
        <template #button-content="{ option }">
          <GlobalGroupsSelectOption
            v-if="option"
            :group="option"
            :is-not-set="option.id === NO_PARENT_GROUP_ID"
            :show-breadcrumbs-tooltip="false"
          />
        </template>
        <template #option-label="{ option }">
          <GlobalGroupsSelectOption
            v-if="option"
            :group="option"
            :is-not-set="option.id === NO_PARENT_GROUP_ID"
          />
        </template>
      </AppSelect>
    </FormFieldNext>
  </div>
</template>

<script setup>
import { cloneDeep, isEmpty, isObject } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  EVENT_SOURCES,
  EVENT_SUBCATEGORIES,
  trackAddRemoveGroupToWorkspaceEvent,
  TRACKING_NONE,
  TRACKING_UNKNOWN
} from '@/tracking/amplitude-helpers'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { checkSomeValueIsTruthy } from '@/utils/general'
import {
  GLOBAL_GROUP_KEYS,
  GROUPS_CATEGORIZED_COLORS,
  NO_PARENT_GROUP_ID,
  userCanUpdateGroup
} from '@/utils/global-groups'
import { NOTIFICATION_TYPES, showNotify } from '@/utils/notify'
import { objectOrNullProp } from '@/utils/prop-validators'
import { selectAllIsSelected } from '@/utils/select'

import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import GlobalGroupsWorkspaceSelect from '@/components/global-groups/GlobalGroupsWorkspaceSelect'
import GroupIconPicker from '@/components/global-groups/GroupIconPicker'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppPaletteColorPicker from '@/components/ui/AppPaletteColorPIcker/AppPaletteColorPicker'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'ManageGlobalGroupSettingsTab'
})

const { ID, ICON, COLOR, NAME, PARENT_ID, WORKSPACE_IDS, WORKSPACES } = GLOBAL_GROUP_KEYS

const props = defineProps({
  group: {
    required: true,
    validator: v => objectOrNullProp(v)
  }
})

const localIcon = computed({
  get: () => `${props.group[ICON]}`,
  set: value => {
    updateGroup({ key: ICON, value })
  }
})

const localColor = computed({
  get: () => props.group[COLOR],
  set: value => {
    updateGroup({ key: COLOR, value })
  }
})

const localWorkspaceIds = computed({
  get: () => props.group[WORKSPACES].map(w => w.id),
  set: value => {
    const isAllWorkspacesSelected = selectAllIsSelected(value)
    const resolvedValue = isAllWorkspacesSelected ? [] : value
    updateGroup({
      key: WORKSPACE_IDS,
      value: resolvedValue,
      addToAllWorkspaces: isAllWorkspacesSelected
    })
  }
})

const isParentGroupsLoading = ref(false)
const parentGroups = ref([])

const parentGroupsOptions = computed(() => {
  return [
    {
      [ID]: NO_PARENT_GROUP_ID
    },
    ...parentGroups.value
  ]
})

const getParentGroups = async ({ searchString = null } = {}) => {
  const api = new GlobalGroupsApiHandler()

  try {
    isParentGroupsLoading.value = true
    return await api.getParentGroups({
      [REQUEST_ENTITY_KEYS.GROUP_ID]: props.group[ID],
      [REQUEST_ENTITY_KEYS.PARENT_GROUP_ID]: props.group[PARENT_ID],
      searchString
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isParentGroupsLoading.value = false
  }
}

const localParentId = computed({
  get: () => props.group[PARENT_ID],
  set: value => {
    updateGroup({ key: PARENT_ID, value })
  }
})

const emit = defineEmits({
  'group-updated': obj => isObject(obj) && !isEmpty(obj),
  'saving-started': null,
  'saving-finished': null,
  'close-modal': null
})

const { t } = useI18n()

const FIELD_BY_KEY = {
  [NAME]: t('group.label.name'),
  [ICON]: t('levels.icon'),
  [COLOR]: t('levels.color'),
  [WORKSPACE_IDS]: t('workspaces.workspaces'),
  [PARENT_ID]: t('global_groups.parent_group')
}

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})
const updateGroup = async ({ key = null, value = null, addToAllWorkspaces = null } = {}) => {
  if (!key) {
    return
  }

  const payload = {
    [ID]: props.group[ID],
    [PARENT_ID]: props.group[PARENT_ID],
    [key]: value,
    addToAllWorkspaces
  }

  loadingStates.value[key] = true

  const api = new GlobalGroupsApiHandler()
  try {
    const result = await api.updateGlobalGroup(payload)
    if (key === PARENT_ID) {
      let parentGroupName = TRACKING_NONE
      if (value) {
        parentGroupName = parentGroups.value.find(group => group.id === value)?.name
      }

      tracker.logEvent('updated group', {
        category: EVENT_CATEGORIES.GROUP_MANAGEMENT,
        subCategory: EVENT_SUBCATEGORIES.PARENT_GROUP,
        role: userRoleForTracking.value,
        parent: parentGroupName
      })
    }

    if (key === WORKSPACE_IDS) {
      const isGroupAddedToWorkspaces =
        value.length > props.group[WORKSPACES].length || addToAllWorkspaces

      const trackingData = {
        source: EVENT_SOURCES.GROUP_SETTINGS,
        role: userRoleForTracking.value,
        subcategory: EVENT_SUBCATEGORIES.SINGLE
      }

      const existedWorkspaces = props.group[WORKSPACES].map(w => w.id)

      if (isGroupAddedToWorkspaces) {
        if (addToAllWorkspaces) {
          trackingData.label = 'all workspaces'
        } else {
          const newWorkspaces = value.filter(
            workspaceId => !existedWorkspaces.includes(workspaceId)
          )

          trackingData.label = newWorkspaces[0] || TRACKING_UNKNOWN
        }
      } else {
        trackingData.eventName = 'Group removed from a workspace'
        // that means we remove group from ws
        if (isEmpty(value) && existedWorkspaces.length > 1) {
          // that means we remove group from all the ws in one click
          trackingData.subcategory = 'bulk'
          trackingData.label = 'remove from all ws'
        } else {
          const removedWorkspaces = existedWorkspaces.filter(
            workspaceId => !value.includes(workspaceId)
          )
          trackingData.label = removedWorkspaces[0] || TRACKING_UNKNOWN
        }
      }

      trackAddRemoveGroupToWorkspaceEvent(trackingData)
    }

    emit('group-updated', result)

    showNotify({
      type: 'success',
      title: t('workspaces.workspace_field_updated', {
        fieldName: FIELD_BY_KEY[key]
      })
    })

    if (!userCanUpdateGroup(result)) {
      loadingStates.value = cloneDeep(DEFAULT_LOADING_STATES)
      emit('saving-finished')
      showNotify({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('notifications.oops'),
        expanded: true,
        content: t('notifications.lost_group_access')
      })

      emit('close-modal')
    }
  } catch (error) {
    emit('group-updated', props.group)
    localName.value = props.group[NAME]
    handleError({ error })
  } finally {
    loadingStates.value[key] = false
  }
}

const localName = ref(props.group[NAME])
const isNameEmpty = ref(false)
const onNameBlur = async () => {
  if (localName.value.trim() === '') {
    isNameEmpty.value = true
    return
  }
  if (localName.value === props.group[NAME]) {
    return
  }

  await updateGroup({ key: NAME, value: localName.value })
}

const getInitialData = async () => {
  parentGroups.value = await getParentGroups()
}

onMounted(() => {
  getInitialData()
})

const DEFAULT_LOADING_STATES = {
  [NAME]: false,
  [PARENT_ID]: false,
  [ICON]: false,
  [COLOR]: false,
  [WORKSPACE_IDS]: false
}

const loadingStates = ref(cloneDeep(DEFAULT_LOADING_STATES))

const isSavingProceed = computed(() => {
  return checkSomeValueIsTruthy(...Object.values(loadingStates.value))
})

watch(isSavingProceed, value => {
  if (value) {
    emit('saving-started')
  } else {
    emit('saving-finished')
  }
})
</script>

<style lang="scss" scoped>
.mgg-SettingsTab {
  padding: 16px $page-right-padding-next 16px $page-left-padding-next;
  width: calc(600px + #{$page-left-padding-next} + #{$page-right-padding-next});
  display: grid;
  gap: 28px;
}

.mgg-IconSettings {
  display: flex;
  justify-content: space-between;
}

.mgg-ParentGroupSelect {
  --select-skeleton-top: 0;
  --select-skeleton-left: 0;
}
</style>
