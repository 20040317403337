<template>
  <span
    :class="{
      'amwso-Option-bottom-fixed-item': bottomFixedItem,
      'amwso-Option-selected': selected
    }"
    class="amwso-Option"
  >
    <AppIcon
      v-if="option.icon"
      :icon-name="option.icon"
      class="amwso-Option_Icon"
      height="24"
      width="24"
    />
    <WorkspaceIcon v-if="option.key" :option="option" class="amwso-Option_WsIcon" />
    <span class="amwso-Option_Name">
      {{ option.name }}
    </span>

    <span v-if="$slots.badge" class="amwso-Option_Badge">
      <slot name="badge" />
    </span>
  </span>
</template>

<script>
import { defineComponent } from 'vue'

import AppIcon from '@/components/ui/AppIcon/AppIcon'
import WorkspaceIcon from '@/components/workspaces/WorkspaceIcon'

export default defineComponent({
  name: 'AppMenuWorkspaceSelectOption',

  components: {
    WorkspaceIcon,
    AppIcon
  },

  props: {
    option: {
      type: Object,
      required: true
    },

    bottomFixedItem: {
      type: Boolean
    },

    selected: {
      type: Boolean
    }
  }
})
</script>

<style lang="scss" scoped>
.amwso-Option {
  display: flex;
  align-items: center;
  overflow: visible;
  min-height: 24px;
  max-width: 100%;
}

.amwso-Option_Icon {
  margin-right: 8px;
  flex-shrink: 0;

  .amwso-Option-bottom-fixed-item & {
    color: $dark-2;
  }
}

.amwso-Option_WsIcon {
  margin-right: 8px;
}

.amwso-Option_Name {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  min-width: calc(100% - 32px);

  .amwso-Option-bottom-fixed-item & {
    font-weight: fw('regular');
  }
}

.amwso-Option_Badge {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  color: $grey-1-next;
  margin-left: auto;
}
</style>
