<template>
  <AppDialog
    :show="show"
    :title="show ? confirmDeleteTitle : ''"
    :type="DIALOG_TYPES.DELETE"
    data-auto-testid="okr-element-delete-modal"
    @on-close="hide"
    @on-confirm="deleteObjective"
  >
    <template v-if="show">
      <div class="odm-DeleteConfirmMessage">
        {{ confirmMessage }}
        <br />
        <b> {{ objective.displayId }} {{ objective.name }} </b>
      </div>
    </template>

    <template v-if="show && objectiveHasChildren && !objectiveIsKRorTask" #footer-prepend>
      <AppCheckbox
        v-model="deleteNestedItems"
        class="odm-DeleteChildrenSwitch"
        data-auto-testid="delete-nested-items-switch"
        size="s"
      >
        {{ $t('confirm.objective.nested_items') }}
      </AppCheckbox>
    </template>

    <template #confirm-btn-text>
      {{ confirmBtnText }}
    </template>
  </AppDialog>
</template>

<script>
import { defineComponent } from 'vue'

import ObjectivesApiHandler from '@/api/okr-elements'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { handleError } from '@/utils/error-handling'
import { getObjectiveTypeName, objectiveIsJiraTask, isKR } from '@/utils/objectives'
import { objectOrNullProp } from '@/utils/prop-validators'
import { getNavigationTabName } from '@/utils/tracking'

import AppDialog from '@/components/AppDialog'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'

export default defineComponent({
  name: 'ObjectiveDeleteModal',

  components: {
    AppDialog,
    AppCheckbox
  },

  props: {
    objective: {
      required: true,
      validator: v => objectOrNullProp(v)
    },

    show: {
      type: Boolean,
      default: false
    },

    source: {
      type: String,
      default: ''
    }
  },

  emits: { 'update:show': null, deleted: null },

  data() {
    return {
      deleteNestedItems: false
    }
  },

  computed: {
    DIALOG_TYPES: () => DIALOG_TYPES,

    objectiveIsKR() {
      return isKR(this.objective)
    },

    objectiveIsTask() {
      return objectiveIsJiraTask(this.objective)
    },

    objectiveIsKRorTask() {
      return this.objectiveIsKR || this.objectiveIsTask
    },

    confirmDeleteTitle() {
      const [title] = [
        this.objectiveIsKR && 'confirm.kr.title',
        this.objectiveIsTask && 'action.unlink_issue',
        'confirm.objective.title'
      ].filter(Boolean)

      return this.$t(title)
    },

    confirmMessage() {
      const [message] = [
        this.objectiveIsKR && 'delete_confirm_kr_note',
        this.objectiveIsTask && 'delete_confirm_issue_note',
        'delete_confirm_objective_note'
      ].filter(Boolean)

      return this.$t(message)
    },

    confirmBtnText() {
      return this.objectiveIsTask ? this.$t('action.unlink_issue') : this.$t('action.delete')
    },

    objectiveHasChildren() {
      return this.objective.childCount > 0
    }
  },

  methods: {
    async hide() {
      this.deleteNestedItems = false
      this.$emit('update:show', false)

      await this.$nextTick()
    },

    async deleteObjective() {
      const objectivesApi = new ObjectivesApiHandler()

      try {
        const elementId = this.objective.id
        await objectivesApi.removeOkrElement({
          elementId,
          removeNestedObjectives: this.deleteNestedItems
        })
        if (objectiveIsJiraTask(this.objective)) {
          tracker.logEvent('Deleted link', {
            category: EVENT_CATEGORIES.OKR_MANAGEMENT,
            label: this.objective.issueType,
            source: this.source,
            event_ref: 'Plugin',
            contribution: this.objective.contribute ? 'Yes' : 'No',
            ent_id: this.objective.displayId,
            tab: getNavigationTabName(this.$route)
          })
        } else {
          tracker.logEvent(isKR(this.objective) ? 'Deleted KR' : 'Deleted objective', {
            category: EVENT_CATEGORIES.OKR_MANAGEMENT,
            source: this.source,
            label: getObjectiveTypeName(this.objective.typeId),
            contribution: this.objective.contribute ? 'Yes' : 'No',
            ent_id: this.objective.displayId,
            tab: getNavigationTabName(this.$route)
          })
        }

        this.$emit('deleted', elementId)
        this.hide()
      } catch (error) {
        handleError({ error })
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.odm-DeleteConfirmMessage {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: $system-ui;
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
}

.odm-DeleteChildrenSwitch {
  --checkmark-color: #{$grade-low-color-next};
  --label-color: #{$grade-low-color-next};
  &:deep(.ac-Text) {
    font-family: $system-ui;
  }
}
</style>
