<template>
  <div class="dc-DashboardConfigPage">
    <div class="dc-DashboardConfigPage_Header">
      <AppTitle :level="3" class="dc-DashboardConfigPage_Title" disable-top-margin>
        {{ $t('jira.dashboard.title') }}
      </AppTitle>
    </div>
    <FormFieldNext :label="$t('field.workspace.title')" class="dc-WorkspaceSelect">
      <AppSelect
        v-model="workspaceId"
        :loading="workspacesAreLoading"
        :offset="[0, 0]"
        :options="workspaces"
        :search-function="searchWorkspaces"
        append-to=".dc-DashboardConfigPage"
        dropdown-search
        item-label="name"
        item-value="id"
        skeleton-loader
        skeleton-loader-height="100%"
        skeleton-loader-width="100%"
        theme="no-shadow-next light"
        type="default-next"
        @update:options="onWorkspacesUpdate"
      >
        <template #button="{ option }">
          <div v-if="option" class="dc-WorkspaceSelect_Button">
            <WorkspaceIcon :option="option" />

            <span v-if="!withoutText" class="dc-SelectedWsName">
              {{ option.name }}
            </span>

            <span class="dc-DroplistIcon">
              <AppIcon height="24" icon-name="chevrons-up-down" width="24" />
            </span>
          </div>
        </template>

        <template #option-label="{ option, selected }">
          <AppMenuWorkspaceSelectOption v-if="option" :option="option" :selected="selected" />
        </template>
      </AppSelect>
    </FormFieldNext>

    <div class="dc-DashboardTopFilters">
      <FormFieldNext :label="$t('field.quoter.title')">
        <OkrFilterSelect
          :loading="intervalsAreLoading"
          :max-height="229"
          :model-value="intervalIds"
          :offset="[0, 0]"
          :options="intervalsOptions"
          :search="false"
          highlight-option
          n-selected-label="field.quoter.title"
          prepend-icon="calendar-next"
          @update:model-value="selectIntervals"
        >
          <template #option="props">
            <IntervalSelectOption v-bind="props" />
          </template>
        </OkrFilterSelect>
      </FormFieldNext>
      <FormFieldNext :label="$t('filter.owners')">
        <ConfluenceDashboardOwners
          :model-value="ownerIds"
          :workspace-id="workspaceId"
          @update:model-value="selectOwners"
          @update:options="onAssigneesUpdate"
        />
      </FormFieldNext>
      <FormFieldNext :label="$t('field.groups.title')">
        <ConfluenceDashboardGroups
          :model-value="groupIds"
          :workspace-id="workspaceId"
          @update:model-value="selectGroups"
          @update:options="onGroupsUpdate"
        />
      </FormFieldNext>
    </div>
    <div class="dc-PairSelects">
      <FormFieldNext :label="$t('jira.dashboard.hierarchy_view')">
        <AppSelect
          v-model="searchType"
          :offset="[0, 0]"
          :options="searchTypesSelectOptions"
          item-label="label"
          theme="no-shadow-next light"
          type="default-next"
        >
        </AppSelect>
      </FormFieldNext>
      <FormFieldNext :label="$t('jira.dashboard.okr_type')">
        <OkrFilterSelect
          :bottom-fixed-items="getBottomFixedItemsClearSelection('levelIds')"
          :loading="levelsAreLoading"
          :model-value="levelIds"
          :offset="[0, -40]"
          :options="levelOptions"
          :search-function="value => localSearch({ value, options: levelOptions })"
          class="dc-Select-regularText"
          disable-select-highlighting
          item-label="name"
          item-value="id"
          n-selected-label="jira.dashboard.okr_types"
          theme="no-shadow-next light"
          type="default-next"
          @update:model-value="selectLevels"
        >
          <template #option-label="{ option }">
            <OkrTypeFieldOption :option="option" />
          </template>
          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="item.action === CLEAR_SELECTION"
                @click="bottomFixedItemsHandle(item.action, 'levelIds')"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>
      </FormFieldNext>

      <FormFieldNext :label="$t('field.status.title')">
        <OkrFilterSelect
          :bottom-fixed-items="getBottomFixedItemsClearSelection('gradeTypes')"
          :hidden-items="[0]"
          :model-value="gradeTypes"
          :offset="[0, -40]"
          :options="gradeTypesOptions"
          :search="false"
          class="dc-Select-regularText"
          disable-select-highlighting
          item-label="label"
          item-value="value"
          n-selected-label="filter.statuses"
          theme="no-shadow-next light"
          type="default-next"
          @update:model-value="selectGradeTypes"
        >
          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="item.action === CLEAR_SELECTION"
                @click="bottomFixedItemsHandle(item.action, 'gradeTypes')"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>
      </FormFieldNext>
      <FormFieldNext :label="$t('field.labels.title')">
        <OkrFilterSelect
          :allow-create-on-search="false"
          :bottom-fixed-items="getBottomFixedItemsClearSelection('labelIds')"
          :loading="labelsAreLoading"
          :model-value="labelIds"
          :offset="[0, -40]"
          :options="labelsOptions"
          :search-function="getLabels"
          :search-max-length="50"
          class="dc-Select-regularText"
          disable-select-highlighting
          item-label="name"
          item-value="id"
          multi
          n-selected-label="field.labels.title"
          skeleton-loader
          theme="no-shadow-next light"
          type="default-next"
          @update:options="onLabelsUpdate"
          @update:model-value="selectLabels"
        >
          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="item.action === CLEAR_SELECTION"
                @click="bottomFixedItemsHandle(item.action, 'labelIds')"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>
      </FormFieldNext>

      <FormFieldNext :label="$t('filter.start_date')">
        <DateFilter
          :model-value="startDates"
          date-format="DD MMM"
          @update:model-value="onStartDateSelect"
        >
          <template #button-content="{ displayedDates }">
            <span class="dc-DateFilterValue">
              <span v-if="displayedDates">
                <i18n-t keypath="date.date_with_word_break" scope="global">
                  <template #start>{{ displayedDates.start }}</template>
                  <template #end>{{ displayedDates.end }}</template>
                </i18n-t>
              </span>
              <span v-else>{{ $t('filter.show_all') }}</span>
            </span>
          </template>
        </DateFilter>
      </FormFieldNext>
      <FormFieldNext :label="$t('filter.due_date')">
        <DateFilter
          :model-value="dueDates"
          date-format="DD MMM"
          @update:model-value="onDueDateSelect"
        >
          <template #button-content="{ displayedDates }">
            <span class="dc-DateFilterValue">
              <span v-if="displayedDates">
                <i18n-t keypath="date.date_with_word_break" scope="global">
                  <template #start>{{ displayedDates.start }}</template>
                  <template #end>{{ displayedDates.end }}</template>
                </i18n-t>
              </span>
              <span v-else>{{ $t('filter.show_all') }}</span>
            </span>
          </template>
        </DateFilter>
      </FormFieldNext>

      <FormFieldNext :label="$t('field.assignee.stakeholders')">
        <OkrFilterSelect
          :bottom-fixed-items="getBottomFixedItemsClearSelection('stakeholderIds')"
          :loading="stakeholdersAreLoading"
          :model-value="stakeholderIds"
          :offset="[0, -40]"
          :options="stakeholdersOptions"
          :search-function="getStakeholders"
          class="dc-Select-regularText"
          disable-select-highlighting
          item-label="displayName"
          item-value="accountId"
          multi
          n-selected-label="field.assignee.stakeholders"
          skeleton-loader
          theme="no-shadow-next light"
          type="default-next"
          @update:options="onStakeholdersUpdate"
          @update:model-value="selectStakeholders"
        >
          <template #option-label="{ option }">
            <OwnerFieldOption :option="option" />
          </template>
          <template #bottom-fixed-items="{ bottomFixedItems }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem
                v-if="item.action === CLEAR_SELECTION"
                @click="bottomFixedItemsHandle(item.action, 'stakeholderIds')"
              >
                {{ $t(item.text) }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </OkrFilterSelect>
      </FormFieldNext>
      <div class="dc-MaxDisplayControl">
        <AppInputNumberNext
          v-model="limit"
          :digit-max-length="3"
          :label="$t('jira.dashboard.okrs_displayed')"
          class="dc-OkrDisplayInput"
        />
        <AppFieldError v-if="limitIsNotValid" :show="limitIsNotValid" class="dc-MaxDisplayError">
          {{ $t('jira.dashboard.okrs_displayed_error') }}
        </AppFieldError>
      </div>
    </div>

    <div class="dc-Actions o-confirm-actions">
      <AppButton v-if="savedConfigExist && withButtonCancel" type="ghost-next" @click="onCancel">
        {{ $t('action.cancel') }}
      </AppButton>

      <AppButton
        v-if="withButtonSave"
        :disable="limitIsNotValid"
        :loading="loading"
        type="primary-next"
        @click="onSave"
      >
        {{ $t('action.save') }}
      </AppButton>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { isEmpty, isEqual } from 'lodash'
import { defineComponent } from 'vue'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import IntervalsInfoApiHandler from '@/api/intervals-info'
import LabelsApiHandler from '@/api/labels'
import LevelApiHandler from '@/api/level'
import { SEARCH_TYPES } from '@/api/okr-elements'
import WorkspacesApiHandler from '@/api/workspaces'
import { CLEAR_SELECTION, SELECTS_WITH_CHECKBOX_ITEMS } from '@/composables/bottom-fixed-items'
import { handleError } from '@/utils/error-handling'
import { filterOnBacklogInterval } from '@/utils/interval'
import { ALL_STATUS_OPTIONS, localSearch } from '@/utils/objectives'
import { convertDatesToStartEnd } from '@/utils/okr-custom-filters'
import {
  DATES_KEYS,
  DEFAULT_VALUE_FOR_FILTER,
  FILTER_DEFAULT_VALUES,
  FILTERS_KEYS,
  SELECT_ALL_VALUE
} from '@/utils/okr-elements/filters'
import {
  getSelectWithSelectAllApiParameter,
  handleSelectInputWithSelectAll,
  selectAllIsSelected
} from '@/utils/select'
import JiraDashboardApiHandler from '@jira/api/jira-dashboard'
import { BACKEND_FILTER_IDS } from '@jira/utils/jira-dashboard-items'

import AppMenuWorkspaceSelectOption from '@/components/AppMenu/AppMenuWorkspaceSelectOption'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import OkrTypeFieldOption from '@/components/form/OkrTypeFieldOption'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import DateFilter from '@/components/objectives/filter-field/DateFilter'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import IntervalSelectOption from '@/components/objectives/toolbar/IntervalSelectOption'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInputNumberNext from '@/components/ui/AppInputNumberNext/AppInputNumberNext'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import WorkspaceIcon from '@/components/workspaces/WorkspaceIcon'
import ConfluenceDashboardGroups from '@jira/components/confluence-macro-dashboard/Toolbar/ConfluenceDashboardGroups'
import ConfluenceDashboardOwners from '@jira/components/confluence-macro-dashboard/Toolbar/ConfluenceDashboardOwners'

const prepareFiltersPayload = (filters = {}) => {
  return Object.fromEntries(
    Object.entries(filters).map(([key, val]) => {
      const normalizedValue = selectAllIsSelected(val) ? null : val
      return [BACKEND_FILTER_IDS[key], JSON.stringify(normalizedValue)]
    })
  )
}

export default defineComponent({
  name: 'JiraDashboardConfig',

  components: {
    BottomFixedSelectItem,
    ConfluenceDashboardGroups,
    ConfluenceDashboardOwners,
    OkrFilterSelect,
    IntervalSelectOption,
    FormFieldNext,
    WorkspaceIcon,
    AppIcon,
    AppInputNumberNext,
    AppMenuWorkspaceSelectOption,
    OkrTypeFieldOption,
    OwnerFieldOption,
    DateFilter,
    AppTitle,
    AppSelect,
    AppButton,
    AppFieldError
  },

  props: {
    savedConfigExist: {
      type: Boolean,
      required: true
    },

    dashboardItemId: {
      type: String,
      required: true
    },

    savedConfigFilters: {
      type: Object,
      default: () => ({})
    },

    withButtonSave: {
      type: Boolean,
      default: true
    },

    withButtonCancel: {
      type: Boolean,
      default: true
    }
  },

  emits: { 'go-to-dashboard': null },

  data() {
    return {
      workspaceId: null,
      workspacesAreLoading: false,
      workspaces: [],

      ownerIds: DEFAULT_VALUE_FOR_FILTER,
      assignees: [],
      assigneesAreLoading: false,

      stakeholderIds: DEFAULT_VALUE_FOR_FILTER,
      stakeholders: [],
      stakeholdersAreLoading: false,

      intervalIds: [],
      intervals: [],
      intervalsAreLoading: false,

      groupIds: DEFAULT_VALUE_FOR_FILTER,
      groups: [],
      groupsAreLoading: false,

      levelIds: DEFAULT_VALUE_FOR_FILTER,
      levels: [],
      levelsAreLoading: false,

      labelIds: DEFAULT_VALUE_FOR_FILTER,
      labels: [],
      labelsAreLoading: false,

      dueDates: null,

      startDates: null,

      gradeTypes: DEFAULT_VALUE_FOR_FILTER,

      searchType: SEARCH_TYPES.ALIGNMENT,

      limit: 10,

      loading: false
    }
  },

  computed: {
    CLEAR_SELECTION: () => CLEAR_SELECTION,
    searchTypesSelectOptions() {
      return [
        { label: this.$t('jira.dashboard.okr_explorer'), value: SEARCH_TYPES.OKR_EXPLORER },
        { label: this.$t('jira.dashboard.alignment'), value: SEARCH_TYPES.ALIGNMENT }
      ]
    },

    assigneesOptions() {
      return [
        {
          accountId: 0,
          displayName: this.$t('filter.all_owners')
        },
        ...this.assignees
      ]
    },

    stakeholdersOptions() {
      return [
        {
          accountId: SELECT_ALL_VALUE,
          displayName: this.$t('filter.all_stakeholders')
        },
        ...this.stakeholders
      ]
    },

    intervalsOptions() {
      return this.intervals.map(i => ({
        ...i,
        label: i.name,
        value: i.id
      }))
    },

    groupsOptions() {
      return [
        {
          id: 0,
          name: this.$t('filter.all_groups')
        },
        ...this.groups
      ]
    },

    levelOptions() {
      return [
        {
          name: this.$t('filter.all_okr_levels'),
          id: 0
        },
        ...this.levels
      ]
    },

    labelsOptions() {
      return [
        {
          name: this.$t('filter.all_labels'),
          id: 0
        },
        ...this.labels
      ]
    },

    gradeTypesOptions() {
      return [
        {
          value: 0,
          label: this.$t('filter.all_grade_types')
        },
        ...ALL_STATUS_OPTIONS.map(option => ({
          ...option,
          label: this.$t(option.label)
        }))
      ]
    },

    limitIsNotValid() {
      const { limit } = this
      return limit > 100 || limit <= 0 || isNaN(limit)
    },

    normalizedSavedConfigFilters() {
      const { savedConfigFilters } = this

      if (isEmpty(savedConfigFilters)) {
        return {}
      } else {
        const specialKeys = [
          FILTERS_KEYS.GRADE_TYPES,
          FILTERS_KEYS.GROUP_IDS,
          'levelIds',
          'ownerIds',
          FILTERS_KEYS.LABEL_IDS,
          FILTERS_KEYS.STAKEHOLDER_IDS
        ]

        const normalizedSpecialKeysValues = Object.fromEntries(
          Object.entries(savedConfigFilters)
            .map(([key, val]) => {
              if (specialKeys.includes(key)) {
                const normalizedValue = isEmpty(val) ? DEFAULT_VALUE_FOR_FILTER : val
                return [key, normalizedValue]
              } else {
                return [key, val]
              }
            })
            .filter(([key]) => !Object.values(DATES_KEYS).includes(key))
        )

        const { dueDateFrom, dueDateTo, startDateFrom, startDateTo } = savedConfigFilters
        const dueDatesEmpty = [dueDateFrom, dueDateTo].every(item => isEmpty(item))
        const startDatesEmpty = [startDateFrom, startDateTo].every(item => isEmpty(item))

        normalizedSpecialKeysValues[FILTERS_KEYS.DUE_DATES] = dueDatesEmpty
          ? FILTER_DEFAULT_VALUES[FILTERS_KEYS.DUE_DATES]
          : [dayjs(dueDateFrom).toDate(), dayjs(dueDateTo).toDate()]

        normalizedSpecialKeysValues[FILTERS_KEYS.START_DATES] = startDatesEmpty
          ? FILTER_DEFAULT_VALUES[FILTERS_KEYS.START_DATES]
          : [dayjs(startDateFrom).toDate(), dayjs(startDateTo).toDate()]

        return normalizedSpecialKeysValues
      }
    }
  },

  watch: {
    workspaceId: {
      async handler(newValue) {
        if (newValue) {
          await this.fetchAll()
          if (
            isEmpty(this.savedConfigFilters) ||
            this.savedConfigFilters.workspaceId !== newValue
          ) {
            this.setDefaultValuesForFilters()
          }
        }
      },

      immediate: true
    }
  },

  async mounted() {
    this.workspaces = await this.getAllUserWorkspaces()
    if (!isEmpty(this.savedConfigFilters)) {
      const workspaceAvailable = this.workspaces.find(
        workspace => workspace.id === this.normalizedSavedConfigFilters.workspaceId
      )
      if (workspaceAvailable) {
        this.workspaceId = this.normalizedSavedConfigFilters.workspaceId
      } else {
        this.workspaceId = this.workspaces[0].id
        return
      }
      await this.fetchAll()
      Object.entries(this.normalizedSavedConfigFilters).forEach(([key, val]) => {
        this[key] = val
      })
    } else {
      this.workspaceId = this.workspaces[0].id
    }
  },

  methods: {
    localSearch,
    selectAllIsSelected,

    selectIntervals(value) {
      this.intervalIds = filterOnBacklogInterval({
        intervals: this.intervals,
        value,
        selectedIntervals: this.intervalIds
      })
    },

    getBottomFixedItemsClearSelection(key) {
      return this[key].includes(SELECT_ALL_VALUE) ? [] : SELECTS_WITH_CHECKBOX_ITEMS
    },

    bottomFixedItemsHandle(action, key) {
      if (action === this.CLEAR_SELECTION) {
        this[key] = DEFAULT_VALUE_FOR_FILTER
      }
    },

    async getAllUserWorkspaces(searchString = null) {
      this.workspacesAreLoading = true
      const api = new WorkspacesApiHandler()
      let result = []
      try {
        result = await api.getAllUserWorkspaces({ searchString })
      } catch (error) {
        handleError({ error })
      }
      this.workspacesAreLoading = false
      return result
    },

    setDefaultValuesForFilters() {
      this.dueDates = FILTER_DEFAULT_VALUES[FILTERS_KEYS.DUE_DATES]
      this.startDates = FILTER_DEFAULT_VALUES[FILTERS_KEYS.START_DATES]
      this.intervalIds = [this.intervals.find(interval => interval.backlog).id]
      this.ownerIds = DEFAULT_VALUE_FOR_FILTER
      this.stakeholderIds = DEFAULT_VALUE_FOR_FILTER
      this.groupIds = DEFAULT_VALUE_FOR_FILTER
      this.levelIds = DEFAULT_VALUE_FOR_FILTER
      this.gradeTypes = DEFAULT_VALUE_FOR_FILTER
      this.labelIds = DEFAULT_VALUE_FOR_FILTER
    },

    async searchWorkspaces(searchString = null) {
      return await this.getAllUserWorkspaces(searchString)
    },

    async getStakeholders(searchString = null) {
      let result = []
      const api = new AssigneesInfoApiHandler()
      this.stakeholdersAreLoading = true
      try {
        result = await api.getUsers({
          searchString,
          ownerIds: getSelectWithSelectAllApiParameter(this.stakeholderIds),
          workspaceId: this.workspaceId
        })
      } catch (error) {
        handleError({ error })
      } finally {
        this.stakeholdersAreLoading = false
      }
      return result
    },

    selectOwners(value) {
      let { valueChanged, result } = handleSelectInputWithSelectAll(value, this.ownerIds)
      valueChanged = valueChanged || !isEqual(this.ownerIds, result)

      if (valueChanged) {
        this.ownerIds = result
      }
    },

    selectStakeholders(value) {
      let { valueChanged, result } = handleSelectInputWithSelectAll(value, this.stakeholderIds)
      valueChanged = valueChanged || !isEqual(this.stakeholderIds, result)

      if (valueChanged) {
        this.stakeholderIds = result
      }
    },

    selectGroups(value) {
      let { valueChanged, result } = handleSelectInputWithSelectAll(value, this.groupIds)
      valueChanged = valueChanged || !isEqual(this.groupIds, result)

      if (valueChanged) {
        this.groupIds = result
      }
    },

    selectLevels(value) {
      let { valueChanged, result } = handleSelectInputWithSelectAll(value, this.levelIds)
      valueChanged = valueChanged || !isEqual(this.levelIds, result)

      if (valueChanged) {
        this.levelIds = result
      }
    },

    selectLabels(value) {
      let { valueChanged, result } = handleSelectInputWithSelectAll(value, this.labelIds)
      valueChanged = valueChanged || !isEqual(this.labelIds, result)

      if (valueChanged) {
        this.labelIds = result
      }
    },

    selectGradeTypes(value) {
      let { valueChanged, result } = handleSelectInputWithSelectAll(value, this.gradeTypes)
      valueChanged = valueChanged || !isEqual(this.gradeTypes, result)

      if (valueChanged) {
        this.gradeTypes = result
      }
    },

    onWorkspacesUpdate(newValue) {
      this.workspaces = newValue
    },

    onAssigneesUpdate(newValue) {
      // filter added in computed 'All selected' option
      this.assignees = newValue.filter(assignee => assignee.accountId !== 0)
    },

    onStakeholdersUpdate(newValue) {
      // filter added in computed 'All selected' option
      this.stakeholders = newValue.filter(stakeholder => stakeholder.accountId !== 0)
    },

    onGroupsUpdate(newValue) {
      // filter added in computed 'All selected' option
      this.groups = newValue.filter(group => group.id !== 0)
    },

    onLabelsUpdate(newValue) {
      // filter added in computed 'All selected' option
      this.labels = newValue.filter(label => label.id !== 0)
    },

    onDueDateSelect(value) {
      this.dueDates = value
    },

    onStartDateSelect(value) {
      this.startDates = value
    },

    async fetchAll() {
      try {
        const [intervals, levels, labels, stakeholders] = await Promise.all([
          this.fetchIntervals(),
          this.getLevels(),
          this.getLabels(),
          this.getStakeholders()
        ])
        Object.entries({ intervals, levels, labels, stakeholders }).forEach(([key, val]) => {
          this[key] = val
        })
      } catch (error) {
        handleError({ error })
      }
    },

    async fetchIntervals() {
      this.intervalsAreLoading = true
      const api = new IntervalsInfoApiHandler()
      let result = []

      try {
        const { currentIndex, intervals } = await api.getIntervalsInfo({
          workspaceId: this.workspaceId
        })

        result = intervals

        if (intervals.some(interval => this.intervalIds.includes(interval.id))) {
          if (currentIndex !== null && !this.savedConfigExist) {
            this.intervalIds = [intervals[currentIndex].id]
          }
        }
      } catch (error) {
        handleError({ error })
      }

      this.intervalsAreLoading = false
      return result
    },

    async getLevels() {
      const api = new LevelApiHandler()
      let result = []
      this.levelsAreLoading = true
      try {
        result = await api.getLevels({
          workspaceIds: null,
          levelIds: null
        })
      } catch (error) {
        handleError({ error })
      }
      this.levelsAreLoading = false
      return result
    },

    async getLabels(searchString = null) {
      const api = new LabelsApiHandler()
      let result = []
      this.labelsAreLoading = true
      try {
        result = await api.getLabels({ name: searchString })
      } catch (error) {
        handleError({ error })
      }
      this.labelsAreLoading = false
      return result
    },

    async onSave() {
      this.loading = true
      const api = new JiraDashboardApiHandler()
      let savedConfig = null
      try {
        const datesPayload = {
          startDates: this.startDates,
          dueDates: this.dueDates
        }

        const payload = {
          dashboardId: this.dashboardItemId,
          name: '', // not need here but required
          filters: {
            ...prepareFiltersPayload({
              workspaceId: this.workspaceId,
              intervalIds: this.intervalIds,
              searchType: this.searchType,
              ownerIds: this.ownerIds,
              stakeholderIds: this.stakeholderIds,
              groupIds: this.groupIds,
              levelIds: this.levelIds,
              gradeTypes: this.gradeTypes,
              labelIds: this.labelIds,
              limit: Number(this.limit)
            }),

            ...convertDatesToStartEnd(datesPayload, BACKEND_FILTER_IDS)
          }
        }
        if (this.savedConfigExist) {
          savedConfig = await api.updateFilterViewForDashboard(payload)
        } else {
          savedConfig = await api.createFilterViewForDashboard(payload)
        }
        this.loading = false
        this.$emit('go-to-dashboard', savedConfig)
      } catch (error) {
        this.loading = false
        handleError({ error })
      }
    },

    onCancel() {
      this.$emit('go-to-dashboard')
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixins.scss';

.dc-DashboardConfigPage {
  max-width: 544px;
  width: 100%;
  //overflow: hidden;
  margin: 0 auto;
  min-width: 260px;
}

.dc-ContentColumn {
  overflow: hidden;
}

.dc-DashboardConfigPage_Title {
  font-family: $system-ui;
  color: $dark-1;
}

.dc-Actions {
  margin-top: 24px;
  justify-content: flex-end;
  position: relative;
}

.dc-MaxDisplayControl {
  padding: 2px 5px;
}

.dc-MaxDisplayError {
  min-height: 20px;
  text-align: right;
  display: block;
}

.dc-DashboardConfigPage {
  --ws-option-selected-color: $dark-1;
}

.dc-WorkspaceSelect {
  transition: opacity $menu-transition;
  max-width: 220px;
  margin-bottom: 28px;
  --select-skeleton-left: 0;
  --select-skeleton-top: 0;
}
:deep(.dc-WorkspaceSelect_Button) {
  cursor: pointer;
  overflow: hidden;
  background: $white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: $border-radius-md;
  padding: 10px;
  display: flex;
  align-items: center;
  @include activityStates($grey-medium);
}

:deep(.o-droplist--expanded) {
  .dc-SelectedWsName {
    color: $primary-color;
  }

  //.svg-icon {
  //  color: $primary-color;
  //}
}

:deep(.svg-icon) {
  .dc-WorkspaceSelect:hover & {
    color: $primary-color;
  }
}

.dc-DroplistIcon {
  display: flex;
  margin-left: auto;
}

.dc-SelectedWsName {
  font-family: $system-ui;
  padding-left: 16px;
  color: $regular-text;
  font-weight: fw('medium');
  font-size: $fs-16;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  .dc-WorkspaceSelect:hover & {
    color: $primary-color;
  }
}
.dc-DashboardTopFilters {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 28px;
  --select-skeleton-left: 0;
  --select-skeleton-top: 0;
}
.dc-Select-regularText {
  &:deep(.si-SelectedItem.si-SelectedItem-secondary-next) {
    font-weight: fw('regular');
    color: $regular-text;
  }
}
.dc-PairSelects {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 28px;

  --select-skeleton-left: 0;
  --select-skeleton-top: 0;
}
.dc-OkrDisplayInput {
  &:deep(.ain-InputWrapper) {
    width: 80px;
  }
  &:deep(.ain-Input) {
    font-weight: fw('regular');
    font-size: $fs-14;
  }
}
</style>

<style lang="scss"></style>
