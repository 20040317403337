<template>
  <div
    v-if="showListOfEmails"
    :class="{
      'eci-Wrapper-error': isError
    }"
    class="eci-Wrapper"
    @click="focus"
  >
    <AppSelectedItemsList
      ref="inputWithTagsReference"
      v-model:searchString="emails"
      :search-max-length="MAX_LENGTH"
      :selected-items="preparedList"
      blur-on-enter
      blur-on-esc
      class="eci-InputWithTags"
      hide-search-icon
      placeholder=""
      show-search
      type="default-next"
      @blur="onBlur"
      @delete="onDelete"
      @focus="onFocus"
      @delete-last="onDeleteLast"
    />
  </div>

  <AppInput
    v-else
    v-model="emails"
    :is-error="isError"
    :max-length="MAX_LENGTH"
    blur-on-enter
    size="xlg"
    style-type="primary"
    @blur="onBlur"
  />
</template>

<script setup>
import { cloneDeep, isEmpty, uniqBy } from 'lodash'
import { computed, nextTick, ref, watch } from 'vue'

import { getEmailsListFromString } from '@/utils/email-regex'

import AppInput from '@/components/ui/AppInput/AppInput'
import AppSelectedItemsList from '@/components/ui/AppSelect/AppSelectedItemsList'

const MAX_LENGTH = 1000

defineOptions({
  name: 'EmailCollectorInput'
})

const props = defineProps({
  modelValue: {
    type: Array,
    required: true
  },

  showListOfEmails: {
    type: Boolean
  },

  isError: {
    type: Boolean
  }
})

const emails = ref('')

const getValidEmailsFromInput = () => {
  const matches = getEmailsListFromString(emails.value)

  return matches.map(email => email.trim())
}

const validEmailsList = computed(() => {
  if (emails.value.includes(',') || emails.value.includes(' ')) {
    return getValidEmailsFromInput()
  }

  return []
})

const onBlur = () => {
  const validEmailsList = getValidEmailsFromInput()
  updateEmailsList(validEmailsList)
}

watch(validEmailsList, newValue => {
  updateEmailsList(newValue)
})

const emit = defineEmits({
  'update:model-value': null,
  focus: null
})

const updateEmailsList = validEmailsList => {
  if (!isEmpty(validEmailsList)) {
    const usersObjects = validEmailsList.map(email => {
      return {
        email
      }
    })
    const result = uniqBy([...cloneDeep(props.modelValue), ...usersObjects], 'email')
    nextTick(() => {
      emails.value = ''
      emit('update:model-value', result)
    })
  }
}

const preparedList = computed(() => {
  return props.modelValue.map(item => {
    return {
      ...item,
      label: item.email,
      value: item.email
    }
  })
})

const onDelete = email => {
  const result = props.modelValue.filter(item => item.email !== email)
  emit('update:model-value', result)
  focus()
}

const onDeleteLast = () => {
  const result = props.modelValue.slice(0, -1)
  emit('update:model-value', result)
  focus()
}

const inputWithTagsReference = ref(null)

const focus = () => {
  inputWithTagsReference.value.focus()
}

const onFocus = () => {
  emit('focus')
}
</script>

<style lang="scss" scoped>
.eci-Wrapper {
  $gap: 6px;
  border: 2px solid $grey-2-next;
  border-radius: $border-radius-sm-next;
  display: flex;
  align-items: center;
  padding: $gap;
  gap: $gap;

  &-error {
    border-color: $grade-low-color-next;
  }

  &:focus-within {
    border-color: $primary-color-next;
  }

  &:deep(.si-SelectedItem) {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      line-height: initial;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .si-selected-tag-clear {
      flex-shrink: 0;
    }
  }
}

.eci-InputWithTags {
  width: 100%;
}
</style>
