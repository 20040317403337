<template>
  <template v-if="isShowCellByAddFor">
    <template v-if="userHasReadAccess">
      <AppDroplist
        v-if="editInitialised"
        v-model="opened"
        :hide-on-click="false"
        :offset="[0, -32]"
        :to-selector="`#otn${uniqId}`"
        max-width="164px"
        position="bottom-start"
        theme="no-shadow-next light"
        trigger="manual"
        @close="onClose"
      >
        <div ref="contentReference" class="otni-Content">
          <AppInputNumberNext
            key="input"
            ref="inputReference"
            v-model="localValue"
            :digit-max-length="NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH"
            :fraction="2"
            :prefix="prefix"
            allow-empty-value
            allow-negative
            class="otni-Content_Input"
            @blur="onBlur"
          />
        </div>
      </AppDroplist>

      <CellSelectItem
        :id="`otn${uniqId}`"
        :is-empty="!displayValue"
        :plug-icon="PLUG_ICONS.COMMON"
        :user-has-read-access="userHasReadAccess"
        :user-has-update-access="userHasUpdateAccess"
        is-transparent
        truncated
        @click.stop="onTriggerClick"
      >
        {{ displayValue }}
      </CellSelectItem>
    </template>
    <AppCipheredText v-else style="--padding-left: 6px" />
  </template>
</template>

<script setup>
defineOptions({
  name: 'OkrsElementsTableNumberInput'
})

import { onClickOutside } from '@vueuse/core'
import { isUndefined } from 'lodash'
import { computed, nextTick, ref } from 'vue'

import { PLUG_ICONS } from '@/utils/cell-helper'
import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'
import { memoizeFormatNumber, shouldDisplayFieldByOkrElementTypeId } from '@/utils/memoizations'
import { NUMBER_FILTER_DEFAULT_DIGIT_MAX_LENGTH } from '@/utils/okr-elements/number-filter'
import { uid } from '@/utils/uid'

import AppDroplist from '@/components/AppDroplist'
import CellSelectItem from '@/components/objectives/table/cells/CellSelectItem'
import AppCipheredText from '@/components/ui/AppCipheredText'
import AppInputNumberNext from '@/components/ui/AppInputNumberNext/AppInputNumberNext'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  userHasReadAccess: {
    type: Boolean
  },

  userHasUpdateAccess: {
    type: Boolean
  },

  prefix: {
    type: String,
    default: ''
  },

  fieldParams: {
    type: Object,
    required: true
  }
})

const displayValue = computed(() => {
  const value = props.item.customFields[props.fieldId]

  return memoizeFormatNumber({
    value: localValue.value || value,
    prefix: props.prefix
  })
})

const uniqId = uid()
const opened = ref(false)
const editInitialised = ref(false)
const inputReference = ref(null)

const onTriggerClick = async () => {
  if (props.userHasUpdateAccess) {
    editInitialised.value = true
    const isNotSetValue = isUndefined(props.item.customFields[props.fieldId])
    localValue.value = isNotSetValue
      ? ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(props.fieldParams.typeId).defaultValue
      : props.item.customFields[props.fieldId]
    await nextTick()
    opened.value = true
    setTimeout(() => {
      inputReference.value.focus()
    })
  }
}

const onClose = () => {
  opened.value = false
}

const contentReference = ref(null)

onClickOutside(contentReference, () => {
  if (opened.value) {
    // if (Array.from(e.composedPath()).some(item => item.classList?.contains('re-ain-Wrapper '))) {
    //   return
    // }
    onClose()
  }
})

const emit = defineEmits({
  'update-field-value': null
})

const localValue = ref(props.item.customFields[props.fieldId])
const onBlur = () => {
  const resolvedValue = localValue.value === '' ? null : localValue.value
  emit('update-field-value', {
    fieldId: props.fieldId,
    value: resolvedValue
  })
  onClose()
}

const isShowCellByAddFor = computed(() => {
  return shouldDisplayFieldByOkrElementTypeId({
    elementTypeIds: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.ELEMENT_TYPE_IDS],
    okrElementTypeId: props.item.typeId,
    okrElementWorkspaceId: props.item.workspaceId,
    workspaceIds: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.WORKSPACE_IDS],
    active: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.ACTIVE]
  })
})
</script>

<style lang="scss" scoped>
.otni-Content_Input {
  --font-size: 14px;
  --font-weight: #{fw('regular')};
}
</style>
