<template>
  <div class="am-Wrapper">
    <AppButton
      :disable="disabled"
      class="am-Wrapper_BackButton"
      type="ghost"
      @click="closeAdditionalMenu"
    >
      <AppIcon height="20" icon-name="menu-additional-menu-chevron" width="20" />
    </AppButton>
    <div ref="menuReference" class="am-Wrapper_Menu">
      <div class="am-Head">
        <AppMenuLogo :placement="APP_MENU_LOGO_PLACEMENTS.ADDITIONAL_MENU" />

        <div class="am-Head_BackButtonWrapper">
          <AppButton
            :class="{ 'am-HeadBackButton-disabled': disabled }"
            :disable="disabled"
            class="am-HeadBackButton"
            icon="menu-additional-menu-chevron"
            size="lg-next"
            type="ghost-next"
            @click="backToReferer"
          >
            {{ $t('action.back') }}
          </AppButton>
        </div>

        <AppVerticalScrollGradient :arrived-state="arrivedState" no-border />
      </div>
      <AppMenuNavList
        :disabled="disabled"
        :items="item.children"
        class="am-ItemsList"
        list-type="additionalMenu"
        style="--gap: 0"
        @item-click="$emit('item-click', $event)"
      >
        <template #list-prepend>
          <li v-if="displayText" class="am-Title oboard-truncated-text">
            {{ displayText }}
          </li>
        </template>
      </AppMenuNavList>
      <AppVerticalScrollGradient :arrived-state="arrivedState" is-bottom-placement />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { APP_MENU_LOGO_PLACEMENTS, useAppMenuHelpers } from '@/utils/app-menu'

import AppMenuLogo from '@/components/AppMenu/AppMenuLogo'
import AppMenuNavList from '@/components/AppMenu/AppMenuNavList'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppVerticalScrollGradient from '@/components/ui/AppVerticalScrollGradient/AppVerticalScrollGradient'

defineOptions({
  name: 'AdditionalMenu'
})

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  disabled: {
    type: Boolean
  }
})

const emit = defineEmits({
  'close-additional-menu': null,
  'item-click': null
})

const store = useStore()

const settingsReferer = computed(() => store.getters['system/settingsReferer'])

const { t } = useI18n()

const displayText = computed(() => {
  const { item } = props
  return item.label ? t(item.label) : item.name
})

const closeAdditionalMenu = () => {
  emit('close-additional-menu')
}

const router = useRouter()

const workspaceId = store.state.workspaces.workspaceId

const backToReferer = () => {
  emit('close-additional-menu')
  const destination = settingsReferer.value || {
    name: ROUTE_NAMES.HOME_PAGE,
    params: { workspaceId }
  }
  router.push(destination)
}

const { menuReference, arrivedState } = useAppMenuHelpers()
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/app-menu';

.am-Wrapper {
  // background-color: $main-bg-grey;
  background-color: $white;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  // height: 100%;
  height: calc(100% - var(--menu-footer-height));
  display: flex;
}

.am-Wrapper_BackButton {
  // background: #eaecf0;
  height: 100%;
  // padding: 2px;
  padding: 0;
  border-radius: 0;
  align-items: center;
  transition: background, color, opacity $transition-normal;
  color: $regular-text;
  opacity: 0;
  @media (any-hover: hover) {
    &:hover {
      // background: $lines-grey;
      background: transparent;
      color: $primary-color;
      opacity: 1;
    }
  }
}

.am-Wrapper_Menu {
  // width: calc(100% - 24px);
  width: calc(100% - 20px);
  // padding: 0 16px 16px;
  padding-right: 20px;
  height: 100%;
  overflow-y: auto;
  @include styled-native-scrollbar();
  display: flex;
  flex-direction: column;
}

.am-Head {
  text-decoration: none;
  color: $regular-text;
  @extend %menu-header-styles;
}

.am-Head_BackButtonWrapper {
  padding: 12px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: $grey-2-next;
}

.am-HeadBackButton {
  --button-high-padding: 8px;
  width: 100%;
  justify-content: flex-start;
  color: $primary-color-next;
  font-weight: fw('regular');
  &-disabled {
    opacity: 0.5;
  }
}

.am-Title {
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-20;
  line-height: 28px;
  color: $dark-1;
  padding: 6px 8px;
  margin-bottom: 8px;
}

.am-ItemsList {
  margin-bottom: auto;
}
</style>
