import WorkspaceUsersApiHandler from '@/api/workspace-users'
import { handleError } from '@/utils/error-handling'
import { prepareFilterParams } from '@/utils/users-helpers'

export const useFetchWorkspaceUsers = async (
  filterParams,
  { itemsOnPage = 50, currentPage = 1 } = {}
) => {
  const workspaceUsersApi = new WorkspaceUsersApiHandler()
  const { groupIds, accountIds, roleIds, workspaceId, searchString = null } = filterParams
  try {
    return await workspaceUsersApi.getUsersFromWorkspace({
      ...prepareFilterParams({
        groupIds,
        accountIds,
        roleIds
      }),
      workspaceId,
      searchString,
      limit: itemsOnPage,
      startAt: (currentPage - 1) * itemsOnPage // calculate pagination, example: (1 - 1) * 50 = 0, (2 - 1) * 50 = 50
    })
  } catch (error) {
    handleError({ error })
    throw error
  }
}
