<template>
  <div>
    <AppButton
      :id="`ir-Trigger-${uniqId}`"
      :class="{ 'ir-Trigger-active': isButtonActive }"
      class="ir-Trigger"
      height="24"
      icon="edit-next"
      remove-padding
      size="sm"
      type="ghost-next"
      width="24"
      @click.stop="onTriggerClick"
    />

    <AppDroplist
      v-if="editInitialised"
      v-model="isOpen"
      :dropdown-width="dropdownWidth"
      :offset="[0, 0]"
      :to-selector="`#ir-Trigger-${uniqId}`"
      class="ir-Wrapper"
      position="bottom"
      theme="no-shadow-next light"
    >
      <div class="ir-Content">
        <div>
          <AppInput
            ref="inputReference"
            v-model="localName"
            :is-error="!isEmpty(errors)"
            :max-length="maxLength"
            :placeholder="$t('okr_element.rename.placeholder')"
            blur-on-enter
            class="ir-Input"
            size="lg"
            style-type="primary"
            @focus="errors = []"
            @keydown.enter="save"
          />

          <AppFieldError v-if="!isEmpty(errors)" :show="!isEmpty(errors)">
            {{ $t(errors[0]) }}
          </AppFieldError>
        </div>

        <div class="ir-Content_Footer">
          <AppButton type="ghost-next" @click="cancel">
            {{ $t('action.cancel') }}
          </AppButton>

          <AppButton :disable="!localName" :loading="isLoading" type="primary-next" @click="save">
            {{ $t('action.confirm') }}
          </AppButton>
        </div>
      </div>
    </AppDroplist>
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { nextTick, ref, watch } from 'vue'

import { uid } from '@/utils/uid'

import AppDroplist from '@/components/AppDroplist'
import AppFieldError from '@/components/form/AppFieldError'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInput from '@/components/ui/AppInput/AppInput'

defineOptions({
  name: 'InlineRename'
})

const props = defineProps({
  entityName: {
    type: String,
    required: true
  },

  isLoading: {
    type: Boolean
  },

  maxLength: {
    type: [String, Number],
    default: Infinity
  },

  dropdownWidth: {
    type: String,
    default: '400px'
  }
})

const uniqId = uid()

const localName = ref('')

const isOpen = ref(false)
const isButtonActive = ref(false)
const inputReference = ref(null)

const editInitialised = ref(false)
const onTriggerClick = () => {
  if (!editInitialised.value) {
    editInitialised.value = true
  }

  nextTick(() => {
    if (!isOpen.value) {
      isOpen.value = true
    }
    localName.value = props.entityName
  })
}

/**
 * Active class for button is depends on isButtonActive variable which in turn depends on isOpen variable
 * to prevent blinking of dropdown when it's disactivate
 * blinking is caused by dropdown has animation but button change display state instant,
 * so we need to wait for animation end to change isButtonActive variable
 */
watch(
  () => isOpen.value,
  value => {
    setTimeout(() => {
      isButtonActive.value = value
      if (value) inputReference.value.focus()
    }, 0)
  }
)

const closeDropdown = () => {
  if (!props.isLoading) {
    isOpen.value = false
    nextTick(() => {
      localName.value = props.entityName
    })
  }
}

const emit = defineEmits({
  'update-name': null
})

const errors = ref([])

const save = async () => {
  if (!localName.value.trim()) {
    errors.value.push('field.required')
    return
  }
  if (localName.value === props.entityName) {
    closeDropdown()
    return
  }

  emit('update-name', {
    newName: localName.value,
    callback: closeDropdown
  })
}

const cancel = () => {
  closeDropdown()
}
</script>

<style lang="scss" scoped>
.ir-Content {
  padding: 20px;
  display: grid;
  gap: 16px;
}

.ir-Content_Footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.ir-Trigger {
  display: none;

  &-active {
    display: flex;
    background-color: lighten($dark-3, 40%);
  }

  .o-objective-row:hover & {
    display: flex;
  }
}
</style>
