<template>
  <AppModalWithConfirmation
    :confirm-close="areDataChanged"
    :show="show"
    :title="$t('statuses.rename_status')"
    hide-hero
    @on-close="onClose"
  >
    <FormFieldNext :label="$t('statuses.status_name')">
      <AppInput
        ref="inputReference"
        v-model="name"
        :is-error="isNameEmpty"
        :placeholder="$t('statuses.status_name')"
        max-length="11"
        size="xlg"
        style-type="primary"
        @focus="isNameEmpty = false"
        @input="onUpdateName"
      />
      <AppFieldError v-if="isNameEmpty" :show="isNameEmpty">
        {{ $t('field.required') }}
      </AppFieldError>
    </FormFieldNext>
    <template #footer-actions>
      <AppButton type="ghost-next" @click="onClose">
        {{ $t('action.cancel') }}
      </AppButton>
      <AppButton
        :disable="isConfirmButtonDisabled"
        :loading="loading"
        type="primary-next"
        @click="onRename"
      >
        {{ $t('action.update') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { nextTick, watch, ref, computed, unref } from 'vue'

import RenameStatusApiHandler from '@/api/rename-status'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { STATUS_NAMES_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInput from '@/components/ui/AppInput/AppInput'

const props = defineProps({
  show: {
    type: Boolean
  },

  statusProperties: {
    type: Object,
    required: true
  }
})

const inputReference = ref(null)
const name = ref('')

const setDefaultState = () => {
  name.value = ''
}

const emit = defineEmits({
  close: null,
  'status-renamed': null
})

watch(
  () => props.show,
  async newValue => {
    if (newValue) {
      name.value = props.statusProperties[STATUS_NAMES_ENTITY_KEYS.CUSTOMER_NAME]
      // nextTick and setTimeout(both!) are needed to make input always focused on modal window open
      await nextTick()
      setTimeout(() => {
        inputReference.value?.focus()
      }, 200)
    } else {
      setDefaultState()
    }
  },
  {
    immediate: true
  }
)

const onClose = () => {
  emit('close')
}

const areDataChanged = computed(() => {
  return name.value !== props.statusProperties[STATUS_NAMES_ENTITY_KEYS.CUSTOMER_NAME]
})

const loading = ref(false)
const isNameEmpty = ref(false)
const onRename = async () => {
  isNameEmpty.value = name.value.trim() === ''
  if (unref(isNameEmpty)) {
    return
  }
  const api = new RenameStatusApiHandler()
  loading.value = true
  try {
    const payload = {
      statusId: props.statusProperties[STATUS_NAMES_ENTITY_KEYS.STATUS_ID],
      newName: unref(name)
    }
    await api.renameStatus(payload)
    emit('status-renamed', payload)
    onClose()

    tracker.logEvent('status updated', {
      category: EVENT_CATEGORIES.SETTINGS,
      label: payload.newName,
      id: payload.statusId
    })
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
}

const onUpdateName = () => {
  name.value = name.value.replace(/[{}]/g, '')
}

const isConfirmButtonDisabled = computed(() => {
  return unref(name).trim() === props.statusProperties[STATUS_NAMES_ENTITY_KEYS.CUSTOMER_NAME]
})
</script>

<style lang="scss" scoped></style>
