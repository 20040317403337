<template>
  <OkrFilterSelect
    :key="`sff-${fieldId}`"
    :bottom-fixed-items="getBottomFixedItems"
    :hidden-items="[null]"
    :model-value="filterValue"
    :n-selected-label="emptyStateLabel"
    :options="allOptions"
    :search-function="value => localSearch({ value, options })"
    additional
    append-to=".ot-AdditionalFiltersContent_Filters"
    class="sff_SelectButton"
    has-only-this-button
    multi
    simplified-selected
    type="default-next"
    @update:model-value="onUpdateFilterValue"
  >
    <template #bottom-fixed-items="{ bottomFixedItems }">
      <div v-for="item in bottomFixedItems" :key="item.id">
        <BottomFixedSelectItem v-if="isClearSelectionAction(item.action)" @click="resetValue">
          {{ $t(item.text) }}
        </BottomFixedSelectItem>
      </div>
    </template>
    <template #button="{ active, fullDataOption, option }">
      <OkrFormFieldSelectTriggerNext
        :label="fieldName"
        :opened="active"
        :selected-options="option"
        truncated-label
        type="secondary"
      >
        <template v-if="isShowAllContain(fullDataOption)" #values>
          <span class="sff-SelectButton-Value"> {{ $t('filter.show_all') }} </span>
        </template>
      </OkrFormFieldSelectTriggerNext>
    </template>
  </OkrFilterSelect>
</template>

<script setup>
import { cloneDeep, isEqual } from 'lodash'
import { computed, nextTick, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  getBottomFixedItemsClearSelectionForCustomFieldFilter,
  isClearSelectionAction
} from '@/composables/bottom-fixed-items'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { localSearch } from '@/utils/objectives'
import { DEFAULT_VALUE_FOR_FILTER, isShowAllContain } from '@/utils/okr-elements/filters'

import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'

defineOptions({
  name: 'SelectFieldFilter',
  inheritAttrs: false
})

const props = defineProps({
  fieldId: {
    type: [String, Number],
    required: true
  },

  filterValue: {
    type: Array,
    required: true
  }
})

const emit = defineEmits({
  'update-filter-value': null,
  'initial-data-loaded': null
})

const getBottomFixedItems = computed(() => {
  return getBottomFixedItemsClearSelectionForCustomFieldFilter({
    filterValue: props.filterValue
  })
})

const { options, fieldName, emptyStateLabel } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const { t } = useI18n()

const allOptions = computed(() => {
  return [
    {
      id: 0,
      name: t('filter.show_all')
    },
    ...options.value
  ]
})

const onUpdateFilterValue = value => {
  emit('update-filter-value', {
    value,
    fieldId: props.fieldId
  })
}

const resetValue = () => {
  onUpdateFilterValue(cloneDeep(DEFAULT_VALUE_FOR_FILTER))
}

const validateFilterValue = async () => {
  const allOptionsValues = allOptions.value.map(item => item.id)
  const validatedValue = props.filterValue.filter(item => allOptionsValues.includes(item))

  if (
    !isEqual(props.filterValue, validatedValue) ||
    !isEqual(validatedValue, DEFAULT_VALUE_FOR_FILTER)
  ) {
    onUpdateFilterValue(validatedValue)
  }

  await nextTick()
  emit('initial-data-loaded')
}

onMounted(() => {
  validateFilterValue()
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/filters';

.sff_SelectButton {
  width: 100%;
}

.sff-SelectButton-Value {
  @extend %select-button-value;
}
</style>
