<template>
  <!--  <CellValue
    v-if="!isJiraIssue"
    :id="`ls${uid}`"
    :cell-value="displayName"
    :user-has-update-access="updatable"
    :user-has-read-access="readable"
    show-native-tooltip
    @click="editInitialised = true"
  >

  </CellValue>-->

  <template v-if="!isJiraIssue">
    <CellSelectItemsList
      :id="`ls${uid}`"
      :active="isOpened"
      :items="item.labels"
      :plug-icon="PLUG_ICONS.SELECT"
      :user-has-read-access="readable"
      :user-has-update-access="updatable"
      item-value="name"
      show-native-tooltip
      @click="editInitialised = true"
    />
    <template v-if="updatable">
      <AppSelect
        v-if="editInitialised"
        :loading="areLabelsLoading"
        :model-value="labelIds"
        :offset="[0, 0]"
        :options="labels"
        :search-function="getLabels"
        :search-max-length="50"
        :to-selector="`#ls${uid}`"
        append-to=".o-objective-table"
        boundary="scrollParent"
        dropdown-width="220px"
        has-only-this-button
        hide-selected-items-in-dropdown
        item-label="name"
        item-value="id"
        multi
        show-on-init
        show-selected-options-inside
        theme="no-shadow-next light"
        type="default-next"
        @hide="hideSelect"
        @open="areLabelsLoading = true"
        @opened="fetchInitialLabels"
        @update:model-value="onLabelUpdate"
      />
    </template>
  </template>
</template>

<script>
import { defineComponent } from 'vue'

import LabelsApiHandler from '@/api/labels'
import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { PLUG_ICONS } from '@/utils/cell-helper'
import { handleError } from '@/utils/error-handling'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import { uid } from '@/utils/uid'

import CellSelectItemsList from '@/components/objectives/table/cells/CellSelectItemsList'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

const labelsApi = new LabelsApiHandler()

export default defineComponent({
  name: 'LabelsCell',

  components: {
    CellSelectItemsList,
    AppSelect
  },

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      required: true
    },

    readable: {
      type: Boolean
    },

    updatable: {
      type: Boolean
    }
  },

  emits: { 'update-labels': null },

  data() {
    return {
      uid: uid(),
      labels: [],
      areLabelsLoading: false,

      editInitialised: false,
      isOpened: false
    }
  },

  computed: {
    PLUG_ICONS() {
      return PLUG_ICONS
    },

    labelIds() {
      return this.item.labels.map(label => label.id)
    },

    isJiraIssue() {
      return this.item.typeId === OBJECTIVE_TYPES.TASK
    }
  },

  methods: {
    hideSelect() {
      this.isOpened = false
      this.labels = []
    },

    async fetchInitialLabels() {
      this.isOpened = true
      this.labels = await this.getLabels()
    },

    async getLabels(searchString = null) {
      let result = []
      try {
        this.areLabelsLoading = true
        result = await labelsApi.getLabels({ name: searchString })
      } catch (error) {
        handleError({ error })
      } finally {
        this.areLabelsLoading = false
      }
      return result
    },

    async onLabelUpdate(value) {
      const api = new ObjectivesInfoApiHandler()
      const {
        contribute,
        dueDate,
        elementStartDate,
        jiraIssueId,
        intervalId,
        name,
        orderValue,
        parentId,
        levelId,
        workspaceId,
        confidenceLevelId,
        originalValue,
        currentValue,
        targetValue,
        fieldTypeId,
        grade
      } = this.item
      try {
        const result = await api.updateOkrElement({
          contribute,
          dueDate,
          elementStartDate,
          jiraIssueId,
          intervalId,
          name,
          orderValue,
          parentId,
          levelId,
          workspaceId,
          confidenceLevelId,
          originalValue,
          currentValue,
          targetValue,
          fieldTypeId,
          grade,
          elementId: this.item.id,
          labelIds: value
        })

        this.$emit('update-labels', { ...this.item, ...result })
      } catch (error) {
        handleError({ error })
      }
    }
  }
})
</script>
