<template>
  <AppDroplist
    v-if="showBanner"
    :offset="[0, 0]"
    append-to=".cbb-Wrapper"
    placement="right"
    theme="transparent"
    trigger="mouseenter click"
  >
    <template #button>
      <div class="cbb-Wrapper">
        <span class="cbb-Wrapper_Content">
          <AppIcon height="24" icon-name="question-circle" width="24" />
          <span class="oboard-truncated-text cbb-Title">
            <slot name="title" />
          </span>
        </span>
        <AppButton class="cbb-ButtonTrigger" type="primary-next" @click="openSurvey">
          <slot name="button-text" />
        </AppButton>
        <AppButton
          class="cbb-ButtonClose"
          height="24"
          icon="close-circle-next"
          remove-padding
          size="sm"
          type="ghost-next"
          width="24"
          @click="onClick"
        />
      </div>
    </template>
  </AppDroplist>
</template>

<script setup>
import { ref } from 'vue'

import { openLink } from '@/utils/external-link-helper'
import { DEFAULT_USERS_SETTINGS, SURVEY_BANNER } from '@/utils/user-settings'

import AppDroplist from '@/components/AppDroplist'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'SurveyBanner'
})

const emit = defineEmits({
  // 'recalculate-footer-height': null,
  'update-banner-data': null
})

const props = defineProps({
  link: {
    type: String,
    default: ''
  }
})
const showBanner = ref(true)

const onClick = () => {
  showBanner.value = false
  emit('update-banner-data', DEFAULT_USERS_SETTINGS[SURVEY_BANNER])
  // nextTick(() => {
  //   emit('recalculate-footer-height')
  // })
}

const openSurvey = () => {
  openLink(props.link)
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_menu_banners';

.cbb-Wrapper {
  @extend %wrapper;
}

.cbb-Wrapper_Content {
  @extend %wrapper_content;

  .cbb-Wrapper:hover & {
    text-decoration: none;
    color: $primary-color-next;
  }
}

.cbb-Title {
  @extend %title;
}

.cbb-ButtonClose {
  @extend %button_close;
}

.cbb-ButtonTrigger {
  @extend %button_trigger;
}
</style>
