<template>
  <AppDroplist
    ref="statusDropDown"
    :append-to="appendTo"
    :disabled="disabled || valueIsEmpty"
    :offset="[0, 0]"
    dropdown-width="min-content"
    placement="bottom-start"
    @close="opened = false"
    @open="opened = true"
  >
    <template #button>
      <slot :option="currentStatusOption" name="button">
        <FormStatusButton
          :disabled="disabled || valueIsEmpty"
          :opened="opened"
          :option="currentStatusOption"
          :outline-button-style="outlineButtonStyle"
        />
      </slot>
    </template>
    <div :class="dropdownClass" class="ss-Wrapper">
      <div v-if="!isOkrElementClosed" class="ss-RadioWrapper">
        <AppRadioGroup
          v-model="basedOnGrade"
          :options="basedOnGradeOptions"
          class="ss-RadioGroup"
          data-testid="auto-switch"
          name="auto-switch"
          style="--option-padding: 0 8px 0 0"
          type="primary-next"
        >
          <template #item-label="{ item }">
            <span class="ss-AutoSwitchOption">
              <AppRadioGroupNextOptionWithIcon :option="item" />
            </span>
          </template>
        </AppRadioGroup>
      </div>

      <AppInfoMessage v-if="basedOnGrade && !isOkrElementClosed" class="ss-Info">
        {{ $t('status.switch_tooltip') }}
      </AppInfoMessage>

      <template v-else>
        <AppRadioGroup
          v-if="!isOkrElementClosed"
          v-model="localValue"
          :options="statusOptionsNext"
          class="ss-StatusSwitch"
          data-testid="default-status-switch"
          name="status-switch"
          type="vertical"
          @update:model-value="onStatusUpdate"
        >
          <template #item-label="{ item }">
            <AppIcon :icon-name="item.icon" class="ss-RadioOption_Icon" height="8" width="8" />
            <span>{{ item.label }}</span>
          </template>
        </AppRadioGroup>
      </template>

      <!--  div and v-show needed because has some bug with closing .gs-ContentWrapper  -->
      <div v-show="isOkrElementClosed">
        <AppButton
          v-for="reopenItem in reopenOptions"
          :key="reopenItem.label"
          :class="{ 'ss-AdditionalStatusButton-active': reopenItem.value === localValue }"
          :style="{ '--bg': reopenItem.color }"
          class="ss-AdditionalStatusButton"
          data-testid="reopen-status-switch"
          size="lg-next"
          type="secondary-inline-next"
          @click="onStatusUpdate(reopenItem.value)"
        >
          <AppIcon
            :icon-name="reopenItem.icon"
            class="ss-OptionReopen_Icon"
            height="24"
            width="24"
          />
          {{ reopenItem.label }}
        </AppButton>
      </div>

      <AppDivider v-if="isOkrElementClosed" class="ss-Divider" />
      <AppButton
        v-for="closedStatus in additionalStatusOptionsNext"
        :key="closedStatus.label"
        :class="{ 'ss-AdditionalStatusButton-active': closedStatus.value === localValue }"
        :data-testid="`additional-status-switch-${closedStatus.value}`"
        :style="{ '--bg': closedStatus.color }"
        class="ss-AdditionalStatusButton"
        size="lg-next"
        type="secondary-inline-next"
        @click="onStatusUpdate(closedStatus.value)"
      >
        <AppIcon :icon-name="closedStatus.icon" class="ss-RadioOption_Icon" height="8" width="8" />
        {{ closedStatus.label }}
      </AppButton>
    </div>
  </AppDroplist>
</template>

<script>
import dayjs from 'dayjs'
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import {
  OBJECTIVE_STATUS_OPTIONS,
  objectiveStatusAutocalculatedOptions,
  calculateObjectiveStatus,
  OBJECTIVE_STATUS_AUTO,
  OKR_STATUSES,
  additionalObjectiveStatusAutocalculatedOptions,
  ADDITIONAL_OBJECTIVE_STATUS_OPTIONS,
  isOkrElementClosed,
  BACKLOG_OBJECTIVE_STATUS_OPTIONS,
  BACKLOG_STATUS_VALUE,
  OBJECTIVE_STATUS_BACKLOG,
  backlogObjectiveStatusAutocalculatedOptions,
  REOPEN_OBJECTIVE_STATUS_OPTIONS,
  NOT_STARTED_OPTION
} from '@/utils/objectives'

import AppDroplist from '@/components/AppDroplist'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppRadioGroupNextOptionWithIcon from '@/components/ui/AppRadioGroup/AppRadioGroupNextOptionWithIcon'
import FormStatusButton from '@/components/ui/AppSelect/TriggerButtons/FormStatusButton'

const DAYS_UNIT = 'days'

export default defineComponent({
  name: 'StatusSelect',

  components: {
    AppInfoMessage,
    AppButton,
    AppDivider,
    AppRadioGroupNextOptionWithIcon,
    AppRadioGroup,
    AppDroplist,
    FormStatusButton,
    AppIcon
  },

  props: {
    autocalculateToggle: {
      type: Boolean
    },

    disabled: {
      type: Boolean
    },

    grade: {
      type: Number,
      default: 0
    },

    objective: {
      type: Object,
      required: true
    },

    appendTo: {
      type: String,
      default: ''
    },

    dropdownClass: {
      type: String,
      default: ''
    },

    outlineButtonStyle: {
      type: Boolean
    }
  },

  emits: { 'update:confidence-level-id': null },

  data() {
    let localValue = calculateObjectiveStatus(
      this.objective.confidenceLevelId,
      this.objective.automaticConfidenceLevelId
    )
    return {
      basedOnGrade: !this.objective.confidenceLevelId,
      localValue,
      opened: false
    }
  },

  computed: {
    ...mapState('system', {
      settings: state => state.settings
    }),

    isOkrElementClosed() {
      return isOkrElementClosed({ confidenceLevelId: Math.abs(this.localValue) })
    },

    basedOnGradeOptions() {
      return [
        {
          value: true,
          label: 'status.switch_auto_next',
          icon: 'period-mode-auto-next'
        },
        {
          value: false,
          label: 'status.switch_manual',
          icon: 'period-mode-manual-next'
        }
      ]
    },

    currentStatusOption() {
      return [
        ...this.statusOptions,
        ...this.additionalStatusOptions,
        ...this.reopenOptions,
        ...this.backlogOption
      ].find(({ value }) => value === this.localValue)
    },

    getHiddenItems() {
      return [
        NOT_STARTED_OPTION,
        ...objectiveStatusAutocalculatedOptions,
        ...backlogObjectiveStatusAutocalculatedOptions
      ].map(option => option.value)
    },

    statusOptions() {
      const result = [
        ...objectiveStatusAutocalculatedOptions.map(option => ({
          ...option,
          label: this.$t(option.label)
        }))
      ]
      if (!this.basedOnGrade) {
        result.push(
          ...OBJECTIVE_STATUS_OPTIONS.map(option => ({ ...option, label: this.$t(option.label) }))
        )
      }
      if (this.autocalculateToggle) {
        result.push({ value: 'auto', icon: '', label: '' })
      }
      if (this.localValue === OKR_STATUSES.AUTO) {
        result.push({ ...OBJECTIVE_STATUS_AUTO, label: this.$t(OBJECTIVE_STATUS_AUTO.label) })
      }
      if (this.localValue === BACKLOG_STATUS_VALUE) {
        result.push({ ...OBJECTIVE_STATUS_BACKLOG, label: this.$t(OBJECTIVE_STATUS_BACKLOG.label) })
      }
      return result
    },

    statusOptionsNext() {
      return this.statusOptions.filter(
        ({ value }) => !this.getHiddenItems.includes(value) && !this.bottomFixedItem.includes(value)
      )
    },

    additionalStatusOptions() {
      return [
        ...additionalObjectiveStatusAutocalculatedOptions,
        ...ADDITIONAL_OBJECTIVE_STATUS_OPTIONS
      ].map(option => ({
        ...option,
        label: this.$t(option.label)
      }))
    },

    additionalStatusOptionsNext() {
      return this.additionalStatusOptions.filter(({ value }) => {
        return (
          !this.getHiddenItems.includes(value) &&
          !this.bottomFixedItem.includes(value) &&
          value > OKR_STATUSES.AUTO
        )
      })
    },

    reopenOptions() {
      return REOPEN_OBJECTIVE_STATUS_OPTIONS.map(option => ({
        ...option,
        label: this.$t(option.label)
      }))
    },

    backlogOption() {
      return [
        ...backlogObjectiveStatusAutocalculatedOptions,
        ...BACKLOG_OBJECTIVE_STATUS_OPTIONS
      ].map(option => ({
        ...option,
        label: this.$t(option.label)
      }))
    },

    bottomFixedItem() {
      if (this.autocalculateToggle) {
        return ['auto']
      }
      return []
    },

    valueIsEmpty() {
      return [
        OKR_STATUSES.AUTO,
        BACKLOG_STATUS_VALUE,
        -BACKLOG_STATUS_VALUE,
        -OKR_STATUSES.AUTO
      ].includes(this.localValue)
    }
  },

  watch: {
    grade() {
      let newStatus = this.calculateNewStatus()

      if (this.basedOnGrade) {
        newStatus = -newStatus
      }
      this.localValue = newStatus
    },

    localValue(newValue, oldValue) {
      if (oldValue === newValue) {
        return
      }

      if (newValue < 0) {
        if (this.objective.confidenceLevelId) {
          this.$emit('update:confidence-level-id', OKR_STATUSES.AUTO)
        }
      } else if (this.objective.confidenceLevelId !== newValue) {
        this.$emit('update:confidence-level-id', newValue)
      }
    },

    basedOnGrade(newValue, oldValue) {
      if (!oldValue && newValue && this.objective.confidenceLevelId) {
        // set to -automaticConfidenceLevelId to save old value until new is here and avoid blinking
        // of status
        this.localValue = -this.objective.automaticConfidenceLevelId
      } else if (
        oldValue &&
        !newValue &&
        !this.objective.confidenceLevelId &&
        this.objective.confidenceLevelId !== this.objective.automaticConfidenceLevelId
      ) {
        this.$emit('update:confidence-level-id', this.objective.automaticConfidenceLevelId)
      }

      this.$refs.select?.updateDropdown()
    },

    objective(newValue) {
      this.onUpdateObjective(newValue)
    }
  },

  methods: {
    onUpdateObjective(newValue) {
      let newLocalValue = newValue.confidenceLevelId
      if (!newValue.confidenceLevelId) {
        newLocalValue = -newValue.automaticConfidenceLevelId
      }

      if (this.localValue !== newLocalValue) {
        this.localValue = newLocalValue
      }
      const newBasedOnGrade = !newValue.confidenceLevelId
      if (this.basedOnGrade !== newBasedOnGrade) {
        this.basedOnGrade = newBasedOnGrade
      }
    },

    onStatusUpdate(value) {
      if (value !== this.localValue) {
        this.$emit('update:confidence-level-id', value)
      }
      this.$refs.statusDropDown.hide()
    },

    calculateNewStatus() {
      const todayMoment = dayjs()
      let tomorrowMoment = todayMoment.clone().add(1, DAYS_UNIT)
      if (dayjs(this.objective.automaticDueDate).isBefore(tomorrowMoment)) {
        tomorrowMoment = dayjs(this.objective.automaticDueDate)
      }

      const startDayMoment = dayjs(this.objective.automaticElementStartDate)
      let endDayMoment = null
      if (this.objective.dueDate !== null) {
        endDayMoment = dayjs(this.objective.dueDate)
      } else {
        endDayMoment = dayjs(this.objective.automaticDueDate)
      }
      const totalDays = endDayMoment.diff(startDayMoment, DAYS_UNIT)

      const daysPassed = tomorrowMoment.diff(startDayMoment, DAYS_UNIT) / totalDays
      const timePassed = daysPassed < 0 ? 1 : daysPassed // avoid negative values. If endDate < startDate
      const statusValue = this.grade / 100 / timePassed

      if (statusValue >= this.settings.thresholdOnTrack / 100) {
        return OKR_STATUSES.ON_TRACK
      }
      if (statusValue >= this.settings.thresholdBehind / 100) {
        return OKR_STATUSES.BEHIND
      }
      return OKR_STATUSES.AT_RISK
    }
  }
})
</script>

<style lang="scss">
.ss-StatusSelectSelector {
  .as-Options {
    box-shadow: inset 0 -1px 0 $grey-2;
  }
}

.ss-StatusSelect {
  .as-AppSelect-inline .as-AppDroplistButton {
    width: 112px;

    &_Icon {
      margin-left: -3px;
    }
  }

  .o-droplist--disabled {
    opacity: 1;
  }

  .svg-icon {
    flex-shrink: 0;
  }
}

.ss-Option {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
}

.ss-Option_Icon {
  flex-shrink: 0;
}

.ss-Option_Label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ss-AutoCalculateToggle {
  min-height: 44px;
  box-sizing: border-box;
  padding: 10px 6px 10px 12px;
  display: flex;
  align-items: center;
}
</style>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.ss-Wrapper {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-md;
  overflow: hidden;
}

.ss-Info {
  white-space: normal;
  color: $dark-3;
  padding: 10px;
}

.ss-StatusSwitch {
  width: 100%;
  background: $grey-3-next;
  margin: 0 0 8px 0;

  &-reopen {
    margin: 0;
    border-radius: $border-radius-sm-next $border-radius-sm-next 0 0;
    --border-radius: $border-radius-sm-next $border-radius-sm-next 0 0;
  }
}

.ss-RadioGroup {
  margin: 10px;
  background: $grey-2-next;
}

.ss-AdditionalStatusButton {
  width: 100%;
  background: $white;
  color: $dark-1;
  border-radius: 0;
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
  min-width: 200px;
  gap: 8px;

  &-active {
    color: $dark-1;
    background: rgba(0, 82, 204, 0.15);
  }

  &:hover {
    background: $grey-2-next;
    color: $dark-1;
  }

  @include activeState($grey-2-next, 10%);
}

.ss-RadioWrapper {
  background: $grey-3-next;
  border-radius: $border-radius-md $border-radius-md 0 0;
}

.ss-RadioOption_Icon {
  color: var(--bg, transparent);
  box-shadow: inset 0 0 0 2px $white;
  border-radius: 20px;
  margin: 0 8px;
}

.ss-OptionReopen_Icon {
  color: var(--bg, transparent);
}

.ss-Divider {
  margin: 4px auto;
  width: calc(100% - 10px);
}
</style>
