<template>
  <div :class="{ 'ggnc-Cell-filtered-out': filteredOut }" class="ggnc-Cell">
    <template v-if="isGroupUnavailable(group)">
      <UnavailableGroup data-testid="unavailable-group" />
      <span v-if="!hideCipher" class="ggnc-Cell_Cipher"> ∗∗∗∗∗∗∗∗∗∗∗ </span>
      <span v-if="!hideWarningMessage" class="ggnc-Cell_WarningMessage oboard-truncated-text">
        {{ t('global_groups.unavailable_group') }}
      </span>
    </template>

    <template v-else>
      <GroupIcon :color="group[colorKey]" :icon-name="group[iconKey]" />

      <span
        :class="{
          'ggnc-Cell_Name-edit': !hideActions && userHasUpdateAccess
        }"
        class="ggnc-Cell_Name oboard-truncated-text"
        data-testid="name-value"
        @click.self.stop="onNameClick"
      >
        {{ group[labelKey] }}
      </span>

      <InlineRename
        v-if="showRenameButton && userHasUpdateAccess"
        :entity-name="group[labelKey]"
        :is-loading="renameLoading"
        class="ggnc-Cell_NameEdit"
        max-length="63"
        @update-name="onUpdateName"
      />

      <GroupLabels v-if="!hideGroupInfo" :labels-list="labelsList">
        <template #user-count>
          {{ group[GROUP_ENTITY_KEYS.USER_COUNT] }}
        </template>

        <template #child-count>
          {{ group[GROUP_ENTITY_KEYS.CHILD_COUNT] }}
        </template>
      </GroupLabels>

      <AppIcon
        v-if="isShowSyncIcon"
        class="ggnc-Cell_SyncIcon"
        height="24"
        icon-name="replace_jira"
        width="24"
      />

      <div
        v-if="!hideActions && !isEmpty(cellActions)"
        :class="{
          'ggnc-Cell_Actions-no-labels': hideActionsLabel
        }"
        class="ggnc-Cell_Actions"
      >
        <AppButton
          v-for="action in cellActions"
          :key="action[ICON]"
          v-tippy="{
            content: action[TOOLTIP]
          }"
          :data-testid="`row-action-${action[ACTION]}`"
          :icon="action[ICON]"
          class="ggnc-ActionButton"
          size="sm"
          type="ghost-next"
          @click.stop="() => $emit(action[ACTION])"
        >
          {{ action.label }}
        </AppButton>
      </div>
    </template>
  </div>
</template>

<script setup>
import { isEmpty, isFunction } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { GROUP_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import {
  GLOBAL_GROUP_KEYS,
  GLOBAL_GROUPS_NAME_CELL_ACTIONS,
  GLOBAL_GROUPS_NAME_CELL_LABELS,
  isGroupUnavailable,
  userCanUpdateGroup
} from '@/utils/global-groups'

import { globalGroupNameCellListValidator } from '@/components/AppMenu/props-validators'
import GroupIcon from '@/components/global-groups/GroupIcon'
import GroupLabels from '@/components/global-groups/GroupLabels'
import UnavailableGroup from '@/components/global-groups/UnavailableGroup'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import InlineRename from '@/components/ui/InlineRename/InlineRename'

defineOptions({
  name: 'GlobalGroupNameCell'
})

const props = defineProps({
  group: {
    type: Object,
    required: true
  },

  hideActions: {
    type: Boolean
  },

  hideGroupInfo: {
    type: Boolean
  },

  hideActionsLabel: {
    type: Boolean
  },

  hideWarningMessage: {
    type: Boolean
  },

  hideCipher: {
    type: Boolean
  },

  filteredOut: {
    type: Boolean
  },

  labelKey: {
    type: String,
    default: 'name'
  },

  actionsList: {
    type: Array,
    default: () => Object.values(GLOBAL_GROUPS_NAME_CELL_ACTIONS),

    validator: value =>
      globalGroupNameCellListValidator({ value, whiteList: GLOBAL_GROUPS_NAME_CELL_ACTIONS })
  },

  labelsList: {
    type: Array,
    default: () => Object.values(GLOBAL_GROUPS_NAME_CELL_LABELS),

    validator: value =>
      globalGroupNameCellListValidator({ value, whiteList: GLOBAL_GROUPS_NAME_CELL_LABELS })
  },

  skipPermissionsCheck: {
    type: Boolean
  },

  colorKey: {
    type: String,
    default: GROUP_ENTITY_KEYS.COLOR
  },

  iconKey: {
    type: String,
    default: GROUP_ENTITY_KEYS.ICON
  },

  showSyncIcon: {
    type: Boolean
  },

  showRenameButton: {
    type: Boolean
  }
})

const { t } = useI18n()

const ICON = 'icon'
const LABEL = 'label'
const ACTION = 'action'
const CHECK_PERMISSIONS = 'checkPermissions'
const TOOLTIP = 'tooltip'

const ACTIONS_OPTIONS = {
  [GLOBAL_GROUPS_NAME_CELL_ACTIONS.MANAGE_USERS]: {
    [ICON]: 'manage-users',
    [LABEL]: t('global_groups.manage_users'),
    [ACTION]: 'manage-group',
    [CHECK_PERMISSIONS]: true
  },
  [GLOBAL_GROUPS_NAME_CELL_ACTIONS.ADD_SUB_GROUP]: {
    [ICON]: 'add-sub-group',
    [LABEL]: t('global_groups.add_sub_group'),
    [ACTION]: 'add-sub-group'
  }
}

const cellActions = computed(() => {
  if (props.hideActions) {
    return []
  }

  const allActions = props.actionsList.map(action => {
    const actionOption = ACTIONS_OPTIONS[action]
    return {
      ...actionOption,
      [LABEL]: props.hideActionsLabel ? null : actionOption.label,
      [TOOLTIP]: props.hideActionsLabel ? actionOption.label : null
    }
  })

  return allActions.filter(action => {
    if (action[CHECK_PERMISSIONS]) {
      return props.skipPermissionsCheck ? true : userHasUpdateAccess.value
    }
    return true
  })
})

const userHasUpdateAccess = computed(() => userCanUpdateGroup(props.group))

const emit = defineEmits({
  'add-sub-group': null,
  'manage-group': null,
  'group-updated': null
})

const onNameClick = () => {
  if (userHasUpdateAccess.value && !props.hideActions) {
    emit('manage-group')
  }
}

const isShowSyncIcon = computed(() => {
  if (props.showSyncIcon) {
    return !isEmpty(props.group[GROUP_ENTITY_KEYS.ASSOCIATED_PLATFORM_GROUPS])
  }

  return false
})

const { ID, NAME, PARENT_ID } = GLOBAL_GROUP_KEYS

const renameLoading = ref(false)

const onUpdateName = async ({ newName, callBack }) => {
  const payload = {
    [ID]: props.group[ID],
    [NAME]: newName,
    [PARENT_ID]: props.group[PARENT_ID]
  }

  const api = new GlobalGroupsApiHandler()
  renameLoading.value = true

  try {
    const group = await api.updateGlobalGroup(payload)
    renameLoading.value = false
    emit('group-updated', { group })

    if (callBack && isFunction(callBack)) {
      callBack()
    }
  } catch (error) {
    handleError({ error })
  } finally {
    renameLoading.value = false
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.ggnc-Cell {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: var(--cell-padding-right, 20px);
  &-filtered-out {
    > * {
      opacity: 0.5;
    }
  }

  &:deep(.ir-Trigger) {
    opacity: 1;

    .iter-Row &,
    .te-Row & {
      display: none;
    }

    .iter-Row:hover &,
    .iter-Row-hovered &,
    .te-Row:hover &,
    .te-Row-hovered & {
      display: flex;
    }
  }

  &:deep(.ir-Trigger-active) {
    opacity: 1;

    .iter-Row &,
    .te-Row & {
      display: flex;
    }
  }
}

.ggnc-Cell_Name {
  color: $dark-1;
  font-size: $fs-14;
  font-weight: fw('semi-bold');
  line-height: 20px;

  &-edit {
    color: $primary-color-next;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.ggnc-Cell_Cipher {
  color: $grey-1-next;
  line-height: 20px;
  font-size: $fs-14;
}

.ggnc-Cell_WarningMessage {
  color: $dark-3;
  font-size: $fs-14;
  line-height: 20px;

  @extend %expandable-table-hidden-items;
}

.ggnc-Cell_SyncIcon {
  flex-shrink: 0;
}

.ggnc-Cell_Actions {
  opacity: 1;
  gap: 12px;

  .iter-Row &,
  .te-Row & {
    display: none;
  }

  .iter-Row:hover &,
  .iter-Row-hovered &,
  .te-Row:hover &,
  .te-Row-hovered & {
    display: flex;
  }
}

.ggnc-ActionButton {
  font-weight: fw('regular');
  color: $primary-color-next;
  gap: 8px;
  padding: 0 8px 0 4px;

  .ggnc-Cell_Actions-no-labels & {
    padding: 0;
  }

  &:not(.ggnc-Cell_Actions-no-labels &) {
    padding: 0;
  }

  &:deep(.svg-icon) {
    border-radius: $border-radius-sm-next;
  }

  &:hover {
    color: $blue-1;
    &:deep(.svg-icon) {
      background-color: $grey-3-next;
    }
  }

  &:active {
    background-color: transparent;
    &:deep(.svg-icon) {
      background-color: darken($grey-3-next, 10%);
    }
  }
}
</style>
