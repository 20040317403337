<template>
  <div @click="$emit('click', $event)">
    <span
      :class="{
        'aa-Avatar': true,
        [`aa-Avatar-${size}`]: true,
        'aa-Avatar-noMargins': noMargins,
        'aa-Avatar-noAvatar': !avatarUrl
      }"
      :style="{
        width: `${size}px`,
        height: `${size}px`
      }"
    >
      <template v-if="!avatarUrl">
        <AppIcon :height="iconSize" :width="iconSize" icon-name="user-next" />
      </template>
      <img v-else :src="avatarUrl" class="aa-ProfilePhoto" />
    </span>

    <span
      v-if="showAvatarReplacer"
      :class="{
        'aa-ReplaceAvatar': true,
        [`aa-ReplaceAvatar-${size}`]: true,
        'aa-ReplaceAvatar-noMargins': noMargins,
        'aa-ReplaceAvatar-noAvatar': !avatarUrl
      }"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
        '--icon-size': `${iconSize}px`
      }"
    >
      <template v-if="avatarUrl">
        {{ $attrs['data-aria-label'] }}
      </template>

      <AppIcon v-else :height="iconSize" :width="iconSize" icon-name="user-next-replace" />
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import {
  AVATAR_SIZES,
  AVATAR_DIMENSIONS,
  AVATAR_OPTIONS,
  AVATAR_USER_PIC_DIMENSIONS
} from '@/utils/components-configurations/app-avatar'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

export default defineComponent({
  name: 'AppAvatar',
  components: { AppIcon },

  props: {
    avatarUrl: {
      type: String,
      default: null
    },

    noMargins: {
      type: Boolean
    },

    size: {
      type: String,
      default: AVATAR_SIZES.MD,
      validator: v => Object.values(AVATAR_SIZES).includes(v)
    },

    showAvatarReplacer: {
      type: Boolean
    }
  },

  emits: { click: null },

  computed: {
    iconSize() {
      return AVATAR_USER_PIC_DIMENSIONS[this.size] || AVATAR_OPTIONS.MD.size
    },

    getCurrentAvatarSize() {
      return AVATAR_DIMENSIONS[this.size]
    },

    getCurrentAvatarSizeInPx() {
      return this.getCurrentAvatarSize + 'px'
    }
  }
})
</script>

<style lang="scss" scoped>
.aa-ProfilePhoto {
  min-width: 100%;
  min-height: 100%;
  object-fit: contain;
}

.aa-Avatar,
.aa-ReplaceAvatar {
  width: v-bind(getCurrentAvatarSizeInPx);
  height: v-bind(getCurrentAvatarSizeInPx);
  border-radius: 50%;
  background-position: center center;
  background-size: 100%;
  position: relative;
  justify-content: center;
  margin-right: 8px;
  overflow: hidden;
  align-items: center;
  color: $white;

  &-noMargins {
    margin-right: 0;
  }

  &-noAvatar {
    background-color: $dark-2;
  }
}

.aa-Avatar {
  display: flex;

  &::before {
    background-color: transparent;
    border-radius: 50%;
    bottom: 0;
    content: ' ';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity $transition-fast;
    transition: opacity $transition-fast;
  }

  &:after {
    content: attr(data-aria-label);
    position: absolute;
    top: calc(100% + 5px);
    background-color: $blue-4;
    border-radius: 3px;
    color: $white;
    left: 0;
    width: auto;
    word-wrap: break-word;
    padding: 2px 6px;
    line-height: 1.3;
    font-size: 0.75rem;
    z-index: 100;
    white-space: nowrap;
    max-width: 240px;
    overflow-wrap: break-word;
    transform: translateY(-4px);
    opacity: 0;
    visibility: hidden;
    transition: all $transition-fast ease;
  }

  &-noAvatar {
    background-color: $dark-2;
  }
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.aa-ReplaceAvatar {
  pointer-events: none;
  display: none;
  background-color: $dark-2;
  &.visible {
    display: block;
  }

  color: $white;
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-10;
  line-height: 23px;
  text-align: center;

  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  &-noAvatar {
    display: none;
    align-items: center;
    justify-content: center;

    &.visible {
      display: flex;
    }
  }
}
</style>
