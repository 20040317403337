<template>
  <IconWrapper>
    <TaskIcon v-if="objective.typeId === OBJECTIVE_TYPES.TASK" />
    <AppIcon
      v-else-if="objective.customIcon"
      :height="absoluteSize"
      :icon-name="objective.customIcon"
      :width="absoluteSize"
    />
    <OkrElementIcon v-else />
    <div
      v-if="objective.private && withLockIcon"
      v-tippy="{
        content: $t('workspaces.private'),
        placement: 'top',
        theme: 'translucent-next'
      }"
      class="oi-LockIcon"
    >
      <AppIcon height="9" icon-name="lock-outline" width="9" />
    </div>
  </IconWrapper>
</template>

<script setup>
import { h, computed } from 'vue'

import { SIZES } from '@/utils/components-configurations/okr-icon'
import { memoizeOkrIconStyles } from '@/utils/memoizations'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'OkrIcon'
})
const props = defineProps({
  objective: {
    type: Object,
    required: true
  },

  size: {
    type: String,
    validator: v => Object.values(SIZES).includes(v),
    default: SIZES.MD
  },

  filteredOut: {
    type: Boolean
  },

  withFill: {
    type: Boolean,
    default: true
  },

  withLockIcon: {
    type: Boolean,
    default: true
  }
})

const styles = computed(() => {
  const { withFill, objective } = props
  return memoizeOkrIconStyles(absoluteSize.value, objective.levelColor, withFill)
})

const absoluteSize = computed(() => {
  const { size } = props
  const { MD, LG, SM, XS, XL } = SIZES
  const sizes = {
    [XL]: 48,
    [LG]: 36,
    [MD]: 24,
    [SM]: 20,
    [XS]: 16
  }
  return sizes[size] || 16
})

const TaskIcon = () => {
  if (props.objective.issueIcon) {
    return h('img', {
      src: props.objective.issueIcon,
      class: 'oi-IconTask',
      'data-issue-type': props.objective.issueType // hardcode solution coz we cant render this icons in roadmap image export because of CORS
    })
  } else {
    return h(AppIcon, {
      iconName: 'jira-objective',
      height: absoluteSize.value,
      width: absoluteSize.value
    })
  }
}

const OkrElementIcon = () => {
  return h(
    'div',
    {
      class: 'oi-ObjectiveIcon',
      style: styles.value
    },
    h('span', props.objective.levelPrefix)
  )
}

const IconWrapper = (_, { slots, attrs }) => {
  return h(
    'div',
    {
      ...attrs,
      class: {
        'oi-OkrIcon': true,
        [`oi-OkrIcon-${props.size}`]: true,
        'oi-OkrIcon-filteredout': props.filteredOut
      }
    },
    h(slots.default)
  )
}
</script>

<style lang="scss" scoped>
/* this eslint plugin won't work with vue 3 render function in <script setup> */
/* eslint-disable vue-scoped-css/no-unused-selector */
.oi-OkrIcon {
  display: flex;
  position: relative;

  &-xs {
    width: 16px;
    height: 16px;
  }

  &-sm {
    width: 20px;
    height: 20px;
  }

  &-lg {
    width: 36px;
    height: 36px;
  }

  &-xl {
    width: 48px;
    height: 48px;
  }

  &-filteredout {
    opacity: 0.5;
  }
}

.oi-IconTask {
  width: 24px;
  height: 24px;

  .oi-OkrIcon-xs & {
    width: 16px;
    height: 16px;
  }

  .oi-OkrIcon-sm & {
    width: 20px;
    height: 20px;
  }

  .oi-OkrIcon-lg & {
    width: 36px;
    height: 36px;
  }
}

.oi-ObjectiveIcon {
  font-family: $system-ui;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-sm-next; //  $border-radius-sm;
  font-size: $fs-12;
  font-weight: fw('bold');
  color: $white;
  .is-safari & {
    white-space: nowrap;
  }

  .oi-OkrIcon-xs & {
    font-size: $fs-7;
    font-weight: fw('regular');
    width: 16px;
    height: 16px;
    border-radius: $border-radius-sm;
  }

  .oi-OkrIcon-sm & {
    font-size: $fs-10;
    font-weight: fw('regular');
    width: 20px;
    height: 20px;
  }

  .oi-OkrIcon-lg & {
    font-size: $fs-20;
    line-height: 1;
    font-weight: fw('regular');
    width: 36px;
    height: 36px;
  }

  .oi-OkrIcon-xl & {
    font-size: $fs-24;
    line-height: 1;
    font-weight: fw('bold');
    width: 48px;
    height: 48px;
    border-radius: $border-radius-md-next;
  }
}

.oi-LockIcon {
  position: absolute;
  left: 0;
  bottom: 0;
  background: $grey-2-next;
  box-shadow: $white 0 0 0 1px;
  display: flex;
  border-radius: 50%;
  z-index: 10;
}

// this styles are used in pdf or roadmap export dont remove it
.oi-ObjectiveIcon.fix-align {
  .oi-OkrIcon-lg & {
    font-size: $fs-18;
    font-weight: fw('semi-bold');
    line-height: 33px;
  }

  .oi-OkrIcon-md & {
    font-weight: fw('semi-bold');
    font-size: $fs-10;
    line-height: 23px;
  }

  .oi-OkrIcon-sm &,
  .oi-OkrIcon-xs & {
    font-size: $fs-7;
    line-height: 19px;
  }
}
</style>
