<template>
  <template v-if="isShowCellByAddFor">
    <template v-if="userHasReadAccess">
      <CellSelectItemsList
        :id="`ms${uniqId}`"
        :active="isOpened"
        :items="selectedItems"
        :plug-icon="PLUG_ICONS.SELECT"
        :user-has-read-access="userHasReadAccess"
        :user-has-update-access="userHasUpdateAccess"
        item-value="name"
        @click="editInitialised = true"
      />

      <template v-if="userHasUpdateAccess">
        <AppSelect
          v-if="editInitialised"
          :model-value="item.customFields[fieldId] || defaultValue"
          :offset="[0, 0]"
          :options="options"
          :search-function="value => localSearch({ value, options })"
          :to-selector="`#ms${uniqId}`"
          append-to=".o-objective-table"
          boundary="scrollParent"
          dropdown-width="220px"
          has-only-this-button
          hide-selected-items-in-dropdown
          item-label="name"
          item-value="id"
          multi
          show-on-init
          show-selected-options-inside
          theme="no-shadow-next light"
          type="default-next"
          @hide="isOpened = false"
          @open="isOpened = true"
          @update:model-value="onUpdateModelValue"
        />
      </template>
    </template>
    <AppCipheredText v-else style="--padding-left: 6px" />
  </template>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, ref } from 'vue'

import { PLUG_ICONS } from '@/utils/cell-helper'
import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'
import { shouldDisplayFieldByOkrElementTypeId } from '@/utils/memoizations'
import { localSearch } from '@/utils/objectives'
import { uid } from '@/utils/uid'

import CellSelectItemsList from '@/components/objectives/table/cells/CellSelectItemsList'
import AppCipheredText from '@/components/ui/AppCipheredText'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'MultiSelect'
})

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  userHasReadAccess: {
    type: Boolean
  },

  userHasUpdateAccess: {
    type: Boolean
  },

  fieldParams: {
    type: Object,
    required: true
  }
})

const uniqId = uid()

const isOpened = ref(false)
const editInitialised = ref(false)

const { options } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const emit = defineEmits({
  'update-field-value': null
})
const onUpdateModelValue = async value => {
  if (props.userHasUpdateAccess) {
    emit('update-field-value', {
      fieldId: props.fieldId,
      value
    })
  }
}

const defaultValue = computed(() => {
  return ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(props.fieldParams.typeId).defaultValue
})

const selectedItems = computed(() => {
  const selectedItems = props.item.customFields[props.fieldId]

  if (!selectedItems || isEmpty(selectedItems)) {
    return defaultValue.value
  }

  return props.item.customFields[props.fieldId].map(id => {
    return options.value.find(option => option.id === id)
  })
})

const isShowCellByAddFor = computed(() => {
  return shouldDisplayFieldByOkrElementTypeId({
    elementTypeIds: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.ELEMENT_TYPE_IDS],
    okrElementTypeId: props.item.typeId,
    okrElementWorkspaceId: props.item.workspaceId,
    workspaceIds: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.WORKSPACE_IDS],
    active: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.ACTIVE]
  })
})
</script>

<style lang="scss" scoped></style>
