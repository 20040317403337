<template>
  <AppDroplist
    v-model="isDropdownVisible"
    :append-to="appendTo"
    :dropdown-min-width="240"
    :offset="[0, 0]"
    boundary="viewport"
    position="bottom-start"
    theme="no-shadow-next light"
    width-as-parent
  >
    <template #button>
      <slot
        :active="isDropdownVisible"
        :model-value="modelValue"
        :option="selectedOption"
        name="button"
      >
        <AppButton data-testid="trigger-button" />
      </slot>
    </template>
    <div class="ofrv-Wrapper">
      <div
        v-for="[group, groupItems] in Object.entries(groups)"
        :key="group"
        class="ofrv-List_Group"
      >
        <div v-for="option in groupItems" :key="option[VALUE]">
          <AppSelectListItem
            :class="{
              'ofrv-ListItem-column-layout': option[LAYOUT_AS_COLUMN],
              'ofrv-ListItem-row-layout': !option[LAYOUT_AS_COLUMN]
            }"
            :disable-toggling="isSelectedOption(option)"
            :model-value="[selectedValue]"
            :val="option[VALUE]"
            class="ofrv-ListItem"
            data-testid="select-list-item"
            multi
            rounded-checkmark
            type="default-next"
            @click="onSelectItem(option)"
          >
            <template #option-label>
              <slot :option="option" name="option-label">
                {{ option[LABEL] }}
              </slot>
            </template>
            <template v-if="option[WITH_INPUT] && selectedValue === option[VALUE]" #after>
              <div class="ofrv-Option_InputWrapper" @click.stop>
                <AppInputNumberNext
                  :key="option[VALUE]"
                  ref="inputRef"
                  :allow-negative="option[ALLOW_NEGATIVE] || false"
                  :debounce-timeout="500"
                  :digit-max-length="option[DIGIT_MAX_LENGTH]"
                  :fraction="option[FRACTION] || 0"
                  :min="option[MIN_INPUT_VALUE] || null"
                  :model-value="getModelValueForInput(option)"
                  class="ofrv-Option_Input"
                  data-testid="trigger-input"
                  @update:model-value="onUpdateInputValue($event, option[MIN_INPUT_VALUE] || null)"
                />
                <span v-if="option[INPUT_AFTER_TEXT]" class="oboard-truncated-text">
                  {{ option[INPUT_AFTER_TEXT] }}
                </span>
              </div>
            </template>
          </AppSelectListItem>
        </div>
      </div>
    </div>
  </AppDroplist>
</template>

<script setup>
import { isNull, isNumber } from 'lodash'
import { computed, nextTick, ref, watch } from 'vue'

import { useGroupOptions } from '@/utils/dropdown-menu'
import { DEFAULT_VALUE_FOR_LAST_COMMENT } from '@/utils/okr-elements/filters'
import { RELATIVE_VALUES_FILTER_KEYS } from '@/utils/okr-elements/relative-values-filter'

import AppDroplist from '@/components/AppDroplist'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInputNumberNext from '@/components/ui/AppInputNumberNext/AppInputNumberNext'
import AppSelectListItem from '@/components/ui/AppSelect/AppSelectListItem'

const isDropdownVisible = ref(false)

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => DEFAULT_VALUE_FOR_LAST_COMMENT
  },

  options: {
    type: Array,
    required: true
  },

  appendTo: {
    type: String,
    default: '.ot-AdditionalFiltersContent_Filters'
  }
})

const {
  LABEL,
  WITH_INPUT,
  VALUE,
  DEFAULT_INPUT_VALUE,
  DIGIT_MAX_LENGTH,
  MIN_INPUT_VALUE,
  INPUT_AFTER_TEXT,
  LAYOUT_AS_COLUMN,
  ALLOW_NEGATIVE,
  FRACTION
} = RELATIVE_VALUES_FILTER_KEYS

watch(
  () => props.modelValue,
  () => {
    nextTick(() => {
      inputRef.value?.[0]?.updateMask()
    })
  },
  { deep: true }
)

const emit = defineEmits(['update:modelValue'])

const groups = computed(() => {
  return useGroupOptions(props.options).groups.value
})

const inputRef = ref(null)

const selectedValue = computed(() => {
  return props.modelValue[0]
})
const valueFromInput = computed(() => {
  return props.modelValue[1]
})

const emitUpdateModelValue = (value, inputValue) => {
  emit('update:modelValue', [value, inputValue])
}
const onSelectItem = ({ value }) => {
  if (value !== selectedValue.value) {
    const previousValue = valueFromInput.value || getOptionByValue(value)[DEFAULT_INPUT_VALUE]
    const getValueFromInput = getOptionByValue(value)[WITH_INPUT] ? previousValue : null

    emitUpdateModelValue(value, getValueFromInput)
  }

  const option = getOptionByValue(value)
  if (option[WITH_INPUT]) {
    setTimeout(() => {
      nextTick(() => {
        inputRef.value?.[0]?.selectAll()
      })
    })
  }
}
const onUpdateInputValue = (value, minValue) => {
  if (isNull(minValue)) {
    emitUpdateModelValue(selectedValue.value, value)
  }
  if (value >= minValue && getOptionByValue(selectedValue.value)[WITH_INPUT]) {
    emitUpdateModelValue(selectedValue.value, value)
  }
}

const selectedOption = computed(() => {
  return getOptionByValue(selectedValue.value)
})

const getOptionByValue = value => {
  return props.options.find(option => option[VALUE] === value) || {}
}

const isSelectedOption = option => {
  return option.value === selectedValue.value
}

const getModelValueForInput = option => {
  const [, value] = props.modelValue
  return isNumber(value) ? value : option[DEFAULT_INPUT_VALUE]
}
</script>

<script>
// eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OkrFilterRelativeValues'
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/dropdown-menu';
.ofrv-Wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: inherit;
}
.ofrv-List_Group {
  .ofrv-Wrapper & {
    @extend %group-divider;
  }
}
.ofrv-ListItem {
  &-column-layout {
    &:deep(.o-checkbox-label) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      width: 100%;
    }
  }
}
.ofrv-Option_InputWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: $fs-14;
  line-height: 20px;
  font-style: normal;
  font-weight: fw('regular');
  --font-size: $fs-14;
  --font-weight: #{fw('regular')};

  .ofrv-ListItem-column-layout & {
    padding: 0 0 0 26px;
  }

  .ofrv-ListItem-row-layout & {
    width: 100%;
  }
}
.ofrv-Option_Input {
  .ofrv-ListItem-column-layout & {
    max-width: 100px;
  }

  .ofrv-ListItem-row-layout & {
    width: 100%;
    margin-left: 12px;

    &:deep(.ain-InputWrapper) {
      width: 100%;
    }
  }
}
</style>
