import { createStore } from 'vuex'

import CustomFieldsModule from '@/store/modules/custom-fields'
import DashboardModule from '@/store/modules/dashboard'
import IntervalsModule from '@/store/modules/intervals'
import ObjectivesModule from '@/store/modules/objectives'
import OkrFiltersModule from '@/store/modules/okr-filters'
import PluginOptionsModule from '@/store/modules/plugin-options'
import SystemModule from '@/store/modules/system'
import WebAppModule from '@/store/modules/web-app'
import WorkspacesModule from '@/store/modules/workspaces'

const modules = {
  objectives: ObjectivesModule,
  workspaces: WorkspacesModule,
  system: SystemModule,
  pluginOptions: PluginOptionsModule,
  okrFilters: OkrFiltersModule,
  intervals: IntervalsModule,
  dashboard: DashboardModule,
  customFields: CustomFieldsModule,
  webApp: WebAppModule
}

export const store = createStore({
  modules
})
