export const ASSIGNEE_ENTITY_KEYS = {
  ID: 'id',
  ACCOUNT_ID: 'accountId',
  AVATAR_URL: 'avatarUrl',
  NAME: 'name',
  DISPLAY_NAME: 'displayName',
  EMAIL: 'email',
  ROLE_ID: 'roleId',
  ADMIN_ROLE_ID: 'adminRoleId'
}

export const OKR_ELEMENT_ENTITY_KEYS = {
  WORKSPACE_ID: 'workspaceId',
  ID: 'id',
  OWNER_ID: 'ownerId',
  STAKEHOLDERS: 'stakeholders',
  GROUPS: 'groups',
  NAME: 'name',
  CONFIDENCE_LEVEL_ID: 'confidenceLevelId',
  TYPE_ID: 'typeId',
  PERMISSIONS: 'permissions',
  CHILD_COUNT: 'childCount'
}

export const GROUP_ENTITY_KEYS = {
  ID: 'id',
  NAME: 'name',
  ICON: 'icon',
  COLOR: 'color',
  PARENT_ID: 'parentId',
  WORKSPACES: 'workspaces',
  USER_COUNT: 'userCount',
  CHILD_COUNT: 'childCount',
  ASSOCIATED_PLATFORM_GROUPS: 'associatedPlatformGroups'
}

export const REQUEST_ENTITY_KEYS = {
  ACCOUNT_IDS: 'accountIds',
  GROUP_IDS: 'groupIds',
  REQUIRED_USERS_ACCOUNT_IDS: 'requiredUserAccountIds',
  REQUIRED_WORKSPACE_IDS: 'requiredWorkspaceIds',
  WORKSPACE_IDS: 'workspaceIds',
  GROUP_ID: 'groupId',
  PARENT_GROUP_ID: 'parentGroupId',
  SEARCH_STRING: 'searchString',
  SEARCH_TYPE_ID: 'searchTypeId',
  LIMIT: 'limit',
  START_AT: 'startAt',
  SYNCED: 'synced',
  ADD_TO_ALL_WORKSPACES: 'addToAllWorkspaces',
  OPERATOR_TYPE_ID: 'operatorTypeId',
  VALUE: 'value',
  FIELD_ID: 'fieldId',
  FIELD_TYPE_ID: 'fieldTypeId',
  IDENTIFIER: 'identifier',
  IDENTIFIER_TYPE_ID: 'identifierTypeId',
  WORKSPACE_ID: 'workspaceId',
  EXPAND_ALL: 'expandAll'
}

export const WORKSPACE_ENTITY_KEYS = {
  ID: 'id',
  NAME: 'name',
  KEY: 'key',
  PUBLIC: 'public',
  TIMEZONE: 'timezone'
}

export const COMPANY_SETTINGS_ENTITY_KEYS = {
  ALLOW_MANAGE_GROUPS_FOR_WS_ADMINS: 'allowManageGroupsForWsAdmins',
  LINK_OBJ_TO_KR: 'linkObjToKR',
  HIDE_DASHBOARD_FROM_USERS: 'hideDashboardFromUsers',
  HIDE_OTHER_USERS_AND_GROUPS_ON_HOMEPAGE: 'hideOtherUsersAndGroupsOnHomepage',
  OBJECTIVE_CONTRIBUTE_BY_DEFAULT: 'objectiveContributeByDefault',
  TASK_CONTRIBUTE_BY_DEFAULT: 'taskContributeByDefault',
  ALLOW_LABEL_CREATION: 'allowLabelCreation',
  USER_ROLE_BY_DEFAULT: 'userRoleByDefault',
  THRESHOLD_BEHIND: 'thresholdBehind',
  THRESHOLD_ON_TRACK: 'thresholdOnTrack',
  TRACING_DISABLED: 'tracingDisabled'
}

const KEY_NAME = 'keyName'
const KEY_ID = 'keyId'

const CUSTOM_FIELD_ENTITY_KEYS_PARAMS = {
  ID: {
    [KEY_NAME]: 'id'
  },
  ICON: {
    [KEY_NAME]: 'icon'
  },
  NAME: {
    [KEY_NAME]: 'name',
    [KEY_ID]: 1
  },
  TYPE: {
    [KEY_NAME]: 'type'
  },
  TYPE_ID: {
    [KEY_NAME]: 'typeId'
  },
  ELEMENT_TYPE_IDS: {
    [KEY_NAME]: 'elementTypeIds'
  },
  WORKSPACES: {
    [KEY_NAME]: 'workspaces'
  },
  WORKSPACE_IDS: {
    [KEY_NAME]: 'workspaceIds'
  },
  ORDER_VALUE: {
    [KEY_NAME]: 'orderValue',
    [KEY_ID]: 2
  },
  ACTIVE: {
    [KEY_NAME]: 'active',
    [KEY_ID]: 3
  },
  REQUIRED: {
    [KEY_NAME]: 'required',
    [KEY_ID]: 4
  },
  FILTERABLE: {
    [KEY_NAME]: 'filterable',
    [KEY_ID]: 5
  },
  SORTABLE: {
    [KEY_NAME]: 'sortable',
    [KEY_ID]: 6
  },
  HELP_TEXT: {
    [KEY_NAME]: 'helpText',
    [KEY_ID]: 9
  },
  PLACEMENT: {
    [KEY_NAME]: 'placement'
  },
  OPTIONS: {
    [KEY_NAME]: 'options'
  },
  CURRENCY: {
    [KEY_NAME]: 'currency'
  },
  ADDITIONAL_PARAMETERS: {
    [KEY_NAME]: 'additionalParameters'
  },
  ADDITIONAL_FIELDS: {
    [KEY_NAME]: 'additionalFields'
  },
  DISPLAY_NAME: {
    [KEY_NAME]: 'displayName'
  },
  DEFAULT_WIDTH: {
    [KEY_NAME]: 'defaultWidth'
  },
  MIN_WIDTH: {
    [KEY_NAME]: 'minWidth'
  },
  HAS_TABLE_COLUMN: {
    [KEY_NAME]: 'hasTableColumn'
  },
  DEFAULT_VALUE: {
    [KEY_NAME]: 'defaultValue'
  }
}
export const CUSTOM_FIELD_ENTITY_KEYS = Object.entries(CUSTOM_FIELD_ENTITY_KEYS_PARAMS).reduce(
  (acc, [key, value]) => {
    return {
      ...acc,
      [key]: value[KEY_NAME]
    }
  },
  {}
)
export const CUSTOM_FIELD_ENTITY_KEYS_IDS = Object.entries(CUSTOM_FIELD_ENTITY_KEYS_PARAMS).reduce(
  (acc, [key, value]) => {
    if (!value[KEY_ID]) {
      return acc
    }
    return {
      ...acc,
      [key]: value[KEY_ID]
    }
  },
  {}
)

export const STATUS_NAMES_ENTITY_KEYS = {
  STATUS_ID: 'statusId',
  DEFAULT_NAME: 'defaultName',
  CUSTOMER_NAME: 'customerName',
  TRANSLATION_KEYS: 'translationKeys',
  KEY: 'key',
  TEMPLATE: 'template',
  I18N_CONTEXT: 'i18nContext',
  DEFAULT_TEMPLATE: 'defaultTemplate'
}

export const WEB_APP_ENTITY_KEYS = {
  ID: 'id',
  PLATFORM_IDS: 'platformIds'
}
