<template>
  <template v-if="isShowCellByAddFor">
    <template v-if="userHasReadAccess">
      <CellSelectItem
        :id="`ss${uniqId}`"
        :active="isOpened"
        :is-empty="isEmptyValue"
        :plug-icon="PLUG_ICONS.SELECT"
        :user-has-read-access="userHasReadAccess"
        :user-has-update-access="userHasUpdateAccess"
        is-transparent
        truncated
        @click="onClick"
      >
        {{ displayValue }}
      </CellSelectItem>

      <template v-if="userHasUpdateAccess">
        <AppSelect
          v-if="editInitialised"
          :inline-loader="false"
          :model-value="item.customFields[fieldId] || defaultValue"
          :offset="[0, 0]"
          :options="options"
          :search-function="value => localSearch({ value, options })"
          :to-selector="`#ss${uniqId}`"
          append-to=".o-objective-table"
          boundary="scrollParent"
          dropdown-search
          dropdown-width="200px"
          item-label="name"
          item-value="id"
          show-on-init
          theme="no-shadow-next light"
          type="default-next"
          @hide="isOpened = false"
          @open="isOpened = true"
          @update:model-value="onUpdateModelValue"
        />
      </template>
    </template>
    <AppCipheredText v-else style="--padding-left: 6px" />
  </template>
</template>

<script setup>
import { isNull, isUndefined } from 'lodash'
import { computed, ref } from 'vue'

import { PLUG_ICONS } from '@/utils/cell-helper'
import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'
import { shouldDisplayFieldByOkrElementTypeId } from '@/utils/memoizations'
import { localSearch } from '@/utils/objectives'
import { uid } from '@/utils/uid'

import CellSelectItem from '@/components/objectives/table/cells/CellSelectItem'
import AppCipheredText from '@/components/ui/AppCipheredText'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'SingleSelect'
})

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  userHasReadAccess: {
    type: Boolean
  },

  userHasUpdateAccess: {
    type: Boolean
  },

  fieldParams: {
    type: Object,
    required: true
  }
})

const uniqId = uid()

const { options } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const editInitialised = ref(false)
const isOpened = ref(false)
const onClick = () => {
  if (props.userHasUpdateAccess) {
    editInitialised.value = true
  }
}

const emit = defineEmits({
  'update-field-value': null
})

const onUpdateModelValue = async value => {
  if (props.userHasUpdateAccess) {
    emit('update-field-value', {
      fieldId: props.fieldId,
      value
    })
  }
}

const defaultValue = computed(() => {
  return ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(props.fieldParams.typeId).defaultValue
})

const isEmptyValue = computed(() => {
  const target = props.item.customFields[props.fieldId]
  return isNull(target) || isUndefined(target)
})

const displayValue = computed(() => {
  if (isEmptyValue.value) {
    return ''
  }

  return options.value.find(option => option.id === props.item.customFields[props.fieldId]).name
})

const isShowCellByAddFor = computed(() => {
  return shouldDisplayFieldByOkrElementTypeId({
    elementTypeIds: props.fieldParams.elementTypeIds,
    okrElementTypeId: props.item.typeId,
    okrElementWorkspaceId: props.item.workspaceId,
    workspaceIds: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.WORKSPACE_IDS],
    active: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.ACTIVE]
  })
})
</script>

<style lang="scss" scoped></style>
