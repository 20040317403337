import { isEmpty } from 'lodash'

import CustomFieldsApiHandler from '@/api/custom-fields'
import {
  DELETE_CUSTOM_FIELD,
  RESET_CUSTOM_FIELDS,
  SET_CUSTOM_FIELDS,
  UPDATE_CUSTOM_FIELD
} from '@/store/mutation-types'
import { ALL_CUSTOM_FIELDS, CUSTOM_FIELDS_PLACEMENTS } from '@/utils/custom-fields/factory'
import { CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'
import { shouldDisplayFieldByOkrElementTypeId } from '@/utils/memoizations'

const state = {
  allFields: []
}

const mutations = {
  [RESET_CUSTOM_FIELDS](state) {
    if (!isEmpty(state.allFields)) {
      state.allFields = []
    }
  },

  [SET_CUSTOM_FIELDS](state, customFields) {
    state.allFields = customFields
  },

  [UPDATE_CUSTOM_FIELD](state, updatedData) {
    const { id, ...rest } = updatedData
    const fieldIndex = state.allFields.findIndex(field => field[CUSTOM_FIELD_ENTITY_KEYS.ID] === id)
    if (fieldIndex !== -1) {
      state.allFields[fieldIndex] = {
        ...state.allFields[fieldIndex],
        ...rest
      }
    }
  },

  [DELETE_CUSTOM_FIELD](state, fieldId) {
    state.allFields = state.allFields.filter(
      field => field[CUSTOM_FIELD_ENTITY_KEYS.ID] !== fieldId
    )
  }
}

const actions = {
  async getAllCustomFields({ commit }) {
    // commit(RESET_CUSTOM_FIELDS)
    const api = new CustomFieldsApiHandler()
    commit(SET_CUSTOM_FIELDS, await api.getCustomFieldsData())
  },

  setCustomFields({ commit }, customFields) {
    commit(SET_CUSTOM_FIELDS, customFields)
  },

  updateCustomField({ commit }, updatedData) {
    commit(UPDATE_CUSTOM_FIELD, updatedData)
  },

  deleteCustomField({ commit }, { id }) {
    commit(DELETE_CUSTOM_FIELD, id)
  }
}

const getters = {
  isCustomField:
    state =>
    ({ fieldId }) => {
      return (
        !isNaN(Number(fieldId)) &&
        state.allFields.some(({ id }) => id === fieldId || id === Number(fieldId))
      )
    },

  fieldsByWorkspaceId: state => workspaceId => {
    if (isNaN(Number(workspaceId))) {
      return []
    }
    return state.allFields.filter(field => {
      return (
        field[CUSTOM_FIELD_ENTITY_KEYS.WORKSPACE_IDS].includes(Number(workspaceId)) &&
        field[CUSTOM_FIELD_ENTITY_KEYS.ACTIVE]
      )
    })
    // console.log('UNCOMMENT ME PLEASE')
    // return state.allFields
  },

  columnsByWorkspaceId: (state, getters) => workspaceId => {
    const allFieldsForWorkspace = getters.fieldsByWorkspaceId(workspaceId)
    return allFieldsForWorkspace.filter(field => {
      const { typeId, active } = field
      const { hasTableColumn } = ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(typeId)
      return hasTableColumn && active
      // console.log('UNCOMMENT ME PLEASE', active)
      // return hasTableColumn
    })
  },

  columnsIdsByWorkspaceId: (state, getters) => workspaceId => {
    const fieldsWithTableColumn = getters.columnsByWorkspaceId(workspaceId)

    return fieldsWithTableColumn.map(field => field[CUSTOM_FIELD_ENTITY_KEYS.ID])
  },

  allColumnsIds: state => {
    const fieldsWithTableColumn = state.allFields.filter(field => {
      const { typeId } = field
      const { hasTableColumn } = ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(typeId)
      return hasTableColumn
    })

    return fieldsWithTableColumn.map(field => field[CUSTOM_FIELD_ENTITY_KEYS.ID])
  },

  fieldsByPlacement:
    (state, getters) =>
    ({
      placement = CUSTOM_FIELDS_PLACEMENTS.CONTENT,
      workspaceId = null,
      okrElementTypeId = null
    } = {}) => {
      if (!workspaceId || !okrElementTypeId) {
        return []
      }

      // console.log('UNCOMMENT ME PLEASE')
      // return state.allFields.filter(field => field.placement === placement)

      const fieldsByWorkspace = getters.fieldsByWorkspaceId(Number(workspaceId))

      const fieldsByOkrType = fieldsByWorkspace.filter(field => {
        return shouldDisplayFieldByOkrElementTypeId({
          okrElementTypeId,
          elementTypeIds: field[CUSTOM_FIELD_ENTITY_KEYS.ELEMENT_TYPE_IDS],
          okrElementWorkspaceId: workspaceId,
          workspaceIds: field[CUSTOM_FIELD_ENTITY_KEYS.WORKSPACE_IDS],
          active: field[CUSTOM_FIELD_ENTITY_KEYS.ACTIVE]
        })
      })

      return fieldsByOkrType.filter(
        field => field[CUSTOM_FIELD_ENTITY_KEYS.PLACEMENT] === placement
      )
    },

  fieldById:
    state =>
    ({ fieldId }) => {
      return state.allFields.find(
        field =>
          field[CUSTOM_FIELD_ENTITY_KEYS.ID] === fieldId ||
          field[CUSTOM_FIELD_ENTITY_KEYS.ID] === Number(fieldId)
      )
    }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
