<template>
  <WebAppUnavailablePageLayout v-if="isOwner">
    <div class="walc-Wrapper">
      <JiraConnectionSettings is-connection-lost>
        <AppTitle :level="3" disable-top-margin>
          {{ t('menu.integrations') }}
        </AppTitle>
      </JiraConnectionSettings>
    </div>
  </WebAppUnavailablePageLayout>
  <LicenseInactiveView v-else />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppTitle from '@/components/ui/AppTitle/AppTitle'
import WebAppUnavailablePageLayout from '@/components/WebAppUnavailablePageLayout'
import LicenseInactiveView from '@/views/LicenseInactiveView'
import JiraConnectionSettings from '@/views/workspaces/settings/integrations/JiraConnectionSettings'

defineOptions({
  name: 'WebAppLostConnection'
})

const { t } = useI18n()

const store = useStore()

const isOwner = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_OWNER])
</script>

<style lang="scss" scoped>
.walc-Wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
