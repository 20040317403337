<template>
  <AppModalWithConfirmation
    :confirm-close="areDataChanged"
    :show="show"
    :title="
      t('global_groups.remove_from_group', { userName: getUserName }, groupsToAdd.groupIds?.length)
    "
    class="rufgm-Modal"
    hide-hero
    @on-close="onClose(false)"
  >
    <div class="rufgm-FieldItem">
      <AppSelect
        v-model="groupsToAdd.groupIds"
        :disabled-items="getUserSyncedGroupIds"
        :is-error="!groupsToAdd.groupValid"
        :loading="isLoadingGroups"
        :offset="[0, -40]"
        :options="userGroups"
        :search-function="value => localSearch({ value, options: userGroups })"
        boundary="scrollParent"
        class="rufgm-Select"
        dropdown-search
        item-label="name"
        item-value="id"
        multi
        show-all-selected
        show-selected-options-inside
        theme="no-shadow-next light"
        type="default-next"
        @open="getGroups"
        @opened="removeError"
      >
        <template #button-content="{ options }">
          <div v-if="isEmpty(options)" class="rufgm-SelectEmptyLabel">
            {{ $t('objective.select_group') }}
          </div>
        </template>
        <template #option="optionProps">
          <AppSelectListItem
            :class="getOptionClasses(optionProps)"
            class="rufgm-SelectOption"
            type="default-next"
            v-bind="optionProps"
          >
            <template v-if="isUserSyncedInGroup(optionProps.option.id)" #checkmark>
              <AppIcon
                v-tippy="{
                  content: $t('user.group.sync.tooltip', { platform: $t('app.platform.jira') }),
                  placement: 'top',
                  theme: 'translucent-next text-center'
                }"
                class="rufgm-SelectOption_SyncIcon"
                height="24"
                icon-name="link-task"
                width="24"
              />
            </template>

            <template #option-label>
              <GlobalGroupsSelectOption v-if="optionProps.option" :group="optionProps.option" />
            </template>
          </AppSelectListItem>
        </template>
      </AppSelect>
      <AppFieldError
        v-if="!groupsToAdd.groupValid"
        :show="!groupsToAdd.groupValid"
        class="rufgm-Error"
      >
        {{ $t('field.required') }}
      </AppFieldError>
      <WorkspacesAffectMessage :limit="2" :workspaces="affectedWorkspaces" class="rufgm-AffectedWs">
        {{ $t('global_groups.will_be_removed_from_ws', affectedWorkspaces.length) }}:
      </WorkspacesAffectMessage>
    </div>

    <template #footer-actions>
      <AppButton type="ghost-next" @click="onClose(false)">
        {{ $t('action.cancel') }}
      </AppButton>
      <AppButton :disable="isLoading" :loading="isLoading" type="primary-next" @click="save">
        {{ $t('action.remove_from_group') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { filter, isEmpty } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { useGlobalGroups } from '@/composables/global-groups'
import {
  MODE_NAMES_FOR_TRACKING,
  TRACKING_UNKNOWN,
  trackRemoveUserEvent
} from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import { localSearch } from '@/utils/objectives'
import { uid } from '@/utils/uid'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import WorkspacesAffectMessage from '@/components/global-groups/WorkspacesAffectMessage'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppSelectListItem from '@/components/ui/AppSelect/AppSelectListItem'

defineOptions({
  name: 'RemoveUserFromGroupModal'
})

const props = defineProps({
  user: {
    type: Object,
    default: () => ({})
  },

  show: {
    type: Boolean
  },

  predefinedGroups: {
    type: Array,
    default: () => []
  },

  trackingSource: {
    type: String,
    default: TRACKING_UNKNOWN
  }
})

const { fetchGroupsForFilter } = useGlobalGroups()

const isLoadingGroups = ref(false)

const areDataChanged = computed(() => {
  return groupsToAdd.value.groupIds?.length > 0
})

const DEFAULT_USER_ITEM = {
  accountId: null,
  accountValid: true
}
const DEFAULT_GROUP_ITEM = {
  groupIds: [],
  groupValid: true
}
const groupsToAdd = ref({
  id: uid(),
  ...DEFAULT_USER_ITEM,
  ...DEFAULT_GROUP_ITEM
})

const emit = defineEmits(['update:show', 'reload-data'])

const onClose = (reloadData = false) => {
  groupsToAdd.value = {
    id: uid(),
    ...DEFAULT_USER_ITEM,
    ...DEFAULT_GROUP_ITEM
  }
  userGroups.value = []
  emit('update:show', false)
  if (reloadData) {
    emit('reload-data')
  }
}

const { t } = useI18n()

const isLoading = ref(false)

const save = async () => {
  trackRemoveUserEvent({
    mode: MODE_NAMES_FOR_TRACKING.GROUP,
    source: props.trackingSource
  })
  groupsToAdd.value.groupValid = !!groupsToAdd.value.groupIds.length

  if (!groupsToAdd.value.groupValid) return

  isLoading.value = true

  const payload = {
    accountId: props.user.accountId,
    groupIds: groupsToAdd.value.groupIds
  }

  try {
    const globalGroupsApi = new GlobalGroupsApiHandler()

    await globalGroupsApi.deleteUserFromGlobalGroup(payload)

    onClose(true)
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const getUserGroupIds = computed(() => {
  return getPredefinedGroups.value.map(group => group.id)
})
const getUserSyncedGroupIds = computed(() => {
  return props.user.groups?.filter(group => group.userSyncedInGroup).map(group => group.id) || []
})

const getPredefinedGroups = computed(() => {
  return isEmpty(props.predefinedGroups) ? props.user?.groups : props.predefinedGroups || []
})
const userGroups = ref([])
const getGroups = async () => {
  if (!isEmpty(userGroups.value)) return
  isLoadingGroups.value = true
  try {
    const data = await fetchGroupsForFilter({ groupIds: getUserGroupIds.value })

    userGroups.value = filter(data, group => getUserGroupIds.value.includes(group.id))
  } catch (error) {
    handleError({ error })
  } finally {
    isLoadingGroups.value = false
  }
}

const removeError = () => {
  groupsToAdd.value.groupValid = true
}

const getOptionClasses = optionProps => {
  return {
    'rufgm-SelectOption-with-sync': isUserSyncedInGroup(optionProps.option.id),
    'rufgm-SelectOption-focused': optionProps.focused
  }
}

const isUserSyncedInGroup = groupId => getUserSyncedGroupIds.value.includes(groupId)

const affectedWorkspaces = computed(() => {
  const workspaces = userGroups.value.find(group =>
    groupsToAdd.value.groupIds?.includes(group.id)
  )?.workspaces

  return workspaces || []
})

const getUserName = computed(() => {
  return props.user.name
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.rufgm-Head {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 0 40px 6px;
}

.rufgm-Head_Cell {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
  &.rufgm-Head_CellWs {
    width: 200px;
  }
}
.rufgm-Fields {
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 0 4px 0;
}
.rufgm-RadioGroup {
  margin: 0 40px 20px;
}
.rufgm-Error {
  min-height: 20px;
  display: flex;
  align-items: center;
  // position: absolute;
  // bottom: -20px;
  // left: 0;
}

.rufgm-SelectOption {
  &-with-sync {
    &:deep(.o-checkbox-label-text) {
      padding-left: 32px;
    }
  }
  &:not(&-with-sync) {
    padding-left: 15px;
    &:deep(.o-checkbox-label-text) {
      padding-left: 27px;
    }
  }

  &-focused#{&}-with-button,
  &-with-default-group {
    :deep(.o-checkbox-label-inner) {
      max-width: calc(100% - 60px);
    }
  }

  :deep(.o-checkbox-disabled) {
    opacity: initial;

    .o-checkbox-label input:checked ~ .checkmark {
      border-color: $grey-1-next;
      background-color: $grey-1-next;
    }
  }
}
.rufgm-SelectOption_SyncIcon {
  position: absolute;
  pointer-events: none;
}
.rufgm-SelectOption_Label {
  overflow: hidden;
  text-overflow: ellipsis;
  &:not(.rufgm-SelectOption-with-sync &) {
    padding-left: 2px;
  }

  .rufgm-SelectOption-with-sync & {
    padding-left: 6px;
  }
}
.rufgm-AffectedWs {
  margin: 10px 0 0 0;
}

.rufgm-SelectEmptyLabel {
  padding-left: 2px;
}

.rufgm-Select {
  :deep(.as-AppDroplistButton_Content) {
    padding: 6px 0 6px 6px;
  }
}
</style>
