<template>
  <div :class="classes" class="ac-Checkbox">
    <label :class="{ 'ac-Checkbox_Label-align-start': alignStart }" class="ac-Checkbox_Label">
      <input
        v-if="multi"
        v-model="checkedItems"
        :disabled="disabled"
        :value="value"
        class="ac-Input"
        hidden
        type="checkbox"
      />
      <input
        v-else
        :checked="isChecked"
        :disabled="disabled"
        :value="value"
        class="ac-Input"
        hidden
        type="checkbox"
        @change="onChange"
        @click="$emit('click', $event)"
      />
      <span v-if="$slots.before" class="ac-Text">
        <slot v-if="$slots.before" name="before" />
      </span>

      <template v-if="round">
        <span v-if="loading" class="ac-Loading">
          <LoadingCircle color="var(--primary-color-next)" size="xsmall" />
        </span>

        <AppIcon
          v-else
          :icon-name="roundChekBoxIconName"
          class="ac-RoundIcon"
          height="24"
          width="24"
        />
      </template>

      <span v-else class="ac-Checkmark" />
      <span v-if="$slots.default" class="ac-Text">
        <slot />
      </span>
    </label>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import AppIcon from '@/components/ui/AppIcon/AppIcon'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'

export default defineComponent({
  name: 'AppCheckbox',
  components: { LoadingCircle, AppIcon },

  props: {
    modelValue: {
      type: [Boolean, Array],
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    value: {
      type: [String, Boolean, Number],
      default: ''
    },

    size: {
      type: String,
      default: 'md',
      validator: v => ['sm', 's', 'md'].includes(v)
    },

    fullWidth: {
      type: Boolean
    },

    fullHeight: {
      type: Boolean
    },

    emitAsValue: {
      type: Boolean
    },

    alignStart: {
      type: Boolean
    },

    round: {
      type: Boolean
    },

    loading: {
      type: Boolean
    }
  },

  emits: { click: null, 'update:modelValue': null },

  computed: {
    classes() {
      return {
        [`ac-Checkbox-${this.size}`]: true,
        'ac-Checkbox-fullWidth': this.fullWidth,
        'ac-Checkbox-fullHeight': this.fullHeight,
        'ac-Checkbox-disabled': this.disabled,
        'ac-Checkbox-round': this.round
      }
    },

    isChecked() {
      return this.emitAsValue ? this.modelValue === this.value : this.modelValue
    },

    roundChekBoxIconName() {
      return this.isChecked ? 'round-checkbox-checked' : 'round-checkbox-empty'
    },

    checkedItems: {
      get() {
        return this.modelValue
      },

      set(val) {
        this.$emit('update:modelValue', val)
      }
    },

    multi() {
      return Array.isArray(this.modelValue)
    }
  },

  methods: {
    onChange(e) {
      const isChecked = e.target.checked
      if (this.emitAsValue) {
        if (isChecked) {
          this.$emit('update:modelValue', this.value)
        } else {
          this.$emit('update:modelValue', null)
        }
      } else {
        this.$emit('update:modelValue', isChecked)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.ac-Checkbox {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100%;

  &-fullHeight {
    height: 100%;
  }

  &-round {
    --fill-opacity: 0;

    &:not(.ac-Checkbox-disabled) {
      &:hover {
        --fill-opacity: 0.15;
      }
    }
  }

  &:not(&-disabled) {
    &:hover {
      .ac-Checkmark {
        border-color: var(--checkmark-color, #{$primary-color-next});
      }

      .ac-RoundIcon {
        color: $primary-color-next;
      }
    }
  }
}

.ac-Loading {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.ac-Checkbox_Label {
  display: flex;
  gap: var(--gap, 8px);
  font-family: $system-ui;
  cursor: pointer;
  justify-content: var(--label-justify-content, initial);

  &:not(&-align-start) {
    align-items: center;
  }

  &-align-start {
    align-items: flex-start;
  }

  .ac-Checkbox-disabled & {
    cursor: not-allowed;
  }

  .ac-Checkbox-fullWidth & {
    width: 100%;
  }

  .ac-Checkbox-fullHeight & {
    height: 100%;
  }
}

.ac-Input {
  &:checked {
    ~ .ac-Checkmark {
      background-color: var(--checkmark-color, #{$primary-color-next});
      border-color: var(--checkmark-color, #{$primary-color-next});
      background-image: url(~@/assets/images/icon-checkbox.svg);
    }

    ~ .ac-RoundIcon {
      color: $dark-2;
    }

    .ac-Checkbox-disabled & {
      ~ .ac-Checkmark {
        background-color: $grey-2-next;
        border-color: $grey-2-next;
      }
    }
  }
}

.ac-RoundIcon {
  transition: $transition-normal;
  color: $grey-1-next;
  .ac-Checkbox-disabled & {
    color: $grey-2-next;
  }
}

.ac-Checkmark {
  box-sizing: border-box;
  border: 2px solid $dark-3;
  background-color: $white;
  border-radius: $border-radius-sm;
  display: block;
  cursor: pointer;
  background-position: center center;
  background-repeat: no-repeat;
  margin: var(--chekmark-margin, 0);

  .ac-Checkbox-md & {
    height: 18px;
    width: 18px;
    flex: 0 0 18px;
    background-size: 12px auto;
  }

  .ac-Checkbox-s & {
    height: 16px;
    width: 16px;
    flex: 0 0 16px;
    background-size: 10px auto;
  }

  .ac-Checkbox-sm & {
    height: 14px;
    width: 14px;
    flex: 0 0 14px;
    background-size: 8px auto;
  }

  .ac-Checkbox-disabled & {
    cursor: not-allowed;
    border-color: $grey-2-next;
  }
}
.ac-Text {
  font-size: var(--font-size, #{$fs-14});
  font-weight: fw('regular');
  line-height: var(--line-height, 1.1);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--label-color, #{$dark-3});

  .ac-Checkbox-fullWidth & {
    width: 100%;
  }

  // .ac-Checkbox_Label-align-start & {
  //   .ac-Checkbox-s & {
  //     margin-top: 1px;
  //   }
  // }
}
</style>
