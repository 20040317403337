<template>
  <div class="aufg-Wrapper">
    <div ref="groupReference" class="aufg-Group">
      <div class="aufg-FieldItem">
        <AppSelect
          ref="userSelectReference"
          :allow-create-on-search="allowAddEmailsFromSelect"
          :disabled-items="disabledItems"
          :entity-for-create="CREATE_ON_SEARCH_ENTITIES.EMAIL"
          :hidden-items="accountsToAdd.map(acc => acc[REQUEST_ENTITY_KEYS.IDENTIFIER])"
          :is-error="!item[USER_IDENTIFIER_VALID_KEY]"
          :model-value="item[REQUEST_ENTITY_KEYS.IDENTIFIER]"
          :offset="[0, -40]"
          :options="allUsers"
          :search-function="getAllUserWorkspaces"
          boundary="scrollParent"
          dropdown-search
          item-label="name"
          item-value="accountId"
          theme="no-shadow-next light"
          type="default-next"
          @opened="removeError"
          @update:model-value="onSelectAccount"
          @update:options="allUsers = $event"
        >
          <template #button-content="{ option }">
            <template v-if="option">
              <OwnerFieldOption :option="option" label-key="name" />
            </template>
            <template v-else>
              {{ $t('admin.user') }}
            </template>
          </template>
          <template #option-label="{ option }">
            <OwnerFieldOption
              :already-added="option.alreadyAdded"
              :option="option"
              label-key="name"
            >
              <template v-if="option.alreadyAdded" #badge>
                {{ $t('badge.already_added') }}
              </template>
            </OwnerFieldOption>
          </template>
        </AppSelect>

        <AppFieldError
          v-if="!item[USER_IDENTIFIER_VALID_KEY]"
          :show="!item[USER_IDENTIFIER_VALID_KEY]"
          class="aufg-Error"
        >
          {{ $t('field.required') }}
        </AppFieldError>
      </div>

      <div ref="rolesSelectField" class="aufg-FieldItem">
        <AppSelect
          ref="rolesSelect"
          :dropdown-search="false"
          :inline-loader="false"
          :model-value="item.roleId"
          :offset="[0, 0]"
          :options="resolvedRoles"
          boundary="scrollParent"
          data-testid="role-select"
          item-label="label"
          item-value="value"
          theme="no-shadow-next light"
          type="default-next"
          @update:model-value="onSelectRole"
        />
      </div>

      <AppButton
        v-if="showClearButton"
        class="aufg-ClearButton"
        icon="close-sm"
        size="sm"
        type="ghost"
        @click="onClearClick"
      />
    </div>
    <AppInfoMessage class="aufg-Message">
      {{ $t('add_users_without_group') }}
    </AppInfoMessage>
  </div>
</template>

<script setup>
import { useResizeObserver, useIntersectionObserver } from '@vueuse/core'
import { cloneDeep } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'

import WorkspaceGroupsApiHandler from '@/api/workspace-groups'
import { useFetchRoles } from '@/composables/plugin-users'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { CREATE_ON_SEARCH_ENTITIES } from '@/utils/select'
import { useEmailsInSelect, USER_IDENTIFIER_VALID_KEY } from '@/utils/web-app/emails-in-select'

import AppFieldError from '@/components/form/AppFieldError'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'AddUserWithGroupFieldGroup'
})

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  showClearButton: {
    type: Boolean,
    default: true
  },

  accountsToAdd: {
    type: Array,
    required: true
  },
  workspaceId: {
    type: Number,
    required: true
  }
})
const { allowAddEmailsFromSelect } = useEmailsInSelect()

const userSelectReference = ref(null)
const rolesSelect = ref(null)

const rolesSelectField = ref(null)
const groupsDropdownYOffset = ref(-40)

useResizeObserver(rolesSelectField, entries => {
  // calculate offset dynamically for dropdown | offset should be negative to show dropdown above the field
  // thats why calculate button height and use -height
  const entry = entries[0]
  const { height } = entry.contentRect
  groupsDropdownYOffset.value = -height
})

const emit = defineEmits(['remove-item', 'update:accounts-to-add'])
const onClearClick = () => {
  emit('remove-item', props.item.uid)
}

onMounted(async () => {
  allUsers.value = await getAllUserWorkspaces()
})
const allUsers = ref([])
const areUsersLoading = ref(false)

const disabledItems = computed(() =>
  allUsers.value.filter(user => user.alreadyAdded).map(acc => acc[REQUEST_ENTITY_KEYS.IDENTIFIER])
)
const getAllUserWorkspaces = async (searchString = null) => {
  const api = new WorkspaceGroupsApiHandler()

  areUsersLoading.value = true

  try {
    let usersList = []

    usersList = await api.getInnerUsers({
      searchString,
      workspaceId: props.workspaceId
    })
    usersList = usersList
      .map(user => {
        return {
          ...user,
          alreadyAdded: user.workspaceIds.includes(props.workspaceId)
        }
      })
      .sort((a, b) => {
        return a.alreadyAdded - b.alreadyAdded
      })

    return usersList
  } catch (error) {
    handleError({ error })
  } finally {
    areUsersLoading.value = false
  }
}
const currentItemIndex = computed(() => {
  return props.accountsToAdd.findIndex(item => item.uid === props.item.uid)
})

const getUpdatedList = newItem => {
  const updatedList = cloneDeep(props.accountsToAdd)
  updatedList.splice(currentItemIndex.value, 1, newItem)
  return updatedList
}

const removeError = () => {
  const newItem = {
    ...props.item,
    [USER_IDENTIFIER_VALID_KEY]: true
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:accounts-to-add', updatedList)
}
const onSelectAccount = identifier => {
  const user = allUsers.value.find(user => user[REQUEST_ENTITY_KEYS.IDENTIFIER] === identifier)
  const newItem = {
    ...props.item,
    ...user,
    identifier
  }

  groups.value = []

  const updatedList = getUpdatedList(newItem)

  emit('update:accounts-to-add', updatedList)
}

const onSelectRole = roleId => {
  const newItem = {
    ...props.item,
    roleId
  }

  const updatedList = getUpdatedList(newItem)
  emit('update:accounts-to-add', updatedList)
}

const groups = ref([])

const groupReference = ref(null)
const groupReferenceIsVisible = ref(false)

useIntersectionObserver(groupReference, ([{ isIntersecting }]) => {
  groupReferenceIsVisible.value = isIntersecting
})

watch(groupReferenceIsVisible, newValue => {
  if (!newValue) {
    userSelectReference.value.hideDropdown()
    rolesSelect.value.hideDropdown()
  }
})

const resolvedRoles = ref([])

onMounted(async () => {
  const data = await useFetchRoles()
  resolvedRoles.value = data.filter(role => role.value)
})
</script>

<style lang="scss" scoped>
$group-gap: 20px;
$items-count: var(--items-count, 2);

.aufg-Group {
  font-family: $system-ui;
  position: relative;
  flex: 1 0 auto;
  display: flex;
  align-items: flex-start;
  gap: $group-gap;
  width: var(--items-width, 100%);
}

.aufg-FieldItem {
  width: calc((100% - (#{$group-gap} * (#{$items-count} - 1))) / #{$items-count});
  position: relative;
  --gap: 0;
  --label-bottom-offset: 6px;
}

.aufg-ClearButton {
  position: absolute;
  right: -24px;
  top: 8px; // 40px - 24px / 2 where 40px is height of field and 24px is height of button
  color: $grey-1-next;
}

.aufg-Error {
  min-height: 20px;
  display: flex;
  align-items: center;
  // position: absolute;
  // bottom: -20px;
  // left: 0;
}

.aufg-Wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
