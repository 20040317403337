<template>
  <ObjectiveMoveModal
    :disable="isDisable"
    :loading="loading"
    :show="show"
    :title="t('objective.select_level')"
    @close="hideModal"
    @proceed="proceed"
    @prev-step="prevStep"
  >
    <div class="sgm-Subtitle">
      <AppIcon height="24" icon-name="warning-icon" width="24" />
      {{ t('move.select_levels_subtitle') }}
    </div>
    <ObjectiveMoveConflictItems
      :conflict-items="conflicts"
      :select-items="levelOptions"
      :selected-items="selectedLevels"
      @update:model-value="updateConflict"
    >
      <template #prepend-input="{ conflict }">
        <OkrIcon :objective="getIconSelectedLevel(conflict.id, currentLevelOptions)" />
      </template>
      <template #prepend-select-icon="{ conflictIndex }">
        <OkrIcon
          :objective="getIconSelectedLevel(selectedLevels[conflictIndex], levelOptions)"
          class="sml-SelectIcon"
        />
      </template>
    </ObjectiveMoveConflictItems>
  </ObjectiveMoveModal>
</template>

<script setup>
import { nextTick, ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import LevelApiHandler from '@/api/level'
import { handleError } from '@/utils/error-handling'
import { levelToOkrIconElement } from '@/utils/objectives'

import OkrIcon from '@/components/objectives/items/OkrIcon'
import ObjectiveMoveConflictItems from '@/components/objectives/objective-move/ObjectiveMoveConflictItems'
import ObjectiveMoveModal from '@/components/objectives/objective-move/ObjectiveMoveModal'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

const props = defineProps({
  show: {
    type: Boolean
  },
  formModel: {
    type: Object,
    required: true
  },
  conflicts: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean
  }
})
const { t } = useI18n()
const emit = defineEmits({
  'update:show': null,
  proceed: null,
  'prev-step': null
})
const hideModal = async () => {
  emit('update:show', false)
  await nextTick()
}
const proceed = () => {
  const conflictLevels = props.conflicts.map((levelItem, levelItemIndex) => {
    return {
      ...levelItem,
      idToChange: selectedLevels.value[levelItemIndex]
    }
  })
  emit('proceed', conflictLevels)
}
const prevStep = () => {
  emit('prev-step')
}

const selectedLevels = ref(props.conflicts.map(conflict => conflict.idToChange))
const levelOptions = ref([])

const updateConflict = ({ index, value }) => {
  selectedLevels.value[index] = value
}

const getIconSelectedLevel = (levelId, options) => {
  const level = options.find(level => level.id === levelId)
  if (!level) return {}
  return levelToOkrIconElement(level)
}
const getLevels = async () => {
  try {
    const levelApi = new LevelApiHandler()
    levelOptions.value = await levelApi.getLevels({
      workspaceIds: [props.formModel.workspaceId],
      levelIds: null
    })
  } catch (error) {
    handleError({ error })
  }
}
onMounted(() => {
  getLevels()
})

const store = useStore()
const currentWorkspaceId = computed(() => store.state.workspaces.workspaceId)

const currentLevelOptions = ref([])
const getCurrentLevels = async () => {
  try {
    const levelApi = new LevelApiHandler()
    currentLevelOptions.value = await levelApi.getLevels({
      workspaceIds: [currentWorkspaceId.value],
      levelIds: null
    })
  } catch (error) {
    handleError({ error })
  }
}

const isDisable = computed(() => {
  return selectedLevels.value.some(level => !level)
})
onMounted(() => {
  getCurrentLevels()
})
</script>
<script>
//eslint-disable-next-line
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SelectMoveLevel'
})
</script>

<style lang="scss" scoped>
.sgm-Subtitle {
  // TODO: refactor after merge with alignment branch
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #42526e;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 35px;
}
</style>
