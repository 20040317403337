<template>
  <AppButton
    v-if="content"
    v-tippy="{
      theme: theme,
      content: resolvedContent,
      placement: placement,
      allowHTML: allowHtml,
      arrow: arrow
    }"
    :height="height"
    :width="width"
    class="ti-ToooltipButtonInfo"
    icon="info-next"
    remove-padding
    size="sm"
    type="none"
  />
</template>

<script>
import { defineComponent } from 'vue'

import { stringOrNullProp } from '@/utils/prop-validators'

import AppButton from '@/components/ui/AppButton/AppButton'

export default defineComponent({
  name: 'TooltipButtonInfo',

  components: {
    AppButton
  },

  props: {
    placement: {
      type: String,
      default: 'bottom'
    },

    content: {
      required: true,
      validator: v => stringOrNullProp(v)
    },

    width: {
      type: [String, Number],
      default: 24
    },

    height: {
      type: [String, Number],
      default: 24
    },

    theme: {
      type: String,
      default: 'translucent-next'
    },

    allowHtml: {
      type: Boolean
    },

    translateContent: {
      type: Boolean,
      default: true
    },

    arrow: {
      type: Boolean
    }
  },

  computed: {
    resolvedContent() {
      return this.translateContent ? this.$t(this.content) : this.content
    }
  }
})
</script>

<style lang="scss" scoped>
.ti-ToooltipButtonInfo {
  color: $dark-2;
}
</style>
