<template>
  <div class="vs-Select">
    <AppSelect
      ref="select"
      :model-value="currentView"
      :offset="[0, 0]"
      :options="views"
      append-to=".ot-Navigation"
      dropdown-width="200px"
      item-label="name"
      item-value="id"
      theme="no-shadow-next light"
      type="default-next"
      @change="onViewChange"
    >
      <template #button="{ option, active }">
        <div v-if="option" :class="{ 'vs-Select_Button-active': active }" class="vs-Select_Button">
          <AppIcon :icon-name="option.icon" class="vs-SelectedIcon" height="24" width="24" />
          <span class="vs-SelectedName">
            {{ option.name }}
          </span>
          <AppIcon
            class="vs-SelectChevron"
            height="24"
            icon-name="arrow-down-black-next"
            width="24"
          />
        </div>
      </template>

      <template #option-label="{ option, selected }">
        <div :class="{ 'vs-Select_Option-selected': selected }" class="vs-Select_Option">
          <AppIcon :icon-name="option.icon" class="vs-OptionIcon" height="24" width="24" />
          <span class="vs-OptionName">
            {{ option.name }}
          </span>
        </div>
      </template>
    </AppSelect>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { ROUTE_PATHS } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_NAMES, EVENT_SECTIONS } from '@/tracking/amplitude-helpers'
import { OKR_VIEW_PAGES_IDS } from '@/utils/objectives'

import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

export default defineComponent({
  name: 'ViewSelect',
  components: { AppIcon, AppSelect },
  computed: {
    currentView() {
      const idByLink = Object.fromEntries(
        Object.entries(this.linksById).map(item => item.reverse())
      )
      return idByLink[this.$route.path] || OKR_VIEW_PAGES_IDS.ALIGNMENT
    },

    linksById() {
      return this.views.reduce((acc, val) => {
        return {
          ...acc,
          [val.id]: val.link
        }
      }, {})
    },

    views() {
      const { workspaceId } = this.$route.params
      const basePath = `${ROUTE_PATHS.WORKSPACES}${workspaceId}/${ROUTE_PATHS.OBJECTIVES}`
      return [
        {
          id: OKR_VIEW_PAGES_IDS.ALIGNMENT,
          name: this.$t('view_select.alignment'),
          icon: 'view-select-alignment',
          link: `${basePath}/${ROUTE_PATHS.ALIGNMENT}/${ROUTE_PATHS.OKR_ELEMENTS_HIERARCHY_TABLE}`
        },
        {
          id: OKR_VIEW_PAGES_IDS.OKREXPLORER,
          name: this.$t('view_select.explorer'),
          icon: 'view-select-explorer',
          link: `${basePath}/${ROUTE_PATHS.OKR_EXPLORER}/${ROUTE_PATHS.OKR_ELEMENTS_EXPLORER}`
        },
        {
          id: OKR_VIEW_PAGES_IDS.MINDMAP,
          name: this.$t('view_select.mindmap'),
          icon: 'view-select-mindmap',
          link: `${basePath}/${ROUTE_PATHS.ALIGNMENT}/${ROUTE_PATHS.OKR_ELEMENTS_MIND_MAP}`
        }
      ]
    }
  },

  methods: {
    onViewChange(viewId) {
      const nextPath = this.linksById[viewId]
      this.$router.push({ path: nextPath, query: this.$route.query })
      tracker.logEvent(EVENT_NAMES.SECTION_OPENED, {
        section: EVENT_SECTIONS.OBJECTIVES,
        view: viewId
      })
    },

    /** @public */
    hideDropdown() {
      this.$refs.select.hideDropdown()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.vs-Select_Button {
  background: $dark-2;
  border-radius: $border-radius-sm-next;
  color: $white;
  padding: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color $transition-normal;

  @include activeState($dark-2, 10%);

  &:not(&-active) {
    @include hoverState($dark-2, 10%);
  }
}

.vs-SelectedIcon {
  flex: 0 0 auto;
  margin-right: 4px;
}

.vs-SelectedName {
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: $system-ui;
}

.vs-SelectChevron {
  flex: 0 0 auto;
  transition: transform $transition-fast ease-in-out;
  .vs-Select_Button-active & {
    transform: rotate(180deg);
  }
}

.vs-Select_Option {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
  font-weight: fw('medium');
  color: $dark-1;
}

.vs-OptionIcon {
  flex: 0 0 auto;
}

.vs-OptionName {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
