<template>
  <div class="aa-AppAuth">
    <slot />
    <AppDialog
      :closable="false"
      :show="showSessionEndModal"
      :title="$t('auth.session_expired.title')"
      hide-footer
      style="--dialog-content-padding-top: 24px; --dialog-content-padding-bottom: 40px"
    >
      {{ $t('auth.session_expired.description') }}
    </AppDialog>
  </div>
</template>

<script>
import axios from 'axios'
import { clone, has, isEmpty, isUndefined } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { getAppType } from '@/util'
import { getValidatedInstanceStateId, INSTANCE_STATES } from '@/utils/instance-states'
import { useReloadPage } from '@/utils/reload-page'
import { checkResponseStatus, RESPONSE_STATUSES } from '@/utils/response-statuses'
import { saveDevOrganizationId } from '@/utils/web-app/organization-helpers'
import { SUBSCRIPTION_PLANS } from '@/utils/web-app/plans-limitations'
import {
  getDevAccessToken,
  getDevRefreshToken,
  saveDevAccessToken,
  saveDevRefreshToken
} from '@/utils/web-app/tokens-helpers'
import { PLUGIN_TYPES } from '@jira/util'
import { IS_DEVELOPMENT } from '@root/app-modes'
import { APP_PLATFORMS } from '@root/app-platforms'
import {
  COMMON_BOOLEAN_KEYS,
  COMMON_NUMBER_KEYS,
  CREATE_ORGANIZATION,
  INSTANCE_STATE_ID,
  IS_OWNER,
  JIRA_CONNECTED,
  PLUGIN_OPTIONS_KEYS,
  SETUP_NAME,
  SUBSCRIPTION_PLAN,
  WEB_APP_CONNECTED
} from '@root/template-options-keys'

import AppDialog from '@/components/AppDialog'

const normalizePluginOptions = pluginOptions => {
  const WEB_APP_BOOLEAN_KEYS = [CREATE_ORGANIZATION, SETUP_NAME]

  const WEB_APP_KEYS_DEFAULT_VALUES = {
    [CREATE_ORGANIZATION]: false,
    [SETUP_NAME]: false
  }

  const CONNECTION_BOOLEAN_KEYS = [JIRA_CONNECTED, WEB_APP_CONNECTED]

  const CONNECTION_KEYS_DEFAULT_VALUES = {
    [JIRA_CONNECTED]: false,
    [WEB_APP_CONNECTED]: false
  }

  const clonedOptions = clone(pluginOptions)

  for (const key in clonedOptions) {
    const val = clonedOptions[key]
    if (WEB_APP_BOOLEAN_KEYS.includes(key)) {
      clonedOptions[key] = isUndefined(val) ? WEB_APP_KEYS_DEFAULT_VALUES[key] : JSON.parse(val)
    }

    if (CONNECTION_BOOLEAN_KEYS.includes(key)) {
      clonedOptions[key] = isUndefined(val) ? CONNECTION_KEYS_DEFAULT_VALUES[key] : JSON.parse(val)
    }

    if (COMMON_BOOLEAN_KEYS.includes(key)) {
      clonedOptions[key] = JSON.parse(val)
    }

    if (COMMON_NUMBER_KEYS.includes(key)) {
      clonedOptions[key] = Number(val)
    }

    if (key === IS_OWNER) {
      clonedOptions[key] = isUndefined(val) ? false : JSON.parse(val)
    }

    if (key === SUBSCRIPTION_PLAN) {
      clonedOptions[key] = Object.values(SUBSCRIPTION_PLANS).includes(val)
        ? val
        : SUBSCRIPTION_PLANS.ESSENTIAL
    }

    if (key === INSTANCE_STATE_ID) {
      clonedOptions[key] = getValidatedInstanceStateId({ instanceStateId: val })
    }
  }

  return clonedOptions
}

export default defineComponent({
  name: 'AppAuth',

  components: {
    AppDialog
  },

  provide() {
    const { appPlatform } = this
    const { isJiraApp, isWebApp } = getAppType({ appPlatform })
    return {
      isJiraApp,
      isWebApp,
      appPlatform
    }
  },

  props: {
    accessToken: {
      type: String,
      default: ''
    },

    refreshToken: {
      type: String,
      default: ''
    },

    pluginType: {
      type: String,
      default: PLUGIN_TYPES.cloud
    },

    pluginOptions: {
      type: Object,
      default: () => ({})
    },

    appPlatform: {
      type: String,
      default: APP_PLATFORMS.JIRA,
      validator: value => {
        const isValidPlatform = Object.values(APP_PLATFORMS).includes(value)
        if (!isValidPlatform) {
          throw new Error(`appPlatform should be one of ${Object.values(APP_PLATFORMS).join(', ')}`)
        }
        return isValidPlatform
      }
    }
  },

  setup() {
    const { reloadPage } = useReloadPage()

    return {
      reloadPage
    }
  },

  data() {
    return {
      showSessionEndModal: false
    }
  },

  computed: {
    instanceStateId() {
      if (!this.$store) {
        return INSTANCE_STATES.ACTIVE.id
      }

      return this.$store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.INSTANCE_STATE_ID]
    },

    isWebAppDev() {
      const { isWebApp } = getAppType({ appPlatform: this.appPlatform })
      return IS_DEVELOPMENT && isWebApp
    },

    resolvedAccessToken() {
      if (this.isWebAppDev) {
        return getDevAccessToken()
      }

      return this.accessToken
    },

    resolvedRefreshToken() {
      if (this.isWebAppDev) {
        return getDevRefreshToken()
      }

      return this.refreshToken
    }
  },

  created() {
    // required this syntax for gadget and other jira instances
    this.$store?.dispatch(
      'pluginOptions/setPluginOptions',
      normalizePluginOptions(this.pluginOptions)
    )

    if (this.pluginType === PLUGIN_TYPES.cloud) {
      const refreshTokenUrl = '/getAccessToken'
      const refreshAccessToken = () => {
        if (this.isWebAppDev) {
          saveDevRefreshToken(this.resolvedRefreshToken)
        }
        return new Promise((resolve, reject) => {
          axios
            .get(refreshTokenUrl, {
              headers: { Authorization: `JWT ${this.resolvedRefreshToken}` }
            })
            .then(response => {
              if (
                isEmpty(response) ||
                isEmpty(response.data) ||
                isUndefined(response.data.accessToken) ||
                !has(response.data, 'accessToken')
              ) {
                console.error('Invalid response from refresh token endpoint')
                tracker.logEvent('view error', {
                  type: 'view error',
                  category: EVENT_CATEGORIES.ERRORS,
                  response: JSON.stringify(response),
                  label: 'undefined jwt token'
                })
              }
              resolve(response.data.accessToken)
            })
            .catch(error => {
              reject(error)
            })
        })
      }

      this.updateAccessToken(this.resolvedAccessToken)

      axios.interceptors.response.use(
        response => response,
        async error => {
          if (error && error.config && error.config.url === refreshTokenUrl) {
            this.onSessionEnd()
            return Promise.reject(error)
          }

          if (checkResponseStatus({ error, status: RESPONSE_STATUSES.UNAUTHORIZED })) {
            const accessToken = await refreshAccessToken()
            this.updateAccessToken(accessToken)
            // eslint-disable-next-line no-param-reassign
            error.config.headers.Authorization = `JWT ${accessToken}`
            return axios.request(error.config)
          }

          if (checkResponseStatus({ error, status: RESPONSE_STATUSES.CONFLICT })) {
            const { data } = error.response
            if (data && String(data.instanceStateId)) {
              const validatedState = getValidatedInstanceStateId({
                instanceStateId: String(data.instanceStateId)
              })

              this.setPluginOptions({
                [PLUGIN_OPTIONS_KEYS.INSTANCE_STATE_ID]: validatedState
              })
            }
          }

          return Promise.reject(error)
        }
      )
    }
  },

  methods: {
    ...mapActions('pluginOptions', {
      setPluginOptions: 'setPluginOptions'
    }),

    updateAccessToken(token) {
      if (this.isWebAppDev) {
        saveDevAccessToken(token)
      }
      axios.defaults.headers.Authorization = `JWT ${token}`
    },

    onSessionEnd() {
      if (this.isWebAppDev) {
        saveDevAccessToken('', true)
        saveDevRefreshToken('', true)
        saveDevOrganizationId('', true)
      }

      if (
        [
          INSTANCE_STATES.CONNECTION_IN_PROGRESS.id,
          INSTANCE_STATES.DISCONNECTION_IN_PROGRESS.id,
          INSTANCE_STATES.ACTIVE.id
        ].includes(this.instanceStateId)
      ) {
        const { isWebApp } = getAppType({ appPlatform: this.appPlatform })
        this.reloadPage({ isWebApp })
      } else {
        this.showSessionEndModal = true
      }
    }
  }
})
</script>

<style lang="scss" scoped></style>
