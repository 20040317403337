import { has, isObject } from 'lodash'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { NOTIFICATION_DURATIONS, NOTIFICATION_TYPES, showNotify } from '@/utils/notify'
import { isSomeOfResponseStatuses, RESPONSE_STATUSES } from '@/utils/response-statuses'

const { UNAUTHORIZED, CONFLICT, BAD_REQUEST, FORBIDDEN, NOT_FOUND, INTERNAL_SERVER_ERROR } =
  RESPONSE_STATUSES

// eslint-disable-next-line import/prefer-default-export
export const handleError = ({ error, message = null, title = undefined, isHtml = false } = {}) => {
  window.console.error(error)
  const trackerEventParameters = {
    category: EVENT_CATEGORIES.ERRORS,
    type: 'frontend'
  }

  let messageToShow = message || error.message || 'Something went wrong'
  if (error.isAxiosError) {
    trackerEventParameters.type = 'api'
    // use url from request, because if there is no response from server(e.g. timeout)
    // `error.response` is unavailable
    trackerEventParameters.requestUrl = error.request?.responseURL
    trackerEventParameters.response = error.response?.status

    const statusCodesCondition = isSomeOfResponseStatuses({
      error,
      statuses: [BAD_REQUEST, FORBIDDEN, INTERNAL_SERVER_ERROR, NOT_FOUND]
    })

    if (statusCodesCondition) {
      if (isObject(error.response.data) && has(error.response.data, 'message')) {
        messageToShow = error.response.data.message
      } else {
        const { response } = error
        const { config } = response
        messageToShow =
          response.data ||
          `Request failed with status code ${response.status}
          [${config.method.toUpperCase()}::${config.url}]`
      }
      trackerEventParameters.responseMessage = messageToShow
    }
  } else {
    trackerEventParameters.errorMessage = error.message
  }

  trackerEventParameters.label = messageToShow
  tracker.logEvent('seen error', trackerEventParameters)

  const isStatusCodeWithoutNotifyCondition = isSomeOfResponseStatuses({
    error,
    statuses: [UNAUTHORIZED, CONFLICT]
  })

  if (!(error.isAxiosError && isStatusCodeWithoutNotifyCondition)) {
    showNotify({
      type: NOTIFICATION_TYPES.ERROR,
      duration: NOTIFICATION_DURATIONS.LONG,
      content: messageToShow,
      title,
      isHtml,
      withButtonClose: true
    })
  }
}
