<template>
  <div class="om-ObjectiveModalWrapper">
    <Modal
      v-if="showModal"
      ref="modal"
      :class="{
        'om-ObjectiveModal-childModalOpened': childModalOpened
      }"
      :data-auto-testid="getAutoTestId"
      :disable-header-bottom-padding="modalView === VIEWS.CUSTOM_WEIGHTS"
      :hide-close="VIEWS_WITH_BREADCRUMB.includes(modalView)"
      :hide-header="VIEWS_WITH_BREADCRUMB.includes(modalView)"
      :scrollable-content="[VIEWS.KR, VIEWS.OBJECTIVE].includes(modalView)"
      :show="showModal"
      :style="
        getBreadcrumbsDepth(
          breadcrumbs,
          [VIEWS.CUSTOM_WEIGHTS, VIEWS.DUPLICATE_OBJECTIVE].includes(modalView)
        )
      "
      :title="modalTitle"
      class="om-ObjectiveModal"
      manual-close
      size="lg-next"
      @close="onCreateObjectiveModalClose"
      @update:show="!$event ? $emit('closed') : undefined"
    >
      <template
        v-if="modalView === VIEWS.CUSTOM_WEIGHTS || modalView === VIEWS.DUPLICATE_OBJECTIVE"
        #header
      >
        <OkrIconAndId
          v-if="modalView === VIEWS.CUSTOM_WEIGHTS"
          :okr-element="formObjective"
          style="--id-font-weight: 600"
        />

        <TitleWithAction v-else>
          <template #prepend> {{ $t('objective.copy') }}</template>
          {{ modalTitle }}
        </TitleWithAction>
      </template>

      <template v-if="VIEWS_WITH_BREADCRUMB.includes(modalView)" #top-part>
        <OkrBreadcrumbs
          :is-edit="isEdit"
          :items="breadcrumbs"
          :modal-view="modalView"
          @close="onCreateObjectiveModalClose"
          @item-click="onBreadcrumbItemClick"
          @update-privacy="onUpdatePrivacy"
        >
          <template #current-item-append>
            <SavingIndicator ref="okrElementSavingIndicator" data-auto-testid="saving-indicator" />
          </template>

          <template #breadcrumbs-actions>
            <PrivacyCell
              v-if="isEdit"
              :item="formObjective"
              :tracking-source="EVENT_SOURCES.FORM"
              @update-privacy="onUpdatePrivacy"
            />
            <PrivacyAnimatedIcons
              v-else
              v-tippy="{
                content: getPrivacyTooltip,
                placement: 'top',
                theme: 'word-break translucent-next white-space-pre-line text-center'
              }"
              :is-animated="isAnimated"
              :item="formObjective"
              can-update-objective-privacy
              @update-privacy="onCreatePrivacyUpdate"
            />
            <template v-if="VIEWS_WITH_BREADCRUMB.includes(modalView)">
              <DropdownMenu
                v-if="isUserCanDelete || isUserCanUpdate || isUserCanCopy"
                :items="getModalActionsMenuItems(formObjective)"
                :offset="[0, 0]"
                data-auto-testid="breadcrumbs-actions-menu"
                position="bottom-end"
                type="default-next"
                @item-click="onMenuActionsClick"
              >
                <template #activator>
                  <AppButton
                    data-auto-testid="breadcrumbs-actions-menu-trigger"
                    icon="more-next"
                    size="sm"
                    type="subtle"
                  />
                </template>
              </DropdownMenu>

              <ObjectiveModalActions
                ref="actions"
                @deleted="onCreateObjectiveModalClose"
                @updated="updateFormObjectiveAfterMove"
              />
            </template>
          </template>
        </OkrBreadcrumbs>
      </template>

      <template #loader>
        <SavingIndicator
          ref="okrElementSavingIndicatorTop"
          :type="SAVING_INDICATOR_TYPES.LINE"
          data-auto-testid="saving-indicator-top"
        />
      </template>

      <template v-if="modalView === VIEWS.OBJECTIVE">
        <ObjectiveForm
          ref="objectiveForm"
          :allow-create-elements="userCanCreateObjectives"
          :children-order="childrenOrder"
          :model-value="formObjective"
          :okr-payload="okrPayload"
          :parent-objectives="parentObjectives"
          :source="source"
          @close="checkConfirmAndCloseModal"
          @update="updateFormObjective"
          @open-custom-weights="openChildForm(VIEWS.CUSTOM_WEIGHTS, $event)"
          @on-created="onElementCreated(VIEWS.OBJECTIVE)"
          @link-jira-issue="openChildForm(VIEWS.LINK_JIRA_ISSUE, $event)"
          @open-kr="openChildForm(VIEWS.KR, $event)"
          @open-child-objective="openChildForm(VIEWS.OBJECTIVE, $event)"
          @set-child-modal-opened="onChildObjectiveModalOpened"
          @edit-element="editElement"
          @saving-started="onOkrElementSavingStarted"
          @saving-ended="onOkrElementSavingEnded"
          @level-changed="onObjectiveFormLevelChanged"
          @update:model-value="formObjective = $event"
          @update:parent-objectives="parentObjectives = $event"
        />
      </template>
      <template v-else-if="modalView === VIEWS.CUSTOM_WEIGHTS">
        <ObjectiveWeightsForm
          ref="childCustomWeightsForm"
          :model-value="formObjective"
          @close="onFormClose"
          @updated-weights="onCustomWeightsUpdate"
        />
      </template>
      <template v-else-if="modalView === VIEWS.KR">
        <KeyResultForm
          ref="childKrForm"
          :model-value="formObjective"
          :navigation-tab="getNavigationTabName($route)"
          :okr-payload="okrPayload"
          :source="source"
          @close="onFormClose"
          @update="updateFormObjective"
          @open-custom-weights="openChildForm(VIEWS.CUSTOM_WEIGHTS, $event)"
          @on-created="onElementCreated(VIEWS.KR)"
          @open-kr="openChildForm(VIEWS.KR, $event)"
          @open-child-objective="openChildForm(VIEWS.OBJECTIVE, $event)"
          @saving-started="onOkrElementSavingStarted"
          @saving-ended="onOkrElementSavingEnded"
          @set-child-modal-opened="onChildObjectiveModalOpened"
          @update:model-value="formObjective = $event"
          @link-jira-issue="openChildForm(VIEWS.LINK_JIRA_ISSUE, $event)"
          @edit-element="editElement"
          @on-parent-objective-id-click="onBreadcrumbItemClick"
        />
      </template>
      <template v-else-if="modalView === VIEWS.LINK_JIRA_ISSUE">
        <LinkJiraIssueForm
          ref="childJiraIssueForm"
          :model-value="formObjective"
          :source="source"
          @close="onFormClose"
          @on-created="onElementCreated(VIEWS.LINK_JIRA_ISSUE)"
          @saving-started="onOkrElementSavingStarted"
          @saving-ended="onOkrElementSavingEnded"
          @on-parent-objective-id-click="onBreadcrumbItemClick"
          @update:model-value="formObjective = $event"
        />
      </template>
      <template v-else-if="modalView === VIEWS.DUPLICATE_OBJECTIVE">
        <DuplicateObjectiveForm
          ref="childDuplicateForm"
          :model-value="formObjective"
          @close="onFormClose"
          @duplicated="$emit('duplicated-objective', $event)"
        />
      </template>
    </Modal>

    <ObjectiveModal
      v-if="showChildObjectiveModal"
      ref="objectiveModal"
      :children-order="childrenOrder"
      :depth="depth + 1"
      :workspace-id="workspaceId"
      source="form"
      @close="onChildObjectiveModalClose"
      @closed="onChildObjectiveModalClosed"
      @opened="onChildObjectiveModalOpened(true)"
      @element-created="onElementCreated"
      @breadcrumb-item-click="onBreadcrumbItemClick"
      @open-child="data => openChildForm(data.childView, data.data)"
    />

    <!-- Confirm close -->
    <portal to="modal-windows">
      <AppDialog
        :show="isConfirmCloseShow"
        :title="$t('confirm_modal.title')"
        :type="DIALOG_TYPES.WARNING"
        @on-close="hideConfirmClose"
        @on-confirm="onConfirm"
      >
        {{ $t('confirm_modal.description') }}

        <template #confirm-btn-text>
          {{ $t('confirm.discard_btn') }}
        </template>
      </AppDialog>
    </portal>
    <!-- Confirm close end -->
  </div>
</template>

<script>
import { cloneDeep, has, isEmpty, isNull } from 'lodash'
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  EVENT_SOURCES,
  MODE_NAMES_FOR_TRACKING
} from '@/tracking/amplitude-helpers'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { SAVING_INDICATOR_TYPES } from '@/utils/components-configurations/saving-indicator'
import { handleError } from '@/utils/error-handling'
import { isEscape } from '@/utils/key-codes'
import { pushKeydownEventListener, removeKeydownEventListener } from '@/utils/keyevents'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import {
  OBJECTIVE_SORT_OPTIONS,
  currentUserCanCreateObjective,
  OKR_FORM_VIEWS,
  OKR_TYPE_TO_FORM_VIEW,
  objectiveIsJiraTask,
  isKR,
  currentUserCanUpdateObjective,
  currentUserCanDeleteObjective,
  currentUserCanCopyObjective,
  OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS,
  MENU_ITEMS_GROUPS,
  isOkrElementClosed
} from '@/utils/objectives'
import { getBreadcrumbsDepth } from '@/utils/okr-breadcrumbs'
import { EDIT_ELEMENT_QUERY_KEYS } from '@/utils/query-parameters'
import { updateQueryParameter, deleteQueryParameter, replaceQueryParameters } from '@/utils/router'
import { getNavigationTabName } from '@/utils/tracking'

import AppDialog from '@/components/AppDialog'
import OkrIconAndId from '@/components/form/OkrIconAndId'
import DuplicateObjectiveForm from '@/components/objectives/forms/DuplicateObjective'
import KeyResultForm from '@/components/objectives/forms/KeyResult'
import ObjectiveForm from '@/components/objectives/forms/Objective'
import ObjectiveWeightsForm from '@/components/objectives/forms/ObjectiveWeights'
import ObjectiveModalActions from '@/components/objectives/ObjectiveModalActions'
import OkrBreadcrumbs from '@/components/objectives/OkrBreadcrumbs'
import PrivacyCell from '@/components/objectives/table/cells/PrivacyCell'
import TitleWithAction from '@/components/objectives/TitleWithAction'
import SavingIndicator from '@/components/SavingIndicator'
import AppButton from '@/components/ui/AppButton/AppButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import Modal from '@/components/ui/Modal/Modal'
import PrivacyAnimatedIcons from '@/components/ui/PrivacyAnimatedIcons'
import LinkJiraIssueForm from '@jira/components/objectives/forms/LinkJiraIssue'

const VIEWS_WITH_BREADCRUMB = [
  OKR_FORM_VIEWS.OBJECTIVE,
  OKR_FORM_VIEWS.KR,
  OKR_FORM_VIEWS.LINK_JIRA_ISSUE
]

const { REMOVE, COPY, MOVE } = OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS

export default defineComponent({
  name: 'ObjectiveModal',

  components: {
    PrivacyAnimatedIcons,
    PrivacyCell,
    AppDialog,
    TitleWithAction,
    OkrBreadcrumbs,
    OkrIconAndId,
    Modal,
    ObjectiveForm,
    ObjectiveWeightsForm,
    KeyResultForm,
    LinkJiraIssueForm,
    DuplicateObjectiveForm,
    SavingIndicator,
    ObjectiveModal: defineAsyncComponent(() => import('@/components/objectives/ObjectiveModal')),
    DropdownMenu,
    AppButton,
    ObjectiveModalActions
  },

  props: {
    source: {
      type: String,
      default: ''
    },

    childrenOrder: {
      type: Array,
      default: () => [OBJECTIVE_SORT_OPTIONS.ORDER_ASC]
    },

    depth: {
      type: Number,
      default: 0
    },

    workspaceId: {
      type: [String, Number],
      default: null
    }
  },

  emits: {
    closed: null,
    'duplicated-objective': null,
    close: null,
    'open-child': null,
    'breadcrumb-item-click': null,
    'updated-weights': null,
    'element-created': null,
    opened: null
  },

  data() {
    return {
      parentObjectives: [],

      breadcrumbs: [],
      modalView: null,
      isConfirmCloseShow: false,
      showModal: false,
      closeOkrElementOnSavingEnd: false,
      okrElementIsSaving: false,

      formObjective: null,
      showChildObjectiveModal: false,
      childModalOpened: false,
      okrPayload: null,
      isAnimated: false
    }
  },

  computed: {
    EVENT_SOURCES: () => EVENT_SOURCES,

    VIEWS: () => OKR_FORM_VIEWS,
    SAVING_INDICATOR_TYPES: () => SAVING_INDICATOR_TYPES,
    DIALOG_TYPES: () => DIALOG_TYPES,

    ...mapState('objectives', {
      levels: state => state.levels
    }),

    ...mapGetters('objectives', {
      getLevelItem: 'getLevelItem'
    }),

    ...mapGetters('workspaces', {
      selectedWorkspace: 'selectedWorkspace'
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin
    }),

    getAutoTestId() {
      const { modalView } = this
      if (isNull(modalView)) {
        return null
      }

      const { OBJECTIVE, DUPLICATE_OBJECTIVE, CUSTOM_WEIGHTS, LINK_JIRA_ISSUE, KR } = OKR_FORM_VIEWS

      const [testId = ''] = [
        modalView === OBJECTIVE && 'objective',
        modalView === DUPLICATE_OBJECTIVE && 'duplicate',
        modalView === LINK_JIRA_ISSUE && 'jira-issue',
        modalView === KR && 'kr',
        modalView === CUSTOM_WEIGHTS && 'custom-weights'
      ].filter(Boolean)

      return `${testId}-modal`
    },

    getPrivacyTooltip() {
      return this.formObjective.private
        ? this.$t('objective.make_okr_public')
        : this.$t('objective.make_okr_private')
    },

    isUserCanUpdate() {
      return this.formObjective.permissions && currentUserCanUpdateObjective(this.formObjective)
    },

    isUserCanDelete() {
      return this.formObjective.permissions && currentUserCanDeleteObjective(this.formObjective)
    },

    isUserCanCopy() {
      return this.formObjective.permissions && currentUserCanCopyObjective(this.formObjective)
    },

    isChild() {
      return this.depth > 0
    },

    isEdit() {
      return this.formObjective && !!(this.formObjective.objectiveId || this.formObjective.id)
    },

    modalTitle() {
      const { isEdit, formObjective, modalView } = this
      const { DUPLICATE_OBJECTIVE, LINK_JIRA_ISSUE, KR, OBJECTIVE } = OKR_FORM_VIEWS
      const objectiveName = formObjective ? formObjective.name : ''
      const [title = ''] = [
        modalView === OBJECTIVE && !isEdit && this.$t('create.objective.title'),
        modalView === DUPLICATE_OBJECTIVE && objectiveName,
        modalView === LINK_JIRA_ISSUE && this.$t('action.link_jira_issue'),
        modalView === KR && !isEdit && this.$t('action.create_keyResult')
      ].filter(Boolean)

      return title
    },

    // modalSize() {
    //   return [OKR_FORM_VIEWS.OBJECTIVE, OKR_FORM_VIEWS.KR].includes(this.modalView) ? 'lg' : 'md'
    // },

    userCanCreateObjectives() {
      return currentUserCanCreateObjective(this.selectedWorkspace, this.isPluginAdmin)
    },

    VIEWS_WITH_BREADCRUMB: () => VIEWS_WITH_BREADCRUMB
  },

  watch: {
    async formObjective(newValue, oldValue) {
      if (!(newValue && newValue.id)) {
        return
      }

      const anotherObjective = oldValue?.id !== newValue.id && newValue.id
      const { EDIT_KR, EDIT_OBJECTIVE, EDIT_LINK_JIRA_ISSUE } = EDIT_ELEMENT_QUERY_KEYS

      const isObjective = newValue.typeId === OBJECTIVE_TYPES.PERSONAL
      const isKeyResult = isKR(newValue)
      const isLinkJiraIssue = objectiveIsJiraTask(newValue)

      if (anotherObjective) {
        const { $router, $route } = this
        let newQuery = cloneDeep($route.query)

        if (isObjective) {
          if (EDIT_KR in $route.query) {
            delete newQuery[EDIT_KR]
            replaceQueryParameters($router, $route, {
              ...newQuery,
              [EDIT_OBJECTIVE]: newValue.id
            })
          } else {
            updateQueryParameter($router, $route, EDIT_OBJECTIVE, newValue.id)
          }
        }

        if (isKeyResult) {
          if (EDIT_OBJECTIVE in $route.query) {
            delete newQuery[EDIT_OBJECTIVE]
            replaceQueryParameters($router, $route, {
              ...newQuery,
              [EDIT_KR]: newValue.id
            })
          } else {
            updateQueryParameter($router, $route, EDIT_KR, newValue.id)
          }
        }

        if (isLinkJiraIssue) {
          updateQueryParameter($router, $route, EDIT_LINK_JIRA_ISSUE, newValue.id)
        }
      }

      const parentObjectiveChanged = oldValue && oldValue.parentId !== newValue.parentId
      if (parentObjectiveChanged) {
        this.breadcrumbs = await this.getBreadcrumbs(
          this.formObjective.id,
          this.formObjective.workspaceId
        )
      }
    }
  },

  mounted() {
    this.isParentObjectivesLoading = true
  },

  created() {
    pushKeydownEventListener('esc', this.onKeyUp)
  },

  beforeUnmount() {
    removeKeydownEventListener('esc', this.onKeyUp)
  },

  methods: {
    ...mapActions('workspaces', {
      setWorkspaceId: 'setWorkspaceId'
    }),

    getNavigationTabName,
    getBreadcrumbsDepth,

    setAnimatedItem() {
      this.isAnimated = true

      setTimeout(() => {
        this.isAnimated = false
      }, 1500)
    },

    async onCreatePrivacyUpdate(value) {
      this.setAnimatedItem()
      this.formObjective.private = value
    },

    async onUpdatePrivacy({ element }) {
      this.formObjective = { ...this.formObjective, ...element }
      this.breadcrumbs = await this.getBreadcrumbs(
        this.formObjective.id,
        this.formObjective.workspaceId
      )
    },

    getModalActionsMenuItems(objective) {
      const result = []

      if (currentUserCanCopyObjective(objective) && this.modalView !== this.VIEWS.LINK_JIRA_ISSUE) {
        result.push({
          name: COPY.name,
          title: 'dropdown.copy',
          icon: 'duplicate-next',
          group: MENU_ITEMS_GROUPS.EDITING
        })
      }

      if (currentUserCanUpdateObjective(objective) && this.modalView === this.VIEWS.OBJECTIVE) {
        result.push({
          name: MOVE.name,
          title: 'action.move',
          icon: 'move-next',
          group: MENU_ITEMS_GROUPS.EDITING,
          disabled: isOkrElementClosed(objective)
        })
      }

      if (currentUserCanDeleteObjective(objective)) {
        const isJiraIssue = objectiveIsJiraTask(objective)
        result.push({
          name: REMOVE.name,
          title: isJiraIssue ? 'action.unlink_issue' : 'action.delete',
          icon: isJiraIssue ? 'unlink-next' : 'delete-next',
          group: MENU_ITEMS_GROUPS.REMOVING,
          color: 'var(--grade-low-color-next)'
        })
      }

      return result
    },

    onMenuActionsClick(name) {
      if (name === REMOVE.name) {
        this.$refs.actions.deleteOkrElement(this.formObjective)
      } else if (name === COPY.name) {
        const { typeId, levelId, intervalId, parentId, contribute, objectiveId, id, name } =
          this.formObjective
        const isCanCopy = currentUserCanCopyObjective(this.formObjective)
        this.openChildForm(this.VIEWS.DUPLICATE_OBJECTIVE, {
          typeId,
          levelId,
          intervalId,
          parentId,
          contribute,
          objectiveId: objectiveId || id,
          name,
          isCanCopy
        })
      } else if (name === MOVE.name) {
        this.$refs.actions.moveOkrElement(this.formObjective)
      }
    },

    onCreateObjectiveModalClose() {
      if (this.okrElementIsSaving) {
        this.closeOkrElementOnSavingEnd = true
      } else {
        this.checkConfirmAndCloseModal()
      }
    },

    onElementCreated(elementType = OKR_FORM_VIEWS.OBJECTIVE) {
      this.$emit('element-created', elementType)
    },

    // eventData = { checkDataChange = false, ...params }
    checkConfirmAndCloseModal(eventData = {}) {
      if (this.showModal) {
        const { CUSTOM_WEIGHTS, KR, OBJECTIVE, LINK_JIRA_ISSUE, DUPLICATE_OBJECTIVE } =
          OKR_FORM_VIEWS
        const { modalView } = this
        const references = {
          [CUSTOM_WEIGHTS]: this.$refs.childCustomWeightsForm,
          [OBJECTIVE]: this.$refs.objectiveForm,
          [KR]: this.$refs.childKrForm,
          [LINK_JIRA_ISSUE]: this.$refs.childJiraIssueForm,
          [DUPLICATE_OBJECTIVE]: this.$refs.childDuplicateForm
        }
        if (eventData.checkDataChange !== false && references[modalView].areDataChanged) {
          this.isConfirmCloseShow = true
        } else {
          this.$refs.modal.close()

          this.$emit('close', { ...eventData, isChildModal: this.isChild })
          const { EDIT_LINK_JIRA_ISSUE } = EDIT_ELEMENT_QUERY_KEYS
          if (!this.isChild && this.$route.query) {
            Object.values(EDIT_ELEMENT_QUERY_KEYS).forEach(async key => {
              await deleteQueryParameter(this.$router, this.$route, key)
            })
          }

          if (this.isChild && EDIT_LINK_JIRA_ISSUE in this.$route.query) {
            deleteQueryParameter(this.$router, this.$route, EDIT_LINK_JIRA_ISSUE)
          }
        }
      }
    },

    async getBreadcrumbs(objectiveId, workspaceId) {
      const api = new ObjectivesInfoApiHandler()

      try {
        return await api.getBreadcrumbs({
          objectiveId,
          workspaceId
        })
      } catch (error) {
        handleError({ error })
      }
      return []
    },

    async updateFormObjectiveAfterMove({ workspaceId }) {
      const { id, typeId } = this.formObjective
      this.setWorkspaceId(workspaceId)
      this.formObjective = await this.getElement({ id, typeId, workspaceId })
    },

    async updateFormObjective() {
      const { id, typeId, workspaceId } = this.formObjective
      this.formObjective = await this.getElement({ id, typeId, workspaceId })
    },

    async getElement({ id, workspaceId }) {
      const api = new ObjectivesInfoApiHandler()

      try {
        const element = await api.getObjectiveById({
          elementId: id,
          workspaceId
        })
        if (
          element.typeId === OBJECTIVE_TYPES.PERSONAL &&
          this.levels.findIndex(level => level.id === element.levelId) === -1
        ) {
          handleError({
            error: new Error(),
            message: this.$t('objective.level_error'),
            isHtml: true
          })
        }
        return element
      } catch (error) {
        handleError({ error })
      }
      return null
    },

    onChildObjectiveModalClose() {
      if (this.okrElementIsSaving) {
        this.closeOkrElementOnSavingEnd = true
      } else {
        if (this.showModal) {
          this.updateFormObjective()
          this.updateObjectiveChildObjectives()
        }
      }
    },

    async onChildObjectiveModalClosed() {
      this.childModalOpened = false
      await this.$nextTick()
      this.showChildObjectiveModal = false
    },

    onChildObjectiveModalOpened(value) {
      this.childModalOpened = value
    },

    onFormClose(eventData) {
      if (this.okrElementIsSaving) {
        this.closeOkrElementOnSavingEnd = true
      } else {
        this.checkConfirmAndCloseModal(eventData)
      }
    },

    /** @public */
    async openForm(view, data, source = 'other', requestElementData = true, payload = null) {
      this.modalView = view
      this.formObjective = data
      if (view === OKR_FORM_VIEWS.KR) {
        this.formObjective.levelId = this.getLevelItem('typeId', OBJECTIVE_TYPES.KR).id
      }

      if (this.isEdit && VIEWS_WITH_BREADCRUMB.includes(view) && this.okrElementIsSaving) {
        this.okrElementIsSaving = false
        this.$refs.okrElementSavingIndicator?.endSaving()
        this.$refs.okrElementSavingIndicatorTop?.endSaving()
      }

      if (!this.isEdit && VIEWS_WITH_BREADCRUMB.includes(view)) {
        const payloadByView = {
          [OKR_FORM_VIEWS.OBJECTIVE]: {
            displayId: this.$t('create.objective.title')
          },

          [OKR_FORM_VIEWS.KR]: {
            displayId: this.$t('action.create_keyResult'),
            customIcon: 'kr-objective-next'
          },

          [OKR_FORM_VIEWS.LINK_JIRA_ISSUE]: {
            displayId: this.$t('action.link_jira_issue'),
            customIcon: 'jira-objective'
          }
        }

        if (view === OKR_FORM_VIEWS.OBJECTIVE) {
          const selectedLevel = this.levels.find(({ id }) => id === data.levelId)
          if (selectedLevel) {
            const { color, prefix, typeId } = selectedLevel
            payloadByView[OKR_FORM_VIEWS.OBJECTIVE] = {
              ...payloadByView[OKR_FORM_VIEWS.OBJECTIVE],
              levelColor: color,
              levelPrefix: prefix,
              typeId
            }
          }
        }

        this.breadcrumbs = [
          {
            element: {
              id: null,
              ...payloadByView[view]
            }
          }
        ]
      }
      if (this.formObjective?.id) {
        if (requestElementData) {
          await this.updateFormObjective()
        }

        if (!isEmpty(this.formObjective)) {
          this.showModal = true
        }

        this.okrPayload = payload
        if (VIEWS_WITH_BREADCRUMB.includes(view) && !isEmpty(this.formObjective)) {
          this.breadcrumbs = await this.getBreadcrumbs(
            this.formObjective.id,
            this.formObjective.workspaceId
          )
        }
      } else {
        this.showModal = true
      }

      const viewToSubcategory = {
        [OKR_FORM_VIEWS.OBJECTIVE]: 'Objective',
        [OKR_FORM_VIEWS.KR]: 'KR',
        [OKR_FORM_VIEWS.LINK_JIRA_ISSUE]: 'Link'
      }
      if (view in viewToSubcategory) {
        tracker.logEvent('Opened form', {
          category: EVENT_CATEGORIES.OKR_MANAGEMENT,
          subcategory: viewToSubcategory[view],
          source,
          mode: data.id ? MODE_NAMES_FOR_TRACKING.EDIT : MODE_NAMES_FOR_TRACKING.NEW
        })
      }

      this.$emit('opened')
    },

    /** @public */
    async openChildForm(childView, data) {
      if (this.isChild) {
        this.$emit('open-child', { childView, data })
        return
      }

      const viewsAboveCurrent = [
        OKR_FORM_VIEWS.CUSTOM_WEIGHTS,
        OKR_FORM_VIEWS.LINK_JIRA_ISSUE,
        OKR_FORM_VIEWS.DUPLICATE_OBJECTIVE
      ]

      const createAbove =
        [OKR_FORM_VIEWS.OBJECTIVE, OKR_FORM_VIEWS.KR].includes(childView) && !data.id
      if (!viewsAboveCurrent.includes(childView) && !createAbove) {
        this.openForm(childView, data, 'form')
      } else {
        this.showChildObjectiveModal = true
        await this.$nextTick()
        this.$refs.objectiveModal.openForm(childView, data, 'form')
      }
    },

    editElement({ view, item }) {
      this.openChildForm(view, item)
    },

    hideConfirmClose() {
      this.isConfirmCloseShow = false
    },

    onConfirm() {
      this.hideConfirmClose()
      this.checkConfirmAndCloseModal({ checkDataChange: false })
    },

    onCustomWeightsUpdate(data) {
      this.$emit('updated-weights', data)
    },

    /* Public API */
    async updateObjectiveChildObjectives() {
      // custom weights can be also updated directly(without objective form)
      if (this.$refs.objectiveForm) {
        await this.$refs.objectiveForm.getChildObjectives({
          isRefetch: true,
          isUpdateHistory: true
        })
      } else if (this.$refs.childKrForm) {
        await this.$refs.childKrForm.getChildKrs({ isRefetch: true, isUpdateHistory: true })
      }
    },

    onBreadcrumbItemClick(objective) {
      // child objective form should be handled separately
      if (this.showChildObjectiveModal) {
        this.childModalOpened = false
        this.$nextTick(() => {
          this.showChildObjectiveModal = false
        })
      }

      if (this.isChild && !(this.formObjective && this.formObjective.id === objective.id)) {
        this.$emit('breadcrumb-item-click', objective)
        return
      }

      // click on item that is already opened
      if (this.formObjective && this.formObjective.id && this.formObjective.id === objective.id) {
        this.updateFormObjective()
        this.updateObjectiveChildObjectives()
        return
      }

      this.openForm(OKR_TYPE_TO_FORM_VIEW[objective.typeId], objective, 'breadcrumb')
    },

    onKeyUp(event) {
      // 27 is key Esc
      if (isEscape(event.keyCode)) {
        const openSelects = document.querySelectorAll('.o-droplist--expanded')
        const openedCustomFieldNumberInputs = document.querySelectorAll('.oni-Wrapper_Input')
        if (
          openSelects.length === 0 &&
          openedCustomFieldNumberInputs.length === 0 &&
          !this.showChildObjectiveModal
        ) {
          this.checkConfirmAndCloseModal()
        }
      }
    },

    onOkrElementSavingStarted() {
      this.okrElementIsSaving = true
      this.$refs.okrElementSavingIndicator.startSaving()
      this.$refs.okrElementSavingIndicatorTop.startSaving()
    },

    onOkrElementSavingEnded() {
      this.okrElementIsSaving = false
      this.$refs.okrElementSavingIndicator.endSaving()
      this.$refs.okrElementSavingIndicatorTop.endSaving()
      if (this.closeOkrElementOnSavingEnd) {
        this.checkConfirmAndCloseModal()
      }
    },

    onObjectiveFormLevelChanged(newLevelId) {
      const lastBreadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1]
      if (lastBreadcrumb && has(lastBreadcrumb, 'element')) {
        const newLevel = this.levels.find(({ id }) => id === newLevelId)
        if (newLevel) {
          const { color, prefix, typeId } = newLevel
          lastBreadcrumb.element = {
            ...lastBreadcrumb.element,
            levelColor: color,
            levelPrefix: prefix,
            typeId
          }
        }

        this.breadcrumbs.splice(-1, 1, { ...lastBreadcrumb })
      }
    }
  }
})
</script>

<style lang="scss">
@import '~@/assets/styles/okr-modal';

.om-ObjectiveModal {
  .o-modal-body {
    padding: 0 24px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .oc-Modal {
    .o-modal-body {
      padding: 0;
    }
  }

  & > .o-modal-content-wrapper > .o-modal-content {
    overflow: visible;
    display: flex;
    flex-direction: column;
    transition: $transition-fast;
  }

  &-childModalOpened {
    & > .o-modal-content-wrapper > .o-modal-content {
      $shift: 120px;
      $rest-shift: calc(#{$shift} - #{$depth-shift});
      width: calc(#{$lg-next-modal-width} + #{$depth-shift} + #{$rest-shift});
      padding-right: $rest-shift;
    }
  }

  .o-droplist-content {
    white-space: normal;
  }
}

.om-ObjectiveModal {
  .o-modal-body {
    padding: 0;
  }
}

.o-confirm-actions .ac-Checkbox {
  margin-right: 8px;
}

.om-ObjectiveModalWrapper {
  .uim-TitleText {
    margin-bottom: 10px;
  }
}

.om-ButtonClose {
  background: rgba($white, 0.93);
  color: $dark-grey;
  border-radius: 50%;

  &:hover {
    background: rgba($white, 0.93);
  }
}
</style>
