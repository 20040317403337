<template>
  <AppSelect
    :data-auto-testid="MULTI_SELECT_FORM_FIELD_TEST_ID"
    :data-testid="MULTI_SELECT_FORM_FIELD_TEST_ID"
    :disabled="!userHasUpdateAccess"
    :model-value="modelValue"
    :offset="[0, 0]"
    :options="options"
    :search-function="value => localSearch({ value, options })"
    has-only-this-button
    hide-selected-items-in-dropdown
    item-label="name"
    item-value="id"
    multi
    show-selected-options-inside
    skeleton-loader
    theme="no-shadow-next light"
    type="default-next"
    @update:model-value="onUpdateModelValue"
  >
    <template #button="{ option, active }">
      <OkrFormFieldSelectTriggerNext
        :empty-state-label="emptyStateLabel"
        :label="fieldName"
        :opened="active"
        :selected-options="option"
        truncated-label
      >
        <template #label="{ resolvedLabel }">
          <CustomFieldLabel :field-id="fieldId"> {{ resolvedLabel }} </CustomFieldLabel>
        </template>
      </OkrFormFieldSelectTriggerNext>
    </template>
  </AppSelect>
</template>

<script setup>
import { MULTI_SELECT_FORM_FIELD_TEST_ID } from '@/utils/custom-fields/jest-helpers'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { localSearch } from '@/utils/objectives'

import CustomFieldLabel from '@/components/custom-fields/okr-elements-form/CustomFieldLabel'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'

defineOptions({
  name: 'MultiSelect',
  inheritAttrs: false
})

const props = defineProps({
  userHasUpdateAccess: {
    type: Boolean,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  modelValue: {
    type: Array,
    required: true
  }
})

const { options, fieldName, emptyStateLabel } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const emit = defineEmits({
  'update:model-value': null
})
const onUpdateModelValue = value => {
  emit('update:model-value', {
    fieldId: props.fieldId,
    value
  })
}
</script>

<style lang="scss" scoped></style>
