<template>
  <div :class="{ 'ofo-Option-with-badge': $slots.badge }" class="ofo-Option">
    <AppIcon
      v-if="alreadyAdded"
      class="ofo-Option_Icon"
      height="24"
      icon-name="user-already-added"
      width="24"
    />
    <template v-else>
      <AppIcon
        v-if="isShowNoAvatarIcon"
        :icon-name="resolvedNoAvatarIconName"
        class="ofo-Option_Icon"
        height="24"
        width="24"
      />
      <AppAvatar v-else :avatar-url="option.avatarUrl" class="ofo-Option_Icon" no-margins />
    </template>
    <span class="oboard-truncated-text">
      <slot name="label">
        {{ option[labelKey] }}
      </slot>
    </span>

    <div v-if="$slots.badge" class="ofo-Option_Badge">
      <slot name="badge" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'OwnerFieldOption'
})

const props = defineProps({
  option: {
    type: Object,
    required: true
  },

  labelKey: {
    type: String,
    default: 'name'
  },

  idKey: {
    type: String,
    default: 'accountId'
  },

  noAvatarIconName: {
    type: String,
    default: 'unassigned-owner'
  },

  alreadyAdded: {
    type: Boolean
  }
})

const isShowNoAvatarIcon = computed(() => !props.option[props.idKey] || props.option.createdEmail)

const resolvedNoAvatarIconName = computed(() => {
  return props.option.createdEmail ? 'email' : props.noAvatarIconName
})
</script>

<style lang="scss" scoped>
.ofo-Option {
  display: flex;
  align-items: center;
  gap: 8px;

  max-width: 100%;
  color: $dark-1;

  &-with-badge {
    width: 100%;
  }
}

.ofo-Option_Icon {
  flex: 0 0 auto;
}

.ofo-Option_Badge {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  color: $grey-1-next;
  margin-left: auto;
}
</style>
