import ApiHandler from '@/api/base'

class WorkspacesApiHandler extends ApiHandler {
  async getWorkspaces() {
    const { data } = await this.http.get('/settings/workspaces')

    return data
  }

  async getAllUserWorkspaces({ searchString = null } = {}) {
    const { data } = await this.http.post('/getAllUserWorkspaces', {
      searchString
    })
    return data
  }

  async createWorkspace({ key = null, name = null, isPublic = false, timezone = 0 } = {}) {
    const { data } = await this.http.post('/createWorkspace', {
      key,
      name,
      public: isPublic,
      timezone
    })
    return data
  }

  async updateWorkspace({
    id = null,
    key = null,
    name = null,
    isPublic = false,
    timezone = 0
  } = {}) {
    const { data } = await this.http.post('/updateWorkspace', {
      id,
      key,
      name,
      public: isPublic,
      timezone
    })
    return data
  }

  async removeWorkspace({ id } = {}) {
    await this.http.post('/removeWorkspace', {
      id
    })
  }
}

export default WorkspacesApiHandler
