import { computed } from 'vue'

import { useFilterByAccessLevel } from '@/utils/access-level-filter'
import { ENTITIES_ACCESS_LEVELS } from '@/utils/entities-access-levels'

export const ADD_USER_TAB = 'addUser'
export const BULK_ADD_TAB = 'bulkAdd'

const OPTIONS = [
  {
    label: 'users.create',
    value: ADD_USER_TAB,
    icon: 'plus-small',
    trackingName: 'single',
    accessLevel: ENTITIES_ACCESS_LEVELS.JIRA_APP | ENTITIES_ACCESS_LEVELS.WEB_APP
  },

  {
    label: 'users.bulk_add',
    value: BULK_ADD_TAB,
    icon: 'bulk-add',
    trackingName: 'bulk',
    accessLevel: ENTITIES_ACCESS_LEVELS.JIRA_APP
  }
]

export const useBulkOperationSwitcher = () => {
  const { getEntitiesForPlatform } = useFilterByAccessLevel()

  const resolvedOptions = getEntitiesForPlatform({ entities: OPTIONS })

  const needShowSwitcher = computed(() => resolvedOptions.length > 1)

  return {
    resolvedOptions,
    needShowSwitcher
  }
}
