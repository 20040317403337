<template>
  <div v-if="isOwner">
    <WebAppUnavailablePageLayout>
      <router-view v-if="ALLOWED_ROUTES.includes(route.name)" v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
      <BillingSettings v-else />
    </WebAppUnavailablePageLayout>
  </div>
  <LicenseInactiveView v-else />
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import { ROUTE_NAMES } from '@/routes/route-helpers'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'
import BillingSettings from '@web-app/views/workspaces/settings/billing/billing-settings'

import WebAppUnavailablePageLayout from '@/components/WebAppUnavailablePageLayout'
import LicenseInactiveView from '@/views/LicenseInactiveView'

const ALLOWED_ROUTES = [
  ROUTE_NAMES.SETTINGS_BILLING_SETTINGS,
  ROUTE_NAMES.SETTINGS_BILLING_INFORMATION,
  ROUTE_NAMES.SETTINGS_BILLING,
  ROUTE_NAMES.SETTINGS_BILLING_PROMO
]

const store = useStore()
const isOwner = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_OWNER])
const route = useRoute()
</script>

<style lang="scss" scoped></style>
