<template>
  <div class="dt-WorkspaceSelect">
    <AppSelect
      v-if="workspacesLoaded"
      :dropdown-search="showDropdownSearch"
      :dropdown-width="dropdownWidth"
      :loading="loading"
      :model-value="workspaceId"
      :offset="[0, 0]"
      :options="workspaces"
      :search-function="searchWorkspaces"
      item-label="name"
      item-value="id"
      no-left-padding
      theme="no-shadow-next light"
      type="default-next"
      @update:options="onDropdownMenuOptionsUpdate"
      @update:model-value="onDropdownMenuChange"
    >
      <template #button="{ option }">
        <div v-if="option" class="dt-WorkspaceSelect_Button">
          <WorkspaceIcon :option="option" />

          <span v-if="!withoutText" class="dt-SelectedWsName">
            {{ option.name }}
          </span>

          <span class="dt-DroplistIcon">
            <AppIcon height="24" icon-name="chevrons-up-down" width="24" />
          </span>
        </div>
      </template>
      <template #option-label="{ option, selected }">
        <AppMenuWorkspaceSelectOption v-if="option" :option="option" :selected="selected" />
      </template>
    </AppSelect>
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, ref, onMounted } from 'vue'

import { getAllUserWorkspaces } from '@/composables/fetchingData'

import AppMenuWorkspaceSelectOption from '@/components/AppMenu/AppMenuWorkspaceSelectOption'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import WorkspaceIcon from '@/components/workspaces/WorkspaceIcon'

const props = defineProps({
  workspaceId: {
    type: [Number, String],
    default: null
  },
  withoutText: {
    type: Boolean,
    default: false
  },
  dropdownWidth: {
    type: String,
    default: '166px'
  }
})

const emit = defineEmits({
  'update:workspaceId': null,
  'show-error': null
})
const onDropdownMenuChange = value => {
  if (value !== props.workspaceId) {
    emit('update:workspaceId', value)
  }
}
const workspaces = ref([])
const loading = ref(false)
const showDropdownSearch = computed(() => workspaces.value.length > 10)
const workspacesLoaded = computed(() => !isEmpty(workspaces))

onMounted(async () => {
  try {
    loading.value = true
    const { options } = await getAllUserWorkspaces()
    workspaces.value = options.value
    if (!props.workspaceId) {
      onDropdownMenuChange(workspaces.value[0].id)
    }
    if (
      props.workspaceId &&
      !workspaces.value.find(workspace => workspace.id === props.workspaceId)
    ) {
      emit('show-error')
      emit('update:workspaceId', workspaces.value[0].id)
    }
  } finally {
    loading.value = false
  }
})

const searchWorkspaces = async searchString => {
  const { options } = await getAllUserWorkspaces(searchString)
  return options
}

const onDropdownMenuOptionsUpdate = options => {
  workspaces.value = [...options]
}
</script>

<script>
//eslint-disable-next-line
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ConfluenceWorkspaceSelect'
})
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_mixins.scss';
.dt-WorkspaceSelect {
  transition: opacity $menu-transition;
  max-width: 220px;
}
:deep(.dt-WorkspaceSelect_Button) {
  cursor: pointer;
  overflow: hidden;
  background: $white;
  box-shadow: 0 1px 2px rgba($regular-text, 0.1);
  border-radius: $border-radius-md;
  padding: 10px;
  display: flex;
  align-items: center;
  @include activityStates($grey-medium);
}

:deep(.o-droplist--expanded) {
  .dt-SelectedWsName {
    color: $primary-color;
  }

  .svg-icon {
    color: $primary-color;
  }
}

:deep(.svg-icon) {
  .dt-WorkspaceSelect:hover & {
    color: $primary-color;
  }
}

.dt-DroplistIcon {
  display: flex;
  margin-left: auto;
}

.dt-SelectedWsName {
  font-family: $system-ui;
  padding-left: 16px;
  color: $regular-text;
  font-weight: fw('medium');
  font-size: $fs-16;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  .dt-WorkspaceSelect:hover & {
    color: $primary-color;
  }
}
</style>
