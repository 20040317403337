<template>
  <div
    ref="header"
    :class="{
      'pc-PageContentHeader-fullscreen': fullscreen,
      'pc-PageContentHeader-no-margin': noMargin,
      'pc-PageContentHeader-roadmap-is-stuck': roadmapStickyStatus,
      'pc-PageContentHeader-without-divider': fullscreen && removeDividerOnFullscreen
    }"
    class="pc-PageContentHeader"
  >
    <div class="pc-HeaderWrapper">
      <div :class="headClasses">
        <div class="pc-HeaderMainContent">
          <AppTitle v-if="title" :level="level" class="pc-Title" disable-margin>
            {{ title }}
          </AppTitle>
          <slot name="header-main-content" />
        </div>
        <div class="pc-RightSide">
          <slot />
          <portal-target name="page-header-actions" />
        </div>
      </div>
    </div>

    <div v-if="description" :class="descriptionClasses">
      {{ description }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

import AppTitle from '@/components/ui/AppTitle/AppTitle'

export default defineComponent({
  name: 'PageContentHeader',
  components: { AppTitle },
  props: {
    title: {
      type: String,
      default: ''
    },

    level: {
      type: Number,
      default: 2
    },

    description: {
      type: String,
      default: ''
    },

    maxWidth: {
      type: String,
      default: 'sm',
      validator: v => ['sm', 'md', 'md-next', 'full', 'full-next'].includes(v)
    },

    centered: {
      type: Boolean
    },

    isFlexWrap: {
      type: Boolean
    },

    observeHeight: {
      type: Boolean
    },

    noMargin: {
      type: Boolean
    },

    removeDividerOnFullscreen: {
      type: Boolean
    }
  },

  data() {
    return {
      resizeObserver: null
    }
  },

  computed: {
    ...mapState('system', {
      fullscreen: state => state.fullscreen,
      roadmapStickyStatus: state => state.roadmapStickyStatus
    }),

    headClasses() {
      return {
        'pc-Header': true,
        [`pc-Header-${this.maxWidth}`]: true,
        'pc-Header-centered': this.centered,
        'pc-Header-flex-wrap': this.isFlexWrap
      }
    },

    descriptionClasses() {
      return {
        'pc-Description': true,
        [`pc-Description-${this.maxWidth}`]: true,
        'pc-Description-centered': this.centered
      }
    }
  },

  mounted() {
    if (this.observeHeight) {
      this.initResizeObserver()
    }
  },

  beforeUnmount() {
    this.disconnectObserver()
  },

  methods: {
    ...mapActions('system', {
      setPageHeaderHeight: 'setPageHeaderHeight'
    }),

    async initResizeObserver() {
      await this.$nextTick()
      const { header } = this.$refs
      this.resizeObserver = new ResizeObserver(() => {
        this.setPageHeaderHeight(header.getBoundingClientRect().height)
      })

      this.resizeObserver.observe(header)
    },

    disconnectObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
        this.resizeObserver = null
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/canvas-dimensions';
.pc-PageContentHeader {
  margin-bottom: 16px;
  &-fullscreen,
  &-no-margin {
    margin-bottom: 0;
  }
}

.pc-HeaderWrapper {
  // box-shadow: 0 0.5px 0 $grey-4;
  background-color: $white;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    left: $page-left-padding;
    bottom: 0;
    width: calc(100% - #{$page-left-padding} - #{$page-right-padding});
    height: 1px;
    background-color: $grey-2-next;

    .pc-PageContentHeader-roadmap-is-stuck & {
      width: calc(100% - #{$page-left-padding});
    }

    .pc-PageContentHeader-without-divider & {
      display: none;
    }
  }
}

.pc-Header {
  display: flex;
  justify-content: space-between;
  align-items: var(--align-items, center);
  padding: 10px 0;
  min-height: $default-objectives-page-header-height;
  gap: 12px;
  font-family: $system-ui;

  &-flex-wrap {
    flex-wrap: wrap;
  }

  &-md-next {
    min-height: 64px;
  }

  &-full-next {
    font-family: $system-ui;

    min-height: unset;
  }
}

.pc-Header,
.pc-Description {
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;

  &-sm {
    width: $page-width-sm;
  }

  &-md {
    width: $page-width-md;
  }

  &-md-next {
    width: 100%;
    max-width: $page-width-md-next;
    padding-top: $page-top-padding;
    padding-bottom: 16px;
  }

  &-full {
    width: $page-width-full;
  }

  &-centered {
    margin: 0 auto;
  }
}

.pc-Header {
  &-full-next {
    width: $page-width-full;
    padding: var(--padding-top, #{$page-top-padding}) $page-right-padding
      var(--padding-bottom, 16px) var(--padding-left, $page-left-padding);
  }
}

.pc-Description {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
  font-family: $system-ui;
}

.pc-Description {
  &-full-next {
    max-width: 600px;
    margin-top: 2px;
  }
}

.pc-Title {
  flex-shrink: 0;

  .pc-Header-full-next & {
    font-weight: fw('semi-bold');
    font-family: inherit;
  }
}

.pc-RightSide {
  align-items: center;
  flex: 0 0 auto;
  max-width: 50%;
  gap: 10px;
}

.pc-RightSide,
.pc-HeaderMainContent {
  display: flex;
  align-items: center;
}

.pc-HeaderMainContent {
  overflow: hidden;
}
</style>
