<template>
  <div
    v-tippy="{
      theme: 'translucent-next text-center word-break',
      content: item.tooltipContent,
      placement: 'top'
    }"
    :style="{
      '--align': item.align
    }"
    @click="onClick"
  >
    <AppIcon v-if="item.icon" :icon-name="item.icon" class="dmi-Icon" height="24" width="24" />

    <OkrIcon
      v-if="!item.icon && item.prefix && item.color"
      :objective="levelToOkrIconElement(item)"
    />

    <slot :item="item" name="item-label">
      <template v-if="item.title">
        {{ $t(item.title) }}
      </template>

      <template v-if="item.label">
        {{ item.label }}
      </template>
    </slot>

    <slot />
  </div>
</template>

<script setup>
import { levelToOkrIconElement } from '@/utils/objectives'

import OkrIcon from '@/components/objectives/items/OkrIcon'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'DropdownMenuItem'
})

defineProps({
  item: {
    type: Object,
    required: true
  }
})

const emit = defineEmits({
  click: null
})

const onClick = () => {
  emit('click')
}
</script>

<style lang="scss" scoped>
.dmi-Icon {
  color: var(--icon-color, inherit);
}
</style>
