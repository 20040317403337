<template>
  <div
    v-if="showPrivacyCell"
    v-tippy="{
      content: getTooltipTitle,
      placement: 'top',
      theme: 'word-break translucent-next white-space-pre-line text-center'
    }"
    class="pc-Wrapper"
  >
    <PrivacyAnimatedIcons
      :can-update-objective-privacy="canUpdateObjectivePrivacy"
      :is-animated="isAnimated"
      :is-loading="isLoading"
      :item="item"
      @update-privacy="onPrivacyUpdate"
    />
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_SOURCES } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import { currentUserCanUpdateObjective, isOkrElementClosed } from '@/utils/objectives'

import PrivacyAnimatedIcons from '@/components/ui/PrivacyAnimatedIcons'

defineOptions({
  name: 'PrivacyCell'
})

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  trackingSource: {
    type: String,
    default: EVENT_SOURCES.GRID
  }
})

const emit = defineEmits({
  'update-privacy': null
})

const isLoading = ref(false)

const isEmptyUsersList = computed(() => isEmpty(props.item.users))

const canUpdateObjectivePrivacy = computed(() => {
  return (
    !isEmptyUsersList.value &&
    currentUserCanUpdateObjective(props.item) &&
    !isOkrElementClosed(props.item)
  )
})
const onPrivacyUpdate = async value => {
  if (!canUpdateObjectivePrivacy.value) return
  const api = new ObjectivesInfoApiHandler()
  isLoading.value = true
  try {
    const result = await api.updateOkrElement({
      ...props.item,
      elementId: props.item.id,
      isPrivate: value
    })

    if (value) setAnimatedItem()

    emit('update-privacy', { ...props.item, ...result })

    const eventName = value ? 'set okr private' : 'set okr public'

    tracker.logEvent(eventName, {
      source: props.trackingSource,
      level: props.item.levelName,
      id: props.item.id,
      category: EVENT_CATEGORIES.OKR_MANAGEMENT
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}
const isAnimated = ref(false)
const setAnimatedItem = () => {
  isAnimated.value = true

  setTimeout(() => {
    isAnimated.value = false
  }, 1500)
}

const store = useStore()
const isPluginAdmin = computed(() => {
  return store.state.pluginOptions.isPluginAdmin
})

const { t } = useI18n()

const userCanUpdateObjective = computed(
  () =>
    currentUserCanUpdateObjective(props.item) &&
    isEmptyUsersList.value &&
    !isPluginAdmin.value &&
    !isOkrElementClosed(props.item)
)
const getTooltipTitle = computed(() => {
  const availableTooltip = props.item.private
    ? t('objective.make_okr_public')
    : t('objective.make_okr_private')

  return userCanUpdateObjective.value
    ? t('objective.you_cant_make_private')
    : userCanUpdateObjective.value || canUpdateObjectivePrivacy.value
    ? availableTooltip
    : null
})

const showPrivacyCell = computed(() => {
  // показываем ячейку если стоит приватность (и может не быть прав на редактирвоание) или есть права на редактирование или есть доступ к тултипу
  // к тултипу есть доступ если пользователь не может редактировать окр и список пользователей пустой
  return (
    props.item &&
    (props.item.private || canUpdateObjectivePrivacy.value || userCanUpdateObjective.value)
  )
})
</script>

<style lang="scss" scoped>
.pc-Wrapper {
  display: flex;
  margin: 0 auto 0 0;
  max-width: 46px;
  width: 100%;
  text-align: center;
  justify-content: center;
}
</style>
