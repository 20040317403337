<template>
  <template v-if="!item.isNestedTask">
    <CellSelectItemsList
      :id="`gr${uid}`"
      :active="isOpened"
      :items="item.groups"
      :plug-icon="PLUG_ICONS.SELECT"
      :user-has-read-access="readable"
      :user-has-update-access="updatable"
      data-testid="groups-cell-value"
      item-value="name"
      show-native-tooltip
      @click="editInitialised = true"
    >
      <template #first-item="{ active, userHasReadAccess, userHasUpdateAccess, item: group }">
        <CellSelectItem
          :active="active"
          :style="{
            '--item-background': group.color,
            '--item-padding': 0
          }"
          :user-has-read-access="userHasReadAccess"
          :user-has-update-access="userHasUpdateAccess"
          class="gc-FirstItem"
          truncated
        >
          <GroupSelectLabel :group="group" no-fill />
        </CellSelectItem>
      </template>
    </CellSelectItemsList>
    <template v-if="updatable">
      <AppSelect
        v-if="editInitialised"
        v-model="selectedGroups"
        :dropdown-min-width="300"
        :loading="areGroupsLoading"
        :offset="[0, 0]"
        :options="groups"
        :search-function="getGroupsInfo"
        :to-selector="`#gr${uid}`"
        append-to=".o-objective-table"
        boundary="scrollParent"
        data-testid="groups-selector"
        dropdown-search
        dropdown-width="220px"
        has-only-this-button
        hide-selected-items-in-dropdown
        item-label="name"
        item-value="id"
        multi
        show-on-init
        show-selected-options-inside
        theme="no-shadow-next light"
        type="default-next"
        @hide="hideSelect"
        @opened="getGroups"
      >
        <template #option-label="{ option }">
          <GlobalGroupsSelectOption v-if="option" :group="option" />
        </template>
      </AppSelect>
    </template>
  </template>
</template>

<script>
import { defineComponent } from 'vue'

import GlobalGroupsApiHandler from '@/api/global-groups'
import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { PLUG_ICONS } from '@/utils/cell-helper'
import { handleError } from '@/utils/error-handling'
import { convertGroupsListToGroups, createGroupsList } from '@/utils/objectives'
import { uid } from '@/utils/uid'

import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import GroupSelectLabel from '@/components/global-groups/GroupSelectLabel'
import CellSelectItem from '@/components/objectives/table/cells/CellSelectItem'
import CellSelectItemsList from '@/components/objectives/table/cells/CellSelectItemsList'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

export default defineComponent({
  name: 'GroupsCell',

  components: {
    GlobalGroupsSelectOption,
    GroupSelectLabel,
    CellSelectItem,
    CellSelectItemsList,
    AppSelect
  },

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      required: true
    },

    readable: {
      type: Boolean
    },

    updatable: {
      type: Boolean
    }
  },

  emits: { 'update-group': null },

  data() {
    return {
      uid: uid(),
      groups: [],
      areGroupsLoading: true,
      editInitialised: false,
      isOpened: false
    }
  },

  computed: {
    PLUG_ICONS() {
      return PLUG_ICONS
    },

    selectedGroups: {
      get() {
        return [...convertGroupsListToGroups(this.item.groups)]
      },

      set(value) {
        this.onGroupUpdate(value)
      }
    }
  },

  methods: {
    async getGroups() {
      this.isOpened = true
      this.groups = await this.getGroupsInfo()
    },

    async getGroupsInfo(searchString = null) {
      const api = new GlobalGroupsApiHandler()

      try {
        return await api.getGroupsForFilter({
          searchString,
          groupIds: this.selectedGroups,
          workspaceIds: [this.item.workspaceId]
        })
      } catch (error) {
        handleError({ error })
      } finally {
        this.areGroupsLoading = false
      }
      return []
    },

    async onGroupUpdate(value) {
      const api = new ObjectivesInfoApiHandler()
      const {
        contribute,
        dueDate,
        elementStartDate,
        jiraIssueId,
        intervalId,
        name,
        orderValue,
        parentId,
        levelId,
        workspaceId,
        confidenceLevelId,
        originalValue,
        currentValue,
        targetValue,
        fieldTypeId,
        typeId,
        gradeMode
      } = this.item
      try {
        const result = await api.updateOkrElement({
          contribute,
          dueDate,
          elementStartDate,
          jiraIssueId,
          intervalId,
          name,
          orderValue,
          parentId,
          levelId,
          workspaceId,
          confidenceLevelId,
          originalValue,
          currentValue,
          targetValue,
          fieldTypeId,
          elementId: this.item.id,
          groups: createGroupsList(value),
          typeId,
          gradeMode
        })

        this.$emit('update-group', { ...this.item, ...result })
      } catch (error) {
        handleError({ error })
      }
    },

    hideSelect() {
      this.isOpened = false
      this.groups = []
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.gc-FirstItem {
  --item-hover-background: #{getGlobalGroupHoverColor(var(--item-background))};
}
</style>
