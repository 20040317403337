<template>
  <div class="o-objective-parent-label">
    <template v-if="objective && objective.id === 0">
      {{ objective.name }}
    </template>
    <template v-else>
      <div class="osi-Option">
        <div
          v-tippy="{
            content: tooltipName,
            theme: 'word-break translucent-next'
          }"
          class="osi-Name"
        >
          <div class="osi-ObjectiveIcon">
            <OkrIcon :objective="objective" />
          </div>
          <div v-if="objective" class="o-objective-parent-id-title">
            <span v-if="objective && objective.displayId" class="o-objective-parent-id">
              {{ objective.displayId }}
            </span>
            <ObjectiveName :objective="objective" />
          </div>
        </div>
        <div class="osi-WorkspaceAndInterval">
          <div v-if="showWorkspace" class="osi-Workspace">
            {{ objective.workspaceKey }}
          </div>
          <div v-if="showInterval" class="osi-Interval">
            <span
              v-tippy="{
                content: objective.intervalName
              }"
            >
              {{ objective.intervalName }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { currentUserCanReadObjective } from '@/utils/objectives'

import OkrIcon from '@/components/objectives/items/OkrIcon'
import ObjectiveName from '@/components/objectives/ObjectiveName'

export default defineComponent({
  name: 'ObjectiveSelectItem',

  components: {
    OkrIcon,
    ObjectiveName
  },

  props: {
    objective: {
      type: Object,
      required: true
    },

    showWorkspace: {
      type: Boolean,
      default: false
    },

    showInterval: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    tooltipName() {
      if (!this.objective) {
        return ''
      }

      if (!currentUserCanReadObjective(this.objective)) {
        return this.$t('objectives.unavailable_description')
      }

      if (this.objective.id === 0) {
        return this.objective.name
      }
      return `${this.objective.displayId} ${this.objective.name}`
    }
  }
})
</script>

<style lang="scss" scoped>
.o-objective-parent-label {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  line-height: initial;
}

.o-objective-parent-id-title {
  display: flex;
  align-items: center;
  white-space: nowrap;

  .o-objective-parent-id {
    line-height: 20px;
    flex-shrink: 0;
    margin-right: 8px;
    color: $primary-color-next;
  }
}

.osi-ObjectiveIcon {
  flex-shrink: 0;

  display: flex;
  align-items: center;
  margin-right: 8px;
}

.osi-Option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.osi-Workspace,
.osi-Interval {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
}

.osi-Workspace {
  padding-right: 4px;
  width: 50px;
}

.osi-Interval {
  width: 106px;
}

.osi-WorkspaceAndInterval,
.osi-Name {
  display: flex;
  align-items: center;
}

.osi-Name {
  overflow: hidden;
  padding-right: 16px;
  width: auto;
}
</style>

<style lang="scss">
.o-objective-parent-id-title {
  overflow: hidden;

  .o-title-text-wrapper {
    overflow: hidden;
  }

  .o-title-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.as-AppSelectOptionsItem_Content-single {
  width: 100%;
}

.lji-ParentObjective,
.kr-ParentObjective {
  .dropdown-search {
    padding: 8px;
    border: 2px solid $primary-color-next;
    border-radius: $border-radius-sm-next;
  }

  .asi-AppSelectOptionsItem {
    padding: 10px;
  }

  .as-AppSelectOptions {
    .dropdown-search {
      padding-left: 9px;
    }
  }

  .as-AppSelectGroupLabel {
    margin-left: 9px;
  }
}
</style>
