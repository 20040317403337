import { computed, inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import WebAppUsersApiHandler from '@/api/web-app-users'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { ADMIN_ROLES } from '@/utils/admin-roles'
import { ASSIGNEE_ENTITY_KEYS, WEB_APP_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'
import { APP_PLATFORMS } from '@root/app-platforms'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

export const JIRA_PLATFORM_ID = 0
export const WEB_APP_PLATFORM_ID = 1

export const WEB_APP_ONLY = 2
export const JIRA_AND_WEB_APP = 3
export const PLATFORMS_OPTIONS = {
  [APP_PLATFORMS.JIRA]: {
    id: JIRA_PLATFORM_ID,
    icon: 'platform-jira',
    order: 1
  },

  [APP_PLATFORMS.WEB_APP]: {
    id: WEB_APP_PLATFORM_ID,
    icon: 'platform-web-app',
    order: 2
  }
}

export const getPlatformOptionsById = id => {
  return (
    Object.values(PLATFORMS_OPTIONS).find(option => option.id === id) ||
    PLATFORMS_OPTIONS[APP_PLATFORMS.JIRA]
  )
}

export const usePlatformHelpers = () => {
  const store = useStore()
  const { t } = useI18n()
  const isJiraConnected = computed(
    () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
  )
  const isWebAppConnected = computed(
    () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.WEB_APP_CONNECTED]
  )
  const isJiraApp = inject('isJiraApp')
  const isWebApp = inject('isWebApp')

  const isPlatformsCellNeed =
    (isJiraApp && isWebAppConnected.value) || (isWebApp && isJiraConnected.value)

  const PLATFORM_CELL = {
    title: t('admins.platform'),
    key: TABLE_COLUMNS_KEYS.PLATFORM,
    width: 94
  }

  return {
    isPlatformsCellNeed,
    platformCell: isPlatformsCellNeed ? PLATFORM_CELL : null
  }
}

export const usePlatformFilter = () => {
  const { t } = useI18n()

  const OPTIONS = [
    {
      label: t('filter.all_platforms'),
      value: 0
    },
    {
      value: 1,
      label: t('app.platform.jira'),
      icon: PLATFORMS_OPTIONS[APP_PLATFORMS.JIRA].icon
    },
    {
      value: WEB_APP_ONLY,
      label: t('app.platform.web_app'),
      icon: PLATFORMS_OPTIONS[APP_PLATFORMS.WEB_APP].icon
    },
    {
      value: JIRA_AND_WEB_APP,
      label: t('app.platform.both'),
      icon: 'platform-both'
    }
  ]

  return {
    OPTIONS
  }
}

export const useRemoveUserActionsDisabledState = () => {
  const store = useStore()
  const userData = computed(() => store.state.system.userData)
  const isOwner = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_OWNER])

  const getActionDisabledState = ({ user }) => {
    const isMe = user.accountId === userData.value.userAccountId

    let isDisabled = isMe

    if (!isOwner.value) {
      isDisabled = user[ASSIGNEE_ENTITY_KEYS.ADMIN_ROLE_ID] === ADMIN_ROLES.OWNER || isMe
    }

    return isDisabled
  }

  return {
    getActionDisabledState
  }
}

export const useWebAppAccessActionsForUser = () => {
  const store = useStore()
  const { getActionDisabledState } = useRemoveUserActionsDisabledState()

  const { isPlatformsCellNeed } = usePlatformHelpers()
  const isPluginAdmin = computed(() => store.state.pluginOptions.isPluginAdmin)

  const getWebAppAccessAction = ({ user }) => {
    let action = null

    if (isPlatformsCellNeed && isPluginAdmin.value) {
      let title = 'action.grant_web_access'
      let icon = 'grant-web-access'
      let name = ACTIONS_KEYS.GRANT_ACCESS
      let description = 'action.grant_web_access.description'
      let align = 'flex-start'
      const disabled = getActionDisabledState({ user })

      const isGrantWebAccessActionNeed =
        !user[WEB_APP_ENTITY_KEYS.PLATFORM_IDS].includes(WEB_APP_PLATFORM_ID)

      const isRevokeWebAccessActionNeed =
        user[WEB_APP_ENTITY_KEYS.PLATFORM_IDS].includes(WEB_APP_PLATFORM_ID) &&
        user[WEB_APP_ENTITY_KEYS.PLATFORM_IDS].includes(JIRA_PLATFORM_ID)

      if (isRevokeWebAccessActionNeed) {
        title = 'action.revoke_web_access'
        icon = 'revoke-web-access'
        name = ACTIONS_KEYS.REVOKE_ACCESS
        description = null
        align = 'center'
      }

      if (isGrantWebAccessActionNeed || isRevokeWebAccessActionNeed) {
        action = {
          title,
          icon,
          name,
          description,
          align,
          disabled
        }
      }
    }

    return action
  }

  const platformCellLoadingIds = ref([])
  const onGrantRevokeWebAccess = async ({ user, action, tableData = [] }) => {
    platformCellLoadingIds.value.push(user[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID])
    const api = new WebAppUsersApiHandler()
    const requestMethod =
      action === ACTIONS_KEYS.GRANT_ACCESS
        ? payload => api.grantWebAppAccess(payload)
        : payload => api.revokeWebAppAccess(payload)

    try {
      const { platformIds } = await requestMethod({
        accountId: user.accountId
      })

      const updatedUser = tableData.find(
        item => item[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID] === user[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID]
      )
      if (updatedUser) {
        updatedUser[WEB_APP_ENTITY_KEYS.PLATFORM_IDS] = platformIds
      }
    } catch (error) {
      handleError({ error })
    } finally {
      platformCellLoadingIds.value = platformCellLoadingIds.value.filter(
        id => id !== user[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID]
      )
    }
  }

  return {
    getWebAppAccessAction,
    onGrantRevokeWebAccess,
    platformCellLoadingIds
  }
}
