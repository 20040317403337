<template>
  <div ref="groupReference" class="agfg-FieldContainer">
    <div>
      <div>
        <FormFieldNext :label="t('users.select_jira_group')">
          <div ref="sourcePlatformSelectField">
            <AppSelect
              ref="sourcePlatformGroupRef"
              :dropdown-min-width="200"
              :model-value="groupsToAdd.sourcePlatformGroupIds"
              :offset="[0, sourcePlatformDropdownYOffset]"
              :options="platformGroups"
              :search-function="getPlatformGroups"
              class="agfg-GroupsSelect"
              dropdown-search
              item-label="name"
              item-value="groupId"
              multi
              show-all-selected
              show-selected-options-inside
              theme="no-shadow-next light"
              type="default-next"
              @open="fetchGroups"
              @update:options="platformGroups = $event"
              @update:model-value="onSelectPlatformGroup"
            >
              <template #button-content="{ options }">
                <span v-if="isEmpty(options)" class="agfg-GroupsSelectEmptyLabel">
                  {{ $t('user_details.select_groups') }}
                </span>
              </template>
            </AppSelect>
          </div>
        </FormFieldNext>
      </div>
    </div>
    <div v-if="groupsToAdd.sourcePlatformGroupIds.length">
      <div class="agfg-Group">
        <div ref="groupsSelectField" class="agfg-FieldItem">
          <FormFieldNext :label="t('objective.select_group')">
            <AppSelect
              ref="targetPluginGroupSelect"
              :loading="areGroupsLoading"
              :model-value="groupsToAdd.targetPluginGroupId"
              :offset="[0, -40]"
              :options="groups"
              :placeholder="t('user_details.select_groups')"
              :search-function="getGroupsList"
              boundary="scrollParent"
              class="agfg-GroupsSelect"
              dropdown-search
              item-label="name"
              item-value="id"
              theme="no-shadow-next light"
              type="default-next"
              @opened="getGroups"
              @update:model-value="onSelectGroup"
              @update:options="groups = $event"
            >
              <template #button-content="{ option }">
                <span v-if="!option" class="agfg-GroupsSelectEmptyLabel">
                  {{ $t('user_details.select_groups') }}
                </span>
                <span v-else class="agfg-GroupsSelectEmptyLabel">{{ option.name }}</span>
              </template>
            </AppSelect>
          </FormFieldNext>
        </div>
      </div>
    </div>
    <div v-if="groupsToAdd.sourcePlatformGroupIds.length">
      <FormFieldNext :label="t('users.select_import_options')">
        <AppRadioGroup
          :model-value="groupsToAdd.keepSynced"
          :options="syncImportOptions"
          :type="APP_RADIO_GROUP_TYPES.BLOCKS"
          name="sync-type"
          @update:model-value="onSelectSync"
        />
      </FormFieldNext>
    </div>
  </div>
</template>

<script setup>
import { useIntersectionObserver, useResizeObserver } from '@vueuse/core'
import { cloneDeep, isEmpty } from 'lodash'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import GlobalGroupsApiHandler from '@/api/global-groups'
import PlatformApiHandler from '@/api/platform'
import { RADIO_GROUP_TYPES as APP_RADIO_GROUP_TYPES } from '@/utils/components-configurations/app-radio-group'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { GLOBAL_GROUPS_PERMISSIONS } from '@/utils/global-groups'

import FormFieldNext from '@/components/form/FormFieldNext'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

const props = defineProps({
  groupsToAdd: {
    type: Object,
    required: true
  },
  workspaceId: {
    type: Number,
    required: true
  }
})

const sourcePlatformGroupRef = ref(null)
const workspaceRef = ref(null)
const targetPluginGroupSelect = ref(null)

const groupReference = ref(null)
const groupReferenceIsVisible = ref(false)

useIntersectionObserver(groupReference, ([{ isIntersecting }], observerElement) => {
  groupReferenceIsVisible.value = isIntersecting
})

watch(
  () => groupReferenceIsVisible,
  newValue => {
    if (!newValue) {
      sourcePlatformGroupRef.value.hideDropdown()
      workspaceRef.value.hideDropdown()
      targetPluginGroupSelect.value.hideDropdown()
    }
  },
  { immediate: true }
)
const getUpdatedList = newItem => {
  const updatedData = cloneDeep(props.groupsToAdd)
  return {
    ...updatedData,
    ...newItem
  }
}

const emit = defineEmits(['update:groups-to-add'])

const platformGroups = ref([])

const getPlatformGroups = async (searchString = null) => {
  const api = new PlatformApiHandler()

  try {
    return await api.getPlatformGroups({
      searchString,
      [REQUEST_ENTITY_KEYS.LIMIT]: 10
    })
  } catch (error) {
    handleError({ error })
  }
}
const fetchGroups = async () => {
  platformGroups.value = await getPlatformGroups()
}

const onSelectPlatformGroup = sourcePlatformGroupIds => {
  const newItem = {
    ...props.groupsToAdd,
    sourcePlatformGroupIds
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:groups-to-add', updatedList)
}
const onSelectGroup = targetPluginGroupId => {
  const newItem = {
    ...props.groupsToAdd,
    targetPluginGroupId
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:groups-to-add', updatedList)
}

const onSelectSync = keepSynced => {
  const newItem = {
    ...props.groupsToAdd,
    keepSynced
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:groups-to-add', updatedList)
}

const groups = ref([])
const areGroupsLoading = ref(false)
const groupsDropdownYOffset = ref(0)
const sourcePlatformDropdownYOffset = ref(0)
const groupsSelectField = ref(null)
const sourcePlatformSelectField = ref(null)

useResizeObserver(groupsSelectField, entries => {
  // calculate offset dynamically for dropdown | offset should be negative to show dropdown above the field
  // thats why calculate button height and use -height

  const entry = entries[0]
  const { height } = entry.contentRect
  groupsDropdownYOffset.value = -height
})
useResizeObserver(sourcePlatformSelectField, entries => {
  // calculate offset dynamically for dropdown | offset should be negative to show dropdown above the field
  // thats why calculate button height and use -height

  const entry = entries[0]
  const { height } = entry.contentRect
  sourcePlatformDropdownYOffset.value = -height
})

const getGroupsList = async (searchString = null) => {
  areGroupsLoading.value = true
  const groupsApi = new GlobalGroupsApiHandler()

  try {
    const data = await groupsApi.getGlobalGroups({
      workspaceIds: [props.workspaceId],
      searchString
    })

    return data.filter(group => group.permissions.includes(GLOBAL_GROUPS_PERMISSIONS.ADD_USERS))
  } catch (error) {
    handleError({ error })
  } finally {
    areGroupsLoading.value = false
  }
}
const getGroups = async () => {
  if (isEmpty(groups.value)) {
    groups.value = await getGroupsList(null)
  }
}

const { t } = useI18n()
const syncImportOptions = [
  {
    icon: 'import-users-large',
    label: t('action.import_users'),
    message: t('users.import_users_description'),
    value: false
  },
  {
    icon: 'replace_jira-large',
    label: t('users.link_jira_groups'),
    message: t('users.link_jira_groups_description'),
    value: true
  }
]
</script>
<script>
//eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AddLinkedUserFieldGroup'
})
</script>

<style lang="scss" scoped>
$group-gap: 20px;
$items-count: 1;

.agfg-Group {
  font-family: $system-ui;
  position: relative;
  flex: 1 0 auto;
  display: flex;
  align-items: flex-start;
  gap: $group-gap;
  width: var(--items-width, 100%);
}
// .agfg-GroupsSelectOption_Label {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   &:not(.agfg-GroupsSelectOption-with-sync &) {
//     padding-left: 2px;
//   }
//
//   .agfg-GroupsSelectOption-with-sync & {
//     padding-left: 6px;
//   }
// }
// .agfg-FieldContainer {
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
// }
.agfg-GroupsSelect {
  :deep(.as-AppDroplistButton_Content) {
    padding: 6px 0 6px 6px;
  }
}
.agfg-FieldItem {
  width: calc((100% - (#{$group-gap} * (#{$items-count} - 1))) / #{$items-count});
  position: relative;
  --gap: 0;
  --label-bottom-offset: 6px;
}

.agfg-GroupsSelectEmptyLabel {
  padding-left: 2px;
}
</style>
