<template>
  <div class="bs-BillingPage">
    <div class="bs-BillingPage_Content">
      <PageContentHeader
        :level="3"
        :title="t('settings.billing.header')"
        max-width="full-next"
        no-margin
        style="--align-items: flex-start; --padding-bottom: 28px"
      >
        <InviteUsersToWorkspaceModal @success="fetchBillingPlanDetails" />
      </PageContentHeader>
      <div class="bs-MainContent">
        <div class="bs-Wrapper">
          <div class="bs-SubscriptionHeader">
            <div>
              <div class="bs-SubscriptionCellHeader">{{ t('subscription.current_plan') }}</div>
              <div class="bs-SubscriptionCellContent">
                <SkeletonItem
                  v-if="isLoading"
                  border-radius="24px"
                  class="bs-SubscriptionCellContent_Loader"
                  color="var(--dark-2)"
                  height="24px"
                  width="120px"
                />
                <span v-else>{{ getSubscriptionName }}</span>
              </div>

              <AppButton
                v-if="
                  !isLoading &&
                  ((!subscriptionStatus.isTrial && !subscriptionStatus.isCanceled) ||
                    (subscriptionStatus.isTrial && subscription.paymentMethod))
                "
                class="bs-SubscriptionHeader_Btn"
                remove-padding
                type="link-next"
                @click="isChoosePlanDialog = true"
              >
                {{ t('subscription.manage_plan') }}
              </AppButton>
            </div>
            <div>
              <div class="bs-SubscriptionCellHeader">{{ t('field.status.title') }}</div>
              <div class="bs-SubscriptionStatus">
                <SkeletonItem
                  v-if="isLoading"
                  border-radius="24px"
                  color="var(--dark-2)"
                  height="24px"
                  width="60px"
                />
                <StatusBadge
                  v-else
                  :data="statusData"
                  no-border
                  style="--padding: 4px 6px"
                  type="default-next"
                >
                  {{ statusData.label }}
                </StatusBadge>
              </div>
            </div>
            <div>
              <div class="bs-SubscriptionCellHeader">{{ t('common.users') }}</div>
              <div class="bs-SubscriptionCellDescription">
                <SkeletonItem
                  v-if="isLoading"
                  border-radius="24px"
                  color="var(--dark-2)"
                  height="24px"
                  width="60px"
                />
                <span v-else-if="subscription.usersCount">
                  {{ getUserCount }}
                </span>
              </div>
              <AppButton
                v-if="isAnnual && !isLoading && !subscriptionStatus.isCanceled"
                class="bs-SubscriptionHeader_Btn"
                remove-padding
                type="link-next"
                @click="onUpgradeTier"
              >
                {{ t('subscription.manage_tier') }}
              </AppButton>
            </div>
            <div>
              <div class="bs-SubscriptionCellHeader">{{ t('subscription.next_payment') }}</div>
              <div class="bs-SubscriptionCellDescription">
                <SkeletonItem
                  v-if="isLoading"
                  border-radius="24px"
                  color="var(--dark-2)"
                  height="24px"
                  width="60px"
                />
                <span v-else>
                  {{ getBillingValue(getNextPaymentAmount) }}
                </span>
              </div>
            </div>
            <div>
              <div class="bs-SubscriptionCellHeader">{{ t('subscription.billing_cycle') }}</div>
              <div class="bs-SubscriptionCellDescription">
                <SkeletonItem
                  v-if="isLoading"
                  border-radius="24px"
                  color="var(--dark-2)"
                  height="24px"
                  width="60px"
                />
                <span v-else>
                  {{ getBillingValue(getBillingCycle) }}
                </span>
              </div>
            </div>

            <div
              v-if="
                !subscriptionStatus.isTrial &&
                !subscriptionStatus.isCanceled &&
                !subscriptionStatus.toBeCanceled
              "
            >
              <div class="bs-SubscriptionCellHeader">{{ t('subscription.next_payment_date') }}</div>
              <div class="bs-SubscriptionCellDescription">
                <SkeletonItem
                  v-if="isLoading"
                  border-radius="24px"
                  color="var(--dark-2)"
                  height="24px"
                  width="60px"
                />
                <span v-else-if="subscription.upcomingInvoice?.nextPaymentDate">
                  {{ getDateFormatted(subscription.upcomingInvoice.nextPaymentDate) }}
                </span>
                <span v-else>
                  {{ getBillingValue() }}
                </span>
              </div>
            </div>
            <div v-else>
              <div class="bs-SubscriptionCellHeader">{{ t('subscription.expiration_date') }}</div>
              <div class="bs-SubscriptionCellDescription">
                <SkeletonItem
                  v-if="isLoading"
                  border-radius="24px"
                  color="var(--dark-2)"
                  height="24px"
                  width="60px"
                />
                <span v-else>
                  {{
                    getBillingValue(
                      subscription.cancelAt && getDateFormatted(subscription.cancelAt)
                    )
                  }}
                </span>
              </div>
            </div>
          </div>

          <template v-if="!isLoading">
            <div class="bs-SubscriptionHeader_Additional">
              <template v-if="!subscriptionStatus.isTrial">
                <PromoSubscription
                  v-if="subscriptionStatus.toBeCanceled"
                  :button-text="$t('subscription.renew')"
                  :loading="loaders.renew"
                  :title="$t('subscription.subscription_canceled')"
                  button-icon="restore"
                  icon-name="info-primary"
                  @action-click="onRenewClick"
                />
                <PromoSubscription
                  v-else-if="subscriptionStatus.isCanceled"
                  :button-text="$t('subscription.choose_plan')"
                  :title="$t('subscription.is_canceled')"
                  @action-click="isChoosePlanDialog = true"
                />
                <PromoSubscription
                  v-else-if="subscription.scheduledDowngrade"
                  :button-text="$t('action.cancel')"
                  :loading="loaders.downgrade"
                  :title="
                    $t('subscription.subscription_will_be_downgraded', {
                      name: subscription.scheduledDowngrade?.planName,
                      cycle: subscription.scheduledDowngrade?.cycle
                    })
                  "
                  button-icon=""
                  icon-name="info-error"
                  type="error"
                  @action-click="onCancelDowngrade"
                />

                <PromoSubscription
                  v-else-if="!isAnnual && !subscriptionStatus.isEnterprise"
                  :button-text="$t('subscription.switch_to_annual')"
                  :title="$t('subscription.try_annual')"
                  icon-name="info-primary"
                  @action-click="onSwitchToAnnual"
                />

                <PromoSubscription
                  v-else-if="isAnnual && subscriptionStatus.isOverLimitedUsers"
                  :button-text="$t('subscription.upgrade_tier')"
                  :title="
                    $t(
                      'subscription.upgrade_your_tier_description',
                      { day: licenseStatusDaysLeft },
                      licenseStatusDaysLeft
                    )
                  "
                  button-icon="edit-next-black"
                  icon-name="info-error"
                  type="error"
                  @action-click="onUpgradeTier"
                >
                  <template #additional-button>
                    <AppButton
                      class="bs-DeleteUserBtn"
                      icon="delete-next"
                      type="link-next"
                      @click="openDeleteUserModal"
                    >
                      {{ $t('groups.unlink.delete_users.label') }}
                    </AppButton>
                  </template>
                </PromoSubscription>
                <PromoSubscription
                  v-else-if="isAnnual && subscriptionStatus.isBlocked"
                  :button-text="$t('subscription.upgrade_tier')"
                  :title="$t('subscription.tier_limit')"
                  button-icon="edit-next-black"
                  icon-name="info-error"
                  type="error"
                  @action-click="onUpgradeTier"
                >
                  <template #additional-button>
                    <AppButton
                      class="bs-DeleteUserBtn"
                      icon="delete-next"
                      type="link-next"
                      @click="openDeleteUserModal"
                    >
                      {{ $t('groups.unlink.delete_users.label') }}
                    </AppButton>
                  </template>
                </PromoSubscription>

                <PromoSubscription
                  v-else-if="subscriptionStatus.isPastDue"
                  :title="$t('subscription.payment_incomplete')"
                  :with-right-side="false"
                  icon-name="info-error"
                  type="error"
                />
              </template>

              <div>
                <PaymentMethods :customer-info="subscription" />
              </div>
              <div v-if="!subscriptionStatus.isTrial">
                <InvoiceHistory />
              </div>
            </div>

            <div class="bs-SubscriptionTiers">
              <SubscriptionTiers
                v-if="subscriptionStatus.isTrial && !isLoading"
                :status-data="statusData"
                :subscription="subscription"
                @on-contact-us="onContactUs"
                @on-subscribe="onSubscribe"
              />
            </div>
          </template>
        </div>
      </div>
    </div>

    <portal to="modal-windows">
      <ManagePlanDialog
        v-model:chosen-subscription="managePlanPayload"
        v-model:show="isShowManagePlan"
        :current-subscription="subscription"
        with-switch-plan
      />
      <BillingContactUsDialog v-model:show="isShowBillingContactDialog" />
      <ManagePlanDialog
        v-model:show="isShowManageTierPlan"
        :current-subscription="subscription"
        is-annual-only
        with-upgrade-tier
      />
      <ManagePlanDialog
        v-model:show="isShowManagePlanAnnualOnly"
        :current-subscription="subscription"
        is-annual-only
      />

      <SubscriptionUpdatedDialog
        v-model:is-show="isShowSubscriptionUpdatedDialog"
        :with-reload-page="false"
      />
    </portal>
    <ChoosePlanDialog
      v-model:show="isChoosePlanDialog"
      :status-data="statusData"
      :subscription="subscription"
    />
    <UserDeleteDialog v-model:show="isShowUserDeleteDialog" :subscription="subscription" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import BillingApiHandler from '@/api/billing'
import { useFetchRoles } from '@/composables/plugin-users'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { EVENT_KEYS, EVENT_NAMES } from '@/tracking/gtm-helpers'
import { gtmTracker } from '@/tracking/gtm-tracking'
import {
  ALL_SUBSCRIPTION_STATUSES,
  YEAR,
  getAmount,
  getDateFormatted,
  useManagePlanDialog
} from '@/utils/billing-settings'
import { handleError } from '@/utils/error-handling'
import { PAYMENT_STATUS } from '@/utils/query-parameters'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'
import BillingContactUsDialog from '@web-app/components/billing-settings/BillingContactUsDialog'
import ChoosePlanDialog from '@web-app/components/billing-settings/ChoosePlanDialog'
import InvoiceHistory from '@web-app/components/billing-settings/InvoiceHistory'
import ManagePlanDialog from '@web-app/components/billing-settings/ManagePlanDialog'
import PaymentMethods from '@web-app/components/billing-settings/PaymentMethods'
import SubscriptionTiers from '@web-app/components/billing-settings/SubscriptionTiers'
import SubscriptionUpdatedDialog from '@web-app/components/billing-settings/SubscriptionUpdatedDialog'
import UserDeleteDialog from '@web-app/components/billing-settings/UserDeleteDialog'
import InviteUsersToWorkspaceModal from '@web-app/components/InviteUsersToWorkspaceModal'

import PromoSubscription from '@/components/AppAlert'
import StatusBadge from '@/components/objectives/StatusBadge'
import AppButton from '@/components/ui/AppButton/AppButton'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

defineOptions({
  name: 'BillingPage'
})

const store = useStore()
const { t } = useI18n()

const isShowManageTierPlan = ref(false)
const isShowManagePlanAnnualOnly = ref(false)
const isShowUserDeleteDialog = ref(false)
const roles = ref([])

const fetchRoles = async () => {
  roles.value = await useFetchRoles()
}

const isLoading = computed(() => store.state.system.billingPlanDetails.loading)
const subscription = computed(
  () =>
    store.state.system.billingPlanDetails.data || {
      upcomingInvoice: {}
    }
)

const getSubscriptionName = computed(() => {
  return subscription.value.name?.toLowerCase().includes('enterprise')
    ? 'Enterprise'
    : subscription.value.name
})

const getNextPaymentAmount = computed(() => {
  return getAmount(subscription.value.upcomingInvoice?.nextPaymentAmount)
})

// const userSettings = computed(() => store.state.system.userData)

const fetchBillingPlanDetails = async ({ withLoading } = {}) => {
  await store.dispatch('system/fetchBillingPlanDetails', { withLoading })
}

const SUCCESS = 'success'
const route = useRoute()
const router = useRouter()
const isShowSubscriptionUpdatedDialog = ref(false)

const updateOverlimitStatus = async () => {
  try {
    const BillingApi = new BillingApiHandler()
    await BillingApi.refreshSubscriptionStatus()
  } catch (error) {
    handleError({ error })
  }
}
onMounted(async () => {
  gtmTracker.logEvent(EVENT_NAMES.SUBSCRIBE_VIEW, {
    [EVENT_KEYS.PAGE_URL]: window?.location?.href || route.fullPath,
    [EVENT_KEYS.PAGE_NAME]: ROUTE_NAMES.SETTINGS_BILLING
  })

  fetchRoles()
  await fetchBillingPlanDetails()
  // if (!userSettings.value) {
  //   store.dispatch('system/fetchInitData', {})
  //   fetchBillingPlanDetails()
  // }
  if (route.query[PAYMENT_STATUS] === SUCCESS) {
    isShowSubscriptionUpdatedDialog.value = true
    router.replace({ query: {} })
  }
  if (subscriptionStatus.value.isOverLimitedUsers) {
    await updateOverlimitStatus()
    await fetchBillingPlanDetails({ withLoading: false })
  }
})

const openDeleteUserModal = () => {
  isShowUserDeleteDialog.value = true

  tracker.logEvent('start overlimit user removal', {
    category: EVENT_CATEGORIES.BILLING
  })
}

const isChoosePlanDialog = ref(false)

const subscriptionStatus = computed(() => store.getters['system/subscriptionStatuses'])

const statusData = computed(
  () =>
    ALL_SUBSCRIPTION_STATUSES.find(
      status => status.value === subscription.value.statusId?.toString()
    ) || ALL_SUBSCRIPTION_STATUSES[6]
)

const loaders = ref({
  renew: false,
  downgrade: false
})

const onRenewClick = async () => {
  try {
    loaders.value.renew = true
    const BillingApi = new BillingApiHandler()
    await BillingApi.renewSubscription()
    await fetchBillingPlanDetails()
    tracker.logEvent('subscription renewed', {
      category: EVENT_CATEGORIES.BILLING
    })
  } catch (error) {
    handleError({ error })
  } finally {
    loaders.value.renew = false
  }
}

const isAnnual = computed(() => subscription.value.period === YEAR)
const getBillingCycle = computed(() =>
  isAnnual.value ? t('subscription.annually') : t('subscription.monthly')
)

const onSwitchToAnnual = () => {
  isShowManagePlanAnnualOnly.value = true
  tracker.logEvent('requested switch to annual', {
    category: EVENT_CATEGORIES.BILLING
  })
}

const onUpgradeTier = () => {
  isShowManageTierPlan.value = true
}
const licenseStatusDaysLeft = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.LICENSE_STATUS_DAYS_LEFT]
)

const onCancelDowngrade = async () => {
  try {
    loaders.value.downgrade = true
    const BillingApi = new BillingApiHandler()
    await BillingApi.cancelDowngrade()

    tracker.logEvent('downgrade subscription cancelled', {
      category: EVENT_CATEGORIES.BILLING,
      plan: subscription.value.scheduledDowngrade?.planName.toLowerCase()
    })

    await fetchBillingPlanDetails()
  } catch (error) {
    handleError({ error })
  } finally {
    loaders.value.downgrade = false
  }
}

const getUserCount = computed(() => {
  const isMaxUsersCount = subscription.value.maxUsersCount
    ? `/ ${subscription.value.maxUsersCount}`
    : ''

  return isAnnual.value
    ? `${subscription.value.usersCount} ${isMaxUsersCount}`
    : subscription.value.usersCount
})

const DELIMITER = '--'
const getBillingValue = value => (value ? value : DELIMITER)

const licenseStatusId = computed(
  () => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.LICENSE_STATUS_ID]
)

const {
  onSubscribe,
  onContactUs,
  isShowBillingContactDialog,
  isShowManagePlan,
  managePlanPayload
} = useManagePlanDialog({
  subscription: subscription.value,
  licenseStatusId: licenseStatusId.value
})
</script>

<style lang="scss" scoped>
.bs-MainContent {
  display: flex;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  font-family: $system-ui;
  padding-bottom: $page-bottom-padding;

  --label-color: $dark-2;
}

.bs-SubscriptionHeader {
  display: grid;
  grid-template-columns: 154fr 149fr repeat(4, 130fr);
  gap: 20px;
  padding: 32px;
  border-radius: $border-radius-lg-next;
  margin: 0 auto 32px auto;
  position: relative;
  z-index: 1;
  background: $dark-1;

  &_Btn {
    font-weight: fw('semi-bold');
    color: $blue-9;
  }
  &_Additional {
    margin: -113px 0 0 0;
    border: 2px solid $grey-2-next;
    border-radius: $border-radius-lg-next;
    padding: 111px 32px 32px 32px;
    display: grid;
    gap: 32px;
  }
}
.bs-SubscriptionCellHeader {
  color: $grey-1-next;
  font-size: $fs-12;
  font-style: normal;
  font-weight: fw('bold');
  line-height: 16px;
}
.bs-SubscriptionCellContent {
  color: $white;
  font-size: $fs-20;
  font-style: normal;
  font-weight: fw('semi-bold');
  line-height: 28px;
  margin: 5px 0 0 0;
  &_Loader {
    margin: 10px 0 0 0;
  }
}
.bs-SubscriptionCellDescription {
  color: $white;
  font-size: $fs-14;
  font-style: normal;
  font-weight: fw('semi-bold');
  line-height: 20px;
  margin: 10px 0 0 0;
}
.bs-SubscriptionStatus {
  margin: 10px 0 0 0;
}
.bs-BillingPage {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  width: 100%;
}

.bs-BillingPage_Content {
  width: 100%;
  max-width: 1060px;
}

.bs-Wrapper {
  width: 100%;
}

.bs-DeleteUserBtn {
  color: $grade-low-color-next;
  font-size: $fs-14;
  line-height: 20px;
}

.bs-SubscriptionTiers {
  margin-top: 24px;
}
</style>
