<template>
  <div
    v-tippy="{
      content: showTooltip ? workspace.name : null,
      placement: 'right'
    }"
    class="wso-Option"
  >
    <slot name="icon">
      <WorkspaceIcon v-if="workspace.key" :option="workspace" :with-lock-icon="!hideLockIcon" />
    </slot>
    <span class="wso-Option_Label oboard-truncated-text">
      <slot name="label">
        {{ workspace.name }}
      </slot>
    </span>
    <span v-if="showBadge" class="wso-Option_Badge">
      <slot name="badge">
        {{ $t('badge.added') }}
      </slot>
    </span>
  </div>
</template>

<script setup>
import { has, isEmpty } from 'lodash'

import WorkspaceIcon from '@/components/workspaces/WorkspaceIcon'

defineOptions({
  name: 'WorkspaceSelectOption'
})

defineProps({
  workspace: {
    type: Object,
    required: true,
    validator: v => {
      return !isEmpty(v) && has(v, 'key') && has(v, 'name')
    }
  },

  hideLockIcon: {
    type: Boolean
  },

  showBadge: {
    type: Boolean
  },

  showTooltip: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
.wso-Option {
  display: flex;
  align-items: center;
  gap: var(--option-gap, 8px);
  max-width: 100%;
  width: 100%;
}

.wso-Option_Label {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
}

.wso-Option_Badge {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  color: $grey-1-next;
  margin-left: auto;
}
</style>
