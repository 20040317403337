<template>
  <AppModal
    :show="show"
    :title="t('settings.synchronization.new_custom_sync_settings')"
    class="ss-ModalWrapper"
    size="md"
    @on-close="onCloseModal"
  >
    <div class="ss-ModalContent">
      <FormFieldNext>
        <template #label>
          <div class="ss-ContentFieldsLabel">
            {{ t('settings.synchronization.projects') }}
          </div>
        </template>

        <AppSelect
          v-model="projectConfiguration.projectId"
          :hidden-items="hiddenItems"
          :n-selected-label="t('settings.synchronization.select_project')"
          :offset="[0, '-100%']"
          :options="projects"
          :search-function="value => localSearch({ value, options: projects })"
          append-to=".ss-ModalContent_Select"
          class="ss-ModalContent_Select"
          dropdown-search
          hide-selected-items-in-dropdown
          item-label="name"
          item-value="key"
          multi
          show-all-selected
          show-selected-options-inside
          theme="no-shadow-next light"
          type="default-next"
        >
          <template #button-content>
            <template v-if="isEmptyProjectConfiguration">
              {{ t('settings.synchronization.select_project') }}
            </template>
          </template>
        </AppSelect>
      </FormFieldNext>

      <div v-if="!isEmptyProjectConfiguration" class="ss-ModalContent_Fields">
        <SyncFieldsSelect
          v-model:value="projectConfiguration.startDateField"
          :fields="fields"
          :filed-type="FIELD_TYPES.START_DATE"
          :hidden-items="[isExistEndDate ? projectConfiguration.endDateField : '']"
          :loading="loading"
        />

        <SyncFieldsSelect
          v-model:value="projectConfiguration.endDateField"
          :fields="fields"
          :filed-type="FIELD_TYPES.END_DATE"
          :hidden-items="[isExistStartDate ? projectConfiguration.startDateField : '']"
          :loading="loading"
        />
        <SyncTypes
          v-if="isExistStartDate || isExistEndDate"
          v-model:keepValue="projectConfiguration.keepValue"
          v-model:syncDirection="projectConfiguration.syncDirection"
          :data="syncTypes"
        />
      </div>
    </div>

    <template #footer>
      <div class="ss-ModalContent_Footer">
        <AppButton type="ghost-next" @click="onCloseModal">
          {{ t('action.cancel') }}
        </AppButton>
        <AppButton
          :disable="isDisabled || loading"
          :loading="loading"
          type="primary-next"
          @click="addProjects"
        >
          {{ t('action.create') }}
        </AppButton>
      </div>
    </template>
  </AppModal>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { localSearch } from '@/utils/objectives'
import { FIELD_TYPES } from '@/utils/sync-settings'

import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppModal from '@/components/ui/AppModal/AppModal'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import SyncFieldsSelect from '@jira/components/settings/SyncFieldsSelect'
import SyncTypes from '@jira/components/settings/SyncTypes/SyncTypes'

import { useSyncTypes, DEFAULT_PROJECT_CONFIGURATION } from './composable'

const { t } = useI18n()
const { syncTypes } = useSyncTypes()

const props = defineProps({
  show: {
    type: Boolean
  },
  projects: {
    type: Array,
    default: () => []
  },
  configuredProjects: {
    type: Array,
    default: () => []
  },
  fields: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits({
  'update:show': null,
  'add-projects': null
})

const onCloseModal = () => {
  emit('update:show', false)
  projectConfiguration.value = { ...DEFAULT_PROJECT_CONFIGURATION }
}

const projectConfiguration = ref({ ...DEFAULT_PROJECT_CONFIGURATION })
const hiddenItems = computed(() => props.configuredProjects.map(item => item.projectId))
const isEmptyProjectConfiguration = computed(() => isEmpty(projectConfiguration.value.projectId))

const isDisabled = computed(() => {
  return (
    !projectConfiguration.value.projectId.length ||
    (isExistStartDate.value || isExistEndDate.value
      ? !projectConfiguration.value.syncDirection
      : false)
  )
})

const addProjects = () => {
  const syncDirection =
    !projectConfiguration.value.startDateField && !projectConfiguration.value.endDateField
      ? 0
      : projectConfiguration.value.syncDirection

  const keepValue =
    !projectConfiguration.value.startDateField && !projectConfiguration.value.endDateField
      ? false
      : projectConfiguration.value.keepValue
  emit('add-projects', {
    syncDirection,
    keepValue,
    projectConfiguration
  })
  onCloseModal()
}

const isExistStartDate = computed(() => {
  return projectConfiguration.value.startDateField?.length
})

const isExistEndDate = computed(() => {
  return projectConfiguration.value.endDateField?.length
})
</script>

<style lang="scss" scoped>
.ss-ModalContent_Footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
}
.ss-ModalContent_Select {
  :deep(.as-AppDroplistButton) {
    background: $white;
    color: $dark-3;
    &:hover {
      background: $white;
    }
  }
}
.ss-ModalContent_Fields {
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  gap: 21px;
  max-width: 100%;
}
.ss-ContentFieldsLabel {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}
</style>

<style lang="scss">
.ss-ModalWrapper {
  &.o-modal-sm > .o-modal-content-wrapper > .o-modal-content {
    width: 516px;
  }
  .o-modal-header {
    padding: 40px 40px 20px 40px;
  }

  div.o-modal-body {
    padding: 0 40px;
  }

  .o-modal-footer {
    padding: 20px 40px 40px;
  }
}
</style>
