<template>
  <div class="dca-Cell">
    <OwnerFieldOption v-if="item" :option="item" id-key="ownerId" label-key="ownerName">
      <template #label>
        <a
          v-if="clickable && item.ownerId"
          :href="getAtlassianUserUrl(item.ownerId)"
          class="dca-Cell_Link oboard-truncated-text"
          target="_blank"
        >
          {{ item.ownerName }}
        </a>
        <span v-else class="dca-UserName-Text oboard-truncated-text">{{ item.ownerName }}</span>
      </template>
    </OwnerFieldOption>
  </div>
</template>
<script setup>
import { getAtlassianUserUrl } from '@jira/util'

import OwnerFieldOption from '@/components/form/OwnerFieldOption'

defineProps({
  item: {
    type: Object,
    required: true
  },

  clickable: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
.dca-Cell {
  display: flex;
  align-items: center;
}

.dca-UserName-Text {
  padding: 0 10px 0 0;
}

.dca-Cell_Link {
  color: $regular-text;
}
</style>
