<template>
  <span
    :id="id || undefined"
    :class="{
      'csi-Wrapper-empty': !isItemsExist,
      'csi-Wrapper-no-interaction': !userHasUpdateAccess
    }"
    class="csi-Wrapper"
  >
    <span
      v-if="isItemsExist"
      v-tippy="{
        content: selectedItemsList,
        placement: 'bottom-start',
        theme: 'word-break translucent-next'
      }"
      :title="showNativeTooltip ? selectedItemsList : null"
      class="csi-List"
    >
      <slot
        :active="active"
        :item="firstItem"
        :user-has-read-access="userHasReadAccess"
        :user-has-update-access="userHasUpdateAccess"
        name="first-item"
      >
        <CellSelectItem
          :active="active"
          :user-has-read-access="userHasReadAccess"
          :user-has-update-access="userHasUpdateAccess"
          class="csi-List_Item"
          truncated
        >
          {{ firstItem[itemValue] }}
        </CellSelectItem>
      </slot>

      <CellSelectItem
        v-if="showPlusLabel"
        :active="active"
        :user-has-read-access="userHasReadAccess"
        :user-has-update-access="userHasUpdateAccess"
        class="csi-List_Item csi-List_Item-count"
        truncated
      >
        +{{ count - 1 }}
      </CellSelectItem>
    </span>
    <span
      v-else
      :class="{
        'csi-Plug-with-icon': showPlugAsIcon,
        'csi-Plug-active': active
      }"
      class="csi-Plug"
    >
      <OkrElementsTableCellPlug v-if="showPlugAsIcon" :icon-name="plugIcon" />
    </span>
  </span>
</template>

<script setup>
import { isNull } from 'lodash'
import { computed } from 'vue'

import { PLUG_ICONS } from '@/utils/cell-helper'
import { stringOrNullProp } from '@/utils/prop-validators'

import CellSelectItem from '@/components/objectives/table/cells/CellSelectItem'
import OkrElementsTableCellPlug from '@/components/objectives/table/cells/OkrElementsTableCellPlug'

defineOptions({
  name: 'CellSelectItemsList'
})

const props = defineProps({
  id: {
    type: String,
    default: null
  },

  items: {
    type: Array,
    required: true
  },

  itemValue: {
    type: String,
    required: true
  },

  userHasUpdateAccess: {
    type: Boolean,
    required: true
  },

  userHasReadAccess: {
    type: Boolean,
    default: true
  },

  showNativeTooltip: {
    type: Boolean
  },

  active: {
    type: Boolean
  },

  plugIcon: {
    type: String,
    default: null,
    validator: v => stringOrNullProp(v) && Object.values(PLUG_ICONS).includes(v)
  }
})

const count = computed(() => props.items.length)

const isItemsExist = computed(() => count.value > 0)

const firstItem = computed(() => props.items[0])

const showPlusLabel = computed(() => count.value > 1)

const selectedItemsList = computed(() => {
  return props.items.map(item => item[props.itemValue]).join(', ')
})

const showPlugAsIcon = computed(() => {
  return !isNull(props.plugIcon) && props.userHasUpdateAccess
})
</script>

<style lang="scss" scoped>
.csi-Wrapper {
  max-width: 100%;

  &-empty {
    width: 100%;
    display: flex;
  }
}

.csi-List {
  display: flex;
  gap: 4px;
  max-width: 100%;
}

.csi-Plug {
  height: 24px;
  width: 100%;
  cursor: pointer;

  &-with-icon {
    width: auto;
    opacity: 0; // !!!

    border-radius: $border-radius-sm-next;

    .o-objective-row:hover & {
      opacity: 1;
    }
  }
  /* eslint-disable-next-line vue-scoped-css/no-unused-selector */
  &-with-icon#{&}-active {
    opacity: 1;
    background-color: $dark-2;
    color: $white;
  }

  .csi-Wrapper-no-interaction & {
    cursor: default;
  }
}

.csi-List_Item {
  &-count {
    flex: 1 0 auto;
    text-align: center;
  }
}
</style>
