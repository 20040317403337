import { inject } from 'vue'

import { ENTITIES_ACCESS_LEVELS } from '@/utils/entities-access-levels'

export const useFilterByAccessLevel = () => {
  const isJiraApp = inject('isJiraApp')
  const isWebApp = inject('isWebApp')

  const getEntitiesForPlatform = ({ entities = [] } = {}) => {
    return entities.filter(option => {
      const [condition] = [
        isJiraApp &&
          (option.accessLevel & ENTITIES_ACCESS_LEVELS.JIRA_APP) ===
            ENTITIES_ACCESS_LEVELS.JIRA_APP,
        isWebApp &&
          (option.accessLevel & ENTITIES_ACCESS_LEVELS.WEB_APP) === ENTITIES_ACCESS_LEVELS.WEB_APP
      ].filter(Boolean)

      return condition || false
    })
  }

  return {
    getEntitiesForPlatform
  }
}
