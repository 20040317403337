<template>
  <div class="lt-Loader">
    <div v-for="row in ROWS" :key="row" class="lt-Loader_Row">
      <div class="lt-Cell">
        <SkeletonItem :appearance="SKELETON_APPEARANCES.SQUARE" :size="SM" border-radius="6px" />
        <SkeletonItem :width="row" :size="XS" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LevelsTableLoader'
})
</script>

<script setup>
import {
  SKELETON_APPEARANCES,
  SKELETON_SIZES
} from '@/utils/components-configurations/skeleton-item'

const { XS, SM } = SKELETON_SIZES

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const ROWS = ['75px', '120px', '80px', '260px', '20px']
</script>

<style scoped lang="scss">
.lt-Loader_Row {
  height: 44px;
  padding: 0 $page-right-padding 0 $page-left-padding;
  display: flex;
  align-items: center;
}

.lt-Cell {
  display: flex;
  align-items: center;

  &:first-child {
    gap: 8px;
    flex: 1 1 auto;
  }
}
</style>
