<template>
  <div
    :class="{ 'fig-no-border': noBorder, 'fig-no-divider': noDivider, 'fig-disabled': disabled }"
    class="fig"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormItemsGroup'
})
</script>

<script setup>
defineProps({
  noBorder: {
    type: Boolean
  },

  noDivider: {
    type: Boolean
  },

  disabled: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
$divider-height: 1px;
$divider-offset: 10px;
.fig {
  padding: var(--group-padding, 8px);
  border-radius: $border-radius-md-next;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: calc((#{$divider-offset} * 2) + #{$divider-height}); // * 2 is top offset and bottom offset

  &:not(&-no-border) {
    border: 2px solid $grey-19;
  }

  &:not(&-no-divider) {
    &:deep(> *) {
      &:not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          width: calc(100% - 20px);
          height: $divider-height;
          background-color: $grey-2-next;
          left: 50%;
          transform: translateX(-50%);
          bottom: -#{$divider-offset};
          border-radius: $border-radius-sm-next;
          pointer-events: none;
        }
      }
    }
  }

  &-disabled {
    background: $grey-3-next;
    cursor: not-allowed;

    --disabled-opacity: 1;

    &:deep(.offn-Button) {
      pointer-events: none;
    }

    &:deep(.o-droplist--disabled) {
      pointer-events: all;
      cursor: not-allowed;

      &:active,
      &:focus {
        pointer-events: none;
      }
    }
  }
}
</style>
