<template>
  <template v-if="isShowCreateOrganization || isShowOnboarding">
    <CreateOrganizationModal
      v-if="isShowCreateOrganization"
      is-edit
      @close-create-organization="$emit('close-create-organization')"
    />

    <OnboardingHub v-else-if="isShowOnboarding" @close-onboarding="$emit('close-onboarding')" />

    <OboardLogoLoader />
  </template>

  <template v-else>
    <template v-if="settingsAreLoaded">
      <router-view />

      <InAppNotifications ref="inAppNotifications" />
    </template>
  </template>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapState, mapGetters } from 'vuex'

import { handleError } from '@/utils/error-handling'
import { useSetAppLocale } from '@/utils/general'

import CreateOrganizationModal from '@/components/ui/CreateOrganizationModal/CreateOrganizationModal'
import InAppNotifications from '@/components/ui/InAppNotifications/InAppNotifications'
import OnboardingHub from '@/components/ui/onboarding/OnboardingHub'
import OboardLogoLoader from '@/components/ui/SkeletonLoaders/OboardLogoLoader'

export default defineComponent({
  name: 'MainLayout',
  components: { CreateOrganizationModal, OboardLogoLoader, OnboardingHub, InAppNotifications },
  beforeRouteEnter(to, _, next) {
    next(vm => {
      vm.fetchInitialData(+to.params.workspaceId)
    })
  },

  beforeRouteUpdate(to, from, next) {
    if (to.name !== from.name) {
      // added optional chaining coz while onboarding
      // we add to route query param redirectTo=first-alignment
      // and this triggers beforeRouteUpdate,
      // but we don't show InAppNotifications
      // and all router-view by condition settingsAreLoaded
      this.$refs.inAppNotifications?.getInitialNotifications()
    }
    next()
  },

  props: {
    isShowOnboarding: {
      type: Boolean
    },

    isShowCreateOrganization: {
      type: Boolean
    }
  },

  emits: {
    'close-onboarding': null,
    'close-create-organization': null
  },

  computed: {
    ...mapGetters('system', ['appLocale']),
    ...mapState('system', {
      settingsAreLoaded: state => state.settingsAreLoaded
    })
  },

  methods: {
    ...mapActions('system', {
      fetchInitData: 'fetchInitData'
    }),

    // eslint-disable-next-line vue/no-unused-properties
    async fetchInitialData(initialWorkspaceId) {
      try {
        await this.fetchInitData({
          initialWorkspaceId
        })
        useSetAppLocale(this.appLocale)
      } catch (error) {
        handleError({ error })
      }
    }
  }
})
</script>

<style lang="scss" scoped></style>
