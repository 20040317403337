<template>
  <AppButton
    :class="{ 'otho-Trigger-active': isActionsShow }"
    class="otho-Trigger"
    height="24"
    icon="table-settings-next"
    size="sm"
    type="ghost-next"
    width="24"
    @click="isActionsShow = !isActionsShow"
  />

  <portal to="modal-windows">
    <Modal
      :scrollable-wrapper="false"
      :show="isActionsShow"
      manual-close
      scrollable-content
      size="xs"
      style="--modal-header-padding: 18px 20px 18px 20px"
      @close="isActionsShow = false"
    >
      <template #header>
        <AppTitle :level="3" disable-margin>
          {{ $t('objective.columns_settings.title') }}
        </AppTitle>
      </template>

      <template #modal-body>
        <div class="otho-Content">
          <SlickList
            :distance="10"
            :dragged-settling-duration="1"
            :list="list"
            :lock-offset="['22px', '22px']"
            :transition-duration="200"
            class="otho-Options"
            helper-class="otho-Options_Item-grabbing"
            lock-axis="y"
            lock-to-container-edges
            @sort-end="onSortEnd({ ...$event })"
          >
            <SlickItem
              v-for="(column, index) in filteredList"
              :key="column.name"
              :index="index"
              class="otho-Options_Item"
            >
              <AppIcon class="oth-DragIcon" height="24" icon-name="drag-next" width="24" />
              <AppCheckbox
                :model-value="localEnabledColumns"
                :value="column.name"
                full-height
                size="s"
                @update:model-value="localEnabledColumns = [...$event]"
              >
                {{ getColumnName(column.name) }}
              </AppCheckbox>
            </SlickItem>
          </SlickList>

          <div class="otho-AddFieldWrapper">
            <AppTableCreateButton
              v-tippy="{
                content: isPluginAdmin ? null : $t('custom_fields.disable_create_tooltip'),
                placement: 'top'
              }"
              :disable="!isPluginAdmin"
              full-width
              icon-name="plus-next"
              style="--padding-left: 20px"
              @click="onAddCustomFieldClick"
            >
              {{ $t('custom_fields.add_field') }}
            </AppTableCreateButton>
          </div>

          <div class="otho-Footer">
            <AppButton
              class="otho-Footer_Restore"
              icon="restore"
              remove-padding
              type="link-next"
              @click="restoreDefaults"
            >
              {{ $t('action.restore') }}
            </AppButton>

            <AppButton type="primary-next" @click="save">
              {{ $t('action.done') }}
            </AppButton>
          </div>
        </div>
      </template>
    </Modal>
  </portal>

  <!--  <AppDroplist
    v-model="isActionsShow"
    :dropdown-min-width="220"
    :offset="[0, 0]"
    class="otho-Actions"
    position="bottom-end"
    theme="no-shadow-next light"
  >
    <template #button>
      <AppButton
        :class="{ 'otho-Trigger-active': isActionsShow }"
        class="otho-Trigger"
        height="24"
        icon="restore"
        size="sm"
        type="ghost-next"
        width="24"
      />
    </template>

  </AppDroplist>-->
</template>

<script>
import { isNull } from 'lodash'
import { defineComponent } from 'vue'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'
import { mapGetters, mapState } from 'vuex'

import { useBackSettingsBackButton } from '@/composables/settings-back-button'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { CONFIGURABLE_COLUMNS } from '@/utils/objective-table'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import Modal from '@/components/ui/Modal/Modal'

export default defineComponent({
  name: 'ObjectiveTableHeaderOptions',

  components: {
    AppTableCreateButton,
    AppTitle,
    Modal,
    AppCheckbox,
    AppButton,
    SlickList,
    SlickItem,
    AppIcon
  },

  directives: {
    handle: HandleDirective
  },

  props: {
    columns: {
      type: Array,
      default: () => []
    },

    enabledColumns: {
      type: Array,
      default: () => []
    },

    defaultColumns: {
      type: Array,
      default: () => []
    },

    workspaceId: {
      type: [String, Number],
      required: true
    }
  },

  emits: { 'update:enabled-columns': null, 'update:columns': null },

  setup() {
    const { saveSettingsReferer } = useBackSettingsBackButton()

    const getColumnName = column => {
      const { columnName } = useCustomFieldsHelpers({
        fieldId: column
      })
      return columnName.value
    }

    return {
      getColumnName,
      saveSettingsReferer
    }
  },

  data() {
    return {
      isActionsShow: false,

      localColumns: [...this.columns],
      localEnabledColumns: [...this.enabledColumns]
    }
  },

  computed: {
    ...mapGetters('customFields', {
      columnsIdsByWorkspaceId: 'columnsIdsByWorkspaceId',
      checkIsCustomField: 'isCustomField'
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin
    }),

    currentWorkspaceCustomFieldsIds() {
      return this.columnsIdsByWorkspaceId(this.workspaceId)
    },

    list() {
      return this.localColumns.map((item, index) => {
        return {
          name: item,
          orderValue: index,
          realIndex: index
        }
      })
    },

    filteredList() {
      return this.list.filter(column => {
        const fieldId = column.name
        const isCustomFieldColumn = this.checkIsCustomField({ fieldId })

        if (isCustomFieldColumn) {
          return this.currentWorkspaceCustomFieldsIds.includes(Number(fieldId))
        }

        return true
      })
    }
  },

  watch: {
    isActionsShow(newValue) {
      if (newValue) {
        this.localColumns = [...this.columns]
        this.localEnabledColumns = [...this.enabledColumns]
      }
    }
  },

  methods: {
    onSortEnd({ oldIndex, newIndex }) {
      if (isNull(newIndex) || oldIndex === newIndex) {
        return
      }

      const data = this.filteredList

      const item = data[oldIndex]
      const newPreviousElement = data[newIndex]
      const newRealIndex = newPreviousElement.realIndex

      this.localColumns.splice(newRealIndex, 0, this.localColumns.splice(item.realIndex, 1)[0])
    },

    save() {
      // keep order of enabled columns
      const result = this.localColumns.filter(column => this.localEnabledColumns.includes(column))
      this.$emit('update:enabled-columns', result)
      this.$emit('update:columns', this.localColumns)
      this.isActionsShow = false
    },

    restoreDefaults() {
      this.localColumns = [...CONFIGURABLE_COLUMNS, ...this.currentWorkspaceCustomFieldsIds]
      this.localEnabledColumns = [...this.defaultColumns]
    },

    onAddCustomFieldClick() {
      if (this.isPluginAdmin) {
        this.saveSettingsReferer()
        this.$router.push({
          name: ROUTE_NAMES.SETTINGS_CUSTOM_FIELDS
        })
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/table-modals-helpers';

// eslint-disable-next-line vue-scoped-css/no-unused-selector

.otho-Content {
  font-family: $system-ui;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  height: $modal-header-height;
  position: relative;

  &:before {
    @extend %divider-style;
  }
}

.otho-Options {
  display: grid;
  overflow-y: auto;
  padding: 20px 0;
  @include styled-native-scrollbar();
}

.otho-Options_Item {
  min-height: 44px;
  display: flex;
  align-items: center;
  padding: $table-modals-gap 20px;
  gap: 8px;
  box-sizing: border-box;
  cursor: grab;
  max-width: 350px;
  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  &-grabbing {
    cursor: grabbing;
  }

  @media (any-hover: hover) {
    &:not(&-grabbing):hover {
      background-color: $grey-3-next;
    }
  }
}

.oth-DragIcon {
  flex-shrink: 0;
}

.otho-AddFieldWrapper {
  padding: 10px 0;
  position: relative;

  &:before {
    @extend %divider-style;
  }
}

.otho-Footer {
  position: relative;
  padding: 20px calc($table-modals-gap * 2) 0;
  gap: $table-modals-gap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &:before {
    @extend %divider-style;
  }
  &_Restore {
    color: $dark-3;
  }
}

.otho-Trigger {
  color: $dark-2;
  &-active {
    background-color: $dark-2;
    color: $white;
  }
}
</style>

<style lang="scss">
.otho-Options_Item-grabbing {
  z-index: 10000;
  background-color: $white;
  pointer-events: all !important;
  box-shadow: 0 4px 16px rgba($regular-text, 0.2), 0 1px 2px rgba($regular-text, 0.31);
}
</style>
