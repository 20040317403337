<template>
  <ObjectiveMoveModal
    :disable="isDisable"
    :loading="loading"
    :show="show"
    :title="t('objective.select_group')"
    @close="hideModal"
    @proceed="proceed"
    @prev-step="prevStep"
  >
    <div class="sgm-Subtitle">
      <AppIcon height="24" icon-name="warning-icon" width="24" />
      {{ t('move.select_groups_subtitle') }}
    </div>
    <ObjectiveMoveConflictItems
      :conflict-items="conflicts"
      :select-items="groupOptionsMapped"
      :selected-items="selectedGroups"
      @update:model-value="updateConflict"
    >
      <template #prepend-select-icon="{ selectedItem }">
        <GroupIcon
          v-if="selectedItem"
          :color="selectedItem.color"
          :icon-name="selectedItem.icon"
          class="sgm-PrependIcon"
        />
      </template>

      <template #select-option-label="{ option }">
        <GlobalGroupsSelectOption v-if="option" :group="option" />
      </template>
    </ObjectiveMoveConflictItems>
  </ObjectiveMoveModal>
</template>

<script setup>
import { nextTick, ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { handleError } from '@/utils/error-handling'

import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import GroupIcon from '@/components/global-groups/GroupIcon'
import ObjectiveMoveConflictItems from '@/components/objectives/objective-move/ObjectiveMoveConflictItems'
import ObjectiveMoveModal from '@/components/objectives/objective-move/ObjectiveMoveModal'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

const props = defineProps({
  show: {
    type: Boolean
  },
  formModel: {
    type: Object,
    required: true
  },
  conflicts: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean
  }
})
const { t } = useI18n()
const emit = defineEmits({
  'update:show': null,
  proceed: null,
  'prev-step': null
})
const hideModal = async () => {
  emit('update:show', false)
  await nextTick()
}
const proceed = () => {
  const conflictGroups = props.conflicts.map((conflict, conflictIndex) => {
    return {
      ...conflict,
      idToChange: selectedGroups.value[conflictIndex]
    }
  })
  emit('proceed', conflictGroups)
}
const prevStep = () => {
  emit('prev-step')
}
const areGroupsLoading = ref(false)
const groupOptions = ref([])
const selectedGroups = ref(props.conflicts.map(conflict => conflict.idToChange))

const groupOptionsMapped = computed(() => {
  return [
    // { name: t('objective.clear_group'), id: null, iconName: 'unassigned-owner' },
    ...groupOptions.value
  ]
})
const updateConflict = ({ index, value }) => {
  selectedGroups.value[index] = value
}
const getGroups = async (searchString = null) => {
  const api = new GlobalGroupsApiHandler()
  areGroupsLoading.value = true
  try {
    groupOptions.value = await api.getGroupsForFilter({
      searchString,
      workspaceIds: [props.formModel.workspaceId]
    })
  } catch (error) {
    handleError({ error })
  } finally {
    areGroupsLoading.value = false
  }
}
const isDisable = computed(() => {
  // return false
  return selectedGroups.value.some(group => !group)
})
onMounted(() => {
  getGroups()
})
</script>
<script>
//eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectGroupModal'
})
</script>

<style lang="scss" scoped>
.sgm-Subtitle {
  // TODO: refactor after merge with alignment branch
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #42526e;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 35px;
}
.sgm-PrependIcon {
  margin-right: 8px;
}
</style>
