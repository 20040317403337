<template>
  <div class="slu-SidebarLayout">
    <div :class="{ 'slu-Main-reversed': reversed }" class="slu-Main">
      <div
        :ref="REFERENCES.CONTENT"
        :class="{ [CUSTOM_SCROLLBAR_CLASS]: true }"
        :style="styles[REFERENCES.CONTENT]"
        class="slu-Content"
      >
        <slot />
      </div>
      <div :ref="REFERENCES.SIDEBAR" :style="styles[REFERENCES.SIDEBAR]" class="slu-Sidebar">
        <slot name="sidebar" />
      </div>
    </div>
    <div v-if="!footerIsEmpty" class="slu-Footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar'
import { defineComponent } from 'vue'

import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { CUSTOM_SCROLLBAR_CLASS } from '@/utils/general'
import { slotIsEmpty } from '@/utils/slots'

const REFERENCES = {
  SIDEBAR: 'sidebar',
  CONTENT: 'content'
}

const SCROLLBAR_WIDTH = 10

export default defineComponent({
  name: 'SidebarLayout',

  props: {
    gap: {
      type: Number,
      default: 40,
      validator: value => {
        if (value < SCROLLBAR_WIDTH) {
          console.error(
            `SidebarLayout: gap must be greater than ${SCROLLBAR_WIDTH}, this is the width of the scrollbar`
          )
        }
        return value >= SCROLLBAR_WIDTH
      }
    },

    sidebarWidth: {
      type: String,
      default: '280px'
    },

    reversed: {
      type: Boolean
    }
  },

  data() {
    return {
      psInstances: {
        ...Object.fromEntries(Object.keys(REFERENCES).map(item => [item, null]))
      }
    }
  },

  computed: {
    REFERENCES: () => REFERENCES,
    CUSTOM_SCROLLBAR_CLASS: () => CUSTOM_SCROLLBAR_CLASS,
    SCROLLBAR_WIDTH: () => SCROLLBAR_WIDTH,

    footerIsEmpty() {
      return slotIsEmpty(this.$slots.footer)
    },

    styles() {
      const { gap, sidebarWidth } = this
      if (this.reversed) {
        return {
          [REFERENCES.CONTENT]: {
            padding: `0 ${gap}px 0 0`,
            flex: `0 0 calc(100% - ${sidebarWidth} - ${gap}px)`
          },

          [REFERENCES.SIDEBAR]: {
            padding: `0 ${gap}px 0 ${gap}px`,
            flex: `0 0 calc(${sidebarWidth} + ${gap}px)`
          }
        }
      }
      return {
        [REFERENCES.CONTENT]: {
          padding: `0 ${gap}px 0 ${gap}px`,
          flex: `0 0 calc(100% - ${sidebarWidth})`
        },

        [REFERENCES.SIDEBAR]: {
          padding: `0 ${gap}px 0 0`,
          flex: `0 0 ${sidebarWidth}`
        }
      }
    }
  },

  mounted() {
    Object.entries(REFERENCES).forEach(([key, reference]) => {
      this.psInstances[key] = new PerfectScrollbar(this.$refs[reference], {
        suppressScrollX: true,
        swipeEasing: true
      })
    })
  },

  beforeUnmount() {
    const { psInstances } = this
    Object.keys(psInstances).forEach(instance => {
      if (psInstances[instance]) {
        psInstances[instance].destroy()
        psInstances[instance] = null
      }
    })
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/okr-modal';

.slu-SidebarLayout {
  height: 100%;
  display: grid;
  grid-auto-rows: auto 1fr;
}

.slu-Main {
  display: flex;
  overflow: hidden;

  &-reversed {
    flex-direction: row-reverse;
  }
}

.slu-Content {
  position: relative;
  overflow-y: auto;
}

.slu-Sidebar {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

%scroll-gradient {
  content: '';
  position: sticky;
  height: 26px;
  width: 100%;
  display: block;
  z-index: 3;
  pointer-events: none;
}

.slu-Content,
.slu-Sidebar {
  &:before {
    @extend %scroll-gradient;
    top: 0;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 15%,
      rgba(255, 255, 255, 0.4) 80%,
      rgba(167, 105, 246, 0) 100%
    );
  }
}

.slu-Sidebar {
  &:after {
    @extend %scroll-gradient;
    bottom: 0;

    background: linear-gradient(
      180deg,
      rgba(167, 105, 246, 0) 15%,
      rgba(255, 255, 255, 0.4) 80%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

.slu-Footer {
  padding: 20px calc(v-bind(gap) * 1px);
  margin-top: 16px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    height: 1px;
    width: calc(100% + #{$depth-shift});
    background-color: $grey-2-next;
    left: calc(-1 * #{$depth-shift});
    top: 0;
    pointer-events: none;
  }
}

.slu-Content,
.slu-Sidebar {
  &:deep(.ps__rail-y) {
    width: calc(v-bind(SCROLLBAR_WIDTH) * 1px);
    right: calc(((v-bind(gap) - v-bind(SCROLLBAR_WIDTH)) / 2) * 1px);
    background: transparent;

    &:hover {
      width: calc(v-bind(SCROLLBAR_WIDTH) * 1px);

      .ps__thumb-y {
        width: 8px;
      }
    }

    .ps__thumb-y {
      right: 1px;
      max-width: 8px;
    }
  }
}
</style>
