<template>
  <div class="al-Wrapper">
    <AppTable
      :columns="COLUMNS"
      :data="auditLog"
      :disable-user-select="false"
      :loading="listState.isLoading"
      border-on-last-row
      class="al-Table"
      no-border
      offset-left="var(--page-left-padding)"
      offset-right="var(--page-right-padding)"
      sticky-header
      type="primary-next"
    >
      <template #cell="{ columnKey, item }">
        <template v-if="columnKey === TABLE_COLUMNS_KEYS.DATE">
          {{ getFormattedDate(item.createDate) }}
        </template>

        <template v-if="columnKey === TABLE_COLUMNS_KEYS.USER_NAME">
          <div class="oboard-truncated-text al-NameCell">{{ item.userName }}</div>
        </template>
      </template>

      <template #loading>
        <AuditLogLoader />
      </template>

      <template #footer>
        <InfiniteScrollLoaderNext
          v-if="!listState.isAllItemsLoaded"
          :loading="listState.isLoading"
          @load-more="onLoadMore"
        />
      </template>
    </AppTable>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { dateFormat } from '@/utils/date'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { DEFAULT_LIMIT } from '@/utils/global-groups'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import InfiniteScrollLoaderNext from '@/components/ui/InfiniteScrollLoaderNext'
import AuditLogLoader from '@/components/ui/SkeletonLoaders/AuditLogLoader'
import AppTable from '@/components/ui/Table/AppTable'

defineOptions({
  name: 'AuditLogPage'
})

const auditLog = ref([])
const getAuditLog = async () => {
  if (listState.value.isLoading || listState.value.isAllItemsLoaded) {
    return
  }

  const api = new GlobalGroupsApiHandler()

  listState.value.isLoading = true

  try {
    const result = await api.getAuditLog({
      [REQUEST_ENTITY_KEYS.START_AT]: listState.value.startAt
    })

    if (result.length < DEFAULT_LIMIT) {
      listState.value.isAllItemsLoaded = true
    }

    listState.value[REQUEST_ENTITY_KEYS.START_AT] += DEFAULT_LIMIT

    auditLog.value = [...auditLog.value, ...result]
    listState.value.isLoading = false
  } catch (error) {
    handleError({ error })
  }
}

const { t } = useI18n()

const onLoadMore = async () => {
  if (!listState.value.isLoading) {
    await getAuditLog()
  }
}

const COLUMNS = [
  {
    key: TABLE_COLUMNS_KEYS.DATE,
    title: t('audit_log.date'),
    width: 220
  },
  {
    title: t('roles.user'),
    key: TABLE_COLUMNS_KEYS.USER_NAME,
    width: 150
  },
  {
    title: t('workspaces.action'),
    key: TABLE_COLUMNS_KEYS.TEXT,
    width: 'auto'
  }
]

const getFormattedDate = utcDate => {
  return dayjs(utcDate).utc().format(`HH:mm (UTC), ${dateFormat}`)
}

const listState = ref({
  isLoading: false,
  [REQUEST_ENTITY_KEYS.START_AT]: 0,
  isAllItemsLoaded: false
})

onMounted(() => {
  getAuditLog()
})
</script>

<style lang="scss" scoped>
.al-Wrapper {
  color: $dark-1;
  --head-padding-bottom: 8px;
  --head-padding-top: 8px;
  width: 100%;
  max-width: $page-width-lg;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  margin-top: 32px;

  &:deep(.tb-Row) {
    padding: 12px 0;
    align-items: flex-start;
  }
}

.al-NameCell {
  padding-right: 8px;
}
</style>
