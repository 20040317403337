import UsersApi from '@/api/workspace-users'
import {
  OBJECTIVES_ADD_USERS,
  OBJECTIVES_SET_LEVELS,
  OBJECTIVES_SET_SORTING
} from '@/store/mutation-types'

const state = {
  levels: [],
  users: {}, // used for mentions, { id: object }
  sorting: []
}

const getters = {
  getLevelItem: state => (key, type) => state.levels.find(item => item[key] === type) || {},
  getSortingItem: state => column => state.sorting[column] || {}
}

const actions = {
  setLevels({ commit }, levels) {
    commit(OBJECTIVES_SET_LEVELS, levels)
  },

  async getUsersForMentioning({ commit, state }, { accountIds, searchString, limit, workspaceId }) {
    const unknownUsers = accountIds.filter(userId => !(userId in state.users))

    if (unknownUsers.length > 0) {
      const usersApi = new UsersApi()
      const users = await usersApi.getUsersForMentioning({
        accountIds: unknownUsers,
        searchString,
        limit,
        workspaceId
      })
      commit(OBJECTIVES_ADD_USERS, users)
    }

    return accountIds.reduce((acc, value) => ({ ...acc, [value]: state.users[value] }), {})
  },

  addUsersFromList({ commit }, users) {
    commit(OBJECTIVES_ADD_USERS, users)
  },
  updateSorting({ commit }, sorting) {
    commit(OBJECTIVES_SET_SORTING, sorting)
  }
}

const mutations = {
  [OBJECTIVES_SET_LEVELS](state, levels) {
    state.levels = levels
  },
  [OBJECTIVES_ADD_USERS](state, users) {
    users.forEach(user => (state.users[user.accountId] = user))
  },
  [OBJECTIVES_SET_SORTING](state, sorting) {
    state.sorting = sorting
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
