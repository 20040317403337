<template>
  <div
    :class="{ 'si-SubscriptionItem-active': showLabelCurrentPlan || withoutCurrentPlan }"
    class="si-SubscriptionItem"
  >
    <div class="si-CurrentPlanWrapper">
      <span v-if="showLabelCurrentPlan" class="si-CurrentPlan">
        {{ $t('subscription.current_plan') }}
      </span>
    </div>

    <div>
      <AppTitle :level="3" class="si-SubscriptionItem_Title" disable-margin>
        {{ subscription.name }}
        <StatusBadge
          v-if="subscription.isPopular"
          :data="popularBadge"
          class="si-SubscriptionItem_Badge"
          style="--padding: 4px 6px"
        >
          {{ popularBadge.label }}
        </StatusBadge>
      </AppTitle>
      <span class="si-SubscriptionItem_SubTitle">
        {{ subscription.subTitle }}
      </span>
    </div>
    <div class="si-SubscriptionItem_Price">
      <div class="si-Price">
        <span class="si-Price_Title">{{ subscription.priceWithSign }}</span>
        <div>
          <p class="si-Price_Description">{{ subscription.description }}</p>
          <p class="si-Price_Description">{{ subscription.description2 }}</p>
        </div>
      </div>
    </div>
    <AppDivider class="si-SubscriptionItem_Divider" />
    <div class="si-SubscriptionItem_Features">
      <div v-for="feature in subscription.features" :key="feature" class="si-Features_Item">
        <AppIcon height="24" icon-name="active" width="24" />
        {{ feature }}
      </div>
    </div>
    <AppButton
      :type="getBtnData.btnType"
      class="si-SubscribeBtn"
      size="lg-next"
      @click="onClickBtn(getBtnData.emit)"
    >
      {{ getBtnData.btnText }}
    </AppButton>
    <AppButton
      v-if="
        isShowCancelBtnOnActiveSubscription &&
        subscription.name === currentSubscription.name &&
        currentSubscription.paymentMethod &&
        isSameCycle &&
        isTrial
      "
      class="si-SubscribeBtn"
      size="lg-next"
      type="simple-next"
      @click="onClickBtn(CANCEL)"
    >
      {{ $t('subscription.cancel_subscription') }}
    </AppButton>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { YEAR, ESSENTIAL, MONTH, PROFESSIONAL } from '@/utils/billing-settings'
import { LICENSE_TYPES } from '@/utils/license-types'
import { SUBSCRIPTION_ITEM_EVENTS } from '@web-app/utils/subscription-item/helpers'

import StatusBadge from '@/components/objectives/StatusBadge'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'SubscriptionItem'
})

const { t } = useI18n()

const props = defineProps({
  currentSubscription: {
    type: Object,
    required: true
  },

  subscription: {
    type: Object,
    required: true
  },

  statusData: {
    type: Object,
    required: true
  },

  isCurrentPlan: {
    type: Boolean
  },

  period: {
    type: String,
    required: true
  },

  isSameCycle: {
    type: Boolean
  },

  showLabelCurrentPlan: {
    type: Boolean
  },

  withoutCurrentPlan: {
    type: Boolean
  },

  isShowCancelBtnOnActiveSubscription: {
    type: Boolean
  }
})

const { SUBSCRIBE, CANCEL, RENEW, CONTACT_US } = SUBSCRIPTION_ITEM_EVENTS

const emit = defineEmits([...Object.values(SUBSCRIPTION_ITEM_EVENTS)])

const isCurrentPlanAndSameCycle = computed(() => props.isCurrentPlan && props.isSameCycle)
const popularBadge = {
  label: t('subscription.popular'),
  color: 'var(--white-color)',
  backgroundColor: 'var(--purple)'
}
const isToBeCanceled = computed(
  () => props.statusData.value === LICENSE_TYPES.WEB_APP_TO_BE_CANCELED
)

const store = useStore()
const subscriptionStatus = computed(() => store.getters['system/subscriptionStatuses'])
const isTrial = computed(() => props.statusData.value === LICENSE_TYPES.WEB_APP_TRIAL)

const getBtnData = computed(() => {
  const PRIMARY_NEXT = 'primary-next'
  const SIMPLE_NEXT = 'simple-next'

  const isMonthlyItem = props.period === MONTH
  const isAnnuallyItem = props.period === YEAR

  const isCurrentProfessional = props.currentSubscription.name === PROFESSIONAL
  const isCurrentEssential = props.currentSubscription.name === ESSENTIAL
  const isCurrentEnterprise =
    props.currentSubscription.name !== ESSENTIAL && props.currentSubscription.name !== PROFESSIONAL
  const isCurrentCycleAnnuallyAndCurrentPlan =
    props.currentSubscription.period === YEAR && props.isCurrentPlan
  const isCurrentPlanEssentialAndAnnually =
    isCurrentEssential && props.currentSubscription.period === YEAR && isMonthlyItem

  const isCanceled = props.statusData.value === LICENSE_TYPES.WEB_APP_CANCELED

  const isCurrentPlanAndAnnually = isAnnuallyItem && props.isCurrentPlan
  const isMonthlyAndCurrentAnnually = isMonthlyItem && props.currentSubscription.period === YEAR

  const isMonthlyAndCurrentProfessional = isMonthlyItem && isCurrentProfessional
  const isAnnuallyAndCurrentEssential = isAnnuallyItem && isCurrentEssential
  const isAnnuallyAndCurrentProfessional = isAnnuallyItem && isCurrentProfessional

  if (props.subscription.isEnterprise && isCurrentEnterprise) {
    return {
      btnText: t('subscription.cancel_subscription'),
      btnType: SIMPLE_NEXT,
      emit: CANCEL
    }
  } else if (isCurrentEnterprise) {
    return {
      btnText: t('subscription.downgrade'),
      btnType: SIMPLE_NEXT,
      emit: SUBSCRIBE
    }
  } else if (props.subscription.isEnterprise) {
    return {
      btnText: t('support.contact_us'),
      btnType: PRIMARY_NEXT,
      emit: CONTACT_US
    }
  } else if (isTrial.value || isCanceled || subscriptionStatus.value.isEnterprise) {
    return {
      btnText: t('subscription.subscribe'),
      btnType: PRIMARY_NEXT,
      emit: SUBSCRIBE
    }
  } else if (isCurrentPlanAndSameCycle.value && isToBeCanceled.value) {
    // only for items with the same cycle and the same plan with my current plan
    return {
      btnText: t('subscription.renew'),
      btnType: SIMPLE_NEXT,
      emit: RENEW
    }
  } else if (isCurrentPlanAndSameCycle.value) {
    // only for items with the same cycle and the same plan with my current plan
    return {
      btnText: t('subscription.cancel_subscription'),
      btnType: SIMPLE_NEXT,
      emit: CANCEL
    }
  } else if (isCurrentCycleAnnuallyAndCurrentPlan) {
    // only for items with the same cycle and the same plan with my current plan
    return {
      btnText: t('subscription.switch_to_monthly'),
      btnType: SIMPLE_NEXT,
      emit: SUBSCRIBE
    }
  } else if (isCurrentPlanEssentialAndAnnually) {
    // only for items with the same cycle and the same plan with my current plan
    return {
      btnText: t('subscription.upgrade_to_monthly'),
      btnType: SIMPLE_NEXT,
      emit: SUBSCRIBE
    }
  } else if (isCurrentPlanAndAnnually) {
    // only in annual item and my current plan is equal by plan
    return {
      btnText: t('subscription.switch_to_annual'),
      btnType: SIMPLE_NEXT,
      emit: SUBSCRIBE
    }
  } else if (isMonthlyAndCurrentAnnually || isMonthlyAndCurrentProfessional) {
    // only in monthly item and my current plan is annually or professional
    // or only in monthly item and my current plan is professional
    // from annual always show downgrade
    return {
      btnText: t('subscription.downgrade'),
      btnType: SIMPLE_NEXT,
      emit: SUBSCRIBE
    }
  } else if (isMonthlyItem) {
    // only in monthly item
    return {
      btnText: t('subscription.upgrade'),
      btnType: PRIMARY_NEXT,
      emit: SUBSCRIBE
    }
  } else if (isAnnuallyAndCurrentEssential) {
    // only in annually item and my current plan is essential
    return {
      btnText: t('subscription.upgrade_to_annual'),
      btnType: PRIMARY_NEXT,
      emit: SUBSCRIBE
    }
  } else if (isAnnuallyAndCurrentProfessional) {
    // only in annually item and my current plan is professional
    return {
      btnText: t('subscription.downgrade_to_annual'),
      btnType: SIMPLE_NEXT,
      emit: SUBSCRIBE
    }
  } else {
    return {
      btnText: t('subscription.subscribe'),
      btnType: PRIMARY_NEXT,
      emit: SUBSCRIBE
    }
  }
})

const onClickBtn = eventName => {
  emit(eventName, props.subscription)
}
</script>

<style lang="scss" scoped>
.si-SubscriptionItem {
  position: relative;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: $border-radius-lg-next;
  max-width: 310px;
  background: $grey-3-next;
  &-active {
    background: $white;
    box-shadow: inset 0 0 0 2px $grey-2-next;
  }
  &_Title {
    font-weight: fw('semi-bold');
    line-height: 28px;
    margin: 0 0 8px 0;
    display: flex;
    align-items: center;
  }
  &_SubTitle {
    font-size: $fs-14;
    font-style: normal;
    font-weight: fw('regular');
    line-height: 20px;
    color: $dark-2;
  }
  &_Divider {
    margin: 0;
  }
  &_Badge {
    margin-left: 8px;
  }

  .si-Price {
    display: flex;
    align-items: center;
    &_Title {
      color: $dark-1;
      font-size: $fs-56;
      font-style: normal;
      font-weight: fw('medium');
      line-height: 64px;
      margin: 0 8px 0 0;
    }
    &_Description {
      display: flex;
      flex-direction: column;
      color: $dark-3;
      font-size: $fs-12;
      font-style: normal;
      font-weight: fw('regular');
      line-height: 16px;
      margin: 0;
    }
  }
  .si-SubscriptionItem_Features {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .si-Features_Item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $dark-2;
    font-size: $fs-14;
    font-style: normal;
    font-weight: fw('medium');
    line-height: 20px;
  }

  .si-CurrentPlanWrapper {
    position: absolute;
    top: -12px;
    display: flex;
    width: 100%;
    left: 0;
  }
  .si-CurrentPlan {
    border-radius: $border-radius-sm-next;
    background: $grey-2-next;
    padding: 4px 6px;
    font-size: $fs-12;
    font-weight: fw('bold');
    line-height: 16px;
    color: $dark-3;
    text-transform: uppercase;
    margin: auto;
  }
}
</style>
