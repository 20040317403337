<template>
  <span :class="{ 'ab-BadgeWrapper-inline': inline }" class="ab-BadgeWrapper">
    <span
      v-if="!hideBadge"
      :class="{
        'ab-Badge-bordered': bordered,
        'ab-Badge-with-content': !!content,
        'ab-Badge-uppercase': isUppercase,
        'ab-Badge-half-filled': halfFilled
      }"
      class="ab-Badge"
    >
      {{ content }}
    </span>
    <slot />
  </span>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppBadge'
})
</script>

<script setup>
defineProps({
  bordered: {
    type: Boolean
  },

  inline: {
    type: Boolean
  },

  content: {
    type: [String, Number],
    default: ''
  },

  hideBadge: {
    type: Boolean
  },

  isUppercase: {
    type: Boolean
  },

  halfFilled: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
$border-size: 2px;
$backdrop-size: calc(var(--backdrop-size, var(--size, 6px)));
.ab-BadgeWrapper {
  position: relative;
  display: inline-block;

  &-inline {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: $backdrop-size;
    min-width: $backdrop-size;
  }
}

.ab-Badge {
  width: var(--size, 6px);
  height: var(--size, 6px);
  border-radius: calc(var(--size, 6px) / 2);
  background-color: var(--color, #{$primary-color-next});
  position: absolute;
  top: var(--top, -4px);
  right: var(--right, -4px);
  left: var(--left, unset);
  pointer-events: none;
  z-index: 1;
  &-bordered {
    .is-safari & {
      box-shadow: 0 0 0 $border-size var(--border-color, #{$white});
    }

    &:not(.is-safari &) {
      outline: $border-size solid var(--border-color, #{$white});
    }
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-with-content {
    display: flex;
    padding: var(--padding, 2px);
    align-items: center;
    justify-content: center;
    font-family: $system-ui;
    font-style: normal;
    font-weight: fw('bold');
    font-size: $fs-8;
    line-height: 10px;
    text-align: center;
    text-indent: 0;
    color: $white;
    width: auto;
    height: auto;
    min-width: var(--size, 6px);
    min-height: var(--size, 6px);
  }

  &-half-filled {
    background: linear-gradient(
      90deg,
      transparent 0%,
      transparent 50%,
      var(--color, #{$primary-color-next}) 50%,
      var(--color, #{$primary-color-next}) 100%
    );
    border: 1px solid var(--color, var(--color, #{$primary-color-next}));
  }

  .ab-BadgeWrapper-inline & {
    position: relative;
    top: unset;
    right: unset;
  }
}
</style>
