<template>
  <div
    :class="{ 'obn-ObjectiveName-filteredout': filteredOut }"
    class="obn-ObjectiveName"
    @click.self="handleClick"
  >
    <div class="o-title-text-wrapper">
      <div class="o-title-text" @click="onTitleClick">
        <template v-if="userHasReadAccess">
          <span
            v-if="withTooltip"
            v-tippy="{
              content: objective.name,
              placement: 'bottom-start',
              theme: 'word-break translucent-next'
            }"
          >
            {{ objective.name }}
          </span>
          <template v-else>
            {{ objective.name }}
          </template>
        </template>
        <template v-else> ∗∗∗∗∗∗∗∗∗∗∗ </template>

        <div
          v-if="!userHasReadAccess"
          :title="$t('objectives.unavailable_description')"
          class="o-title-text-overlay"
        />
      </div>
    </div>

    <ExternalJiraLink v-if="isJiraIssue" :jira-issue-id="objective.jiraIssueId" />

    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import { currentUserCanReadObjective } from '@/utils/objectives'
import { doubleClickHandler, checkIsTextSelectionProceed } from '@/utils/text-selection'

import ExternalJiraLink from '@/components/objectives/ExternalJiraLink'

export default defineComponent({
  name: 'ObjectiveName',

  components: {
    ExternalJiraLink
  },

  props: {
    objective: {
      type: Object,
      required: true
    },

    withTooltip: {
      type: Boolean
    },

    filteredOut: {
      type: Boolean
    }
  },

  emits: { click: null },

  computed: {
    userHasReadAccess() {
      return currentUserCanReadObjective(this.objective)
    },

    isJiraIssue() {
      return this.objective.typeId === OBJECTIVE_TYPES.TASK
    }
  },

  beforeUnmount() {
    doubleClickHandler.destroy()
  },

  methods: {
    onTitleClick() {
      doubleClickHandler.handleClick(this.handleClick)
    },

    handleClick() {
      if (!checkIsTextSelectionProceed()) {
        this.$emit('click')
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.obn-ObjectiveName {
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
  min-height: 20px;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1 1 auto;
  gap: 4px;

  &-filteredout {
    opacity: 0.5;
  }
}

.o-title-text-wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.o-title-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  user-select: text;
  line-height: 20px;

  &:after {
    // Fix for Safari native tooltip. For prevent showing when content is truncated.
    content: '';
    display: block;
  }

  --gradient-color: 255, 255, 255;

  &-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    background: -moz-linear-gradient(
      left,
      rgba(var(--gradient-color), 0) 0%,
      rgba(var(--gradient-color), 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba($white, 0) 0%,
      rgba(var(--gradient-color), 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba($white, 0) 0%,
      rgba(var(--gradient-color), 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    .o-objective-row:hover &,
    .asi-AppSelectOptionsItem-default-next.asi-AppSelectOptionsItem-focused &,
    .asi-AppSelectOptionsItem-default-next:hover &,
    .ol-ObjectivesList-default-next .oeli-ItemContent:hover &,
    .ob-DistantRelative & {
      --gradient-color: 245, 246, 247;
    }

    .asi-AppSelectOptionsItem-default-next.asi-AppSelectOptionsItem-selected & {
      --gradient-color: 217, 229, 247;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
