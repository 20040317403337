<template>
  <AppDroplist
    :append-to="appendTo"
    :duration="[250, 0]"
    :follow-cursor="followCursor"
    :model-value="showDatesDropdown"
    :offset="offset"
    :position="position"
    theme="no-shadow-next light"
    trigger="manual"
    @hidden="hideDropdown"
  >
    <div class="odd-Dropdown">
      <LoadingCircle v-if="loading" class="odd-Dropdown_Loading" size="small" />
      <div v-if="showHead" class="odd-Dropdown_Head">
        <OkrIconAndId :okr-element="objective" @click-on-id="editObjective" />

        <AppButton
          v-tippy="{
            content: $t('action.close'),
            placement: 'top'
          }"
          icon="close-next"
          remove-padding
          size="sm"
          type="ghost-next"
          @click="hideDropdown"
        />
      </div>
      <OkrPeriodModeSwitch
        v-if="showPeriodModeSwitch"
        :disabled="disabled"
        :due-date-manual="objective.dueDateManual"
        :name="`periodMode-${objective.id}`"
        :show-title="showPeriodModeTitle"
        :start-date-manual="objective.startDateManual"
        @update-period-mode="$emit('update-period-mode', $event)"
      />

      <div class="odd-Dropdown_Dates">
        <div class="odd-DropdownField">
          <div class="odd-DropdownField_Label">
            {{ $t('objectives.table_header_startDate') }}
          </div>
          <OkrDateSelect
            v-if="!isAutoPeriodMode"
            :ref="`datepicker-${OKR_DATES_SELECT_DATE_PROPS.START_DATE}`"
            v-model="formModel.elementStartDate"
            :date-prop="OKR_DATES_SELECT_DATE_PROPS.START_DATE"
            :disabled="disabled"
            :duration="[250, 0]"
            :hide-footer="hideStartDateFooter"
            :min-max-dates="minMaxDates"
            append-to=".odd-Dropdown"
            date-format="DD MMM YYYY"
            @update:model-value="updateElementStartDate"
          />
          <div v-else class="odd-DropdownPlug">
            {{ datesPlugs.startDate }}
          </div>
        </div>

        <div class="odd-DropdownField">
          <div class="odd-DropdownField_Label">
            {{ $t('objectives.table_header_duedate') }}
          </div>
          <OkrDateSelect
            v-if="!isAutoPeriodMode"
            :ref="`datepicker-${OKR_DATES_SELECT_DATE_PROPS.DUE_DATE}`"
            v-model="formModel.dueDate"
            :date-prop="OKR_DATES_SELECT_DATE_PROPS.DUE_DATE"
            :disabled="disabled"
            :duration="[250, 0]"
            :hide-footer="hideDueDateFooter"
            :min-max-dates="minMaxDates"
            append-to=".odd-Dropdown"
            date-format="DD MMM YYYY"
            @update:model-value="updateDueDate"
          />
          <div v-else class="odd-DropdownPlug">
            {{ datesPlugs.dueDate }}
          </div>
        </div>
      </div>
    </div>
  </AppDroplist>
</template>

<script>
import dayjs from 'dayjs'
import { defineComponent } from 'vue'

import { dateFormat, localDateToUtc, utcDateToLocal } from '@/utils/date'
import { OKR_ELEMENT_PARAMETERS_SAVING_STATUSES } from '@/utils/objectives'
import {
  getMinMaxDates,
  getSelectedIntervalDates,
  hideDueDateFooter,
  hideStartDateFooter,
  OKR_DATES_SELECT_DATE_PROPS
} from '@/utils/okr-element-dates'

import AppDroplist from '@/components/AppDroplist'
import OkrIconAndId from '@/components/form/OkrIconAndId'
import OkrDateSelect from '@/components/objectives/OkrDateSelect'
import OkrPeriodModeSwitch from '@/components/objectives/OkrPeriodModeSwitch'
import AppButton from '@/components/ui/AppButton/AppButton'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'

const getFormattedDate = date => dayjs(date).utc().format(dateFormat)

export default defineComponent({
  name: 'OkrDatesDropdown',
  components: {
    OkrIconAndId,
    LoadingCircle,
    OkrDateSelect,
    OkrPeriodModeSwitch,
    AppButton,
    AppDroplist
  },

  props: {
    objective: {
      type: Object,
      required: true
    },

    showDatesDropdown: {
      type: Boolean
    },

    loading: {
      type: Boolean
    },

    isAutoPeriodMode: {
      type: Boolean
    },

    showPeriodModeSwitch: {
      type: Boolean
    },

    showPeriodModeTitle: {
      type: Boolean,
      default: true
    },

    showHead: {
      type: Boolean
    },

    appendTo: {
      type: String,
      default: 'parent'
    },

    position: {
      type: String,
      default: 'top'
    },

    followCursor: {
      type: [String, Boolean],
      default: 'initial'
    },

    splitUpdateDateEvents: {
      type: Boolean
    },

    openCalendarOnInit: {
      type: String,
      default: null,
      validator: v => v === null || Object.values(OKR_DATES_SELECT_DATE_PROPS).includes(v)
    },

    offset: {
      type: Array,
      default: () => [0, 8]
    },

    disabled: {
      type: Boolean
    }
  },

  emits: {
    'hide-dates-dropdown': null,
    'edit-objective': null,
    'update-period-mode': null,
    'update-element-date': null
  },

  data() {
    return {
      formModel: {}
    }
  },

  computed: {
    OKR_DATES_SELECT_DATE_PROPS: () => OKR_DATES_SELECT_DATE_PROPS,
    datesPlugs() {
      const { automaticElementStartDate, automaticDueDate } = this.objective

      return {
        startDate: automaticElementStartDate
          ? getFormattedDate(automaticElementStartDate)
          : this.$t('objectives.due_date.start_of_interval'),

        dueDate: automaticDueDate
          ? getFormattedDate(automaticDueDate)
          : this.$t('objectives.due_date.end_of_interval')
      }
    },

    selectedIntervalDates() {
      return getSelectedIntervalDates(this.objective)
    },

    minMaxDates() {
      return getMinMaxDates(
        this.selectedIntervalDates,
        this.formModel.elementStartDate,
        this.formModel.dueDate
      )
    },

    hideDueDateFooter() {
      return hideDueDateFooter(
        this.selectedIntervalDates.intervalEndDate,
        this.formModel.elementStartDate
      )
    },

    hideStartDateFooter() {
      return hideStartDateFooter(
        this.selectedIntervalDates.intervalStartDate,
        this.formModel.dueDate
      )
    }
  },

  watch: {
    objective: {
      handler() {
        this.formModel.elementStartDate = this.objective.elementStartDate
          ? utcDateToLocal(new Date(this.objective.elementStartDate))
          : null

        this.formModel.dueDate = this.objective.dueDate
          ? utcDateToLocal(new Date(this.objective.dueDate))
          : null
      },

      immediate: true,
      deep: true
    },

    showDatesDropdown: {
      handler(newValue) {
        if (newValue && !this.isAutoPeriodMode && this.openCalendarOnInit) {
          this.$nextTick(() => {
            this.$refs[`datepicker-${this.openCalendarOnInit}`].open()
          })
        }
      }
    }
  },

  methods: {
    updateElementStartDate(val) {
      let payload = {
        elementStartDate: localDateToUtc(val),
        dueDate: this.objective.dueDate,
        dateProp: OKR_DATES_SELECT_DATE_PROPS.START_DATE
      }
      if (this.splitUpdateDateEvents) {
        payload = {
          val: localDateToUtc(val),
          dateProp: OKR_DATES_SELECT_DATE_PROPS.START_DATE,
          savingStatus: OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.START_DATE
        }
      }
      this.$emit('update-element-date', payload)
    },

    updateDueDate(val) {
      let payload = {
        elementStartDate: this.objective.elementStartDate,
        dueDate: localDateToUtc(val),
        dateProp: OKR_DATES_SELECT_DATE_PROPS.DUE_DATE
      }
      if (this.splitUpdateDateEvents) {
        payload = {
          val: localDateToUtc(val),
          dateProp: OKR_DATES_SELECT_DATE_PROPS.DUE_DATE,
          savingStatus: OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.DUE_DATE
        }
      }

      this.$emit('update-element-date', payload)
    },

    hideDropdown() {
      this.$emit('hide-dates-dropdown')
    },

    editObjective() {
      this.$emit('edit-objective')
      this.hideDropdown()
    }
  }
})
</script>

<style lang="scss" scoped>
.odd-Dropdown {
  min-width: 258px;
  padding: 20px;
  display: grid;
  gap: 18px;
  box-sizing: border-box;
  font-family: $system-ui;

  &_Head {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
  }

  &_Dates {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
  }

  &_Loading {
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(4px);
    z-index: 3;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-md;
  }
}

.odd-DropdownField {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.odd-DropdownField_Label {
  font-size: $fs-12;
  line-height: 16px;
  font-weight: fw('regular');
  color: $dark-3;
}

.odd-DropdownPlug {
  min-height: 20px;
  display: flex;
  align-items: center;
  font-size: $fs-14;
  color: $dark-1;
}
</style>
