<template>
  <div
    :style="{
      '--grade': resolvedGrade
    }"
    class="ogc-Wrapper"
  >
    <div v-if="showDescription" :style="styles" class="ogc-Wrapper_Labels">
      <div
        v-for="bar in chartBars"
        :key="bar.color"
        :style="{
          '--color': `var(${bar.color})`
        }"
      >
        <div :class="{ 'ogc-Description-one-line': oneLineDescription }" class="ogc-Description">
          <div class="ogc-Description_Thresholds">{{ bar.start }}-{{ bar.end }}%</div>
          <i18n-t :keypath="bar.title" class="ogc-Description_Title" scope="global" tag="div">
            <template v-if="oneLineDescription" #after>:</template>
          </i18n-t>
        </div>
      </div>
    </div>
    <div class="ogc-Wrapper_Bars">
      <div
        v-for="bar in chartBars"
        :key="bar.color"
        :style="{
          '--resolved-color': `var(${bar.resolvedColor})`,
          '--color': `var(${bar.color})`,
          '--width': bar.width,
          '--grade-fill': bar.gradeFill,
          '--predicted-score-fill': bar.predictedScoreFill,
          '--rgb-color': bar.rgbColor
        }"
        class="ogc-BarItem"
      >
        <div :class="{ 'ogc-Bar-solid': removeTransparency }" class="ogc-Bar">
          <span
            v-if="bar.gradeFill !== FILLS.MIN"
            :class="getClassName({ fill: bar.gradeFill, end: bar.end, value: resolvedGrade })"
            class="ogc-Bar_Grade"
          />
          <span
            v-if="bar.predictedScoreFill !== FILLS.MIN"
            :class="
              getClassName({
                fill: bar.predictedScoreFill,
                end: bar.end,
                value: resolvedPredictedScore
              })
            "
            class="ogc-Bar_PredictedScore"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import { memoizeGetCssVariableValue, memoizeHexToRgb } from '@/utils/memoizations'
import {
  getGradeColorByValue,
  getGradeColorVariable,
  THRESHOLDS_COLORS
} from '@/utils/okr-elements/grade'
import { stringOrNullProp } from '@/utils/prop-validators'
import { THRESHOLDS_DEFAULT_VALUES } from '@/utils/thresholds'

defineOptions({
  name: 'OkrGradeChart'
})

const props = defineProps({
  grade: {
    type: Number,
    required: true
  },
  predictedScore: {
    type: Number,
    default: -Infinity
  },

  showDescription: {
    type: Boolean
  },

  oneLineDescription: {
    type: Boolean
  },

  removeTransparency: {
    type: Boolean
  },

  showPredictedScore: {
    type: Boolean
  },

  gradeColor: {
    required: true,
    validator: v => stringOrNullProp(v)
  }
})

const store = useStore()
const settings = computed(() => store.state.system.settings || {})
const MIN_GRADE = 0
const MAX_GRADE = 100

const getResolvedValue = ({ value = 0, round = true }) => {
  let resolvedValue = value
  if (value < MIN_GRADE) {
    resolvedValue = MIN_GRADE
  }

  if (value > MAX_GRADE) {
    resolvedValue = MAX_GRADE
  }

  return round ? Math.round(resolvedValue) : resolvedValue
}

const resolvedGrade = computed(() => {
  // let resolvedGrade = props.grade
  // if (props.grade < MIN_GRADE) {
  //   resolvedGrade = MIN_GRADE
  // }
  //
  // if (props.grade > MAX_GRADE) {
  //   resolvedGrade = MAX_GRADE
  // }
  //
  // return (resolvedGrade * resolvedMaxGrade.value) / MAX_GRADE

  return getResolvedValue({
    value: props.grade
  })
})

const styles = computed(() => {
  if (!props.showDescription) {
    return {}
  }
  const template = chartBars.value.reduce((acc, val) => {
    return `${acc} ${val.width}fr`
  }, '')

  return { 'grid-template-columns': template }
})

const resolvedPredictedScore = computed(() =>
  getResolvedValue({
    value: props.predictedScore,
    round: false
  })
)

// const resolvedMaxGrade = computed(() => {
//   return props.predictedScore > MAX_GRADE ? Math.round(props.predictedScore) : MAX_GRADE
// })

const FILLS = {
  MIN: '0%',
  MAX: '100%'
}

const getClassName = ({ fill, end, value }) => {
  const isFullFilled =
    (resolvedGrade.value === MAX_GRADE && resolvedPredictedScore.value === MAX_GRADE) ||
    (resolvedGrade.value === MAX_GRADE && resolvedPredictedScore.value === MIN_GRADE)
  return {
    'ogc-Bar-withMarker': (value === end || fill !== FILLS.MAX) && !isFullFilled
  }
}

const getFillPercentage = ({ start, end, value }) => {
  const [fill] = [
    value >= end && FILLS.MAX,
    value <= start && FILLS.MIN,
    `${((value - start) / (end - start)) * 100}%`
  ].filter(Boolean)

  return fill
}

const getResolvedColor = value => {
  const {
    thresholdBehind = THRESHOLDS_DEFAULT_VALUES.BEHIND,
    thresholdOnTrack = THRESHOLDS_DEFAULT_VALUES.ON_TRACK
  } = settings.value

  return getGradeColorByValue({ value, thresholdBehind, thresholdOnTrack })
}

const chartBars = computed(() => {
  const {
    thresholdBehind = THRESHOLDS_DEFAULT_VALUES.BEHIND,
    thresholdOnTrack = THRESHOLDS_DEFAULT_VALUES.ON_TRACK
  } = settings.value
  const { AT_RISK, BEHIND, ON_TRACK } = THRESHOLDS_COLORS
  const items = [
    {
      title: 'objectives.at_risk',
      color: AT_RISK,
      width: thresholdBehind - MIN_GRADE,
      start: MIN_GRADE,
      end: thresholdBehind
    },
    {
      title: 'objectives.behind',
      color: BEHIND,
      width: thresholdOnTrack - thresholdBehind,
      start: thresholdBehind,
      end: thresholdOnTrack
    },
    {
      title: 'objectives.on_track',
      color: ON_TRACK,
      width: MAX_GRADE - thresholdOnTrack,
      start: thresholdOnTrack,
      end: MAX_GRADE
    }
  ]

  return items.map(item => {
    const { start, end, color } = item

    const gradeFill = getFillPercentage({ start, end, value: resolvedGrade.value })
    const predictedScoreFill = getFillPercentage({
      start,
      end,
      value: resolvedPredictedScore.value
    })

    const hex = memoizeGetCssVariableValue(color)
    const rgbColor = memoizeHexToRgb(hex.trim())

    // const resolvedColor = getResolvedColor(resolvedGrade.value)
    let resolvedColor = getResolvedColor(
      props.showPredictedScore ? resolvedPredictedScore.value : resolvedGrade.value
    ) // pass the predicted score for make grade color same with predicted score color

    if (props.gradeColor) {
      resolvedColor = getGradeColorVariable(props.gradeColor)
    }

    const predictedScoreHex = memoizeGetCssVariableValue(resolvedColor)
    const predictedScoreRgbColor = memoizeHexToRgb(predictedScoreHex.trim())

    let resolvedRgbColor = rgbColor

    if (rgbColor !== predictedScoreRgbColor) {
      resolvedRgbColor = predictedScoreFill === FILLS.MAX ? predictedScoreRgbColor : rgbColor
    }

    return {
      ...item,
      gradeFill,
      predictedScoreFill,
      rgbColor: resolvedRgbColor,
      resolvedColor
    }
  })
})
</script>

<style lang="scss" scoped>
$gap: 4px;
.ogc-Wrapper {
  display: grid;
  gap: 6px;
}

.ogc-Wrapper_Labels {
  display: grid;
  gap: $gap;
}

.ogc-Wrapper_Bars {
  display: flex;
  gap: $gap;
}

.ogc-BarItem {
  width: calc((100% - (#{$gap} * 2)) * var(--width) / 100);
}

.ogc-Bar {
  height: 6px;

  border-radius: $border-radius-sm-next;
  position: relative;

  &:not(&-solid) {
    background: rgba(var(--rgb-color), 0.2);
  }

  &-solid {
    background: var(--color);
  }
}

.ogc-Bar_Grade,
.ogc-Bar_PredictedScore {
  pointer-events: none;
  border-radius: $border-radius-sm-next;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  min-width: 12px;
}

.ogc-Bar-withMarker {
  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $white;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: 3px solid;
    box-sizing: border-box;
  }
}

.ogc-Bar_Grade {
  background-color: var(--resolved-color);
  width: var(--grade-fill);
  z-index: 1;

  &:after {
    border-color: var(--resolved-color);
  }
}

.ogc-Bar_PredictedScore {
  background-color: rgba(var(--rgb-color), 0.5);
  width: var(--predicted-score-fill);

  &:after {
    border-color: rgba(var(--rgb-color), 0.5);
  }
}

.ogc-Description {
  font-style: normal;
  font-size: $fs-12;
  line-height: 16px;

  &-one-line {
    display: inline-flex;
    flex-direction: row-reverse;
    gap: 1ch;
  }
}

.ogc-Description_Thresholds {
  font-weight: fw('regular');
  color: $dark-3;
  white-space: nowrap;
}

.ogc-Description_Title {
  font-weight: fw('semi-bold');
  color: var(--color);
  white-space: nowrap;
}
</style>
