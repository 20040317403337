<template>
  <div class="lt-Loader">
    <div v-for="row in ROWS" :key="row" class="lt-Loader_Row">
      <div class="lt-Cell">
        <SkeletonItem :size="SM" :width="row" border-radius="6px" color="var(--grey-3-next)" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LabelsTableLoader'
})
</script>

<script setup>
import { SKELETON_SIZES } from '@/utils/components-configurations/skeleton-item'

const { SM } = SKELETON_SIZES

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const ROWS = ['75px', '120px', '80px', '260px', '20px', '41px', '36px', '80px', '64px', '210px']
</script>

<style lang="scss" scoped>
.lt-Loader_Row {
  height: 45px;
  padding: 0 $page-right-padding 0 $page-left-padding;
  display: flex;
  align-items: center;
}

.lt-Cell {
  display: flex;
  align-items: center;
}
</style>
