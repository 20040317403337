<template>
  <div class="air-Wrapper" :class="{ 'air-Wrapper-disabled': disabled }">
    <input
      :key="key"
      v-model.number="value"
      type="range"
      class="air-Input"
      :min="min"
      :max="max"
      :style="styles"
      :step="step"
      :disabled="disabled"
    />

    <div v-if="showPercentage" class="air-Percentage">
      {{ percentage }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { getRangePercentage } from '@/utils/range-percentage'
import { uid } from '@/utils/uid'

export default defineComponent({
  name: 'AppInputRange',

  props: {
    modelValue: {
      type: Number,
      required: true
    },

    min: {
      type: Number,
      required: true
    },

    max: {
      type: Number,
      required: true
    },

    step: {
      type: Number,
      required: true
    },

    showPercentage: {
      type: Boolean,
      default: true
    },

    trackWidth: {
      type: String,
      default: '140px',
      validator: v => /\d*\.?\d+(?:px|%)?/i.test(v)
    },

    disabled: {
      type: Boolean
    }
  },

  emits: {
    'update:modelValue': null
  },

  computed: {
    // necessary coz input range brokes with dynamic min and max values
    key() {
      return `${uid()}-${[this.min, this.max, this.step].join('-')}`
    },

    styles() {
      return {
        '--min': this.min,
        '--max': this.max,
        '--val': this.modelValue
      }
    },

    percentage() {
      const { min, max, step, modelValue: current } = this

      return getRangePercentage({
        min,
        max,
        step,
        current
      })
    },

    value: {
      get() {
        return this.modelValue
      },

      set(value) {
        if (!this.disabled) {
          this.$emit('update:modelValue', Number(value))
        }
      }
    }
  }
})
</script>

<style scoped lang="scss">
$thumb-size: 12px;
$track-height: 6px;

.air-Wrapper {
  height: $thumb-size;
  display: flex;
  align-items: center;
  gap: 4px;

  --main-color: #{$primary-color-next};
  --secondary-color: #ccdcf5;

  &-disabled {
    cursor: not-allowed;
    --main-color: #{$grey-1-next};
    --secondary-color: #f0f1f3;
  }
}

@mixin track($fill: 0) {
  box-sizing: border-box;
  border: none;
  width: v-bind(trackWidth);
  height: $track-height;
  background: var(--secondary-color);
  border-radius: $border-radius-sm-next;

  @if $fill == 1 {
    background: linear-gradient(var(--main-color), var(--main-color))
      0 /
      var(--sx)
      100%
      no-repeat
      var(--secondary-color);
  }
}

@mixin fill() {
  height: $track-height;
  background: var(--main-color);
}

@mixin thumb() {
  box-sizing: border-box;
  cursor: pointer;
  border: 3px solid var(--main-color);
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 50%;
  background: $white;
  // box-shadow: rgba($regular-text, 0.25) 0 4px 8px -2px, rgba($regular-text, 0.31) 0 0 1px 1px;

  .air-Wrapper-disabled & {
    cursor: not-allowed;
  }

  &:active {
    background: var(--main-color);
  }
}

.air-Input {
  outline: none;
  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(0.5 * #{$thumb-size} + var(--ratio) * (100% - #{$thumb-size}));
  margin: 0;
  padding: 0;
  width: v-bind(trackWidth);
  height: $track-height;
  background: transparent;
  font: 1em/1 arial, sans-serif;

  &:disabled {
    cursor: not-allowed;
  }

  &::-webkit-slider-runnable-track {
    @include track(1);
  }
  &::-moz-range-track {
    @include track;
  }
  &::-ms-track {
    @include track;
  }

  &::-moz-range-progress {
    @include fill;
  }
  &::-ms-fill-lower {
    @include fill;
  }

  &::-webkit-slider-thumb {
    margin-top: 0.5 * ($track-height - $thumb-size);
    @include thumb;
  }
  &::-moz-range-thumb {
    @include thumb;
  }
  &::-ms-thumb {
    margin-top: 0;
    @include thumb;
  }

  &::-ms-tooltip {
    display: none;
  }
}

.air-Percentage {
  color: $regular-text;
  font-size: $fs-13;
  min-width: 4.5ch;
  text-align: right;
}
</style>
