<template>
  <router-view v-if="workspaceSelected" />
  <OboardLogoLoader v-else />
</template>

<script>
import { isNull, isUndefined } from 'lodash'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

import { ROUTE_NAMES, ROUTE_PATHS } from '@/routes/route-helpers'
import { FILTER_PRESET_KEY } from '@/utils/query-parameters'

import OboardLogoLoader from '@/components/ui/SkeletonLoaders/OboardLogoLoader'

const routesWithRedirectOnWorkspaceChange = [
  ROUTE_NAMES.OKR_ELEMENTS_MIND_MAP,
  ROUTE_NAMES.OKR_ELEMENTS_EXPLORER,
  ROUTE_NAMES.OKR_ELEMENTS_HIERARCHY_TABLE
]

export default defineComponent({
  name: 'WorkspaceLayout',
  components: {
    OboardLogoLoader
  },

  // beforeRouteEnter(to, _, next) {
  //   next(vm => {
  //     const userIsAdmin = vm.userIsAdmin
  //     const selectedWorkspaceId = vm.selectedWorkspaceId
  //     if (!userIsAdmin && to.name === menuItemsNames.SETTINGS.children.INTERVALS.name) {
  //       // wait for finishing initial route resolve and redirect
  //
  //       vm.$nextTick(() => {
  //         vm.$router.push(`/workspaces/${selectedWorkspaceId}`)
  //       })
  //     }
  //   })
  // },

  computed: {
    ...mapState('workspaces', {
      selectedWorkspaceId: state => state.workspaceId
    }),

    // ...mapGetters('system', {
    //   userIsAdmin: 'userIsAdmin'
    // }),

    workspaceSelected() {
      return this.selectedWorkspaceId !== null
    }
  },

  watch: {
    selectedWorkspaceId: {
      async handler(newValue, oldValue) {
        await this.$router.isReady()
        // value can be taken from route params, check type-agnostic
        if (`${newValue}` === `${oldValue}`) {
          return
        }

        const wasPreviousWorkspace = !isNull(oldValue) && !isUndefined(oldValue)

        const redirectToAlignmentNeed =
          routesWithRedirectOnWorkspaceChange.includes(this.$route.name) && wasPreviousWorkspace

        let newQuery = { ...this.$route.query }

        if (newValue !== null && wasPreviousWorkspace && newQuery[FILTER_PRESET_KEY]) {
          delete newQuery[FILTER_PRESET_KEY]
        }

        if (newValue !== null) {
          if (redirectToAlignmentNeed) {
            await this.$router.push({
              path: `${ROUTE_PATHS.WORKSPACES}${newValue}/${ROUTE_PATHS.OBJECTIVES}/${ROUTE_PATHS.ALIGNMENT}`,
              query: newQuery
            })
          } else {
            await this.$router.replace({
              query: newQuery,
              params: { workspaceId: newValue }
            })
          }

          Promise.all([
            this.$store.dispatch('okrFilters/getSavedFilters', {
              accountId: this.$store.state.system.userData?.userAccountId,
              workspaceId: newValue
            }),

            this.$store.dispatch('customFields/getAllCustomFields')
          ])
        }
      },

      immediate: true
    }
  },

  mounted() {
    this.getFavoriteList()
  },

  methods: {
    ...mapActions('dashboard', ['getFavoriteList'])
  }
})
</script>

<style lang="scss" scoped>
/* styles */
</style>
