<template>
  <!--  <AppButton
    v-tippy="{
      content: tooltipText,
      placement: 'right'
    }"
    :class="classes"
    icon="menu-toggle"
    round
    size="xss"
    type="ghost-next"
    @click="toggleMenu"
  />-->

  <div class="amt-Wrapper">
    <AppButton
      v-tippy="{
        content: tooltipText,
        placement: 'right'
      }"
      :class="classes"
      icon="roadmap-collapse-table"
      remove-padding
      type="secondary-next"
      @click="toggleMenu"
    />
    <div class="amt-Line"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import AppButton from '@/components/ui/AppButton/AppButton'

export default defineComponent({
  name: 'AppMenuTrigger',
  components: { AppButton },
  props: {
    menuPinned: {
      type: Boolean
    }
  },

  emits: { 'toggle-menu': null },
  computed: {
    classes() {
      return {
        'amt-Trigger': true,
        'amt-Trigger-revert': this.menuPinned
      }
    },

    tooltipText() {
      return this.menuPinned ? this.$t('menu.tooltip.collapse') : this.$t('menu.tooltip.expand')
    }
  },

  methods: {
    toggleMenu() {
      this.$emit('toggle-menu')
    }
  }
})
</script>

<style lang="scss" scoped>
.amt-Wrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2;
  transform: translateX(50%);
  padding-block: $page-top-padding;
  pointer-events: none;
}
.amt-Trigger {
  // display: inline-flex;
  // background: $white;
  // box-shadow: $common-box-shadow;
  // color: $regular-text;
  // position: absolute;
  // top: 30px;
  // top: 32px;
  // right: 0;
  // z-index: 2;
  // transform: translateX(50%);
  transition: opacity $menu-transition;
  color: $white;
  pointer-events: all;
  z-index: 1;
  position: relative;

  @media (any-hover: hover) {
    &:hover {
      background-color: $primary-color-next;

      + .amt-Line {
        display: block;
      }
    }
  }

  //&:after {
  //  content: '';
  //  height: calc(100% + 14px);
  //  width: calc(100% + 14px);
  //  position: absolute;
  //}

  // @media (any-hover: hover) {
  //   &:hover {
  //     color: $white;
  //     background: $primary-color-next;
  //   }
  // }

  :deep(.svg-icon) {
    transition: transform $menu-transition;
  }

  // &-revert {
  //   &:deep(.svg-icon) {
  //     transform: rotate(180deg);
  //   }
  // }

  &:not(&-revert) {
    &:deep(.svg-icon) {
      transform: rotate(180deg);
    }
  }
}

.amt-Line {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  margin: auto;
  height: 100%;
  width: 2px;
  display: none;
  background: $primary-color-next;
}
</style>
