<template>
  <LimitTagList
    :filtered-out="filteredOut"
    :items="workspacesList"
    :limit="limit"
    data-testid="workspaces-cell"
    style="--list-gap: 4px; --count-offset: 0"
    tooltip-value="tooltipLabel"
  >
    <template #item="{ item: workspace }">
      <AppIcon
        v-if="workspace.hidden"
        class="wlc-LockedWs"
        height="24"
        icon-name="lock-next"
        width="24"
      />
      <WorkspaceIcon :option="workspace" />
    </template>
  </LimitTagList>
</template>

<script>
// eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WorkspacesListCell'
})
</script>

<script setup>
import { computed } from 'vue'

import LimitTagList from '@/components/form/LimitTagList'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import WorkspaceIcon from '@/components/workspaces/WorkspaceIcon'

const props = defineProps({
  filteredOut: {
    type: Boolean
  },

  workspaces: {
    type: Array,
    required: true
  },

  limit: {
    type: Number,
    default: 4
  }
})

const workspacesList = computed(() => {
  return props.workspaces.map(workspace => {
    const { name, key, hidden } = workspace
    const resolvedKey = hidden ? '∗' : key
    const resolvedName = hidden ? '∗∗∗∗∗∗' : name
    return {
      ...workspace,
      tooltipLabel: `[${resolvedKey}] ${resolvedName}`
    }
  })
})
</script>

<style lang="scss" scoped>
.wlc-LockedWs {
  flex-shrink: 0;
  color: $dark-2;
}
</style>
