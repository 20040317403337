<template>
  <AppDroplist
    v-if="showBanner"
    :offset="[0, 0]"
    append-to=".cbb-ButtonWrapper"
    placement="right"
    theme="transparent"
    trigger="mouseenter click"
  >
    <template #button>
      <div class="cbb-ButtonWrapper">
        <a :href="marketplaceLink" class="cbb-ButtonWrapper_Content" target="_blank">
          <AppIcon height="24" icon-name="plus-circle-next" width="24" />
          <span class="oboard-truncated-text cbb-Title">
            {{ t('confluence.add_okrs_to_confluence') }}
          </span>
        </a>
      </div>
      <AppButton
        class="cbb-ButtonClose"
        height="24"
        icon="close-circle-next"
        remove-padding
        size="sm"
        type="ghost-next"
        width="24"
        @click="onClick"
      />
    </template>
  </AppDroplist>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import AppDroplist from '@/components/AppDroplist'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'ConfluenceBanner'
})

const emit = defineEmits({ 'recalculate-footer-height': null, 'update-banner-data': null })

const showBanner = ref(true)
const { t } = useI18n()

defineProps({
  marketplaceLink: {
    type: String,
    default:
      'https://marketplace.atlassian.com/apps/1229394/okr-board-for-confluence?ref_source=left_nav_app'
  }
})

const onClick = () => {
  showBanner.value = false
  emit('update-banner-data')
  // nextTick(() => {
  //   emit('recalculate-footer-height')
  // })
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_menu_banners';

.cbb-ButtonWrapper {
  @extend %wrapper;
}

.cbb-ButtonWrapper_Content {
  @extend %wrapper_content;

  .cbb-ButtonWrapper:hover & {
    text-decoration: none;
    color: $primary-color-next;
  }
}

.cbb-ButtonClose {
  @extend %button_close;
}

.cbb-Title {
  @extend %title;
}
</style>
