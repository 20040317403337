import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { JIRA_CLOUD_API } from '@jira/util'
import { IS_DEVELOPMENT } from '@root/app-modes'

export const useReloadPage = () => {
  const store = useStore()

  const injectedIsWebApp = inject('isWebApp')

  const isPluginServer = computed(() => store.getters['pluginOptions/isPluginServer'])

  const reloadPage = ({ isWebApp = false } = {}) => {
    const resolvedIsWebApp = isWebApp || injectedIsWebApp

    if (resolvedIsWebApp || isPluginServer.value || IS_DEVELOPMENT) {
      location.reload()
    }

    if (JIRA_CLOUD_API) {
      JIRA_CLOUD_API.navigator.reload()
    }
  }

  return {
    reloadPage
  }
}
