<template>
  <template v-if="isShowCellByAddFor">
    <template v-if="userHasReadAccess">
      <CellSelectItemsList
        :id="`ascc${uniqId}`"
        :active="isOpened"
        :item-value="ASSIGNEE_ENTITY_KEYS.DISPLAY_NAME"
        :items="item.customFields[fieldId] || defaultValue"
        :plug-icon="PLUG_ICONS.ASSIGNEE"
        :user-has-read-access="userHasReadAccess"
        :user-has-update-access="userHasUpdateAccess"
        @click="editInitialised = true"
      />
      <template v-if="userHasUpdateAccess">
        <AppSelect
          v-if="editInitialised"
          :disabled="!userHasUpdateAccess"
          :loading="isLoading"
          :model-value="selectedAssignees"
          :offset="[0, 0]"
          :options="assignees"
          :search-function="getAssignees"
          :to-selector="`#ascc${uniqId}`"
          dropdown-search
          dropdown-width="200px"
          has-only-this-button
          hide-selected-items-in-dropdown
          item-label="name"
          item-value="accountId"
          multi
          show-on-init
          show-selected-options-inside
          skeleton-loader
          theme="no-shadow-next light"
          type="default-next"
          @hide="onHideSelect"
          @open="isLoading = true"
          @opened="onOpenSelect"
          @update:options="assignees = $event"
          @update:model-value="onUpdateModelValue"
        >
          <template #option-label="{ option }">
            <OwnerFieldOption :option="option" />
          </template>
        </AppSelect>
      </template>
    </template>
    <AppCipheredText v-else style="--padding-left: 6px" />
  </template>
</template>

<script setup>
import { computed, nextTick, ref, unref } from 'vue'

import { PLUG_ICONS } from '@/utils/cell-helper'
import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import {
  getAssigneeCustomFieldEmitValue,
  getAssigneeCustomFieldSelectedItems
} from '@/utils/custom-fields/helpers'
import { useFetchAssignees } from '@/utils/custom-fields/use-assignees'
import { ASSIGNEE_ENTITY_KEYS, CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'
import { shouldDisplayFieldByOkrElementTypeId } from '@/utils/memoizations'
import { uid } from '@/utils/uid'

import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import CellSelectItemsList from '@/components/objectives/table/cells/CellSelectItemsList'
import AppCipheredText from '@/components/ui/AppCipheredText'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'AssigneeCell'
})

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  userHasReadAccess: {
    type: Boolean
  },

  userHasUpdateAccess: {
    type: Boolean
  },

  fieldParams: {
    type: Object,
    required: true
  }
})

const uniqId = uid()

const editInitialised = ref(false)
const isOpened = ref(false)
const assignees = ref([])

const defaultValue = computed(() => {
  return ALL_CUSTOM_FIELDS.getFieldOptionsByTypeId(props.fieldParams.typeId)[
    CUSTOM_FIELD_ENTITY_KEYS.DEFAULT_VALUE
  ]
})

const selectedAssignees = computed(() => {
  const selectedItems = props.item.customFields[props.fieldId]

  return getAssigneeCustomFieldSelectedItems({
    fieldValue: selectedItems
  })
})

const { isLoading, getAssignees } = useFetchAssignees({
  workspaceId: props.item.workspaceId,
  selectedAssignees: selectedAssignees.value
})

const emit = defineEmits({
  'update-field-value': null
})

const onUpdateModelValue = async accountIds => {
  if (props.userHasUpdateAccess) {
    const value = getAssigneeCustomFieldEmitValue({
      accountIds,
      allAssignees: unref(assignees)
    })

    emit('update-field-value', {
      fieldId: props.fieldId,
      value
    })
  }
}

const onOpenSelect = async () => {
  await nextTick()
  isOpened.value = true

  assignees.value = await getAssignees()
}
const onHideSelect = () => {
  isOpened.value = false
  assignees.value = []
}

const isShowCellByAddFor = computed(() => {
  return shouldDisplayFieldByOkrElementTypeId({
    elementTypeIds: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.ELEMENT_TYPE_IDS],
    okrElementTypeId: props.item.typeId,
    okrElementWorkspaceId: props.item.workspaceId,
    workspaceIds: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.WORKSPACE_IDS],
    active: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.ACTIVE]
  })
})
</script>

<style lang="scss" scoped></style>
