<template>
  <div
    :class="{
      'os-SortButton': true,
      'os-SortButton-notEmpty': !emptySelectedValue
    }"
    @click="opened = !opened"
  >
    <AppIcon height="24" icon-name="arrows-up-down-sorting-next" width="24" />
    <AppSelectItem
      :show-delete-button="false"
      :show-selected-count-circle="!emptySelectedValue"
      type="secondary-next"
    >
      {{ t('objective.sort') }}
      <template #delete-button>
        {{ modelValue.length }}
      </template>
    </AppSelectItem>
  </div>

  <Modal
    :scrollable-content="false"
    :show="opened"
    manual-close
    size="xs"
    style="--modal-header-padding: 18px 20px 4px 20px"
    @close="onClose"
  >
    <template #header>
      <AppTitle :level="3" disable-margin>
        {{ $t('filter.sort_by') }}
      </AppTitle>
    </template>

    <div class="os-DropdownContent">
      <template v-if="!emptySelectedValue">
        <SlickList
          :list="modelValue"
          :lock-offset="['0%', '0%']"
          :transition-duration="200"
          class="os-SelectedSortingList"
          lock-axis="y"
          lock-to-container-edges
          use-drag-handle
          @update:list="updateModelValue"
        >
          <SlickItem
            v-for="(option, optionIndex) in selectedOptions"
            :key="`${optionIndex}_${option.value}`"
            :index="optionIndex"
            class="os-SelectedSortingItem"
            collection="sort-list"
          >
            <AppIcon
              v-handle
              class="os-SelectedSorting_Drag"
              height="24"
              icon-name="drag-next"
              width="24"
            />
            <div class="os-SelectedSortingItem-right">
              <AppSelect
                :hidden-items="[option.value]"
                :max-height="300"
                :model-value="option.value"
                :offset="[0, 0]"
                :options="selectOptionsForOption(option)"
                class="os-DropdownSelect"
                content-class="os-OptionSelectList"
                theme="no-shadow-next light"
                type="default-next"
                @update:model-value="onOptionSelectChange(option.value, $event)"
              />
              <AppRadioGroup
                :model-value="selectedOrder[option.value]"
                :name="`sort-${option.value}`"
                :options="orderOptions"
                style="--option-padding: 0; --backdrop-padding: 8px"
                type="primary-next"
                @update:model-value="value => selectOrder(option, value)"
              >
                <template #item-label="{ item }">
                  <AppIcon :icon-name="item.icon" class="os-SortingIcon" height="24" width="24" />
                </template>
              </AppRadioGroup>
              <AppButton
                icon="cross-circle-next"
                size="sm"
                type="subtle"
                @click="deselectOption(option.value)"
              />
            </div>
          </SlickItem>
        </SlickList>
      </template>

      <div v-if="!emptySelectedValue && notSelectedOptions.length" class="os-BtnPickFieldWrapper">
        <AppButton
          :class="{
            'os-BtnPickField': true,
            'os-BtnPickField-expanded': notSelectedOptionsExpanded
          }"
          type="none"
          @click.stop="onPickFieldClick"
        >
          <AppIcon height="24" icon-name="plus-next" width="24" />
          <AppDivider v-if="notSelectedOptionsExpanded" />
          <span v-else class="os-BtnPickField_Text">
            {{ $t('filter.add_another_field') }}
          </span>
        </AppButton>
      </div>

      <div v-show="notSelectedOptionsExpanded || emptySelectedValue">
        <ul class="os-SortingList">
          <li v-for="option in notSelectedOptions" :key="option.value" class="os-SortingList_Item">
            <a class="os-SortingItem" @click.prevent="selectOption(option.value)">
              <AppIcon :icon-name="option.icon" height="24" width="24" />
              <span class="os-SortingItemText">
                {{ t(option.label) }}
              </span>
            </a>
          </li>
        </ul>
      </div>

      <div v-if="!emptySelectedValue" class="os-SwitchWrapper">
        <AppCheckbox
          :disabled="emptySelectedValue"
          :model-value="sortChildren"
          size="sm"
          type="default"
          value="sortChildren"
          @update:model-value="switchSortChildren"
        >
          {{ $t('objective.sorting_switch_label') }}
          <span class="os-SwitchWrapper_Subtitle">{{ $t('objective.sorting_drag_blocked') }}</span>
        </AppCheckbox>
      </div>

      <ClearFiltersButton v-if="!emptySelectedValue" class="os-ClearButton" @click="onClearAll" />
    </div>
  </Modal>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { SlickItem, SlickList, HandleDirective } from 'vue-slicksort'
import { mapActions } from 'vuex'

import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { COLUMNS_SETTINGS } from '@/utils/objective-table'
import { OBJECTIVE_SORT_OPTIONS } from '@/utils/objectives'
import { ASC, DESC } from '@/utils/sort-options'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppSelectItem from '@/components/ui/AppSelect/AppSelectItem'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import ClearFiltersButton from '@/components/ui/ClearFiltersButton/ClearFiltersButton'
import Modal from '@/components/ui/Modal/Modal'

const { NAME, ASSIGNEE, GROUPS, GRADE, LABELS, STATUS, START_DATE, DUE_DATE } = COLUMNS_SETTINGS
export default defineComponent({
  name: 'ObjectiveSorting',

  components: {
    ClearFiltersButton,
    Modal,
    AppButton,
    AppIcon,
    AppSelect,
    AppCheckbox,
    AppSelectItem,
    AppDivider,
    AppRadioGroup,
    SlickItem,
    SlickList,
    AppTitle
  },

  directives: {
    handle: HandleDirective
  },

  inheritAttrs: false,

  props: {
    modelValue: {
      type: Array,
      required: true
    },

    sortChildren: {
      type: Boolean
    },

    currentTab: {
      type: String,
      required: true
    }
  },

  emits: { 'update:modelValue': null, 'update:sort-children': null },

  setup() {
    const { t } = useI18n()
    return { t }
  },

  data() {
    return {
      opened: false,
      searchString: '',
      options: {
        NAME: {
          label: 'users.table_header_name',
          value: NAME.sortKey,
          valueType: 'string',
          icon: 'name-next'
        },

        GRADE: {
          label: 'objectives.table_header_grade',
          value: GRADE.sortKey,
          valueType: 'number',
          icon: 'grade-next'
        },

        ASSIGNEE: {
          label: 'roles.owner',
          value: ASSIGNEE.sortKey,
          valueType: 'string',
          icon: 'user-next'
        },

        GROUPS: {
          label: 'field.group.title',
          value: GROUPS.sortKey,
          valueType: 'string',
          icon: 'team-next'
        },

        STATUS: {
          label: 'field.status.title',
          value: STATUS.sortKey,
          valueType: 'string',
          icon: 'done-next'
        },

        DUE_DATE: {
          label: 'objectives.table_header_duedate',
          value: DUE_DATE.sortKey,
          valueType: 'number',
          icon: 'due-date-next'
        },

        START_DATE: {
          label: 'objectives.table_header_startDate',
          value: START_DATE.sortKey,
          valueType: 'number',
          icon: 'create-date-next'
        },

        LABELS: {
          label: 'field.labels.title',
          value: LABELS.sortKey,
          valueType: 'string',
          icon: 'label-next'
        }
      },

      orders: Object.freeze({
        ASC,
        DESC
      }),

      notSelectedOptionsExpanded: false
    }
  },

  computed: {
    orderOptions() {
      return [
        { value: this.orders.ASC, icon: 'arrow-up-sorting' },
        {
          value: this.orders.DESC,
          icon: 'arrow-down-sorting'
        }
      ]
    },

    selectedOptionObjects() {
      const sortOptionKeys = Object.keys(OBJECTIVE_SORT_OPTIONS)
      const sortOptionValues = Object.values(OBJECTIVE_SORT_OPTIONS)
      return this.modelValue.map(valueOption => {
        const optionKeyIndex = sortOptionValues.findIndex(value => value === valueOption)
        const splittedKey = sortOptionKeys[optionKeyIndex].split('_')
        const value = splittedKey.slice(0, splittedKey.length - 1).join('_')
        const order = splittedKey[splittedKey.length - 1]
        return { value, order }
      })
    },

    notSelectedOptions() {
      return Object.values(this.options).filter(
        option =>
          !this.selectedOptionObjects.map(o => o.value).includes(option.value) &&
          this.$t(option.label).toLowerCase().includes(this.searchString.toLowerCase())
      )
    },

    selectedOptions() {
      return this.selectedOptionObjects.map(o => this.options[o.value])
    },

    selectedOrder() {
      const result = {}
      this.selectedOptionObjects.forEach(obj => {
        result[obj.value] = obj.order
      })
      return result
    },

    emptySelectedValue() {
      return isEmpty(this.modelValue)
    }
  },

  watch: {
    selectedOptionObjects: {
      handler(newValue) {
        this.updateSorting(this.convertSelectedSortingToObject(newValue))
      },

      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapActions('objectives', ['updateSorting']),

    convertSelectedSortingToObject(selectedObjects) {
      return selectedObjects
        .map((o, index) => ({ ...o, index: index + 1 }))
        .reduce((acc, obj) => {
          acc[obj.value] = obj
          return acc
        }, {})
    },

    onClearAll() {
      this.updateModelValue([])
      this.onClose()
    },

    updateModelValue(value) {
      this.$emit('update:modelValue', value)
    },

    selectOption(value) {
      this.searchString = ''
      const newValue = [...this.modelValue]
      newValue.push(OBJECTIVE_SORT_OPTIONS[`${value}_${this.orders.ASC}`])
      this.updateModelValue(newValue)
      tracker.logEvent('Table sorted', {
        category: EVENT_CATEGORIES.GRID,
        label: this.sortChildren === true ? 'all' : '1st level',
        tab: this.currentTab,
        value: value
      })
    },

    deselectOption(value) {
      const newValue = this.modelValue.filter(
        valueOption =>
          valueOption !== OBJECTIVE_SORT_OPTIONS[`${value}_${this.selectedOrder[value]}`]
      )
      this.updateModelValue(newValue)
    },

    selectOrder(option, order) {
      if (this.selectedOrder[option.value] === order) {
        return
      }

      const anotherOrder = order === this.orders.ASC ? this.orders.DESC : this.orders.ASC
      const optionIndex = this.modelValue.findIndex(
        valueOption => valueOption === OBJECTIVE_SORT_OPTIONS[`${option.value}_${anotherOrder}`]
      )
      const newValue = [...this.modelValue]
      newValue.splice(optionIndex, 1, OBJECTIVE_SORT_OPTIONS[`${option.value}_${order}`])
      this.updateModelValue(newValue)
    },

    onPickFieldClick() {
      this.notSelectedOptionsExpanded = !this.notSelectedOptionsExpanded
      /* if (this.notSelectedOptionsExpanded === true) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.search.focus();
          }, 100);
        });
      } */
    },

    onOptionSelectChange(oldOptionValue, newOptionValue) {
      const order = this.selectedOrder[oldOptionValue]
      const oldValueIndex = this.modelValue.findIndex(
        valueOption => valueOption === OBJECTIVE_SORT_OPTIONS[`${oldOptionValue}_${order}`]
      )
      const newValue = [...this.modelValue]
      newValue.splice(oldValueIndex, 1, OBJECTIVE_SORT_OPTIONS[`${newOptionValue}_${order}`])
      this.updateModelValue(newValue)
      tracker.logEvent('Table sorted', {
        category: EVENT_CATEGORIES.GRID,
        label: this.sortChildren === true ? 'all' : '1st level',
        tab: this.currentTab,
        value: newOptionValue
      })
    },

    selectOptionsForOption(option) {
      return [...this.notSelectedOptions, option].map(o => ({ ...o, label: this.$t(o.label) }))
    },

    // onClickOutside(event) {
    //   if (this.opened) {
    //     if (
    //       event.target.matches('.os-SortButton, .os-SortButton *') ||
    //       // why all elements must be tested separately?
    //       event.target.matches('.os-OptionSelectList *') ||
    //       event.target.matches('.os-SortingList_Item *') ||
    //       event.target.matches('.os-SelectedSortingItem *')
    //     ) {
    //       return
    //     }
    //     this.opened = false
    //   }
    // },

    onClose() {
      this.notSelectedOptionsExpanded = false
      this.opened = false
    },

    switchSortChildren(value) {
      this.$emit('update:sort-children', value)
      if (!this.emptySelectedValue) {
        tracker.logEvent('Table sorted', {
          category: EVENT_CATEGORIES.GRID,
          label: value ? 'all' : '1st level',
          tab: this.currentTab,
          value: this.modelValue
        })
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.os-DropdownContent {
  // min-width: 220px;
  // max-width: 500px;
  // padding: 20px;
  font-family: $system-ui;
  // max-height: 470px;
  // overflow: auto;
  // @include styled-native-scrollbar();
  border-top: 1px solid $grey-2-next;
  padding-top: 8px;
}
/*.os-DropdownContent_Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 15px 0;
}*/
.os-ClearButton {
  margin-top: 20px;
}
/*.os-DropdownContent_Title {
  font-weight: fw('semi-bold');
  color: $dark-1;
  margin: 0;
  font-family: $system-ui;
}*/
.os-SortButton {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 32px;
  padding: 4px 8px 4px 4px;
  font-size: $fs-14;
  line-height: 16px;
  font-style: normal;
  font-weight: fw('regular');
  color: $dark-grey;
  background-color: $grey-3-next;
  cursor: pointer;
  border-radius: $border-radius-sm-next;
  font-family: $system-ui;

  @include hoverState($grey-3-next, 2%);
  @include activeState($grey-3-next);

  &-notEmpty {
    color: $white;
    background: $dark-grey;
    @include hoverState($dark-grey, 10%);
    @include activeState($dark-grey);
  }
  &:deep(.si-SelectedItem) {
    display: flex;
    align-items: center;
    font-size: $fs-12;
    font-weight: fw('semi-bold');
    line-height: 16px;
    background: transparent;
    color: $dark-grey;
    padding: 0;
    &.si-SelectedItem-circle {
      color: $white;
    }
  }
  &:deep(.si-SelectedTagClear) {
    margin-right: 4px;
    background: $dark-3;
    font-weight: fw('bold');
    font-size: $fs-12;
    line-height: 16px;
    color: $white;
    width: auto;
    font-family: $system-ui;
    &:hover {
      background-color: $dark-3;
      color: $white;
    }
  }
}

.os-BtnPickFieldWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  gap: 8px;
}
.os-BtnPickField_Text {
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
  font-family: $system-ui;
}
.os-BtnPickField {
  color: $dark-1;
  font-size: $fs-14;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  height: 44px;
  padding: 0;
  @media (any-hover: hover) {
    .os-BtnPickField_Text {
      @include activityStates($dark-1, 10%, 'color');
    }
  }
}

.os-SortingList {
  list-style-type: none;
  margin-bottom: 0;

  .os-SortingItem {
    color: $dark-1;
    display: flex;
    align-items: center;
    padding: 12px 0;
    font-size: $fs-14;
    line-height: 20px;
    border-radius: $border-radius-sm-next;

    &:hover {
      cursor: pointer;
    }

    .os-SortingItemText {
      margin-left: 8px;
    }
  }
}

// .os-Search {
//   padding-bottom: 12px;
// }

.os-SelectedSortingItem {
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 10000;
  padding: 10px 0; // gap is not good, because of the dragging
}

.os-SelectedSortingItem-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.os-DropdownSelect {
  min-width: 140px;
  width: 100%;
}

.os-SelectedSortingList {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 0;
}

.os-SwitchWrapper {
  padding: 20px 0;
  border-top: 1px solid $grey-2-next;
  border-bottom: 1px solid $grey-2-next;
  &:deep(.ac-Text) {
    font-family: $system-ui;
    line-height: 20px;
  }
}
.os-SelectedSorting_Drag {
  cursor: move;
}
.os-SwitchWrapper_Subtitle {
  color: $dark-3;
}

.os-SortingIcon {
  display: flex;
}
</style>
