<template>
  <div class="wai-Step">
    <div class="wai-Step_Header">
      <AppTitle :level="4" disable-margin>
        {{ getStepContent().title }}
      </AppTitle>

      <div class="wai-Step_Description">
        {{ getStepContent().description }}
      </div>
    </div>

    <AppButton height="24" icon="shortcut" type="primary-next" width="24" @click="onOpenMarkeplace">
      {{ `${t('action.open')} ${t('marketplace')}` }}
    </AppButton>
  </div>

  <div class="wai-Step">
    <div class="wai-Step_Header">
      <AppTitle :level="4" disable-margin>
        {{ getStepContent({ stepNumber: 2 }).title }}
      </AppTitle>
      <div class="wai-Step_Description">
        {{ getStepContent({ stepNumber: 2 }).description }}
      </div>
    </div>

    <div class="wai-Step_Input">
      <AppInput
        v-model="token"
        :disabled="isLoading"
        :is-error="!isTokenValid"
        :placeholder="t('connections.token_placeholder')"
        size="xlg"
        @focus="isTokenValid = true"
      />
      <AppFieldError v-if="!isTokenValid" :show="!isTokenValid">
        {{ t('field.required') }}
      </AppFieldError>
    </div>

    <AppButton
      :disable="isLoading"
      height="24"
      icon="merge-groups"
      type="primary-next"
      width="24"
      @click="showConnectModal"
    >
      {{ t('action.connect') }}
    </AppButton>

    <portal to="modal-windows">
      <AppDialog
        :show="isShowConnectModal"
        :title="t('connect_modal.title')"
        :type="DIALOG_TYPES.INFO"
        @on-confirm="onConfirm"
        @on-close="closeConnectModal"
      >
        <template #confirm-btn-text>
          {{ t('action.connect') }}
        </template>
        <div class="wai-ConnectModal_Description">
          {{ t('connect_modal.description') }}
        </div>

        <AppInfoMessage :type="INFO_MESSAGE_TYPES.WARNING" class="wai-ConnectModal_Message">
          {{ t('connect_modal.message') }}
        </AppInfoMessage>
        <KeywordInput ref="keywordInputReference" :keyword="KEYWORD" />
      </AppDialog>
    </portal>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import WebAppConnectionsApiHandler from '@/api/web-app-connections'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { EVENT_NAMES } from '@/tracking/gtm-helpers'
import { gtmTracker } from '@/tracking/gtm-tracking'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { handleError } from '@/utils/error-handling'
import { openLink } from '@/utils/external-link-helper'
import { INSTANCE_STATES } from '@/utils/instance-states'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppDialog from '@/components/AppDialog'
import AppFieldError from '@/components/form/AppFieldError'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import KeywordInput from '@/components/ui/KeywordInput/KeywordInput'

defineOptions({
  name: 'WebAppIntegration'
})

defineProps({
  isLoading: {
    type: Boolean
  }
})

const emit = defineEmits({
  'update:isLoading': null
})

const { t } = useI18n()

const token = ref('')

const isTokenValid = ref(true)

const store = useStore()

const isShowConnectModal = ref(false)

const KEYWORD = 'CONNECT'

const closeConnectModal = () => {
  isShowConnectModal.value = false
}

const showConnectModal = () => {
  if (!token.value) {
    isTokenValid.value = false
    return
  }
  isShowConnectModal.value = true
}

const keywordInputReference = ref(null)

const onConfirm = async () => {
  const isKeywordValid = keywordInputReference.value.validate()

  if (!isKeywordValid) {
    return
  }

  tracker.logEvent('started jira integration', {
    category: EVENT_CATEGORIES.SETTINGS
  })

  gtmTracker.logEvent(EVENT_NAMES.STARTED_JIRA_APP_CONNECTION)

  closeConnectModal()
  await onConnect()
}

const onConnect = async () => {
  emit('update:isLoading', true)
  try {
    const api = new WebAppConnectionsApiHandler()
    await api.setupConnection({ token: token.value })

    store.dispatch('webApp/setIsConnectionInitializedByMe', true)

    store.dispatch('pluginOptions/setPluginOptions', {
      [PLUGIN_OPTIONS_KEYS.INSTANCE_STATE_ID]: INSTANCE_STATES.CONNECTION_IN_PROGRESS.id
    })

    emit('update:isLoading', false)
  } catch (error) {
    handleError({ error })
  } finally {
    emit('update:isLoading', false)
  }
}

const onOpenMarkeplace = () => {
  openLink(
    'https://marketplace.atlassian.com/apps/1219988/okr-board-for-jira?tab=overview&hosting=cloud'
  )
}

const getStepContent = ({ stepNumber = 1 } = {}) => {
  const stepName = t('step_with_number', stepNumber)

  let secondPart = ''
  let description = ''

  if (stepNumber === 1) {
    secondPart = t('integration_settings.web_app.step1.title')
    description = t('integration_settings.web_app.step1.description')
  }

  if (stepNumber === 2) {
    secondPart = `${t('connections.paste_token_from')} ${t('app_name.jira_plugin')}`
    description = t('integration_settings.web_app.step2.description')
  }

  return {
    title: `${stepName} ${secondPart}`,
    description
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/integrations-steps';

.wai-Step {
  @extend %step-styles;
}

.wai-Step_Description {
  @extend %step-description-styles;
}

.wai-Step_Header {
  @extend %step-header-styles;
}

.wai-Step_Input {
  width: 100%;
}

.wai-ConnectModal_Message {
  margin-block: 20px 28px;
  --bg-color: rgba(var(--grade-medium-color-rgb-next), 0.1);
  --padding: 10px;
  --color: #{$dark-2};
  --font-size: #{$fs-14};
  --line-height: 20px;
  --border-radius: #{$border-radius-sm-next};
}

.wai-ConnectModal_Description {
  color: $dark-2;
  font-size: $fs-14;
  line-height: 20px;
}
</style>
