<template>
  <div class="aic-IconCreator">
    <slot name="first-term" />

    <div class="aic-IconCreator_Symbol">
      <AppIcon height="24" icon-name="plus-small" width="24" />
    </div>

    <slot name="second-term" />

    <template v-if="!hideResult">
      <div class="aic-IconCreator_Symbol">
        <AppIcon height="24" icon-name="equal-small" width="24" />
      </div>
      <FormFieldNext :label="resultLabel">
        <div class="aic-ResultIcon">
          <slot name="result" />
        </div>
      </FormFieldNext>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import FormFieldNext from '@/components/form/FormFieldNext'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'AppIconCreator'
})

const props = defineProps({
  hideResultLabel: {
    type: Boolean
  },

  hideResult: {
    type: Boolean
  }
})

const { t } = useI18n()

const resultLabel = computed(() => {
  return props.hideResultLabel ? null : t('levels.icon')
})
</script>

<style lang="scss" scoped>
.aic-IconCreator {
  display: flex;
  align-items: flex-end;
}

.aic-IconCreator_Symbol {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: $dark-3;
}

.aic-ResultIcon {
  width: 40px;
  height: 40px;
  background-color: $grey-3-next;
  border-radius: $border-radius-sm-next;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
