<template>
  <PageContentHeader
    :remove-divider-on-fullscreen="view === OKR_VIEW_PAGES.MINDMAP"
    is-flex-wrap
    max-width="full-next"
    no-margin
    observe-height
  >
    <template #header-main-content>
      <slot />
    </template>
    <portal-target name="objective-navigation-right" />
  </PageContentHeader>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OkrFilters'
})
</script>

<script setup>
import { OKR_VIEW_PAGES } from '@/utils/objectives'

import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'

defineProps({
  view: {
    type: String,
    default: OKR_VIEW_PAGES.ALIGNMENT
  }
})
</script>

<style lang="scss"></style>
