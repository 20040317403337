<template>
  <AppCheckbox
    v-if="!isNull(item.parentId) && !item.isNestedTask"
    :disabled="!updatable"
    :loading="isLoading"
    :model-value="item.contribute"
    round
    @update:model-value="updateContributeStatus"
  />
</template>

<script setup>
import { isBoolean, isNull } from 'lodash'
import { ref } from 'vue'

import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { handleError } from '@/utils/error-handling'

import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'

defineOptions({
  name: 'ContributeCell',
  inheritAttrs: false
})
const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  updatable: {
    type: Boolean
  }
})

const emit = defineEmits({ 'update-contribute-status': null })

const isLoading = ref(false)
const updateContributeStatus = async status => {
  if (!props.updatable) return
  if (!isBoolean(status)) return
  if (isNull(props.item.parentId)) return

  isLoading.value = true

  try {
    const api = new ObjectivesInfoApiHandler()

    const {
      dueDate,
      elementStartDate,
      jiraIssueId,
      intervalId,
      orderValue,
      parentId,
      levelId,
      workspaceId,
      confidenceLevelId,
      originalValue,
      currentValue,
      targetValue,
      fieldTypeId,
      gradeToUse,
      labelIds,
      name,
      typeId,
      gradeMode
    } = props.item

    let payload = {
      contribute: status,
      dueDate,
      elementStartDate,
      jiraIssueId,
      intervalId,
      name,
      orderValue,
      parentId,
      levelId,
      workspaceId,
      confidenceLevelId,
      originalValue,
      currentValue,
      targetValue,
      fieldTypeId,
      grade: gradeToUse,
      elementId: props.item.id,
      labelIds,
      typeId,
      gradeMode
    }

    const result = await api.updateOkrElement(payload)

    emit('update-contribute-status', { ...props.item, ...result })
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}
</script>

<style scoped></style>
