<template>
  <div class="ain-Wrapper">
    <AddOkrButton
      :disable-margin="disableMargin || simplify"
      :disabled="disabled"
      :is-active="showDropdown"
      :simplify="simplify"
      @click="onClick"
    >
      <slot v-if="$slots.default" />
    </AddOkrButton>

    <AppDroplist
      :follow-cursor="followCursor"
      :model-value="showDropdown"
      :offset="[0, 0]"
      dropdown-width="200px"
      theme="no-shadow-next light"
      trigger="manual"
      @hidden="hideDropdown"
    >
      <div class="ani-Options">
        <div
          v-for="option in dropdownOptions"
          :key="option.id"
          :class="{ 'ani-Options_Item-disabled': option.disabled }"
          class="ani-Options_Item"
          @click="onOptionClick(option.id)"
        >
          <template v-if="option.icon">
            <AppIcon :icon-name="option.icon" height="24" width="24" />
          </template>
          <template v-else>
            <OkrIcon :objective="levelToOkrIconElement(option)" />
          </template>
          <span class="ani-OptionName">{{ option.name || $t(option.title) }}</span>
        </div>
      </div>
    </AppDroplist>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import { levelToOkrIconElement } from '@/utils/objectives'

import AppDroplist from '@/components/AppDroplist'
import AddOkrButton from '@/components/objectives/forms/AddOkrButton'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'AddNestedItem'
})

const showDropdown = ref(false)
const hideDropdown = () => (showDropdown.value = false)

const props = defineProps({
  dropdownOptions: {
    type: Array,
    required: true
  },

  followCursor: {
    type: [String, Boolean],
    default: 'initial'
  },

  simplify: {
    type: Boolean
  },

  disableMargin: {
    type: Boolean
  },

  disabled: {
    type: Boolean
  }
})

const emit = defineEmits(['on-option-click'])

const onOptionClick = id => {
  emit('on-option-click', id)
  hideDropdown()
}

const onClick = () => {
  if (!props.disabled) {
    showDropdown.value = !showDropdown.value
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/form-add-nested-item.scss';

.ani-Options {
  overflow: hidden;
  border-radius: inherit;
}

.ani-Options_Item {
  @extend %nested-item-option;
  &-disabled {
    cursor: not-allowed;
    opacity: var(--disabled-opacity, 0.5);
    &:active {
      pointer-events: none;
    }
  }
}
</style>
