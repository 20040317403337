<template>
  <div class="sts-SubscriptionTierSwitcher">
    <AppButton
      :class="{ 'sts-SubscriptionTierSwitcher_SwitchTierBtn-inactive': period !== YEAR }"
      class="sts-SubscriptionTierSwitcher_SwitchTierBtn"
      remove-padding
      type="link-next"
      @click="updatePeriod(YEAR)"
    >
      {{ $t('subscription.annually_save_count_percent') }}
    </AppButton>
    <AppDivider vertical />
    <AppButton
      :class="{ 'sts-SubscriptionTierSwitcher_SwitchTierBtn-inactive': period !== MONTH }"
      class="sts-SubscriptionTierSwitcher_SwitchTierBtn"
      remove-padding
      type="link-next"
      @click="updatePeriod(MONTH)"
    >
      {{ $t('subscription.monthly') }}
    </AppButton>
  </div>
</template>

<script setup>
import { YEAR, MONTH } from '@/utils/billing-settings'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'

defineProps({
  period: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  'update:period': null
})

const updatePeriod = period => emit('update:period', period)
</script>

<style lang="scss" scoped>
.sts-SubscriptionTierSwitcher {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 24px;
}
.sts-SubscriptionTierSwitcher_SwitchTierBtn {
  font-weight: fw('semi-bold');
  &:hover {
    text-decoration: none;
  }
}
.sts-SubscriptionTierSwitcher_SwitchTierBtn-inactive {
  font-weight: fw('semi-bold');
  color: $dark-2;
}
</style>
