import ApiHandler from '@/api/base'

class LevelApiHandler extends ApiHandler {
  async getLevels({ levelIds = null, workspaceIds = null } = {}) {
    const { data } = await this.http.post('/getLevels', {
      levelIds,
      workspaceIds
    })
    return data
  }

  async createLevel({ color = null, name, prefix = null, orderValue = -1, workspaceIds } = {}) {
    const { data } = await this.http.post('/createLevel', {
      color,
      name,
      prefix,
      workspaceIds,
      orderValue
    })
    return data
  }

  async updateLevel({
    color = null,
    levelId = null,
    name,
    prefix = null,
    workspaceIds,
    orderValue
  } = {}) {
    const { data } = await this.http.post('/updateLevel', {
      color,
      levelId,
      name,
      prefix,
      workspaceIds,
      orderValue
    })
    return data
  }

  async removeLevel({ levelId } = {}) {
    const { data } = await this.http.post('/removeLevel', {
      levelId
    })
    return data
  }

  async getLevelsForFilter({ workspaceId = null } = {}) {
    const { data } = await this.http.post('/getLevelsForFilter', {
      workspaceId
    })
    return data
  }
}

export default LevelApiHandler
