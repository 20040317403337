<template>
  <div class="unc-CellName">
    <AppAvatar :avatar-url="item[avatarUrlKey]" no-margins />

    <AppLink
      v-if="resolvedNameAsLink"
      :to="`${ROUTE_PATHS.WORKSPACES}${ROUTE_PATHS.SETTINGS}/${ROUTE_PATHS.ORGANIZATION}/${item.accountId}`"
    >
      <template #default="{ href, onClick }">
        <a :href="href" class="unc-Name unc-Name_Link" @click.prevent="onClick">
          {{ item[itemLabel] }}
        </a>
      </template>
    </AppLink>
    <span v-else class="unc-Name">
      {{ item[itemLabel] }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import { ROUTE_PATHS } from '@/routes/route-helpers'

import AppLink from '@/components/AppLink'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  nameAsLink: {
    type: Boolean
  },

  itemLabel: {
    type: String,
    default: 'displayName'
  },

  avatarUrlKey: {
    type: String,
    default: 'avatarUrl'
  }
})

const store = useStore()

const hasAccessToGlobalGroupsPage = computed(
  () => store.state.system.userData.hasAccessToGlobalGroupsPage
)

const resolvedNameAsLink = computed(() => hasAccessToGlobalGroupsPage.value && props.nameAsLink)
</script>
<script>
//eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserNameCell'
})
</script>

<style lang="scss" scoped>
.unc-CellName {
  display: flex;
  align-items: center;
  padding-right: 4px;
  gap: 4px;
  max-width: 100%;
  overflow: hidden;
}
.unc-Name {
  margin-left: 4px;
  font-size: $fs-14;
  line-height: 20px;
  font-weight: fw('semi-bold');
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &_Link {
    color: $primary-color-next;
  }
}
</style>
