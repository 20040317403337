<template>
  <AppModalWithConfirmation
    ref="appModalWithConfirmationReference"
    :confirm-close="areDataChanged"
    :show="show"
    :title="$t('groups.new_group')"
    data-auto-testid="create-global-group-modal"
    hide-hero
    @on-close="closeModal"
  >
    <div class="ggm-ModalBody">
      <FormFieldNext :label="$t('group.label.name')">
        <AppInput
          ref="nameReference"
          v-model="formModel.name"
          data-testid="group-name-input"
          max-length="63"
          size="xlg"
          style-type="primary"
        />
      </FormFieldNext>

      <AppIconCreator class="ggm-IconCreator" hide-result-label>
        <template #first-term>
          <GroupIconPicker
            v-model:selected-icon="formModel.icon"
            :selected-color="formModel.color"
          />
        </template>

        <template #second-term>
          <AppPaletteColorPicker
            v-model:selected-color="formModel.color"
            :palette="GROUPS_CATEGORIZED_COLORS"
            data-testid="palette-color-picker"
            style="--thumb-color: var(--dark-2)"
          />
        </template>

        <template #result>
          <GroupIcon :color="formModel.color" :icon-name="formModel.icon" />
        </template>
      </AppIconCreator>

      <GlobalGroupsWorkspaceSelect
        v-model:selected-workspaces="formModel.workspaceIds"
        data-testid="workspaces-select"
      />

      <FormFieldNext :label="$t('global_groups.select_parent')">
        <AppSelect
          v-model="formModel.parentId"
          :loading="isParentGroupsLoading"
          :offset="[0, -40]"
          :options="parentGroupsOptions"
          :search-function="$event => getParentGroups({ searchString: $event })"
          class="ggm-ParentGroupSelect"
          data-testid="parent-group-select"
          dropdown-search
          item-label="name"
          item-value="id"
          skeleton-loader
          skeleton-loader-height="100%"
          skeleton-loader-width="100%"
          theme="no-shadow-next light"
          type="default-next"
          @update:options="parentGroups = $event"
        >
          <template #button-content="{ option }">
            <GlobalGroupsSelectOption
              v-if="option"
              :group="option"
              :is-not-set="option.id === NO_PARENT_GROUP_ID"
              :show-breadcrumbs-tooltip="false"
            />
          </template>
          <template #option-label="{ option }">
            <GlobalGroupsSelectOption
              v-if="option"
              :group="option"
              :is-not-set="option.id === NO_PARENT_GROUP_ID"
            />
          </template>
        </AppSelect>
      </FormFieldNext>
    </div>

    <template #footer-actions>
      <AppButton type="ghost-next" @click="close">
        {{ $t('action.cancel') }}
      </AppButton>
      <AppButton
        :disable="isNameEmpty"
        :loading="loading"
        data-testid="submit-button"
        type="primary-next"
        @click="save"
      >
        {{ $t('action.create') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { cloneDeep, isBoolean, isEmpty, isEqual, isNull, isObject } from 'lodash'
import { computed, nextTick, ref, watch } from 'vue'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, TRACKING_NONE, TRACKING_UNKNOWN } from '@/tracking/amplitude-helpers'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { GROUPS_CATEGORIZED_COLORS, NO_PARENT_GROUP_ID } from '@/utils/global-groups'
import { selectAllIsSelected } from '@/utils/select'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import FormFieldNext from '@/components/form/FormFieldNext'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import GlobalGroupsWorkspaceSelect from '@/components/global-groups/GlobalGroupsWorkspaceSelect'
import GroupIcon from '@/components/global-groups/GroupIcon'
import GroupIconPicker from '@/components/global-groups/GroupIconPicker'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIconCreator from '@/components/ui/AppIconCreator/AppIconCreator'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppPaletteColorPicker from '@/components/ui/AppPaletteColorPIcker/AppPaletteColorPicker'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'GlobalGroupModal'
})

const props = defineProps({
  show: {
    type: Boolean
  },

  predefinedParentGroup: {
    type: [String, Number],
    default: null
  },

  predefinedWorkspaceId: {
    type: [String, Number],
    default: null
  },

  trackingSource: {
    type: String,
    default: TRACKING_UNKNOWN
  }
})

const DEFAULT_FORM_MODEL = {
  name: '',
  color: GROUPS_CATEGORIZED_COLORS.VIOLET[0],
  icon: '1',
  parentId: NO_PARENT_GROUP_ID,
  workspaceIds: []
}

const formModel = ref(cloneDeep(DEFAULT_FORM_MODEL))
const localFormModel = ref(cloneDeep(DEFAULT_FORM_MODEL))
const loading = ref(false)

const areDataChanged = computed(() => {
  return !isEqual(formModel.value, localFormModel.value)
})

const isNameEmpty = computed(() => {
  return formModel.value.name.trim() === ''
})

const emit = defineEmits({
  'update:show': value => isBoolean(value),
  'on-group-created': value => isObject(value) && !isEmpty(value),
  'clear-modal-payload': null
})
const closeModal = () => {
  formModel.value = cloneDeep(DEFAULT_FORM_MODEL)
  localFormModel.value = cloneDeep(DEFAULT_FORM_MODEL)

  emit('update:show', false)
}

const nameReference = ref(null)
const setFocusOnName = () => {
  // nextTick is needed to make input always focused on modal window
  // opening because modal window(o-modal) has transition of opacity with 0.2s
  nextTick(() => {
    setTimeout(() => {
      nameReference.value.focus()
    }, 100)
  })
}

const appModalWithConfirmationReference = ref(null)

const close = () => {
  appModalWithConfirmationReference.value.close()
}

const isAllWorkspacesOptionSelected = computed(() => {
  return selectAllIsSelected(formModel.value.workspaceIds)
})

const parentGroups = ref([])
const isParentGroupsLoading = ref(false)
const getParentGroups = async ({ searchString = null, parentGroupId = null } = {}) => {
  const api = new GlobalGroupsApiHandler()

  try {
    isParentGroupsLoading.value = true
    const resolvedParentGroupId = isNull(searchString) ? parentGroupId : null
    return await api.getParentGroups({
      [REQUEST_ENTITY_KEYS.GROUP_ID]: null,
      [REQUEST_ENTITY_KEYS.PARENT_GROUP_ID]: resolvedParentGroupId,
      searchString
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isParentGroupsLoading.value = false
  }
}

const parentGroupsOptions = computed(() => {
  return [
    {
      id: NO_PARENT_GROUP_ID
    },
    ...parentGroups.value
  ]
})

watch(
  () => props.show,
  async value => {
    if (!value) {
      emit('clear-modal-payload')
      closeModal()
      return
    }

    if (value) {
      if (props.predefinedWorkspaceId) {
        localFormModel.value.workspaceIds = [props.predefinedWorkspaceId]
        formModel.value.workspaceIds = [props.predefinedWorkspaceId]
      }

      parentGroups.value = await getParentGroups({
        [REQUEST_ENTITY_KEYS.PARENT_GROUP_ID]: props.predefinedParentGroup
      })

      if (props.predefinedParentGroup) {
        localFormModel.value.parentId = props.predefinedParentGroup
        formModel.value.parentId = props.predefinedParentGroup
      }

      nextTick(() => {
        setFocusOnName()
      })
    }
  },
  { immediate: true }
)

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})
const save = async () => {
  if (isNameEmpty.value) return

  const api = new GlobalGroupsApiHandler()

  try {
    loading.value = true

    const payload = {
      ...formModel.value,
      name: formModel.value.name.trim(),
      workspaceIds: isAllWorkspacesOptionSelected.value ? [] : formModel.value.workspaceIds,
      addToAllWorkspaces: isAllWorkspacesOptionSelected.value
    }

    const result = await api.createGlobalGroup({
      ...payload
    })

    let parentGroupName = TRACKING_NONE

    if (payload.parentId) {
      parentGroupName = parentGroups.value.find(group => group.id === payload.parentId)?.name
    }

    tracker.logEvent('Created group', {
      category: EVENT_CATEGORIES.GROUP_MANAGEMENT,
      role: userRoleForTracking.value,
      label: payload.name,
      parent: parentGroupName,
      source: props.trackingSource
    })

    emit('on-group-created', result)
    await nextTick()
    closeModal()
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
.ggm-IconCreator {
  --select-width: 148px;
}

.ggm-ModalBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ggm-ParentGroupSelect {
  --select-skeleton-top: 0;
  --select-skeleton-left: 0;
}
</style>
