import { groupBy, has, isEmpty } from 'lodash'
import { computed } from 'vue'

export const useGroupOptions = (options, key = 'group') => {
  const needGrouping = computed(() => {
    return !isEmpty(options) && options.every(item => has(item, key))
  })

  const groups = computed(() => {
    return needGrouping.value ? groupBy(options, key) : { all: options }
  })

  return {
    groups,
    needGrouping
  }
}
