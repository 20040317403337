<template>
  <AppModalWithConfirmation
    :confirm-close="areDataChanged"
    :show="show"
    :size="500"
    :title="
      t('users.add_to_group_userName', {
        userName: user?.name,
        count: groupsToAdd.groupIds.length + 1
      })
    "
    class="autgm-Modal"
    data-auto-testid="add-user-to-group-modal"
    hide-hero
    @on-close="onClose(false)"
  >
    <div class="autgm-ModalBody">
      <div class="autgm-FieldItem">
        <AppSelect
          v-model="groupsToAdd.groupIds"
          :disabled-items="getAlreadyAddedGroupIds"
          :is-error="!groupsToAdd.groupValid"
          :loading="isLoadingGroups"
          :offset="[0, -40]"
          :options="groupsFilterOptions"
          :search-function="searchString => fetchGroupsForFilter({ searchString, workspaceIds })"
          boundary="scrollParent"
          content-class="autgm-SelectContent"
          dropdown-search
          item-label="name"
          item-value="id"
          multi
          theme="no-shadow-next light"
          type="default-next"
          @open="fetchGroups"
          @opened="removeError"
          @update:options="groupsFilterOptions = $event"
        >
          <template #option="optionProps">
            <AppSelectListItem
              :class="{
                'autgm-SelectOption-alreadyAdded': checkIfGroupAlreadyAdded(optionProps.option)
              }"
              class="autgm-SelectOption"
              type="default-next"
              v-bind="optionProps"
            >
              <template v-if="checkIfGroupAlreadyAdded(optionProps.option)" #checkmark>
                <AppCheckbox
                  :model-value="checkIfGroupAlreadyAdded(optionProps.option)"
                  :value="true"
                  disabled
                  size="sm"
                />
              </template>
              <template #option-label>
                <GlobalGroupsSelectOption v-if="optionProps.option" :group="optionProps.option">
                  <template v-if="checkIfGroupAlreadyAdded(optionProps.option)" #badge>
                    {{ $t('badge.already_added') }}
                  </template>
                </GlobalGroupsSelectOption>
              </template>
            </AppSelectListItem>
          </template>
          <template #button-content="{ options }">
            <div v-if="isEmpty(options)">
              {{ $t('objective.select_group') }}
            </div>
          </template>
        </AppSelect>
        <AppFieldError
          v-if="!groupsToAdd.groupValid"
          :show="!groupsToAdd.groupValid"
          class="autgm-Error"
        >
          {{ $t('field.required') }}
        </AppFieldError>
        <FormFieldNext label="">
          <WorkspacesAffectMessage
            :limit="2"
            :workspaces="affectedWorkspaces"
            class="rufgm-AffectedWs"
          >
            {{ $t('global_groups.will_be_removed_from_ws', affectedWorkspaces.length) }}:
          </WorkspacesAffectMessage>
        </FormFieldNext>
      </div>
    </div>
    <template #footer-actions>
      <AppButton type="ghost-next" @click="onClose(false)">
        {{ $t('action.cancel') }}
      </AppButton>
      <AppButton :disable="isLoading" :loading="isLoading" type="primary-next" @click="save">
        {{ $t('action.add_user_qty', groupsToAdd.length) }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { useGlobalGroups } from '@/composables/global-groups'
import { trackAddUserEvent, TRACKING_UNKNOWN } from '@/tracking/amplitude-helpers'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { objectOrNullProp } from '@/utils/prop-validators'
import { uid } from '@/utils/uid'
import { IDENTIFIER_TYPE_IDS } from '@/utils/web-app/emails-in-select'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import WorkspacesAffectMessage from '@/components/global-groups/WorkspacesAffectMessage'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppSelectListItem from '@/components/ui/AppSelect/AppSelectListItem'

defineOptions({
  name: 'AddUsersToGroupModal'
})

const props = defineProps({
  user: {
    required: true,
    validator: v => objectOrNullProp(v)
  },

  show: {
    type: Boolean
  },

  workspaceIds: {
    type: Array,
    required: true
  },

  trackingSource: {
    type: String,
    default: TRACKING_UNKNOWN
  }
})

const { fetchGroupsForFilter } = useGlobalGroups()

const groupsFilterOptions = ref([])
const isLoadingGroups = ref(false)
const fetchGroups = async () => {
  try {
    isLoadingGroups.value = true
    groupsFilterOptions.value = await fetchGroupsForFilter({ workspaceIds: props.workspaceIds })
  } catch (error) {
    handleError({ error })
  } finally {
    isLoadingGroups.value = false
  }
}
const areDataChanged = computed(() => {
  return groupsToAdd.value.groupIds.length > 0
})

const DEFAULT_USER_ITEM = {
  accountId: null,
  accountValid: true
}
const DEFAULT_GROUP_ITEM = {
  groupIds: [],
  groupValid: true
}
const groupsToAdd = ref({
  id: uid(),
  ...DEFAULT_USER_ITEM,
  ...DEFAULT_GROUP_ITEM
})

const emit = defineEmits(['update:show', 'reload-data'])

const onClose = (reloadData = false) => {
  groupsToAdd.value = {
    id: uid(),
    ...DEFAULT_USER_ITEM,
    ...DEFAULT_GROUP_ITEM
  }
  emit('update:show', false)
  if (reloadData) {
    emit('reload-data')
  }
}

const { t } = useI18n()

const isLoading = ref(false)

const save = async () => {
  groupsToAdd.value.groupValid = !!groupsToAdd.value.groupIds.length

  if (!groupsToAdd.value.groupValid) return

  isLoading.value = true

  const payload = [
    {
      [REQUEST_ENTITY_KEYS.IDENTIFIER]: props.user.accountId,
      groupIds: groupsToAdd.value.groupIds,
      [REQUEST_ENTITY_KEYS.IDENTIFIER_TYPE_ID]: IDENTIFIER_TYPE_IDS.ACCOUNT_ID
    }
  ]

  try {
    const api = new GlobalGroupsApiHandler()

    await api.addUsersToGlobalGroups({ items: payload })

    trackAddUserEvent({
      selectedUsers: [props.user],
      source: props.trackingSource
    })

    onClose(true)
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const removeError = () => {
  groupsToAdd.value.groupValid = true
}

const affectedWorkspaces = computed(() => {
  const workspaces = groupsFilterOptions.value.find(group =>
    groupsToAdd.value.groupIds?.includes(group.id)
  )?.workspaces

  return workspaces || []
})

const getAlreadyAddedGroupIds = computed(() => {
  return props.user?.groups.map(ws => ws.id) || []
})

const checkIfGroupAlreadyAdded = group => {
  return getAlreadyAddedGroupIds.value.includes(group.id)
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.autgm-ModalBody {
  overflow-y: auto;
  max-height: calc(
    100vh - (60px * 2) - 44px - 28px - 20px - 22px - 92px - 32px - 20px
  ); // where 60 * 2 is Y margin of modal; 44px is modal head height; 28px is modal title height; 20px is modal title margin bottom; 22px is fields head height; 92px is modal footer height; 32px is radioGroup height
  padding: 0 40px;
  @include styled-native-scrollbar();
}
.autgm-FieldItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0;
}
.autgm-SelectOption {
  --checkmark-color: #{$grey-1-next};

  &-alreadyAdded {
    :deep(.o-checkbox-label-text) {
      padding-left: 12px;
    }
  }
}
</style>

<style lang="scss">
.autgm-Modal {
  .ad-Content_Title {
    padding: 0 40px;
  }
  .o-modal-content {
    .o-modal-body {
      padding: 0;
    }
  }
  // --footer-padding-top: 0;
  --dialog-content-padding-right: 0;
  --dialog-content-padding-left: 0;
}
.autgm-SelectContent {
  .o-checkbox-label-inner,
  .o-checkbox-label-text {
    width: 100%;
  }

  .o-checkbox-disabled {
    opacity: 1;
  }
}
</style>
