<template>
  <FormFieldNext :label="$t('custom_fields.currency')">
    <AppInput
      v-model="localModelValue"
      :data-testid="CUSTOM_FIELD_MODAL_CURRENCY_VALUE_INPUT_TEST_ID"
      :is-error="!validationStatus[REQUIRED_STATUS]"
      :max-length="3"
      :placeholder="$t('custom_fields.currency.placeholder')"
      size="xlg"
      style-type="primary"
      @focus="onFocus"
    />

    <AppFieldError
      v-if="!validationStatus[REQUIRED_STATUS]"
      :data-testid="CUSTOM_FIELD_MODAL_CURRENCY_FIELD_ERROR_TEST_ID"
      :show="!validationStatus[REQUIRED_STATUS]"
    >
      {{ $t('field.required') }}
    </AppFieldError>

    <AppInfoMessage class="cf-Message">
      {{
        $t('validation.input_number.allowed_characters', {
          integerMaxLength: MAX_LENGTH
        })
      }}
    </AppInfoMessage>
  </FormFieldNext>
</template>

<script setup>
import { computed } from 'vue'

import { ADDITIONAL_PARAMS_SETTINGS } from '@/utils/custom-fields/factory'
import { REQUIRED_STATUS } from '@/utils/custom-fields/helpers'
import {
  CUSTOM_FIELD_MODAL_CURRENCY_FIELD_ERROR_TEST_ID,
  CUSTOM_FIELD_MODAL_CURRENCY_VALUE_INPUT_TEST_ID
} from '@/utils/custom-fields/jest-helpers'

import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppInput from '@/components/ui/AppInput/AppInput'

defineOptions({
  name: 'CurrencyField',
  inheritAttrs: false
})
const MAX_LENGTH = 3

const props = defineProps({
  modelValue: {
    type: [Number, String],
    required: true
  },

  validationStatus: {
    type: Object,
    required: true
  }
})

const emit = defineEmits({
  'update:model-value': null,
  'remove-error': null
})

const localModelValue = computed({
  get: () => props.modelValue,
  set: value => {
    emit('update:model-value', value.toUpperCase())
  }
})

const onFocus = () => {
  emit('remove-error', {
    fieldKey: ADDITIONAL_PARAMS_SETTINGS.CURRENCY.typeId
  })
}
</script>

<style lang="scss" scoped>
.cf-Message {
  margin-top: 2px;
}
</style>
