<template>
  <div class="op-OkrPage">
    <ObjectiveTable
      ref="table"
      :first-level-draggable="listState.view === pages.ALIGNMENT"
      :hideable-krs="listState.view === pages.OKREXPLORER"
      :objective-levels="objectiveLevels"
      :show-filter-buttons="listState.view === pages.ALIGNMENT"
      :tab-name="navigationTabName"
      :transparent-filtered="listState.view === pages.ALIGNMENT"
      :user-can-create-objectives="userCanCreateObjectives"
      :with-krs-list="withKrsList"
      :workspace-id="workspaceId"
      no-padding-bottom
      offset-left="var(--page-left-padding)"
      offset-right="var(--page-right-padding)"
      resizeable-columns
      @create-objective="onCreateObjectiveClick($event.typeId)"
      @reset-search-criteria="$emit('reset-search-criteria', $event)"
      @load-more="$emit('load-more', $event)"
      @update-okr-data="$emit('update-data', $event)"
      @expand-all="$emit('expand-all')"
      @collapse-all="$emit('collapse-all')"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'

import { TRACKING_UNKNOWN } from '@/tracking/amplitude-helpers'
import { currentUserCanCreateObjective, OKR_VIEW_PAGES } from '@/utils/objectives'

import ObjectiveTable from '@/components/objectives/table/ObjectiveTable'

export default defineComponent({
  name: 'OkrElementsTablePage',

  components: {
    ObjectiveTable
  },

  inject: ['listState'],
  emits: {
    'reset-search-criteria': null,
    'load-more': null,
    'update-data': null,
    'create-objective': null,
    'expand-all': null,
    'collapse-all': null
  },

  computed: {
    ...mapState('objectives', {
      objectiveLevels: state => state.levels
    }),

    ...mapGetters('workspaces', {
      selectedWorkspace: 'selectedWorkspace'
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin
    }),

    pages: () => OKR_VIEW_PAGES,

    // used for analytics
    navigationTabName() {
      if (this.listState.view === this.pages.OKREXPLORER) {
        return 'OKR Explorer'
      }
      if (this.listState.view === this.pages.ALIGNMENT) {
        return 'Alignment'
      }
      return TRACKING_UNKNOWN
    },

    withKrsList() {
      return this.listState.view === this.pages.OKREXPLORER
    },

    workspaceId() {
      return this.$route.params.workspaceId
    },

    userCanCreateObjectives() {
      return currentUserCanCreateObjective(this.selectedWorkspace, this.isPluginAdmin)
    }
  },

  methods: {
    async onCreateObjectiveClick(levelId) {
      this.$emit('create-objective', {
        levelId
      })
    },

    /** @public */
    requestData(expandAll) {
      this.$refs.table.requestData(expandAll)
    }
  }
})
</script>

<style lang="scss" scoped>
.op-OkrPage {
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
}
</style>
