<template>
  <div v-if="isNotSet" class="ggs-Option_CurrentGroup">
    <AppIcon height="24" icon-name="unassigned-owner" width="24" />

    <span class="oboard-truncated-text"> {{ $t('global_groups.without_parent_group') }} </span>
  </div>
  <div
    v-else
    v-tippy="{
      content: fullPath,
      placement: 'bottom-start',
      theme: 'word-break translucent-next',
      delay: [1000, null],
      followCursor: 'initial'
    }"
    class="ggs-Option"
  >
    <template v-if="isShowImmediateParent">
      <GroupIcon
        v-if="isParentGroupAvailable"
        :color="parentGroup.color"
        :icon-name="parentGroup.icon"
      />
      <UnavailableGroup v-else />

      <AppIcon
        :height="BREADCRUMB_ARROW_SIZE"
        :width="BREADCRUMB_ARROW_SIZE"
        class="ggs-BreadcrumbArrow"
        icon-name="group-parent-arrow"
      />
    </template>
    <div :style="{ 'max-width': maxWidth }" class="ggs-Option_CurrentGroup oboard-truncated-text">
      <GroupIcon :color="group.color" :icon-name="group.icon" />
      <span class="ggs-CurrentGroupName oboard-truncated-text">
        {{ group.name }}
      </span>
    </div>
    <div v-if="$slots.badge" class="ggs-Option_Badge">
      <slot name="badge" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { GLOBAL_GROUPS_SELECT_OPTION_PARAMS, isGroupUnavailable } from '@/utils/global-groups'
import { memoizeGetCurrentGroupMaxWidth } from '@/utils/memoizations'

import GroupIcon from '@/components/global-groups/GroupIcon'
import UnavailableGroup from '@/components/global-groups/UnavailableGroup'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'GlobalGroupsSelectOption'
})

const props = defineProps({
  group: {
    type: Object,
    required: true
  },

  isNotSet: {
    type: Boolean
  },

  showBreadcrumbsTooltip: {
    type: Boolean,
    default: true
  }
})

const { BREADCRUMB_ARROW_SIZE } = GLOBAL_GROUPS_SELECT_OPTION_PARAMS

const depth = computed(() => props.group.parents?.length || 0)

const parentGroup = computed(() => {
  const resolvedParents = props.group.parents || []
  const [parentGroup] = resolvedParents.slice(-1)
  return parentGroup
})

const isShowImmediateParent = computed(() => !!parentGroup.value)

const isParentGroupAvailable = computed(() => {
  return isShowImmediateParent.value && !isGroupUnavailable(parentGroup.value)
})

const maxWidth = computed(() => {
  const isRootLevel = !depth.value

  return memoizeGetCurrentGroupMaxWidth({
    isRootLevel,
    isShowParent: isShowImmediateParent.value
  })
})

const fullPath = computed(() => {
  if (!props.showBreadcrumbsTooltip) {
    return null
  }
  if (!depth.value) {
    return null
  }
  const { parents } = props.group

  return [...parents, props.group]
    .map(group => {
      return group.name || '∗∗∗∗∗∗'
    })
    .join(' / ')
})
</script>

<style lang="scss" scoped>
.ggs-Option {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}
.ggs-Option_CurrentGroup {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 auto;
}

.ggs-BreadcrumbArrow {
  flex-shrink: 0;
}
.ggs-Option_Badge {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  color: $grey-1-next;
  margin-left: auto;
}
</style>
