<template>
  <template v-if="item.showMoreButton">
    <SavedFiltersDropDown
      :filters="item.dropdownItems"
      :placement="item.placement"
      @select-filter="onFilterSelect($event, item.placement)"
    >
      <template #title>{{ item.name }}</template>
    </SavedFiltersDropDown>
  </template>
  <span
    v-else-if="
      (item.children && item.showChildrenLikeAdditionalMenu) ||
      item.customFilter ||
      item.customDashboard ||
      item.hasExternalClickListener ||
      item.comingSoon
    "
    v-tippy="{
      content: isHorizontalLayout ? displayText : null
    }"
    :class="classes"
    @click="onMenuItemClick"
  >
    <AppIcon
      v-if="item.icon"
      :icon-name="item.icon"
      class="amni-Item_Icon"
      height="24"
      width="24"
    />
    <SavedFiltersWarningIcon v-if="item.customFilter && item.hasUnexpectedCustomFields" />
    <span
      v-if="!isHorizontalLayout"
      :title="item.showNativeTooltip ? displayText : null"
      class="amni-Item_Text"
    >
      <slot :label="displayText" :name="`${item.name}-item-label`">
        {{ displayText }}
      </slot>

      <span v-if="item.description" class="amni-ItemTextDescription">
        {{ $t(item.description) }}
      </span>
    </span>
    <AppBadge
      v-if="item.comingSoon"
      :content="$t('badge.soon')"
      class="amni-ItemBadge"
      inline
      is-uppercase
    />
    <template v-else>
      <span
        v-if="$slots[`${item.name}-item-append`] || item.hasActionsMenu"
        class="amni-Item_Actions"
      >
        <slot :name="`${item.name}-item-append`">
          <MenuItemActions :item="item" :placement="item.placement" append-to=".app-Aside" />
        </slot>
      </span>
    </template>
  </span>

  <AppLink v-else :to="to" @click="$emit('item-click', item)">
    <template #default="{ href, onClick, isActive }">
      <a
        v-tippy="{
          content: isHorizontalLayout ? displayText : null
        }"
        :class="{
          ...classes,
          'amni-Item-router-active': isActive && item.addRouterActiveClassFromRouterLinkComponent
        }"
        :href="href"
        @click.prevent="onClick"
      >
        <AppIcon
          v-if="item.icon"
          :icon-name="item.icon"
          class="amni-Item_Icon"
          height="24"
          width="24"
        />
        <span
          v-if="!isHorizontalLayout"
          :title="item.showNativeTooltip ? displayText : null"
          class="amni-Item_Text"
        >
          <slot :key-label="item.label" :label="displayText" :name="`${item.name}-item-label`">
            {{ displayText }}
          </slot>

          <span v-if="item.description" class="amni-ItemTextDescription">
            {{ $t(item.description) }}
          </span>
        </span>
        <span
          v-if="$slots[`${item.name}-item-append`]"
          :class="{ [`amni-Item_Actions-${item.name}`]: !!item.name }"
          class="amni-Item_Actions"
          @click.prevent.stop
        >
          <slot :name="`${item.name}-item-append`" />
        </span>
      </a>
    </template>
  </AppLink>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import { useBackSettingsBackButton } from '@/composables/settings-back-button'
import { ROUTE_NAMES, ROUTE_PATHS } from '@/routes/route-helpers'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_NAMES, EVENT_SECTIONS } from '@/tracking/amplitude-helpers'
import { APP_MENU_NAV_LAYOUTS } from '@/utils/app-menu'
import { menuItemsNames } from '@/utils/menu-items'
import { FILTER_PRESET_KEY } from '@/utils/query-parameters'

import AppLink from '@/components/AppLink'
import MenuItemActions from '@/components/AppMenu/MenuItemActions'
import { DASHBOARD, FILTERS } from '@/components/AppMenu/props-validators'
import SavedFiltersDropDown from '@/components/AppMenu/SavedFiltersDropDown'
import SavedFiltersWarningIcon from '@/components/AppMenu/SavedFiltersWarningIcon'
import AppBadge from '@/components/ui/AppBadge/AppBadge'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

export default defineComponent({
  name: 'AppMenuNavItem',

  components: {
    AppBadge,
    SavedFiltersDropDown,
    SavedFiltersWarningIcon,
    MenuItemActions,
    AppLink,
    AppIcon
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    listChildren: {
      type: Boolean
    },

    listType: {
      type: String,
      default: 'default',
      validator: v => ['default', 'additionalMenu'].includes(v)
    },

    layout: {
      type: String,
      default: APP_MENU_NAV_LAYOUTS.VERTICAL,
      validator: v => Object.values(APP_MENU_NAV_LAYOUTS).includes(v)
    },

    disabled: {
      type: Boolean
    }
  },

  emits: { 'set-active-additional-menu': null, 'item-click': null },

  setup() {
    const { saveSettingsReferer } = useBackSettingsBackButton()

    return { saveSettingsReferer }
  },

  computed: {
    ...mapState('workspaces', {
      workspaceId: state => state.workspaceId
    }),

    isHorizontalLayout() {
      return this.layout === APP_MENU_NAV_LAYOUTS.HORIZONTAL
    },

    classes() {
      return {
        'amni-Item': true,
        'amni-Item-children': this.listChildren,
        'amni-Item-exact-active': this.itemExactActive,
        [`amni-Item-${this.listType}`]: true,
        'amni-Item-with-action': this.$slots[`after-text-${this.item.name}`] && !this.listChildren,
        'amni-Item-with-description': this.item.description,
        'amni-Item-with-icon-badge': this.item.showBadgeOnIcon,
        'amni-Item-disabled': this.item.comingSoon || this.disabled
      }
    },

    to() {
      const { query, path } = this.item
      return query ? { query, path } : { path }
    },

    itemExactActive() {
      /*      const { $route, to, item } = this
      if (item.query) {
        return !Object.keys(item.query).some(key => this.$route.query[key] !== item.query[key])
      } else if (item.showChildrenLikeAdditionalMenu) {
        return item.children.some(children => $route.path === children.path)
      } else if (item.children && !item.showChildrenLikeAdditionalMenu) {
        return $route.path.includes(to.path) && !$route.query[FILTER_PRESET_KEY]
      } else {
        return $route.path === to.path && !$route.query[FILTER_PRESET_KEY]
      }*/

      const { $route, to, item } = this

      if (item.comingSoon) {
        return false
      }

      const isItemHasChildrenButNotAdditionalMenu =
        item.children && !item.showChildrenLikeAdditionalMenu

      if (item.query) {
        return !Object.keys(item.query).some(key => this.$route.query[key] !== item.query[key])
      }

      if (item.showChildrenLikeAdditionalMenu) {
        const matchedRoutePaths = $route.matched.map(route => route.path)

        return (
          item.children.some(children => $route.path === children.path) ||
          item.children.some(children => matchedRoutePaths.includes(children.path))
        )
      }

      if (isItemHasChildrenButNotAdditionalMenu) {
        const { name } = item

        const defaultCondition = $route.path.includes(to.path) && !$route.query[FILTER_PRESET_KEY]

        if (name === menuItemsNames.OBJECTIVES.name) {
          return defaultCondition
        }

        if (name === menuItemsNames.DASHBOARD.name) {
          return $route.path.includes(to.path) && $route.name !== ROUTE_NAMES.CUSTOM_DASHBOARD_ITEM
        }

        return defaultCondition
      }

      return $route.path === to.path && !$route.query[FILTER_PRESET_KEY]
    },

    displayText() {
      const { item } = this
      return item.label ? this.$t(item.label) : item.name
    }
  },

  methods: {
    onFilterSelect(id, placement) {
      const currentDropdownItem = this.item.dropdownItems.find(item => item.id === id)

      const trackerData = {
        category: EVENT_CATEGORIES.MAIN_NAVIGATION,
        label: currentDropdownItem?.name || 'unknown label'
      }

      if (placement === FILTERS) {
        trackerData.section = EVENT_SECTIONS.OBJECTIVES

        this.$router.push({
          path: `${ROUTE_PATHS.WORKSPACES}${this.workspaceId}/${ROUTE_PATHS.OBJECTIVES}/${ROUTE_PATHS.ALIGNMENT}/${ROUTE_PATHS.OKR_ELEMENTS_HIERARCHY_TABLE}`,
          query: { [FILTER_PRESET_KEY]: id }
        })
      }

      if (placement === DASHBOARD) {
        trackerData.section = EVENT_SECTIONS.DASHBOARD

        this.$router.push({
          path: `${ROUTE_PATHS.WORKSPACES}${this.workspaceId}/${ROUTE_PATHS.DASHBOARD}/${ROUTE_PATHS.CUSTOM_DASHBOARD}/${ROUTE_PATHS.CUSTOM_DASHBOARD_ITEM}/${id}`
        })
      }

      tracker.logEvent(EVENT_NAMES.SECTION_OPENED, trackerData)
    },

    onMenuItemClick() {
      const { item } = this
      if (item.children && item.showChildrenLikeAdditionalMenu) {
        if (item.path && !item.thisAdditionalMenuOpened) {
          this.$router.push(item.path)
        }

        this.$emit('set-active-additional-menu', item.name)

        if (item.name === menuItemsNames.SETTINGS.name) {
          this.saveSettingsReferer()
        }
      }
      this.$emit('item-click', item)
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-menu';

.amni-Item {
  // padding: 8px 10px;
  padding: 8px;
  @extend %nav-item-styles;

  &:not(&-children) {
    min-height: $menu-item-min-height;
  }

  @media (any-hover: hover) {
    &:hover {
      color: $primary-color-next;
      --color: #{$primary-color-next};
    }
  }

  &-children {
    min-height: 32px;
  }

  &-router-active,
  &-active,
  &-exact-active {
    &:not(.amni-Item-children) {
      // color: $primary-color-next;
      color: $white;
      background-color: $primary-color-next;
      // --color: #{$primary-color-next};
      --color: #{$white};

      @media (any-hover: hover) {
        &:hover {
          color: $white;
          --color: #{$white};
        }
      }
    }

    &.amni-Item-children {
      background-color: rgba($primary-color-next, 0.15);
      color: $dark-2;
    }

    .amni-ItemTextDescription {
      color: $white;
    }
  }

  &-children {
    &:not(.amni-Item-additionalMenu) {
      // temporary padding 42px until pin/unpin button implemented
      // padding: 10px 10px 10px 50px; // where 50 is 10px padding + 24px icon + 16px gap
      padding: 4px 4px 4px 36px; // where 36 is 4px padding + 24px icon + 8px gap
    }

    &.amni-Item-additionalMenu {
      padding: 10px 4px 10px 32px; // where 32 is 24px icon + 8px gap
    }
  }

  &-with-action {
    padding-right: 20px;
  }

  &-with-description {
    align-items: flex-start;
  }

  &-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &-additionalMenu {
    align-items: flex-start;
  }

  &-with-icon-badge {
    position: relative;

    &:after {
      pointer-events: none;
      content: '';
      position: absolute;
      right: 8px;
      top: 8px;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background-color: $grade-low-color-next;
    }
  }
}

.amni-Item_Icon {
  // color: $dark-2;
  color: inherit;
  flex: 0 0 auto;
  transition: color $menu-transition;

  // .amni-Item-active &,
  // .amni-Item-exact-active &,
  // .amni-Item-router-active & {
  //   color: $primary-color-next;
  // }
  //
  // .amni-Item:hover & {
  //   color: $primary-color-next;
  // }
}

.amni-Item_Text {
  font-style: normal;
  font-weight: fw('normal');
  font-size: $fs-14;
  transition: color $menu-transition;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  // .amni-Item-active &,
  // .amni-Item-exact-active &,
  // .amni-Item-router-active & {
  //   font-weight: fw('semi-bold');
  // }

  &:not(.amni-Item-children &) {
    font-weight: fw('semi-bold');
  }

  .amni-Item-children & {
    font-size: $fs-12;
  }

  .amni-Item-with-description & {
    // padding-top: 2px;
  }

  .amni-Item-additionalMenu & {
    padding-top: 2px;
    line-height: 20px;
    white-space: normal;
  }
}

.amni-ItemTextDescription {
  display: block;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  padding-top: 3px;
  color: $dark-3;
  white-space: pre-line;
}

.amni-Item_Actions {
  margin-left: auto;
  max-width: 24px; // Safari fix. Expanded width when opening the menu
  display: flex;

  &-dashboard {
    max-width: 32px; // Safari fix. Expanded width when opening the menu
    display: flex;
    justify-content: center;
    z-index: 0;
  }

  &-warning {
    max-width: 48px;
    display: flex;
    align-items: center;
  }
}

.amni-ItemBadge {
  --color: var(--dark-3);
  --size: 12px;
  --padding: 2px 4px;
  z-index: 0;
  flex: 0 0 auto;
  align-self: center;
}
</style>
