<template>
  <div class="ofs-Divider" />
  <AppDroplist
    v-model="showForm"
    :has-fixed-parent="true"
    :hide-on-click="false"
    max-width="372px"
    position="bottom"
    theme="no-shadow-next light"
    trigger="manual"
  >
    <template #button>
      <div
        ref="trigger"
        :class="{ 'ofs-Trigger-loading': loading }"
        class="ofs-Trigger"
        data-testid="filter-saver-trigger"
        @click="onTriggerClick"
      >
        <LoadingCircle v-if="loading && updateSavedFilter" color="#0052CC" size="xsmall" />
        {{ triggerText }}
      </div>
    </template>
    <div v-click-away="onClickOutside">
      <OkrFilterSaverForm
        v-if="showForm"
        :form-model="formModel"
        is-create-filter
        @close-form="showForm = false"
      />
    </div>
  </AppDroplist>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import OkrFiltersApiHandler from '@/api/okr-filters'
import {
  CUSTOM_FIELDS_FILTERS_QUERY_KEY,
  getResolvedCustomFieldFiltersValues
} from '@/utils/custom-fields/helpers'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { prepareFiltersPayload } from '@/utils/okr-custom-filters'
import { FILTER_PRESETS } from '@/utils/okr-elements/filters'
import { FILTER_PRESET_KEY } from '@/utils/query-parameters'

import AppDroplist from '@/components/AppDroplist'
import OkrFilterSaverForm from '@/components/objectives/toolbar/OkrFilterSaverForm'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'

export default defineComponent({
  name: 'OkrFilterSaver',

  components: {
    LoadingCircle,
    OkrFilterSaverForm,
    AppDroplist
  },

  props: {
    filtersValues: {
      type: Object,
      required: true
    },

    customFieldsFiltersValues: {
      type: Object,
      required: true
    },

    haveCustomFieldFiltersDefaultValues: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      showForm: false,
      loading: false
    }
  },

  computed: {
    ...mapGetters('okrFilters', {
      currentSavedFilter: 'currentSavedFilter'
    }),

    formModel() {
      return {
        name: '',
        filtersValues: this.filtersValues,
        customFieldsFiltersValues: this.customFieldsFiltersValues,
        haveCustomFieldFiltersDefaultValues: this.haveCustomFieldFiltersDefaultValues
      }
    },

    updateSavedFilter() {
      const routeFilterPreset = this.$route.query[FILTER_PRESET_KEY]

      if (routeFilterPreset) {
        const isDefaultFilterPreset = Object.values(FILTER_PRESETS).includes(routeFilterPreset)
        const savedFilterExist = Boolean(this.currentSavedFilter(Number(routeFilterPreset)))

        if (isDefaultFilterPreset || !savedFilterExist) {
          return false
        } else {
          return savedFilterExist
        }
      }
      return false
    },

    triggerText() {
      return this.updateSavedFilter ? this.$t('filters.update_filter') : this.$t('filters.save_as')
    }
  },

  methods: {
    onClickOutside(e) {
      if (this.showForm) {
        const isTargetIgnore = e.composedPath().includes(this.$refs.trigger)
        if (isTargetIgnore) {
          return
        }
        this.showForm = false
      }
    },

    async updateSavedFilterView() {
      this.loading = true
      const api = new OkrFiltersApiHandler()

      const currentFilter = this.$store.state.okrFilters.savedFilters.find(filter => {
        return filter.id === Number(this.$route.query[FILTER_PRESET_KEY])
      })

      const { filtersValues, customFieldsFiltersValues, haveCustomFieldFiltersDefaultValues } = this

      const payload = {
        accountId: this.$store.state.system.userData.userAccountId,
        name: currentFilter.name,
        filters: prepareFiltersPayload(
          {
            ...filtersValues,
            [CUSTOM_FIELDS_FILTERS_QUERY_KEY]: getResolvedCustomFieldFiltersValues({
              customFieldsFiltersValues,
              haveCustomFieldFiltersDefaultValues
            })
          },
          this.$store.state.workspaces.workspaceId
        ),

        filterViewId: currentFilter.id
      }

      try {
        const updatedFilter = await api.updateFilterViewForUser(payload)
        this.$store.dispatch('okrFilters/updateFilter', updatedFilter)
        showNotify({
          title: this.$t('filters_saver.filter_updated', { filterName: currentFilter.name })
        })

        this.loading = false
      } catch (error) {
        this.loading = false
        handleError({ error })
      }

      this.loading = false
    },

    onTriggerClick() {
      if (this.updateSavedFilter) {
        this.updateSavedFilterView()
      } else {
        this.showForm = !this.showForm
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_mixins.scss';
.ofs-Trigger {
  font-family: $system-ui;
  font-size: $fs-14;
  line-height: 20px;
  font-style: normal;
  font-weight: fw('semi-bold');
  color: $primary-color-next;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: $border-radius-sm-next;
  gap: 8px;
  display: flex;
  align-items: center;
  height: 100%;

  @include activityStates($primary-color-next, 10%, 'color');
  &-loading {
    padding-left: 8px;
  }
}
.ofs-Divider {
  background: $grey-2-next;
  height: 32px;
  width: 1px;
}
</style>
