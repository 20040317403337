<template>
  <div class="cfl-Label">
    <span class="cfl-Label_Text oboard-truncated-text">
      <slot>
        {{ fieldName }}
      </slot>
    </span>
    <TooltipButtonInfo
      v-if="!hideHelpText"
      :content="helpText"
      :translate-content="false"
      arrow
      class="cfl-Icon"
      placement="top"
      theme="translucent-next word-break"
    />
  </div>
</template>

<script setup>
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'

import TooltipButtonInfo from '@/components/TooltipButtonInfo'

defineOptions({
  name: 'CustomFieldLabel'
})

const props = defineProps({
  fieldId: {
    type: [String, Number],
    required: true
  },

  hideHelpText: {
    type: Boolean
  }
})

const { helpText, fieldName } = useCustomFieldsHelpers({ fieldId: props.fieldId })
</script>

<style lang="scss" scoped>
.cfl-Label {
  display: flex;
  align-items: center;
}
.cfl-Icon {
  color: $dark-3;
  flex-shrink: 0;
  pointer-events: all;
}
</style>
