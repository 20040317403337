import { ROUTE_NAMES, ROUTE_PATHS } from '@/routes/route-helpers'
import BillingLayout from '@web-app/views/workspaces/settings/billing/billing'
import BillingInformationSettings from '@web-app/views/workspaces/settings/billing/billing-information-settings'
import BillingPromo from '@web-app/views/workspaces/settings/billing/billing-promo'
import BillingSettings from '@web-app/views/workspaces/settings/billing/billing-settings'
// import BrandingSettings from '@/views/workspaces/settings/branding-settings'

export const WEB_APP_SETTINGS_ROUTES = [
  {
    path: ROUTE_PATHS.SETTINGS_BILLING,
    name: ROUTE_NAMES.SETTINGS_BILLING,
    component: BillingLayout,
    redirect: { name: ROUTE_NAMES.SETTINGS_BILLING_SETTINGS },
    children: [
      {
        path: ROUTE_PATHS.SETTINGS_BILLING_SETTINGS,
        name: ROUTE_NAMES.SETTINGS_BILLING_SETTINGS,
        component: BillingSettings
      },
      {
        path: ROUTE_PATHS.SETTINGS_BILLING_INFORMATION,
        name: ROUTE_NAMES.SETTINGS_BILLING_INFORMATION,
        component: BillingInformationSettings
      },
      {
        path: ROUTE_PATHS.SETTINGS_BILLING_PROMO,
        name: ROUTE_NAMES.SETTINGS_BILLING_PROMO,
        component: BillingPromo
      }
    ]
  }
  // {
  //   path: ROUTE_PATHS.SETTINGS_BRANDING,
  //   name: ROUTE_NAMES.SETTINGS_BRANDING,
  //   component: BrandingSettings
  // }
]
