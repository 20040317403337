<template>
  <AppDialog
    :show="show"
    :size="560"
    :type="DIALOG_TYPES.INFO"
    hide-footer
    hide-hero
    @on-close="onClose"
    @on-confirm="onConfirm"
  >
    <div class="bcu-Wrapper">
      <div class="bcu-ContactWrapper">
        <AppIcon
          class="bcu-ContactWrapper_Icon"
          height="48"
          icon-name="calendar-green"
          width="48"
        />
        <AppTitle :level="3" class="bcu-ContactWrapper_Title">
          {{ $t('subscription.contact_oboard_team') }}
        </AppTitle>
        <div class="bcu-ContactWrapper_Description">
          {{ $t('subscription.schedule_free_demo') }}
        </div>
        <AppButton class="bcu-ContactWrapper_Btn" @click="onBookMeeting">
          {{ $t('subscription.book_meeting') }}
        </AppButton>
      </div>
      <div class="bcu-FormWrapper">
        <AppIcon class="bcu-FormWrapper_Icon" height="48" icon-name="mail" width="48" />
        <AppTitle :level="3" class="bcu-FormWrapper_Title">
          {{ $t('subscription.drop_message') }}
        </AppTitle>
        <div class="bcu-Form">
          <FormFieldNext :label="$t('subscription.your_name')">
            <AppInput
              v-model="formModel[CUSTOMER_NAME]"
              :is-error="isFieldError(CUSTOMER_NAME)"
              :max-length="64"
              size="xlg"
              style-type="primary"
              @focus="clearError(CUSTOMER_NAME)"
            />
          </FormFieldNext>
          <FormFieldNext :label="$t('subscription.business_email')">
            <AppInput
              v-model="formModel[BUSINESS_EMAIL]"
              :is-error="isFieldError(BUSINESS_EMAIL)"
              :max-length="128"
              size="xlg"
              style-type="primary"
              @change="validateEmail"
              @focus="clearError(BUSINESS_EMAIL)"
            />
          </FormFieldNext>
          <FormFieldNext :label="$t('subscription.company_name')">
            <AppInput
              v-model="formModel[COMPANY_NAME]"
              :is-error="isFieldError(COMPANY_NAME)"
              :max-length="64"
              size="xlg"
              style-type="primary"
              @focus="clearError(COMPANY_NAME)"
            />
          </FormFieldNext>
          <FormFieldNext :label="$t('subscription.company_size')">
            <AppSelect
              v-model="formModel[COMPANY_SIZE]"
              :offset="[0, 0]"
              :options="COMPANY_SIZE_OPTIONS"
              theme="no-shadow-next light"
              type="default-next"
            />
          </FormFieldNext>
          <AppButton :loading="loading" size="lg-next" @click="onConfirm">
            {{ $t('subscription.get_your_price_quote') }}
          </AppButton>
        </div>
      </div>
    </div>
  </AppDialog>
</template>

<script setup>
import { ref } from 'vue'

import BillingApiHandler from '@/api/billing'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { EMAIL_REGEX } from '@/utils/email-regex'
import { handleError } from '@/utils/error-handling'
import { openLink } from '@/utils/external-link-helper'
import { VIKTOR_CALENDLY_LINK } from '@/utils/helpers'
import { showNotify } from '@/utils/notify'

import AppDialog from '@/components/AppDialog'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

const emit = defineEmits(['update:show'])

defineOptions({
  name: 'BillingContactUsDialog'
})

defineProps({
  show: {
    type: Boolean,
    required: true
  }
})
const CUSTOMER_NAME = 'customerName'
const BUSINESS_EMAIL = 'businessEmail'
const COMPANY_NAME = 'companyName'
const COMPANY_SIZE = 'companySize'

const DEFAULT_FORM_MODEL = {
  [CUSTOMER_NAME]: '',
  [BUSINESS_EMAIL]: '',
  [COMPANY_NAME]: '',
  [COMPANY_SIZE]: 1
}
const formModel = ref({ ...DEFAULT_FORM_MODEL })
const loading = ref(false)

const onClose = () => {
  emit('update:show', false)
  formModel.value = { ...DEFAULT_FORM_MODEL }
}

const errorFields = ref([])

const clearError = field => {
  errorFields.value = errorFields.value.filter(errorField => errorField !== field)
}

const validateForm = () => {
  const requiredFields = [CUSTOMER_NAME, BUSINESS_EMAIL, COMPANY_NAME]
  errorFields.value = requiredFields.filter(field => !formModel.value[field].trim())
}
const isFieldError = field => errorFields.value.includes(field)

const validateEmail = () => {
  const email = formModel.value[BUSINESS_EMAIL]
  if (!new RegExp(EMAIL_REGEX).test(email)) {
    errorFields.value.push(BUSINESS_EMAIL)
  }
}

const onConfirm = async () => {
  validateForm()
  validateEmail()
  if (errorFields.value.length) {
    return
  }

  try {
    loading.value = true
    const BillingApi = new BillingApiHandler()
    await BillingApi.contactUs({
      ...formModel.value
    })
    showNotify()
    emit('update:show', false)
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
}

const COMPANY_SIZE_OPTIONS = [
  { label: '1-99 employees', value: 1 },
  { label: '100-299 employees', value: 2 },
  { label: '300-1999 employees', value: 3 },
  { label: '2000+ employees', value: 4 }
]

const onBookMeeting = () => {
  openLink(VIKTOR_CALENDLY_LINK)
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.bcu-Wrapper {
  padding: 0 0 40px 0;
}
.bcu-ContactWrapper {
  padding: 40px;
  background: rgba(var(--grade-high-color-rgb-next), 0.1);
  border-radius: $border-radius-lg-next;
  text-align: center;
  margin: 0 0 16px 0;
  &_Description {
    color: $dark-2;
    margin: 0 0 20px 0;
  }
  &_Title {
    margin: 0 0 14px 0;
  }
  &_Icon {
    margin: 0 0 20px 0;
  }
  &_Btn {
    margin: auto;
    background: var(--grade-high-color);
    @include activityStates($grade-high-color-next);
  }
}
.bcu-FormWrapper {
  padding: 40px;
  background: rgba(var(--primary-color-rgb-next), 0.1);
  border-radius: $border-radius-lg-next;
  &_Icon {
    display: flex;
    color: $primary-color-next;
    margin: 0 auto 20px auto;
  }
  &_Title {
    text-align: center;
    margin: 0 0 14px 0;
    line-height: 24px;
  }
}
.bcu-Form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
