<template>
  <AppModalWithConfirmation
    :confirm-close="areDataChanged"
    :show="show"
    :size="680"
    :title="modalTitle"
    class="upg-Modal"
    hide-hero
    @on-close="onClose"
  >
    <FormFieldNext :label="fieldLabel" class="upg-FormField">
      <div class="upg-SelectField">
        <AppSelect
          v-model="groupsForUnlink"
          :is-error="isGroupsFieldEmpty"
          :offset="[0, '-100%']"
          :options="groupsOptions"
          :placeholder="$t('placeholder.search')"
          :search-function="value => localSearch({ value, options: groupsOptions })"
          append-to=".upg-SelectField"
          boundary="scrollParent"
          class="upg-Select"
          content-class="upg-SelectContent"
          data-testid="groups-field-select"
          dropdown-search
          hide-selected-items-in-dropdown
          item-label="name"
          item-value="groupId"
          multi
          show-all-selected
          show-selected-options-inside
          skeleton-loader
          theme="no-shadow-next light"
          type="default-next"
          @open="isGroupsFieldEmpty = false"
        >
          <template #button-content="{ options }">
            <span v-if="isEmpty(options)" class="upg-SelectEmptyLabel">
              {{ fieldLabel }}
            </span>
          </template>
        </AppSelect>
      </div>

      <AppFieldError
        v-if="isGroupsFieldEmpty"
        :show="isGroupsFieldEmpty"
        class="upg-FieldError"
        data-testid="groups-field-error"
      >
        {{ $t('field.required') }}
      </AppFieldError>
    </FormFieldNext>

    <FormFieldNext
      :label="$t('groups.unlink.strategy_label')"
      class="upg-StrategyField upg-FormField"
    >
      <AppRadioGroup
        v-model="unlinkStrategy"
        :is-error="isStrategyFieldEmpty"
        :options="UNLINK_STRATEGY_OPTIONS"
        :type="APP_RADIO_GROUP_TYPES.BLOCKS"
        data-testid="strategy-field-switch"
        name="unlink-strategy"
        @update:model-value="isStrategyFieldEmpty = false"
      >
        <template #message="{ option }">
          <i18n-t :keypath="option.message" scope="global">
            <template #group>
              <b>{{ group.name }}</b>
            </template>

            <template #platform>
              {{ platformName }}
            </template>
          </i18n-t>
        </template>
      </AppRadioGroup>

      <AppFieldError
        v-if="isStrategyFieldEmpty"
        :show="isStrategyFieldEmpty"
        class="upg-FieldError"
        data-testid="strategy-field-error"
      >
        {{ $t('field.required') }}
      </AppFieldError>
    </FormFieldNext>

    <template #footer-actions>
      <AppButton type="ghost-next" @click="onClose">
        {{ $t('action.cancel') }}
      </AppButton>

      <AppButton
        :disable="isLoading"
        :loading="isLoading"
        data-testid="unlink-platform-groups-modal-submit-button"
        type="danger"
        @click="unlinkGroups"
      >
        {{ $t('action.unlink_groups') }}
      </AppButton>
    </template>
  </AppModalWithConfirmation>
</template>

<script setup>
import { isEmpty, isNull } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  MODE_NAMES_FOR_TRACKING,
  TRACKING_UNKNOWN
} from '@/tracking/amplitude-helpers'
import { RADIO_GROUP_TYPES as APP_RADIO_GROUP_TYPES } from '@/utils/components-configurations/app-radio-group'
import { GROUP_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { localSearch } from '@/utils/objectives'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'UnlinkPlatformGroupsModal'
})

const props = defineProps({
  show: {
    type: Boolean
  },

  group: {
    type: Object,
    required: true
  },

  trackingSource: {
    type: String,
    default: TRACKING_UNKNOWN
  }
})

const emit = defineEmits({
  close: null,
  'on-groups-unlinked': null
})

const { t } = useI18n()

const UNLINK_STRATEGY_OPTIONS = [
  {
    label: t('groups.unlink.keep_users.label'),
    message: 'groups.unlink.keep_users.message',
    value: true,
    icon: 'keep-users'
  },
  {
    label: t('groups.unlink.delete_users.label'),
    message: 'groups.unlink.delete_users.message',
    value: false,
    icon: 'delete-users'
  }
]

const areDataChanged = computed(() => {
  return !isEmpty(groupsForUnlink.value) || !isNull(unlinkStrategy.value)
})

const groupsForUnlink = ref([])

const groupsOptions = computed(() => {
  return props.group.linkedGroups || props.group[GROUP_ENTITY_KEYS.ASSOCIATED_PLATFORM_GROUPS] || []
})

const isGroupsFieldEmpty = ref(false)
const isStrategyFieldEmpty = ref(false)

const isLoading = ref(false)

const platformName = computed(() => {
  return t('app.platform.jira')
})

const fieldLabel = computed(() => {
  return t('workspaces.select_linked_platform_groups', {
    platform: platformName.value
  })
})

const modalTitle = computed(() => {
  return t('dropdown.unlink_platform_groups', { platform: platformName.value })
})

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

const unlinkStrategy = ref(null)

const unlinkGroups = async () => {
  const isNoGroups = isEmpty(groupsForUnlink.value)
  const isNoStrategy = isNull(unlinkStrategy.value)
  if (isNoGroups || isNoStrategy) {
    isGroupsFieldEmpty.value = isNoGroups
    isStrategyFieldEmpty.value = isNoStrategy
    return
  }

  const api = new GlobalGroupsApiHandler()

  isLoading.value = true
  try {
    await api.unlinkPlatformGroupsFromGlobalGroup({
      groupId: props.group.groupId || props.group.id,
      platformGroupIds: groupsForUnlink.value,
      keepUsers: unlinkStrategy.value
    })

    tracker.logEvent('Jira group unlinked', {
      category: EVENT_CATEGORIES.GROUP_MANAGEMENT,
      source: props.trackingSource,
      role: userRoleForTracking.value,
      mode: unlinkStrategy.value
        ? MODE_NAMES_FOR_TRACKING.KEEP_USERS
        : MODE_NAMES_FOR_TRACKING.DELETE_USERS
    })

    emit('on-groups-unlinked', props.group.groupId || props.group.id)
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}

const onClose = () => {
  emit('close')
}

const setDefaultState = () => {
  isGroupsFieldEmpty.value = false
  isLoading.value = false
  isStrategyFieldEmpty.value = false
  groupsForUnlink.value = []
  unlinkStrategy.value = null
}

watch(
  () => props.show,
  async newValue => {
    if (!newValue) {
      setDefaultState()
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.upg-SelectField {
  position: relative;
}

.upg-StrategyField {
  margin-top: 20px;
}

.upg-FormField {
  --gap: 0;
  --label-bottom-offset: 6px;
}

.upg-FieldError {
  line-height: 20px;
}

.upg-Select {
  :deep(.as-AppDroplistButton_Content) {
    padding: 6px 0 6px 6px;
  }
}

.upg-SelectEmptyLabel {
  padding-left: 2px;
}
</style>
