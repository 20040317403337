<template>
  <AppDroplist
    ref="dropdown"
    v-model="isDropdownVisible"
    :append-to="appendTo"
    :boundary="boundary"
    :disabled="disabled"
    :dropdown-min-width="dropdownMinWidth"
    :dropdown-width="dropdownWidth"
    :fit-content-width="fitContentWidth"
    :has-fixed-parent="hasFixedParent"
    :hide-on-click="hideOnClick"
    :loading="inlineLoader && loading"
    :offset="offset"
    :position="position"
    :show-on-init="showOnInit"
    :skeleton-loader="skeletonLoader"
    :skeleton-loader-color="skeletonLoaderColor"
    :skeleton-loader-height="skeletonLoaderHeight"
    :skeleton-loader-width="skeletonLoaderWidth"
    :theme="theme"
    :to-selector="toSelector"
    :width-as-parent="dropdownWidth === ''"
    max-width="100%"
    @hidden="onHide"
    @open="onOpen"
    @opened="onOpened"
  >
    <template v-if="!toSelector" #button>
      <div
        v-click-away="onClickOutside"
        :class="{
          'as-AppSelect': true,
          'as-AppSelect-disabled': disabled,
          'as-AppSelect-error': isError,
          'as-AppSelect-noLeftPadding': noLeftPadding,
          [`as-AppSelect-${type}`]: true
        }"
      >
        <div v-if="showLabel" class="as-BlockWithLabelWrapper">
          <!-- label -->
          <label :class="{ 'as-AppDroplistLabel-required': required }" class="as-AppDroplistLabel">
            <slot name="label">
              {{ label }}
            </slot>
          </label>
          <slot />
        </div>
        <slot
          :active="isDropdownVisible"
          :full-data-option="getSelectedOptionFull"
          :option="getSelectedOption"
          name="button"
        >
          <div
            :class="{
              'as-AppDroplistButton-showAll': showAllSelected,
              'as-AppDroplistButton-focused': isDropdownVisible,
              'as-AppDroplistButton-highlight': highlightSelected,
              'as-AppDroplistButton-isActive': isHighlighted
            }"
            class="as-AppDroplistButton"
            tabindex="-1"
            @keydown.up.prevent="navigateOptions('prev')"
            @keydown.down.prevent="navigateOptions('next')"
            @keydown.enter.prevent="selectFocusedOption"
          >
            <!-- icon  -->
            <slot :option="getSelectedOption" name="prepend-icon">
              <AppIcon
                v-if="icon"
                :icon-name="highlightSelected ? `${icon}-highlight` : icon"
                class="as-AppDroplistButton_Icon as-AppDroplistButton_Icon-prepend"
                height="24"
                width="24"
              />
            </slot>

            <div class="as-AppDroplistButton_Content">
              <template v-if="!multi">
                <!-- single mode with inline search -->
                <template v-if="inlineSearch">
                  <input
                    v-model="inlineSearchString"
                    :class="inlineSearchClasses"
                    :placeholder="inlineSearchPlaceholder"
                    :tabindex="dropdownSearchTabindex"
                    autocomplete="off"
                    data-testid="inlineSearch"
                    @click.stop="onInlineSearchClick"
                  />
                </template>

                <!-- single mode  -->
                <template v-else>
                  <slot
                    :is-dropdown-visible="isDropdownVisible"
                    :option="getSelectedOption"
                    name="button-content"
                  >
                    <div class="as-AppDroplistButtonOption">
                      <div
                        v-if="getItemIcon(getSelectedOption)"
                        class="as-AppDroplistButtonOption_Icon"
                      >
                        <AppIcon :icon-name="getItemIcon(getSelectedOption)" />
                      </div>
                      <span class="as-AppDroplistButton_Text">
                        {{ getSelectedLabel }}
                      </span>
                    </div>
                  </slot>
                </template>
              </template>
              <!-- multi mode -->
              <template v-else-if="selectedItemValues.length <= 1 || showAllSelected">
                <slot :active="isDropdownVisible" :options="selectedItems" name="button-content">
                  <AppSelectedItemsList
                    :disabled="disabled"
                    :dropdown-visible="isDropdownVisible"
                    :loading="loading"
                    :selected-items="selectedItems"
                    :show-selected-count-circle="showSelectedCountCircle"
                    :simplified-selected="simplifiedSelected"
                    type="default-next"
                    @delete="showAllSelected ? removeSelectedItem($event) : deleteItemValue()"
                  />
                </slot>
              </template>

              <!-- show the count of the selected items-->
              <template v-else>
                <slot :options="selectedItems" name="button-content">
                  <AppSelectItem
                    :show-selected-count-circle="showSelectedCountCircle"
                    @delete="deleteItemValue"
                  >
                    {{ selectedItemsCountText }}
                    <template v-if="showSelectedCountCircle" #delete-button>
                      {{ selectedItemValues.length }}
                    </template>
                  </AppSelectItem>
                </slot>
              </template>
            </div>

            <AppIcon
              v-show="showDropdownIcon"
              :class="[
                'as-AppDroplistButton_Icon-dropdown',
                'as-AppDroplistButton_Icon',
                'as-AppDroplistButton_Icon-append'
              ]"
              :height="appendIconHeight"
              :icon-name="appendIcon"
              :width="appendIconWidth"
            />
          </div>
        </slot>
      </div>
    </template>

    <ul
      :class="getDroplistContentClasses"
      :data-auto-testid="selectOptionsListTestId"
      :data-testid="selectOptionsListTestId"
      :style="selectOptionsStyle"
      class="as-AppSelectOptions"
    >
      <!-- selected options -->
      <li v-if="resolvedShowSelectedOptionsInside" class="as-SelectedOptionsInside" @click="focus">
        <AppSelectedItemsList
          ref="selectedItemsInside"
          v-model:searchString="searchString"
          :disabled="disabled"
          :disabled-items="disabledItems"
          :dropdown-visible="isDropdownVisible"
          :search-max-length="searchMaxLength"
          :selected-items="selectedItems"
          :show-search="isDropdownSearch"
          class="as-SelectedOptionsInside_Items"
          type="default-next"
          @delete="showSelectedOptionsInside ? removeSelectedItem($event) : deleteItemValue()"
        />

        <ClearButton v-if="selectedItemValues.length || searchString" @click="clearAll" />
      </li>
      <!-- dropdown search -->
      <li
        v-if="showSingleSearch"
        ref="dropdownSearch"
        class="dropdown-search"
        tabindex="0"
        @focus="onDropdownSearchFocus"
      >
        <AppIcon class="dropdown-search-icon" height="24" icon-name="search-next" width="24" />
        <input
          ref="dropdownSearchInput"
          v-model="searchString"
          :maxlength="searchMaxLength"
          :placeholder="placeholder"
          :tabindex="dropdownSearchTabindex"
          class="dropdown-search-input"
          @keydown.up.prevent="navigateOptions('prev')"
          @keydown.down.prevent="navigateOptions('next')"
          @keydown.enter.prevent="selectFocusedOption"
        />

        <AppButton
          v-visible="!isSearchEmpty"
          icon="cross-circle-next"
          size="sm"
          type="none"
          @click="clearInput"
        />
      </li>

      <!-- is loading -->
      <li v-if="loading || isItemsLoading" class="as-AppSelectOptionEmpty">
        {{ $t('field.select.loading') }}
      </li>

      <!-- no options -->
      <li v-else-if="needToShowNoOptionsMessage" class="as-AppSelectOptionEmpty">
        <template v-if="!searchString.trim()">
          {{ $t(noOptionsTextWithoutSearch) }}
        </template>
        <template v-else>
          {{ $t('field.select.nooptions') }}
        </template>
      </li>

      <!-- list -->
      <template v-else>
        <div ref="optionList" :style="{ 'max-height': `${maxHeight}px` }" class="as-Options">
          <!-- ungrouped options -->
          <div class="as-UngroupedItems">
            <template v-for="(option, index) in ungroupedOptions" :key="getItemValue(option)">
              <slot
                :disabled="disabledItems.includes(getItemValue(option))"
                :focused="focusIndex === index"
                :highlighted="option.highlightOption"
                :icon="getItemIcon(option)"
                :label="getItemLabel(option)"
                :model-value="selectedItemValues"
                :multi="multi"
                :on-mouseover="() => onListItemMouseOver(getItemValue(option), index)"
                :on-select="() => onListItemSelect(option)"
                :on-update:model-value="data => (selectedItemValues = data)"
                :option="option"
                :selected="modelValue === getItemValue(option)"
                :split="
                  splitFirstOption &&
                  searchString === '' &&
                  index === 0 &&
                  ungroupedOptions.length > 1
                "
                :val="getItemValue(option)"
                name="option"
              >
                <AppSelectListItem
                  :ref="data => setUngroupedItemRef(data, `listOption${getItemValue(option)}`)"
                  v-model="selectedItemValues"
                  :disabled="disabledItems.includes(getItemValue(option))"
                  :focused="focusIndex === index"
                  :hide-checkbox="hideSelectedItemsInDropdown"
                  :highlighted="option.highlightOption"
                  :icon="getItemIcon(option)"
                  :label="getItemLabel(option)"
                  :multi="multi"
                  :selected="modelValue === getItemValue(option)"
                  :show-only-this-button="showOnlyThisButton"
                  :split="
                    splitFirstOption &&
                    searchString === '' &&
                    index === 0 &&
                    ungroupedOptions.length > 1
                  "
                  :type="type"
                  :val="getItemValue(option)"
                  @mouseover="onListItemMouseOver(getItemValue(option), index)"
                  @select="onListItemSelect(option)"
                >
                  <template #option-label>
                    <slot
                      :option="option"
                      :selected="modelValue === getItemValue(option)"
                      name="option-label"
                    />
                  </template>
                </AppSelectListItem>
              </slot>
            </template>
          </div>

          <!-- grouped options-->
          <template v-if="groupedOptions">
            <div v-for="(group, groupIndex) in groupedOptionKeys" :key="group">
              <header v-if="showGroupHeader" class="as-GroupRow">
                <AppIcon
                  v-if="showGroupIcon && groups[group]?.icon"
                  :icon-name="groups[group].icon"
                  height="24"
                  width="24"
                />
                <span
                  :class="{ 'as-GroupLabel-no-icon': !groups[group]?.icon }"
                  class="as-GroupLabel"
                >
                  {{ getGroupLabel(group) }}
                </span>
              </header>
              <span v-if="showGroupDivider && groupIndex !== 0" class="as-GroupDivider" />

              <template v-for="option in groupedOptions[group]" :key="getItemValue(option)">
                <slot
                  :disabled="disabledItems.includes(getItemValue(option))"
                  :focused="getItemValue(option) === focusValue"
                  :label="getItemLabel(option)"
                  :model-value="selectedItemValues"
                  :multi="multi"
                  :on-mouseover="() => onListItemMouseOver(getItemValue(option))"
                  :on-select="() => onListItemSelect(option)"
                  :on-update:model-value="data => (selectedItemValues = data)"
                  :option="option"
                  :selected="modelValue === getItemValue(option)"
                  :val="getItemValue(option)"
                  name="option"
                >
                  <AppSelectListItem
                    :ref="`listOption${getItemValue(option)}`"
                    v-model="selectedItemValues"
                    :disabled="disabledItems.includes(getItemValue(option))"
                    :focused="getItemValue(option) === focusValue"
                    :hide-checkbox="hideSelectedItemsInDropdown"
                    :label="getItemLabel(option)"
                    :multi="multi"
                    :selected="modelValue === getItemValue(option)"
                    :show-only-this-button="showOnlyThisButton"
                    :type="type"
                    :val="getItemValue(option)"
                    @mouseover="onListItemMouseOver(getItemValue(option))"
                    @select="onListItemSelect(option)"
                  >
                    <template #option-label>
                      <slot :option="option" name="option-label" />
                    </template>
                  </AppSelectListItem>
                </slot>
              </template>
            </div>
          </template>
        </div>
      </template>

      <template v-if="bottomFixedItems.length">
        <slot
          :bottom-fixed-items="bottomFixedItems"
          :search-string="searchString"
          name="bottom-fixed-items"
        >
          <div class="as-BottomFixedItems">
            <AppSelectListItem
              v-for="optionValue in bottomFixedItems"
              :key="optionValue"
              v-model="selectedItemValues"
              :disabled="disabledItems.includes(optionValue)"
              :label="getItemLabelByValue(optionValue)"
              :multi="multi"
              :selected="modelValue === optionValue"
              :type="type"
              :val="optionValue"
              @select="onListItemValueSelect(optionValue)"
            >
              <template #option-label>
                <slot :option="itemByValue[optionValue]" name="option-label" />
              </template>
            </AppSelectListItem>
          </div>
        </slot>
      </template>

      <template v-if="showCreateButton">
        <li
          v-if="isLabelCreation || showCreateEmailButton"
          class="as-CreateEntityButton"
          @click="onCreateButtonClick"
        >
          <OwnerFieldOption
            v-if="multi && isEmailAlreadyAdded"
            :option="{
              [itemLabel]: searchString,
              [itemValue]: searchString
            }"
            already-added
          >
            <template #badge>
              {{ $t('badge.already_added') }}
            </template>
          </OwnerFieldOption>
          <template v-else>
            <AppIcon
              :icon-name="createButtonIcon"
              class="as-CreateEntityButton_Icon"
              height="24"
              width="24"
            />
            <span class="as-CreateEntityButton_Content">
              <span class="oboard-truncated-text">
                {{ createButtonLabel }}
              </span>
            </span>
          </template>
        </li>
      </template>
    </ul>
  </AppDroplist>
</template>

<script>
import { clone, cloneDeep, isBoolean, isEmpty, isEqual, isFunction, uniq } from 'lodash'
import { defineComponent } from 'vue'

import i18n from '@/i18n'
import { EMAIL_REGEX, getEmailsListFromString } from '@/utils/email-regex'
import { handleError } from '@/utils/error-handling'
import { CREATE_ON_SEARCH_ENTITIES } from '@/utils/select'

import AppDroplist from '@/components/AppDroplist'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelectedItemsList from '@/components/ui/AppSelect/AppSelectedItemsList'
import { APP_SELECT_OPTIONS_LIST_TEST_ID } from '@/components/ui/AppSelect/jest-helpers'
import ClearButton from '@/components/ui/ClearButton/ClearButton'

import AppSelectItem from './AppSelectItem'
import AppSelectListItem from './AppSelectListItem'

const SELECT_TYPES = {
  DEFAULT: 'default',
  DEFAULT_NEXT: 'default-next',
  SECONDARY: 'secondary',
  SECONDARY_NEXT: 'secondary-next',
  INLINE: 'inline',
  SIMPLE: 'simple',
  MODERN: 'modern',
  MODERN_LIGHT: 'modern-light',
  MODERN_LARGE_LIGHT: 'modern-large-light',
  MODERN_LARGE_LIGHT_NEXT: 'modern-large-light-next',
  MODERN_MEDIUM_LIGHT: 'modern-medium-light'
}

export default defineComponent({
  name: 'AppSelect',

  components: {
    OwnerFieldOption,
    ClearButton,
    AppSelectedItemsList,
    AppButton,
    AppDroplist,
    AppIcon,
    AppSelectItem,
    AppSelectListItem
  },

  props: {
    modelValue: {
      type: [Number, String, Array],
      default: null
    },

    loading: {
      type: Boolean
    },

    label: {
      type: String,
      default: ''
    },

    options: {
      type: Array,
      required: true
    },

    placeholder: {
      type: String,
      default: () => i18n.global.t('placeholder.search')
    },

    required: {
      type: Boolean
    },

    disabled: {
      type: Boolean
    },

    icon: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'default',
      validator: v => Object.values(SELECT_TYPES).includes(v)
    },

    highlightSelected: {
      type: Boolean,
      default: false
    },

    hideOnClick: {
      type: Boolean,
      default: true
    },

    toSelector: {
      type: String,
      default: null
    },

    multi: {
      type: Boolean
    },

    inlineLoader: {
      type: Boolean,
      default: true
    },

    inlineSearch: {
      type: Boolean
    },

    dropdownSearch: {
      type: Boolean,
      default: null
    },

    nSelectedLabel: {
      type: String,
      default: 'selected'
    },

    searchFunction: {
      type: Function,
      default: null
    },

    disabledItems: {
      type: Array,
      default: () => []
    },

    hiddenItems: {
      type: Array,
      default: () => []
    },

    bottomFixedItems: {
      type: Array,
      default: () => []
    },

    showAllSelected: {
      type: Boolean
    },

    showSelectedOptionsInside: {
      type: Boolean
    },

    simplifiedSelected: {
      type: Boolean
    },

    showSelectedCountCircle: {
      type: Boolean
    },

    contentClass: {
      type: [Array, String],
      default: ''
    },

    appendTo: {
      type: String,
      default: ''
    },

    offset: {
      type: Array,
      default: () => [0, 4]
    },

    itemLabel: {
      type: [String, Function],
      default: 'label'
    },

    itemValue: {
      type: [String, Function],
      default: 'value'
    },

    itemIcon: {
      type: [String, Function],
      default: null
    },

    /*
     * Function that returns group name to group options by
     * If you need options without group, return for that options
     * `null` as group name value.
     */
    groupBy: {
      type: Function,
      default: null
    },

    maxHeight: {
      type: [String, Number],
      default: 220
    },

    hasFixedParent: {
      type: Boolean,
      default: false
    },

    fixDropdownHeight: {
      type: Boolean,
      default: undefined
    },

    boundary: {
      type: [String, Object],
      default: 'viewport'
    },

    fitContentWidth: {
      type: Boolean
    },

    dropdownWidth: {
      type: String,
      default: ''
    },

    dropdownMinWidth: {
      type: Number,
      default: 120
    },

    noLeftPadding: {
      type: Boolean,
      default: false
    },

    position: {
      type: String,
      default: 'bottom-start'
    },

    groups: {
      type: Object,
      default: () => ({})
    },

    showOnInit: {
      type: Boolean
    },

    appendIconWidth: {
      type: [String, Number],
      default: 24
    },

    appendIconHeight: {
      type: [String, Number],
      default: 24
    },

    allowCreateOnSearch: {
      type: Boolean
    },

    entityForCreate: {
      type: String,
      default: CREATE_ON_SEARCH_ENTITIES.LABEL,
      validator: v => Object.values(CREATE_ON_SEARCH_ENTITIES).includes(v)
    },

    createLabel: {
      type: String,
      default: ''
    },

    searchMaxLength: {
      type: Number,
      default: 512,
      validator: v => v > 0 && v <= 512 && Number.isInteger(v)
    },

    // split first ungrouped option if search string is empty
    splitFirstOption: {
      type: Boolean
    },

    dropdownSearchTabindex: {
      type: String,
      default: '-1'
    },

    showNoOptionsMessage: {
      type: Boolean,
      default: true
    },

    theme: {
      type: String,
      default: 'light'
    },

    showGroupDivider: {
      type: Boolean
    },

    showGroupHeader: {
      type: Boolean,
      default: true
    },

    skeletonLoader: {
      type: Boolean
    },

    skeletonLoaderHeight: {
      type: String,
      default: 'calc(100% - 20px)'
    },

    skeletonLoaderWidth: {
      type: String,
      default: 'calc(100% - 20px)'
    },

    skeletonLoaderColor: {
      type: String,
      default: '#f4f5f7'
    },

    isHighlighted: {
      type: Boolean,
      default: false
    },

    hideSelectedItemsInDropdown: {
      type: Boolean
    },

    isError: {
      type: Boolean
    },

    hasOnlyThisButton: {
      type: Boolean
    },

    noOptionsTextWithoutSearch: {
      type: String,
      default: 'field.select.nooptions'
    },

    selectOptionsListTestId: {
      type: String,
      default: APP_SELECT_OPTIONS_LIST_TEST_ID
    }
  },

  emits: {
    'search-update': null,
    'update:model-value': null,
    change: null,
    search: null,
    'update:options': null,
    open: null,
    opened: null,
    hide: null,
    create: null
  },

  data() {
    return {
      isDropdownVisible: false,
      searchString: '',
      inlineSearchString: '',
      timeout: null,
      isItemsLoading: false,
      searchedItems: [],
      selectedItemValues: this.modelValue || [],
      ungroupedItemsRefs: {},
      createdEmails: [],

      focusIndex: 0,
      focusValue: 0
    }
  },

  computed: {
    searchFieldCanBeAutoFocused() {
      return this.isNextType && this.showSelectedOptionsInside && this.isDropdownSearch
    },

    showCreateButton() {
      return (
        this.allowCreateOnSearch &&
        !this.itemWithSearchStringExists &&
        !this.isItemsLoading &&
        this.searchString !== ''
      )
    },

    isEmailAlreadyAdded() {
      return this.showCreateEmailButton && this.createdEmails.includes(this.searchString)
    },

    createButtonIcon() {
      if (this.isEmailCreation) {
        return this.isEmailAlreadyAdded ? 'user-already-added' : 'email'
      }

      return 'plus-next'
    },

    firstValidEmailFromSearchString() {
      if (this.isEmailCreation) {
        const matches = getEmailsListFromString(this.searchString)
        const [firstValidEmail] = matches
        return firstValidEmail || ''
      }

      return null
    },

    createButtonLabel() {
      let resolvedEntityName = `"${this.searchString}"`

      if (this.isEmailCreation) {
        resolvedEntityName = this.firstValidEmailFromSearchString
      }

      return `${this.createLabel} ${resolvedEntityName}`.trim()
    },

    needToShowNoOptionsMessage() {
      const defaultCondition = this.noOptions && this.showNoOptionsMessage

      let allowCreateOnSearchCondition = !this.allowCreateOnSearch

      if (this.isEmailCreation) {
        allowCreateOnSearchCondition = !new RegExp(EMAIL_REGEX).test(this.searchString)
      }

      return defaultCondition && allowCreateOnSearchCondition
    },

    isLabelCreation() {
      return this.allowCreateOnSearch && this.entityForCreate === CREATE_ON_SEARCH_ENTITIES.LABEL
    },

    isEmailCreation() {
      return this.allowCreateOnSearch && this.entityForCreate === CREATE_ON_SEARCH_ENTITIES.EMAIL
    },

    showCreateEmailButton() {
      if (this.entityForCreate === CREATE_ON_SEARCH_ENTITIES.EMAIL) {
        const isEmailValid = new RegExp(EMAIL_REGEX).test(this.searchString)
        return isEmailValid && this.noOptions
      }

      return false
    },

    showOnlyThisButton() {
      return this.multi && this.hasOnlyThisButton
    },

    showDropdownIcon() {
      if (this.type === SELECT_TYPES.SECONDARY_NEXT) {
        return false
      } else {
        return !this.disabled || this.type === SELECT_TYPES.DEFAULT_NEXT
      }
    },

    itemWithSearchStringExists() {
      const pureOptions = this.options.filter(option => {
        return !this.createdEmails.includes(this.getItemValue(option))
      })
      return pureOptions.findIndex(option => this.getItemLabel(option) === this.searchString) !== -1
    },

    appendIcon() {
      return this.type === SELECT_TYPES.SIMPLE ? 'arrow-down-filters' : 'arrow-down-black'
    },

    showGroupIcon() {
      return !isEmpty(this.groups)
    },

    selectedItems() {
      return this.selectedItemValues.map(value => {
        return {
          value,
          label: this.getItemLabelByValue(value)
        }
      })
    },

    selectedItemsCountText() {
      const { showSelectedCountCircle, nSelectedLabel, selectedItemValues } = this
      return showSelectedCountCircle
        ? `${this.$t(nSelectedLabel)}`
        : `${selectedItemValues.length} ${this.$t(nSelectedLabel)}`
    },

    showLabel() {
      return this.label || this.$slots.label
    },

    getSelectedLabel() {
      return this.getItemLabelByValue(this.modelValue)
    },

    getSingleOption() {
      return (
        this.options.find(option => `${this.getItemValue(option)}` === `${this.modelValue}`) || null
      )
    },

    getSelectedOption() {
      if (this.multi) {
        /// if (!isEmpty(this.selectedItems)) {
        ///   return this.selectedItems.map(({ label }) => label)
        /// } else {
        ///   return this.options
        ///     .filter(option => this.modelValue.includes(this.getItemValue(option)))
        ///     .map(item => {
        ///       return item[this.itemLabel]
        ///     })
        /// }

        return this.selectedItems.map(({ label }) => label)
      }
      return this.getSingleOption
    },

    getSelectedOptionFull() {
      if (this.multi) {
        // if (!isEmpty(this.selectedItems)) {
        //   return this.selectedItems.map(({ value }) => {
        //     return cloneDeep(this.options.find(option => this.getItemValue(option) === value))
        //   }).filter(Boolean)
        // } else {
        //   return this.options.filter(option => this.modelValue.includes(this.getItemValue(option)))
        // }

        return this.selectedItems
          .map(({ value }) => {
            return cloneDeep(this.options.find(option => this.getItemValue(option) === value))
          })
          .filter(Boolean)
      }
      return this.getSingleOption
    },

    filterOptionsBySearch() {
      const { isSearchEmpty, options, searchedItems } = this
      const list = isSearchEmpty ? options : searchedItems

      const allHiddenItems = [...this.createdEmails, ...this.hiddenItems]

      const filteredList = list.filter(
        objFromOptions => !allHiddenItems.includes(this.getItemValue(objFromOptions))
      )

      const result = filteredList.filter(
        option => !this.bottomFixedItems.includes(this.getItemValue(option))
      )

      if (this.hideSelectedItemsInDropdown) {
        return result.filter(option => !this.selectedItemValues.includes(this.getItemValue(option)))
      } else {
        return result
      }
    },

    isSearchEmpty() {
      return this.searchString.trim() === '' && this.inlineSearchString.trim() === ''
    },

    isDropdownSearch() {
      const { dropdownSearch, multi } = this

      return isBoolean(dropdownSearch) ? dropdownSearch : multi
    },

    resolvedShowSelectedOptionsInside() {
      if (this.isDropdownSearch) {
        return this.showSelectedOptionsInside
      } else {
        return this.showSelectedOptionsInside && !isEmpty(this.selectedItemValues)
      }
    },

    isNextType() {
      return [SELECT_TYPES.DEFAULT_NEXT, SELECT_TYPES.SECONDARY_NEXT].includes(this.type)
    },

    showSingleSearch() {
      if (this.isNextType) {
        return this.showSelectedOptionsInside ? false : this.isDropdownSearch
      } else {
        return this.isDropdownSearch
      }
    },

    inlineSearchClasses() {
      const { inlineSearchString, inlineSearchPlaceholder, placeholder } = this

      return {
        'as-AppDroplistButton_InlineSearch': true,
        'as-AppDroplistButton_InlineSearch-notempty':
          inlineSearchString || inlineSearchPlaceholder !== placeholder
      }
    },

    inlineSearchPlaceholder() {
      const { getSelectedLabel, placeholder } = this

      return getSelectedLabel || placeholder
    },

    noOptions() {
      return this.filterOptionsBySearch.length === 0
    },

    ungroupedOptions() {
      const { groupBy, filterOptionsBySearch } = this
      if (!groupBy) {
        return filterOptionsBySearch
      }

      return filterOptionsBySearch.filter(option => {
        const key = groupBy(option)
        return key === null
      })
    },

    groupedOptions() {
      if (!this.groupBy) {
        return null
      }

      const result = {}
      this.filterOptionsBySearch.forEach(option => {
        const key = this.groupBy(option)
        // ignore options without group
        if (key === null) {
          return
        }

        if (key in result) {
          result[key].push(option)
        } else {
          result[key] = [option]
        }
      })

      return result
    },

    groupedOptionKeys() {
      if (this.groupedOptions !== null) {
        return Object.keys(this.groupedOptions)
      }
      return []
    },

    allOptions() {
      const data = this.groupedOptionKeys.map(key => this.groupedOptions[key])
      const options = data.reduce((acc, item) => acc.concat(item), [])
      options.unshift(...this.ungroupedOptions)
      return options
    },

    getDroplistContentClasses() {
      const { contentClass, type } = this
      const defaultClasses = {
        'as-AppDroplistContent': true,
        [`as-AppDroplist-${type}`]: true,
        'as-AppDroplistContent-selectedOptionsInside': this.showSelectedOptionsInside
      }
      return Array.isArray(contentClass)
        ? [defaultClasses, ...contentClass]
        : [defaultClasses, contentClass]
    },

    itemByValue() {
      const result = {}
      this.options.forEach(option => {
        result[this.getItemValue(option)] = option
      })

      return result
    },

    selectOptionsStyle() {
      const { fixDropdownHeight, maxHeight, dropdownWidth: width } = this
      const styles = { width }
      if (fixDropdownHeight) {
        styles.height = `${maxHeight}px`
      }
      return styles
    }
  },

  watch: {
    searchString(newValue) {
      this.$emit('search-update', newValue)
      this.searchItems(newValue)
    },

    inlineSearchString(newValue) {
      this.$emit('search-update', newValue)
      this.searchItems(newValue)
    },

    selectedItemValues: {
      handler(newValue) {
        if (!isEqual(this.modelValue, newValue)) {
          this.$emit('update:model-value', newValue)
          this.$emit('change', newValue)

          // SEARCH CLEAR HERE
          if (!this.isSearchEmpty) {
            this.inlineSearchString = ''
            this.searchString = ''
          }

          this.focusSearchField()
        }
      },

      deep: true
    },

    modelValue: {
      handler(newValue) {
        if (newValue === null && this.multi) {
          if (this.selectedItemValues.length > 0) {
            this.selectedItemValues = []
          }
        } else if (!isEqual(this.selectedItemValues, newValue)) {
          this.selectedItemValues = cloneDeep(this.modelValue)
        }
      },

      deep: true
    },

    allOptions: {
      handler(newValue) {
        if (newValue.length > 0) {
          this.focusIndex = 0
          this.focusValue = this.getItemValue(newValue[0])
        }
      },

      deep: true
    }
  },

  beforeUpdate() {
    this.ungroupedItemsRefs = {}
  },

  methods: {
    /** Focus on select.
     * @public
     * This method is part of public API.
     */
    focus() {
      this.showDropdown()
      if (this.isDropdownSearch) {
        this.$nextTick(() => {
          if (this.searchFieldCanBeAutoFocused) {
            this.$refs.selectedItemsInside.focus()
          } else {
            this.$refs.dropdownSearch.focus()
          }
        })
      }
    },

    focusSearchField() {
      if (this.searchFieldCanBeAutoFocused) {
        this.$refs.selectedItemsInside.focus()
      }
    },

    setUngroupedItemRef(component, key) {
      if (component) {
        this.ungroupedItemsRefs[key] = component
      }
    },

    showDropdown() {
      this.isDropdownVisible = true
    },

    onClickOutside(e) {
      let condition = !e.target.closest('.as-AppDroplistContent')
      const { hideOnClick, toSelector } = this
      const toSelectorProvided = !!toSelector

      if (hideOnClick) {
        condition = condition && !hideOnClick
      }

      if (toSelectorProvided) {
        const targetElement = document.querySelector(toSelector)
        condition = condition && !e.composedPath().includes(targetElement)
      }
      if (condition) {
        this.hideDropdown()
      }
    },

    /** @public */

    hideDropdown() {
      if (this.isDropdownVisible) {
        this.isDropdownVisible = false
      }
    },

    selectValue(value) {
      if (!this.isSearchEmpty) {
        this.inlineSearchString = ''
        this.searchString = ''
      }

      // in multi mode events are emmited by watcher on selectedItemValues
      if (!this.multi) {
        this.hideDropdown()

        this.$emit('update:model-value', value)
        // TODO: check if it emitted only for manual change
        this.$emit('change', value)
      }
    },

    /** @public */
    clearInput() {
      this.searchString = ''
    },

    /** @public */
    async searchItems(searchQuery = '') {
      if (this.timeout) window.clearTimeout(this.timeout)
      if (searchQuery.length === 0) {
        this.isItemsLoading = false
        return
      }

      this.isItemsLoading = true
      this.searchedItems = []
      this.$emit('search', searchQuery)

      this.timeout = setTimeout(async () => {
        try {
          this.searchedItems = await this.searchFunction(searchQuery)
        } catch (error) {
          handleError({ error })
        } finally {
          setTimeout(() => {
            this.isItemsLoading = false
          }, 300)
        }
      }, 400)
    },

    /** @public */
    setSearchString(value) {
      this.inlineSearchString = value
    },

    resetSearchString() {
      this.searchString = ''
    },

    onInlineSearchClick() {
      this.isDropdownVisible = true
    },

    clearAll() {
      if (!isEmpty(this.selectedItemValues)) {
        this.selectedItemValues = this.selectedItemValues.filter(item =>
          this.disabledItems.includes(item)
        )
      }

      if (!isEmpty(this.createdEmails)) {
        this.createdEmails = []
        const newOptions = this.options.filter(option => !option.createdEmail)
        this.$emit('update:options', newOptions)
      }

      if (this.searchString) {
        this.searchString = ''
      }
    },

    getItemLabelByValue(value) {
      const { options, getItemValue, getItemLabel } = this

      const selectedItem = options.find(item => value === getItemValue(item))
      return selectedItem ? getItemLabel(selectedItem) : ''
    },

    deleteItemValue() {
      this.$emit('update:model-value', null)
    },

    removeSelectedItem(item) {
      if (this.allowCreateOnSearch && this.isEmailCreation) {
        if (this.createdEmails.includes(item)) {
          this.createdEmails = this.createdEmails.filter(email => email !== item)

          const newOptions = this.options.filter(option => this.getItemValue(option) !== item)
          this.$emit('update:options', newOptions)
        }
      }
      this.selectedItemValues = this.selectedItemValues.filter(
        selectedItem => selectedItem !== item
      )
    },

    getItemLabel(item) {
      if (!this.itemLabel) {
        return item.label
      }

      return isFunction(this.itemLabel) ? this.itemLabel(item) : item[this.itemLabel]
    },

    getItemValue(item) {
      if (!this.itemValue) {
        return item.value
      }

      return isFunction(this.itemValue) ? this.itemValue(item) : item[this.itemValue]
    },

    getItemIcon(item) {
      if (!this.itemIcon) {
        return null
      }

      return isFunction(this.itemIcon) ? this.itemIcon(item) : item && item[this.itemIcon]
    },

    getGroupLabel(group) {
      const { groups } = this
      return isEmpty(groups) ? group : groups[group].name
    },

    onDropdownSearchFocus() {
      this.$refs.dropdownSearchInput.focus()
    },

    onListItemValueSelect(optionValue) {
      if (!this.multi) {
        this.selectValue(optionValue)
      }

      const indexInOptions = this.options.findIndex(i => this.getItemValue(i) === optionValue)
      const item = this.searchedItems.find(i => this.getItemValue(i) === optionValue)

      if (indexInOptions === -1 && item) {
        const newOptions = [...this.options, item]
        this.$emit('update:options', newOptions)
      }
    },

    onListItemSelect(option) {
      this.onListItemValueSelect(this.getItemValue(option))
    },

    onHide(data) {
      this.resetSearchString()
      this.$emit('hide', data)
    },

    onOpen(data) {
      this.$emit('open', data)
    },

    onOpened(data) {
      this.focus()
      this.$emit('opened', data)
    },

    onListItemMouseOver(value, index) {
      this.focusValue = value
      if (index) {
        this.focusIndex = index
      } else {
        this.focusIndex = this.allOptions.findIndex(
          item => this.getItemValue(item) === this.focusValue
        )
      }
    },

    /**
     * @param direction prev|next
     */
    navigateOptions(direction) {
      if (direction === 'next') {
        this.focusIndex += 1
        if (this.focusIndex === this.allOptions.length) {
          this.focusIndex = 0
        }
      } else if (direction === 'prev') {
        this.focusIndex -= 1
        if (this.focusIndex < 0) {
          this.focusIndex = this.allOptions.length - 1
        }
      }
      this.focusValue = this.getItemValue(this.allOptions[this.focusIndex])

      // scroll option into view
      if (this.ungroupedItemsRefs[`listOption${this.focusValue}`]) {
        this.$refs.optionList.scrollTop =
          this.ungroupedItemsRefs[`listOption${this.focusValue}`].offsetTop -
          this.$refs.optionList.offsetTop
      }
    },

    selectFocusedOption() {
      if (this.showCreateEmailButton && this.showCreateButton) {
        this.onCreateButtonClick()
        return
      }
      const focusedOption = this.allOptions[this.focusIndex]
      // list of options can by empty so in this case focused options - undefined
      if (focusedOption) {
        this.onListItemSelect(focusedOption)
      }
    },

    /** @public */
    updateDropdown() {
      this.$refs.dropdown.update()
    },

    onCreateButtonClick() {
      if (this.isLabelCreation) {
        this.$emit('create', this.searchString)
      }

      if (this.isEmailCreation) {
        if (this.multi && this.isEmailAlreadyAdded) {
          this.focusSearchField()
          return
        }

        this.createdEmails.push(this.firstValidEmailFromSearchString)

        const item = {
          [this.itemValue]: this.firstValidEmailFromSearchString,
          [this.itemLabel]: this.firstValidEmailFromSearchString,
          createdEmail: true
        }

        if (this.multi) {
          this.selectedItemValues = uniq(
            clone([...this.selectedItemValues, this.getItemValue(item)])
          )
        }

        this.searchedItems.push(item)

        this.onListItemSelect(item)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.as-AppDroplistLabel-required:after {
  content: ' *';
  color: $red-1;
}

.as-AppDroplistButton_Icon-dropdown {
  margin-right: 8px;
}

.as-AppSelectOptions {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  font-size: 0.9rem;
  margin-bottom: 0;
  border-radius: $border-radius-md;
  overflow: hidden;

  &.as-AppDroplistContent-selectedOptionsInside &.as-AppDroplist-default-next {
    border-radius: 0 0 $border-radius-sm-next $border-radius-sm-next;
  }

  &.as-AppDroplist-default-next,
  &.as-AppDroplist-secondary-next,
  &.as-AppDroplist-modern-large-light-next {
    border-radius: $border-radius-sm-next;
    font-family: $system-ui;
  }
}

.as-Options {
  overflow-y: auto;
  @include styled-native-scrollbar();

  .as-AppDroplist-secondary & {
    padding: 8px 0;
  }
}

.as-AppSelectOptionEmpty {
  font-family: $system-ui;
  padding: 12px;
  text-align: center;
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-3;
}

:deep(.as-AppSelectOptionsItem-disabled) {
  color: $grey-semi-medium;

  &:hover {
    cursor: default;
    background-color: transparent;
  }
}

:deep(.asi-AppSelectOptionsItem-selected) {
  background-color: $grey-semi-medium;
  color: $white;

  @include activityStates($grey-semi-medium);

  .as-AppDroplist-default-next &,
  .as-AppDroplist-secondary-next &,
  .as-AppDroplist-modern-large-light-next & {
    background-color: rgba($primary-color-next, 0.15);
    color: $dark-1;
    @include activityStates(rgba($primary-color-next, 0.15));
  }

  .as-AppDroplist-secondary-next & {
    background-color: rgba($primary-color-next, 0.15);
    color: $dark-1;
  }
}

.as-AppDroplistButton {
  display: flex;
  align-items: center;
  background-color: $grey-8;
  border-radius: $border-radius-md;
  border: 2px solid $grey-8;
  max-width: 100%;
  min-height: 32px;
  cursor: pointer;

  @include hoverState($grey-11, 0%);
  @include activeState($grey-11, 10%);

  .as-AppSelect-default-next & {
    min-height: 40px;
    border-color: $grey-2-next;
    border-radius: $border-radius-sm-next;
    background-color: $white;
    font-family: $system-ui;
    .as-AppDroplistButtonOption {
      max-width: 100%;
    }
  }

  .as-AppSelect-default-next.as-AppSelect-error & {
    border-color: $grade-low-color-next;
  }

  .as-AppSelect-secondary-next & {
    min-height: 32px;
    border: none;
    border-radius: $border-radius-sm-next;
    background-color: $grey-3-next;
    font-family: $system-ui;
    color: $dark-grey;
    @include hoverState($grey-3-next, 0%);
    @include activeState($grey-3-next, 10%);
    padding: 2px 12px 2px 4px;
    .as-AppDroplistButton_Content {
      margin-left: 4px;
    }
    &_Icon {
      margin: 0;
    }
  }

  .as-AppSelect-default-next.as-AppSelect-disabled & {
    color: $grey-1-next;
    border-color: $grey-2-next;
  }

  &-showAll &_Content {
    flex-wrap: wrap;
  }

  &-focused,
  &-isActive {
    border-color: $primary-color;
    background-color: $white;

    .as-AppSelect-default-next &,
    .as-AppSelect-secondary-next & {
      background-color: $dark-2;
      color: $white;
      border-color: $dark-2;

      &:hover {
        border-color: $dark-2;
      }
      @include hoverState($dark-2, 10%);
      @include activeState($dark-2, 10%);
    }

    &:hover {
      border-color: $primary-color;
      background-color: transparent;
    }
  }

  &_Content {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-left: 8px;

    * + & {
      padding-left: 0;
    }

    .as-AppSelect-noLeftPadding & {
      padding-left: 0;
    }
  }

  &_Text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $regular-text;

    .as-AppDroplistButton-focused & {
      .as-AppSelect-default-next & {
        color: $white;
      }
    }

    .as-AppSelect-default-next.as-AppSelect-disabled & {
      color: $grey-1-next;
    }
  }

  &_Icon {
    margin-left: 6px;
    margin-right: 8px;
    flex-shrink: 0;

    &-append {
      margin-right: 6px;
    }
  }

  &_InlineSearch {
    background: transparent;
    border: none;
    outline: none;
    flex: 1 1 auto;

    &::placeholder {
      font-size: 0.875rem;
      color: $placeholder-color;
    }

    &-notempty::placeholder {
      opacity: 1;
      color: $regular-text;
    }
  }
}

// from multiselect, TODO: refactor
.dropdown-search {
  padding: 12px;
  // padding: 5px 8px 5px 5px;
  // border: 2px solid $primary-color-next;
  // border-radius: $border-radius-sm-next;
  background-color: $white;
  display: flex;
  align-items: center;
  gap: 8px;

  .as-AppDroplist-default-next &,
  .as-AppDroplist-secondary-next &,
  .as-AppDroplist-modern-large-light-next & {
    border: 2px solid $primary-color-next;
    border-radius: $border-radius-sm-next;
    padding: 6px;
  }

  &-icon {
    flex: 0 0 auto;
  }

  &-input {
    caret-color: $primary-color-next;
    flex: 1 1 auto;
    min-width: 0;
    background-color: $white;
    border: none;
    padding: 0;
    font-family: $system-ui;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $fs-14;
    line-height: 20px;
    color: $dark-1;

    &::placeholder {
      color: $grey-1-next;

      font-weight: fw('regular');
    }

    &:focus {
      background-color: $white;
      outline: none;
    }
  }
}

.as-GroupRow {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.as-GroupLabel {
  color: $dark-grey;
  font-size: $fs-14;
  line-height: 16px;
  margin: 12px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .as-AppDroplist-secondary-next &,
  .as-AppDroplist-default-next & {
    margin: 18px 10px 10px 8px;
    font-style: normal;
    font-weight: fw('bold');
    font-size: $fs-12;
    line-height: 16px;
    color: $dark-3;
  }

  &-no-icon {
    margin-left: 0;

    .as-AppDroplist-secondary-next &,
    .as-AppDroplist-default-next & {
      margin-left: 0;
    }
  }
}

.as-AppDroplistButtonOption {
  display: flex;
  align-items: center;

  &_Icon {
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
}

.as-AppSelect {
  &-simple {
    font-family: $system-ui;
  }
}

.as-AppDroplistButton_Icon-dropdown {
  transition: transform $transition-fast ease-in-out;

  .as-AppDroplistButton-focused & {
    transform: rotate(180deg);
  }
}

.as-AppDroplistButton {
  .as-AppSelect-simple & {
    background: transparent;
    border: none;
    min-height: 16px;

    .as-AppDroplistButton_Icon-dropdown {
      color: $grey-8;
    }

    @media (any-hover: hover) {
      &:hover {
        background-color: transparent;
        color: $blue;

        &:deep(.si-SelectedItem) {
          color: $blue;
        }

        .as-AppDroplistButton_Icon-dropdown {
          color: $grey-medium;
        }
      }
    }

    &:active {
      color: darken($blue, 10%);

      &:deep(.si-SelectedItem) {
        color: darken($blue, 10%);
      }

      .as-AppDroplistButton_Icon-dropdown {
        color: darken($grey-medium, 10%);
      }
    }
  }

  .as-AppSelect-modern-large-light & {
    background-color: transparent;
    border: 1px solid $lines-grey;
    border-radius: $border-radius-md;
    padding: 11px 8px 11px 16px;
    min-height: 44px;

    &-focused {
      border-color: $primary-color;
      background-color: $white;
    }

    @include hoverState($azure-medium, 0%);
    @include activeState($azure-medium, 2%);

    .as-AppDroplistButton_Icon-dropdown {
      transition: transform 0.3s;
    }
  }

  .as-AppSelect-modern-large-light-next & {
    background-color: transparent;
    border-radius: $border-radius-md;
    padding: 18px;
    min-height: 44px;
    font-size: 14px;
    line-height: 20px;
    border: 2px solid $grey-3-next;

    &-focused {
      border-color: $grey-2-next;
      background-color: $white;
    }

    @include hoverState($grey-3-next, 1%);
    @include activeState($grey-3-next, 2%);

    .as-AppDroplistButton_Icon-dropdown {
      transition: transform 0.3s;
    }
  }

  .as-AppSelect-modern-light &,
  .as-AppSelect-modern-medium-light & {
    background-color: transparent;
    border: 1px solid $azure-medium;
    @media (any-hover: hover) {
      &:hover {
        background-color: $azure-medium;
        border-color: $azure-medium;
      }
    }

    &:active {
      background-color: darken($azure-medium, 5%);
      border-color: darken($azure-medium, 5%);
    }
  }

  .as-AppSelect-modern-light & {
    min-height: 40px;
  }

  .as-AppSelect-modern-medium-light & {
    background: $azure;
    height: 44px;
    border-radius: $border-radius-md;

    .as-AppDroplistButton_Content {
      padding-left: 16px;
    }

    .as-AppDroplistButton_Icon-append {
      margin-right: 16px;
    }
  }
}

.as-AppDroplistButton_Content {
  .as-AppSelect-simple & {
    font-weight: fw('regular');
    font-size: $fs-16;
    line-height: 21px;

    &:deep(.ass-SelectedItems) {
      padding: 0;
    }
  }
}

.as-AppDroplistButton_Icon-append {
  .as-AppSelect-simple & {
    margin: 0 0 0 4px;
  }
}

.as-CreateEntityButton {
  display: flex;
  align-items: center;
  padding: 10px;
  color: $regular-text;
  font-size: $fs-14;
  line-height: 20px;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }
}

.as-CreateEntityButton_Icon {
  flex-shrink: 0;
  color: $dark-2;
}

.as-CreateEntityButton_Content {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.as-SelectedOptionsInside {
  $gap: 6px;
  border: 2px solid $primary-color-next;
  border-radius: $border-radius-sm-next;
  display: flex;
  align-items: center;
  padding: $gap;
  gap: $gap;
  // padding-right: 6px;

  // max-width: calc(100% - 30px);

  &:deep(.si-SelectedItem) {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      line-height: initial;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .si-selected-tag-clear {
      flex-shrink: 0;
    }
  }
}

.as-SelectedOptionsInside_Items {
  max-width: calc(100% - 30px);
  width: 100%;
}

.as-GroupDivider {
  background: $grey-2-next;
  height: 1px;
  margin: 4px 10px;
  pointer-events: none;
  display: flex;
}
</style>

<style lang="scss">
@import '~@/assets/styles/mixins';

.as-AppDroplistButton {
  .si-SelectedItem {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      line-height: initial;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .si-SelectedItem + .si-SelectedItem {
    margin-left: 0;
  }

  &-highlight {
    .si-SelectedItem {
      background-color: rgba($blue-1, 0.1);
      color: $primary-color;

      &-circle {
        background-color: transparent;
        color: $regular-text;
        font-size: $fs-14;
        font-weight: fw('medium');
      }
    }

    .si-selected-tag-clear,
    .as-AppDroplistButton_Text {
      color: $primary-color;
    }
  }

  .si-selected-tag-clear {
    flex-shrink: 0;
  }
}

.as-AppDroplistContent {
  .o-checkbox-label-text {
    min-height: 18px;
  }

  input {
    box-shadow: none !important;
  }
}

.dropdown-search-input {
  box-shadow: none !important;
}

.as-AppSelect {
  &-inline {
    .as-AppDroplistButton {
      border-color: transparent;
      background-color: transparent;

      @include activeState($grey-medium, 10%);
      @include hoverState($grey-medium, 0%);
      @include hoverState($grey-11, 0%, 'border-color');

      &-focused,
      &-focused:hover {
        border-color: $primary-color;
        background-color: $white;
      }
    }
  }
}
</style>
