<template>
  <div class="ap-AdminsPage">
    <div class="ap-Toolbar">
      <div class="ap-Toolbar_Description">
        {{ $t('admins.description') }}
      </div>
      <AppButton
        height="24"
        icon="plus-next"
        type="primary-next"
        width="24"
        @click="onAddAdminClick"
      >
        {{ $t('create.admin.title') }}
      </AppButton>
    </div>

    <div class="ap-MainContent">
      <AppTable
        :columns="tableColumns"
        :data="admins"
        :disable-user-select="false"
        :hover-row="tableHoverRow"
        :loading="loading"
        border-on-last-row
        class="ap-Table"
        no-border
        offset-left="var(--page-left-padding)"
        offset-right="var(--page-right-padding)"
        type="primary-next"
      >
        <template #header-cell="{ column }">
          <template v-if="column.title"> {{ $t(column.title) }}</template>
        </template>
        <template #cell="{ columnKey, item, index }">
          <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
            <UserNameCell :item="item" item-label="name" />
          </template>
          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ROLE">
            <AdminRoleCell :admin="item" :roles="roles" @role-updated="onRoleUpdated" />
          </template>
          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.PLATFORM">
            <UserPlatformsCell
              :loading="platformCellLoadingIds.includes(item[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID])"
              :platforms="item[WEB_APP_ENTITY_KEYS.PLATFORM_IDS]"
            />
          </template>
          <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
            <DropdownMenu
              :items="menuItemActions(item)"
              :offset="[0, 0]"
              position="bottom-end"
              type="default-next"
              @close="tableHoverRow = -1"
              @open="tableHoverRow = index"
              @item-click="onMenuActionsClick($event, item)"
            >
              <template #activator="{ isOpened }">
                <AppButton
                  :class="{ 'ap-ActionsButton-active': isOpened }"
                  class="ap-ActionsButton"
                  icon="more-next"
                  size="sm"
                  type="tertiary"
                />
              </template>

              <template #item-label="{ item: labelItem }">
                <DropdownMenuItemLabelWithDescription :label-item="labelItem" />
              </template>
            </DropdownMenu>
          </template>
          <template v-else>
            {{ item[columnKey] }}
          </template>
        </template>
        <template #loading>
          <PluginAdminsTableLoader />
        </template>

        <template #footer>
          <AppTableCreateButton full-width icon-name="plus-next" @click="onAddAdminClick">
            {{ $t('create.admin.title') }}
          </AppTableCreateButton>
        </template>
      </AppTable>
    </div>

    <AdminModal
      v-model:show="showAdminModal"
      :existed-admins="admins"
      @admin-added="onAdminAdded"
    />

    <AdminDeleteModal
      :admin-to-delete="adminToDelete"
      :show="showAdminDeleteModal"
      @on-close="showAdminDeleteModal = false"
      @on-confirm="deleteAdmin"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import RolesApiHandler from '@/api/roles'
import WorkspaceAdministratorsApiHandler from '@/api/workspace-administrators'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_SOURCES } from '@/tracking/amplitude-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
import { ASSIGNEE_ENTITY_KEYS, WEB_APP_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { MENU_ITEMS_GROUPS } from '@/utils/objectives'
import {
  usePlatformHelpers,
  useRemoveUserActionsDisabledState,
  useWebAppAccessActionsForUser
} from '@/utils/platforms-helpers'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import DropdownMenuItemLabelWithDescription from '@/components/ui/DropdownMenu/DropdownMenuItemLabelWithDescription'
import PluginAdminsTableLoader from '@/components/ui/SkeletonLoaders/PluginAdminsTableLoader'
import AppTable from '@/components/ui/Table/AppTable'
import AdminDeleteModal from '@/components/workspaces/AdminDeleteModal'
import AdminModal from '@/components/workspaces/AdminModal'
import AdminRoleCell from '@/views/workspaces/settings/plugin-users/AdminRoleCell'
import UserNameCell from '@/views/workspaces/settings/plugin-users/UserNameCell'
import UserPlatformsCell from '@/views/workspaces/settings/plugin-users/UserPlatformsCell'

const adminsApi = new WorkspaceAdministratorsApiHandler()

export default defineComponent({
  name: 'AdministratorsPage',

  components: {
    DropdownMenuItemLabelWithDescription,
    DropdownMenu,
    AdminRoleCell,
    UserPlatformsCell,
    UserNameCell,
    AppTableCreateButton,
    AppTable,
    PluginAdminsTableLoader,
    AppButton,
    AdminModal,
    AdminDeleteModal
  },

  inject: ['isJiraApp', 'isWebApp'],

  setup() {
    const { platformCell } = usePlatformHelpers()
    const { getActionDisabledState } = useRemoveUserActionsDisabledState()
    const { getWebAppAccessAction, onGrantRevokeWebAccess, platformCellLoadingIds } =
      useWebAppAccessActionsForUser()

    return {
      platformCell,
      getWebAppAccessAction,
      onGrantRevokeWebAccess,
      platformCellLoadingIds,
      getActionDisabledState
    }
  },

  data() {
    return {
      loading: false,
      showAdminModal: false,
      showAdminDeleteModal: false,
      adminToDelete: null,
      roles: [],
      admins: [],

      tableHoverRow: -1
    }
  },

  computed: {
    WEB_APP_ENTITY_KEYS: () => WEB_APP_ENTITY_KEYS,
    TABLE_COLUMNS_KEYS: () => TABLE_COLUMNS_KEYS,
    ASSIGNEE_ENTITY_KEYS: () => ASSIGNEE_ENTITY_KEYS,

    ...mapState('pluginOptions', {
      isOwner: state => state.isOwner
    }),

    tableColumns() {
      const ROLE_CELL =
        this.isWebApp && this.isOwner
          ? {
              title: 'users.table_header_role',
              key: TABLE_COLUMNS_KEYS.ROLE,
              width: 136
            }
          : null

      return [
        {
          title: 'users.table_header_name',
          key: TABLE_COLUMNS_KEYS.NAME,
          width: 'auto'
        },
        this.platformCell,
        ROLE_CELL,
        {
          key: TABLE_COLUMNS_KEYS.ACTION,
          width: 24
        }
      ].filter(Boolean)
    }
  },

  created() {
    Promise.all([this.getAdmins(), this.getAdminRoles()])
  },

  methods: {
    menuItemActions(item) {
      let isActionsDisabled = this.getActionDisabledState({ user: item })

      const result = [
        {
          name: ACTIONS_KEYS.REMOVE,
          title: 'action.remove_from_admins',
          icon: 'delete-next',
          color: 'var(--grade-low-color-next)',
          group: MENU_ITEMS_GROUPS.REMOVING,
          disabled: isActionsDisabled
        }
      ]

      const grantRevokeWebAccessAction = this.getWebAppAccessAction({
        user: item
      })

      if (grantRevokeWebAccessAction) {
        result.unshift({
          ...grantRevokeWebAccessAction,
          disabled: isActionsDisabled
        })
      }

      return result
    },

    onMenuActionsClick(name, item) {
      if (name === ACTIONS_KEYS.REMOVE) {
        this.onRemoveClick(item)
      }

      if (name === ACTIONS_KEYS.GRANT_ACCESS || name === ACTIONS_KEYS.REVOKE_ACCESS) {
        this.onGrantRevokeWebAccess({
          user: item,
          action: name,
          tableData: this.admins
        })
      }
    },

    onAddAdminClick() {
      this.showAdminModal = true
    },

    onRoleUpdated({ accountId, adminRoleId }) {
      const admin = this.admins.find(admin => admin[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID] === accountId)

      if (admin) {
        admin[ASSIGNEE_ENTITY_KEYS.ADMIN_ROLE_ID] = adminRoleId
      }
    },

    async getAdminRoles() {
      const rolesApi = new RolesApiHandler()
      try {
        const adminRoles = await rolesApi.getAdminRoles()
        this.roles = adminRoles.map(role => {
          return {
            ...role,
            label: this.$t(role.label)
          }
        })
      } catch (error) {
        handleError({ error })
      }
    },

    async getAdmins() {
      this.loading = true
      try {
        this.admins = await adminsApi.getAdmins()
      } catch (error) {
        handleError({ error })
      } finally {
        this.loading = false
      }
    },

    async deleteAdmin() {
      try {
        await adminsApi.removeAdmin({
          accountId: this.adminToDelete.accountId
        })

        tracker.logEvent('Administrator removed', {
          category: EVENT_CATEGORIES.USER_MANAGEMENT,
          source: EVENT_SOURCES.ADMINISTRATOR_TAB
        })
      } catch (error) {
        handleError({ error })
      }
      this.showAdminDeleteModal = false
      this.adminToDelete = null
      this.getAdmins()
    },

    onDeleteAdmin(admin) {
      this.adminToDelete = admin
      this.showAdminDeleteModal = true
    },

    onRemoveClick(admin) {
      this.onDeleteAdmin(admin)
    },

    onAdminAdded() {
      this.showAdminModal = false
      showNotify({ title: this.$t('notifications.administrator_added') })
      this.getAdmins()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';
.ap-AdminsPage {
  padding-bottom: $page-bottom-padding;
  font-family: $system-ui;
}

.ap-Toolbar {
  width: 100%;
  max-width: $page-width-lg;
  padding: 16px $page-right-padding 35px $page-left-padding;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}

.ap-Toolbar_Description {
  color: $dark-2;
  font-size: $fs-14;
  line-height: 20px;
  max-width: 600px;
}

.ap-MainContent {
  width: 100%;
  max-width: $page-width-lg;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
}

.ap-ActionsButton {
  &-active {
    background-color: $dark-2;
    color: $white;
  }
  @extend %app-table-hidden-items;
}
</style>
