<template>
  <OkrFilterSelect
    :key="`ws-${workspaceId}—af-${fieldId}`"
    :bottom-fixed-items="getBottomFixedItems"
    :model-value="filterValue"
    :n-selected-label="emptyStateLabel"
    :options="options"
    :search-function="getAssignees"
    append-to=".ot-AdditionalFiltersContent_Filters"
    class="af-FilterButton"
    dropdown-search
    has-only-this-button
    item-label="displayName"
    item-value="accountId"
    multi
    skeleton-loader
    type="default-next"
    @update:options="assignees = $event"
    @update:model-value="onUpdateFilterValue"
  >
    <template #bottom-fixed-items="{ bottomFixedItems }">
      <div v-for="item in bottomFixedItems" :key="item.id">
        <BottomFixedSelectItem v-if="isClearSelectionAction(item.action)" @click="resetValue">
          {{ $t(item.text) }}
        </BottomFixedSelectItem>
      </div>
    </template>
    <template #button="{ active, fullDataOption, option }">
      <OkrFormFieldSelectTriggerNext
        :label="fieldName"
        :opened="active"
        :selected-options="option"
        half-width-items
        item-label="displayName"
        item-value="accountId"
        separated-label
        type="secondary"
      >
        <template #values>
          <SelectedUsersAvatarsList
            v-if="getFilteredValues(fullDataOption).length"
            :users="getFilteredValues(fullDataOption)"
          />
          <template v-else-if="isShowAllContain(fullDataOption)">
            <span class="af-SelectButton-Value">
              {{ $t('filter.show_all') }}
            </span>
          </template>
        </template>
      </OkrFormFieldSelectTriggerNext>
    </template>
    <template #option-label="{ option }">
      <OwnerFieldOption :option="option" />
    </template>
  </OkrFilterSelect>
</template>

<script setup>
defineOptions({
  name: 'AssigneeFilter',
  inheritAttrs: false
})

import { cloneDeep, isEmpty, isEqual } from 'lodash'
import { computed, nextTick, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import {
  getBottomFixedItemsClearSelectionForCustomFieldFilter,
  isClearSelectionAction
} from '@/composables/bottom-fixed-items'
import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { ASSIGNEE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import {
  CUSTOM_FIELD_FILTER_DEFAULT_VALUES,
  isShowAllContain,
  SELECT_ALL_VALUE
} from '@/utils/okr-elements/filters'

import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import SelectedUsersAvatarsList from '@/components/form/SelectedUsersAvatarsList'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'

const props = defineProps({
  fieldId: {
    type: [String, Number],
    required: true
  },

  workspaceId: {
    type: [String, Number],
    required: true
  },

  filterValue: {
    type: Array,
    required: true
  }
})

const DEFAULT_VALUE = CUSTOM_FIELD_FILTER_DEFAULT_VALUES[ALL_CUSTOM_FIELDS.getTypeIds().ASSIGNEE]

const emit = defineEmits({
  'update-filter-value': null,
  'initial-data-loaded': null
})

const onUpdateFilterValue = value => {
  emit('update-filter-value', {
    value,
    fieldId: props.fieldId
  })
}

const getFilteredValues = items => {
  return items.filter(item => item[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID])
}

const { t } = useI18n()

const options = computed(() => {
  return [
    {
      [ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID]: SELECT_ALL_VALUE,
      displayName: t('filter.show_all')
    },
    ...assignees.value
  ]
})

const { fieldName, emptyStateLabel } = useCustomFieldsHelpers({
  fieldId: props.fieldId
})

const isFilterValidated = ref(false)
const getAssignees = async (searchString = null) => {
  const api = new AssigneesInfoApiHandler()
  let result = []
  try {
    result = await api.getUsers({
      searchString,
      workspaceId: props.workspaceId,
      requiredUserAccountIds: props.filterValue.filter(item => item !== SELECT_ALL_VALUE)
    })

    if (!isFilterValidated.value) {
      const allAssigneeIds = result.map(item => item[ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID])
      const validatedValue = props.filterValue.filter(item => allAssigneeIds.includes(item))
      const resolvedValidatedValue = isEmpty(validatedValue)
        ? cloneDeep(DEFAULT_VALUE)
        : validatedValue

      if (
        !isEqual(props.filterValue, resolvedValidatedValue) ||
        !isEqual(resolvedValidatedValue, DEFAULT_VALUE)
      ) {
        onUpdateFilterValue(resolvedValidatedValue)
      }

      isFilterValidated.value = true
    }

    await nextTick()
    emit('initial-data-loaded')
  } catch (error) {
    handleError({ error })
  }
  return result
}

const assignees = ref([])
onMounted(async () => {
  assignees.value = await getAssignees()
})

const getBottomFixedItems = computed(() => {
  return getBottomFixedItemsClearSelectionForCustomFieldFilter({
    filterValue: props.filterValue
  })
})

const resetValue = () => {
  onUpdateFilterValue(cloneDeep(DEFAULT_VALUE))
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/filters';

.af-FilterButton {
  width: 100%;
}

.af-SelectButton-Value {
  @extend %select-button-value;
}
</style>
