<template>
  <div ref="wrapperReference" class="ch-Head">
    <div class="ch-Head_InputField">
      <AppInput
        v-model="localKey"
        :is-error="showKeyErrors"
        class="ch-Head_Key"
        data-testid="key-input"
        max-length="3"
        size="xlg"
        style-type="title"
        @blur="onKeyInputFocusOut"
      />

      <AppFieldError :show="showKeyErrors" class="ch-HeadInputFieldError">
        <span v-show="keyLengthIsNotValid">
          {{ $t('field.workspace_key_length') }}
        </span>
        <template v-if="!isEmpty(keyErrors)">
          <span v-for="error in keyErrors" :key="error">
            {{ error }}
          </span>
        </template>
      </AppFieldError>
    </div>

    <div class="ch-Head_InputField">
      <AppInput
        v-model="modelValue.name"
        :is-error="showNameErrors"
        class="ch-Head_Name"
        data-testid="name-input"
        max-length="30"
        size="xlg"
        style-type="title"
        @blur="onNameInputFocusOut"
      />

      <AppFieldError :show="showNameErrors" class="ch-HeadInputFieldError">
        <span v-show="isNameEmpty || nameLengthNotValid">
          {{ $t(isNameEmpty ? 'field.required' : 'field.workspace_name') }}
        </span>
        <template v-if="!isEmpty(nameErrors)">
          <span v-for="error in nameErrors" :key="error">
            {{ error }}
          </span>
        </template>
      </AppFieldError>
    </div>

    <div class="ch-Head_Visibility">
      <AppVisibilitySwitch
        :model-value="modelValue.public"
        data-testid="visibility-switch"
        @update:model-value="updateWorkspace(WS_VISIBILITY_FIELD_NAME, $event)"
      />
    </div>

    <div class="ch-Head_Timezone" data-testid="timezone-wrapper">
      <AppSelect
        :model-value="modelValue.timezone"
        :offset="[0, 0]"
        :options="timezonesOptions"
        :search-function="value => localSearch({ value, options: timezonesOptions })"
        data-testid="timezone-select"
        dropdown-search
        item-label="label"
        item-value="value"
        position="bottom-end"
        show-all-selected
        theme="no-shadow-next light"
        type="default-next"
        @update:model-value="onTimezoneUpdate"
      >
        <template #button="{ option, active }">
          <div
            :class="{ 'ch-Head_TimezoneSelectButton-opened': active }"
            class="ch-Head_TimezoneSelectButton"
          >
            <span class="ch-TimezoneSelectButtonLabel" data-testid="timezone-button-label">
              {{ option ? option.label : $t('workspace.select_timezone') }}
            </span>
          </div>
        </template>
      </AppSelect>
    </div>
  </div>
</template>

<script setup>
import { useIntersectionObserver } from '@vueuse/core'
import { cloneDeep, isEmpty, isEqual, isUndefined } from 'lodash'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import WorkspacesApiHandler from '@/api/workspaces'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import { checkSomeValueIsTruthy } from '@/utils/general'
import { showNotify } from '@/utils/notify'
import { localSearch } from '@/utils/objectives'
import { timezonesOptions } from '@/utils/timezones'

import AppFieldError from '@/components/form/AppFieldError'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppVisibilitySwitch from '@/components/workspaces/AppVisibilitySwitch'

defineOptions({
  name: 'ConfigurationHead'
})

const props = defineProps({
  data: {
    type: Object,
    required: true
  },

  wsKey: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  'update:ws-key': null,
  'update:current-workspace-name': null,
  'update:areDataChanged': null,
  'saving-started': null,
  'saving-finished': null
})

const modelValue = ref({})

const setLocalModelValue = () => {
  modelValue.value = cloneDeep(props.data)
}

watch(
  () => props.data,
  () => {
    setLocalModelValue()
  },
  { deep: true, immediate: true }
)

const wrapperReference = ref(null)
const wrapperReferenceIsVisible = ref(false)

useIntersectionObserver(
  wrapperReference,
  ([{ isIntersecting }]) => {
    wrapperReferenceIsVisible.value = isIntersecting
  },
  {
    threshold: 0.99
  }
)

watch(
  wrapperReferenceIsVisible,
  (newValue, oldValue) => {
    if (newValue === oldValue) return
    if (isUndefined(oldValue)) {
      emit('update:current-workspace-name', null)
    }
    const resolvedName = isEmpty(modelValue.value) ? null : modelValue.value.name
    // we not show current workspace name near icon in modal header if this component is visible
    const emmitedName = newValue ? null : resolvedName
    emit('update:current-workspace-name', emmitedName)
  },
  { immediate: true }
)

const keyErrors = ref([])
const keyLengthIsNotValid = ref(false)

const localKey = computed({
  get: () => props.wsKey,
  set: value => {
    keyLengthIsNotValid.value = false
    keyErrors.value = []
    emit('update:ws-key', value.toUpperCase())
  }
})

const nameErrors = ref([])

const showKeyErrors = computed(() => {
  return !isEmpty(keyErrors.value) || keyLengthIsNotValid.value
})

const showNameErrors = computed(() => {
  return !isEmpty(nameErrors.value) || isNameEmpty.value || nameLengthNotValid.value
})

const store = useStore()

const WS_KEY_FIELD_NAME = 'key'
const WS_NAME_FIELD_NAME = 'name'
const WS_VISIBILITY_FIELD_NAME = 'isPublic'
const WS_TIMEZONE_FIELD_NAME = 'timezone'

const savingStatuses = ref({
  [WS_KEY_FIELD_NAME]: false,
  [WS_NAME_FIELD_NAME]: false,
  [WS_VISIBILITY_FIELD_NAME]: false,
  [WS_TIMEZONE_FIELD_NAME]: false
})

const isSavingProceed = computed(() => {
  return checkSomeValueIsTruthy(...Object.values(savingStatuses.value))
})

const localAreDataChanged = computed(() => {
  const { name, public: isPublic, key, timezone } = props.data
  const workspaceData = {
    name,
    public: isPublic,
    key,
    timezone
  }
  const localData = {
    name: modelValue.value.name,
    public: modelValue.value.public,
    key: localKey.value,
    timezone: modelValue.value.timezone
  }

  return !isEqual(workspaceData, localData)
})

watch(
  localAreDataChanged,
  value => {
    emit('update:areDataChanged', value)
  },
  { immediate: true }
)

watch(isSavingProceed, value => {
  if (value) {
    emit('saving-started')
  } else {
    emit('saving-finished')
  }
})

const TRANSLATION_KEYS = {
  [WS_KEY_FIELD_NAME]: 'workspace.label.key',
  [WS_NAME_FIELD_NAME]: 'workspace.label.name',
  [WS_VISIBILITY_FIELD_NAME]: 'workspaces.visibility',
  [WS_TIMEZONE_FIELD_NAME]: 'workspace.timezone'
}

const { t } = useI18n()

const updateWorkspace = async (fieldName, value) => {
  if (!fieldName || isUndefined(value)) {
    throw new Error('Field name and Value is required')
  }

  savingStatuses.value[fieldName] = true
  try {
    const workspacesApi = new WorkspacesApiHandler()
    const { id, name, public: isPublic, key, timezone } = props.data
    const payload = {
      id,
      name,
      isPublic,
      key,
      timezone,
      [fieldName]: value
    }

    const response = await workspacesApi.updateWorkspace(payload)

    if (!isEmpty(response.errors)) {
      response.errors.forEach(errorItem => {
        const { fieldName, error } = errorItem
        if (fieldName === WS_KEY_FIELD_NAME) {
          keyErrors.value.push(error)
        }
        if (fieldName === WS_NAME_FIELD_NAME) {
          nameErrors.value.push(error)
        }
      })
    } else {
      await store.dispatch('workspaces/updateWorkspace', response.workspace)
      showNotify({
        title: t('workspaces.workspace_field_updated', {
          fieldName: t(TRANSLATION_KEYS[fieldName])
        })
      })

      const trackingData = {
        category: EVENT_CATEGORIES.WORKSPACE_MANAGEMENT,
        subCategory: t(TRANSLATION_KEYS[fieldName], 1, { locale: 'en' }),
        value
      }

      if (fieldName === WS_TIMEZONE_FIELD_NAME) {
        const newTimezone = timezonesOptions.find(option => option.value === value)
        trackingData.value = newTimezone?.label || value
      }

      tracker.logEvent('Updated workspace', trackingData)
    }
  } catch (error) {
    handleError({ error })
  } finally {
    savingStatuses.value[fieldName] = false
  }
}

const onKeyInputFocusOut = () => {
  keyErrors.value = []
  keyLengthIsNotValid.value = !localKey.value.length || localKey.value.length > 3
  if (!keyLengthIsNotValid.value && localKey.value !== props.data.key) {
    updateWorkspace(WS_KEY_FIELD_NAME, localKey.value)
  }
}

const isNameEmpty = ref(false)
const nameLengthNotValid = ref(false)
const onNameInputFocusOut = () => {
  nameErrors.value = []

  isNameEmpty.value = modelValue.value.name.trim() === ''
  nameLengthNotValid.value = modelValue.value.name.length < 2
  if (
    !nameLengthNotValid.value &&
    !isNameEmpty.value &&
    modelValue.value.name !== props.data.name
  ) {
    updateWorkspace(WS_NAME_FIELD_NAME, modelValue.value.name)
  }
}

const onTimezoneUpdate = value => {
  modelValue.value.timezone = value
  updateWorkspace(WS_TIMEZONE_FIELD_NAME, value)
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.ch-Head {
  padding: 0 40px 0 30px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.ch-HeadInputFieldError {
  margin-top: 4px;
  display: block;
}

.ch-Head_Key {
  width: 115px;
}

.ch-Head_Name {
  flex: 1 0;

  &:deep(.o-input-field) {
    width: 100%;
  }
}

.ch-Head_Visibility,
.ch-Head_Timezone {
  padding-top: 6px;
}

.ch-Head_TimezoneSelectButton {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-13;
  display: flex;
  align-items: center;
  color: $dark-1;

  padding: 6px 10px;
  cursor: pointer;
  width: 200px;
  border-radius: $border-radius-sm-next;
  min-height: 32px;

  @include hoverState($grey-3-next, 0%);
  @include activeState($grey-3-next, 2%);

  &-opened {
    background-color: $grey-3-next;
  }
}

.ch-TimezoneSelectButtonLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
