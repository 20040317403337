<template>
  <div class="ale-Wrapper">
    <div class="ale-Wrapper_Content">
      <ObjectivesEmptyHero height="240" name="no-license-hero" width="424" />

      <div class="ale-Content">
        <AppTitle :level="1" class="ale-Title" disable-margin>
          <i18n-t keypath="expired_license.title" scope="global">
            <template #break>
              <br />
            </template>
            <template #platform>
              {{ platform }}
            </template>
          </i18n-t>
        </AppTitle>

        <i18n-t class="ale-Subtitle" keypath="expired_license.subtitle" scope="global" tag="div">
          <template #break>
            <br />
          </template>
          <template #platform>
            {{ platform }}
          </template>
        </i18n-t>

        <AppDivider class="ale-Divider" />

        <AppButton class="ale-Button" type="primary-next" @click="handle">
          {{ $t('expired_license.button') }}
        </AppButton>

        <a :href="link" class="ale-Link" target="_blank">
          {{ $t('expired_license.link') }}
        </a>
      </div>
    </div>

    <div class="ale-Message">
      <AppIcon class="ale-Message_Icon" height="24" icon-name="info-next" width="24" />

      <div class="ale-Message_Text">
        {{ $t('expired_license.message') }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

// import { LICENSE_TYPES } from '@/utils/license-types'

import { openLink } from '@/utils/external-link-helper'
import { getAtlassianBaseUrl } from '@jira/util'

import ObjectivesEmptyHero from '@/components/objectives/ObjectivesEmptyHero'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

export default defineComponent({
  name: 'AppLicenseExpired',
  components: { AppButton, AppDivider, AppTitle, ObjectivesEmptyHero, AppIcon },
  props: {
    // licenseType: {
    //   type: String,
    //   required: true
    // }

    isPluginServer: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    platform() {
      return this.$t('app.platform.jira')
    },

    link() {
      const queryParam = this.isPluginServer ? 'datacenter' : 'cloud'

      return `https://marketplace.atlassian.com/apps/1219988/okr-board-for-jira?tab=pricing&hosting=${queryParam}`
    }

    // keyPath() {
    //   const { licenseType } = this
    //   const standardKeyPath = 'expired_license.standard_and_none'
    //   const trialKeyPath = 'expired_license.trial'
    //
    //   return [LICENSE_TYPES.JIRA_STANDARD, LICENSE_TYPES.JIRA_NONE].includes(licenseType)
    //     ? standardKeyPath
    //     : trialKeyPath
    // }
  },

  methods: {
    handle() {
      if (this.isPluginServer) {
        openLink('https://www.atlassian.com/purchase/addon/okrplugin_prod.data-center')
      } else {
        const atlassianBaseUrl = getAtlassianBaseUrl()

        openLink(`${atlassianBaseUrl}/plugins/servlet/upm?source=side_nav_manage_addons`)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/canvas-dimensions';

.ale-Wrapper {
  font-family: $system-ui;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: $default-canvas-height-non-objectives-page;
}

.ale-Wrapper_Content {
  padding-bottom: 60px;
  margin: auto 0;
}

.ale-Content {
  max-width: 595px;
  display: grid;
  gap: 16px;
}

.ale-Title {
  font-family: $system-ui;
  white-space: normal;
}

.ale-Subtitle,
.ale-Message_Text,
.ale-Link {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  text-align: center;
  color: $dark-3;
}

.ale-Divider {
  width: 100%;
  margin: 2px 0 0;
}

.ale-Button {
  margin: 8px auto 0;
}

.ale-Link {
  color: $primary-color-next;
  margin: 9px auto 0;
}

.ale-Message {
  color: $dark-3;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px 10px 10px;
  background-color: $grey-3-next;
  border-radius: $border-radius-sm-next;
  margin: 0 auto;
}

.ale-Message_Text {
  text-align: left;
}

.ale-Message_Icon {
  flex: 1 0 auto;
}
</style>
