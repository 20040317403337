<template>
  <div>
    <AppLink v-if="clickable" :to="routeTo">
      <template #default="{ href, onClick }">
        <a
          :class="{ 'dc-DashboardCount-empty': !getValue }"
          :href="href"
          class="dc-DashboardCount"
          target="_blank"
          @click.prevent="onClick"
        >
          {{ getValue }}
        </a>
      </template>
    </AppLink>
    <span
      v-else
      :class="{ 'dc-DashboardCount-empty': !getValue }"
      class="dc-DashboardCount dc-DashboardCount-notClickable"
    >
      {{ getValue }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import AppLink from '@/components/AppLink'

defineOptions({
  name: 'DashboardCount'
})

const props = defineProps({
  value: {
    type: Number,
    default: 0
  },

  routeTo: {
    type: Object,
    default: () => ({})
  },

  clickable: {
    type: Boolean
  }
})

const getValue = computed(() => props.value || 0)
</script>

<style lang="scss" scoped>
.dc-DashboardCount {
  color: var(--text-color, $primary-color-next);
  text-decoration: none;
  text-align: right;

  &-empty {
    --text-color: var(--empty-color, #{$grey-1-next});
  }
  &-notClickable {
    cursor: default;
  }
}
.tb-Cell .dc-DashboardCount,
.te-Cell .dc-DashboardCount {
  width: 100%;
  display: inline-block;
}
</style>
