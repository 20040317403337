import { memoizeGetCssVariableValue, memoizeHexToRgb } from '@/utils/memoizations'
import { THRESHOLDS_DEFAULT_VALUES } from '@/utils/thresholds'

const MIN_GRADE_VALUE = 0
const MAX_GRADE_VALUE = 100

export const calculateGradeValue = (targetValue, originalValue, currentValue) => {
  const [normalizedTargetValue, normalizedOriginalValue, normalizedCurrentValue] = [
    targetValue,
    originalValue,
    currentValue
  ].map(Number)

  if (normalizedTargetValue === normalizedOriginalValue) {
    // Condition added in scope of https://oboard.atlassian.net/browse/OK-2731
    return normalizedCurrentValue === normalizedTargetValue ? MAX_GRADE_VALUE : MIN_GRADE_VALUE
  }

  if (
    (normalizedOriginalValue < normalizedTargetValue &&
      normalizedCurrentValue < normalizedOriginalValue) ||
    (normalizedOriginalValue > normalizedTargetValue &&
      normalizedCurrentValue > normalizedOriginalValue)
  ) {
    return MIN_GRADE_VALUE
  }

  let result = Math.round(
    ((normalizedCurrentValue - normalizedOriginalValue) /
      (normalizedTargetValue - normalizedOriginalValue)) *
      100
  )

  if (result < MIN_GRADE_VALUE || Number.isNaN(result)) {
    result = MIN_GRADE_VALUE
  }

  return result
}

export const getGradeDisplayValue = grade => {
  return Math.round(grade)
}

export const GRADE_VALUE_DEFAULT_COLOR = '#172B4D'

export const CUSTOM_COLORS = {
  'primary-color-next': '--primary-color-next'
}
export const THRESHOLDS_COLORS = {
  AT_RISK: '--grade-low-color-next',
  BEHIND: '--grade-medium-color-next',
  ON_TRACK: '--grade-high-color-next',
  CLOSED: '--grade-closed-color',
  ABANDONED: '--grade-abandoned-color',
  BACKLOG: '--grade-backlog-color',
  DEFAULT: '--grade-default-color',
  NOT_STARTED: '--grade-default-color'
}

const GRADE_COLORS = {
  'grade-green': {
    variable: THRESHOLDS_COLORS.ON_TRACK
  },
  'grade-purpur': {
    variable: THRESHOLDS_COLORS.BEHIND
  },
  'grade-red': {
    variable: THRESHOLDS_COLORS.AT_RISK
  },
  'grade-default': {
    variable: THRESHOLDS_COLORS.DEFAULT
  },
  'grade-white': {
    variable: THRESHOLDS_COLORS.CLOSED
  },
  'grade-black': {
    variable: THRESHOLDS_COLORS.BACKLOG
  },
  'grade-blue': {
    variable: THRESHOLDS_COLORS.ABANDONED
  },
  'grade-yellow': {
    variable: THRESHOLDS_COLORS.NOT_STARTED
  },
  'grade-gray': {
    variable: THRESHOLDS_COLORS.DEFAULT
  }
}

export const getGradeColorByValue = ({
  value = 0,
  thresholdBehind = THRESHOLDS_DEFAULT_VALUES.BEHIND,
  thresholdOnTrack = THRESHOLDS_DEFAULT_VALUES.ON_TRACK
}) => {
  const { AT_RISK, BEHIND, ON_TRACK } = THRESHOLDS_COLORS
  const [color] = [
    value < thresholdBehind && AT_RISK,
    value < thresholdOnTrack && BEHIND,
    ON_TRACK
  ].filter(Boolean)

  return color
}

export const getGradeColorNameByValue = ({
  value = 0,
  thresholdBehind = THRESHOLDS_DEFAULT_VALUES.BEHIND,
  thresholdOnTrack = THRESHOLDS_DEFAULT_VALUES.ON_TRACK
}) => {
  const [name] = [
    value <= thresholdBehind && 'grade-red',
    value <= thresholdOnTrack && 'grade-purpur',
    'grade-green'
  ].filter(Boolean)

  return name
}

export const getGradeColorStyle = gradeColor => {
  const colorVariable = GRADE_COLORS[gradeColor]?.variable || THRESHOLDS_COLORS.AT_RISK

  const hex = memoizeGetCssVariableValue(colorVariable)
  const rgbColor = memoizeHexToRgb(hex.trim())

  return {
    color: `var(${colorVariable})`,
    '--color': `var(${colorVariable})`,
    '--rgb-color': rgbColor
  }
}

export const getGradeColorVariable = gradeColor =>
  GRADE_COLORS[gradeColor]?.variable || CUSTOM_COLORS[gradeColor] || THRESHOLDS_COLORS.AT_RISK

export const showGradeMultiplier = (weight, weightMultiplier) => {
  return weight !== null && weightMultiplier !== null
}
