<template>
  <div class="df-Filter">
    <AppSelect
      v-model="selectedListOption"
      :append-icon-height="appendIconHeight"
      :append-icon-width="appendIconWidth"
      :append-to="appendTo"
      :disabled="disabled"
      :dropdown-min-width="200"
      :no-left-padding="noLeftPadding"
      :offset="[0, 0]"
      :options="listOptions"
      theme="no-shadow-next light"
      type="default-next"
      @change="onSelectedListOptionChange"
    >
      <template #button="{ option, active }">
        <slot :active="active" :displayed-dates="displayedDates" :option="option" name="button" />
      </template>
      <template #button-content="{ option }">
        <slot :displayed-dates="displayedDates" :option="option" name="button-content" />
      </template>
    </AppSelect>

    <div class="df-DatepickerWrapper">
      <DatePicker
        v-model="selectedDates"
        v-model:open="showDatepicker"
        :lang="getDatepickerOptions"
        :popup-style="popupStyle"
        popup-class="dp-Datepicker"
        range
        type="date"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isNull } from 'lodash'
import { defineComponent } from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import { compensateTimeZone, datepickerLang, getDisplayedDates, localDateToUtc } from '@/utils/date'
import { isValidDatesArray, isValidDateString } from '@/utils/okr-elements/filters'

import AppSelect from '@/components/ui/AppSelect/AppSelect'

dayjs.extend(utc)

const LIST_OPTIONS = {
  SHOW_ALL: 'show_all',
  THIS_WEEK: 'this_week',
  THIS_MONTH: 'this_month',
  CUSTOM_RANGE: 'custom_range'
}

const getStartEndDates = unit => {
  return {
    start: dayjs.utc().startOf(unit).hour(0).toDate(),
    end: dayjs.utc().endOf(unit).toDate()
  }
}

const [, WEEK_UNIT] = LIST_OPTIONS.THIS_WEEK.split('_')
const [, MONTH_UNIT] = LIST_OPTIONS.THIS_MONTH.split('_')

/*
 * This filter has local state with date including timezone
 * and exposes API with UTC time
 */

export default defineComponent({
  name: 'DateFilter',
  components: { AppSelect, DatePicker },
  props: {
    modelValue: {
      type: Array,
      default: null
    },

    appendTo: {
      type: String,
      default: ''
    },

    noLeftPadding: {
      type: Boolean
    },

    appendIconWidth: {
      type: [String, Number],
      default: 24
    },

    appendIconHeight: {
      type: [String, Number],
      default: 24
    },

    dateFormat: {
      type: String,
      default: 'DD MMM YYYY'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    dateCanBeNull: {
      type: Boolean
    },

    popupStyle: {
      type: Object,
      default: () => ({})
    }
  },

  emits: { 'update:modelValue': null },

  data() {
    return {
      selectedListOption: LIST_OPTIONS.SHOW_ALL,
      showDatepicker: false,
      selectedDates: []
    }
  },

  computed: {
    getDatepickerOptions() {
      return datepickerLang()
    },

    displayedDates() {
      const { dateFormat } = this
      const { selectedDates } = this
      return getDisplayedDates({
        selectedDates,
        dateFormat
      })
    },

    listOptions() {
      return Object.values(LIST_OPTIONS).map(option => {
        return {
          label: this.$t(`filter.${option}`),
          value: option
        }
      })
    }
  },

  watch: {
    selectedDates: {
      handler(newValue) {
        if (!newValue) {
          if (this.modelValue !== null) {
            this.$emit('update:modelValue', null)
          }
          return
        }

        if (!this.dateCanBeNull && newValue.every(date => !isNull(date))) {
          const endDate = newValue[1]
          endDate.setHours(23)
          endDate.setMinutes(59)
          endDate.setSeconds(59)
          endDate.setMilliseconds(999)
          this.$emit('update:modelValue', [localDateToUtc(newValue[0]), localDateToUtc(endDate)])
        }
      },

      deep: true
    },

    modelValue: {
      handler(newValue) {
        if (isNull(newValue) && this.selectedListOption !== LIST_OPTIONS.SHOW_ALL) {
          this.selectedListOption = LIST_OPTIONS.SHOW_ALL
          this.updateLocalsFromValue(newValue)
        } else if (
          isValidDatesArray(this.selectedDates) &&
          !isNull(newValue) &&
          !this.dateCanBeNull
        ) {
          const [selectedDateFrom, selectedDateTo] = this.selectedDates
          const [modelDateFrom, modelDateTo] = newValue

          if (
            Number(selectedDateFrom) !== Number(compensateTimeZone(modelDateFrom)) ||
            Number(selectedDateTo) !== Number(compensateTimeZone(modelDateTo))
          ) {
            this.updateLocalsFromValue(newValue)
          }

          // if (
          //   +this.selectedDates[0] !== +this.compensateTimezone(newValue[0]) ||
          //   +this.selectedDates[1] !== +this.compensateTimezone(newValue[1])
          // ) {
          //   this.updateLocalsFromValue(newValue)
          // }
        } else {
          this.updateLocalsFromValue(newValue)
        }
      },

      immediate: true,
      deep: true
    }
  },

  methods: {
    onSelectedListOptionChange(optionValue) {
      if (optionValue === LIST_OPTIONS.CUSTOM_RANGE) {
        this.showDatepicker = true
      } else if (optionValue === LIST_OPTIONS.THIS_WEEK) {
        this.selectedDates = [
          compensateTimeZone(getStartEndDates(WEEK_UNIT).start),
          compensateTimeZone(getStartEndDates(WEEK_UNIT).end)
        ]
      } else if (optionValue === LIST_OPTIONS.THIS_MONTH) {
        this.selectedDates = [
          compensateTimeZone(getStartEndDates(MONTH_UNIT).start),
          compensateTimeZone(getStartEndDates(MONTH_UNIT).end)
        ]
      } else if (optionValue === LIST_OPTIONS.SHOW_ALL) {
        this.$emit('update:modelValue', null)
      }
    },

    updateLocalsFromValue(value) {
      if (!value) {
        this.selectedDates = null
        return
      }

      const [dateFrom, dateTo] = value

      // this.selectedDates = [this.compensateTimezone(value[0]), this.compensateTimezone(value[1])]

      let resolvedDateFrom =
        this.dateCanBeNull && isNull(dateFrom) ? dateFrom : compensateTimeZone(dateFrom)
      let resolvedDateTo =
        this.dateCanBeNull && isNull(dateTo) ? dateTo : compensateTimeZone(dateTo)

      this.selectedDates = [resolvedDateFrom, resolvedDateTo]

      // const thisWeekDates = [
      //   compensateTimeZone(getStartEndDates(WEEK_UNIT).start),
      //   compensateTimeZone(getStartEndDates(WEEK_UNIT).end)
      // ]
      // const thisMonthDates = [
      //   compensateTimeZone(getStartEndDates(MONTH_UNIT).start),
      //   compensateTimeZone(getStartEndDates(MONTH_UNIT).end)
      // ]

      const [selectedDateFrom, selectedDateTo] = this.selectedDates

      const thisWeekDateFrom = compensateTimeZone(getStartEndDates(WEEK_UNIT).start)
      const thisWeekDateTo = compensateTimeZone(getStartEndDates(WEEK_UNIT).end)

      const thisMonthDateFrom = compensateTimeZone(getStartEndDates(MONTH_UNIT).start)
      const thisMonthDateTo = compensateTimeZone(getStartEndDates(MONTH_UNIT).end)

      if (
        Number(selectedDateFrom) === Number(thisWeekDateFrom) &&
        Number(selectedDateTo) === Number(thisWeekDateTo)
      ) {
        this.selectedListOption = LIST_OPTIONS.THIS_WEEK
      } else if (
        Number(selectedDateFrom) === Number(thisMonthDateFrom) &&
        Number(selectedDateTo) === Number(thisMonthDateTo)
      ) {
        this.selectedListOption = LIST_OPTIONS.THIS_MONTH
      } else if (isValidDateString(resolvedDateFrom) && isValidDateString(resolvedDateTo)) {
        // both dates are valid, but not equal shortcuts
        this.selectedListOption = LIST_OPTIONS.CUSTOM_RANGE
      }

      // if (
      //   +this.selectedDates[0] === +thisWeekDates[0] &&
      //   +this.selectedDates[1] === +thisWeekDates[1]
      // ) {
      //   this.selectedListOption = LIST_OPTIONS.THIS_WEEK
      // } else if (
      //   +this.selectedDates[0] === +thisMonthDates[0] &&
      //   +this.selectedDates[1] === +thisMonthDates[1]
      // ) {
      //   this.selectedListOption = LIST_OPTIONS.THIS_MONTH
      // } else if (dayjs(value[0]).isValid() && dayjs(value[1]).isValid()) {
      //   // both dates are valid, but not equal shortcuts
      //   this.selectedListOption = LIST_OPTIONS.CUSTOM_RANGE
      // }
    }
  }
})
</script>

<style lang="scss" scoped>
.df-Filter {
  width: 100%;
  position: relative;
}

.df-DatepickerWrapper {
  // make on the place and the same height as select
  margin-top: -44px;
  height: 44px;

  &:deep(.mx-input-wrapper) {
    display: none;
  }

  &:deep(.mx-datepicker-range) {
    height: 100%;
    pointer-events: none;
    width: auto;
  }
}
</style>
