<template>
  <template v-if="isShowCellByAddFor">
    <template v-if="userHasReadAccess">
      <CellSelectItem
        :id="`cdc${uniqId}`"
        :active="opened"
        :is-empty="!displayValue"
        :plug-icon="PLUG_ICONS.DATE"
        :user-has-read-access="userHasReadAccess"
        :user-has-update-access="userHasUpdateAccess"
        is-transparent
        truncated
        @click.stop="onTriggerClick"
      >
        {{ displayValue }}
      </CellSelectItem>

      <AppDroplist
        v-if="editInitialised"
        v-model="opened"
        :format="dateFormat"
        :hide-on-click="false"
        :offset="[0, 0]"
        :to-selector="`#cdc${uniqId}`"
        position="bottom-start"
        theme="no-shadow-next light"
        trigger="manual"
        @close="onClose"
      >
        <div ref="contentReference">
          <DatePicker
            :clearable="false"
            :format="dateFormat"
            :inline="true"
            :lang="datepickerLang()"
            :model-value="innerModelValue"
            :placeholder="$t('datepicker.without_date')"
            @update:model-value="onUpdateModelValue"
          >
            <template #footer="{ emit: emitEvent }">
              <a href="#" @click.prevent="emitEvent(null)"> {{ $t('datepicker.without_date') }} </a>
            </template>
          </DatePicker>
        </div>
      </AppDroplist>
    </template>
    <AppCipheredText v-else style="--padding-left: 6px" />
  </template>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import dayjs from 'dayjs'
import { isNull } from 'lodash'
import { computed, nextTick, ref } from 'vue'
import DatePicker from 'vue2-datepicker'

import { PLUG_ICONS } from '@/utils/cell-helper'
import {
  dateFormat,
  datepickerLang,
  getValidDueOrStartDate,
  localDateToUtc,
  UNSELECTED_DATE
} from '@/utils/date'
import { CUSTOM_FIELD_ENTITY_KEYS } from '@/utils/entity-keys'
import { shouldDisplayFieldByOkrElementTypeId } from '@/utils/memoizations'
import { OKR_DATES_SELECT_DATE_PROPS } from '@/utils/okr-element-dates'
import { uid } from '@/utils/uid'

import AppDroplist from '@/components/AppDroplist'
import CellSelectItem from '@/components/objectives/table/cells/CellSelectItem'
import AppCipheredText from '@/components/ui/AppCipheredText'

defineOptions({
  name: 'DateCell'
})
const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  fieldId: {
    type: [String, Number],
    required: true
  },

  userHasReadAccess: {
    type: Boolean
  },

  userHasUpdateAccess: {
    type: Boolean
  },

  fieldParams: {
    type: Object,
    required: true
  }
})

const uniqId = uid()
const opened = ref(false)
const editInitialised = ref(false)

const displayValue = computed(() => {
  const value = props.item.customFields[props.fieldId]
  if (value && !isNull(value)) {
    return dayjs(value).format(dateFormat)
  }

  return ''
})

const onTriggerClick = async () => {
  if (props.userHasUpdateAccess) {
    editInitialised.value = true
    await nextTick()
    opened.value = true
  }
}

const emit = defineEmits({
  'update-field-value': null
})
const onUpdateModelValue = date => {
  if (props.userHasUpdateAccess) {
    const value = localDateToUtc(
      getValidDueOrStartDate(date, OKR_DATES_SELECT_DATE_PROPS.START_DATE)
    )
    emit('update-field-value', {
      fieldId: props.fieldId,
      value
    })
  }
  onClose()
}

const onClose = () => {
  opened.value = false
}

const contentReference = ref(null)

onClickOutside(contentReference, () => {
  if (opened.value) {
    onClose()
  }
})

const innerModelValue = computed(() => {
  const value = props.item.customFields[props.fieldId]
  if (!isNull(value)) {
    return dayjs(value).toDate()
  }

  return UNSELECTED_DATE
})

const isShowCellByAddFor = computed(() => {
  return shouldDisplayFieldByOkrElementTypeId({
    elementTypeIds: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.ELEMENT_TYPE_IDS],
    okrElementTypeId: props.item.typeId,
    okrElementWorkspaceId: props.item.workspaceId,
    workspaceIds: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.WORKSPACE_IDS],
    active: props.fieldParams[CUSTOM_FIELD_ENTITY_KEYS.ACTIVE]
  })
})
</script>

<style lang="scss" scoped></style>
