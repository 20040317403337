import { isEmpty } from 'lodash'

import ApiHandler from '@/api/base'
import { FAKE_OBJECTIVE_TYPES } from '@/utils/objective-types'
import { OKR_ELEMENT_PERMISSIONS } from '@/utils/objectives'
import { uid } from '@/utils/uid'

const enrichNestedTask = ({ task, elementId, workspaceId }) => {
  const divider = '-'
  const [rootLinkedIssueId] = `${elementId}`.split(divider)
  const id = `${rootLinkedIssueId}${divider}${task.id}`
  return {
    ...task,
    id,
    uniqueId: id,
    uid: uid(),
    displayId: task.jiraIssueId || '',
    workspaceId,
    parentId: elementId,
    fitFilterCriteria: true,
    hiddenByFilter: false,
    isNestedTask: true,
    childElements: []
    // childElements: enrichNestedTasksList({
    //   tasks: task.childElements,
    //   elementId: task.id,
    //   workspaceId
    // })
  }
}

const enrichNestedTasksList = ({ tasks, elementId, workspaceId }) => {
  if (isEmpty(tasks)) {
    return [
      {
        id: uid(),
        typeId: FAKE_OBJECTIVE_TYPES.NO_NESTED_TASKS_FAKE_ROW,
        permissions: [OKR_ELEMENT_PERMISSIONS.READ],
        hiddenByFilter: false,
        fitFilterCriteria: true
      }
    ].map(task => enrichNestedTask({ task, elementId, workspaceId }))
  }
  return tasks.map(task => enrichNestedTask({ task, elementId, workspaceId }))
}

class IssuesApiHandler extends ApiHandler {
  async getIssues({ searchString, taskId }) {
    const { data } = await this.http.post('/getIssueInfo', {
      searchString,
      taskId
    })
    return data
  }

  async getIssuesByJql({ jql } = {}) {
    const { data } = await this.http.post('/getIssuesByJql', {
      jql
    })
    return data
  }

  async getTaskNestedElements({ elementId, workspaceId, parentJiraIssueKey } = {}) {
    const { data } = await this.http.post('/getNestedIssues', {
      parentJiraIssueKey
    })

    return enrichNestedTasksList({ tasks: data, elementId, workspaceId })
  }

  /*  async getTaskNestedElements({ elementId, workspaceId } = {}) {
    console.table('mocked getTaskNestedElements', { elementId, workspaceId })

    const randomCount = random(1, 3)

    const TASK_STATUSES = [
      {
        jiraStatus: 'To Do',
        jiraStatusColor: { text: '#42526e', background: '#dfe1e6' }
      },
      {
        jiraStatus: 'In Progress',
        jiraStatusColor: { text: '#0747a6', background: '#deebff' }
      },
      {
        jiraStatus: 'Done',
        jiraStatusColor: { text: '#006644', background: '#e3fcef' }
      }
    ]

    const users = [
      {
        accountId: '557058:989b8931-c8bf-4350-8b05-2ff823c62976',
        email: 'scred666@gmail.com',
        name: '557058:989b8931-c8bf-4350-8b05-2ff823c62976',
        displayName: 'Yuriy Obyschenko',
        avatarUrl:
          'https://secure.gravatar.com/avatar/98c8da862302040d703af24b9eb0a316?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FYO-2.png',
        elementRoleId: 1
      }
    ]

    const fakeItems = Array.from({ length: randomCount }, (_, index) => {
      this.id += 1
      return {
        id: random(1000000, 9999990) + index,
        displayId: `FAKE-TASK-ID-${index + 1}`,
        workspaceId, // [*]
        name: `FAKE NESTED TASK ${index + 1}`,
        typeId: OBJECTIVE_TYPES.TASK,
        permissions: [OKR_ELEMENT_PERMISSIONS.READ],
        jiraIssueId: `FAKE-NESTED-TASK-${index + 1}`,
        issueType: 'Task',
        isNestedTask: true, // [*]
        parentId: elementId,
        childElements: [],
        fitFilterCriteria: true,
        hiddenByFilter: false,
        ...TASK_STATUSES[index],
        users: index % 2 === 0 ? users : [],
        issueIcon:
          'https://yakovleiv-oboard2.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium'
      }
    }).map(enrichOkrElement)

    const coin = random(0, 1)

    let items = coin ? fakeItems : []

    if (isEmpty(items)) {
      items = [
        {
          id: uid(),
          typeId: FAKE_OBJECTIVE_TYPES.NO_NESTED_TASKS_FAKE_ROW,
          childElements: [],
          parentId: elementId,
          permissions: [OKR_ELEMENT_PERMISSIONS.READ],
          hiddenByFilter: false,
          fitFilterCriteria: true
        }
      ].map(enrichOkrElement)
    }

    logAttentionPhrase(`RESULT IS ${items.length} ITEMS`)

    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ items })
      }, 1000)
    })
  }*/
}

export default IssuesApiHandler
