import { has, isEmpty, random } from 'lodash'

export const GLOBAL_GROUPS_ICON_PREFIX = 'ggi-'
export const GROUPS_CATEGORIZED_COLORS = {
  BLACK: ['#B5BBC5', '#CBCFD6', '#DFE1E6'],
  BLUE: ['#85B2DD', '#A7C4EC', '#BFD4F2'],
  GREEN: ['#86D1B2', '#AFE0CB', '#CDECDF'],
  VIOLET: ['#B69EF3', '#CAC0F5', '#D6D4F6'],
  RED: ['#F8768A', '#FAA4B1', '#FDD1D8'],
  ORANGE: ['#FFA370', '#FCC289', '#FDE1B8']
}

export const GLOBAL_GROUPS_PERMISSIONS = {
  ADD_TO_WORKSPACE: 'ADD_TO_WORKSPACE',
  UPDATE: 'UPDATE',
  ADD_USERS: 'ADD_USERS',
  DELETE: 'DELETE',
  DELETE_USERS: 'DELETE_USERS',
  SYNC: 'SYNC',
  LINK: 'LINK',
  UNLINK: 'UNLINK'
}

export const userCanDeleteGroup = group => {
  if (isEmpty(group)) {
    return false
  }

  return group.permissions.includes(GLOBAL_GROUPS_PERMISSIONS.DELETE)
}

export const userCanUpdateGroup = group => {
  if (isEmpty(group)) {
    return false
  }

  return group.permissions.includes(GLOBAL_GROUPS_PERMISSIONS.UPDATE)
}

export const userCanAddGroupToWorkspace = group => {
  if (isEmpty(group)) {
    return false
  }

  return group.permissions.includes(GLOBAL_GROUPS_PERMISSIONS.ADD_TO_WORKSPACE)
}

export const userCanUnlinkGroups = group => {
  if (isEmpty(group)) {
    return false
  }

  return group.permissions.includes(GLOBAL_GROUPS_PERMISSIONS.UNLINK)
}
export const DEFAULT_LIMIT = 50

export const CHILD_ITEMS_KEY = 'childGroups'

export const GLOBAL_GROUPS_SEARCH_TYPES = {
  PLAIN: 1,
  BOTTOM_UP: 2
}

export const getRandomGroupColor = () => {
  const allColors = Object.values(GROUPS_CATEGORIZED_COLORS).flat()

  return allColors[random(0, allColors.length - 1)]
}

export const getRandomGroupIcon = () => random(1, 32)

export const GLOBAL_GROUPS_SELECT_OPTION_PARAMS = {
  BREADCRUMB_ARROW_SIZE: 24,
  IMMEDIATE_PARENT_MIN_WIDTH: 24
}

export const isGroupUnavailable = group => group.hidden

export const getAvailableGroupIdsDeep = groups => {
  return groups.reduce((acc, group) => {
    if (!isGroupUnavailable(group) && userCanAddGroupToWorkspace(group)) {
      acc.push(group)
    }
    if (has(group, CHILD_ITEMS_KEY) && !isEmpty(group[CHILD_ITEMS_KEY])) {
      acc.push(...getAvailableGroupIdsDeep(group[CHILD_ITEMS_KEY]))
    }
    return acc
  }, [])
}

export const GLOBAL_GROUP_TABLE_OFFSET = '32px'
export const ADD_GROUP_TO_WORKSPACE_TABLE_OFFSET = '40px'

export const isItemHasChildren = ({ item, childItemsKey = 'childItems' } = {}) => {
  return has(item, childItemsKey) && !isEmpty(item[childItemsKey])
}

export const GLOBAL_GROUPS_NAME_CELL_ACTIONS = {
  MANAGE_USERS: 'MANAGE_USERS',
  ADD_SUB_GROUP: 'ADD_SUB_GROUP'
}

export const GLOBAL_GROUPS_NAME_CELL_LABELS = {
  USERS_COUNT: 'USERS_COUNT',
  SUB_GROUPS_COUNT: 'SUB_GROUPS_COUNT'
}

export const NO_PARENT_GROUP_ID = null

export const GLOBAL_GROUP_KEYS = {
  ID: 'id',
  PARENT_ID: 'parentId',
  ICON: 'icon',
  COLOR: 'color',
  NAME: 'name',
  WORKSPACE_IDS: 'workspaceIds',
  WORKSPACES: 'workspaces'
}

export const getUserGroupsByWorkspace = ({ allGroups, workspaceId }) => {
  return (
    allGroups.filter(group => group.workspaceIds.includes(workspaceId)).map(({ id }) => id) || []
  )
}
