const ICON_PREFIX = 'emoji-category-'

export const EMOJI_LIST = [
  {
    category: 'Smileys & Emotion',
    label: '🙂',
    icon: `${ICON_PREFIX}smileys`,
    data: [
      {
        emoji: '😀',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😃',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😄',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😁',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😆',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😅',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤣',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😂',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🙂',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🙃',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😉',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😊',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😇',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🥰',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😍',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤩',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😘',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😗',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '☺️',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😚',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😙',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🥲',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😋',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😛',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😜',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤪',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😝',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤑',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤗',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤭',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤫',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤔',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤐',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤨',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😐',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😑',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😶',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😶‍🌫️',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😏',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😒',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🙄',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😬',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😮‍💨',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤥',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😌',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😔',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😪',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤤',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😴',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😷',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤒',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤕',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤢',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤮',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤧',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🥵',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🥶',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🥴',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😵',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😵‍💫',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤯',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤠',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🥳',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🥸',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😎',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤓',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🧐',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😕',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😟',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🙁',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '☹️',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😮',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😯',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😲',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😳',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🥺',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😦',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😧',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😨',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😰',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😥',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😢',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😭',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😱',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😖',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😣',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😞',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😓',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😩',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😫',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🥱',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😤',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😡',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😠',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤬',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😈',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '👿',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💀',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '☠️',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💩',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤡',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '👹',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '👺',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '👻',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '👽',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '👾',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤖',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😺',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😸',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😹',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😻',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😼',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😽',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🙀',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😿',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '😾',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🙈',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🙉',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🙊',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💋',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💌',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💘',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💝',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💖',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💗',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💓',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💞',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💕',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💟',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '❣️',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💔',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '❤️‍🔥',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '❤️‍🩹',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '❤️',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🧡',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💛',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💚',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💙',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💜',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤎',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🖤',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🤍',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💯',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💢',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💥',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💫',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💦',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💨',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🕳️',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💣',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💬',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '👁️‍🗨️',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🗨️',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '🗯️',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💭',
        category: 'Smileys & Emotion'
      },
      {
        emoji: '💤',
        category: 'Smileys & Emotion'
      }
    ]
  },
  {
    category: 'People & Body',
    label: '✋',
    icon: `${ICON_PREFIX}people`,
    data: [
      {
        emoji: '👋',
        category: 'People & Body'
      },
      {
        emoji: '🤚',
        category: 'People & Body'
      },
      {
        emoji: '🖐️',
        category: 'People & Body'
      },
      {
        emoji: '✋',
        category: 'People & Body'
      },
      {
        emoji: '🖖',
        category: 'People & Body'
      },
      {
        emoji: '👌',
        category: 'People & Body'
      },
      {
        emoji: '🤌',
        category: 'People & Body'
      },
      {
        emoji: '🤏',
        category: 'People & Body'
      },
      {
        emoji: '✌️',
        category: 'People & Body'
      },
      {
        emoji: '🤞',
        category: 'People & Body'
      },
      {
        emoji: '🤟',
        category: 'People & Body'
      },
      {
        emoji: '🤘',
        category: 'People & Body'
      },
      {
        emoji: '🤙',
        category: 'People & Body'
      },
      {
        emoji: '👈',
        category: 'People & Body'
      },
      {
        emoji: '👉',
        category: 'People & Body'
      },
      {
        emoji: '👆',
        category: 'People & Body'
      },
      {
        emoji: '🖕',
        category: 'People & Body'
      },
      {
        emoji: '👇',
        category: 'People & Body'
      },
      {
        emoji: '☝️',
        category: 'People & Body'
      },
      {
        emoji: '👍',
        category: 'People & Body'
      },
      {
        emoji: '👎',
        category: 'People & Body'
      },
      {
        emoji: '✊',
        category: 'People & Body'
      },
      {
        emoji: '👊',
        category: 'People & Body'
      },
      {
        emoji: '🤛',
        category: 'People & Body'
      },
      {
        emoji: '🤜',
        category: 'People & Body'
      },
      {
        emoji: '👏',
        category: 'People & Body'
      },
      {
        emoji: '🙌',
        category: 'People & Body'
      },
      {
        emoji: '👐',
        category: 'People & Body'
      },
      {
        emoji: '🤲',
        category: 'People & Body'
      },
      {
        emoji: '🤝',
        category: 'People & Body'
      },
      {
        emoji: '🙏',
        category: 'People & Body'
      },
      {
        emoji: '✍️',
        category: 'People & Body'
      },
      {
        emoji: '💅',
        category: 'People & Body'
      },
      {
        emoji: '🤳',
        category: 'People & Body'
      },
      {
        emoji: '💪',
        category: 'People & Body'
      },
      {
        emoji: '🦾',
        category: 'People & Body'
      },
      {
        emoji: '🦿',
        category: 'People & Body'
      },
      {
        emoji: '🦵',
        category: 'People & Body'
      },
      {
        emoji: '🦶',
        category: 'People & Body'
      },
      {
        emoji: '👂',
        category: 'People & Body'
      },
      {
        emoji: '🦻',
        category: 'People & Body'
      },
      {
        emoji: '👃',
        category: 'People & Body'
      },
      {
        emoji: '🧠',
        category: 'People & Body'
      },
      {
        emoji: '🫀',
        category: 'People & Body'
      },
      {
        emoji: '🫁',
        category: 'People & Body'
      },
      {
        emoji: '🦷',
        category: 'People & Body'
      },
      {
        emoji: '🦴',
        category: 'People & Body'
      },
      {
        emoji: '👀',
        category: 'People & Body'
      },
      {
        emoji: '👁️',
        category: 'People & Body'
      },
      {
        emoji: '👅',
        category: 'People & Body'
      },
      {
        emoji: '👄',
        category: 'People & Body'
      },
      {
        emoji: '👶',
        category: 'People & Body'
      },
      {
        emoji: '🧒',
        category: 'People & Body'
      },
      {
        emoji: '👦',
        category: 'People & Body'
      },
      {
        emoji: '👧',
        category: 'People & Body'
      },
      {
        emoji: '🧑',
        category: 'People & Body'
      },
      {
        emoji: '👱',
        category: 'People & Body'
      },
      {
        emoji: '👨',
        category: 'People & Body'
      },
      {
        emoji: '🧔',
        category: 'People & Body'
      },
      {
        emoji: '🧔‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧔‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🦰',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🦱',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🦳',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🦲',
        category: 'People & Body'
      },
      {
        emoji: '👩',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🦰',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🦰',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🦱',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🦱',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🦳',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🦳',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🦲',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🦲',
        category: 'People & Body'
      },
      {
        emoji: '👱‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '👱‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧓',
        category: 'People & Body'
      },
      {
        emoji: '👴',
        category: 'People & Body'
      },
      {
        emoji: '👵',
        category: 'People & Body'
      },
      {
        emoji: '🙍',
        category: 'People & Body'
      },
      {
        emoji: '🙍‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🙍‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🙎',
        category: 'People & Body'
      },
      {
        emoji: '🙎‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🙎‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🙅',
        category: 'People & Body'
      },
      {
        emoji: '🙅‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🙅‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🙆',
        category: 'People & Body'
      },
      {
        emoji: '🙆‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🙆‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '💁',
        category: 'People & Body'
      },
      {
        emoji: '💁‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '💁‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🙋',
        category: 'People & Body'
      },
      {
        emoji: '🙋‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🙋‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧏',
        category: 'People & Body'
      },
      {
        emoji: '🧏‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧏‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🙇',
        category: 'People & Body'
      },
      {
        emoji: '🙇‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🙇‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🤦',
        category: 'People & Body'
      },
      {
        emoji: '🤦‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🤦‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🤷',
        category: 'People & Body'
      },
      {
        emoji: '🤷‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🤷‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍⚕️',
        category: 'People & Body'
      },
      {
        emoji: '👨‍⚕️',
        category: 'People & Body'
      },
      {
        emoji: '👩‍⚕️',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🎓',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🎓',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🎓',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🏫',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🏫',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🏫',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍⚖️',
        category: 'People & Body'
      },
      {
        emoji: '👨‍⚖️',
        category: 'People & Body'
      },
      {
        emoji: '👩‍⚖️',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🌾',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🌾',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🌾',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🍳',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🍳',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🍳',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🔧',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🔧',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🔧',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🏭',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🏭',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🏭',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍💼',
        category: 'People & Body'
      },
      {
        emoji: '👨‍💼',
        category: 'People & Body'
      },
      {
        emoji: '👩‍💼',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🔬',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🔬',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🔬',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍💻',
        category: 'People & Body'
      },
      {
        emoji: '👨‍💻',
        category: 'People & Body'
      },
      {
        emoji: '👩‍💻',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🎤',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🎤',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🎤',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🎨',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🎨',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🎨',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍✈️',
        category: 'People & Body'
      },
      {
        emoji: '👨‍✈️',
        category: 'People & Body'
      },
      {
        emoji: '👩‍✈️',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🚀',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🚀',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🚀',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🚒',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🚒',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🚒',
        category: 'People & Body'
      },
      {
        emoji: '👮',
        category: 'People & Body'
      },
      {
        emoji: '👮‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '👮‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🕵️',
        category: 'People & Body'
      },
      {
        emoji: '🕵️‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🕵️‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '💂',
        category: 'People & Body'
      },
      {
        emoji: '💂‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '💂‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🥷',
        category: 'People & Body'
      },
      {
        emoji: '👷',
        category: 'People & Body'
      },
      {
        emoji: '👷‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '👷‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🤴',
        category: 'People & Body'
      },
      {
        emoji: '👸',
        category: 'People & Body'
      },
      {
        emoji: '👳',
        category: 'People & Body'
      },
      {
        emoji: '👳‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '👳‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '👲',
        category: 'People & Body'
      },
      {
        emoji: '🧕',
        category: 'People & Body'
      },
      {
        emoji: '🤵',
        category: 'People & Body'
      },
      {
        emoji: '🤵‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🤵‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '👰',
        category: 'People & Body'
      },
      {
        emoji: '👰‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '👰‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🤰',
        category: 'People & Body'
      },
      {
        emoji: '🤱',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🍼',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🍼',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🍼',
        category: 'People & Body'
      },
      {
        emoji: '👼',
        category: 'People & Body'
      },
      {
        emoji: '🎅',
        category: 'People & Body'
      },
      {
        emoji: '🤶',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🎄',
        category: 'People & Body'
      },
      {
        emoji: '🦸',
        category: 'People & Body'
      },
      {
        emoji: '🦸‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🦸‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🦹',
        category: 'People & Body'
      },
      {
        emoji: '🦹‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🦹‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧙',
        category: 'People & Body'
      },
      {
        emoji: '🧙‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧙‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧚',
        category: 'People & Body'
      },
      {
        emoji: '🧚‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧚‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧛',
        category: 'People & Body'
      },
      {
        emoji: '🧛‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧛‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧜',
        category: 'People & Body'
      },
      {
        emoji: '🧜‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧜‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧝',
        category: 'People & Body'
      },
      {
        emoji: '🧝‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧝‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧞',
        category: 'People & Body'
      },
      {
        emoji: '🧞‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧞‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧟',
        category: 'People & Body'
      },
      {
        emoji: '🧟‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧟‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '💆',
        category: 'People & Body'
      },
      {
        emoji: '💆‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '💆‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '💇',
        category: 'People & Body'
      },
      {
        emoji: '💇‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '💇‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🚶',
        category: 'People & Body'
      },
      {
        emoji: '🚶‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🚶‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧍',
        category: 'People & Body'
      },
      {
        emoji: '🧍‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧍‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧎',
        category: 'People & Body'
      },
      {
        emoji: '🧎‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧎‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🦯',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🦯',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🦯',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🦼',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🦼',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🦼',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🦽',
        category: 'People & Body'
      },
      {
        emoji: '👨‍🦽',
        category: 'People & Body'
      },
      {
        emoji: '👩‍🦽',
        category: 'People & Body'
      },
      {
        emoji: '🏃',
        category: 'People & Body'
      },
      {
        emoji: '🏃‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🏃‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '💃',
        category: 'People & Body'
      },
      {
        emoji: '🕺',
        category: 'People & Body'
      },
      {
        emoji: '🕴️',
        category: 'People & Body'
      },
      {
        emoji: '👯',
        category: 'People & Body'
      },
      {
        emoji: '👯‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '👯‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧖',
        category: 'People & Body'
      },
      {
        emoji: '🧖‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧖‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧗',
        category: 'People & Body'
      },
      {
        emoji: '🧗‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧗‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🤺',
        category: 'People & Body'
      },
      {
        emoji: '🏇',
        category: 'People & Body'
      },
      {
        emoji: '⛷️',
        category: 'People & Body'
      },
      {
        emoji: '🏂',
        category: 'People & Body'
      },
      {
        emoji: '🏌️',
        category: 'People & Body'
      },
      {
        emoji: '🏌️‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🏌️‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🏄',
        category: 'People & Body'
      },
      {
        emoji: '🏄‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🏄‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🚣',
        category: 'People & Body'
      },
      {
        emoji: '🚣‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🚣‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🏊',
        category: 'People & Body'
      },
      {
        emoji: '🏊‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🏊‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '⛹️',
        category: 'People & Body'
      },
      {
        emoji: '⛹️‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '⛹️‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🏋️',
        category: 'People & Body'
      },
      {
        emoji: '🏋️‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🏋️‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🚴',
        category: 'People & Body'
      },
      {
        emoji: '🚴‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🚴‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🚵',
        category: 'People & Body'
      },
      {
        emoji: '🚵‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🚵‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🤸',
        category: 'People & Body'
      },
      {
        emoji: '🤸‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🤸‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🤼',
        category: 'People & Body'
      },
      {
        emoji: '🤼‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🤼‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🤽',
        category: 'People & Body'
      },
      {
        emoji: '🤽‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🤽‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🤾',
        category: 'People & Body'
      },
      {
        emoji: '🤾‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🤾‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🤹',
        category: 'People & Body'
      },
      {
        emoji: '🤹‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🤹‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🧘',
        category: 'People & Body'
      },
      {
        emoji: '🧘‍♂️',
        category: 'People & Body'
      },
      {
        emoji: '🧘‍♀️',
        category: 'People & Body'
      },
      {
        emoji: '🛀',
        category: 'People & Body'
      },
      {
        emoji: '🛌',
        category: 'People & Body'
      },
      {
        emoji: '🧑‍🤝‍🧑',
        category: 'People & Body'
      },
      {
        emoji: '👭',
        category: 'People & Body'
      },
      {
        emoji: '👫',
        category: 'People & Body'
      },
      {
        emoji: '👬',
        category: 'People & Body'
      },
      {
        emoji: '💏',
        category: 'People & Body'
      },
      {
        emoji: '👩‍❤️‍💋‍👨',
        category: 'People & Body'
      },
      {
        emoji: '👨‍❤️‍💋‍👨',
        category: 'People & Body'
      },
      {
        emoji: '👩‍❤️‍💋‍👩',
        category: 'People & Body'
      },
      {
        emoji: '💑',
        category: 'People & Body'
      },
      {
        emoji: '👩‍❤️‍👨',
        category: 'People & Body'
      },
      {
        emoji: '👨‍❤️‍👨',
        category: 'People & Body'
      },
      {
        emoji: '👩‍❤️‍👩',
        category: 'People & Body'
      },
      {
        emoji: '👪',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👩‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👩‍👧',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👩‍👧‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👩‍👦‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👩‍👧‍👧',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👨‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👨‍👧',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👨‍👧‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👨‍👦‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👨‍👧‍👧',
        category: 'People & Body'
      },
      {
        emoji: '👩‍👩‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👩‍👩‍👧',
        category: 'People & Body'
      },
      {
        emoji: '👩‍👩‍👧‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👩‍👩‍👦‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👩‍👩‍👧‍👧',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👦‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👧',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👧‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👨‍👧‍👧',
        category: 'People & Body'
      },
      {
        emoji: '👩‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👩‍👦‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👩‍👧',
        category: 'People & Body'
      },
      {
        emoji: '👩‍👧‍👦',
        category: 'People & Body'
      },
      {
        emoji: '👩‍👧‍👧',
        category: 'People & Body'
      },
      {
        emoji: '🗣️',
        category: 'People & Body'
      },
      {
        emoji: '👤',
        category: 'People & Body'
      },
      {
        emoji: '👥',
        category: 'People & Body'
      },
      {
        emoji: '🫂',
        category: 'People & Body'
      },
      {
        emoji: '👣',
        category: 'People & Body'
      }
    ]
  },
  {
    category: 'Animals & Nature',
    label: '🐶',
    icon: `${ICON_PREFIX}animals`,
    data: [
      {
        emoji: '🐵',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐒',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦍',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦧',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐶',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐕',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦮',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐕‍🦺',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐩',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐺',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦊',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦝',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐱',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐈',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐈‍⬛',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦁',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐯',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐅',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐆',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐴',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐎',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦄',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦓',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦌',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦬',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐮',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐂',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐃',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐄',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐷',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐖',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐗',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐽',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐏',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐑',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐐',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐪',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐫',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦙',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦒',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐘',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦣',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦏',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦛',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐭',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐁',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐀',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐹',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐰',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐇',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐿️',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦫',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦔',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦇',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐻',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐻‍❄️',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐨',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐼',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦥',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦦',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦨',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦘',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦡',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐾',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦃',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐔',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐓',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐣',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐤',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐥',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐦',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐧',
        category: 'Animals & Nature'
      },
      {
        emoji: '🕊️',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦅',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦆',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦢',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦉',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦤',
        category: 'Animals & Nature'
      },
      {
        emoji: '🪶',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦩',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦚',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦜',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐸',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐊',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐢',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦎',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐍',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐲',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐉',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦕',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦖',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐳',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐋',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐬',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦭',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐟',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐠',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐡',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦈',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐙',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐚',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐌',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦋',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐛',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐜',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐝',
        category: 'Animals & Nature'
      },
      {
        emoji: '🪲',
        category: 'Animals & Nature'
      },
      {
        emoji: '🐞',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦗',
        category: 'Animals & Nature'
      },
      {
        emoji: '🪳',
        category: 'Animals & Nature'
      },
      {
        emoji: '🕷️',
        category: 'Animals & Nature'
      },
      {
        emoji: '🕸️',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦂',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦟',
        category: 'Animals & Nature'
      },
      {
        emoji: '🪰',
        category: 'Animals & Nature'
      },
      {
        emoji: '🪱',
        category: 'Animals & Nature'
      },
      {
        emoji: '🦠',
        category: 'Animals & Nature'
      },
      {
        emoji: '💐',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌸',
        category: 'Animals & Nature'
      },
      {
        emoji: '💮',
        category: 'Animals & Nature'
      },
      {
        emoji: '🏵️',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌹',
        category: 'Animals & Nature'
      },
      {
        emoji: '🥀',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌺',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌻',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌼',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌷',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌱',
        category: 'Animals & Nature'
      },
      {
        emoji: '🪴',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌲',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌳',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌴',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌵',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌾',
        category: 'Animals & Nature'
      },
      {
        emoji: '🌿',
        category: 'Animals & Nature'
      },
      {
        emoji: '☘️',
        category: 'Animals & Nature'
      },
      {
        emoji: '🍀',
        category: 'Animals & Nature'
      },
      {
        emoji: '🍁',
        category: 'Animals & Nature'
      },
      {
        emoji: '🍂',
        category: 'Animals & Nature'
      },
      {
        emoji: '🍃',
        category: 'Animals & Nature'
      }
    ]
  },
  {
    category: 'Food & Drink',
    label: '🍉',
    icon: `${ICON_PREFIX}food`,
    data: [
      {
        emoji: '🍇',
        category: 'Food & Drink'
      },
      {
        emoji: '🍈',
        category: 'Food & Drink'
      },
      {
        emoji: '🍉',
        category: 'Food & Drink'
      },
      {
        emoji: '🍊',
        category: 'Food & Drink'
      },
      {
        emoji: '🍋',
        category: 'Food & Drink'
      },
      {
        emoji: '🍌',
        category: 'Food & Drink'
      },
      {
        emoji: '🍍',
        category: 'Food & Drink'
      },
      {
        emoji: '🥭',
        category: 'Food & Drink'
      },
      {
        emoji: '🍎',
        category: 'Food & Drink'
      },
      {
        emoji: '🍏',
        category: 'Food & Drink'
      },
      {
        emoji: '🍐',
        category: 'Food & Drink'
      },
      {
        emoji: '🍑',
        category: 'Food & Drink'
      },
      {
        emoji: '🍒',
        category: 'Food & Drink'
      },
      {
        emoji: '🍓',
        category: 'Food & Drink'
      },
      {
        emoji: '🫐',
        category: 'Food & Drink'
      },
      {
        emoji: '🥝',
        category: 'Food & Drink'
      },
      {
        emoji: '🍅',
        category: 'Food & Drink'
      },
      {
        emoji: '🫒',
        category: 'Food & Drink'
      },
      {
        emoji: '🥥',
        category: 'Food & Drink'
      },
      {
        emoji: '🥑',
        category: 'Food & Drink'
      },
      {
        emoji: '🍆',
        category: 'Food & Drink'
      },
      {
        emoji: '🥔',
        category: 'Food & Drink'
      },
      {
        emoji: '🥕',
        category: 'Food & Drink'
      },
      {
        emoji: '🌽',
        category: 'Food & Drink'
      },
      {
        emoji: '🌶️',
        category: 'Food & Drink'
      },
      {
        emoji: '🫑',
        category: 'Food & Drink'
      },
      {
        emoji: '🥒',
        category: 'Food & Drink'
      },
      {
        emoji: '🥬',
        category: 'Food & Drink'
      },
      {
        emoji: '🥦',
        category: 'Food & Drink'
      },
      {
        emoji: '🧄',
        category: 'Food & Drink'
      },
      {
        emoji: '🧅',
        category: 'Food & Drink'
      },
      {
        emoji: '🍄',
        category: 'Food & Drink'
      },
      {
        emoji: '🥜',
        category: 'Food & Drink'
      },
      {
        emoji: '🌰',
        category: 'Food & Drink'
      },
      {
        emoji: '🍞',
        category: 'Food & Drink'
      },
      {
        emoji: '🥐',
        category: 'Food & Drink'
      },
      {
        emoji: '🥖',
        category: 'Food & Drink'
      },
      {
        emoji: '🫓',
        category: 'Food & Drink'
      },
      {
        emoji: '🥨',
        category: 'Food & Drink'
      },
      {
        emoji: '🥯',
        category: 'Food & Drink'
      },
      {
        emoji: '🥞',
        category: 'Food & Drink'
      },
      {
        emoji: '🧇',
        category: 'Food & Drink'
      },
      {
        emoji: '🧀',
        category: 'Food & Drink'
      },
      {
        emoji: '🍖',
        category: 'Food & Drink'
      },
      {
        emoji: '🍗',
        category: 'Food & Drink'
      },
      {
        emoji: '🥩',
        category: 'Food & Drink'
      },
      {
        emoji: '🥓',
        category: 'Food & Drink'
      },
      {
        emoji: '🍔',
        category: 'Food & Drink'
      },
      {
        emoji: '🍟',
        category: 'Food & Drink'
      },
      {
        emoji: '🍕',
        category: 'Food & Drink'
      },
      {
        emoji: '🌭',
        category: 'Food & Drink'
      },
      {
        emoji: '🥪',
        category: 'Food & Drink'
      },
      {
        emoji: '🌮',
        category: 'Food & Drink'
      },
      {
        emoji: '🌯',
        category: 'Food & Drink'
      },
      {
        emoji: '🫔',
        category: 'Food & Drink'
      },
      {
        emoji: '🥙',
        category: 'Food & Drink'
      },
      {
        emoji: '🧆',
        category: 'Food & Drink'
      },
      {
        emoji: '🥚',
        category: 'Food & Drink'
      },
      {
        emoji: '🍳',
        category: 'Food & Drink'
      },
      {
        emoji: '🥘',
        category: 'Food & Drink'
      },
      {
        emoji: '🍲',
        category: 'Food & Drink'
      },
      {
        emoji: '🫕',
        category: 'Food & Drink'
      },
      {
        emoji: '🥣',
        category: 'Food & Drink'
      },
      {
        emoji: '🥗',
        category: 'Food & Drink'
      },
      {
        emoji: '🍿',
        category: 'Food & Drink'
      },
      {
        emoji: '🧈',
        category: 'Food & Drink'
      },
      {
        emoji: '🧂',
        category: 'Food & Drink'
      },
      {
        emoji: '🥫',
        category: 'Food & Drink'
      },
      {
        emoji: '🍱',
        category: 'Food & Drink'
      },
      {
        emoji: '🍘',
        category: 'Food & Drink'
      },
      {
        emoji: '🍙',
        category: 'Food & Drink'
      },
      {
        emoji: '🍚',
        category: 'Food & Drink'
      },
      {
        emoji: '🍛',
        category: 'Food & Drink'
      },
      {
        emoji: '🍜',
        category: 'Food & Drink'
      },
      {
        emoji: '🍝',
        category: 'Food & Drink'
      },
      {
        emoji: '🍠',
        category: 'Food & Drink'
      },
      {
        emoji: '🍢',
        category: 'Food & Drink'
      },
      {
        emoji: '🍣',
        category: 'Food & Drink'
      },
      {
        emoji: '🍤',
        category: 'Food & Drink'
      },
      {
        emoji: '🍥',
        category: 'Food & Drink'
      },
      {
        emoji: '🥮',
        category: 'Food & Drink'
      },
      {
        emoji: '🍡',
        category: 'Food & Drink'
      },
      {
        emoji: '🥟',
        category: 'Food & Drink'
      },
      {
        emoji: '🥠',
        category: 'Food & Drink'
      },
      {
        emoji: '🥡',
        category: 'Food & Drink'
      },
      {
        emoji: '🦀',
        category: 'Food & Drink'
      },
      {
        emoji: '🦞',
        category: 'Food & Drink'
      },
      {
        emoji: '🦐',
        category: 'Food & Drink'
      },
      {
        emoji: '🦑',
        category: 'Food & Drink'
      },
      {
        emoji: '🦪',
        category: 'Food & Drink'
      },
      {
        emoji: '🍦',
        category: 'Food & Drink'
      },
      {
        emoji: '🍧',
        category: 'Food & Drink'
      },
      {
        emoji: '🍨',
        category: 'Food & Drink'
      },
      {
        emoji: '🍩',
        category: 'Food & Drink'
      },
      {
        emoji: '🍪',
        category: 'Food & Drink'
      },
      {
        emoji: '🎂',
        category: 'Food & Drink'
      },
      {
        emoji: '🍰',
        category: 'Food & Drink'
      },
      {
        emoji: '🧁',
        category: 'Food & Drink'
      },
      {
        emoji: '🥧',
        category: 'Food & Drink'
      },
      {
        emoji: '🍫',
        category: 'Food & Drink'
      },
      {
        emoji: '🍬',
        category: 'Food & Drink'
      },
      {
        emoji: '🍭',
        category: 'Food & Drink'
      },
      {
        emoji: '🍮',
        category: 'Food & Drink'
      },
      {
        emoji: '🍯',
        category: 'Food & Drink'
      },
      {
        emoji: '🍼',
        category: 'Food & Drink'
      },
      {
        emoji: '🥛',
        category: 'Food & Drink'
      },
      {
        emoji: '☕',
        category: 'Food & Drink'
      },
      {
        emoji: '🫖',
        category: 'Food & Drink'
      },
      {
        emoji: '🍵',
        category: 'Food & Drink'
      },
      {
        emoji: '🍶',
        category: 'Food & Drink'
      },
      {
        emoji: '🍾',
        category: 'Food & Drink'
      },
      {
        emoji: '🍷',
        category: 'Food & Drink'
      },
      {
        emoji: '🍸',
        category: 'Food & Drink'
      },
      {
        emoji: '🍹',
        category: 'Food & Drink'
      },
      {
        emoji: '🍺',
        category: 'Food & Drink'
      },
      {
        emoji: '🍻',
        category: 'Food & Drink'
      },
      {
        emoji: '🥂',
        category: 'Food & Drink'
      },
      {
        emoji: '🥃',
        category: 'Food & Drink'
      },
      {
        emoji: '🥤',
        category: 'Food & Drink'
      },
      {
        emoji: '🧋',
        category: 'Food & Drink'
      },
      {
        emoji: '🧃',
        category: 'Food & Drink'
      },
      {
        emoji: '🧉',
        category: 'Food & Drink'
      },
      {
        emoji: '🧊',
        category: 'Food & Drink'
      },
      {
        emoji: '🥢',
        category: 'Food & Drink'
      },
      {
        emoji: '🍽️',
        category: 'Food & Drink'
      },
      {
        emoji: '🍴',
        category: 'Food & Drink'
      },
      {
        emoji: '🥄',
        category: 'Food & Drink'
      },
      {
        emoji: '🔪',
        category: 'Food & Drink'
      },
      {
        emoji: '🏺',
        category: 'Food & Drink'
      }
    ]
  },
  {
    category: 'Travel & Places',
    label: '🌍',
    icon: `${ICON_PREFIX}travel`,
    data: [
      {
        emoji: '🌍',
        category: 'Travel & Places'
      },
      {
        emoji: '🌎',
        category: 'Travel & Places'
      },
      {
        emoji: '🌏',
        category: 'Travel & Places'
      },
      {
        emoji: '🌐',
        category: 'Travel & Places'
      },
      {
        emoji: '🗺️',
        category: 'Travel & Places'
      },
      {
        emoji: '🗾',
        category: 'Travel & Places'
      },
      {
        emoji: '🧭',
        category: 'Travel & Places'
      },
      {
        emoji: '🏔️',
        category: 'Travel & Places'
      },
      {
        emoji: '⛰️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌋',
        category: 'Travel & Places'
      },
      {
        emoji: '🗻',
        category: 'Travel & Places'
      },
      {
        emoji: '🏕️',
        category: 'Travel & Places'
      },
      {
        emoji: '🏖️',
        category: 'Travel & Places'
      },
      {
        emoji: '🏜️',
        category: 'Travel & Places'
      },
      {
        emoji: '🏝️',
        category: 'Travel & Places'
      },
      {
        emoji: '🏞️',
        category: 'Travel & Places'
      },
      {
        emoji: '🏟️',
        category: 'Travel & Places'
      },
      {
        emoji: '🏛️',
        category: 'Travel & Places'
      },
      {
        emoji: '🏗️',
        category: 'Travel & Places'
      },
      {
        emoji: '🧱',
        category: 'Travel & Places'
      },
      {
        emoji: '🪨',
        category: 'Travel & Places'
      },
      {
        emoji: '🪵',
        category: 'Travel & Places'
      },
      {
        emoji: '🛖',
        category: 'Travel & Places'
      },
      {
        emoji: '🏘️',
        category: 'Travel & Places'
      },
      {
        emoji: '🏚️',
        category: 'Travel & Places'
      },
      {
        emoji: '🏠',
        category: 'Travel & Places'
      },
      {
        emoji: '🏡',
        category: 'Travel & Places'
      },
      {
        emoji: '🏢',
        category: 'Travel & Places'
      },
      {
        emoji: '🏣',
        category: 'Travel & Places'
      },
      {
        emoji: '🏤',
        category: 'Travel & Places'
      },
      {
        emoji: '🏥',
        category: 'Travel & Places'
      },
      {
        emoji: '🏦',
        category: 'Travel & Places'
      },
      {
        emoji: '🏨',
        category: 'Travel & Places'
      },
      {
        emoji: '🏩',
        category: 'Travel & Places'
      },
      {
        emoji: '🏪',
        category: 'Travel & Places'
      },
      {
        emoji: '🏫',
        category: 'Travel & Places'
      },
      {
        emoji: '🏬',
        category: 'Travel & Places'
      },
      {
        emoji: '🏭',
        category: 'Travel & Places'
      },
      {
        emoji: '🏯',
        category: 'Travel & Places'
      },
      {
        emoji: '🏰',
        category: 'Travel & Places'
      },
      {
        emoji: '💒',
        category: 'Travel & Places'
      },
      {
        emoji: '🗼',
        category: 'Travel & Places'
      },
      {
        emoji: '🗽',
        category: 'Travel & Places'
      },
      {
        emoji: '⛪',
        category: 'Travel & Places'
      },
      {
        emoji: '🕌',
        category: 'Travel & Places'
      },
      {
        emoji: '🛕',
        category: 'Travel & Places'
      },
      {
        emoji: '🕍',
        category: 'Travel & Places'
      },
      {
        emoji: '⛩️',
        category: 'Travel & Places'
      },
      {
        emoji: '🕋',
        category: 'Travel & Places'
      },
      {
        emoji: '⛲',
        category: 'Travel & Places'
      },
      {
        emoji: '⛺',
        category: 'Travel & Places'
      },
      {
        emoji: '🌁',
        category: 'Travel & Places'
      },
      {
        emoji: '🌃',
        category: 'Travel & Places'
      },
      {
        emoji: '🏙️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌄',
        category: 'Travel & Places'
      },
      {
        emoji: '🌅',
        category: 'Travel & Places'
      },
      {
        emoji: '🌆',
        category: 'Travel & Places'
      },
      {
        emoji: '🌇',
        category: 'Travel & Places'
      },
      {
        emoji: '🌉',
        category: 'Travel & Places'
      },
      {
        emoji: '♨️',
        category: 'Travel & Places'
      },
      {
        emoji: '🎠',
        category: 'Travel & Places'
      },
      {
        emoji: '🎡',
        category: 'Travel & Places'
      },
      {
        emoji: '🎢',
        category: 'Travel & Places'
      },
      {
        emoji: '💈',
        category: 'Travel & Places'
      },
      {
        emoji: '🎪',
        category: 'Travel & Places'
      },
      {
        emoji: '🚂',
        category: 'Travel & Places'
      },
      {
        emoji: '🚃',
        category: 'Travel & Places'
      },
      {
        emoji: '🚄',
        category: 'Travel & Places'
      },
      {
        emoji: '🚅',
        category: 'Travel & Places'
      },
      {
        emoji: '🚆',
        category: 'Travel & Places'
      },
      {
        emoji: '🚇',
        category: 'Travel & Places'
      },
      {
        emoji: '🚈',
        category: 'Travel & Places'
      },
      {
        emoji: '🚉',
        category: 'Travel & Places'
      },
      {
        emoji: '🚊',
        category: 'Travel & Places'
      },
      {
        emoji: '🚝',
        category: 'Travel & Places'
      },
      {
        emoji: '🚞',
        category: 'Travel & Places'
      },
      {
        emoji: '🚋',
        category: 'Travel & Places'
      },
      {
        emoji: '🚌',
        category: 'Travel & Places'
      },
      {
        emoji: '🚍',
        category: 'Travel & Places'
      },
      {
        emoji: '🚎',
        category: 'Travel & Places'
      },
      {
        emoji: '🚐',
        category: 'Travel & Places'
      },
      {
        emoji: '🚑',
        category: 'Travel & Places'
      },
      {
        emoji: '🚒',
        category: 'Travel & Places'
      },
      {
        emoji: '🚓',
        category: 'Travel & Places'
      },
      {
        emoji: '🚔',
        category: 'Travel & Places'
      },
      {
        emoji: '🚕',
        category: 'Travel & Places'
      },
      {
        emoji: '🚖',
        category: 'Travel & Places'
      },
      {
        emoji: '🚗',
        category: 'Travel & Places'
      },
      {
        emoji: '🚘',
        category: 'Travel & Places'
      },
      {
        emoji: '🚙',
        category: 'Travel & Places'
      },
      {
        emoji: '🛻',
        category: 'Travel & Places'
      },
      {
        emoji: '🚚',
        category: 'Travel & Places'
      },
      {
        emoji: '🚛',
        category: 'Travel & Places'
      },
      {
        emoji: '🚜',
        category: 'Travel & Places'
      },
      {
        emoji: '🏎️',
        category: 'Travel & Places'
      },
      {
        emoji: '🏍️',
        category: 'Travel & Places'
      },
      {
        emoji: '🛵',
        category: 'Travel & Places'
      },
      {
        emoji: '🦽',
        category: 'Travel & Places'
      },
      {
        emoji: '🦼',
        category: 'Travel & Places'
      },
      {
        emoji: '🛺',
        category: 'Travel & Places'
      },
      {
        emoji: '🚲',
        category: 'Travel & Places'
      },
      {
        emoji: '🛴',
        category: 'Travel & Places'
      },
      {
        emoji: '🛹',
        category: 'Travel & Places'
      },
      {
        emoji: '🛼',
        category: 'Travel & Places'
      },
      {
        emoji: '🚏',
        category: 'Travel & Places'
      },
      {
        emoji: '🛣️',
        category: 'Travel & Places'
      },
      {
        emoji: '🛤️',
        category: 'Travel & Places'
      },
      {
        emoji: '🛢️',
        category: 'Travel & Places'
      },
      {
        emoji: '⛽',
        category: 'Travel & Places'
      },
      {
        emoji: '🚨',
        category: 'Travel & Places'
      },
      {
        emoji: '🚥',
        category: 'Travel & Places'
      },
      {
        emoji: '🚦',
        category: 'Travel & Places'
      },
      {
        emoji: '🛑',
        category: 'Travel & Places'
      },
      {
        emoji: '🚧',
        category: 'Travel & Places'
      },
      {
        emoji: '⚓',
        category: 'Travel & Places'
      },
      {
        emoji: '⛵',
        category: 'Travel & Places'
      },
      {
        emoji: '🛶',
        category: 'Travel & Places'
      },
      {
        emoji: '🚤',
        category: 'Travel & Places'
      },
      {
        emoji: '🛳️',
        category: 'Travel & Places'
      },
      {
        emoji: '⛴️',
        category: 'Travel & Places'
      },
      {
        emoji: '🛥️',
        category: 'Travel & Places'
      },
      {
        emoji: '🚢',
        category: 'Travel & Places'
      },
      {
        emoji: '✈️',
        category: 'Travel & Places'
      },
      {
        emoji: '🛩️',
        category: 'Travel & Places'
      },
      {
        emoji: '🛫',
        category: 'Travel & Places'
      },
      {
        emoji: '🛬',
        category: 'Travel & Places'
      },
      {
        emoji: '🪂',
        category: 'Travel & Places'
      },
      {
        emoji: '💺',
        category: 'Travel & Places'
      },
      {
        emoji: '🚁',
        category: 'Travel & Places'
      },
      {
        emoji: '🚟',
        category: 'Travel & Places'
      },
      {
        emoji: '🚠',
        category: 'Travel & Places'
      },
      {
        emoji: '🚡',
        category: 'Travel & Places'
      },
      {
        emoji: '🛰️',
        category: 'Travel & Places'
      },
      {
        emoji: '🚀',
        category: 'Travel & Places'
      },
      {
        emoji: '🛸',
        category: 'Travel & Places'
      },
      {
        emoji: '🛎️',
        category: 'Travel & Places'
      },
      {
        emoji: '🧳',
        category: 'Travel & Places'
      },
      {
        emoji: '⌛',
        category: 'Travel & Places'
      },
      {
        emoji: '⏳',
        category: 'Travel & Places'
      },
      {
        emoji: '⌚',
        category: 'Travel & Places'
      },
      {
        emoji: '⏰',
        category: 'Travel & Places'
      },
      {
        emoji: '⏱️',
        category: 'Travel & Places'
      },
      {
        emoji: '⏲️',
        category: 'Travel & Places'
      },
      {
        emoji: '🕰️',
        category: 'Travel & Places'
      },
      {
        emoji: '🕛',
        category: 'Travel & Places'
      },
      {
        emoji: '🕧',
        category: 'Travel & Places'
      },
      {
        emoji: '🕐',
        category: 'Travel & Places'
      },
      {
        emoji: '🕜',
        category: 'Travel & Places'
      },
      {
        emoji: '🕑',
        category: 'Travel & Places'
      },
      {
        emoji: '🕝',
        category: 'Travel & Places'
      },
      {
        emoji: '🕒',
        category: 'Travel & Places'
      },
      {
        emoji: '🕞',
        category: 'Travel & Places'
      },
      {
        emoji: '🕓',
        category: 'Travel & Places'
      },
      {
        emoji: '🕟',
        category: 'Travel & Places'
      },
      {
        emoji: '🕔',
        category: 'Travel & Places'
      },
      {
        emoji: '🕠',
        category: 'Travel & Places'
      },
      {
        emoji: '🕕',
        category: 'Travel & Places'
      },
      {
        emoji: '🕡',
        category: 'Travel & Places'
      },
      {
        emoji: '🕖',
        category: 'Travel & Places'
      },
      {
        emoji: '🕢',
        category: 'Travel & Places'
      },
      {
        emoji: '🕗',
        category: 'Travel & Places'
      },
      {
        emoji: '🕣',
        category: 'Travel & Places'
      },
      {
        emoji: '🕘',
        category: 'Travel & Places'
      },
      {
        emoji: '🕤',
        category: 'Travel & Places'
      },
      {
        emoji: '🕙',
        category: 'Travel & Places'
      },
      {
        emoji: '🕥',
        category: 'Travel & Places'
      },
      {
        emoji: '🕚',
        category: 'Travel & Places'
      },
      {
        emoji: '🕦',
        category: 'Travel & Places'
      },
      {
        emoji: '🌑',
        category: 'Travel & Places'
      },
      {
        emoji: '🌒',
        category: 'Travel & Places'
      },
      {
        emoji: '🌓',
        category: 'Travel & Places'
      },
      {
        emoji: '🌔',
        category: 'Travel & Places'
      },
      {
        emoji: '🌕',
        category: 'Travel & Places'
      },
      {
        emoji: '🌖',
        category: 'Travel & Places'
      },
      {
        emoji: '🌗',
        category: 'Travel & Places'
      },
      {
        emoji: '🌘',
        category: 'Travel & Places'
      },
      {
        emoji: '🌙',
        category: 'Travel & Places'
      },
      {
        emoji: '🌚',
        category: 'Travel & Places'
      },
      {
        emoji: '🌛',
        category: 'Travel & Places'
      },
      {
        emoji: '🌜',
        category: 'Travel & Places'
      },
      {
        emoji: '🌡️',
        category: 'Travel & Places'
      },
      {
        emoji: '☀️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌝',
        category: 'Travel & Places'
      },
      {
        emoji: '🌞',
        category: 'Travel & Places'
      },
      {
        emoji: '🪐',
        category: 'Travel & Places'
      },
      {
        emoji: '⭐',
        category: 'Travel & Places'
      },
      {
        emoji: '🌟',
        category: 'Travel & Places'
      },
      {
        emoji: '🌠',
        category: 'Travel & Places'
      },
      {
        emoji: '🌌',
        category: 'Travel & Places'
      },
      {
        emoji: '☁️',
        category: 'Travel & Places'
      },
      {
        emoji: '⛅',
        category: 'Travel & Places'
      },
      {
        emoji: '⛈️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌤️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌥️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌦️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌧️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌨️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌩️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌪️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌫️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌬️',
        category: 'Travel & Places'
      },
      {
        emoji: '🌀',
        category: 'Travel & Places'
      },
      {
        emoji: '🌈',
        category: 'Travel & Places'
      },
      {
        emoji: '🌂',
        category: 'Travel & Places'
      },
      {
        emoji: '☂️',
        category: 'Travel & Places'
      },
      {
        emoji: '☔',
        category: 'Travel & Places'
      },
      {
        emoji: '⛱️',
        category: 'Travel & Places'
      },
      {
        emoji: '⚡',
        category: 'Travel & Places'
      },
      {
        emoji: '❄️',
        category: 'Travel & Places'
      },
      {
        emoji: '☃️',
        category: 'Travel & Places'
      },
      {
        emoji: '⛄',
        category: 'Travel & Places'
      },
      {
        emoji: '☄️',
        category: 'Travel & Places'
      },
      {
        emoji: '🔥',
        category: 'Travel & Places'
      },
      {
        emoji: '💧',
        category: 'Travel & Places'
      },
      {
        emoji: '🌊',
        category: 'Travel & Places'
      }
    ]
  },
  {
    category: 'Activities',
    label: '🎇',
    icon: `${ICON_PREFIX}activity`,
    data: [
      {
        emoji: '🎃',
        category: 'Activities'
      },
      {
        emoji: '🎄',
        category: 'Activities'
      },
      {
        emoji: '🎆',
        category: 'Activities'
      },
      {
        emoji: '🎇',
        category: 'Activities'
      },
      {
        emoji: '🧨',
        category: 'Activities'
      },
      {
        emoji: '✨',
        category: 'Activities'
      },
      {
        emoji: '🎈',
        category: 'Activities'
      },
      {
        emoji: '🎉',
        category: 'Activities'
      },
      {
        emoji: '🎊',
        category: 'Activities'
      },
      {
        emoji: '🎋',
        category: 'Activities'
      },
      {
        emoji: '🎍',
        category: 'Activities'
      },
      {
        emoji: '🎎',
        category: 'Activities'
      },
      {
        emoji: '🎏',
        category: 'Activities'
      },
      {
        emoji: '🎐',
        category: 'Activities'
      },
      {
        emoji: '🎑',
        category: 'Activities'
      },
      {
        emoji: '🧧',
        category: 'Activities'
      },
      {
        emoji: '🎀',
        category: 'Activities'
      },
      {
        emoji: '🎁',
        category: 'Activities'
      },
      {
        emoji: '🎗️',
        category: 'Activities'
      },
      {
        emoji: '🎟️',
        category: 'Activities'
      },
      {
        emoji: '🎫',
        category: 'Activities'
      },
      {
        emoji: '🎖️',
        category: 'Activities'
      },
      {
        emoji: '🏆',
        category: 'Activities'
      },
      {
        emoji: '🏅',
        category: 'Activities'
      },
      {
        emoji: '🥇',
        category: 'Activities'
      },
      {
        emoji: '🥈',
        category: 'Activities'
      },
      {
        emoji: '🥉',
        category: 'Activities'
      },
      {
        emoji: '⚽',
        category: 'Activities'
      },
      {
        emoji: '⚾',
        category: 'Activities'
      },
      {
        emoji: '🥎',
        category: 'Activities'
      },
      {
        emoji: '🏀',
        category: 'Activities'
      },
      {
        emoji: '🏐',
        category: 'Activities'
      },
      {
        emoji: '🏈',
        category: 'Activities'
      },
      {
        emoji: '🏉',
        category: 'Activities'
      },
      {
        emoji: '🎾',
        category: 'Activities'
      },
      {
        emoji: '🥏',
        category: 'Activities'
      },
      {
        emoji: '🎳',
        category: 'Activities'
      },
      {
        emoji: '🏏',
        category: 'Activities'
      },
      {
        emoji: '🏑',
        category: 'Activities'
      },
      {
        emoji: '🏒',
        category: 'Activities'
      },
      {
        emoji: '🥍',
        category: 'Activities'
      },
      {
        emoji: '🏓',
        category: 'Activities'
      },
      {
        emoji: '🏸',
        category: 'Activities'
      },
      {
        emoji: '🥊',
        category: 'Activities'
      },
      {
        emoji: '🥋',
        category: 'Activities'
      },
      {
        emoji: '🥅',
        category: 'Activities'
      },
      {
        emoji: '⛳',
        category: 'Activities'
      },
      {
        emoji: '⛸️',
        category: 'Activities'
      },
      {
        emoji: '🎣',
        category: 'Activities'
      },
      {
        emoji: '🤿',
        category: 'Activities'
      },
      {
        emoji: '🎽',
        category: 'Activities'
      },
      {
        emoji: '🎿',
        category: 'Activities'
      },
      {
        emoji: '🛷',
        category: 'Activities'
      },
      {
        emoji: '🥌',
        category: 'Activities'
      },
      {
        emoji: '🎯',
        category: 'Activities'
      },
      {
        emoji: '🪀',
        category: 'Activities'
      },
      {
        emoji: '🪁',
        category: 'Activities'
      },
      {
        emoji: '🎱',
        category: 'Activities'
      },
      {
        emoji: '🔮',
        category: 'Activities'
      },
      {
        emoji: '🪄',
        category: 'Activities'
      },
      {
        emoji: '🧿',
        category: 'Activities'
      },
      {
        emoji: '🎮',
        category: 'Activities'
      },
      {
        emoji: '🕹️',
        category: 'Activities'
      },
      {
        emoji: '🎰',
        category: 'Activities'
      },
      {
        emoji: '🎲',
        category: 'Activities'
      },
      {
        emoji: '🧩',
        category: 'Activities'
      },
      {
        emoji: '🧸',
        category: 'Activities'
      },
      {
        emoji: '🪅',
        category: 'Activities'
      },
      {
        emoji: '🪆',
        category: 'Activities'
      },
      {
        emoji: '♠️',
        category: 'Activities'
      },
      {
        emoji: '♥️',
        category: 'Activities'
      },
      {
        emoji: '♦️',
        category: 'Activities'
      },
      {
        emoji: '♣️',
        category: 'Activities'
      },
      {
        emoji: '♟️',
        category: 'Activities'
      },
      {
        emoji: '🃏',
        category: 'Activities'
      },
      {
        emoji: '🀄',
        category: 'Activities'
      },
      {
        emoji: '🎴',
        category: 'Activities'
      },
      {
        emoji: '🎭',
        category: 'Activities'
      },
      {
        emoji: '🖼️',
        category: 'Activities'
      },
      {
        emoji: '🎨',
        category: 'Activities'
      },
      {
        emoji: '🧵',
        category: 'Activities'
      },
      {
        emoji: '🪡',
        category: 'Activities'
      },
      {
        emoji: '🧶',
        category: 'Activities'
      },
      {
        emoji: '🪢',
        category: 'Activities'
      }
    ]
  },
  {
    category: 'Objects',
    label: '👔',
    icon: `${ICON_PREFIX}objects`,
    data: [
      {
        emoji: '👓',
        category: 'Objects'
      },
      {
        emoji: '🕶️',
        category: 'Objects'
      },
      {
        emoji: '🥽',
        category: 'Objects'
      },
      {
        emoji: '🥼',
        category: 'Objects'
      },
      {
        emoji: '🦺',
        category: 'Objects'
      },
      {
        emoji: '👔',
        category: 'Objects'
      },
      {
        emoji: '👕',
        category: 'Objects'
      },
      {
        emoji: '👖',
        category: 'Objects'
      },
      {
        emoji: '🧣',
        category: 'Objects'
      },
      {
        emoji: '🧤',
        category: 'Objects'
      },
      {
        emoji: '🧥',
        category: 'Objects'
      },
      {
        emoji: '🧦',
        category: 'Objects'
      },
      {
        emoji: '👗',
        category: 'Objects'
      },
      {
        emoji: '👘',
        category: 'Objects'
      },
      {
        emoji: '🥻',
        category: 'Objects'
      },
      {
        emoji: '🩱',
        category: 'Objects'
      },
      {
        emoji: '🩲',
        category: 'Objects'
      },
      {
        emoji: '🩳',
        category: 'Objects'
      },
      {
        emoji: '👙',
        category: 'Objects'
      },
      {
        emoji: '👚',
        category: 'Objects'
      },
      {
        emoji: '👛',
        category: 'Objects'
      },
      {
        emoji: '👜',
        category: 'Objects'
      },
      {
        emoji: '👝',
        category: 'Objects'
      },
      {
        emoji: '🛍️',
        category: 'Objects'
      },
      {
        emoji: '🎒',
        category: 'Objects'
      },
      {
        emoji: '🩴',
        category: 'Objects'
      },
      {
        emoji: '👞',
        category: 'Objects'
      },
      {
        emoji: '👟',
        category: 'Objects'
      },
      {
        emoji: '🥾',
        category: 'Objects'
      },
      {
        emoji: '🥿',
        category: 'Objects'
      },
      {
        emoji: '👠',
        category: 'Objects'
      },
      {
        emoji: '👡',
        category: 'Objects'
      },
      {
        emoji: '🩰',
        category: 'Objects'
      },
      {
        emoji: '👢',
        category: 'Objects'
      },
      {
        emoji: '👑',
        category: 'Objects'
      },
      {
        emoji: '👒',
        category: 'Objects'
      },
      {
        emoji: '🎩',
        category: 'Objects'
      },
      {
        emoji: '🎓',
        category: 'Objects'
      },
      {
        emoji: '🧢',
        category: 'Objects'
      },
      {
        emoji: '🪖',
        category: 'Objects'
      },
      {
        emoji: '⛑️',
        category: 'Objects'
      },
      {
        emoji: '📿',
        category: 'Objects'
      },
      {
        emoji: '💄',
        category: 'Objects'
      },
      {
        emoji: '💍',
        category: 'Objects'
      },
      {
        emoji: '💎',
        category: 'Objects'
      },
      {
        emoji: '🔇',
        category: 'Objects'
      },
      {
        emoji: '🔈',
        category: 'Objects'
      },
      {
        emoji: '🔉',
        category: 'Objects'
      },
      {
        emoji: '🔊',
        category: 'Objects'
      },
      {
        emoji: '📢',
        category: 'Objects'
      },
      {
        emoji: '📣',
        category: 'Objects'
      },
      {
        emoji: '📯',
        category: 'Objects'
      },
      {
        emoji: '🔔',
        category: 'Objects'
      },
      {
        emoji: '🔕',
        category: 'Objects'
      },
      {
        emoji: '🎼',
        category: 'Objects'
      },
      {
        emoji: '🎵',
        category: 'Objects'
      },
      {
        emoji: '🎶',
        category: 'Objects'
      },
      {
        emoji: '🎙️',
        category: 'Objects'
      },
      {
        emoji: '🎚️',
        category: 'Objects'
      },
      {
        emoji: '🎛️',
        category: 'Objects'
      },
      {
        emoji: '🎤',
        category: 'Objects'
      },
      {
        emoji: '🎧',
        category: 'Objects'
      },
      {
        emoji: '📻',
        category: 'Objects'
      },
      {
        emoji: '🎷',
        category: 'Objects'
      },
      {
        emoji: '🪗',
        category: 'Objects'
      },
      {
        emoji: '🎸',
        category: 'Objects'
      },
      {
        emoji: '🎹',
        category: 'Objects'
      },
      {
        emoji: '🎺',
        category: 'Objects'
      },
      {
        emoji: '🎻',
        category: 'Objects'
      },
      {
        emoji: '🪕',
        category: 'Objects'
      },
      {
        emoji: '🥁',
        category: 'Objects'
      },
      {
        emoji: '🪘',
        category: 'Objects'
      },
      {
        emoji: '📱',
        category: 'Objects'
      },
      {
        emoji: '📲',
        category: 'Objects'
      },
      {
        emoji: '☎️',
        category: 'Objects'
      },
      {
        emoji: '📞',
        category: 'Objects'
      },
      {
        emoji: '📟',
        category: 'Objects'
      },
      {
        emoji: '📠',
        category: 'Objects'
      },
      {
        emoji: '🔋',
        category: 'Objects'
      },
      {
        emoji: '🔌',
        category: 'Objects'
      },
      {
        emoji: '💻',
        category: 'Objects'
      },
      {
        emoji: '🖥️',
        category: 'Objects'
      },
      {
        emoji: '🖨️',
        category: 'Objects'
      },
      {
        emoji: '⌨️',
        category: 'Objects'
      },
      {
        emoji: '🖱️',
        category: 'Objects'
      },
      {
        emoji: '🖲️',
        category: 'Objects'
      },
      {
        emoji: '💽',
        category: 'Objects'
      },
      {
        emoji: '💾',
        category: 'Objects'
      },
      {
        emoji: '💿',
        category: 'Objects'
      },
      {
        emoji: '📀',
        category: 'Objects'
      },
      {
        emoji: '🧮',
        category: 'Objects'
      },
      {
        emoji: '🎥',
        category: 'Objects'
      },
      {
        emoji: '🎞️',
        category: 'Objects'
      },
      {
        emoji: '📽️',
        category: 'Objects'
      },
      {
        emoji: '🎬',
        category: 'Objects'
      },
      {
        emoji: '📺',
        category: 'Objects'
      },
      {
        emoji: '📷',
        category: 'Objects'
      },
      {
        emoji: '📸',
        category: 'Objects'
      },
      {
        emoji: '📹',
        category: 'Objects'
      },
      {
        emoji: '📼',
        category: 'Objects'
      },
      {
        emoji: '🔍',
        category: 'Objects'
      },
      {
        emoji: '🔎',
        category: 'Objects'
      },
      {
        emoji: '🕯️',
        category: 'Objects'
      },
      {
        emoji: '💡',
        category: 'Objects'
      },
      {
        emoji: '🔦',
        category: 'Objects'
      },
      {
        emoji: '🏮',
        category: 'Objects'
      },
      {
        emoji: '🪔',
        category: 'Objects'
      },
      {
        emoji: '📔',
        category: 'Objects'
      },
      {
        emoji: '📕',
        category: 'Objects'
      },
      {
        emoji: '📖',
        category: 'Objects'
      },
      {
        emoji: '📗',
        category: 'Objects'
      },
      {
        emoji: '📘',
        category: 'Objects'
      },
      {
        emoji: '📙',
        category: 'Objects'
      },
      {
        emoji: '📚',
        category: 'Objects'
      },
      {
        emoji: '📓',
        category: 'Objects'
      },
      {
        emoji: '📒',
        category: 'Objects'
      },
      {
        emoji: '📃',
        category: 'Objects'
      },
      {
        emoji: '📜',
        category: 'Objects'
      },
      {
        emoji: '📄',
        category: 'Objects'
      },
      {
        emoji: '📰',
        category: 'Objects'
      },
      {
        emoji: '🗞️',
        category: 'Objects'
      },
      {
        emoji: '📑',
        category: 'Objects'
      },
      {
        emoji: '🔖',
        category: 'Objects'
      },
      {
        emoji: '🏷️',
        category: 'Objects'
      },
      {
        emoji: '💰',
        category: 'Objects'
      },
      {
        emoji: '🪙',
        category: 'Objects'
      },
      {
        emoji: '💴',
        category: 'Objects'
      },
      {
        emoji: '💵',
        category: 'Objects'
      },
      {
        emoji: '💶',
        category: 'Objects'
      },
      {
        emoji: '💷',
        category: 'Objects'
      },
      {
        emoji: '💸',
        category: 'Objects'
      },
      {
        emoji: '💳',
        category: 'Objects'
      },
      {
        emoji: '🧾',
        category: 'Objects'
      },
      {
        emoji: '💹',
        category: 'Objects'
      },
      {
        emoji: '✉️',
        category: 'Objects'
      },
      {
        emoji: '📧',
        category: 'Objects'
      },
      {
        emoji: '📨',
        category: 'Objects'
      },
      {
        emoji: '📩',
        category: 'Objects'
      },
      {
        emoji: '📤',
        category: 'Objects'
      },
      {
        emoji: '📥',
        category: 'Objects'
      },
      {
        emoji: '📦',
        category: 'Objects'
      },
      {
        emoji: '📫',
        category: 'Objects'
      },
      {
        emoji: '📪',
        category: 'Objects'
      },
      {
        emoji: '📬',
        category: 'Objects'
      },
      {
        emoji: '📭',
        category: 'Objects'
      },
      {
        emoji: '📮',
        category: 'Objects'
      },
      {
        emoji: '🗳️',
        category: 'Objects'
      },
      {
        emoji: '✏️',
        category: 'Objects'
      },
      {
        emoji: '✒️',
        category: 'Objects'
      },
      {
        emoji: '🖋️',
        category: 'Objects'
      },
      {
        emoji: '🖊️',
        category: 'Objects'
      },
      {
        emoji: '🖌️',
        category: 'Objects'
      },
      {
        emoji: '🖍️',
        category: 'Objects'
      },
      {
        emoji: '📝',
        category: 'Objects'
      },
      {
        emoji: '💼',
        category: 'Objects'
      },
      {
        emoji: '📁',
        category: 'Objects'
      },
      {
        emoji: '📂',
        category: 'Objects'
      },
      {
        emoji: '🗂️',
        category: 'Objects'
      },
      {
        emoji: '📅',
        category: 'Objects'
      },
      {
        emoji: '📆',
        category: 'Objects'
      },
      {
        emoji: '🗒️',
        category: 'Objects'
      },
      {
        emoji: '🗓️',
        category: 'Objects'
      },
      {
        emoji: '📇',
        category: 'Objects'
      },
      {
        emoji: '📈',
        category: 'Objects'
      },
      {
        emoji: '📉',
        category: 'Objects'
      },
      {
        emoji: '📊',
        category: 'Objects'
      },
      {
        emoji: '📋',
        category: 'Objects'
      },
      {
        emoji: '📌',
        category: 'Objects'
      },
      {
        emoji: '📍',
        category: 'Objects'
      },
      {
        emoji: '📎',
        category: 'Objects'
      },
      {
        emoji: '🖇️',
        category: 'Objects'
      },
      {
        emoji: '📏',
        category: 'Objects'
      },
      {
        emoji: '📐',
        category: 'Objects'
      },
      {
        emoji: '✂️',
        category: 'Objects'
      },
      {
        emoji: '🗃️',
        category: 'Objects'
      },
      {
        emoji: '🗄️',
        category: 'Objects'
      },
      {
        emoji: '🗑️',
        category: 'Objects'
      },
      {
        emoji: '🔒',
        category: 'Objects'
      },
      {
        emoji: '🔓',
        category: 'Objects'
      },
      {
        emoji: '🔏',
        category: 'Objects'
      },
      {
        emoji: '🔐',
        category: 'Objects'
      },
      {
        emoji: '🔑',
        category: 'Objects'
      },
      {
        emoji: '🗝️',
        category: 'Objects'
      },
      {
        emoji: '🔨',
        category: 'Objects'
      },
      {
        emoji: '🪓',
        category: 'Objects'
      },
      {
        emoji: '⛏️',
        category: 'Objects'
      },
      {
        emoji: '⚒️',
        category: 'Objects'
      },
      {
        emoji: '🛠️',
        category: 'Objects'
      },
      {
        emoji: '🗡️',
        category: 'Objects'
      },
      {
        emoji: '⚔️',
        category: 'Objects'
      },
      {
        emoji: '🔫',
        category: 'Objects'
      },
      {
        emoji: '🪃',
        category: 'Objects'
      },
      {
        emoji: '🏹',
        category: 'Objects'
      },
      {
        emoji: '🛡️',
        category: 'Objects'
      },
      {
        emoji: '🪚',
        category: 'Objects'
      },
      {
        emoji: '🔧',
        category: 'Objects'
      },
      {
        emoji: '🪛',
        category: 'Objects'
      },
      {
        emoji: '🔩',
        category: 'Objects'
      },
      {
        emoji: '⚙️',
        category: 'Objects'
      },
      {
        emoji: '🗜️',
        category: 'Objects'
      },
      {
        emoji: '⚖️',
        category: 'Objects'
      },
      {
        emoji: '🦯',
        category: 'Objects'
      },
      {
        emoji: '🔗',
        category: 'Objects'
      },
      {
        emoji: '⛓️',
        category: 'Objects'
      },
      {
        emoji: '🪝',
        category: 'Objects'
      },
      {
        emoji: '🧰',
        category: 'Objects'
      },
      {
        emoji: '🧲',
        category: 'Objects'
      },
      {
        emoji: '🪜',
        category: 'Objects'
      },
      {
        emoji: '⚗️',
        category: 'Objects'
      },
      {
        emoji: '🧪',
        category: 'Objects'
      },
      {
        emoji: '🧫',
        category: 'Objects'
      },
      {
        emoji: '🧬',
        category: 'Objects'
      },
      {
        emoji: '🔬',
        category: 'Objects'
      },
      {
        emoji: '🔭',
        category: 'Objects'
      },
      {
        emoji: '📡',
        category: 'Objects'
      },
      {
        emoji: '💉',
        category: 'Objects'
      },
      {
        emoji: '🩸',
        category: 'Objects'
      },
      {
        emoji: '💊',
        category: 'Objects'
      },
      {
        emoji: '🩹',
        category: 'Objects'
      },
      {
        emoji: '🩺',
        category: 'Objects'
      },
      {
        emoji: '🚪',
        category: 'Objects'
      },
      {
        emoji: '🛗',
        category: 'Objects'
      },
      {
        emoji: '🪞',
        category: 'Objects'
      },
      {
        emoji: '🪟',
        category: 'Objects'
      },
      {
        emoji: '🛏️',
        category: 'Objects'
      },
      {
        emoji: '🛋️',
        category: 'Objects'
      },
      {
        emoji: '🪑',
        category: 'Objects'
      },
      {
        emoji: '🚽',
        category: 'Objects'
      },
      {
        emoji: '🪠',
        category: 'Objects'
      },
      {
        emoji: '🚿',
        category: 'Objects'
      },
      {
        emoji: '🛁',
        category: 'Objects'
      },
      {
        emoji: '🪤',
        category: 'Objects'
      },
      {
        emoji: '🪒',
        category: 'Objects'
      },
      {
        emoji: '🧴',
        category: 'Objects'
      },
      {
        emoji: '🧷',
        category: 'Objects'
      },
      {
        emoji: '🧹',
        category: 'Objects'
      },
      {
        emoji: '🧺',
        category: 'Objects'
      },
      {
        emoji: '🧻',
        category: 'Objects'
      },
      {
        emoji: '🪣',
        category: 'Objects'
      },
      {
        emoji: '🧼',
        category: 'Objects'
      },
      {
        emoji: '🪥',
        category: 'Objects'
      },
      {
        emoji: '🧽',
        category: 'Objects'
      },
      {
        emoji: '🧯',
        category: 'Objects'
      },
      {
        emoji: '🛒',
        category: 'Objects'
      },
      {
        emoji: '🚬',
        category: 'Objects'
      },
      {
        emoji: '⚰️',
        category: 'Objects'
      },
      {
        emoji: '🪦',
        category: 'Objects'
      },
      {
        emoji: '⚱️',
        category: 'Objects'
      },
      {
        emoji: '🗿',
        category: 'Objects'
      },
      {
        emoji: '🪧',
        category: 'Objects'
      }
    ]
  },
  {
    category: 'Symbols',
    label: '⚠️',
    icon: `${ICON_PREFIX}symbol`,
    data: [
      {
        emoji: '🏧',
        category: 'Symbols'
      },
      {
        emoji: '🚮',
        category: 'Symbols'
      },
      {
        emoji: '🚰',
        category: 'Symbols'
      },
      {
        emoji: '♿',
        category: 'Symbols'
      },
      {
        emoji: '🚹',
        category: 'Symbols'
      },
      {
        emoji: '🚺',
        category: 'Symbols'
      },
      {
        emoji: '🚻',
        category: 'Symbols'
      },
      {
        emoji: '🚼',
        category: 'Symbols'
      },
      {
        emoji: '🚾',
        category: 'Symbols'
      },
      {
        emoji: '🛂',
        category: 'Symbols'
      },
      {
        emoji: '🛃',
        category: 'Symbols'
      },
      {
        emoji: '🛄',
        category: 'Symbols'
      },
      {
        emoji: '🛅',
        category: 'Symbols'
      },
      {
        emoji: '⚠️',
        category: 'Symbols'
      },
      {
        emoji: '🚸',
        category: 'Symbols'
      },
      {
        emoji: '⛔',
        category: 'Symbols'
      },
      {
        emoji: '🚫',
        category: 'Symbols'
      },
      {
        emoji: '🚳',
        category: 'Symbols'
      },
      {
        emoji: '🚭',
        category: 'Symbols'
      },
      {
        emoji: '🚯',
        category: 'Symbols'
      },
      {
        emoji: '🚱',
        category: 'Symbols'
      },
      {
        emoji: '🚷',
        category: 'Symbols'
      },
      {
        emoji: '📵',
        category: 'Symbols'
      },
      {
        emoji: '🔞',
        category: 'Symbols'
      },
      {
        emoji: '☢️',
        category: 'Symbols'
      },
      {
        emoji: '☣️',
        category: 'Symbols'
      },
      {
        emoji: '⬆️',
        category: 'Symbols'
      },
      {
        emoji: '↗️',
        category: 'Symbols'
      },
      {
        emoji: '➡️',
        category: 'Symbols'
      },
      {
        emoji: '↘️',
        category: 'Symbols'
      },
      {
        emoji: '⬇️',
        category: 'Symbols'
      },
      {
        emoji: '↙️',
        category: 'Symbols'
      },
      {
        emoji: '⬅️',
        category: 'Symbols'
      },
      {
        emoji: '↖️',
        category: 'Symbols'
      },
      {
        emoji: '↕️',
        category: 'Symbols'
      },
      {
        emoji: '↔️',
        category: 'Symbols'
      },
      {
        emoji: '↩️',
        category: 'Symbols'
      },
      {
        emoji: '↪️',
        category: 'Symbols'
      },
      {
        emoji: '⤴️',
        category: 'Symbols'
      },
      {
        emoji: '⤵️',
        category: 'Symbols'
      },
      {
        emoji: '🔃',
        category: 'Symbols'
      },
      {
        emoji: '🔄',
        category: 'Symbols'
      },
      {
        emoji: '🔙',
        category: 'Symbols'
      },
      {
        emoji: '🔚',
        category: 'Symbols'
      },
      {
        emoji: '🔛',
        category: 'Symbols'
      },
      {
        emoji: '🔜',
        category: 'Symbols'
      },
      {
        emoji: '🔝',
        category: 'Symbols'
      },
      {
        emoji: '🛐',
        category: 'Symbols'
      },
      {
        emoji: '⚛️',
        category: 'Symbols'
      },
      {
        emoji: '🕉️',
        category: 'Symbols'
      },
      {
        emoji: '✡️',
        category: 'Symbols'
      },
      {
        emoji: '☸️',
        category: 'Symbols'
      },
      {
        emoji: '☯️',
        category: 'Symbols'
      },
      {
        emoji: '✝️',
        category: 'Symbols'
      },
      {
        emoji: '☦️',
        category: 'Symbols'
      },
      {
        emoji: '☪️',
        category: 'Symbols'
      },
      {
        emoji: '☮️',
        category: 'Symbols'
      },
      {
        emoji: '🕎',
        category: 'Symbols'
      },
      {
        emoji: '🔯',
        category: 'Symbols'
      },
      {
        emoji: '♈',
        category: 'Symbols'
      },
      {
        emoji: '♉',
        category: 'Symbols'
      },
      {
        emoji: '♊',
        category: 'Symbols'
      },
      {
        emoji: '♋',
        category: 'Symbols'
      },
      {
        emoji: '♌',
        category: 'Symbols'
      },
      {
        emoji: '♍',
        category: 'Symbols'
      },
      {
        emoji: '♎',
        category: 'Symbols'
      },
      {
        emoji: '♏',
        category: 'Symbols'
      },
      {
        emoji: '♐',
        category: 'Symbols'
      },
      {
        emoji: '♑',
        category: 'Symbols'
      },
      {
        emoji: '♒',
        category: 'Symbols'
      },
      {
        emoji: '♓',
        category: 'Symbols'
      },
      {
        emoji: '⛎',
        category: 'Symbols'
      },
      {
        emoji: '🔀',
        category: 'Symbols'
      },
      {
        emoji: '🔁',
        category: 'Symbols'
      },
      {
        emoji: '🔂',
        category: 'Symbols'
      },
      {
        emoji: '▶️',
        category: 'Symbols'
      },
      {
        emoji: '⏩',
        category: 'Symbols'
      },
      {
        emoji: '⏭️',
        category: 'Symbols'
      },
      {
        emoji: '⏯️',
        category: 'Symbols'
      },
      {
        emoji: '◀️',
        category: 'Symbols'
      },
      {
        emoji: '⏪',
        category: 'Symbols'
      },
      {
        emoji: '⏮️',
        category: 'Symbols'
      },
      {
        emoji: '🔼',
        category: 'Symbols'
      },
      {
        emoji: '⏫',
        category: 'Symbols'
      },
      {
        emoji: '🔽',
        category: 'Symbols'
      },
      {
        emoji: '⏬',
        category: 'Symbols'
      },
      {
        emoji: '⏸️',
        category: 'Symbols'
      },
      {
        emoji: '⏹️',
        category: 'Symbols'
      },
      {
        emoji: '⏺️',
        category: 'Symbols'
      },
      {
        emoji: '⏏️',
        category: 'Symbols'
      },
      {
        emoji: '🎦',
        category: 'Symbols'
      },
      {
        emoji: '🔅',
        category: 'Symbols'
      },
      {
        emoji: '🔆',
        category: 'Symbols'
      },
      {
        emoji: '📶',
        category: 'Symbols'
      },
      {
        emoji: '📳',
        category: 'Symbols'
      },
      {
        emoji: '📴',
        category: 'Symbols'
      },
      {
        emoji: '♀️',
        category: 'Symbols'
      },
      {
        emoji: '♂️',
        category: 'Symbols'
      },
      {
        emoji: '⚧️',
        category: 'Symbols'
      },
      {
        emoji: '✖️',
        category: 'Symbols'
      },
      {
        emoji: '➕',
        category: 'Symbols'
      },
      {
        emoji: '➖',
        category: 'Symbols'
      },
      {
        emoji: '➗',
        category: 'Symbols'
      },
      {
        emoji: '♾️',
        category: 'Symbols'
      },
      {
        emoji: '‼️',
        category: 'Symbols'
      },
      {
        emoji: '⁉️',
        category: 'Symbols'
      },
      {
        emoji: '❓',
        category: 'Symbols'
      },
      {
        emoji: '❔',
        category: 'Symbols'
      },
      {
        emoji: '❕',
        category: 'Symbols'
      },
      {
        emoji: '❗',
        category: 'Symbols'
      },
      {
        emoji: '〰️',
        category: 'Symbols'
      },
      {
        emoji: '💱',
        category: 'Symbols'
      },
      {
        emoji: '💲',
        category: 'Symbols'
      },
      {
        emoji: '⚕️',
        category: 'Symbols'
      },
      {
        emoji: '♻️',
        category: 'Symbols'
      },
      {
        emoji: '⚜️',
        category: 'Symbols'
      },
      {
        emoji: '🔱',
        category: 'Symbols'
      },
      {
        emoji: '📛',
        category: 'Symbols'
      },
      {
        emoji: '🔰',
        category: 'Symbols'
      },
      {
        emoji: '⭕',
        category: 'Symbols'
      },
      {
        emoji: '✅',
        category: 'Symbols'
      },
      {
        emoji: '☑️',
        category: 'Symbols'
      },
      {
        emoji: '✔️',
        category: 'Symbols'
      },
      {
        emoji: '❌',
        category: 'Symbols'
      },
      {
        emoji: '❎',
        category: 'Symbols'
      },
      {
        emoji: '➰',
        category: 'Symbols'
      },
      {
        emoji: '➿',
        category: 'Symbols'
      },
      {
        emoji: '〽️',
        category: 'Symbols'
      },
      {
        emoji: '✳️',
        category: 'Symbols'
      },
      {
        emoji: '✴️',
        category: 'Symbols'
      },
      {
        emoji: '❇️',
        category: 'Symbols'
      },
      {
        emoji: '©️',
        category: 'Symbols'
      },
      {
        emoji: '®️',
        category: 'Symbols'
      },
      {
        emoji: '™️',
        category: 'Symbols'
      },
      {
        emoji: '#️⃣',
        category: 'Symbols'
      },
      {
        emoji: '*️⃣',
        category: 'Symbols'
      },
      {
        emoji: '0️⃣',
        category: 'Symbols'
      },
      {
        emoji: '1️⃣',
        category: 'Symbols'
      },
      {
        emoji: '2️⃣',
        category: 'Symbols'
      },
      {
        emoji: '3️⃣',
        category: 'Symbols'
      },
      {
        emoji: '4️⃣',
        category: 'Symbols'
      },
      {
        emoji: '5️⃣',
        category: 'Symbols'
      },
      {
        emoji: '6️⃣',
        category: 'Symbols'
      },
      {
        emoji: '7️⃣',
        category: 'Symbols'
      },
      {
        emoji: '8️⃣',
        category: 'Symbols'
      },
      {
        emoji: '9️⃣',
        category: 'Symbols'
      },
      {
        emoji: '🔟',
        category: 'Symbols'
      },
      {
        emoji: '🔠',
        category: 'Symbols'
      },
      {
        emoji: '🔡',
        category: 'Symbols'
      },
      {
        emoji: '🔢',
        category: 'Symbols'
      },
      {
        emoji: '🔣',
        category: 'Symbols'
      },
      {
        emoji: '🔤',
        category: 'Symbols'
      },
      {
        emoji: '🅰️',
        category: 'Symbols'
      },
      {
        emoji: '🆎',
        category: 'Symbols'
      },
      {
        emoji: '🅱️',
        category: 'Symbols'
      },
      {
        emoji: '🆑',
        category: 'Symbols'
      },
      {
        emoji: '🆒',
        category: 'Symbols'
      },
      {
        emoji: '🆓',
        category: 'Symbols'
      },
      {
        emoji: 'ℹ️',
        category: 'Symbols'
      },
      {
        emoji: '🆔',
        category: 'Symbols'
      },
      {
        emoji: 'Ⓜ️',
        category: 'Symbols'
      },
      {
        emoji: '🆕',
        category: 'Symbols'
      },
      {
        emoji: '🆖',
        category: 'Symbols'
      },
      {
        emoji: '🅾️',
        category: 'Symbols'
      },
      {
        emoji: '🆗',
        category: 'Symbols'
      },
      {
        emoji: '🅿️',
        category: 'Symbols'
      },
      {
        emoji: '🆘',
        category: 'Symbols'
      },
      {
        emoji: '🆙',
        category: 'Symbols'
      },
      {
        emoji: '🆚',
        category: 'Symbols'
      },
      {
        emoji: '🈁',
        category: 'Symbols'
      },
      {
        emoji: '🈂️',
        category: 'Symbols'
      },
      {
        emoji: '🈷️',
        category: 'Symbols'
      },
      {
        emoji: '🈶',
        category: 'Symbols'
      },
      {
        emoji: '🈯',
        category: 'Symbols'
      },
      {
        emoji: '🉐',
        category: 'Symbols'
      },
      {
        emoji: '🈹',
        category: 'Symbols'
      },
      {
        emoji: '🈚',
        category: 'Symbols'
      },
      {
        emoji: '🈲',
        category: 'Symbols'
      },
      {
        emoji: '🉑',
        category: 'Symbols'
      },
      {
        emoji: '🈸',
        category: 'Symbols'
      },
      {
        emoji: '🈴',
        category: 'Symbols'
      },
      {
        emoji: '🈳',
        category: 'Symbols'
      },
      {
        emoji: '㊗️',
        category: 'Symbols'
      },
      {
        emoji: '㊙️',
        category: 'Symbols'
      },
      {
        emoji: '🈺',
        category: 'Symbols'
      },
      {
        emoji: '🈵',
        category: 'Symbols'
      },
      {
        emoji: '🔴',
        category: 'Symbols'
      },
      {
        emoji: '🟠',
        category: 'Symbols'
      },
      {
        emoji: '🟡',
        category: 'Symbols'
      },
      {
        emoji: '🟢',
        category: 'Symbols'
      },
      {
        emoji: '🔵',
        category: 'Symbols'
      },
      {
        emoji: '🟣',
        category: 'Symbols'
      },
      {
        emoji: '🟤',
        category: 'Symbols'
      },
      {
        emoji: '⚫',
        category: 'Symbols'
      },
      {
        emoji: '⚪',
        category: 'Symbols'
      },
      {
        emoji: '🟥',
        category: 'Symbols'
      },
      {
        emoji: '🟧',
        category: 'Symbols'
      },
      {
        emoji: '🟨',
        category: 'Symbols'
      },
      {
        emoji: '🟩',
        category: 'Symbols'
      },
      {
        emoji: '🟦',
        category: 'Symbols'
      },
      {
        emoji: '🟪',
        category: 'Symbols'
      },
      {
        emoji: '🟫',
        category: 'Symbols'
      },
      {
        emoji: '⬛',
        category: 'Symbols'
      },
      {
        emoji: '⬜',
        category: 'Symbols'
      },
      {
        emoji: '◼️',
        category: 'Symbols'
      },
      {
        emoji: '◻️',
        category: 'Symbols'
      },
      {
        emoji: '◾',
        category: 'Symbols'
      },
      {
        emoji: '◽',
        category: 'Symbols'
      },
      {
        emoji: '▪️',
        category: 'Symbols'
      },
      {
        emoji: '▫️',
        category: 'Symbols'
      },
      {
        emoji: '🔶',
        category: 'Symbols'
      },
      {
        emoji: '🔷',
        category: 'Symbols'
      },
      {
        emoji: '🔸',
        category: 'Symbols'
      },
      {
        emoji: '🔹',
        category: 'Symbols'
      },
      {
        emoji: '🔺',
        category: 'Symbols'
      },
      {
        emoji: '🔻',
        category: 'Symbols'
      },
      {
        emoji: '💠',
        category: 'Symbols'
      },
      {
        emoji: '🔘',
        category: 'Symbols'
      },
      {
        emoji: '🔳',
        category: 'Symbols'
      },
      {
        emoji: '🔲',
        category: 'Symbols'
      }
    ]
  },
  {
    category: 'Flags',
    label: '🚩',
    icon: `${ICON_PREFIX}flag`,
    data: [
      {
        emoji: '🏁',
        category: 'Flags'
      },
      {
        emoji: '🚩',
        category: 'Flags'
      },
      {
        emoji: '🎌',
        category: 'Flags'
      },
      {
        emoji: '🏴',
        category: 'Flags'
      },
      {
        emoji: '🏳️',
        category: 'Flags'
      },
      {
        emoji: '🏳️‍🌈',
        category: 'Flags'
      },
      {
        emoji: '🏳️‍⚧️',
        category: 'Flags'
      },
      {
        emoji: '🏴‍☠️',
        category: 'Flags'
      },
      {
        emoji: '🇦🇨',
        category: 'Flags'
      },
      {
        emoji: '🇦🇩',
        category: 'Flags'
      },
      {
        emoji: '🇦🇪',
        category: 'Flags'
      },
      {
        emoji: '🇦🇫',
        category: 'Flags'
      },
      {
        emoji: '🇦🇬',
        category: 'Flags'
      },
      {
        emoji: '🇦🇮',
        category: 'Flags'
      },
      {
        emoji: '🇦🇱',
        category: 'Flags'
      },
      {
        emoji: '🇦🇲',
        category: 'Flags'
      },
      {
        emoji: '🇦🇴',
        category: 'Flags'
      },
      {
        emoji: '🇦🇶',
        category: 'Flags'
      },
      {
        emoji: '🇦🇷',
        category: 'Flags'
      },
      {
        emoji: '🇦🇸',
        category: 'Flags'
      },
      {
        emoji: '🇦🇹',
        category: 'Flags'
      },
      {
        emoji: '🇦🇺',
        category: 'Flags'
      },
      {
        emoji: '🇦🇼',
        category: 'Flags'
      },
      {
        emoji: '🇦🇽',
        category: 'Flags'
      },
      {
        emoji: '🇦🇿',
        category: 'Flags'
      },
      {
        emoji: '🇧🇦',
        category: 'Flags'
      },
      {
        emoji: '🇧🇧',
        category: 'Flags'
      },
      {
        emoji: '🇧🇩',
        category: 'Flags'
      },
      {
        emoji: '🇧🇪',
        category: 'Flags'
      },
      {
        emoji: '🇧🇫',
        category: 'Flags'
      },
      {
        emoji: '🇧🇬',
        category: 'Flags'
      },
      {
        emoji: '🇧🇭',
        category: 'Flags'
      },
      {
        emoji: '🇧🇮',
        category: 'Flags'
      },
      {
        emoji: '🇧🇯',
        category: 'Flags'
      },
      {
        emoji: '🇧🇱',
        category: 'Flags'
      },
      {
        emoji: '🇧🇲',
        category: 'Flags'
      },
      {
        emoji: '🇧🇳',
        category: 'Flags'
      },
      {
        emoji: '🇧🇴',
        category: 'Flags'
      },
      {
        emoji: '🇧🇶',
        category: 'Flags'
      },
      {
        emoji: '🇧🇷',
        category: 'Flags'
      },
      {
        emoji: '🇧🇸',
        category: 'Flags'
      },
      {
        emoji: '🇧🇹',
        category: 'Flags'
      },
      {
        emoji: '🇧🇻',
        category: 'Flags'
      },
      {
        emoji: '🇧🇼',
        category: 'Flags'
      },
      {
        emoji: '🇧🇾',
        category: 'Flags'
      },
      {
        emoji: '🇧🇿',
        category: 'Flags'
      },
      {
        emoji: '🇨🇦',
        category: 'Flags'
      },
      {
        emoji: '🇨🇨',
        category: 'Flags'
      },
      {
        emoji: '🇨🇩',
        category: 'Flags'
      },
      {
        emoji: '🇨🇫',
        category: 'Flags'
      },
      {
        emoji: '🇨🇬',
        category: 'Flags'
      },
      {
        emoji: '🇨🇭',
        category: 'Flags'
      },
      {
        emoji: '🇨🇮',
        category: 'Flags'
      },
      {
        emoji: '🇨🇰',
        category: 'Flags'
      },
      {
        emoji: '🇨🇱',
        category: 'Flags'
      },
      {
        emoji: '🇨🇲',
        category: 'Flags'
      },
      {
        emoji: '🇨🇳',
        category: 'Flags'
      },
      {
        emoji: '🇨🇴',
        category: 'Flags'
      },
      {
        emoji: '🇨🇵',
        category: 'Flags'
      },
      {
        emoji: '🇨🇷',
        category: 'Flags'
      },
      {
        emoji: '🇨🇺',
        category: 'Flags'
      },
      {
        emoji: '🇨🇻',
        category: 'Flags'
      },
      {
        emoji: '🇨🇼',
        category: 'Flags'
      },
      {
        emoji: '🇨🇽',
        category: 'Flags'
      },
      {
        emoji: '🇨🇾',
        category: 'Flags'
      },
      {
        emoji: '🇨🇿',
        category: 'Flags'
      },
      {
        emoji: '🇩🇪',
        category: 'Flags'
      },
      {
        emoji: '🇩🇬',
        category: 'Flags'
      },
      {
        emoji: '🇩🇯',
        category: 'Flags'
      },
      {
        emoji: '🇩🇰',
        category: 'Flags'
      },
      {
        emoji: '🇩🇲',
        category: 'Flags'
      },
      {
        emoji: '🇩🇴',
        category: 'Flags'
      },
      {
        emoji: '🇩🇿',
        category: 'Flags'
      },
      {
        emoji: '🇪🇦',
        category: 'Flags'
      },
      {
        emoji: '🇪🇨',
        category: 'Flags'
      },
      {
        emoji: '🇪🇪',
        category: 'Flags'
      },
      {
        emoji: '🇪🇬',
        category: 'Flags'
      },
      {
        emoji: '🇪🇭',
        category: 'Flags'
      },
      {
        emoji: '🇪🇷',
        category: 'Flags'
      },
      {
        emoji: '🇪🇸',
        category: 'Flags'
      },
      {
        emoji: '🇪🇹',
        category: 'Flags'
      },
      {
        emoji: '🇪🇺',
        category: 'Flags'
      },
      {
        emoji: '🇫🇮',
        category: 'Flags'
      },
      {
        emoji: '🇫🇯',
        category: 'Flags'
      },
      {
        emoji: '🇫🇰',
        category: 'Flags'
      },
      {
        emoji: '🇫🇲',
        category: 'Flags'
      },
      {
        emoji: '🇫🇴',
        category: 'Flags'
      },
      {
        emoji: '🇫🇷',
        category: 'Flags'
      },
      {
        emoji: '🇬🇦',
        category: 'Flags'
      },
      {
        emoji: '🇬🇧',
        category: 'Flags'
      },
      {
        emoji: '🇬🇩',
        category: 'Flags'
      },
      {
        emoji: '🇬🇪',
        category: 'Flags'
      },
      {
        emoji: '🇬🇫',
        category: 'Flags'
      },
      {
        emoji: '🇬🇬',
        category: 'Flags'
      },
      {
        emoji: '🇬🇭',
        category: 'Flags'
      },
      {
        emoji: '🇬🇮',
        category: 'Flags'
      },
      {
        emoji: '🇬🇱',
        category: 'Flags'
      },
      {
        emoji: '🇬🇲',
        category: 'Flags'
      },
      {
        emoji: '🇬🇳',
        category: 'Flags'
      },
      {
        emoji: '🇬🇵',
        category: 'Flags'
      },
      {
        emoji: '🇬🇶',
        category: 'Flags'
      },
      {
        emoji: '🇬🇷',
        category: 'Flags'
      },
      {
        emoji: '🇬🇸',
        category: 'Flags'
      },
      {
        emoji: '🇬🇹',
        category: 'Flags'
      },
      {
        emoji: '🇬🇺',
        category: 'Flags'
      },
      {
        emoji: '🇬🇼',
        category: 'Flags'
      },
      {
        emoji: '🇬🇾',
        category: 'Flags'
      },
      {
        emoji: '🇭🇰',
        category: 'Flags'
      },
      {
        emoji: '🇭🇲',
        category: 'Flags'
      },
      {
        emoji: '🇭🇳',
        category: 'Flags'
      },
      {
        emoji: '🇭🇷',
        category: 'Flags'
      },
      {
        emoji: '🇭🇹',
        category: 'Flags'
      },
      {
        emoji: '🇭🇺',
        category: 'Flags'
      },
      {
        emoji: '🇮🇨',
        category: 'Flags'
      },
      {
        emoji: '🇮🇩',
        category: 'Flags'
      },
      {
        emoji: '🇮🇪',
        category: 'Flags'
      },
      {
        emoji: '🇮🇱',
        category: 'Flags'
      },
      {
        emoji: '🇮🇲',
        category: 'Flags'
      },
      {
        emoji: '🇮🇳',
        category: 'Flags'
      },
      {
        emoji: '🇮🇴',
        category: 'Flags'
      },
      {
        emoji: '🇮🇶',
        category: 'Flags'
      },
      {
        emoji: '🇮🇷',
        category: 'Flags'
      },
      {
        emoji: '🇮🇸',
        category: 'Flags'
      },
      {
        emoji: '🇮🇹',
        category: 'Flags'
      },
      {
        emoji: '🇯🇪',
        category: 'Flags'
      },
      {
        emoji: '🇯🇲',
        category: 'Flags'
      },
      {
        emoji: '🇯🇴',
        category: 'Flags'
      },
      {
        emoji: '🇯🇵',
        category: 'Flags'
      },
      {
        emoji: '🇰🇪',
        category: 'Flags'
      },
      {
        emoji: '🇰🇬',
        category: 'Flags'
      },
      {
        emoji: '🇰🇭',
        category: 'Flags'
      },
      {
        emoji: '🇰🇮',
        category: 'Flags'
      },
      {
        emoji: '🇰🇲',
        category: 'Flags'
      },
      {
        emoji: '🇰🇳',
        category: 'Flags'
      },
      {
        emoji: '🇰🇵',
        category: 'Flags'
      },
      {
        emoji: '🇰🇷',
        category: 'Flags'
      },
      {
        emoji: '🇰🇼',
        category: 'Flags'
      },
      {
        emoji: '🇰🇾',
        category: 'Flags'
      },
      {
        emoji: '🇰🇿',
        category: 'Flags'
      },
      {
        emoji: '🇱🇦',
        category: 'Flags'
      },
      {
        emoji: '🇱🇧',
        category: 'Flags'
      },
      {
        emoji: '🇱🇨',
        category: 'Flags'
      },
      {
        emoji: '🇱🇮',
        category: 'Flags'
      },
      {
        emoji: '🇱🇰',
        category: 'Flags'
      },
      {
        emoji: '🇱🇷',
        category: 'Flags'
      },
      {
        emoji: '🇱🇸',
        category: 'Flags'
      },
      {
        emoji: '🇱🇹',
        category: 'Flags'
      },
      {
        emoji: '🇱🇺',
        category: 'Flags'
      },
      {
        emoji: '🇱🇻',
        category: 'Flags'
      },
      {
        emoji: '🇱🇾',
        category: 'Flags'
      },
      {
        emoji: '🇲🇦',
        category: 'Flags'
      },
      {
        emoji: '🇲🇨',
        category: 'Flags'
      },
      {
        emoji: '🇲🇩',
        category: 'Flags'
      },
      {
        emoji: '🇲🇪',
        category: 'Flags'
      },
      {
        emoji: '🇲🇫',
        category: 'Flags'
      },
      {
        emoji: '🇲🇬',
        category: 'Flags'
      },
      {
        emoji: '🇲🇭',
        category: 'Flags'
      },
      {
        emoji: '🇲🇰',
        category: 'Flags'
      },
      {
        emoji: '🇲🇱',
        category: 'Flags'
      },
      {
        emoji: '🇲🇲',
        category: 'Flags'
      },
      {
        emoji: '🇲🇳',
        category: 'Flags'
      },
      {
        emoji: '🇲🇴',
        category: 'Flags'
      },
      {
        emoji: '🇲🇵',
        category: 'Flags'
      },
      {
        emoji: '🇲🇶',
        category: 'Flags'
      },
      {
        emoji: '🇲🇷',
        category: 'Flags'
      },
      {
        emoji: '🇲🇸',
        category: 'Flags'
      },
      {
        emoji: '🇲🇹',
        category: 'Flags'
      },
      {
        emoji: '🇲🇺',
        category: 'Flags'
      },
      {
        emoji: '🇲🇻',
        category: 'Flags'
      },
      {
        emoji: '🇲🇼',
        category: 'Flags'
      },
      {
        emoji: '🇲🇽',
        category: 'Flags'
      },
      {
        emoji: '🇲🇾',
        category: 'Flags'
      },
      {
        emoji: '🇲🇿',
        category: 'Flags'
      },
      {
        emoji: '🇳🇦',
        category: 'Flags'
      },
      {
        emoji: '🇳🇨',
        category: 'Flags'
      },
      {
        emoji: '🇳🇪',
        category: 'Flags'
      },
      {
        emoji: '🇳🇫',
        category: 'Flags'
      },
      {
        emoji: '🇳🇬',
        category: 'Flags'
      },
      {
        emoji: '🇳🇮',
        category: 'Flags'
      },
      {
        emoji: '🇳🇱',
        category: 'Flags'
      },
      {
        emoji: '🇳🇴',
        category: 'Flags'
      },
      {
        emoji: '🇳🇵',
        category: 'Flags'
      },
      {
        emoji: '🇳🇷',
        category: 'Flags'
      },
      {
        emoji: '🇳🇺',
        category: 'Flags'
      },
      {
        emoji: '🇳🇿',
        category: 'Flags'
      },
      {
        emoji: '🇴🇲',
        category: 'Flags'
      },
      {
        emoji: '🇵🇦',
        category: 'Flags'
      },
      {
        emoji: '🇵🇪',
        category: 'Flags'
      },
      {
        emoji: '🇵🇫',
        category: 'Flags'
      },
      {
        emoji: '🇵🇬',
        category: 'Flags'
      },
      {
        emoji: '🇵🇭',
        category: 'Flags'
      },
      {
        emoji: '🇵🇰',
        category: 'Flags'
      },
      {
        emoji: '🇵🇱',
        category: 'Flags'
      },
      {
        emoji: '🇵🇲',
        category: 'Flags'
      },
      {
        emoji: '🇵🇳',
        category: 'Flags'
      },
      {
        emoji: '🇵🇷',
        category: 'Flags'
      },
      {
        emoji: '🇵🇸',
        category: 'Flags'
      },
      {
        emoji: '🇵🇹',
        category: 'Flags'
      },
      {
        emoji: '🇵🇼',
        category: 'Flags'
      },
      {
        emoji: '🇵🇾',
        category: 'Flags'
      },
      {
        emoji: '🇶🇦',
        category: 'Flags'
      },
      {
        emoji: '🇷🇪',
        category: 'Flags'
      },
      {
        emoji: '🇷🇴',
        category: 'Flags'
      },
      {
        emoji: '🇷🇸',
        category: 'Flags'
      },
      {
        emoji: '🇷🇼',
        category: 'Flags'
      },
      {
        emoji: '🇸🇦',
        category: 'Flags'
      },
      {
        emoji: '🇸🇧',
        category: 'Flags'
      },
      {
        emoji: '🇸🇨',
        category: 'Flags'
      },
      {
        emoji: '🇸🇩',
        category: 'Flags'
      },
      {
        emoji: '🇸🇪',
        category: 'Flags'
      },
      {
        emoji: '🇸🇬',
        category: 'Flags'
      },
      {
        emoji: '🇸🇭',
        category: 'Flags'
      },
      {
        emoji: '🇸🇮',
        category: 'Flags'
      },
      {
        emoji: '🇸🇯',
        category: 'Flags'
      },
      {
        emoji: '🇸🇰',
        category: 'Flags'
      },
      {
        emoji: '🇸🇱',
        category: 'Flags'
      },
      {
        emoji: '🇸🇲',
        category: 'Flags'
      },
      {
        emoji: '🇸🇳',
        category: 'Flags'
      },
      {
        emoji: '🇸🇴',
        category: 'Flags'
      },
      {
        emoji: '🇸🇷',
        category: 'Flags'
      },
      {
        emoji: '🇸🇸',
        category: 'Flags'
      },
      {
        emoji: '🇸🇹',
        category: 'Flags'
      },
      {
        emoji: '🇸🇻',
        category: 'Flags'
      },
      {
        emoji: '🇸🇽',
        category: 'Flags'
      },
      {
        emoji: '🇸🇾',
        category: 'Flags'
      },
      {
        emoji: '🇸🇿',
        category: 'Flags'
      },
      {
        emoji: '🇹🇦',
        category: 'Flags'
      },
      {
        emoji: '🇹🇨',
        category: 'Flags'
      },
      {
        emoji: '🇹🇩',
        category: 'Flags'
      },
      {
        emoji: '🇹🇫',
        category: 'Flags'
      },
      {
        emoji: '🇹🇬',
        category: 'Flags'
      },
      {
        emoji: '🇹🇭',
        category: 'Flags'
      },
      {
        emoji: '🇹🇯',
        category: 'Flags'
      },
      {
        emoji: '🇹🇰',
        category: 'Flags'
      },
      {
        emoji: '🇹🇱',
        category: 'Flags'
      },
      {
        emoji: '🇹🇲',
        category: 'Flags'
      },
      {
        emoji: '🇹🇳',
        category: 'Flags'
      },
      {
        emoji: '🇹🇴',
        category: 'Flags'
      },
      {
        emoji: '🇹🇷',
        category: 'Flags'
      },
      {
        emoji: '🇹🇹',
        category: 'Flags'
      },
      {
        emoji: '🇹🇻',
        category: 'Flags'
      },
      {
        emoji: '🇹🇼',
        category: 'Flags'
      },
      {
        emoji: '🇹🇿',
        category: 'Flags'
      },
      {
        emoji: '🇺🇦',
        category: 'Flags'
      },
      {
        emoji: '🇺🇬',
        category: 'Flags'
      },
      {
        emoji: '🇺🇲',
        category: 'Flags'
      },
      {
        emoji: '🇺🇳',
        category: 'Flags'
      },
      {
        emoji: '🇺🇸',
        category: 'Flags'
      },
      {
        emoji: '🇺🇾',
        category: 'Flags'
      },
      {
        emoji: '🇺🇿',
        category: 'Flags'
      },
      {
        emoji: '🇻🇦',
        category: 'Flags'
      },
      {
        emoji: '🇻🇨',
        category: 'Flags'
      },
      {
        emoji: '🇻🇪',
        category: 'Flags'
      },
      {
        emoji: '🇻🇬',
        category: 'Flags'
      },
      {
        emoji: '🇻🇮',
        category: 'Flags'
      },
      {
        emoji: '🇻🇳',
        category: 'Flags'
      },
      {
        emoji: '🇻🇺',
        category: 'Flags'
      },
      {
        emoji: '🇼🇫',
        category: 'Flags'
      },
      {
        emoji: '🇼🇸',
        category: 'Flags'
      },
      {
        emoji: '🇽🇰',
        category: 'Flags'
      },
      {
        emoji: '🇾🇪',
        category: 'Flags'
      },
      {
        emoji: '🇾🇹',
        category: 'Flags'
      },
      {
        emoji: '🇿🇦',
        category: 'Flags'
      },
      {
        emoji: '🇿🇲',
        category: 'Flags'
      },
      {
        emoji: '🇿🇼',
        category: 'Flags'
      },
      {
        emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        category: 'Flags'
      },
      {
        emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        category: 'Flags'
      },
      {
        emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        category: 'Flags'
      }
    ]
  }
]
