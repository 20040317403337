<template>
  <div class="bp-BillingInformationPage">
    <PageContentHeader
      :level="3"
      :title="$t('Promotions')"
      class="bl-PageContentHeader"
      max-width="full-next"
      no-margin
      style="--padding-top: 29px"
    />
    <div class="bp-Wrapper">
      <div class="bp-Box">
        <AppTitle :level="1" class="bs-Title" disable-margin>
          {{ $t('Get 3 months of Professional plan for $1') }}
        </AppTitle>
        <span class="bs-Subtitle">
          {{ $t('Limited time only, unlimited seats, cancel anytime.') }}
        </span>
        <AppButton
          :loading="loading"
          class="bs-Button"
          size="lg-next"
          type="success"
          @click="getCheckoutEarlyBirdCheckoutSession"
        >
          {{ $t('GET 3 MONTHS FOR $1') }}
        </AppButton>
        <span class="bs-Description">
          {{ $t('Monthly subscription') }}
          <a :href="OKR_PRICING" class="bs-Link" target="_blank">{{ $t('fee') }}</a>
          {{ $t('applies after. Offer ends 30 July 2024.') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import BillingApiHandler from '@/api/billing'
import { handleError } from '@/utils/error-handling'
import { openLink } from '@/utils/external-link-helper'

import AppButton from '@/components/ui/AppButton/AppButton'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'

const OKR_PRICING = 'https://oboard.io/okr-app-pricing'
const loading = ref(false)
const getCheckoutEarlyBirdCheckoutSession = async () => {
  try {
    const BillingApi = new BillingApiHandler()
    loading.value = true
    const { url } = await BillingApi.earlyBirdCheckoutSession()
    openLink(url, '_self')
  } catch (error) {
    handleError({ error })
  } finally {
    loading.value = false
  }
}
</script>
<style lang="scss" scoped>
.bp-Wrapper {
  padding: 0 $page-right-padding 0 $page-left-padding;
}

.bp-Box {
  padding: 60px;
  background: rgba(var(--grade-high-color-rgb-next), 0.1);
  border-radius: $border-radius-lg-next;
  text-align: center;
  max-width: 1200px;
  margin: auto;
}

.bs-Title {
  font-size: $fs-48;
  line-height: 60px;
  margin-bottom: 16px;
}

.bs-Subtitle {
  margin-bottom: 48px;
  font-size: $fs-20;
  line-height: 28px;
  color: $dark-1;
  display: block;
  font-weight: fw('semi-bold');
}

.bs-Button {
  margin: auto auto 32px auto;
}

.bs-Description {
  color: $dark-2;
}
</style>
