<template>
  <FormField class="st-Field">
    <template #label>
      <div class="st-Field_Title">
        {{ t('settings.synchronization.select_synchronisation_type') }}
      </div>
    </template>
    <div class="st-Field_Wrapper">
      <div class="st-Field_Content">
        <AppRadioGroup
          v-model="selectType"
          :options="data"
          :type="APP_RADIO_GROUP_TYPES.BLOCKS"
          name="migration-type"
        />
      </div>
      <AppCheckbox
        v-model="setKeepValue"
        :disabled="!selectType || disabled"
        class="st-Field_Switch"
        label-color="black"
        label-position="right"
        label-size="md"
        size="s"
      >
        {{ getSwitchLabel }}
      </AppCheckbox>
    </div>
  </FormField>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { RADIO_GROUP_TYPES as APP_RADIO_GROUP_TYPES } from '@/utils/components-configurations/app-radio-group'

import FormField from '@/components/form/FormField'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'

defineOptions({
  name: 'SyncTypes'
})

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },

  syncDirection: {
    type: Number,
    default: 1
  },

  keepValue: {
    type: Boolean,
    default: false
  },

  disabled: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits({
  'update:syncDirection': null,
  'update:keepValue': null
})

const { t } = useI18n()

const setKeepValue = computed({
  get: () => props.keepValue,
  set: value => emit('update:keepValue', value)
})

const selectType = computed({
  get: () => props.syncDirection,
  set: value => emit('update:syncDirection', value)
})
const MIGRATION_OKR_TO_JIRA = 4

const getSwitchLabel = computed(() =>
  selectType.value === MIGRATION_OKR_TO_JIRA
    ? t('settings.synchronization.keep_value_jira')
    : t('settings.synchronization.keep_value_okr')
)
</script>

<style lang="scss" scoped>
.st-Field_Title {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}
.st-Field_Content {
  display: flex;
  align-items: center;
  gap: 16px;
}
.st-Field_Wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.st-Field_Switch {
  font-size: $fs-14;
  line-height: 20px;
  color: $regular-text;
}
</style>
