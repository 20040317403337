<template>
  <AppDroplist v-model="opened" :offset="[0, 0]" position="top-end" theme="no-shadow-next light">
    <template #button>
      <DescriptionButton v-if="showButtonTriggerButton" :active="opened" icon="emoji" />
    </template>
    <div class="aep-Wrapper">
      <div class="aep-Navbar">
        <AppButton
          v-for="(emojiCategory, emojiCategoryIndex) in EMOJI_LIST"
          :key="emojiCategory.category"
          :class="{
            'aep-NavButton-active': getCategoryName(emojiCategoryIndex) === activeCategory
          }"
          :icon="emojiCategory.icon"
          class="aep-NavButton"
          height="24"
          remove-padding
          size="sm"
          type="ghost"
          width="24"
          @click="scrollToCategory(emojiCategoryIndex)"
        />
      </div>
      <ul ref="categoryListTarget" class="aep-CategoryList">
        <li
          v-for="emojiCategory in EMOJI_LIST"
          :key="emojiCategory.category"
          ref="categoryListRef"
          class="aep-CategoryList_Item"
        >
          <AppTitle :level="5" class="aep-CategoryList_Title" disable-margin>
            {{ emojiCategory.category }}
          </AppTitle>
          <ul class="aep-CategoryList_Emojis">
            <li
              v-for="item in emojiCategory.data"
              :key="item.emoji"
              class="aep-Emojis_Item"
              @click="selectEmoji(item.emoji)"
            >
              {{ item.emoji }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </AppDroplist>
</template>

<script setup>
import { onBeforeUnmount, ref, watch } from 'vue'

import { EMOJI_LIST } from '@/utils/emoji-list'

import AppDroplist from '@/components/AppDroplist'
import DescriptionButton from '@/components/objectives/forms/DescriptionButton'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineProps({
  showButtonTriggerButton: {
    type: Boolean,
    default: true
  }
})
const opened = ref(false)
const emit = defineEmits({
  'select-emoji': null
})

const getCategoryName = id => `category-${id}`

const categoryListTarget = ref(null)
const activeCategory = ref(getCategoryName(0))
const categoryListRef = ref([])

defineExpose({
  opened
})

const selectEmoji = emoji => {
  emit('select-emoji', emoji)
}
const scrollToCategory = emojiCategoryIndex => {
  const category = categoryListRef.value[emojiCategoryIndex]
  categoryListTarget.value.scrollTo({ top: category.offsetTop, behavior: 'smooth' })
}

const intersectionObserver = ref(null)

const initIntersectionObserver = () => {
  intersectionObserver.value = new IntersectionObserver(category => {
    const [entry] = category
    if (entry.isIntersecting) {
      const activeCategoryIndex = categoryListRef.value.findIndex(item => item === entry.target)
      activeCategory.value = getCategoryName(activeCategoryIndex)
    }
  }, {})

  categoryListRef.value.forEach(category => {
    intersectionObserver.value.observe(category)
  })
}

const disconnectObserver = () => {
  if (intersectionObserver.value) {
    intersectionObserver.value.disconnect()
    intersectionObserver.value = null
  }
}

watch(
  () => opened.value,
  newValue => {
    if (newValue) {
      initIntersectionObserver()
    } else {
      disconnectObserver()
    }
  }
)

onBeforeUnmount(() => {
  disconnectObserver()
})
</script>
<script>
//eslint-disable-next-line import/order
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppEmojiPicker'
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.aep-CategoryList {
  display: grid;
  gap: 5px;
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
  margin: 0;
  @include styled-native-scrollbar();
}

.aep-CategoryList_Emojis {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  font-size: $fs-26;
}

.aep-Emojis_Item {
  cursor: pointer;
  border-radius: $border-radius-sm;
  width: 35px;
  text-align: center;
  @include activityStates($placeholder-color, 10%);
}

.aep-Wrapper {
  position: relative;
  border-radius: $border-radius-md;
  overflow: hidden;
  padding: 20px;
}

.aep-Navbar {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  list-style: none;
  box-shadow: inset 0 1px 0 $grey-medium;
  box-sizing: border-box;
  background: $grey-3-next;
  border-radius: $border-radius-sm-next;
  margin: 0 0 16px 0;
  padding: 4px;
  gap: 11px;
}

.aep-NavButton-active {
  background: $white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: $border-radius-sm;
}

.aep-CategoryList_Title {
  position: sticky;
  top: 0;
  background: $white;
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  font-family: $system-ui;
  padding-top: 0;
  padding-bottom: 16px;
}
</style>
