import i18n from '@/i18n'
import { ascSortValue, descSortValue } from '@/utils/sort-options'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

export const HORIZONTAL = 'HORIZONTAL'
export const VERTICAL = 'VERTICAL'
export const LAYOUT_VIEW_TYPES = {
  [HORIZONTAL]: 1,
  [VERTICAL]: 2
}

export const LAYOUT_NAMES_BY_TYPE_ID = Object.fromEntries(
  Object.entries(LAYOUT_VIEW_TYPES).map(item => item.reverse())
)

export const MAX_OBJECTIVES = 10
export const MIN_OBJECTIVES = 3

export const USER_SHARING_ROLE = {
  OWNER: 1,
  VIEWER: 2
}

const { FAVORITES, NAME, OWNER, VISIBILITY } = TABLE_COLUMNS_KEYS

export const CUSTOM_DASHBOARD_SORT_TYPES = {
  FAVORITE_ASC: {
    id: 1,
    sortBy: ascSortValue,
    sortField: FAVORITES
  },
  FAVORITE_DESC: {
    id: 2,
    sortBy: descSortValue,
    sortField: FAVORITES
  },
  NAME_ACS: {
    id: 3,
    sortBy: ascSortValue,
    sortField: NAME
  },
  NAME_DESC: {
    id: 4,
    sortBy: descSortValue,
    sortField: NAME
  },
  OWNER_NAME_ASC: {
    id: 5,
    sortBy: ascSortValue,
    sortField: OWNER
  },
  OWNER_NAME_DESC: {
    id: 6,
    sortBy: descSortValue,
    sortField: OWNER
  },
  VISIBILITY_ASC: {
    id: 7,
    sortBy: ascSortValue,
    sortField: VISIBILITY
  },
  VISIBILITY_DESC: {
    id: 8,
    sortBy: descSortValue,
    sortField: VISIBILITY
  }
}

export const CUSTOM_DASHBOARD_QUERY_KEYS = {
  OWNER_IDS: 'ownerIds',
  SORT_BY: 'sortBy',
  SORT_FIELD: 'sortField'
}

export const getLayoutTypeId = [
  {
    label: i18n.global.t('dashboard.horizontal'),
    value: LAYOUT_VIEW_TYPES[HORIZONTAL],
    icon: 'horizontal-view'
  },
  {
    label: i18n.global.t('dashboard.vertical'),
    value: LAYOUT_VIEW_TYPES[VERTICAL],
    icon: 'vertical-view'
  }
]
