<template>
  <div class="arc-Cell">
    <CellSelectItem
      :id="`ad-ro${id}`"
      :active="isOpened"
      :user-has-update-access="!isReadOnly"
      is-transparent
      truncated
      user-has-read-access
      @click="onClick"
    >
      {{ roleName }}
    </CellSelectItem>
    <AppSelect
      v-if="editInitialised"
      v-model="selectedRole"
      :dropdown-search="false"
      :inline-loader="false"
      :max-height="264"
      :offset="[0, 0]"
      :options="roles"
      :to-selector="`#ad-ro${id}`"
      append-to="parent"
      boundary="scrollParent"
      dropdown-width="160px"
      item-label="label"
      item-value="value"
      show-on-init
      theme="no-shadow-next light"
      type="default-next"
      @hide="hideSelect"
      @opened="isOpened = true"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import WebAppAdminsApiHandler from '@/api/web-app-admins'
import { ASSIGNEE_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { showNotify } from '@/utils/notify'
import { uid } from '@/utils/uid'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import CellSelectItem from '@/components/objectives/table/cells/CellSelectItem'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'AdminRoleCell'
})

const props = defineProps({
  admin: {
    type: Object,
    required: true
  },

  roles: {
    type: Array,
    required: true
  }
})

const id = uid()

const editInitialised = ref(false)

const isOpened = ref(false)

const hideSelect = () => {
  isOpened.value = false
}

const roleName = computed(() => {
  return (
    props.roles.find(role => role.value === props.admin[ASSIGNEE_ENTITY_KEYS.ADMIN_ROLE_ID])
      ?.label || ''
  )
})

const emit = defineEmits({
  'role-updated': null
})

const { t } = useI18n()
const updateRole = async newRoleId => {
  const api = new WebAppAdminsApiHandler()
  try {
    const payload = {
      [ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID]: props.admin.accountId,
      [ASSIGNEE_ENTITY_KEYS.ADMIN_ROLE_ID]: newRoleId
    }
    await api.updateAdminRole(payload)
    emit('role-updated', payload)
    showNotify({ title: t('notifications.role_updated') })
  } catch (error) {
    handleError({ error })
  }
}

const selectedRole = computed({
  get: () => props.admin[ASSIGNEE_ENTITY_KEYS.ADMIN_ROLE_ID],
  set: value => updateRole(value)
})

const store = useStore()

const isOwner = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_OWNER])

const userData = computed(() => store.state.system.userData)

const isReadOnly = computed(() => {
  if (isOwner.value) {
    // you can't change your own role
    return props.admin.accountId === userData.value.userAccountId
  }

  return true
})

const onClick = () => {
  if (!isReadOnly.value) {
    editInitialised.value = true
  }
}
</script>

<style lang="scss" scoped>
.arc-Cell {
  display: flex;
  padding-right: 8px;
}
</style>
