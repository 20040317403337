<template>
  <div ref="groupReference" class="aufg-Group">
    <div class="aufg-FieldItem_User">
      <AppSelect
        ref="userSelectRef"
        :allow-create-on-search="allowAddEmailsFromSelect"
        :entity-for-create="CREATE_ON_SEARCH_ENTITIES.EMAIL"
        :hidden-items="hiddenUsers"
        :is-error="!item[USER_IDENTIFIER_VALID_KEY]"
        :model-value="item[REQUEST_ENTITY_KEYS.IDENTIFIER]"
        :offset="[0, -40]"
        :options="users"
        :search-function="getUsersList"
        boundary="scrollParent"
        dropdown-search
        item-label="name"
        item-value="accountId"
        theme="no-shadow-next light"
        type="default-next"
        @opened="removeError(USER_IDENTIFIER_VALID_KEY)"
        @update:options="users = $event"
        @update:model-value="onSelectUser"
      >
        <template #button-content="{ option }">
          <OwnerFieldOption v-if="option" :option="option" label-key="name" />
          <div v-else class="aufg-SelectEmptyLabel">
            {{ $t('admin.user') }}
          </div>
        </template>
        <template #option-label="{ option }">
          <OwnerFieldOption :option="option" label-key="name" />
        </template>
      </AppSelect>
      <AppFieldError
        v-if="!item[USER_IDENTIFIER_VALID_KEY]"
        :show="!item[USER_IDENTIFIER_VALID_KEY]"
        class="aufg-Error"
      >
        {{ $t('field.required') }}
      </AppFieldError>
    </div>
    <div class="aufg-GroupsContainer">
      <div class="aufg-FieldItem">
        <AppSelect
          ref="userSelectRef"
          :is-error="!item.groupValid"
          :model-value="item.groupIds"
          :offset="[0, -40]"
          :options="groupsFilterOptions"
          :search-function="searchString => fetchGroupsForFilter({ searchString })"
          boundary="scrollParent"
          dropdown-search
          item-label="name"
          item-value="id"
          multi
          theme="no-shadow-next light"
          type="default-next"
          @opened="removeError('groupValid')"
          @update:options="groupsFilterOptions = $event"
          @update:model-value="onSelectGroup"
        >
          <template #button-content="{ options }">
            <div v-if="isEmpty(options)">
              {{ $t('objective.select_group') }}
            </div>
          </template>
          <template #option-label="{ option }">
            <GlobalGroupsSelectOption v-if="option" :group="option" />
          </template>
        </AppSelect>
        <AppFieldError v-if="!item.groupValid" :show="!item.groupValid" class="aufg-Error">
          {{ $t('field.required') }}
        </AppFieldError>
      </div>
    </div>

    <AppButton
      v-if="showClearButton"
      class="aufg-ClearButton"
      icon="close-sm"
      size="sm"
      type="ghost"
      @click="onClearClick"
    />
  </div>
  <WorkspacesAffectMessage :limit="5" :workspaces="affectedWorkspaces">
    {{ $t('group.user_will_be_added_to_the_workspaces') }}
  </WorkspacesAffectMessage>
</template>

<script setup>
import { useIntersectionObserver } from '@vueuse/core'
import { cloneDeep, isEmpty, isNull } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'

import PlatformApiHandler from '@/api/platform'
import { useGlobalGroups } from '@/composables/global-groups'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { CREATE_ON_SEARCH_ENTITIES } from '@/utils/select'
import { useEmailsInSelect, USER_IDENTIFIER_VALID_KEY } from '@/utils/web-app/emails-in-select'

import AppFieldError from '@/components/form/AppFieldError'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import WorkspacesAffectMessage from '@/components/global-groups/WorkspacesAffectMessage'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'AddUsersAndGroupFieldGroup'
})

const { allowAddEmailsFromSelect } = useEmailsInSelect()

const props = defineProps({
  item: {
    type: Object,
    required: true
  },

  showClearButton: {
    type: Boolean,
    default: true
  },

  usersToAdd: {
    type: Array,
    required: true
  }
})

const userSelectRef = ref(null)
const groupSelectRefs = ref([])

const groupReference = ref(null)
const groupReferenceIsVisible = ref(false)

useIntersectionObserver(
  groupReference,
  ([{ isIntersecting }]) => {
    groupReferenceIsVisible.value = isIntersecting
  },
  { threshold: 1 }
)

watch(
  () => groupReferenceIsVisible.value,
  newValue => {
    if (!newValue) {
      userSelectRef.value?.hideDropdown()
      // https://vuejs.org/guide/essentials/template-refs.html#refs-inside-v-for
      groupSelectRefs.value.forEach(item => item.hideDropdown())
    }
  },
  { immediate: true }
)

const emit = defineEmits(['remove-item', 'update:users-to-add', 'add-workspace'])
const onClearClick = () => {
  emit('remove-item', props.item.id)
}

const currentItemIndex = computed(() => {
  return props.usersToAdd.findIndex(item => item.id === props.item.id)
})
const getUpdatedList = newItem => {
  const updatedList = cloneDeep(props.usersToAdd)
  updatedList.splice(currentItemIndex.value, 1, newItem)
  return updatedList
}

const removeError = (key, index = null) => {
  const groupIds = [...props.item.groupIds]
  let newItem
  if (!isNull(index)) {
    groupIds[index][key] = true
    newItem = {
      ...props.item,
      groupIds
    }
  } else {
    newItem = {
      ...props.item,
      [key]: true
    }
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:users-to-add', updatedList)
}
const onSelectGroup = value => {
  const newItem = {
    ...props.item,
    groupIds: value
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:users-to-add', updatedList)
}

const onSelectUser = identifier => {
  const newItem = {
    ...props.item,
    identifier
  }

  const updatedList = getUpdatedList(newItem)

  emit('update:users-to-add', updatedList)
}

onMounted(async () => {
  users.value = await getUsersList()

  await Promise.all([getInitialGroupsForFilter()])
})
const users = ref([])

const hiddenUsers = computed(() => {
  return props.usersToAdd
    .map(user => user[REQUEST_ENTITY_KEYS.IDENTIFIER])
    .filter(userId => userId !== props.item[REQUEST_ENTITY_KEYS.IDENTIFIER])
})
const getUsersList = async (searchString = null) => {
  const platformApi = new PlatformApiHandler()

  try {
    return await platformApi.getPlatformUsers({
      searchString
    })
  } catch (error) {
    handleError({ error })
  }
}

const affectedWorkspaces = computed(() => {
  return (
    props.item.groupIds?.map(groupId => {
      const entity = groupsFilterOptions.value.find(item => item.id === groupId)
      return entity?.workspaces || []
    }) || []
  )
})

const { fetchGroupsForFilter } = useGlobalGroups()

const isGroupsFilterLoading = ref(false)
const groupsFilterOptions = ref([])
const getInitialGroupsForFilter = async () => {
  isGroupsFilterLoading.value = true
  try {
    groupsFilterOptions.value = await fetchGroupsForFilter()
  } catch (error) {
    handleError({ error })
  } finally {
    isGroupsFilterLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
$group-gap: 20px;
$items-count: var(--items-count, 2);

.aufg-Group {
  font-family: $system-ui;
  position: relative;
  flex: 1 0 auto;
  display: flex;
  align-items: flex-start;
  gap: $group-gap;
  width: var(--items-width, 100%);
}

.aufg-FieldItem_User,
.aufg-GroupsContainer {
  width: calc((100% - #{$group-gap}) / #{$items-count});
  position: relative;
  --gap: 0;
  --label-bottom-offset: 6px;
}
//.aufg-FieldItem {
//  width: 50%;
//  position: relative;
//  --gap: 0;
//  --label-bottom-offset: 6px;
//}

.aufg-ClearButton {
  position: absolute;
  right: -24px;
  top: 8px; // 40px - 24px / 2 where 40px is height of field and 24px is height of button
  color: $grey-1-next;
}

.aufg-Error {
  min-height: 20px;
  display: flex;
  align-items: center;
  // position: absolute;
  // bottom: -20px;
  // left: 0;
}

.aufg-SelectEmptyLabel {
  padding-left: 2px;
}
</style>
