<template>
  <span v-if="!loading" :class="classes">
    <template v-if="simplifiedSelected && !dropdownVisible">
      {{ selectedItemsList }}
    </template>
    <template v-else>
      <template v-for="item in selectedItems" :key="item.value">
        <AppSelectItem
          v-if="item.label"
          :disabled="disabled"
          :show-delete-button="isShowDeleteButton(item.value)"
          :show-selected-count-circle="showSelectedCountCircle"
          :type="type"
          @delete="deleteHandle(item.value)"
        >
          {{ item.label }}
        </AppSelectItem>
        <SkeletonItem v-else border-radius="6px" color="#e5edfa" size="sm" width="85px" />
      </template>

      <div
        v-if="showSearch"
        :class="{ 'ass-SearchWrapper-noItems': isNoItems }"
        class="ass-SearchWrapper"
      >
        <AppIcon
          v-if="isNoItems && !hideSearchIcon"
          class="ass-SearchIcon"
          height="24"
          icon-name="search-next"
          width="24"
        />
        <span v-if="!isNoItems" class="ass-SearchText">
          {{ localSearchString }}
        </span>

        <input
          ref="search"
          v-model="localSearchString"
          :maxlength="searchMaxLength"
          :placeholder="resolvedPlaceholder"
          class="ass-SearchInput"
          @blur="onBlur"
          @focus="onFocus"
          @keydown.enter="onKeyEnter"
          @keydown.esc="onKeyEsc"
          @keydown.delete="onKeyDelete"
        />
      </div>
    </template>
  </span>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelectItem from '@/components/ui/AppSelect/AppSelectItem'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

export default defineComponent({
  name: 'AppSelectedItemsList',
  components: { SkeletonItem, AppIcon, AppSelectItem },
  props: {
    selectedItems: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean
    },

    simplifiedSelected: {
      type: Boolean
    },

    dropdownVisible: {
      type: Boolean
    },

    showSelectedCountCircle: {
      type: Boolean
    },

    type: {
      type: String,
      default: 'default',
      validator: v => ['default', 'default-next'].includes(v)
    },

    showSearch: {
      type: Boolean
    },

    searchString: {
      type: String,
      default: ''
    },

    searchMaxLength: {
      type: Number,
      default: 512,
      validator: v => v > 0 && v <= 512 && Number.isInteger(v)
    },

    disabledItems: {
      type: Array,
      default: () => []
    },

    disabled: {
      type: Boolean
    },

    placeholder: {
      type: String,
      default: 'Search'
    },

    hideSearchIcon: {
      type: Boolean
    },

    blurOnEnter: {
      type: Boolean
    },

    blurOnEsc: {
      type: Boolean
    }
  },

  emits: {
    delete: null,
    'update:searchString': null,
    blur: null,
    'delete-last': null,
    focus: null
  },

  computed: {
    localSearchString: {
      get() {
        return this.searchString
      },

      set(v) {
        this.$emit('update:searchString', v)
      }
    },

    isNoItems() {
      return isEmpty(this.selectedItems)
    },

    resolvedPlaceholder() {
      return this.isNoItems ? this.placeholder : ''
    },

    selectedItemsList() {
      return this.selectedItems.map(item => item.label).join(', ')
    },

    classes() {
      return {
        'ass-SelectedItems': true,
        [`ass-SelectedItems-${this.type}`]: true,
        'ass-SelectedItems-dropdown-hidden': this.simplifiedSelected && !this.dropdownVisible,
        'ass-SelectedItems-with-search': this.showSearch
      }
    }
  },

  methods: {
    /**
     * @public
     */

    focus() {
      if (this.$refs.search) {
        this.$refs.search.focus()
      }
    },

    deleteHandle(value) {
      if (!this.disabledItems.includes(value)) {
        this.$emit('delete', value)
      }
    },

    isShowDeleteButton(value) {
      if (this.showSelectedCountCircle) {
        return this.selectedItems.length > 1
      } else {
        if (this.disabled) {
          return false
        }
        return !this.disabledItems.includes(value)
      }
    },

    onKeyEnter() {
      if (this.blurOnEnter) {
        this.$refs.search.blur()
      }
    },

    onKeyEsc() {
      if (this.blurOnEsc) {
        this.$refs.search.blur()
      }
    },

    onKeyDelete(e) {
      if (e.target.value === '') {
        this.$emit('delete-last')
      }
    },

    onBlur(e) {
      this.$emit('blur', e.target.value)
    },

    onFocus() {
      this.$emit('focus')
    }
  }
})
</script>

<style lang="scss" scoped>
.ass-SelectedItems {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;

  &-default {
    gap: 4px 2px;
    padding: 4px 8px 4px 0;
    line-height: 20px;
  }

  &-default-next {
    // padding: 6px;
    gap: 4px;
  }

  &-dropdown-hidden {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-with-search {
    min-height: 24px;
    cursor: text;
  }
}

.ass-SearchWrapper {
  position: relative;
  min-height: 24px;
  flex-grow: 1;

  &:not(&-noItems) {
    min-width: 1em;
    width: min-content;
  }

  &-noItems {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.ass-SearchIcon {
  flex: 0 0 auto;
}

.ass-SearchText {
  visibility: hidden;
  /* Prevent the span from wrapping the text when input value has multiple words, or collapsing multiple spaces into one */
  white-space: pre;
}

.ass-SearchInput {
  left: 0;
  top: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(.ass-SearchWrapper-noItems &) {
    position: absolute;
    width: 100%;
  }

  .ass-SearchWrapper-noItems & {
    width: inherit;
  }
}

.ass-SearchText,
.ass-SearchInput {
  caret-color: $primary-color-next;
  /* Normalize styles that the browser sets differently between spans and inputs.
  Ideally, use a "CSS reset" here. */
  padding: 0;
  /* Demonstrate that this works for input with custom styles */
  font-family: $system-ui;
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 24px;
  color: $dark-1;
  border: none;
  outline: none;
}
</style>
