<template>
  <div v-if="showOption" :class="{ 'iso-Option-backlog': option.backlog }" class="iso-Option">
    <AppSelectListItem
      :class="{
        'iso-Option_Content': true,
        'iso-Option_Content-activeInterval': isActiveInterval,
        'iso-Option_Content-backlog': option.backlog,
        'iso-Option_Content-archivedInterval': !option.open
      }"
      :focused="focused"
      :label="label"
      :model-value="modelValue"
      :multi="multi"
      :rounded-checkmark="option.backlog"
      :selected="!multi && modelValue === val"
      :show-only-this-button="!option.backlog"
      :val="val"
      class="iso-Option_Content"
      type="default-next"
      @mouseover="$emit('mouseover', $event)"
      @select="$emit('select', $event)"
      @update:model-value="$emit('update:model-value', $event)"
    />

    <AppDivider v-if="option.backlog" class="iso-Option_Divider" no-margin />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'

import { isBetweenDates } from '@/utils/interval'

import AppDivider from '@/components/ui/AppDivider'
import AppSelectListItem from '@/components/ui/AppSelect/AppSelectListItem'

export default defineComponent({
  name: 'IntervalSelectOption',

  components: {
    AppDivider,
    AppSelectListItem
  },

  props: {
    label: {
      type: String,
      required: true
    },

    modelValue: {
      type: [Number, String, Array],
      required: true
    },

    focused: {
      type: Boolean
    },

    multi: {
      type: Boolean
    },

    val: {
      type: [String, Number, Boolean],
      default: -1
    },

    option: {
      type: Object,
      default: () => ({})
    }
  },

  emits: { 'update:model-value': null, select: null, mouseover: null },

  computed: {
    isActiveInterval() {
      const { startDate, endDate, backlog } = this.option
      return isBetweenDates(startDate, endDate) && !backlog
    },

    showOption() {
      return !isEmpty(this.option)
    }
  }
})
</script>

<style lang="scss" scoped>
.iso-Option {
  display: flex;
  &:deep(.o-checkbox-label) {
    line-height: 24px;
  }

  &-backlog {
    flex-direction: column;
  }
}

.iso-Option_Content {
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  padding-left: 17px;

  &-activeInterval {
    box-shadow: inset 2px 0 0 var(--grade-high-color-next);
  }

  &-archivedInterval {
    box-shadow: inset 2px 0 0 $grey-1-next;
  }
}

.iso-Option_Divider {
  width: calc(100% - 18px);
  margin: 4px auto;
}
</style>
