<template>
  <div class="cs-Wrapper">
    <div
      v-for="cycle in cycles"
      :key="cycle.title"
      :class="{
        'cs-Item-active': cycle.period === selectedCycle
      }"
      class="cs-Item"
      @click="emit('update:selectedCycle', cycle.period)"
    >
      <span v-if="cycle.discount" class="cs-LabelDiscount">
        {{ $t('subscription.save_count_percent', cycle.discount) }}
      </span>
      <span class="cs-Title">{{ cycle.title }}</span>
      <div class="cs-PriceWrapper">
        <span class="cs-Price">${{ cycle.price }}</span>
        <div class="cs-PriceDescription">
          <span>{{ $t('subscription.per_user') }}</span>
          <span>{{ $t('subscription.per_month') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  cycles: {
    type: Array,
    required: true
  },

  selectedCycle: {
    type: [Number, String],
    required: true
  }
})

const emit = defineEmits(['update:selectedCycle'])
</script>

<style lang="scss" scoped>
.cs-Wrapper {
  display: flex;
  gap: 8px;
}

.cs-Item {
  position: relative;
  border-radius: $border-radius-sm-next;
  padding: 16px;
  width: 100%;
  cursor: pointer;
  box-shadow: inset 0 0 0 2px $grey-2-next;
  &-active {
    box-shadow: inset 0 0 0 2px $primary-color-next;
    background: rgba(var(--primary-color-rgb-next), 0.1);
  }
}

.cs-Title {
  color: $dark-2;
  font-size: $fs-14;
  line-height: 20px;
  font-weight: fw('semi-bold');
}

.cs-PriceWrapper {
  display: flex;
  gap: 8px;
  align-items: flex-end;
}

.cs-Price {
  font-size: $fs-32;
  line-height: 40px;
  color: $dark-1;
}

.cs-PriceDescription {
  display: flex;
  flex-direction: column;
  color: $dark-3;
  font-size: $fs-12;
  line-height: 16px;
}

.cs-LabelDiscount {
  padding: 3px 6px;
  border-radius: 0 $border-radius-sm-next 0 $border-radius-sm-next;
  font-size: $fs-12;
  line-height: 16px;
  color: $white;
  font-weight: fw('bold');
  background: $grade-high-color-next;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
