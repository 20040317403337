<template>
  <div class="up-UserPreferencesPage">
    <PageContentHeader
      :level="3"
      :title="$t('in_app_notifications.title')"
      max-width="full-next"
      no-margin
    />

    <div class="up-MainContent">
      <div class="up-EmailWrapper">
        <div v-if="!userData.email" class="up-ContentField">
          <div class="up-ContentField_SubTitle">
            <AppIcon height="24" icon-name="lock-next" width="24" />
            {{ t('user.personal_settings.dont_have_email_access') }}
          </div>
          <div class="up-NoAccess_Desc">
            <i18n-t keypath="user.personal_settings.please_contact_our" scope="global" tag="span">
              <template #contact>
                <a href="mailto:support@oboard.io">{{ t('menu.support') }}</a>
              </template>
            </i18n-t>
          </div>
        </div>
        <div v-else class="up-NotificationsWrapper">
          <div class="up-ContentField">
            <div class="up-ContentField_SubTitle">
              <AppIcon height="24" icon-name="email" width="24" />
              {{ userData.email }}
            </div>
            <AppDivider class="up-ContentField_Divider" />
          </div>
          <div class="up-ContentField">
            <div class="up-ContentField_Title up-ContentField_Title-withSubtitle">
              {{ t('settings.get_email_updates') }}
            </div>
            <div class="up-CheckboxItem_Desc up-ContentField_EmailNotificationSubtitle">
              {{ t('settings.when_someone_manually_changes') }}
            </div>
            <div class="up-ContentField_ConversationCheckboxList">
              <div class="up-ContentField_SubTitle">
                <AppCheckbox
                  v-model="groupedNotificationSettings[OWNER_CHANGES]"
                  size="sm"
                  @update:model-value="
                    updateGroupedNotificationSettings({
                      value: $event,
                      trackingName: 'Owner notifications'
                    })
                  "
                >
                  {{ t('user.personal_settings.you_are_owner') }}
                </AppCheckbox>
              </div>
              <div class="up-ContentField_SubTitle">
                <AppCheckbox
                  v-model="groupedNotificationSettings[STAKEHOLDER_CHANGES]"
                  size="sm"
                  @update:model-value="
                    updateGroupedNotificationSettings({
                      value: $event,
                      trackingName: 'Stakeholder notifications'
                    })
                  "
                >
                  {{ t('user.personal_settings.you_are_stakeholder') }}
                </AppCheckbox>
              </div>
              <div class="up-ContentField_SubTitle">
                <AppCheckbox
                  v-model="groupedNotificationSettings[REPLY_AND_MENTION]"
                  size="sm"
                  @update:model-value="
                    updateGroupedNotificationSettings({
                      value: $event,
                      trackingName: 'Reply and mention notifications'
                    })
                  "
                >
                  <i18n-t keypath="user.personal_settings.when_someone_reply_me">
                    <template #mention>
                      <b>{{ t('user.personal_settings.mentions') }}</b>
                    </template>
                    <template #reply>
                      <b>{{ t('user.personal_settings.reply') }}</b>
                    </template>
                  </i18n-t>
                </AppCheckbox>
              </div>
              <a
                href="https://oboard.atlassian.net/wiki/pages/viewpage.action?pageId=926285884"
                target="_blank"
              >
                {{ t('settings.learn_more_email_notifications') }}
              </a>
            </div>
            <!--            <AppDivider class="up-ContentField_Divider" />-->
          </div>
          <!--          <div class="up-ContentField">
            <div class="up-ContentField_Title">
              {{ t('user.personal_settings.other') }}
            </div>
            <div class="up-ContentField_CheckboxList">
              <AppCheckbox
                v-model="
                  notificationStatuses[NOTIFICATION_SETTING_TYPES.MARKETING_PRODUCT_UPDATE]
                    .isEnabled
                "
                size="sm"
                @update:model-value="updateNotificationSettings"
              >
                <div class="up-CheckboxItem">
                  <span class="up-CheckboxItem_Title">
                    {{ t('user.personal_settings.product_update') }}
                  </span>
                  <span class="up-CheckboxItem_Desc">
                    {{ t('user.personal_settings.about_qty_per_month', { qty: 2 }) }}
                  </span>
                </div>
              </AppCheckbox>
              <AppCheckbox
                v-model="
                  notificationStatuses[NOTIFICATION_SETTING_TYPES.MARKETING_PRODUCT_CASE_STUDY]
                    .isEnabled
                "
                size="sm"
                @update:model-value="updateNotificationSettings"
              >
                <div class="up-CheckboxItem">
                  <span class="up-CheckboxItem_Title">
                    {{ t('user.personal_settings.product_case_study') }}
                  </span>
                  <span class="up-CheckboxItem_Desc">
                    {{ t('user.personal_settings.about_qty_per_month', { qty: 1 }) }}
                  </span>
                </div>
              </AppCheckbox>
              <AppCheckbox
                v-model="
                  notificationStatuses[NOTIFICATION_SETTING_TYPES.MARKETING_PRODUCT_PROMOS]
                    .isEnabled
                "
                size="sm"
                @update:model-value="updateNotificationSettings"
              >
                <div class="up-CheckboxItem">
                  <span class="up-CheckboxItem_Title">
                    {{ t('user.personal_settings.product_promos') }}
                  </span>
                  <span class="up-CheckboxItem_Desc">
                    {{
                      t('user.personal_settings.about_qty_every_months', { qty: 1, repeat: '2-3' })
                    }}
                  </span>
                </div>
              </AppCheckbox>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { has, isEqual } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import NotificationsApiHandler from '@/api/notifications'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import {
  GROUPED_NOTIFICATIONS,
  NOTIFICATION_SETTING_TYPES,
  OWNER_CHANGES,
  REPLY_AND_MENTION,
  STAKEHOLDER_CHANGES
} from '@/utils/in-app-notifications'

import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'

defineOptions({
  name: 'UserNotificationsSettings'
})

const ALWAYS_ENABLE_INBOX_NOTIFICATIONS = [
  NOTIFICATION_SETTING_TYPES.USER_TAGGED,
  NOTIFICATION_SETTING_TYPES.COMMENTED_FOR_STAKEHOLDER,
  NOTIFICATION_SETTING_TYPES.COMMENTED_FOR_OWNER,
  NOTIFICATION_SETTING_TYPES.CONFIDENCE_LEVEL_CHANGE_FOR_OWNER,
  NOTIFICATION_SETTING_TYPES.CONFIDENCE_LEVEL_CHANGE_FOR_STAKEHOLDER,
  NOTIFICATION_SETTING_TYPES.GRADE_CHANGED_FOR_STAKEHOLDER,
  NOTIFICATION_SETTING_TYPES.GRADE_CHANGED_FOR_OWNER,
  NOTIFICATION_SETTING_TYPES.REPLY_COMMENT
]

const INBOX_NOTIFICATION_CHANNEL = 1

const store = useStore()

const { t } = useI18n()
const userData = computed(() => store.state.system.userData)

const api = new NotificationsApiHandler()

const notificationTypeAndChannels = ref([])

const notificationStatuses = ref(
  Object.values(NOTIFICATION_SETTING_TYPES).reduce((acc, curr) => {
    acc[curr] = {
      id: curr,
      isEnabled: false
    }
    return acc
  }, {})
)

const groupedNotificationSettings = ref(
  Object.entries(GROUPED_NOTIFICATIONS).reduce((acc, [key]) => {
    acc[key] = false
    return acc
  }, {})
)

const notificationSettings = ref([])

const fetchTypeAndChannels = async () => {
  try {
    notificationTypeAndChannels.value = await api.getNotificationTypeAndChannels()
  } catch (error) {
    handleError({ error })
  }
}

const getNotificationSettings = async () => {
  try {
    const data = await api.getNotificationSettings()
    const groupedNotifications = Object.entries(GROUPED_NOTIFICATIONS)

    notificationSettings.value = data.forEach(item => {
      const { notificationTypeId, notificationChannelsIds } = item

      const isAlwaysEnableInboxNotifications =
        ALWAYS_ENABLE_INBOX_NOTIFICATIONS.includes(notificationTypeId)

      if (isAlwaysEnableInboxNotifications) {
        notificationStatuses.value[notificationTypeId].isEnabled = !isEqual(
          notificationChannelsIds,
          [INBOX_NOTIFICATION_CHANNEL]
        )
      } else {
        if (notificationStatuses.value[notificationTypeId]) {
          notificationStatuses.value[notificationTypeId].isEnabled = true
        }
      }

      // Get grouped notifications
      const { channels } = notificationTypeAndChannels.value.find(
        item => item.id === notificationTypeId
      )
      const channelIds = channels.map(({ id }) => id)

      groupedNotifications.forEach(([key, value]) => {
        // Check if notification type is in grouped notifications and if channels are the same
        // Checking for channels required because some of notification can be enable ALWAYS, and have channel INBOX(1)
        if (
          value.notificationSettingTypes.includes(notificationTypeId) &&
          isEqual(channelIds.toSorted(), notificationChannelsIds.toSorted())
        ) {
          groupedNotificationSettings.value[key] = true
        }
      })
    })
  } catch (error) {
    handleError({ error })
  }
}

const updateGroupedNotificationSettings = ({ value, trackingName }) => {
  tracker.logEvent('Notification updated', {
    category: EVENT_CATEGORIES.PERSONAL_SETTINGS,
    value,
    label: trackingName
  })
  Object.entries(groupedNotificationSettings.value).forEach(([key, value]) => {
    const currentNotificationSettingTypes = GROUPED_NOTIFICATIONS[key].notificationSettingTypes
    if (value) {
      currentNotificationSettingTypes.forEach(notificationTypeId => {
        notificationStatuses.value[notificationTypeId].isEnabled = true
      })
    } else {
      currentNotificationSettingTypes.forEach(notificationTypeId => {
        notificationStatuses.value[notificationTypeId].isEnabled = false
      })
    }
  })
  updateNotificationSettings()
}
const updateNotificationSettings = async () => {
  try {
    const payload = Object.values(notificationStatuses.value).reduce((acc, item) => {
      const { id: notificationTypeId, isEnabled } = item

      const isAlwaysEnableInboxNotifications =
        ALWAYS_ENABLE_INBOX_NOTIFICATIONS.includes(notificationTypeId)

      if (isEnabled || isAlwaysEnableInboxNotifications) {
        const currentNotificationSettings = notificationTypeAndChannels.value.find(
          item => item.id === notificationTypeId
        )
        let notificationChannelsIds = []
        if (has(currentNotificationSettings, 'channels')) {
          notificationChannelsIds = currentNotificationSettings.channels.map(({ id }) => id)

          // additional logic for mentions settings: we need to send inbox channel id all the time
          // even if user uncheck mentions settings by email
          // but if user uncheck mentions settings by email we need to send all available channels
          if (isAlwaysEnableInboxNotifications && !isEnabled) {
            notificationChannelsIds = [INBOX_NOTIFICATION_CHANNEL]
          } else {
            notificationChannelsIds = currentNotificationSettings.channels.map(({ id }) => id)
          }
        }

        const notificationSettings = {
          notificationTypeId,
          notificationChannelsIds
        }
        return [...acc, notificationSettings]
      }

      return acc
    }, [])

    notificationSettings.value = await api.updateNotificationSettings({
      notificationsSettings: payload
    })
  } catch (error) {
    handleError({ error })
  }
}

onMounted(async () => {
  await fetchTypeAndChannels()
  await getNotificationSettings()
})
</script>

<style lang="scss" scoped>
.up-UserPreferencesPage {
  padding-bottom: $page-bottom-padding;
}

.up-MainContent {
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
  width: 100%;
  --label-color: $dark-2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.up-EmailWrapper {
  margin-bottom: 60px;
}

.up-ContentField_Title {
  font-weight: fw('medium');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
  margin-bottom: 19px;

  &-withSubtitle {
    margin-bottom: 7px;
  }
}

.up-ContentField_SubTitle {
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 0 5px 0;
}

.up-ContentField_EmailNotificationSubtitle {
  margin-bottom: 20px;
}

.up-NoAccess_Desc {
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-3;
  padding: 0 0 0 32px;
}

.up-EmailWrapper {
  margin-bottom: 60px;
}

// .up-ContentField_CheckboxList {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 20px;
//   &:deep(.ac-Checkbox_Label) {
//     align-items: flex-start;
//   }
//   &:deep(.ac-Checkmark) {
//     margin-top: 3px;
//   }
// }
// .up-CheckboxItem {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 5px;
// }
.up-CheckboxItem_Desc {
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}

// .up-CheckboxItem_Title {
//   font-size: $fs-14;
//   line-height: 20px;
//   color: $dark-2;
// }
.up-NotificationsWrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.up-ContentField_Divider {
  margin: 32px 0 0 0;
}

.up-ContentField_ConversationCheckboxList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
