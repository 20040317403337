<template>
  <span
    v-if="statusObject"
    :class="{
      [`obs-ObjectiveStatus-${type}`]: type,
      'obs-ObjectiveStatus-loading': isDefaultNext && loading,
      'obs-ObjectiveStatus-noBorder': noBorder
    }"
    :style="{
      color: statusObject.color,
      '--rgb-color': statusObject.rgbColor,
      '--border-color': statusObject.borderColor,
      '--background-color': statusObject.backgroundColor
    }"
    class="obs-ObjectiveStatus"
  >
    <AppIcon
      v-if="type !== TYPES.DEFAULT_NEXT"
      :icon-name="statusObject.icon"
      class="obs-ObjectiveStatus_Icon"
    />
    <slot :label="$t(statusObject.label)">
      {{ $t(statusObject.label) }}
    </slot>
    <slot name="after" />
    <SkeletonItem
      v-if="isDefaultNext && loading"
      :color="statusObject.color"
      border-radius="0"
      class="obs-ObjectiveStatus_Loader"
      height="100%"
      width="100%"
    />
  </span>
</template>

<script>
import { defineComponent } from 'vue'

import { OBJECTIVE_STATUS_ALL_OPTIONS } from '@/utils/objectives'

import AppIcon from '@/components/ui/AppIcon/AppIcon'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const TYPES = {
  DEFAULT: 'default',
  DEFAULT_NEXT: 'default-next'
}

export default defineComponent({
  name: 'ObjectiveStatus',

  components: {
    SkeletonItem,
    AppIcon
  },

  props: {
    status: {
      type: Number,
      required: true
    },

    type: {
      type: String,
      default: TYPES.DEFAULT,
      validator: v => Object.values(TYPES).includes(v)
    },

    loading: {
      type: Boolean
    },

    noBorder: {
      type: Boolean
    }
  },

  computed: {
    TYPES: () => TYPES,

    isDefaultNext() {
      return this.type === TYPES.DEFAULT_NEXT
    },

    statusObject() {
      return OBJECTIVE_STATUS_ALL_OPTIONS.find(option => option.value === this.status)
    }
  }
})
</script>

<style lang="scss" scoped>
.obs-ObjectiveStatus {
  &-default {
    gap: 4px;
    line-height: normal;
    display: flex;
    align-items: center;
  }

  &-default-next {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $system-ui;
    font-style: normal;
    font-weight: fw('bold');
    text-transform: uppercase;
    font-size: $fs-12;
    line-height: 16px;
    padding: var(--padding, 2px 4px);
    border-radius: $border-radius-sm-next;
    white-space: nowrap;
    width: min-content;
    max-width: 100%;
    background-color: var(--background-color, transparent);
  }

  &:not(&-noBorder) {
    border: 2px solid var(--border-color, rgba(var(--rgb-color), 0.3));
  }

  &-loading {
    position: relative;
  }
}

.obs-ObjectiveStatus_Icon {
  flex: 0 0 auto;
}

.obs-ObjectiveStatus_Loader {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
}
</style>
